


const AdminInfoService = async (url, userId) => {
    console.log(`uid: ${userId}`);
    // console.log(`em:${empId} se: ${sequence} st: ${status} md: ${moduleId} au: ${authorizationId} ap: ${approvalId}`);
    // const url1="http://190.92.217.21:8080/ords/weplappsdev/dev/hrlm/my_info";
    const response = await fetch(url,
        {
            method: "POST",
            headers: {

                "Content-Type": "application/json",
                'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
            },
            body: JSON.stringify({
                "Z_DATA": userId,
                "DEVICE":"WEB"
            }),
        }
    );
    // if (!response.ok) {
    //     throw new Error("fetching is not successful");
    //   }
    const data = await response.json();
    // console.log(`info: ${data.status}`);
    return data;


}
export default AdminInfoService
