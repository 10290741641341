
const PositionGetFromSapService = async ( url) => {
   
    const response = await fetch(url,
        {
            // mode: 'no-cors',
            // mode: "cors",
            method: "GET",
            headers: {
                "Content-Type": "application/json",
               
                'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_SU}:${process.env.REACT_APP_SP}`),
            },
            
        }
    );

    const data = await response.json();
  //   console.log(`odata: ${data}`);
    return data;


}
export default PositionGetFromSapService