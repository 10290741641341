


const getPropicService = async (url, empId, propicName) => {
    console.log(`empId: ${empId} url: ${url} propicName: ${propicName}`);
    // console.log(`em:${empId} se: ${sequence} st: ${status} md: ${moduleId} au: ${authorizationId} ap: ${approvalId}`);
    
    // const response = await fetch(`${url}${propicId}`,
    //     {
    //         method: "POST",
    //         headers: {

    //             "Content-Type": "application/json",
    //             'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
    //         },
            
    //     }
    // );

    const response = await fetch(url, {
        
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization:
                "Basic " +
                btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
        },
        body: JSON.stringify({
            "EMPLOYEE_ID": empId,
            "PROPIC_NAME": propicName,
        }),
    });


    // if (!response.ok) {
    //     throw new Error("fetching is not successful");
    //   }
    const data = await response.json();
    // console.log(`pic response: ${data['PROPIC']}`);
    return data;


}
export default getPropicService
