
import { baseUrl } from "../../../utils/path";

const UpdatePasswordService = async (email, password, id, empNum) => {

  const url = `${baseUrl}update_password`;

  console.log("email", email);
  console.log("password", password);
  console.log("id", id);
  console.log("mob no.: ", empNum);
    
  const response = await fetch(url,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
      },
      body: JSON.stringify({
        "EMAIL": email,
        "PASS": password,
        "EMPLOYEE_ID": id,
        "MOBILE_NUMBER": empNum
      }),
    }
  );
  const data = await response.json();
  console.log(`result: ${data}`);
  return data;
   
}
export default UpdatePasswordService;