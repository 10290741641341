  import { DatePicker } from "rsuite";
  import Datepicker from "react-tailwindcss-datepicker";
  import { useEffect } from "react";
  import { useState } from "react";
  import RequisitionReportService from "../service/RequisitionReportService";
  import Loading from "../../components/loader/Loading";
  import moment from "moment";
  import { baseUrl } from "../../utils/path";
  import getCompanyList from "../../services/CompanyListService";
  import getPlantList from "../../services/PlantListService";

  import { CSVLink } from "react-csv";

  const companyUrl = `${baseUrl}getCompanyList`;
  const moduleUrl = `${baseUrl}approval_module_types`;
  const plantUrl = `${baseUrl}get_plant_details`;


  export default function RequisitionReportPage() {

    const [sateStatusCnt, setStageStatusCnt] = useState('');
    const [userStatusCnt, setUserStatusCnt] = useState('A');
    const [uId, setUid] = useState('');
    const [page, setPage] = useState(0);
    let limit = 10;
    let totalLimit = 100000;
    const [data, setData] = useState([]);
    const [totalPage, setTotalPage] = useState(null);
    const [pageCount, setPageCount] = useState(1)
    const [empId, setEmpId] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [isSearch, setIsSearch]=useState(false);
    let [pageCountSearch, setPageCountSearch]=useState(1);
    let [pageSearch, setPageSearch]=useState(0);
    const [startEndDate, setStartEndDate] = useState({ startDate: null, endDate: null, });
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [companyCode, setCompanyCode] = useState('');
    const [plantCode, setPlantCode] = useState('');
    const [selectedCompanyname, setSelectedCompanyName] = useState('');
    const [selectedPlantname, setSelectedPlantName] = useState('');
    const [isCompanyLoading, setIsCompanyLoading] = useState(true);
    const [company, setCompany] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const [isPlantVisible, setIsPlantVisible] = useState(false);
    const [plant, setPlant] = useState([]);
    const [isPlantLoading, setIsPlantLoading] = useState(true);
    const [searchById, setSearchById]=useState('');
    const [processedData, setProcessedData] = useState([]);
    const [allProcessedData, setAllProcessedData] = useState([]);
    const [fileName, setFileName] = useState(moment(Date()).format("DD/MM/YYYY"));
    const todayDate = moment(Date()).format('YYYY-MM-DD');

    useEffect(() => {
      const id = localStorage.getItem('userId');
      setUid(id);
      getAll();
      allDownloadData();
      // getPlanMaster();
      // stageStatusRef.current.value = "A";
      // userStatusRef.current.value = "A";
      setStageStatusCnt("A");
      setUserStatusCnt("A");

    }, []);

    useEffect(() => {
      const getCompanyName = async () => {
        const result = await getCompanyList(companyUrl);
        setCompanyList(result.items);
        setIsCompanyLoading(false);
      };
      getCompanyName();
    },[] );

    // initially get all data
    const getAll = async () => {
      const result = await RequisitionReportService("", "", "", "", "", page, limit);
      
      dividePage(result.TOTAL);
      console.log("Requisition Report", result.REQUISTION_LIST);
        
      setData(result.REQUISTION_LIST);
      processData(result.REQUISTION_LIST);
      setIsLoading(false);
    };

    const allDownloadData = async (stDate, eDate, compCode, plantCode, searchId) => {
      setIsLoading(true);

      console.log("stDate: ", stDate);
      console.log("eDate: ", eDate);
      console.log("compCode: ", compCode);
      console.log("plantCode: ", plantCode);
      console.log("searchId: ", searchId);

      const result = await RequisitionReportService(searchId ? searchId : "", compCode ? compCode : "", plantCode ? plantCode : "", stDate ? stDate : "", eDate ? eDate : "", page, totalLimit);
      
      allProcessData(result.REQUISTION_LIST);
      setIsLoading(false);
    };

    // divided page number to allPage (for pagination)
    const dividePage = (number) => {
      console.log(number);
      if (typeof number !== 'number') {
        throw new Error('Input must be a number');
      }
        
      const re= Math.ceil(number / 10);
      setTotalPage(re);
    };

    // go to next page
    const next = async () => {
      // setPage((page)=>page+10);
      const newPage = page + 10;
      setPage(newPage);
      console.log(`page here ${newPage}`);
      setPageCount((pre)=>pre+1);
    
      setIsLoading(true);
      setData([]);
    
      const result = await RequisitionReportService("", "", "", fromDate, toDate, newPage, limit)
        
      dividePage(result.TOTAL);
      setData(result.REQUISTION_LIST);
      processData(result.REQUISTION_LIST);
      setIsLoading(false);
      
      console.log('clicked');
    }

    // after Searching next page
    const searchNext = async() => {
      // setPage((page)=>page+10);
      const newPage = pageSearch + 10;
      setPageSearch(newPage);
      console.log(`page here ${newPage}`);
      setPageCountSearch((pre)=>pre+1);

      setIsLoading(true);
      setData([]);

      const result = await RequisitionReportService("", "", "", fromDate, toDate, newPage, limit);

      dividePage(result.TOTAL);
        
      setData(result.REQUISTION_LIST);
      processData(result.REQUISTION_LIST);
      setIsLoading(false);
    }

    // back to previous page
    const previous = async () => {
        
      // setPage((pre)=>pre-10);
      const newPage = page - 10;
      setPage(newPage);
      console.log(`page here ${newPage}`);
      setPageCount((pre)=>pre-1);
    
      console.log('previous button clicked');
      setIsLoading(true);
      setData([]);
      
      const result = await RequisitionReportService("", "", "", fromDate, toDate, newPage, limit);
      
      dividePage(result.TOTAL);

      setData(result.REQUISTION_LIST);
      processData(result.REQUISTION_LIST);
      setIsLoading(false);
    }

    // after Searching back previous
    const searchPrevious = async () => {
      const newPage = pageSearch - 10;
      setPageSearch(newPage);
      console.log(`page here ${newPage}`);
      setPageCountSearch((pre)=>pre-1);
    
      console.log('clicked');
      setIsLoading(true);
      setData([]);
      
      const result = await RequisitionReportService("", "", "", fromDate, toDate, newPage, limit);

      dividePage(result.TOTAL);
      setData(result.REQUISTION_LIST);
      processData(result.REQUISTION_LIST);
      setIsLoading(false);
    }

    // convert date by day/month/year
    const convertDate = (dateString) => {
      const dateObject = new Date(dateString);
      const day = String(dateObject.getUTCDate()).padStart(2, '0');
      const month = String(dateObject.getUTCMonth() + 1).padStart(2, '0');
      const year = dateObject.getUTCFullYear();

      return `${day}/${month}/${year}`;
    };

    // using for see more and see less for description
    const [seeMore, setSeeMore] = useState(-1);

    const handleSeeMoreDetails = (index) => {
      setSeeMore(index === seeMore ? -1 : index);
    };

    // date select
    const handleDateChange = async (newDate) => {
      console.log("start:", moment(newDate.startDate).format("YYYY-MM-DD"));
      console.log("end:", moment(newDate.endDate).format("YYYY-MM-DD"));
      setStartEndDate(newDate);
      
      setFromDate(newDate.startDate);
      setToDate(newDate.endDate);

      console.log("select date new: ", fromDate);
      setIsLoading(true);
      setData([]);
      
      setCompanyCode([]);
      setPlantCode([]);
      setSearchById('')

      // Update fileName to include the selected date or fallback to today's date
      setFileName(newDate ? moment(newDate).format("DD/MM/YYYY") : todayDate);  
      
      const result = await RequisitionReportService("", "", "", newDate.startDate, newDate.endDate, page, limit);
      
      dividePage(result.TOTAL);
      setData(result.REQUISTION_LIST);
      processData(result.REQUISTION_LIST);
      allDownloadData(newDate.startDate, newDate.endDate, "", "", "");
      setIsLoading(false);
    }

    // company data
    const handleCompany = async (e) => {
      const selectedCode = e.target.value;
      const selectedName = companyList.find(c => c.company_code === selectedCode)?.company_name || '';
      
      console.log("Selected company code: ", selectedCode)

      setCompanyCode(selectedCode);
      setSelectedCompanyName(selectedName);
      getPlant(selectedCode);

      setIsLoading(true);
      setData([]);
      
      const result = await RequisitionReportService("", selectedCode, "", fromDate, toDate, page, limit);

      dividePage(result.TOTAL);
      setData(result.REQUISTION_LIST);
      processData(result.REQUISTION_LIST);
      allDownloadData(fromDate, toDate, selectedCode, "", "");
      setIsPlantVisible(true);
      setIsLoading(false);
    };

    // plant data 
    const handlePlant = async (e) => {
      // handleDropDown(e);
      // setPlantName(e.PLANT_NAME);

      const selectedPlantCode = e.target.value;
      setPlantCode(selectedPlantCode);

      const selectedName = plant.find(c => c.PLANT_CODE === selectedPlantCode)?.PLANT_NAME || '';
      setSelectedPlantName(selectedName);

      setIsLoading(true);
      setData([]);

      const result = await RequisitionReportService("", companyCode, selectedPlantCode, fromDate, toDate, page, limit);

      dividePage(result.TOTAL);
      setData(result.REQUISTION_LIST);
      processData(result.REQUISTION_LIST);
      allDownloadData(fromDate, toDate, companyCode, selectedPlantCode, "");
      setIsLoading(false);
    };

    // plant list here
    const getPlant = async (code) => {
      const plant = await getPlantList(plantUrl, code);
      console.log("plant:=", plant);
      setPlant(plant.PLANT_DETAILS);

      setIsPlantLoading(false);
    }

    // for searching by employee id
    const searchEmpId = async () => {
      setIsLoading(true);
      setData([]);

      const result = await RequisitionReportService(searchById, companyCode, plantCode, fromDate, toDate, page, limit);

      dividePage(result.TOTAL);
      setData(result.REQUISTION_LIST);
      processData(result.REQUISTION_LIST);
      allDownloadData(fromDate, toDate, companyCode, plantCode, searchById);
      setIsLoading(false);

      // setSearchById('');
    }

    const processData = (data) => {
      if (data && data.length > 0) {
        const newData = data.map((obj) => {
          const newDate = new Date(obj.REQUISITION_LOADING_DATE);
          const formattedDate = moment(newDate).format("DD/MM/YYYY");

          return { ...obj, REQUISITION_LOADING_DATE: formattedDate };
        });
        setProcessedData(newData);
        console.log("processed Data: ", processedData)
      } 
      
      else {
        console.log("Data is empty or undefined");
      }
    };

    const allProcessData = (data) => {
      if (data && data.length > 0) {
        const newData = data.map((obj) => {
          const newDate = new Date(obj.REQUISITION_LOADING_DATE);
          const formattedDate = moment(newDate).format("DD/MM/YYYY");

          return { ...obj, REQUISITION_LOADING_DATE: formattedDate };
        });
        setAllProcessedData(newData);
      } 
      
      else {
        console.log("Data is empty or undefined");
      }
    };

    const headers = [
      { label: "EMPLOYEE ID", key: "EMPLOYEE_ID" },
      { label: "EMPLOYEE NAME", key: "NAME" },
      { label: "POSITION", key: "POSITION" },
      { label: "SUBAREA", key: "SUBAREA" },
      { label: "REQUISITION LOADING DATE", key: "REQUISITION_LOADING_DATE" },
      { label: "REQUISITION NUMBER", key: "REQUISITION_NUMBER" },
      { label: "REQUISITION TYPE", key: "REQUISITION_TYPE" },
      { label: "APPROVED BY", key: "REQUISITION_APPROVED_BY" },
      { label: "PURPOSE", key: "PURPOSE" },
      { label: "DESCRIPTION", key: "DESCRIPTION" },
      { label: "QUANTITY", key: "QUANTITY" },
      { label: "UOM", key: "UOM" },
      { label: "AMOUNT", key: "AMOUNT" },
      { label: "CASH RECEIVED TYPE", key: "CASH_RECEIVED_TYPE" },
      { label: "REQUISITION STATUS", key: "PENDING_REQUISITION" },
    ];

    // let fileName = moment(Date()).format("DD/MM/YYYY");


    return (
      <div className="mx-8 my-20 bg-white">
        <div className='w-full bg-white'>
          <h4 className=' text-black font-semibold lg:text-lg md:text-sm text-xs'>Requisition Report</h4>

          <div className="h-5"></div>

          {
            isLoading ? (
              <Loading />
            ) : (
              <>
                <div className="flex items-center space-x-3">
                  <div className="w-1/4">
                    <Datepicker
                      // useRange={moveTypeCode==='11'?true:false}
                      // asSingle={moveTypeCode!=='11'?true:false}
                      popoverDirection="down"
                      placeholder="Date From-To         "
                      showFooter={true}
                      primaryColor={"green"}
                      showShortcuts={true}
                      value={startEndDate}
                      onChange={handleDateChange}
                      displayFormat={"DD/MM/YYYY"}
                      toggleClassName={`absolute rounded-r-lg text-[#7F7F7F] right-0 h-full px-3 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed`}
                      // inputClassName="w-full rounded-md focus:ring-0 font-normal bg-green-100 dark:bg-green-900 dark:placeholder:text-green-100"
                      // containerClassName=" border border-[1px] border-gray-400 w-80 rounded-md"
                      // toggleClassName=" text-gray-400"
                      inputClassName={`outline-buttonColor bg-white w-full rounded-r-lg h-[48px] border-[1px] border-gray-300 rounded-md px-3`}
                      // containerClassName="  "
                    />
                  </div>

                  <div className="w-1/4">
                    {
                      <select
                        placeholder='Select Company'
                        value={companyCode}
                        onChange={handleCompany}
                        className="select select-bordered bg-white w-full focus:outline-none h-[48px]"
                      >
                        <option value='' disabled selected>Select Company</option>
                        {
                          companyList.map((e, index) =>
                            <option key={index} value={e.company_code}>{e.company_name}({e.company_code})</option>
                          )
                        }
                      </select>
                    }
                  </div>

                  <div className="w-1/4">
                    {  isPlantVisible && (
                      <select
                        value={plantCode}
                        onChange={handlePlant}
                        className="select select-bordered bg-white w-full focus:outline-none h-[48px]"
                      >
                        <option value="" disabled selected>Select Plant</option>
                        {
                          isPlantLoading ?
                            <p>loading.....</p>
                            : plant.map((e, index) =>
                              <option value={e.PLANT_CODE} key={index}>{e.PLANT_NAME}({e.PLANT_CODE})</option>
                            )
                        }
                      </select>
                    )}
                  </div>

                  <div className="flex flex-row space-x-2 items-center w-1/4">
                    <input onChange={(e)=>{setSearchById(e.target.value)}} onKeyPress={(e) => { if (e.key === 'Enter') searchEmpId(); }} value={searchById} type="text" className="w-full px-3 h-[48px] rounded-lg border-[0.2px] bg-white border-gray-300 placeholder:text-sm placeholder:text-gray-400 focus:outline-none" placeholder='Search by Employee Id' />
                    
                    <button onClick={() => { searchEmpId() }} className="bg-[#013E0E] h-[48px] w-[87px] px-3 flex justify-center items-center text-white rounded-lg">
                      {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                      </svg> */}

                      Search
                    </button>
                  </div>
                </div>

                <div className="h-7"></div>

                {/* <button onClick={downloadExcel} className="bg-[#013E0E] h-[48px] w-[87px] px-3 flex justify-center items-center text-white rounded-lg">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" dataSlot="icon" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
                  </svg>

                  <p>Download</p>
                </button> */}

                <div className="flex items-center justify-end space-x-2">
  

                  { data.length === 0 ? null : (
                    <CSVLink data={processedData} headers={headers} filename={`REQUISITION_REPORT_${fileName}.csv`}>
                      <button className={` w-[180px] h-[48px] flex justify-center items-center bg-[#E6E1DD] px-2 rounded-lg shadow-sm text-[#013E0E] space-x-2 no-underline`}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" dataSlot="icon" className="w-5 h-5">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
                        </svg>

                        <h2 className=" lg:text-sm md:text-xs text-xs">
                          Download
                        </h2>
                      </button>
                    </CSVLink>
                  )}

                  { data.length === 0 ? null : (
                    <CSVLink data={allProcessedData} headers={headers} filename={`ALL_REQUISITION_REPORT_${fileName}.csv`}>
                      <button className={` w-[180px] h-[48px] flex justify-center items-center bg-[#E6E1DD] px-2 rounded-lg shadow-sm text-[#013E0E] space-x-2`}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" dataSlot="icon" className="w-5 h-5">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
                        </svg>

                        <h2 className=" lg:text-sm md:text-xs text-xs">
                          Download All
                        </h2>
                      </button>
                    </CSVLink>
                  )}
                </div>

                <div className="h-7"></div>

                <div className="w-full">
                  {
                    data.length === 0 ? (
                      <div className='flex flex-row justify-center items-center text-center w-full h-screen'>
                        <h1 className={`text-red-500 text-center w-full font-bold text-2xl`}>No Data Found</h1>
                      </div>
                    ) : (
                      <>
                        <div className="overflow-x-auto">
                          <table className="w-full border-[0.5px] border-gray-200 rounded-lg">
                            <thead className="bg-bgTableHeader text-tableHeaderText shadow-sm h-14 rounded-lg">
                              <tr>
                                <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                  SL
                                </th>

                                <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                  EMPLOYEE ID
                                </th>
                                    
                                <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                  EMPLOYEE NAME
                                </th>
                                    
                                <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                  POSITION
                                </th>
                                    
                                <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                  SUBAREA
                                </th>
                                  
                                <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                  REQUISITION LOADING DATE
                                </th>

                                <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                  REQUISITION NO.
                                </th>

                                <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                  REQUISITION TYPE
                                </th>
                                    
                                <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                  APPROVED BY
                                </th>
                                    
                                <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                  PURPOSE
                                </th>
                                    
                                <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                  DESCRIPTION
                                </th>
                                  
                                <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                  QUANTITY
                                </th>
                                <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                  UOM
                                </th>

                                <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                  AMOUNT
                                </th>
                                    
                                <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                  CASH RECEIVED TYPE
                                </th>
                                    
                                <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                  PENDING REQUISITION
                                </th>
                              </tr>
                            </thead>
                                
                            <tbody className="w-full divide-y divide-borderColor">
                              {data.map((e, index) => (
                                <tr key={index} className="bg-white">
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {page + index + 1}
                                  </td>

                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {e.EMPLOYEE_ID == null ? "N/A" : e.EMPLOYEE_ID}
                                  </td>
                                      
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {e.NAME == null ? "N/A" : e.NAME}
                                  </td>
                                      
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {e.POSITION == null ? "N/A" : e.POSITION}
                                  </td>
                                      
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {e.SUBAREA == null ? "N/A" : e.SUBAREA}
                                  </td>
                                      
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {convertDate(e.REQUISITION_LOADING_DATE) == null ? "N/A" : convertDate(e.REQUISITION_LOADING_DATE)}
                                  </td>

                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {e.REQUISITION_NUMBER == null ? "N/A" : e.REQUISITION_NUMBER}
                                  </td>
                                      
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {e.REQUISITION_TYPE == null ? "N/A" : e.REQUISITION_TYPE}
                                  </td>
                                      
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {e.REQUISITION_APPROVED_BY == null ? "N/A" : e.REQUISITION_APPROVED_BY}
                                  </td>
                                      
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {e.PURPOSE == null ? "N/A" : e.PURPOSE}
                                  </td>
                                      
                                  {/* <td className="px-6 py-4 whitespace-nowrap">
                                    {e.DESCRIPTION == null ? "N/A" : e.DESCRIPTION}
                                  </td> */}
                                  <td style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>
                                    {e.DESCRIPTION == null ? "N/A" : (
                                      <>
                                        {seeMore === index ? (
                                          <div>
                                            {e.DESCRIPTION}
                                            <button className='text-gray-500' onClick={() => handleSeeMoreDetails(index)}>See Less</button>
                                          </div>
                                        ) : (
                                          <div>
                                            {e.DESCRIPTION.length > 20 ? (
                                              <>
                                                {e.DESCRIPTION.substring(0, 20)}...
                                                <button className='text-gray-500' onClick={() => handleSeeMoreDetails(index)}>See More</button>
                                              </>
                                            ) : (
                                              <div>{e.DESCRIPTION}</div>
                                            )}
                                          </div>
                                        )}
                                      </>
                                    )}
                                  </td>

                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {e.QUANTITY == null ? "N/A" : e.QUANTITY}
                                  </td>
                                      
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {e.UOM == null ? "N/A" : e.UOM}
                                  </td>
                                      
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {e.AMOUNT == null ? "N/A" : e.AMOUNT}
                                  </td>
                                      
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {e.CASH_RECEIVED_TYPE == null ? "N/A" : e.CASH_RECEIVED_TYPE}
                                  </td>
                                      
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {e.PENDING_REQUISITION == null ? "N/A" : e.PENDING_REQUISITION}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </>
                    )
                  }
                </div>
                </>
            )
          }

          {/* pagination start here */}

          <div className="h-5"></div>

          {data.length > 0 && (
            <div className='flex flex-row space-x-4 justify-center items-end mt-4 mb-3 lg:w-full md:w-3/4 sm:w-96 pr-6'>
              <button disabled={page === 0 && pageSearch === 0 ? true : false} onClick={() => { isSearch ? searchPrevious() : previous() }}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-6 h-6 font-bold ${page === 0 && pageSearch === 0 ? 'text-white' : `text-gray-600`} font-bold`}>
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                </svg>
              </button>

              <p className={`text- text-[18px] font-bold`}>
                Page {isSearch ? pageCountSearch : pageCount} Of {totalPage}
              </p>

              <button disabled={(isSearch ? pageCountSearch : pageCount) === totalPage || data.length === 0} onClick={() => { isSearch ? searchNext() : next() }}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-6 h-6 font-bold ${((isSearch ? pageCountSearch : pageCount) === totalPage || data.length === 0) ? 'text-white' : 'text-gray-500'} font-bold`}>
                  <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                </svg>
              </button>
            </div>
          )}
        </div>
      </div>
    )
  }