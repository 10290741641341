import React, { useState, useEffect } from 'react'
import Datepicker from "react-tailwindcss-datepicker";
import moment from 'moment';
import getMonlyRoutine from '../../services/MonthlyRoutineService';
import Loading from '../loader/Loading';
import { gray500, gray100, red500, gray300, gray400, green100, green600, gray700, gray800, gray200, gray600 } from '../../utils/colors';
import { baseUrl } from '../../utils/path';
const url = `${baseUrl}monthly_attendance_web`;




export default function MonthlyAttendancePage() {

    const currentDate = new Date();

    // Get the year and month of the current date
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1; // Add 1 to get the month number in the correct range (1-12)

    // Create a new Date object for the first day of the month
    const firstDayOfMonth = new Date(year, month - 1, 1);



    const lastDayOfMonth = new Date(year, month, 0);



    const formattedFirstDay = moment(firstDayOfMonth).format("YYYY-MM-DD");
    const formattedLastDay = moment(lastDayOfMonth).format("YYYY-MM-DD");
    // console.log(formattedFirstDay);
    // console.log(formattedLastDay);
    let [pageCount,setPage]=useState(1);
    let [page,setPg]=useState(0);
    let [pageCountSearch,setPageSearch]=useState(1);
    let [pageSearch,setPgSearch]=useState(0);
    const limit = 10;
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [value, setValue] = useState({
        startDate: null,
        endDate: null

    });
    const [isSearch,setIsSearch]=useState(false);

    useEffect(() => {
        const getDMonthlyRtn = async () => {
            const result = await getMonlyRoutine(url, page, limit, formattedFirstDay, formattedLastDay);
            divideAndCeil(result.Total);
           
            setData(result.ATTENDANCE_DETAILS);
            setIsLoading(false);

            // setDataInCSV(result.ATTENDANCE_DETAILS);

           
            // console.log(data);

        };
        getDMonthlyRtn();


    },[] );

    const [totalPage,setTotalPage]=useState(null);

    const divideAndCeil = (number) => {
        console.log(number);
        if (typeof number !== 'number') {
          throw new Error('Input must be a number');
        }
      
        const re= Math.ceil(number / 10);
        setTotalPage(re);
      };



    const next=async()=>{
             
               
        // setPage((page)=>page+10);
        const newPage = page + 10;
        setPg(newPage);
        console.log(`page here ${newPage}`);
        setPage((pre)=>pre+1);
      
      
        setIsLoading(true);
        setData([]);
      
            // const result= await getDailyRoutine(url,newPage,limit);
            const result = await getMonlyRoutine(url, newPage, limit, formattedFirstDay, formattedLastDay);
          
            divideAndCeil(result.Total);
        setData(result.ATTENDANCE_DETAILS);
        // console.log(result.ATTENDANCE_DETAILS);
        setIsLoading(false);
        
        console.log('clicked');
        
        
      
        
    }
    const searchNext=async()=>{
             
               
        // setPage((page)=>page+10);
        const newPage = pageSearch + 10;
        setPgSearch(newPage);
        console.log(`page here ${newPage}`);
        setPageSearch((pre)=>pre+1);
      
      
        setIsLoading(true);
        setData([]);
      
            // const result= await getDailyRoutine(url,newPage,limit);
            const result = await getMonlyRoutine(url, newPage, limit, value.startDate, value.endDate);
            divideAndCeil(result.Total);
           
        setData(result.ATTENDANCE_DETAILS);
        setIsLoading(false);
        // console.log(result.ATTENDANCE_DETAILS);
      
        
        console.log('clicked');
        
        
      
        
    }
  
   
    const previous=async()=>{
     
        // setPage((pre)=>pre-10);
        const newPage = page - 10;
        setPg(newPage);
        console.log(`page here ${newPage}`);
        setPage((pre)=>pre-1);
       
        console.log('clicked');
        setIsLoading(true);
        setData([]);
        
        // const result= await getDailyRoutine(url,newPage,limit);
        const result = await getMonlyRoutine(url, newPage, limit, formattedFirstDay, formattedLastDay);
        
        divideAndCeil(result.Total);
      
        setData(result.ATTENDANCE_DETAILS);
        setIsLoading(false);
        // console.log(result.ATTENDANCE_DETAILS);
    }
    const searchPrevious=async()=>{
     
        // setPage((pre)=>pre-10);
        const newPage = pageSearch - 10;
        setPgSearch(newPage);
        console.log(`page here ${newPage}`);
        setPageSearch((pre)=>pre-1);
       
        console.log('clicked');
        setIsLoading(true);
        setData([]);
        
        // const result= await getDailyRoutine(url,newPage,limit);
        const result = await getMonlyRoutine(url, newPage, limit, value.startDate, value.endDate);
        
        divideAndCeil(result.Total);
        setData(result.ATTENDANCE_DETAILS);
        setIsLoading(false);
        // console.log(result.ATTENDANCE_DETAILS);
    }

  

    const handleValueChange = (newValue) => {
        console.log("start:", moment(newValue.startDate).format("YYYY-MM-DD"));
        console.log("end:", moment(newValue.endDate).format("YYYY-MM-DD"));
        setValue(newValue);
        // setPage((pre)=>0);
       
        
    }

    const searchAttendance=async()=>{
        setIsSearch(true);
        setIsLoading(true);
        setData([]);
        setPg(0);
        if(pageSearch !==0){
            setPgSearch(0);
        }
       
        if(pageCountSearch !==1){
            setPageSearch(1);
            
        }
        const result = await getMonlyRoutine(url, pageSearch!==1?1:pageSearch, limit, value.startDate, value.endDate);
            setIsLoading(false);
            setData(result.ATTENDANCE_DETAILS);
            divideAndCeil(result.Total);
            console.log(`len: ${data.length}`);

    }


    return (
        <div className=' mt-16 bg-white'>

            <div className=' md:w-full w-3/4   bg-white rounded-lg  px-8  flex flex-col items-start'>
                 {/* start header */}
                 <div className=' flex  flex-row lg:justify-between space-x-3 items-center md:p-5 p-2 md:w-full w-3/4 h-16   bg-white'>
                    <h4 className=' text-black font-semibold md:text-lg text-xs md:pl-10 pl-0'>Monthly Attendance</h4>
                    <div className=' flex flex-row space-x-2 '>

                        <Datepicker

                            placeholder='From Date - To Date         '
                            showFooter={true}
                            primaryColor={"green"}
                            showShortcuts={true}
                            value={value}
                            onChange={handleValueChange}
                            displayFormat={"DD/MM/YYYY"}
                            toggleClassName={`absolute bg-buttonColor rounded-r-lg text-white right-0 h-full px-3 text-${gray400} focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed`}
                            // inputClassName="w-full rounded-md focus:ring-0 font-normal bg-green-100 dark:bg-green-900 dark:placeholder:text-green-100" 
                            // containerClassName=" border border-[1px] border-gray-400 w-80 rounded-md" 
                            // toggleClassName=" text-gray-400"
                            inputClassName={`outline-green-600 bg-white lg:w-72 md:w-64 sm:w-60 rounded-r-lg h-full px-3`}
                        // containerClassName="  " 


                        />



                        {/* <button onClick={selectStartDate}>
                  <div className='border  border-gray-300 shadow-sm h-8 w-40 rounded-lg  items-start flex flex-row space-x-2 px-2'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7 text-gray-500 font-semibold">
  <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
</svg>
<p className=' text-gray-500 font-semibold text-sm mt-1'>From</p>

                    </div>
                  </button> */}

                        {/* <div className='border  border-gray-300 shadow-sm h-8 w-40 rounded-lg  items-start flex flex-row space-x-2 px-2'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7 text-gray-500 font-semibold">
  <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
</svg>
<p className=' text-gray-500 font-semibold text-sm mt-1'>To</p>

                    </div> */}

                        <button onClick={searchAttendance} className={`h-8 w-12 rounded-md shadow-sm flex justify-center bg-buttonColor px-2`}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-8 text-white font-bold">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                            </svg>
                        </button>
                        <div className='w-36'></div>

                    </div>
                </div>

                {/* end header */}

                {/* start table header */}

                {/* <div className='mt-2 pl-8 pr-8 bg-gray-200 md:w-full w-1/2 h-10 items-center flex flex-row justify-between space-x-4'>
                    <div className='  h-10   text-gray-500  text-sm  font-bold flex  justify-center items-center  ml-4'>
                        Employee Id
                    </div>
                    <div className='   h-10   text-gray-500  text-sm  font-bold flex  justify-center items-center   '>
                        Employee Name
                    </div>
                    <div className='   h-10   text-gray-500  text-sm  font-bold flex  justify-center items-center  '>
                        Total Working Days
                    </div>
                    <div className='   h-10   text-gray-500  text-sm  font-bold flex  justify-center items-center  '>
                        Total Attendance
                    </div>
                    <div className='   h-10   text-gray-500  text-sm  font-bold flex  justify-center items-center  '>
                        Full Day Leave
                    </div>


                </div> */}


                {/* end table header */}

                {/* start table content */}
                
                    {/* isLoading ?
                        <Loading /> :
                        data.length === 0 ?


                            <div className=' flex justify-center items-center w-full h-[300px]'>

                                <h1 className=' text-red-600 font-bold text-2xl'>No Data Found</h1>

                            </div>

                            :

                            data.map((e, index) =>
                                
                                <div key={index} className='flex flex-col items-start w-full'>
                                    <div className='mt-1  md:w-full w-1/2 h-10 md:h-16 pl-4 pr-16 items-center flex flex-row space-x-14'>
                                        <div className=' w-32  h-10  flex justify-center items-center  text-gray-800  text-xs font-medium ml-4  '>
                                            {e.EMPLOYEE_ID == null ? "N/A" : e.EMPLOYEE_ID}
                                        </div>
                                        <div className=' w-48  h-10  flex justify-center items-center  text-gray-800  text-xs font-medium  '>
                                            {
                                                e.EMPLOYEE_NAME == null ? "N/A" : e.EMPLOYEE_NAME
                                            }
                                        </div>
                                        <div className=' w-40  h-10  flex justify-center items-center  text-gray-800  text-xs font-medium  '>
                                            {
                                                e.TOTAL_WORKING_DAYS == null ? "N/A" : e.TOTAL_WORKING_DAYS
                                            }
                                        </div>
                                        <div className=' w-36  h-10  flex justify-center items-center  text-gray-800  text-xs font-medium  '>
                                            {
                                                e.TOTAL_ATTENDANCE == null ? "N/A" : e.TOTAL_ATTENDANCE
                                            }
                                        </div>
                                        <div className=' w-32  h-10  flex justify-center items-center  text-gray-800  text-xs font-medium  '>
                                            {

                                                (e.TOTAL_WORKING_DAYS != null && e.TOTAL_ATTENDANCE != null) ?
                                                    (e.TOTAL_WORKING_DAYS - e.TOTAL_ATTENDANCE)
                                                    :
                                                    "N/A"


                                            }
                                        </div>


                                    </div>
                                    <div className=' flex flex-row w-full'>
                                        <div className=' w-full h-[0.3px] bg-gray-200 ml-4 mr-8'>

                                        </div>
                                    </div>
                                </div>
                            ) */}


                


                {/* end table content */}

                {
                      isLoading ?
                      <Loading /> :
                    
       
            <div className=' w-full flex justify-center items-center'>
                <table className="border-[0.5px] border-gray-200">
                    <thead className="bg-bgTableHeader text-tableHeaderText shadow-sm h-14">
                        <tr>
                       
                            
                            <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">EMPLOYEE ID</th>
                            <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">EMPLOYEE NAME</th>
                            {/* Add the rest of the columns */}
                            <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">TOTAL WORKING DAYS</th>
                            <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">TOTAL ATTENDANCE</th>
                            <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">FULL DAY LEAVE</th>
                           
                            
                            
                        </tr>
                    </thead>
                    <tbody className=" divide-y divide-borderColor">
                   { 
                 
                   data.length === 0 ?


                       <div className=' flex justify-center items-center w-full h-[300px]'>

                           <h1 className=' text-red-600 font-bold text-2xl'>No Data Found</h1>

                       </div>

                       :
                  
                  data.map((e,index)=>{
                  
                    return(
                        <tr key={index}  className='bg-white'>
                   
                                    <td className="px-6 py-4 whitespace-nowrap">{e.EMPLOYEE_ID == null ? "N/A" : e.EMPLOYEE_ID}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{
                                                e.EMPLOYEE_NAME == null ? "N/A" : e.EMPLOYEE_NAME
                                            }</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{
                                                e.TOTAL_WORKING_DAYS == null ? "N/A" : e.TOTAL_WORKING_DAYS
                                            }</td>
                                    {/* Render the rest of the columns */}
                                    <td className="px-6 py-4 whitespace-nowrap">{
                                                e.TOTAL_ATTENDANCE == null ? "N/A" : e.TOTAL_ATTENDANCE
                                            }</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{

(e.TOTAL_WORKING_DAYS != null && e.TOTAL_ATTENDANCE != null) ?
    (e.TOTAL_WORKING_DAYS - e.TOTAL_ATTENDANCE)
    :
    "N/A"


}</td>
                                   
                                    {/* Render the rest of the columns */}
                                  
                                   
                                </tr>
                    )
                  })
                                
                                }
                           

                    </tbody>
                  
                </table>
          
    </div>
    
    }



                {
                
                    <div  className=' flex flex-row space-x-4 justify-center  items-end mt-4 mb-3 lg:w-full md:w-3/4 sm:w-96  pr-6'>
      <button  disabled={ page===0 && pageSearch===0?true:false} onClick={()=>{isSearch?searchPrevious():previous()}}>
      <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-6 h-6  font-bold  ${page ===0 && pageSearch===0?'text-white':`text-${gray600}`} font-bold`}>
  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
</svg>
      </button>
 <p className={`text-${gray500} text-[18px] font-bold`}>
{isSearch?pageCountSearch:pageCount} Of {totalPage}
    </p> 


            <button disabled={data.length ===0? true:false} onClick={()=>{isSearch?searchNext():next()}}>
            <svg   xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-6 h-6  font-bold  ${data.length ===0?'text-white':`text-${gray600}`} font-bold`}>
  <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
</svg>
            </button>


            </div>}

            <div className='h-8'></div>



            </div>

        </div>
    )
}
