import { baseUrl } from "../../utils/path";


  const RoleAccessService= async(empId,status)=>{
   
   
    const url=`${baseUrl}role_access`;
  
    const response = await   fetch(url,
        {
            method: "POST",
            headers: {
              
              "Content-Type": "application/json",
              'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
            },
            body: JSON.stringify({
               
                  
                 
            }),
          }
        );
     console.log(response);
          const data = await response.json();
        // console.log(data);
          return data;

     
}
export default RoleAccessService
