import { baseUrl } from "../../utils/path";


  

  const CreateIouService= async(empId,iouTypeName,purpose,approverId,iouDate,companyCode,plantCode)=>{
   
   console.log(`${empId} ${iouTypeName} ${purpose} ${approverId} ${iouDate} ${companyCode} ${plantCode}`);
    const url=`${baseUrl}create_iou`;
  
    const response = await   fetch(url,
        {
            method: "POST",
            headers: {
              
              "Content-Type": "application/json",
              'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
            },
            body: JSON.stringify({
                "OBJECT_TYPE_CODE": "IOU",
                "REQUSTED_BY_ID": empId,
                "IOU_TYPE": iouTypeName,
                "IOU_REASON": purpose,
                "APPROVER_ID": approverId,
                "ACCOUNT_OFFICER_ID":approverId,
                "STATUS": "IN PROCESS",
                "IOU_DATE": iouDate,
                "COMPANY_CODE":companyCode,
                "PLANT_CODE":plantCode
            }),
          }
        );
        // if (!response.ok) {
        //     throw new Error("fetching is not successful");
        //   }
          const data = await response.json();
          console.log(`stage: ${data}`);
          return data;

     
}
export default CreateIouService
