import { baseUrl } from "../../../utils/path";


  

  const EditDailyAttendanceService= async(attId,time)=>{

    console.log(`id: ${attId} up ${time}`);

    const url=`${baseUrl}attendance_time_edit`;

    const response = await   fetch(url,
        {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
            },
            body: JSON.stringify({
              "DATETIME":time, 
              "OBJECT_ID": attId
                
            }),
          }
        );
        if (!response.ok) {
            throw new Error("fetching is not successful");
          }
        
          const data = await response.json();
         
          return data;

     
}
export default EditDailyAttendanceService


