import { baseUrl } from "../../utils/path";


const url = `${baseUrl}move_order_report`;

const MoveOrderReportService = async ( EMPLOYEE_ID, COMPANY_CODE, PLANT_CODE, TASK_TYPE, FROM_DATE, TO_DATE, P_OFFSET, P_LIMIT ) => {
    // console.log(`uid: ${userId} lat ${lat}  long ${long} ra${radius} cc${companyCode}  pc${PlantCode}`);
    console.log(`empId:${EMPLOYEE_ID} compCode: ${COMPANY_CODE} pCode: ${PLANT_CODE} tType: ${TASK_TYPE} fDate: ${FROM_DATE} toDate: ${TO_DATE} offset: ${P_OFFSET} limit: ${P_LIMIT}`);

    // console.log(`IN_TIME value: ${IN_TIME}`);
    console.log(
      "P_COMPANY_CODE", COMPANY_CODE.toString(),
        "P_PLANT_CODE", PLANT_CODE.toString(),
        "P_EMPLOYEE_ID", EMPLOYEE_ID || "",
        "P_TASK_TYPE", TASK_TYPE,
        "FROM_DATE", FROM_DATE,
        "TO_DATE", TO_DATE,
        "P_OFFSET", P_OFFSET,
        "P_LIMIT", P_LIMIT
    );

    const response = await fetch(url, {
      method: "POST",
      headers: {
          "Content-Type": "application/json",
          Authorization:
              "Basic " +
              btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
      },
      body: JSON.stringify({
        "P_COMPANY_CODE": COMPANY_CODE.toString(),
        "P_PLANT_CODE": PLANT_CODE.toString(),
        "P_EMPLOYEE_ID": EMPLOYEE_ID.toString(),
        "P_TASK_TYPE": TASK_TYPE,
        "FROM_DATE": FROM_DATE,
        "TO_DATE": TO_DATE,
        "P_OFFSET": P_OFFSET,
        "P_LIMIT": P_LIMIT
      }),
    });
    // if (!response.ok) {
    //     throw new Error("fetching is not successful");
    //   }

    const data = await response.json();
    console.log("service data", data['ATTENDANCE_DETAILS']);
    return data;

};
export default MoveOrderReportService;
