import { baseUrl } from "../../utils/path";



const RequisitionItemInsertionService = async (payment,  reqList) => { 
    const url=`${baseUrl}emp_requisition_item_dtls`;
    console.log(JSON.stringify(reqList));
    const payload = {
        PAYMENY_METHOD:payment,
        data: reqList
     };
    const jsonString = JSON.stringify(payload);
    console.log(jsonString);
    const response = await fetch(url,
      {
        method: "POST",
        headers: {
  
          "Content-Type": "application/json",
          'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
        },
        body: JSON.stringify(
            payload
        ),
      }
    );
    const data = await response.json();
    return data;
  
  }
  export default RequisitionItemInsertionService
  