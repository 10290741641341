import React,{useState,useRef} from 'react'
import FrontDeskAccessService from '../service/FrontDeskAccessService';
import getUserList from '../../services/UsersService';
import { gray500, gray100, red500, gray300, gray400, green100, green600, gray700, gray600, gray200 } from '../../utils/colors';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { baseUrl } from '../../utils/path';
import Loading from '../../components/loader/Loading';
import FrontDeskReportPermissionService from '../service/FrontDeskReportPermissionService';
const userUrl = `${baseUrl}employee_search_by_name_id`;
export default function FrontDeskAccessPage() {
    const [selectedUserName, setSelectedUserName] = useState('');
 const [users, setUsers] = useState([]);
  const [isUsersLoading, setIsUsersLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState({});
  const [searchKey, setSearchKey] = useState({});
  const [userId,setUserId]=useState();
  const [adminId, setAdminId] = useState('');
  const [companyPlantList,setCompanyPlantList]=useState([]);
  const [isLoading,setIsLoading]=useState(false);
  const [isSelectAll, setIsSelectAll] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [empId,setEmpId]=useState('');
  let id, username,selectedUserId;
const usersRef = useRef(null);

 const userSelect=async(e)=>{
    setIsLoading(true);
    setCompanyPlantList([]);
    setSelectedUser(e); 
    selectedUserId = e.EMPLOYEE_ID;
    getCompanyPlant(selectedUserId);
    setEmpId(e.EMPLOYEE_ID);
    //  setUserId(selectedUserId); 
    setSelectedUserName(e.EMPLOYEE_NAME) ;
    setIsLoading(false);
  }

  const getUsers = async () => {
 
    if (!isNaN(searchKey)) {
      id = searchKey;
    }
    else {
      username = searchKey;
    }
    const users = await getUserList(userUrl, id, username);
    setUsers(users.EMPLOYEE_LIST);
    setEmpId(users.EMPLOYEE_LIST[0].EMPLOYEE_ID);
  
    setIsUsersLoading(false);
    setSearchKey('');
    usersRef.current.value = '';
   
  }

 const getCompanyPlant=async(uId)=>{
    const re=await FrontDeskAccessService(uId);
    setCompanyPlantList(re.report_access);
  }

  const showToastError = (message) => {
    toast.error(message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT }); // autoClose is in milliseconds
  };
  const showToastSuccess = (message) => {
    toast.success(message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT }); // autoClose is in milliseconds
  };


  const handleButtonClick = (menuIndex, buttonIndex) => {
    setCompanyPlantList(prevMenuList => {
        const updatedMenuList = [...prevMenuList];
        const updatedMenu = { ...updatedMenuList[menuIndex] };
        const updatedPlantList = [...updatedMenu.PLANT_LIST];

        const updatedButton = { ...updatedPlantList[buttonIndex] };
        updatedButton.IS_ASSOCIATED = updatedButton.IS_ASSOCIATED === 1 ? 0 : 1;
        updatedPlantList[buttonIndex] = updatedButton;

        updatedMenu.PLANT_LIST = updatedPlantList;
        updatedMenuList[menuIndex] = updatedMenu;

        return updatedMenuList;
    });
};


const handleSelectAll = () => {
  if (isSelectAll === null || isSelectAll === false) {
    setIsSelectAll(true);
    selectAll();
  } else {
    if (isSelectAll === true) {
      setIsSelectAll(null);
      // setCompanyPlantList([]);
      handleUnselectAll();
    }
  }
};


const [selectedCompanyPlant, setSelectedCompanyPlant] = useState([]);

// const selectAll = () => {
//     const selectedData = [];

//     setCompanyPlantList(prevMenuList => {
//         const updatedMenuList = prevMenuList.map(menu => {
//             const updatedMenu = { ...menu };
//             updatedMenu.IS_ASSOCIATED = 1;

//             const updatedPlantList = menu.PLANT_LIST.map(plant => {
//                 const updatedPlant = { ...plant, IS_ASSOCIATED: 1 };
//                 const { COMPANY_CODE, PLANT_CODE } = updatedPlant;

                // selectedData.push({
                //     EMPLOYEE_ID: empId,
                //     COMPANY_CODE:COMPANY_CODE,
                //     PLANT_CODE:PLANT_CODE
                // });

//                 return updatedPlant;
//             });

//             updatedMenu.PLANT_LIST = updatedPlantList;
//             return updatedMenu;
//         });

//         setSelectedCompanyPlant(selectedData);

//         return updatedMenuList;
//     });
// };

const selectAll = () => {
  setCompanyPlantList(prevMenuList => {
      const updatedMenuList = prevMenuList.map(menu => {
          const updatedMenu = { ...menu };
          updatedMenu.IS_ASSOCIATED = 1;

          const updatedPlantList = menu.PLANT_LIST.map(plant => {
              return { ...plant, IS_ASSOCIATED: 1 };
          });

          updatedMenu.PLANT_LIST = updatedPlantList;
          return updatedMenu;
      });

      return updatedMenuList;
  });
  const selectedData = [];
  for(let i=0;i<companyPlantList.length;i++){
    for(let j=0;j<companyPlantList[i].PLANT_LIST.length;j++){
      selectedData.push({
        EMPLOYEE_ID: empId,
        COMPANY_CODE:companyPlantList[i].COMPANY_CODE,
        PLANT_CODE:companyPlantList[i].PLANT_LIST[j].PLANT_CODE
    });
    }
  }
  setSelectedCompanyPlant(selectedData);
};


const handleUnselectAll = () => {
  setCompanyPlantList(prevMenuList => {
      const updatedMenuList = prevMenuList.map(menu => {
          const updatedMenu = { ...menu };
          updatedMenu.IS_ASSOCIATED = 0;

          const updatedPlantList = menu.PLANT_LIST.map(plant => {
              return { ...plant, IS_ASSOCIATED: 0 };
          });

          updatedMenu.PLANT_LIST = updatedPlantList;
          return updatedMenu;
      });

      return updatedMenuList;
  });
  setSelectedCompanyPlant([]);
};



const permissionGrantRevoke=async(companyCode,plantCode)=>{
    // setIsLoading(true);
    const re=await FrontDeskReportPermissionService(empId,companyCode,plantCode);
   if(re.Status===200){
    showToastSuccess(re.Message);
   }
   else{
    showToastError(re.Message);
   }
  //  getCompanyPlant(empId);
  //   setIsLoading(false);
}

const save=async()=>{
  setIsLoading(true);
  for(let i=0;i<selectedCompanyPlant.length;i++){
    const re=await FrontDeskReportPermissionService(empId,selectedCompanyPlant[i].COMPANY_CODE,selectedCompanyPlant[i].PLANT_CODE);
    
  }
  showToastSuccess("Saved Successfully");
  getCompanyPlant(empId);
  setIsSelectAll(false);
  setSelectedCompanyPlant([]);
    setIsLoading(false);

}



  return (
    <div className=' my-20 px-8'>
        <p className=' text-lg font-semibold text-black'>Front Desk Report Access Management</p>
        <div className='h-4'></div>
        <ToastContainer/>
        <div className="modal-action w-full">
              <label htmlFor="my-modal-16" className="w-full border-none bg-transparent hover:bg-transparent">
                <div className={` w-full h-12 border-[0.2px] border-${gray300} rounded-md flex text-left justify-center items-center`}>
                  <p className={`w-full  pl-6 text-${gray400} text-[16px]`}>{selectedUserName === '' ? "Select user" : selectedUserName}</p>
                </div>
              </label>
            </div>
            <div className='h-4'></div>
            <p className=' text-[18px] text-black font-semibold'>Roles</p>
            <div className='h-4'></div>
            <button   
            onClick={handleSelectAll}   
            className=' flex flex-row space-x-2 items-center'>
            <div className={`${isSelectAll===true?"bg-green-600 ":"bg-white border-[1px] border-gray-400"} h-4 w-4 rounded-md flex justify-center items-center`}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-3 h-3 text-white">
  <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
</svg>

            </div>
            <p className=' text-sm font-medium  text-gray-400'>Selelct All</p>
            </button>
            <div className='h-4'></div>
            {
              isSelectAll
              ?
              <button
            onClick={save}
            className=' py-1 px-6 text-white font-semibold bg-green-600 rounded-md shadow-sm'>SAVE</button>
            :
            null
            }
            <div className='h-4'></div>
            {
                isLoading ?
                <div className=' w-full flex h-64 justify-center items-center'>
                    <Loading/>
                </div>
                :

                <div className='flex flex-col items-start'>
                <div className='h-6'></div>
                <div className='w-full h-14 flex flex-row space-x-4 items-center rounded-t-md border-[0.5px] border-gray-200 '>
                    <div className='w-44 h-full text-sm font-mon text-black font-semibold flex justify-center items-center'>Company</div>
                    
                    <div className="flex-1 h-full text-sm font-mon text-black font-semibold flex justify-center items-center">Plant List</div>
                    <div className='w-14 h-full text-sm font-mon text-black font-semibold '></div>
                </div>
                    
                 {   companyPlantList.map((com, k) => (
                    <div key={k} className={` px-8 w-full py-4 flex flex-row space-x-4 ${k === com.length - 1 ? "rounded-b-md" : ""} items-start border-r-[0.5px] border-b-[0.5px] border-l-[0.5px] border-gray-200`}>
                        <div className='w-44 h-full flex justify-start items-start text-start text-sm font-bold text-blackColor'>{com.COMPANY_NAME}({com.COMPANY_CODE})</div>

                        <div className='flex-1 flex-col items-start space-y-10'>
                            {/* <div className='flex-1 h-full flex flex-row space-x-4 items-start'>
                                <div className="w-44 h-full flex justify-center items-center text-sm font-mon font-medium text-blackColor">
                                    Menu Mobile
                                </div>
                                <div className="flex-1 h-full flex justify-center items-center text-sm font-mon font-medium text-blackColor">
                                    <div className='grid grid-cols-4 gap-6'>
                                        {menu.Menu_Mobile.map((e, i) => (
                                            <button
                                                onClick={() =>{ handleButtonClick(k, i,"Menu_Mobile");roleInsertDelete(menu.ROLE_ID,e.MENU_ID)}}
                                                key={i} className='flex text-start flex-row space-x-2 items-center'>
                                                <div className={`rounded-[4px] w-4 h-4 ${e.IS_ASSOCIATED === 1 ? "bg-green-600 border-none" : "bg-white border-[0.1px] border-gray-300"}  flex justify-center items-center`}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-3 h-3 text-white">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                                    </svg>
                                                </div>
                                                <p className='text-xs  font-medium text-black'>{e.MENU_NAME}</p>
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            </div> */}
                            <div className='flex-1 h-full flex flex-row space-x-4 items-start'>
                                <div className="w-32 h-full flex justify-center items-center text-sm font-mon font-medium text-blackColor">
                                
                                </div>
                                <div className="flex-1 h-full flex justify-start items-start text-sm font-mon font-medium text-blackColor">
                                    <div className='grid grid-cols-4 gap-6'>
                                        {com.PLANT_LIST.map((f, j,) => (
                                            <button
                                           
                                                onClick={() => {
                                                    handleButtonClick(k, j);
                                                    permissionGrantRevoke(com.COMPANY_CODE,f.PLANT_CODE);
                                                    //  roleInsertDelete(menu.ROLE_ID,f.MENU_ID)
                                                    }}
                                                key={j} className='flex text-start flex-row space-x-2 items-center'>
                                                <div className={`rounded-[4px] w-4 h-4 ${f.IS_ASSOCIATED === 1 ? "bg-green-600 border-none" : "bg-white border-[0.1px] border-gray-300"}  flex justify-center items-center`}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-3 h-3 text-white">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                                    </svg>
                                                </div>
                                                <p className='text-xs  text-start font-medium text-black'>{f.PLANT_NAME}({f.PLANT_CODE})</p>
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                </div>

            }





        {/* start select user modal */}
      <input type="checkbox" id="my-modal-16" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box h-3/4 bg-white">
          <label onClick={(e) => {
            setUsers([]); setSearchKey('');
            usersRef.current.value = '';
          }} htmlFor="my-modal-16" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
          <h3 className="font-bold text-lg">Select User</h3>
          <div className='w-full flex flex-col justify-center items-center my-3'>
            <div className="form-control">
              <div className="input-group ">
                <input ref={usersRef} onChange={(e) => { setSearchKey(e.target.value) }} onKeyPress={(e) => { if (e.key === 'Enter') getUsers(); }} type="text" placeholder="Search…" className="input input-bordered w-72 focus:outline-none bg-white" />
                <button onClick={getUsers} className="btn btn-square">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" /></svg>
                </button>
              </div>
            </div>

            {

              users.length === 0 ?
                <p className=' mt-16 font-medium text-lg'>Search Name Here</p> :
                isUsersLoading ?
                  <p>loding...</p> :

                  users.map((e, index) =>
                    <div key={index} onClick={()=>{userSelect(e)}} className="modal-action w-[336px]">
                      <label htmlFor="my-modal-16" className="w-full border-none bg-transparent hover:bg-transparent">
                        <div className={` w-full h-12 shadow-md  rounded-md flex text-left justify-center items-center`}>
                          <p className={`w-full  pl-6 text-${gray600} text-[16px]`}>{`${e.EMPLOYEE_NAME} (${e.POSITION_NAME})`}</p>
                        </div>
                      </label>
                    </div>
                  )

            }

          </div>

        </div>
      </div>

      {/* end select user modal */}

    </div>
  )
}
