export const baseUrl="https://weapp.weplbd.com:8080/ords/weplappsdev/dev/hrlm/";

// export const baseUrl="https://weapp.weplbd.com:8080/ords/weplappsprod/prod/hrlm/";

// export const baseUrl="http://118.179.60.93:8085/ords/wepl/hrlm/";

// export const baseUrl="http://190.92.217.21:8080/ords/weplappsdev/dev/hrlm/";

// export const baseUrl="http://devapp.weplbd.com:8085/ords/wepl/hrlm/";

// http://devapp.weplbd.com:8085/ords/wepl/hrlm/user_login


export const sapBaseUrl="https://wsaps4h03.weplbd.com:5201/sap/opu/odata/SAP";

// http://190.92.217.21:8080/ords/weplappsdev/dev/hrlm/

//http://118.179.60.93:8085/ords/wepl/hrlm/

//http://190.92.217.21:8080/ords/weplappsprod/prod/hrlm/

//"http://wsaps4h01.weplbd.com:8001/sap/opu/odata/SAP"
//"http://wsaps4h03.weplbd.com:8001/sap/opu/odata/SAP"