import { Grid } from '@mui/material'
import React from 'react'
import AppWebsiteVisits from '../../mui/chart/AppWebsiteVisits'
import AppConversionRates from '../../mui/chart/AppConversionRates'

export default function Home() {
  return (
    <div className='  my-16 mx-8 '>
      <div className=' h-6'></div>
      <div className=' w-full h-screen flex justify-center items-center'>
        <img src="/images/logo.png" alt="logo" className=' w-96 h-96' />
      </div>
      {/* <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates
              title="Daily Meal Consumption"
              subheader="(+3%) than last month"
              chartData={[
                { label: 'IT', value: 200 },
                { label: 'SAP', value: 230 },
                { label: 'Audit', value: 248 },
                { label: 'SCM', value: 270 },
                { label: 'Delivery', value: 340 },
                { label: 'Sales', value: 380 },
                { label: 'Marketing', value: 490 },
                { label: 'HR', value: 800 },
                { label: 'PMO', value: 900 },
                { label: 'Admin', value: 1080 },
              ]}
            />
          </Grid>
         
      <div className=' h-6'></div>
    
    <Grid item xs={12} md={6} lg={6}>
            <AppWebsiteVisits
              title="Monthly Absent"
              subheader="(+9%) than last year"
              chartLabels={[
                '01/01/2022',
                '02/01/2022',
                '03/01/2022',
                '04/01/2022',
                '05/01/2022',
                '06/01/2022',
                '07/01/2022',
                '08/01/2022',
                '09/01/2022',
                '10/01/2022',
                '11/01/2022',
              ]}
              chartData={[
                {
                  name: 'Admin',
                  type: 'column',
                  fill: 'solid',
                  data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
                },
                {
                  name: 'Audit',
                  type: 'area',
                  fill: 'gradient',
                  data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
                },
                {
                  name: 'Supply Chain',
                  type: 'line',
                  fill: 'solid',
                  data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
                },
              ]}
            />
          </Grid> */}
          
    </div>
  )
}
