import { baseUrl } from "../../utils/path";

const LeaveDateCheckService = async(id, fromDate, toDate) => {

  const url = `${baseUrl}check_appl_by_dates`;

  console.log("id", id)
  console.log("fromDate: ", fromDate)
  console.log("toDate: ", toDate)
    
  const response = await fetch(url,
      {
          method: "POST",
          headers: {
            
            "Content-Type": "application/json",
            'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
          },
          body: JSON.stringify({
              "EMPLOYEE_ID": id,
              "FROM_DATE": fromDate,
              "TO_DATE": toDate
          }),
        }
      );
      // if (!response.ok) {
      //     throw new Error("fetching is not successful");
      //   }
        const data = await response.json();
        console.log(`users: ${data}`);
        return data;

   
}
export default LeaveDateCheckService;
