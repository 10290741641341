import React, { createContext, useState, useContext } from 'react';

const Requisition = createContext();

export const RequisitionProvider = ({ children }) => {
  const [requisitionPageNo, setRequisitionPageNo] = useState(1);
  const [details,setDetails]=useState({});
  const [iouPageNo, setIouPageNo] = useState(1);
  const [isDelete,setIsDelete]=useState(false);
  const [isPopUp,setIsPopUp]=useState(false);
  const [deleteRowId, setDeleteRowId] = useState(null);

  return (
    <Requisition.Provider value={{ requisitionPageNo, setRequisitionPageNo, details, setDetails, isDelete, setIsDelete, isPopUp, setIsPopUp, deleteRowId, setDeleteRowId }}>
      {children}
    </Requisition.Provider>
  );
};

export const useRequisitionContext = () => {
  return useContext(Requisition);
};