import React from 'react'
import { useHomePageContext } from '../../components/home_component/HomePageContext';
import { useState } from 'react';
import { useIouContext } from '../context/IouContext';
import { useEffect } from 'react';
import ApproverHierarchyListService from '../../move_order/service/ApproverHierarchyListService';
import IouItemDetailsService from '../service/IouItemDetailsService';
import AdminInfoService from '../../services/AdminInfoService';
import { baseUrl } from '../../utils/path';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import Loading from '../../components/loader/Loading';
import MyIouService from '../service/MyIouService';
import numeral from 'numeral';
import DeleteApplicationService from '../service/DeleteApplicationService';
import IouDetailsPopup from './IouDetailsPopup';
import { usePDF } from 'react-to-pdf';
import moment from 'moment';
const infoUrl = `${baseUrl}my_info`;


export default function IouPage() {
  const {menu,setMenu}=useHomePageContext();
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);

  // iou start
  const [employeeId, setEmployeeId] = useState('');
  const [iouList, setIouList]=useState([]);
  const [isLoading,setIsLoading]=useState(false);
  const [designation, setDesignation] = useState('');
  const [empId, setEmpId] = useState('');
  const [joiningDate, setJoiningDate] = useState('');
  const [iouItemDetails, setIouItemDetails] = useState([]);
  const [approverList, setApproverList] = useState([]);
  const { setMovePageNo,isDelete,setIsDelete,isPopUp,setIsPopUp,setDetails,details, deleteRowId, setDeleteRowId } = useIouContext();
  const { toPDF, targetRef } = usePDF({filename: `MY_IOU_${moment(details.IOU_DATE).format('DD/MM/yyyy')}.pdf`});

  // const {iouPageNo, setIouPageNo,setDetails}=useIouContext();
  // let [pageCount,setPage]=useState(1);
  // let [page,setPg]=useState(0);
  // const [isEmpty,setIsEmpty]=useState(false);
   
   
    // const setData = (val) => {
    //     setDetails(val);
    //     // setIouPageNo(2);
    // }

  useEffect(()=>{
    const eid = localStorage.getItem('empId');
    setEmployeeId(eid);
    getMyIouProcessing(eid);
    // getMyIouApproved(eid);
    // getMyIouRejected(eid);
    getHierarchy(details.REQUSTED_BY_ID, details.OBJECT_TYPE_CODE ,details.IOU_ID);
  },[]); 
    
  const getMyIouProcessing = async (id) => {
    setIsLoading(true);
    const re=await MyIouService(id, "");
    console.log("Details:", re.iou_details)
    setIouList(re.iou_details);
    setIsLoading(false);
  }

  useEffect(() => {
    getAll();

  }, []);

  const getAll = async () => {
    setIsLoading(true);
    const id = localStorage.getItem('userId');
    getAdminInfo(id);
    getItem();
    // console.log("Hierachy datA: ", details);
    // getHierarchy(details.REQUSTED_BY_ID, "IOU", details.IOU_ID);
    setIsLoading(false);
}

  const getHierarchy = async (id, module, object) => {
    setIsLoading(true);
    const re = await ApproverHierarchyListService(id, module, object);
    const sortedData = re.APPROVER_LIST.sort((a, b) => a.STAGE_SEQ - b.STAGE_SEQ);
    console.log("approverList: ", sortedData);
    setApproverList(sortedData);
    setIsLoading(false);
}

// const getItem = async (item) => {
//     const re = await IouItemDetailsService(item.IOU_ID);
//     setIouItemDetails(re.iou_items);
// }

const getItem = async (item) => {
  if (item && item.IOU_ID) {
      const re = await IouItemDetailsService(item.IOU_ID);
      setIouItemDetails(re.iou_items);
  } else {
      // Handle the case where item or item.IOU_ID is undefined
      console.error("Item or item.IOU_ID is undefined");
  }
}

const getAdminInfo = async (uid) => {
    const admin = await AdminInfoService(infoUrl, uid);
    // setAdminDetails(admin.EMP_DETAILS);
    const eId = admin.EMP_DETAILS[0].EMPLOYEE_ID;

    const designation = admin.EMP_DETAILS[0].DESIGNATION;
    const joiningDate = admin.EMP_DETAILS[0].JOINING_DATE;
    setEmpId(eId);
    setDesignation(designation);
    setJoiningDate(joiningDate);
}

  // const getMyIouApproved = async (id) => {
  //   setIsLoading(true);
  //   const re=await MyIouService(id, "APPROVED");
  //   setIouList(re.iou_details);
  //   setIsLoading(false);
  // }

  // const getMyIouRejected = async (id) => {
  //   setIsLoading(true);
  //   const re=await MyIouService(id, "REJECTED");
  //   setIouList(re.iou_details);
  //   setIsLoading(false);
  // }

    // iou end


    function test(n) {
      if (n < 0)
        return false;
     const  single_digit = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine']
    const   double_digit = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen']
    const   below_hundred = ['Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety']
      if (n === 0) return 'Zero'
      function translate(n) {
        let  word = ""
          if (n < 10) {
              word = single_digit[n] + ' '
          }
          else if (n < 20) {
              word = double_digit[n - 10] + ' '
          }
          else if (n < 100) {
            const  rem = translate(n % 10)
              word = below_hundred[(n - n % 10) / 10 - 2] + ' ' + rem
          }
          else if (n < 1000) {
              word = single_digit[Math.trunc(n / 100)] + ' Hundred ' + translate(n % 100)
          }
          else if (n < 1000000) {
              word = translate(parseInt(n / 1000)).trim() + ' Thousand ' + translate(n % 1000)
          }
          else if (n < 1000000000) {
              word = translate(parseInt(n / 1000000)).trim() + ' Million ' + translate(n % 1000000)
          }
          else {
              word = translate(parseInt(n / 1000000000)).trim() + ' Billion ' + translate(n % 1000000000)
          }
          return word
      }
    const   result = translate(n) 
      return result.trim()+' tk only.'
  }

    // const formatDate = (inputDate) => {
    //   const options = { year: 'numeric', month: 'short', day: 'numeric' };
    //   const date = new Date(inputDate);
    //   return date.toLocaleDateString('en-US', options);
    // }
  
    // const convertDate = (inputDate) => {
    //   // const months = [
    //   //   "January", "February", "March", "April", "May", "June",
    //   //   "July", "August", "September", "October", "November", "December"
    //   // ];
    
    //   const date = new Date(inputDate);
    //   const day = date.getUTCDate();  // Use getUTCDate to get the day in UTC
    //   // const monthName = months[date.getUTCMonth()];  // Use getUTCMonth to get the month in UTC
    //   const month = date.getUTCMonth() + 1; // Months are zero-based, so we add 1
    //   const year = date.getUTCFullYear();  // Use getUTCFullYear to get the year in UTC
    
    //   return `${day}/${month}/${year}`;
    // };

    // const convertDate = (dateString) => {
    //   const dateObject = new Date(dateString);
    //   const day = String(dateObject.getUTCDate()).padStart(2, '0');
    //   const month = String(dateObject.getUTCMonth() + 1).padStart(2, '0');
    //   const year = dateObject.getUTCFullYear();
  
    //   return `${day}/${month}/${year}`;
    // };
    
    // const convertTo12HourFormat = (dateString) => {
    //   const options = {
    //     year: 'numeric',
    //     month: 'long',
    //     day: 'numeric',
    //     hour: 'numeric',
    //     minute: 'numeric',
    //     second: 'numeric',
    //     hour12: true,
    //     timeZone: 'UTC'
    //   };
    
    //   const date = new Date(dateString);
    //   return date.toLocaleString('en-US', options);
    // };
    
    // // Example usage
    // const inputDate = "2023-09-07T17:00:00Z";
    // const formattedDate = convertTo12HourFormat(inputDate);
    // console.log(formattedDate);

    const[pdfData, setPdfData] = useState('');

    // const handleRowClick = (item) => {
    //   setSelectedItem(item);
    //   setPdfData(item);  // save value for pdf
    //   getItem(item);
    //   console.log("details item select: ", item)
    //   getHierarchy(item.REQUSTED_BY_ID, item.OBJECT_TYPE_CODE ,item.IOU_ID);
    // };

    // row click function then see details
  // const handleRowClick = (item) => {
  //   setIsLoading(true);
  //   setSelectedItem(item); //aita k bad dibo
  //   setDetails(item);
  //   setPdfData(item);
  //   setIsPopUp(true);
  //   getItem(item);
  //   getHierarchy(item.REQUSTED_BY_ID, "IOU", item.IOU_ID);
  //   setIsLoading(false);
  // };

  const handleRowClick = async (item) => {
    setIsLoading(true);
  
    await setSelectedItem((prevSelectedItem) => {
      if (prevSelectedItem) {
        // Do cleanup for the previously selected item
        setDetails();
        setPdfData('');
        setIsPopUp(false);
        getItem();
        getHierarchy();
      }
  
      // Set the new selected item
      setDetails(item);
      setPdfData(item);
      setIsPopUp(true);
      getItem(item);
      getHierarchy(item.REQUSTED_BY_ID, "IOU", item.IOU_ID);
  
      return item; // Return the new selected item
    });
  
    setIsLoading(false);
  };
  

  const handleRowDelete = async () => {
    setIsLoading(true);
    console.log("Deleted row: ");
    console.log("Deleted Item: ", deleteRowId);
    const result = await DeleteApplicationService(empId, "IOU_REQUISITION", deleteRowId);
    getMyIouProcessing(employeeId);
    setIsDelete(false);
    setIsPopUp(false);
    setIsLoading(false);
  }
  
  // Function to close details popup
  const closeDetailsPopup = () => {
    setSelectedItem(null);
  };

  const handleStatus = async (status) => {
    setIsLoading(true);
    const re = await MyIouService(employeeId, status);
    console.log("Details:", re.iou_details)
    setIouList(re.iou_details);
    setIsLoading(false);
  }

  const {setIouPageNo}=useIouContext();

  const goTo=()=>{
    setIouPageNo(2);
  }

  return (
    <div className='my-20 mx-6'>
      <div className='w-full'>
        <p className=" text-black text-lg font-semibold">
          IOU
        </p>

        <div className="h-5"></div>

        <div className=' w-96 flex flex-row space-x-10 items-center'>
            <button
            onClick={()=>{goTo()}}
            className=' h-12 px-4 bg-buttonColor text-white rounded-md flex items-center space-x-1'>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
              </svg>

              <p>Add IOU</p>
            </button>
            {/* <button
              onClick={()=>{setMenu('mmv')}}
            className=' py-2 px-4 bg-green-500 text-white rounded-md'>
                My IOU
            </button> */}
        </div>
      </div>

        {/* <div className=' w-96 flex flex-row space-x-10 items-center'>
            <button
            onClick={()=>{setMenu('ciou')}}
            className=' py-2 px-4 bg-green-500 text-white rounded-md'>
                Create IOU
            </button>
            <button
              onClick={()=>{setMenu('miou')}}
            className=' py-2 px-4 bg-green-500 text-white rounded-md'>
                My IOU
            </button>
        </div> */}

      <div className="h-6"></div>

      <div className="flex items-center space-x-2">
        <select
          placeholder='Sort By Status' className="py-2 w-48 focus:outline-none text-gray-500 rounded-md border-[0.5px] hover:border-gray-400 px-3 bg-white"
          onChange={(e) => {handleStatus(e.target.value); setSelectedStatus(e.target.value) }}
          value={selectedStatus}
        >
          <option value="">ALL</option>
          <option value="IN PROCESS">Pending</option>
          <option value="APPROVED">Approved</option>
          <option value="REJECTED">Rejected</option>
        </select>

        <div className=''>
          {
            details.STATUS==="APPROVED" ?
              <button onClick={() => toPDF()} className='py-2 px-4 rounded-md border-[0.5px] font-medium text-white bg-buttonColor flex items-center space-x-1'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                </svg>
                
                <p>Download PDF</p>
              </button>
            :
            <div></div>
          }
        </div>
      </div>

      { isLoading ? (
        <Loading />
        ) :  (
        <div>
          <div className="h-6"></div>
            
            <p className=" text-black text-lg font-semibold">
              My IOU
            </p>
          
          <div className="h-4"></div>
          {/* { !selectedItem && ( */}

          {
            iouList.length === 0 ? (
              <div className='flex flex-row justify-center items-center text-center w-full h-screen'>
                <h1 className={`text-red-500 text-center w-full font-bold text-2xl`}>No Data Found</h1>
              </div>
            ) : (
              iouList.filter((e) => !selectedStatus || e.STATUS === selectedStatus).length === 0 ? (
                <div className='h-[400px] w-full text-center flex items-center justify-center'>
                  <h1 className={`text-red-500 text-center w-full font-bold text-2xl`}>No Data Found</h1>
                </div>
            ) : (

              <table className={`w-full ${isPopUp ? 'lg:w-4/6' : ''} border-[0.5px] border-gray-200`}>
                <thead className="bg-bgTableHeader text-tableHeaderText shadow-sm h-14">
                  <tr>
                    <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                      NAME
                    </th>

                    <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                      DATE
                    </th>
                          
                    <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                      AMOUNT
                    </th>
                          
                    <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                      STATUS
                    </th>
                  </tr>
                </thead>
                  
                {/* {
                  myMoveOrderList.length === 0 ? (
                    <div className='flex flex-row justify-center items-center text-center w-full h-screen'>
                      <h1 className={`text-red-500 text-center w-full font-bold text-2xl`}>No Data Found</h1>
                    </div>
                  ) : (
                    myMoveOrderList.filter((e) => !selectedStatus || e.AUTHORIZATION_STATUS === selectedStatus).length === 0 ? (
                      <div className='h-[400px] w-full text-center flex items-center justify-center'>
                        <h1 className={`text-red-500 text-center w-full font-bold text-2xl`}>No Data Found</h1>
                      </div>
                  ) : ( */}
                  <tbody className="w-full divide-y divide-borderColor">
                    {iouList.filter((e) => !selectedStatus || e.STATUS === selectedStatus).map((e, index) => (
                      <tr key={index} className="bg-white cursor-pointer" onClick={() => handleRowClick(e)}>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {e.EMPLOYEE_NAME == null ? "N/A" : e.EMPLOYEE_NAME}
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap">
                          {e.IOU_DATE == null ? "N/A" : e.IOU_DATE}
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap">
                          {e.TOTAL_AMOUNT == null ? "N/A" : e.TOTAL_AMOUNT}
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap">
                          {e.STATUS == null ? "N/A" : e.STATUS}
                        </td>
                        {/* Uncomment the section below if needed */}
                        {/* 
                        <td className="px-6 py-4 whitespace-nowrap">
                          {e.AUTHORIZATION_STATUS === 'IN PROCESS' ? (
                            <span className="text-[#FDCA4D]">Pending</span>
                          ) : e.AUTHORIZATION_STATUS === 'APPROVED' ? (
                            <span className="text-[#2D833F]">Approved</span>
                          ) : e.AUTHORIZATION_STATUS === 'REJECTED' ? (
                            <span className="text-[#E20000]">Rejected</span>
                          ) : (
                            <span className="text-gray-500">N/A</span>
                          )}
                        </td>
                        */}
                      </tr>
                    ))}
                  </tbody>
              </table>
            )
          )}

          {/* Display details popup if a row is clicked */}
          { isPopUp && (
            <IouDetailsPopup />
          )}

          {isDelete && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white px-6 py-8 rounded-xl w-96">
                <div className="text-center w-full">
                  <div className=' flex justify-center mb-5'>
                    <img src="/images/delete.png" alt="delete" className='w-12 h-12' />
                  </div>

                  <h3 className='font-semibold text-lg'>Warning !!!</h3>
                  <p className="text-lg mb-4">Are you sure you want to delete?</p>
                </div>
                <div className="flex justify-between items-center">
                  <button
                    className="px-4 w-36 py-2 mr-2 text-gray-600 bg-white border-[0.5px] border-gray-700 rounded-lg"
                    onClick={() => setIsDelete(false)}
                  >
                    No
                  </button>

                  <button 
                    className="px-4 w-36 py-2 bg-red-500 text-white rounded-lg"
                    onClick={handleRowDelete}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          )}


          {/* for pdf */}

          {
            pdfData.STATUS==='APPROVED' ?
              <div ref={targetRef} id='pdf' className='w-3/4 bg-white py-8 pl-16 pr-16'>
                <p className=' w-full flex  justify-center items-center text-2xl font-pristina italic text-black'>{pdfData.COMPANY_NAME} ({pdfData.COMPANY_CODE})</p>
                
                <div className='h-4'></div>
                
                <div className=' text-black font-bold text-xl w-full justify-center items-center flex'>IOU</div>

                <div className='h-4'></div>
                
                <div className=' w-full border-2 border-black'>
                  <div className=' w-full flex flex-row items-center'>
                    <div className='h-10 flex px-2 text-black font-bold justify-start flex-1 border-b-2 border-r-2 border-black'>
                      <p>Applicant Name: {pdfData.EMPLOYEE_NAME} ({empId})</p>
                        
                      <div className='h-3'></div>

                    </div>
                      
                    <div className='h-10 flex px-2 text-black font-bold justify-start w-60 border-b-2 border-black'>
                      <p>Application Date: {pdfData.IOU_DATE}</p>
                        
                      <div className='h-3'></div>
                    </div>
                  </div>

                  <div className='w-full flex flex-row items-center'>
                    <div className='h-10 flex px-2 text-black font-bold justify-start flex-1 border-b-2 border-r-2 border-black'>
                      <p>Designation: {designation}</p>
                      <div className='h-3'></div>
                    </div>
                    
                    <div className='h-10 flex px-2 text-black font-bold justify-start w-60 border-b-2 border-black'>
                      <p>IOU No: {pdfData.IOU_ID}</p>
                      <div className='h-3'></div>
                    </div>
                  </div>
                  
                  <div className=' w-full flex flex-row items-center'>
                    <div className='h-10 flex px-2 text-black font-bold justify-start flex-1 border-b-2 border-r-2 border-black'>
                      <p>Company Name: {pdfData.COMPANY_NAME} ({pdfData.COMPANY_CODE})</p>
                      
                      <div className='h-3'></div>
                    </div>
                    
                    <div className='h-10 flex px-2 text-black font-bold justify-start w-60 border-b-2 border-black'>
                      <p>IOU Type: {pdfData.IOU_TYPE}</p>
                      
                      <div className='h-3'></div>
                    </div>
                  </div>

                  <div className='w-full flex flex-row items-center'>
                    <div className='h-10 flex px-2 text-black font-bold justify-start flex-1 border-b-2 border-r-2 border-black'>
                      <p>Plant Name: {pdfData.PLANT_NAME}</p>
                      
                      <div className='h-3'></div>
                    </div>
                    
                    <div className='h-10 flex px-2 text-black font-bold justify-start w-60 border-b-2 border-black'>
                      <p>Plant Code: {pdfData.PLANT_CODE}</p>
                      
                      <div className='h-3'></div>
                    </div>
                  </div>
                    
                  <div className='w-full flex flex-row items-center'>
                    <div className='h-10 flex px-2 text-black font-bold justify-center flex-1 border-b-2 border-r-2 border-black'>
                      <p>Payment Description</p>
                      <div className='h-3'></div>
                    </div>
                    
                    <div className='h-10 flex px-2 text-black font-bold justify-center w-60 border-b-2 border-black'>
                      <p>Taka</p>
                      <div className='h-3'></div>
                    </div>
                  </div>

                  {
                    iouItemDetails.map((e, index) =>
                      <div key={index} className='w-full flex flex-row items-stretch'>
                        <div className='flex pt-1 pb-3 px-2 text-black font-bold justify-start flex-1 border-b-2 border-r-2 border-black'>
                          <p>{e.ITEM_DESC}</p>
                          
                          <div className='h-3'></div>
                        </div>
                        
                        <div className='flex items-center px-2 text-black font-bold justify-center w-60 border-b-2 border-black'>
                          <p>{numeral(e.ITEM_AMOUNT).format('0,0')} tk</p>
                          <div className='h-3'></div>
                        </div>
                      </div>
                    )
                  }

                  <div className=' w-full flex flex-row items-center'>
                    <div className='h-10 flex  px-2 text-black font-bold justify-center flex-1 border-b-2 border-r-2 border-black'>
                      <p>Total Amount</p>
                        <div className='h-3'></div>

                    </div>
                    
                    <div className='h-10 flex px-2 text-black font-bold justify-center w-60 border-b-2 border-black'>
                      <p>{numeral(pdfData.TOTAL_AMOUNT).format('0,0') } tk</p>
                      
                      <div className='h-3'></div>
                    </div>
                  </div>

                  <div className='w-full flex flex-row items-center'>
                    <div className='h-10 flex px-2 text-black font-bold justify-center w-32  border-b-2  border-black'>
                      <p>Amount in Words: </p>
                      <div className='h-3'></div>
                    </div>
                    
                    <div className='h-10 flex pr-2 text-black font-bold justify-start flex-1 border-b-2 border-black'>
                      <p>{test(pdfData.TOTAL_AMOUNT)} </p>
                      
                      <div className='h-5'></div>
                    </div>
                  </div>

                    <div className=' w-full  flex flex-row items-center'>
                      <div className=' flex-1 flex flex-row justify-between items-center'>
                        <div className='flex h-24  px-2 text-black font-bold justify-start flex-col flex-1 border-b-2 border-r-2 border-black'>
                          <p className=' text-center'>Previous Due Amount:</p>
                          {
                            pdfData.DUE_AMOUNT ?
                              <p className='  '>{numeral(pdfData.DUE_AMOUNT).format('0,0')} tk</p> : <p className='  '>N/A</p>

                          }
                          
                          <div className='h-3'></div>
                        </div>
                        
                        <div className='flex h-24 px-2 text-black font-bold justify-start flex-col flex-1 border-b-2 border-r-2 border-black'>
                          <p className=' text-center'>Previous advance withdrawal 
                                more than 30 days:</p>
                          { pdfData.MORE_DAYS_30 ? <p className=''>{pdfData.MORE_DAYS_30.includes('null') ? "N/A" : pdfData.MORE_DAYS_30}</p>

                            : <p className=' '>N/A</p>
                          }
                          
                          <div className='h-3'></div>

                        </div>
                      </div>
                      
                      <div className=' flex  h-24 w-60 px-2 text-black font-bold justify-start flex-col  border-b-2  border-black'>
                        <p className=' text-center'>Previous advance withdrawal
                                less than 30 days:
                        </p>
                        
                        { pdfData.LESS_DAYS_30 ? <p className='  '>{pdfData.LESS_DAYS_30.includes('null') ? "N/A" : pdfData.MORE_DAYS_30}</p>
                          : <p className='  '>N/A</p>
                        } 
                      </div>
                    </div>
                    
                    <div className='h-3'></div>

                    <div className=' w-full grid grid-cols-2 gap-6 border-b-2 border-black pb-5'>
                    {
                      approverList.map((e,i)=>(
                        <div className=' '>
                          <div key={i} className=' flex flex-col items-center space-y-0'>
                            <p className=' text-black font-bold'>

                              { e.ACTION_CODE === 1 ?
                                <>
                                  {
                                    e.AUTHORIZER_TYPE === "RECOMMENDER" || e.AUTHORIZER_TYPE === "ACCOUNTS INFORMER" ? "Recommended" : "Approved"
                                  }
                                </>
                              :
                                <>
                                  { e.AUTHORIZER_TYPE === "RECOMMENDER" ? "Not Recommended" : "Rejected"  }
                                </>
                              }

                            
                            </p>
                            
                            <p className=' text-black font-bold '>
                              Note: { e.NOTE ? e.NOTE : "N/A" }
                            </p>
                            
                            <p className=' text-black font-bold'>
                              {
                                `${e.EMPLOYEE_NAME} (${e.APPROVER_ID})`
                              }
                            </p>
                            
                            <p className=' text-black font-bold'>
                              {
                                e.DESIGNATION
                              }
                            </p>

                          </div>
                        </div>
                      ))
                    }
                  </div>

                  <div className='h-2'></div>
                    <div className=' w-full h-20 flex pb-3 items-end justify-center text-black font-bold'>
                      <p> Central Treasury Department</p>
                    </div>
                  </div>
                </div>
              :
            null
          }
        </div>
      )
    }
    </div>
  )
}
