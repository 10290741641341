import { baseUrl } from "../../utils/path";

const FrontDeskAccessService= async(empId)=>{

    const url=`${baseUrl}attendance_report_management`;

    const response = await   fetch(url,
        {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
            },
            body: JSON.stringify({
              "EMPLOYEE_ID": empId
            }),
          }
        );
        if (!response.ok) {
            throw new Error("fetching is not successful");
          }
        
          const data = await response.json();
         
          return data;

     
}
export default FrontDeskAccessService