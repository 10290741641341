import { baseUrl } from "../../utils/path";


  

  const GetMoveOrderTypeService= async(userId)=>{

    const url=`${baseUrl}move_order_type`;

    const response = await   fetch(url,
        {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
            },
            body: JSON.stringify({
              "Z_DATA": userId
            }),
          }
        );
        if (!response.ok) {
            throw new Error("fetching is not successful");
          }
        
          const data = await response.json();
         
          return data;

     
}
export default GetMoveOrderTypeService
