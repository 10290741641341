


const EmployeeInfoService = async ( url) => {

    // console.log(`em:${empId} se: ${sequence} st: ${status} md: ${moduleId} au: ${authorizationId} ap: ${approvalId}`);
    const response = await fetch(url,
        {
            // mode: 'no-cors',
            // mode: "cors",
            method: "GET",
            headers: {
                "Content-Type": "application/json",
               
                // "dataType": "jsonp",
                // "Access-Control-Allow-Origin": "*",
                // "Access-Control-Allow-Headers" : "Content-Type",
                // "Access-Control-Allow-Origin": "http://localhost:3000",
                // "Access-Control-Allow-Methods": "OPTIONS,POST,GET",
                'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_SU}:${process.env.REACT_APP_SP}`),
            },
            
        }
    );
    // if (!response.ok) {
    //     throw new Error("fetching is not successful");
    //   }
    const data = await response.json();
    console.log(`odata: ${data}`);
    return data;


}
export default EmployeeInfoService
// const EmployeeInfoService = async (url) => {
//     const uname = "ABAPCONS3";
//     const pass = "Abap@cons456";
  
//     try {
//       const response = await fetch(url, {
//         method: "GET",
//         mode: 'no-cors',
//         headers: {
//           "Content-Type": "application/json",
//           "Authorization": "Basic " + btoa(`${uname}:${pass}`),
//         },
//       });
  
//       if (!response.ok) {
//         throw new Error("Fetching data was not successful");
//       }
  
//       const data = await response.json();
//       console.log(`odata: ${JSON.stringify(data)}`);
//       return data;
//     } catch (error) {
//       console.error("Error:", error);
//       throw error; // Rethrow the error to handle it at the calling side
//     }
//   };
  
//   export default EmployeeInfoService;
  