

  

  const addStageDetails= async(url,empId,sequence,status,moduleId,authorizationId,approvalId)=>{
    
    console.log(`em:${empId} se: ${sequence} st: ${status} md: ${moduleId} au: ${authorizationId} ap: ${approvalId}`);
    const response = await   fetch(url,
        {
            method: "POST",
            headers: {
              
              "Content-Type": "application/json",
              'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
            },
            body: JSON.stringify({
                "EMPLOYEE_ID": empId, 
                "STAGE_SEQ": sequence,
                 "STATUS": status,
                "MODULE_TYPE_ID": moduleId,
                "AUTHORIZATION_TYPE_ID": authorizationId,
                "APPROVAL_MASTER_ID": approvalId
            }),
          }
        );
        // if (!response.ok) {
        //     throw new Error("fetching is not successful");
        //   }
          const data = await response.json();
          console.log(`stage: ${data}`);
          return data;

     
}
export default addStageDetails
