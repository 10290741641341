import { baseUrl } from "../../utils/path";



const InsertNoticePdfService = async (id,pdfString) => {
    const url=`${baseUrl}insert_pdf`;
     // console.log(`em:${empId} se: ${sequence} st: ${status} md: ${moduleId} au: ${authorizationId} ap: ${approvalId}`);
     console.log(`pd base: ${pdfString} id: ${id}`);
     const response = await fetch(url,
         {
             method: "POST",
             headers: {
 
                 "Content-Type": "application/json",
                 'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
             },
             body: JSON.stringify(
                {
                    "MODULE_TYPE": "NOTICE_PDF",
                     "OBJECT_ID": id,
                      "PDF": pdfString
                }
             ),
         }
     );
    
     const data = await response.json();
     console.log(`pf: ${data}`);
     return data;
 
 
 }
 export default InsertNoticePdfService
 