import { useEffect, useRef, useState } from "react";
import InputLebel from "../../common/InputLebel";
import Datepicker from "react-tailwindcss-datepicker";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LeaveApplyService from "../service/LeaveApplyService";
import { baseUrl, sapBaseUrl } from "../../utils/path";
import LeaveAssignerService from "../service/LeaveAssignerService";
import LeaveApplyPropicService from "../service/LeaveApplyPropicService";
import getPropicService from "../../services/PropicService";
import LeaveApproverService from "../service/LeaveApproverService";
import LeaveApplySubmitService from "../service/LeaveApplySubmitService";
import LeaveDateCheckService from "../service/LeaveDateCheckService";
import LeaveCheckMoveOrderService from "../service/LeaveCheckMoveOrderService";
import AdminInfoService from "../../services/AdminInfoService";
import LeaveApplyImageService from "../service/LeaveApplyImageService";
import LeaveApplyPdfService from "../service/LeaveApplyPdfService";
import LeaveHistoryService from "../service/LeaveHistoryService";
import Loading from "../../components/loader/Loading";
import { useLeaveContext } from "../context/LeaveContext";
import LeaveDetailsPopup from "./LeaveDetailsPopup";
import { usePDF } from 'react-to-pdf';
import ApproverHierarchyListService from "../../move_order/service/ApproverHierarchyListService";
import DeleteApplicationService from "../../iou/service/DeleteApplicationService";


const propicUrl = `${baseUrl}get_emp_propic`;
const infoUrl = `${baseUrl}my_info`;


export default function LeaveHistoryPage () {

  const [selectedStatus, setSelectedStatus] = useState('');
  const [isApplyModalOpen, setIsApplyModalOpen] = useState(false);
  const [startEndDate, setStartEndDate] = useState({ startDate: null, endDate: null, });
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [searchAssigner, setSearchAssigner] = useState({});
  const [searchViaEmail, setSearchViaEmail] = useState({});
  const [note, setNote] = useState("");
  const [showAlert, setShowAlert] = useState(null);
  const maxCharLimit = 150;
  const [noteImages, setNoteImages] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [image, setImage] = useState(null);
  const [pdfBase64Array, setPdfBase64Array] = useState([]);
  const acceptedFilesRef = useRef([]);
  const [empId, setEmpId] = useState('');
  const [uId, setUId] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [leaveType, setLeaveType] = useState([]);
  const [users, setUsers] = useState([]);
  const [emailUsers, setEmailUsers] = useState([]);
  let id, username, selectedUserId;
  const [isUsersLoading, setIsUsersLoading] = useState(true);
  const [roleList,setRoleList]=useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [selectedEmailUser, setSelectedEmailUser] = useState({});
  const [isSelectAll, setIsSelectAll] = useState(null);
  // const [selectedItems, setSelectedItems] = useState([]);
  const [userId,setUserId]=useState();
  const [selectedUserName, setSelectedUserName] = useState('');
  const [selectedEmailUserName, setSelectedEmailUserName] = useState('');
  const usersRef = useRef(null);
  const [picUrl, setPicUrl] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [selectedRecommender, setSelectedRecommender] = useState(null);
  const [selectedLeaveCode, setSelectedLeaveCode] = useState(null);
  const [selectPicUrl, setSelectPicUrl] = useState({});
  const [leaveStatus, setLeaveStatus] = useState('');
  const [leaveCode, setLeaveCode] = useState(null);
  const [companyCode, setCompanyCode] = useState("");
  const [plantCode, setPlantCode] = useState("");
  const [selectedRecommenderId, setSelectedRecommenderId] = useState(null);
  const [totalLeaveDays, setTotalLeaveDays] = useState('');
  const [taskAssignerId, setTaskAssignerId] = useState('');
  const todayDate = moment(Date()).format('YYYY-MM-DD');
  const [submittedId, setSubmittedId] = useState('');
  const [base64Strings, setBase64Strings] = useState([]);
  const [wsRules, setWsRules] = useState('');
  const [leaveDetails, setLeaveDetails] = useState([]);
  const [leaveDetails2, setLeaveDetails2] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const[pdfData, setPdfData] = useState('');
  const [designation, setDesignation] = useState('');
  const [approverList, setApproverList] = useState([]);
  const [actionCode, setActionCode] = useState('');
  const { isDelete, setIsDelete, isPopUp, setIsPopUp, setDetails, details, deleteRowId, setDeleteRowId } = useLeaveContext();

  const { toPDF, targetRef } = usePDF({filename: `MY_LEAVE_HISTORY_${moment(details.APPLICATION_DATE).format('DD/MM/yyyy')}.pdf`});


  useEffect(()=>{
    const id = localStorage.getItem('empId');
    const zData = localStorage.getItem("userId");
    setUId(id);

    getLeaveType(id);
    getAdminInfo(zData);
    getLeaveHistory(id);
    // getHierarchy(details.EMPLOYEE_ID, "LEAVE", details.LEAVE_APPL_ID);
    // handleStatus("IN PROCESS");
  }, []);

  const getLeaveType = async (uId) => {
    const leaveTypeUrl = `${sapBaseUrl}/ZHCM_INFO_MOBILE_APP_SRV/Leave_trackSet?$filter=(Employee_Id%20eq%20%27${uId}%27)&$format=json`;
    setIsLoading(true);
    const type = await LeaveApplyService(leaveTypeUrl);
    // setDateWiseInfos(info.d.results);
    console.log("date wise data: ", type.d.results);
    setLeaveType(type.d.results);
    setIsLoading(false);
  }

  // Extract Leave_left values for each leave type
  const slLeaveLeft = leaveType.find((item) => item.Leave_type === '2000')?.Leave_left || '';
  const clLeaveLeft = leaveType.find((item) => item.Leave_type === '2001')?.Leave_left || '';
  const elLeaveLeft = leaveType.find((item) => item.Leave_type === '2002')?.Leave_left || '';
  const mlLeaveLeft = leaveType.find((item) => item.Leave_type === '2003')?.Leave_left || '';

  const getAdminInfo = async (zId) => {
    const admin = await AdminInfoService(infoUrl, zId);
    console.log("admin info: ", admin.EMP_DETAILS[0].WS_RULES);
    const designation = admin.EMP_DETAILS[0].DESIGNATION;

    setWsRules(admin.EMP_DETAILS[0].WS_RULES);
    setCompanyCode(admin.EMP_DETAILS[0].COMPANY_CODE);
    setPlantCode(admin.EMP_DETAILS[0].PLANT_CODE);
    setDesignation(designation);
  }

  const getLeaveHistory = async (eId) => {
    setIsLoading(true);
    const result = await LeaveHistoryService(eId);
    setLeaveDetails(result.leave_details);
    setLeaveDetails2(result.leave_details);
    console.log("leave: ", result.leave_details);
    setIsLoading(false);
  }

  const getHierarchy = async (id, module, object) => {
    setIsLoading(true);
    const re = await ApproverHierarchyListService(id, module, object);
    const sortedData = re.APPROVER_LIST.sort((a, b) => a.STAGE_SEQ - b.STAGE_SEQ);
    console.log("approverList: ", sortedData);
    setApproverList(sortedData);
    setActionCode(sortedData[0].ACTION_CODE)
    setIsLoading(false);
  }

  const openApplyModal = () => {
    setIsApplyModalOpen(true);
  };

  const closeApplyModal = () => {
    setIsApplyModalOpen(false);
  };

  // const handleDateChange = async (newDate) => {
  //   console.log("start:", moment(newDate.startDate).format("YYYY-MM-DD"));
  //   console.log("end:", moment(newDate.endDate).format("YYYY-MM-DD"));
  //   setStartEndDate(newDate);
    
  //   setFromDate(newDate.startDate);
  //   setToDate(newDate.endDate);

  //   console.log("select date new: ", fromDate);
  // }

  // const handleDateChange = async (newDate) => {

  //   // setStartEndDate([]);
  //   const { startDate, endDate } = newDate;

  //   // Retrieve deduction_from and deduction_to from the selected leave type
  //   const selectedLeaveType = leaveType.find(
  //     (leave) => leave.Leave_Name === selectedStatus
  //   );

  //   if (!selectedLeaveType) {
  //     // Handle the case where selected leave type is not found
  //     return;
  //   }

  //   // Convert deduction_from and deduction_to to valid Date objects using moment
  //   const deductionFrom = moment(selectedLeaveType.deduction_from, "DD.MM.YYYY");
  //   const deductionTo = moment(selectedLeaveType.deduction_to, "DD.MM.YYYY");

  //   // Check if the selected date range is within the deduction_from and deduction_to dates
  //   const isWithinDeductionRange =
  //     moment(startDate).isSameOrAfter(deductionFrom) &&
  //     moment(endDate).isSameOrBefore(deductionTo);

  //   if (isWithinDeductionRange) {
  //     setFromDate(startDate);
  //     setToDate(endDate);
  //     setStartEndDate(newDate);
  //     // Clear any previous toast
  //     toast.dismiss();
  //   } else {
  //     // Display an error toast
  //     toast.error("Selected date range is not within the deduction period.", {
  //       autoClose: 3000,
  //       position: toast.POSITION.TOP_CENTER,
  //     });

  //     // Set the date fields to null
  //     setFromDate(null);
  //     setToDate(null);
  //     console.log("Dates set to null:", fromDate, toDate);
  //     // Optionally, you can clear the selected dates to visually indicate an error
  //     setStartEndDate([]);
  //   }

  //   console.log("start:", moment(newDate.startDate).format("YYYY-MM-DD"));
  //   console.log("end:", moment(newDate.endDate).format("YYYY-MM-DD"));
  // };


  const handleDateChange = (newDate) => {
    // setStartEndDate([]);
    const { startDate, endDate } = newDate;

    // Retrieve selected leave type
    const selectedLeaveType = leaveType.find(
      (leave) => leave.Leave_Name === selectedStatus
    );

    if(selectedStatus === "" || selectedStatus === null) {
      toast.error("Please Select Leave Type", {
        autoClose: 3000,
        position: toast.POSITION.TOP_CENTER,
      });
    }

    if (!selectedLeaveType) {
      // Handle the case where selected leave type is not found
      return;
    }

    // Check if deduction_from and deduction_to are both null
    // if (!selectedLeaveType.deduction_from && !selectedLeaveType.deduction_to) {
    //   // Display an error toast
    //   toast.error(`Deduction date is not assign in SAP for ${selectedLeaveType.Leave_Name}.`, {
    //     autoClose: 3000,
    //     position: toast.POSITION.TOP_CENTER,
    //   });

    //   // Set the date fields to null
    //   setFromDate(null);
    //   setToDate(null);
    //   // Optionally, you can clear the selected dates to visually indicate an error
    //   setStartEndDate({});
    //   return;
    // }

    // Convert deduction_from and deduction_to to valid Date objects using moment
    const deductionFrom = moment(selectedLeaveType.deduction_from, "DD.MM.YYYY");
    const deductionTo = moment(selectedLeaveType.deduction_to, "DD.MM.YYYY");

    // Check if the selected date range is within the deduction_from and deduction_to dates
    const isWithinDeductionRange =
      moment(startDate).isSameOrAfter(deductionFrom) &&
      moment(endDate).isSameOrBefore(deductionTo);

    if(selectedLeaveType.Leave_type === '2000' || selectedLeaveType.Leave_type === '2001' || selectedLeaveType.Leave_type === '2002' || selectedLeaveType.Leave_type === '2003') {

      if (!isWithinDeductionRange) {
        // Display an error toast
        toast.error("Selected date range is outside the deduction period.", {
          autoClose: 3000,
          position: toast.POSITION.TOP_CENTER,
        });

        // Set the date fields to null
        setFromDate(null);
        setToDate(null);
        // Optionally, you can clear the selected dates to visually indicate an error
        setStartEndDate([]);
        return;
      }
    }

    // Check if the selected date range is longer than Leave_left
    const selectedDays = Math.ceil((new Date(endDate) - new Date(startDate)) / (1000 * 60 * 60 * 24)) + 1;
    setTotalLeaveDays(selectedDays)

    const leaveLeft = parseInt(selectedLeaveType.Leave_left, 10);

    if (selectedDays > leaveLeft) {
      // Display an error toast
      toast.error(`Available Leave Left (${leaveLeft} days) for ${selectedLeaveType.Leave_Name}`, {
        autoClose: 3000,
        position: toast.POSITION.TOP_CENTER,
      });

      // Set the date fields to null
      setFromDate(null);
      setToDate(null);
      // Optionally, you can clear the selected dates to visually indicate an error
      setStartEndDate([]);
      return;
    }

    // Check against the specific conditions for each leave type
    switch (selectedLeaveType.Leave_type) {
      case "2000": // Medical Leave
        if (selectedDays > 14) {
          toast.error(`${selectedLeaveType.Leave_Name} allows a maximum of 14 days at a row.`, {
            autoClose: 3000,
            position: toast.POSITION.TOP_CENTER,
          });
          // Set the date fields to null
          setFromDate(null);
          setToDate(null);
          // Optionally, you can clear the selected dates to visually indicate an error
          setStartEndDate([]);
          return;
        }
        break;

      case "2001": // Casual Leave
        if (selectedDays > 4) {
          toast.error(`${selectedLeaveType.Leave_Name} allows a maximum of 4 days at a row.`, {
            autoClose: 3000,
            position: toast.POSITION.TOP_CENTER,
          });
          // Set the date fields to null
          setFromDate(null);
          setToDate(null);
          // Optionally, you can clear the selected dates to visually indicate an error
          setStartEndDate([]);
          return;
        }
        break;

      case "2002": // Earned Leave
        if (selectedDays > 15) {
          toast.error(`${selectedLeaveType.Leave_Name} allows a maximum of 15 days at a row.`, {
            autoClose: 3000,
            position: toast.POSITION.TOP_CENTER,
          });
          // Set the date fields to null
          setFromDate(null);
          setToDate(null);
          // Optionally, you can clear the selected dates to visually indicate an error
          setStartEndDate([]);
          return;
        }
        break;

      case "2003": // Maternity Leave (HO)
        if (selectedDays > 120) {
          toast.error(`${selectedLeaveType.Leave_Name} allows a maximum of 120 days at a row.`, {
            autoClose: 3000,
            position: toast.POSITION.TOP_CENTER,
          });
          // Set the date fields to null
          setFromDate(null);
          setToDate(null);
          // Optionally, you can clear the selected dates to visually indicate an error
          setStartEndDate([]);
          return;
        }
        break;

      default:
        break;
    }

    // Set the selected values
    setFromDate(startDate);
    setToDate(endDate);
    setStartEndDate(newDate);
    // Clear any previous toast
    toast.dismiss();

    console.log("start:", moment(newDate.startDate).format("YYYY-MM-DD"));
    console.log("end:", moment(newDate.endDate).format("YYYY-MM-DD"));
  };
  

  const handleNoteChange = (e) => {
    const inputText = e.target.value;
    if(inputText.length <= maxCharLimit) {
      setNote(inputText);
      setShowAlert(null); // Clear any previous character limit exceeded message
    } 
    else {
      setShowAlert('Character limit exceeded!');
    }
    
  };

  const handleImage = async (event) => {
    const files = event.target.files;
    console.log("photo: ", files) 
    const newImages = [];
    const newPreviews = [];
  
    const resizeAndConvert = async (file) => {
      if (file.size > 150 * 1024) {
        toast.error("File size should be less than 150 KB", {
          autoClose: 3000,
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }

      const reader = new FileReader();
      reader.onload = async () => {
        const image = new Image();
        image.src = reader.result;
  
        image.onload = async () => {
          const resizedBlob = await resizeImage(image, 800, 600); // Resize to 800x600
  
          const readerResized = new FileReader();
          readerResized.onload = () => {
            const base64String = readerResized.result.split(',')[1]; // Extract base64 string
            // Check if image is already uploaded
            if (noteImages.flat().includes(base64String)) {
              toast.error("The image has already been uploaded.", {
                autoClose: 3000,
                position: toast.POSITION.TOP_CENTER,
              });
              return;
            }
            newImages.push(base64String);
            newPreviews.push(reader.result);
  
            if (newImages.length === files.length) {
              setNoteImages([...noteImages, newImages]);
              setImagePreviews([...imagePreviews, newPreviews]);
            }
            console.log("photoIf: ", noteImages)
          };
          readerResized.readAsDataURL(resizedBlob);
        };
      };
      reader.readAsDataURL(file);
    };
  
    for (let i = 0; i < files.length; i++) {
      await resizeAndConvert(files[i]);
    }
  };

  const resizeImage = (image, maxWidth, maxHeight) => {
    return new Promise((resolve) => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      const width = image.width;
      const height = image.height;

      let newWidth = width;
      let newHeight = height;

      if (width > height) {
        if (width > maxWidth) {
          newHeight = (maxWidth / width) * height;
          newWidth = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          newWidth = (maxHeight / height) * width;
          newHeight = maxHeight;
        }
      }

      canvas.width = newWidth;
      canvas.height = newHeight;

      ctx.drawImage(image, 0, 0, newWidth, newHeight);

      canvas.toBlob((blob) => {
        resolve(blob);
      }, 'image/jpeg');
    });
  };

  const handleDeleteImage = (index) => {
    const updatedImages = [...noteImages];
    const updatedPreviews = [...imagePreviews];

    updatedImages.splice(index, 1);
    updatedPreviews.splice(index, 1);

    setNoteImages(updatedImages);
    setImagePreviews(updatedPreviews);

    toast.success(`The Image has been deleted.`, {
      autoClose: 3000,
      position: toast.POSITION.TOP_CENTER,
    });
  };

  // const [files, setFiles] = useState([]);

  // const handleFileChange = (e) => {
  //   const newFiles = [...files, e.target.files[0]];
  //   setFiles(newFiles);
  // };

  // const handleDeleteFile = (index) => {
  //   const updatedFiles = [...files];
  //   updatedFiles.splice(index, 1);
  //   setFiles(updatedFiles);
  // };

  // const handleFileUpload = (e) => {
  //   const files = e.target.files;
  //   const newFiles = [];
  
  //   for (let i = 0; i < files.length; i++) {
  //     const file = files[i];
  
  //     if (file && file.type === 'application/pdf') {
  //       if (file.size > 5 * 1024 * 1024) {
  //         alert(`The PDF "${file.name}" exceeds the 5MB limit and will be ignored.`);
  //       } else {
  //         newFiles.push(file);
  //       }
  //     }
  //   }
  
  //   setPdfBase64Array([...pdfBase64Array, ...newFiles]);
  
  //   acceptedFilesRef.current = [...acceptedFilesRef.current, ...newFiles];
  
  //   // Reset input field if no accepted files
  //   if (newFiles.length === 0) {
  //     e.target.value = '';
  //   }
  // };

  const handleFileUpload = async (e) => {
    const files = e.target.files;
    const newFiles = [];
    const newPdfBase64Strings = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      if (file && file.type === 'application/pdf') {
        if (file.size > 5 * 1024 * 1024) {
          // Show alert for exceeding file size limit
          toast.error(`Please choose PDF less than 5MB`, {
            autoClose: 3000,
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (pdfBase64Array.some((existingFile) => existingFile.name === file.name)) {
          // Show alert for duplicate file
          console.log("file again")
          toast.error(`The PDF "${file.name}" has already been uploaded.`, {
            autoClose: 3000,
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          newFiles.push(file);

          // Convert PDF to Base64 string
          const fileData = await file.arrayBuffer();
          const base64String = btoa(new Uint8Array(fileData).reduce((data, byte) => data + String.fromCharCode(byte), ''));
          newPdfBase64Strings.push(base64String);
        }
      }
    }

    setPdfBase64Array([...pdfBase64Array, ...newFiles]);
    setBase64Strings([...base64Strings, ...newPdfBase64Strings]);
    console.log("pdf: ", pdfBase64Array);

    acceptedFilesRef.current = [...acceptedFilesRef.current, ...newFiles];

    // Reset input field if no accepted files
    if (newFiles.length === 0) {
      e.target.value = '';
    }
  };
  

  const handleDeleteFile = (index) => {
    const updatedFiles = [...pdfBase64Array];
    updatedFiles.splice(index, 1);
    setPdfBase64Array(updatedFiles);

    toast.success(`The PDF has been deleted.`, {
      autoClose: 3000,
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const [propicImg, setPropicImg] = useState('');

  const getAssigners = async () => {
    if (!isNaN(searchAssigner)) {
      id = searchAssigner;
    }
    else {
      username = searchAssigner;
    }

    if(selectedStatus === "" || selectedStatus === null) {
      toast.error("Please Select Leave Type", {
        autoClose: 3000,
        position: toast.POSITION.TOP_CENTER,
      });

      return;
    }

    else if(fromDate === null || toDate === null) {
      toast.error("Please select Date.", {
        autoClose: 3000,
        position: toast.POSITION.TOP_CENTER,
      });
      
      return;
    }

    const users = await LeaveAssignerService(id, username);
    setUsers(users.EMPLOYEE_LIST);
    setEmpId(users.EMPLOYEE_LIST[0].EMPLOYEE_ID);
    setPropicImg(users.EMPLOYEE_LIST[0].PROPIC_NAME)

    getPropic(users.EMPLOYEE_LIST[0].EMPLOYEE_ID, users.EMPLOYEE_LIST[0].PROPIC_NAME);
    
    setIsUsersLoading(false);
    setSearchAssigner('');
    usersRef.current.value = '';
  }

  const userSelect = async(e) => {
    setIsLoading(true);
    console.log("type: ", typeof uId);

    // Assuming uId is the variable containing the user ID you want to compare with
    if (e.EMPLOYEE_ID === parseInt(uId)) {
      console.log("same id");
      toast.error("You cannot select yourself as a Task Assigner.", {
        autoClose: 3000,
        position: toast.POSITION.TOP_CENTER,
      });
      setUsers([]);
      setSelectedUserName('');
      setIsLoading(false);
      return;
    }

    setRoleList([]);
    setSelectedUser(e); 
    selectedUserId = e.EMPLOYEE_ID;
    console.log(selectedUserId);
    // getRole(e.EMPLOYEE_ID);
    setUserId(selectedUserId); 
    setSelectedUserName(e.EMPLOYEE_NAME);
    setTaskAssignerId(e.EMPLOYEE_ID);
    setUsers([]);
    setIsLoading(false);

    // Close the modal by unchecking the checkbox
    const modalCheckbox = document.getElementById("my-modal-1");
    if (modalCheckbox) {
      modalCheckbox.checked = false;
    }
  }

  const getUserViaEmail = async () => {
    if (!isNaN(searchViaEmail)) {
      id = searchViaEmail;
    }
    else {
      username = searchViaEmail;
    }

    if(selectedStatus === "" || selectedStatus === null) {
      toast.error("Please Select Leave Type", {
        autoClose: 3000,
        position: toast.POSITION.TOP_CENTER,
      });

      return;
    }

    else if(fromDate === null || toDate === null) {
      toast.error("Please select Date.", {
        autoClose: 3000,
        position: toast.POSITION.TOP_CENTER,
      });
      
      return;
    }
    
    else if(selectedUserName === "") {
      toast.error("Please select Task Assigner.", {
        autoClose: 3000,
        position: toast.POSITION.TOP_CENTER,
      });
      
      return;
    }

    const users = await LeaveAssignerService(id, username);
    setEmailUsers(users.EMPLOYEE_LIST);
    setEmpId(users.EMPLOYEE_LIST[0].EMPLOYEE_ID);
    setPropicImg(users.EMPLOYEE_LIST[0].PROPIC_NAME)

    getPropic(users.EMPLOYEE_LIST[0].EMPLOYEE_ID, users.EMPLOYEE_LIST[0].PROPIC_NAME);
    
    setIsUsersLoading(false);
    setSearchAssigner('');
    // setEmailUsers([]);
    usersRef.current.value = '';
  }

  const getPropic = async(id, proImg) => {
    const res = await getPropicService(propicUrl, id, proImg);
    // console.log("img: ", res.PROPIC);
    base64ToImageUrl(res["PROPIC"]);
  }

  const base64ToImageUrl = (base64String) => {
    const blob = base64ToBlob(base64String);
    const url = URL.createObjectURL(blob);
    setPicUrl(url);
  };

  const base64ToBlob = (base64String) => {
    const byteCharacters = atob(base64String);
    const byteArrays = [];

    for (let i = 0; i < byteCharacters.length; i++) {
      byteArrays.push(byteCharacters.charCodeAt(i));
    }

    const blob = new Blob([new Uint8Array(byteArrays)], {
      type: "image/png",
    });
    return blob;
  };

  // const selectUserEmail = async(e)=>{
  //   setIsLoading(true);
  //   setRoleList([]);
  //   setSelectedUser(e); 
  //   selectedUserId = e.EMPLOYEE_ID;
  //   console.log(selectedUserId);
  //   // getRole(e.EMPLOYEE_ID);
  //   setUserId(selectedUserId); 
  //   setSelectedEmailUserName(e.EMPLOYEE_NAME) ;
  //   setEmailUsers([]);
  //   setIsLoading(false);

  //   // Close the modal by unchecking the checkbox
  //   const modalCheckbox = document.getElementById("my-modal-2");
  //   if (modalCheckbox) {
  //     modalCheckbox.checked = false;
  //   }
  // }
  
  const selectUserEmail = (selectedUser) => {
    if (selectedUser.EMPLOYEE_ID === parseInt(uId)) {
      console.log("same id");
      toast.error("You cannot select yourself as a Notifier.", {
        autoClose: 3000,
        position: toast.POSITION.TOP_CENTER,
      });
      setEmailUsers([]);
      setSelectedEmailUserName('');
      setIsLoading(false);
      return;
    }
    
    setSelectedUsers((prevUsers) => {
      const updatedUsers = [...prevUsers, selectedUser];
      console.log("selectLength: ", updatedUsers.length); // Log the updated length
      return updatedUsers;
    });

    setSearchViaEmail('');
    setEmailUsers([]);
    const modalCheckbox = document.getElementById("my-modal-2");
    if (modalCheckbox) {
      modalCheckbox.checked = false;
    }

    setSelectedEmailUserName(selectedUser.EMPLOYEE_NAME);

    // Generate a unique key for each selected user
    const userId = selectedUser.EMPLOYEE_ID;

    showUserImage(selectedUser.EMPLOYEE_ID, selectedUser.PROPIC_NAME, userId);
  }

  const showUserImage = async(id, proImg, userKey) => {
    const res = await getPropicService(propicUrl, id, proImg);
    // console.log("img: ", res.PROPIC);
    const imageUrl = base64ToImageUrl2(res["PROPIC"]);

    // Update the state with the new image URL for the specific user
    setSelectPicUrl((prevUrls) => ({
      ...prevUrls,
      [userKey]: imageUrl,
    }));
  }

  const base64ToImageUrl2 = (base64String) => {
    const blob = base64ToBlob2(base64String);
    const url = URL.createObjectURL(blob);
    return url;
  };

  const base64ToBlob2 = (base64String) => {
    const byteCharacters = atob(base64String);
    const byteArrays = [];

    for (let i = 0; i < byteCharacters.length; i++) {
      byteArrays.push(byteCharacters.charCodeAt(i));
    }

    const blob = new Blob([new Uint8Array(byteArrays)], {
      type: "image/png",
    });
    return blob;
  };

  const removeSelectedUser = (userId) => {
    setSelectedUsers((prevUsers) => {
      const updatedUsers = prevUsers.filter(user => user.EMPLOYEE_ID !== userId);
      console.log("removeLength: ", updatedUsers.length);
      
      if(updatedUsers.length === 0) {
        setSelectedEmailUserName('');
      }
      return updatedUsers;
    });
  }

  // const resetForm = () => {
  //   setSelectedStatus('');
  //   setStartEndDate({ startDate: null, endDate: null });
  //   setFromDate(null);
  //   setToDate(null);
  //   setSearchAssigner({});
  //   setSearchViaEmail({});
  //   setNote('');
  //   setShowAlert(null);
  //   setNoteImages([]);
  //   setImagePreviews([]);
  //   setImage(null);
  //   setPdfBase64Array([]);
  //   acceptedFilesRef.current = [];
  //   setEmpId('');
  //   setUId('');
  //   setIsLoading(true);
  //   setLeaveType([]);
  //   setUsers([]);
  //   setEmailUsers([]);
  //   setIsUsersLoading(true);
  //   setRoleList([]);
  //   setSelectedUser({});
  //   setSelectedUsers([]);
  //   setIsSelectAll(null);
  //   setSelectedItems([]);
  //   setUserId();
  //   setSelectedUserName('');
  //   setSelectedEmailUserName('');
  //   usersRef.current.value = '';
  // };

  const handleSelectChange = (e) => {

    const selectedValue = e.target.value;
    const selectedLeave = leaveType.find((leave) => leave.Leave_Name === selectedValue);

    // Reset the date if it's already selected
    if (startEndDate && Object.keys(startEndDate).length > 0) {
      setStartEndDate({});
    }

    getRecommender(selectedLeave.Leave_type);
    setLeaveCode(selectedLeave.Leave_type);
  
    const specialLeaveTypeCodes = ['2000', '2001', '2002', '2003'];
  
    if (selectedLeave && selectedLeave.Leave_Quota === "" && specialLeaveTypeCodes.includes(selectedLeave.Leave_type)) {
      toast.error(`${selectedLeave.Leave_Name} is not created in SAP.`, {
        position: toast.POSITION.TOP_CENTER
      });
      // Set selected value to null
      setSelectedStatus(null);
    } else {
      setSelectedStatus(selectedValue);
      setSelectedLeaveCode(selectedLeave.Leave_type);

      setFromDate(null);
      setToDate(null);
      setStartEndDate(null)
      console.log("date: ", setStartEndDate);
      setSelectedUserName('');
      setSelectedEmailUserName('');
      setSearchAssigner({});
      setSearchViaEmail({});
      setNote('');
      setShowAlert(null);
      setNoteImages([]);
      setImagePreviews([]);
      setImage(null);
      setPdfBase64Array([]);
      setSelectedUsers([]);
    }
  };
  
  const getRecommender = async (orderId) => {
    const res = await LeaveApproverService(uId, orderId);
    console.log("return apprv: ", res.EMPLOYEE_LIST[0].APPROVER_ID);
    setSelectedRecommender(res.EMPLOYEE_LIST[0]);
    setSelectedRecommenderId(res.EMPLOYEE_LIST[0].APPROVER_ID);
  }
  
  const handleSubmit = async () => {
    setIsLoading(true);

    console.log("selected: ", selectedUsers.length)
    console.log("submit button clicked");
    console.log("start: ", fromDate);
    console.log("end: ", toDate);
    console.log("image: ", imagePreviews.length)
    console.log("code: ", selectedLeaveCode)

    if(selectedStatus === "" || selectedStatus === null) {
      toast.error("Please Select Leave Type", {
        autoClose: 3000,
        position: toast.POSITION.TOP_CENTER,
      });
      setIsLoading(false);
      return;
    }

    else if(fromDate === null || toDate === null) {
      toast.error("Please select Date.", {
        autoClose: 3000,
        position: toast.POSITION.TOP_CENTER,
      });
      setIsLoading(false);
      return;
    }
    
    else if(selectedUserName === "") {
      toast.error("Please select Task Assigner.", {
        autoClose: 3000,
        position: toast.POSITION.TOP_CENTER,
      });
      setIsLoading(false);
      return;
    }

    else if(selectedEmailUserName === "") {
      toast.error("Please select User for Notify Email.", {
        autoClose: 3000,
        position: toast.POSITION.TOP_CENTER,
      });
      setIsLoading(false);
      return;
    }


    // Retrieve selected leave type
    const selectedLeaveType = leaveType.find(
      (leave) => leave.Leave_Name === selectedStatus
    );

    if(selectedStatus === "" || selectedStatus === null) {
      toast.error("Please Select Leave Type", {
        autoClose: 3000,
        position: toast.POSITION.TOP_CENTER,
      });
    }

    if (!selectedLeaveType) {
      // Handle the case where selected leave type is not found
      setIsLoading(false);
      return;
    }

    // Check if deduction_from and deduction_to are both null
    // if (!selectedLeaveType.deduction_from && !selectedLeaveType.deduction_to) {
    //   // Display an error toast
    //   toast.error(`Deduction date is not assign in SAP for ${selectedLeaveType.Leave_Name}.`, {
    //     autoClose: 3000,
    //     position: toast.POSITION.TOP_CENTER,
    //   });

    //   // Set the date fields to null
    //   setFromDate(null);
    //   setToDate(null);
    //   // Optionally, you can clear the selected dates to visually indicate an error
    //   setStartEndDate([]);
    //   setIsLoading(false);
    //   return;
    // }

    // Convert deduction_from and deduction_to to valid Date objects using moment
    const deductionFrom = moment(selectedLeaveType.deduction_from, "DD.MM.YYYY");
    const deductionTo = moment(selectedLeaveType.deduction_to, "DD.MM.YYYY");

    // Check if the selected date range is within the deduction_from and deduction_to dates
    const isWithinDeductionRange =
      moment(fromDate).isSameOrAfter(deductionFrom) &&
      moment(toDate).isSameOrBefore(deductionTo);

    if(selectedLeaveType.Leave_type === '2000' || selectedLeaveType.Leave_type === '2001' || selectedLeaveType.Leave_type === '2002' || selectedLeaveType.Leave_type === '2003') {

      if (!isWithinDeductionRange) {
        // Display an error toast
        toast.error("Selected date range is outside the deduction period.", {
          autoClose: 3000,
          position: toast.POSITION.TOP_CENTER,
        });

        // Set the date fields to null
        setFromDate(null);
        setToDate(null);
        // Optionally, you can clear the selected dates to visually indicate an error
        setStartEndDate([]);
        setIsLoading(false);
        return;
      }
    }

    // Check if the selected date range is longer than Leave_left
    const selectedDays = Math.ceil((new Date(toDate) - new Date(fromDate)) / (1000 * 60 * 60 * 24)) + 1;
    setTotalLeaveDays(selectedDays)
    console.log("days: ", fromDate);
    console.log("days: ", toDate);
    console.log("days: ", selectedDays);

    const leaveLeft = parseInt(selectedLeaveType.Leave_left, 10);

    if (selectedDays > leaveLeft) {
      // Display an error toast
      toast.error(`Available Leave Left (${leaveLeft} days) for ${selectedLeaveType.Leave_Name}`, {
        autoClose: 3000,
        position: toast.POSITION.TOP_CENTER,
      });

      // Set the date fields to null
      setFromDate(null);
      setToDate(null);
      // Optionally, you can clear the selected dates to visually indicate an error
      setStartEndDate([]);
      setIsLoading(false);
      return;
    }

    // Check against the specific conditions for each leave type
    switch (selectedLeaveType.Leave_type) {
      case "2000": // Medical Leave
        if (selectedDays > 14) {
          toast.error(`${selectedLeaveType.Leave_Name} allows a maximum of 14 days at a row.`, {
            autoClose: 3000,
            position: toast.POSITION.TOP_CENTER,
          });
          // Set the date fields to null
          setFromDate(null);
          setToDate(null);
          // Optionally, you can clear the selected dates to visually indicate an error
          setStartEndDate([]);
          setIsLoading(false);
          return;
        }
        break;

      case "2001": // Casual Leave
        if (selectedDays > 4) {
          toast.error(`${selectedLeaveType.Leave_Name} allows a maximum of 4 days at a row.`, {
            autoClose: 3000,
            position: toast.POSITION.TOP_CENTER,
          });
          // Set the date fields to null
          setFromDate(null);
          setToDate(null);
          // Optionally, you can clear the selected dates to visually indicate an error
          setStartEndDate([]);
          setIsLoading(false);
          return;
        }
        break;

      case "2002": // Earned Leave
        if (selectedDays > 15) {
          toast.error(`${selectedLeaveType.Leave_Name} allows a maximum of 15 days at a row.`, {
            autoClose: 3000,
            position: toast.POSITION.TOP_CENTER,
          });
          // Set the date fields to null
          setFromDate(null);
          setToDate(null);
          // Optionally, you can clear the selected dates to visually indicate an error
          setStartEndDate([]);
          setIsLoading(false);
          return;
        }
        break;

      case "2003": // Maternity Leave (HO)
        if (selectedDays > 120) {
          toast.error(`${selectedLeaveType.Leave_Name} allows a maximum of 120 days at a row.`, {
            autoClose: 3000,
            position: toast.POSITION.TOP_CENTER,
          });
          // Set the date fields to null
          setFromDate(null);
          setToDate(null);
          // Optionally, you can clear the selected dates to visually indicate an error
          setStartEndDate([]);
          setIsLoading(false);
          return;
        }
        break;

      default:
        break;
    }
  
    // if selected leave is 2000 and date range is 3 or more days
    if (selectedLeaveCode === "2000") {
      const selectedDays = Math.ceil((new Date(toDate) - new Date(fromDate)) / (1000 * 60 * 60 * 24)) + 1;
      console.log("select days: ", selectedDays);
  
      if (selectedDays >= 3 && (imagePreviews.length === 0 && pdfBase64Array.length === 0)) {
        // display error toast
        toast.error(`${selectedStatus} with 3 or more days requires either an image or a PDF upload.`, {
          autoClose: 3000,
          position: toast.POSITION.TOP_CENTER,
        });
        setIsLoading(false);
        return; // Prevent further submission
      }
    }


    const checkDate = await LeaveDateCheckService(uId, `${moment(fromDate).format("DD-MM-YYYY")}`, `${moment(toDate).format("DD-MM-YYYY")}`);
    console.log("dateCheck: ", checkDate.Application_DETAILS);

    if (checkDate.Application_DETAILS.length > 0) {
      // User is already on leave for the specified date range
      toast.error("You are already on Leave for the selected date.", {
        autoClose: 3000,
        position: toast.POSITION.TOP_CENTER,
      });
      setIsLoading(false);
      return;
    }

    const checkMoveOrder = await LeaveCheckMoveOrderService(uId, `${moment(fromDate).format("DD-MM-YYYY")}`, `${moment(toDate).format("DD-MM-YYYY")}`);
    console.log("moveCheck: ", checkMoveOrder.MOVE_ORDER_DETAILS);

    if (checkMoveOrder.MOVE_ORDER_DETAILS.length > 0) {
      // User is already on leave for the specified date range
      toast.error("You are already on Move Order for the selected date.", {
        autoClose: 3000,
        position: toast.POSITION.TOP_CENTER,
      });
      setIsLoading(false);
      return;
    }

    const sendForm = await LeaveApplySubmitService(uId, todayDate, leaveCode, fromDate, toDate, selectedRecommenderId, note, totalLeaveDays, companyCode, plantCode, wsRules, taskAssignerId)
    console.log("afterSubmit", sendForm.APPL_ID);
    setSubmittedId(sendForm.APPL_ID);

    // if (sendForm.APPL_ID) {

    //   console.log("noteImages:", noteImages);

    //   // Call image upload API for each image
    //   for (let i = 0; i < noteImages.length; i++) {
    //     console.log(`base image ${i + 1}: ${noteImages[i]}`);
    //     const base64String = noteImages[i]; // Use the entire data URL
    //     const sendImage = await LeaveApplyImageService(submittedId, base64String);
    //     console.log(`Image upload response ${i + 1}:`, sendImage);
    //   }
    // }

    if (sendForm.APPL_ID) {
      // Log the contents of each nested array
      for (let i = 0; i < noteImages.length; i++) {
        const nestedArray = noteImages[i];
        console.log(`Nested Array ${i + 1}:`, nestedArray);
    
        // Log the content of each element in the nested array
        for (let j = 0; j < nestedArray.length; j++) {
          const base64String = nestedArray[j];
          console.log(`Base image ${i + 1}-${j + 1}: ${base64String}`);

          const sendImage = await LeaveApplyImageService(sendForm.APPL_ID, base64String);
          console.log(`Image upload response ${i + 1}:`, sendImage);
        }
      }

      for (let i = 0; i < base64Strings.length; i++) {
        const nestedArray = base64Strings[i];
        console.log(`Nested Array ${i + 1}:`, nestedArray);
        const sendPdf = await LeaveApplyPdfService(sendForm.APPL_ID, nestedArray);
          console.log(`Image upload response ${i + 1}:`, sendPdf);
    
        // Log the content of each element in the nested array
        // for (let j = 0; j < nestedArray.length; j++) {
        //   const base64String = nestedArray[j];
        //   console.log(`Base image ${i + 1}-${j + 1}: ${base64String}`);

        //   const sendPdf = await LeaveApplyPdfService(sendForm.APPL_ID, base64String);
        //   console.log(`Image upload response ${i + 1}:`, sendPdf);
        // }
      }
    }
  
    // Show a success toast
    toast.success("Form submitted successfully!", {
      autoClose: 3000,
      position: toast.POSITION.TOP_CENTER,
    });

    getLeaveHistory(uId);

    setIsApplyModalOpen(false);

    setSelectedStatus('');
    setStartEndDate({ startDate: null, endDate: null });
    setFromDate(null);
    setToDate(null);
    setSearchAssigner({});
    setSearchViaEmail({});
    setNote('');
    setShowAlert(null);
    setNoteImages([]);
    setImagePreviews([]);
    setImage(null);
    setPdfBase64Array([]);
    acceptedFilesRef.current = [];
    setEmpId('');
    setUId('');
    setIsLoading(false);
    setLeaveType([]);
    setUsers([]);
    setEmailUsers([]);
    setIsUsersLoading(true);
    setRoleList([]);
    setSelectedUser({});
    setSelectedUsers([]);
    setIsSelectAll(null);
    setUserId();
    setSelectedUserName('');
    setSelectedEmailUserName('');
    usersRef.current.value = '';
    
  };

  const handleTaskAssignerClick = () => {
    if (selectedStatus === "" || selectedStatus === null) {
      toast.error("Please Select Leave Type", {
        autoClose: 3000,
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    } 
    
    else if (fromDate === null || toDate === null) {
      toast.error("Please select Date.", {
        autoClose: 3000,
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    // // If both conditions are true, open the modal with id "my-modal-2"
    // const emailModal = document.getElementById("my-modal-2");
    // if (emailModal) {
    //   // Adjust this based on your modal implementation
    //   emailModal.classList.add("modal-show");
    // }
  };

  const handleEmailUserSelect = () => {
    console.log("email user: ", selectedStatus)
    if (selectedStatus === "" || selectedStatus === null) {
      toast.error("Please Select Leave Type", {
        autoClose: 3000,
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    } 
    
    else if (fromDate === null || toDate === null) {
      toast.error("Please select Date.", {
        autoClose: 3000,
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    else if(selectedUserName === "") {
      toast.error("Please select Task Assigner.", {
        autoClose: 3000,
        position: toast.POSITION.TOP_CENTER,
      });
      
      return;
    }
  }

  const handleStatus = async (status) => {
    setIsLoading(true);
    setIsPopUp(false);
    const result = await LeaveHistoryService(uId, status);
    console.log("Details:", result.leave_details)
    setLeaveDetails(result.leave_details);
    setLeaveDetails2(result.leave_details);
    setIsLoading(false);
  }

  const handleRowClick = async (item) => {
    setIsLoading(true);
  
    await setSelectedItem((prevSelectedItem) => {
      if (prevSelectedItem) {
        // Do cleanup for the previously selected item
        setDetails();
        setPdfData('');
        setIsPopUp(false);
        // getItem();
        // getHierarchy();
      }
  
      // Set the new selected item
      setDetails(item);
      console.log("item: ", item)
      setPdfData(item);
      setIsPopUp(true);
      // getItem(item);
      getHierarchy(item.EMPLOYEE_ID, "LEAVE", item.LEAVE_APPL_ID);
  
      return item; // Return the new selected item
    });
    setIsLoading(false);
  }

  const handleRowDelete = async () => {
    setIsLoading(true);
    console.log("Deleted row: ");
    console.log("Deleted Item: ", deleteRowId);
    const result = await DeleteApplicationService(uId, "EMPLOYEE_LEAVE", deleteRowId);
    getLeaveHistory(uId);
    setIsDelete(false);
    setIsPopUp(false);
    setIsLoading(false);
  }


  return (
    <div className="my-20 mx-8">
      <p className=" text-black text-lg font-semibold">
        Leave History
      </p>

      <div className="h-4"></div>

      <ToastContainer />

      <button onClick={openApplyModal} className="h-12 w-40 bg-buttonColor text-white rounded-md flex justify-center items-center">
        Apply for Leave
      </button>

      <div className="h-10"></div>

      { isLoading ? (
        <Loading /> ) : (
        <>
          <div className="flex items-center space-x-2">
            <select
              placeholder='Sort By Status' className="select select-bordered py-2 w-48 focus:outline-none text-gray-500 rounded-md border-[0.5px] hover:border-gray-400 px-3 bg-white"
              onChange={(e) => {handleStatus(e.target.value); setLeaveStatus(e.target.value) }}
              value={leaveStatus}
              defaultValue="IN PROCESS"
            >
              {/* <option value="">ALL</option> */}
              <option value="IN PROCESS">Pending</option>
              <option value="APPROVED">Approved</option>
              <option value="REJECTED">Rejected</option>
            </select>

            <div>
              {
                details.AUTHORIZATION_STATUS === "APPROVED" ?
                <button onClick={() => toPDF()} className={`w-[180px] h-[48px] flex justify-center items-center bg-[#E6E1DD] px-2 rounded-lg shadow-sm text-[#013E0E] space-x-2 no-underline`}>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" dataSlot="icon" className="w-5 h-5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
                  </svg>

                  <h2 className=" lg:text-sm md:text-xs text-xs">
                    Download PDF
                  </h2>
                </button> 
                :
                <div></div>
              }
            </div>
          </div>

          <div className="h-7"></div>

          <div className="w-full">
            {
              leaveDetails2.length === 0 ? (
                <div className='flex flex-row justify-center items-center text-center w-full h-screen'>
                  <h1 className={`text-red-500 text-center w-full font-bold text-2xl`}>No Data Found</h1>
                </div>
              ) : (
                <>
                  <div className="overflow-x-">
                    <table className={`w-full ${isPopUp ? 'lg:w-4/6' : ''} border-[0.5px] border-gray-200`}>
                      <thead className="bg-bgTableHeader text-tableHeaderText shadow-sm h-14">
                        <tr>
                          <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                            SL
                          </th>

                          <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                            LEAVE TYPE
                          </th>
                                        
                          <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                            APPLICATION DATE
                          </th>
                                        
                          <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                            MANAGER NAME
                          </th>

                          <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                            STATUS
                          </th>
                        </tr>
                      </thead>

                      <tbody className="w-full divide-y divide-borderColor">
                        {leaveDetails.map((e, index) => (
                          <tr key={index} className="bg-white hover:cursor-pointer" onClick={() => handleRowClick(e)}>
                            <td className="px-6 py-4 whitespace-nowrap">
                              {index + 1}
                            </td>

                            <td className="px-6 py-4 whitespace-nowrap">
                              {e.LEAVE_TYPE == null ? "N/A" : e.LEAVE_TYPE}
                            </td>

                            <td className="px-6 py-4 whitespace-nowrap">
                              {e.APPLICATION_DATE == null ? "N/A" : e.APPLICATION_DATE}
                            </td>

                            <td className="px-6 py-4 whitespace-nowrap">
                              {e.MANAGER_NAME == null ? "N/A" : e.MANAGER_NAME}
                            </td>

                            <td className="px-6 py-4 whitespace-nowrap">
                              {e.AUTHORIZATION_STATUS == null ? "N/A" : e.AUTHORIZATION_STATUS}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </>
              )
            }

            {/* Display details popup if a row is clicked */}
          { isPopUp && (
            <LeaveDetailsPopup />
          )}

          {isDelete && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white px-6 py-8 rounded-xl w-96">
                <div className="text-center w-full">
                  <div className=' flex justify-center mb-5'>
                    <img src="/images/delete.png" alt="delete" className='w-12 h-12' />
                  </div>

                  <h3 className='font-semibold text-lg'>Warning !!!</h3>
                  <p className="text-lg mb-4">Are you sure you want to delete?</p>
                </div>
                <div className="flex justify-between items-center">
                  <button
                    className="px-4 w-36 py-2 mr-2 text-gray-600 bg-white border-[0.5px] border-gray-700 rounded-lg"
                    onClick={() => setIsDelete(false)}
                  >
                    No
                  </button>

                  <button 
                    className="px-4 w-36 py-2 bg-red-500 text-white rounded-lg"
                    onClick={handleRowDelete}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          )}
          </div>
        </>
      )}

      <div className="h-10"></div>

      {
         pdfData.AUTHORIZATION_STATUS==='APPROVED' && isPopUp ?
        <div ref={targetRef} id='pdf' className='w-3/4 bg-white py-8 pl-16 pr-16'>
          <div className=' w-full border-2 border-black'>
            <div className=' flex flex-row w-full'>
              <div className=' flex-1 h-24 border-b-2 border-r-2 border-black'>
                <p className=' w-full flex  justify-center items-center text-3xl font-pristina italic text-black'>{pdfData.COMPANY_NAME} ({pdfData.COMPANY_CODE})</p>
                <div className=' text-black font-bold text-lg w-full justify-center items-center flex'>LEAVE REQUEST</div>
              </div>

              <div className=' w-56 h-24 border-b-2 flex items-center justify-center border-black'>
                <p className='text-black font-bold justify-start'>
                  Date: {pdfData.APPLICATION_DATE}
                </p>
              </div>
            </div>

            <div className=' w-full flex flex-row items-center'>
              <div className='h-10 flex px-2 text-black font-bold justify-start w-52 border-b-2 border-r-2 border-black'>
                <p>Applicant Name: </p>
                <div className='h-3'></div>
              </div>

              <div className='h-10 flex px-2 text-black font-bold justify-start flex-1 border-b-2 border-black'>
                <p>{pdfData.EMPLOYEE_NAME}</p>
                <div className='h-3'></div>
              </div>
            </div>

            <div className=' w-full flex flex-row items-center'>
              <div className='h-10 flex px-2 text-black font-bold justify-start w-52 border-b-2 border-r-2 border-black'>
                <p>Applicant ID: </p>
                <div className='h-3'></div>
              </div>

              <div className='h-10 flex px-2 text-black font-bold justify-start flex-1 border-b-2 border-black'>
                <p>{pdfData.EMPLOYEE_ID}</p>
                <div className='h-3'></div>
              </div>
            </div>

            <div className=' w-full flex flex-row items-center'>
              <div className='h-10 flex px-2 text-black font-bold justify-start w-52 border-b-2 border-r-2 border-black'>
                <p>Designation: </p>
                <div className='h-3'></div>
              </div>

              <div className='h-10 flex px-2 text-black font-bold justify-start flex-1 border-b-2 border-black'>
                <p>{designation}</p>
                <div className='h-3'></div>
              </div>
            </div>

            <div className=' w-full flex flex-row items-center'>
              <div className='h-10 flex px-2 text-black font-bold justify-start w-52 border-b-2 border-r-2 border-black'>
                <p>Company: </p>
                <div className='h-3'></div>

              </div>
              <div className='h-10 flex px-2 text-black font-bold justify-start flex-1 border-b-2 border-black'>
                <p>{pdfData.COMPANY_NAME} ({pdfData.COMPANY_CODE})</p>
                <div className='h-3'></div>
              </div>
            </div>

            <div className=' w-full flex flex-row items-center'>
              <div className='h-10 flex px-2 text-black font-bold justify-start w-52 border-b-2 border-r-2 border-black'>
                <p>Plant: </p>
                <div className='h-3'></div>

              </div>
              <div className='h-10 flex px-2 text-black font-bold justify-start flex-1 border-b-2 border-black'>
                <p>{pdfData.PLANT_NAME} ({pdfData.PLANT_CODE})</p>
                <div className='h-3'></div>
              </div>
            </div>

            <div className=' w-full flex flex-row items-center'>
              <div className='h-10 flex px-2 text-black font-bold justify-start w-52 border-b-2 border-r-2 border-black'>
                <p>Leave Type: </p>
                <div className='h-3'></div>
              </div>

              <div className='h-10 flex px-2 text-black font-bold justify-start flex-1 border-b-2 border-black'>
                <p>{pdfData.LEAVE_TYPE} </p>
                <div className='h-3'></div>
              </div>
            </div>

            <div className=' w-full flex flex-row items-center'>
              <div className='h-10 flex px-2 text-black font-bold justify-start w-52 border-b-2 border-r-2 border-black'>
                <p>Mobile:</p>
                <div className='h-3'></div>
              </div>

              <div className='h-10 flex px-2 text-black font-bold justify-start flex-1 border-b-2 border-black'>
                <p>{pdfData.MOBILE_NUMBER} </p>
                <div className='h-3'></div>
              </div>
            </div>

            <div className=' w-full flex flex-row items-center'>
              <div className='h-20 flex px-2 text-black font-bold justify-start w-52 border-b-2 border-r-2 border-black'>
                <p>Duration</p>
                <div className='h-3'></div>
              </div>

              <div className='h-20 flex flex-col items-start text-black font-bold justify-start flex-1 border-b-2 border-black'>
                <div className=' h-10 w-full flex flex-row items-center'>
                  <div className='px-2 h-10 flex-1 border-r-2 border-black border-b-2'>
                    <p className='h-full w-full flex justify-center '>Start Date</p>
                    <div className='h-3'></div>
                  </div>
              
                  <div className='px-2 h-10 flex-1 border-r-2 border-black border-b-2'>
                    <p className='h-full w-full flex justify-center'>End Date</p>
                    <div className='h-3'></div>
                  </div>

                  <div className='px-2 h-10 flex-1 border-black border-b-2'>
                    <p className='h-full w-full flex justify-center'>Total Days</p>
                    <div className='h-3'></div>
                  </div>
                </div>
                
                <div className=' h-10 w-full flex flex-row items-center'>
                  <div className='px-2 h-10 flex-1 border-r-2 border-black border-b-2'>
                    <p className='h-full w-full flex justify-center'>{pdfData.DATE_FROM}</p>
                    <div className='h-3'></div>
                  </div>
                  
                  <div className='px-2 h-10 flex-1 border-r-2 border-black border-b-2'>
                    <p className='h-full w-full flex justify-center'>{pdfData.DATE_TO}</p>
                    <div className='h-3'></div>
                  </div>

                  <div className='px-2 h-10 flex-1 border-black border-b-2'>
                    <p className='h-full w-full flex justify-center'>{pdfData.LEAVE_DAYS}</p>
                    <div className='h-3'></div>
                  </div>
                </div>
              </div>
            </div>
          
            <div className=' w-full flex flex-row items-center'>
              <div className='h-10 flex px-2 text-black font-bold justify-start border-b-2 w-52 border-r-2 border-black'>
                <p>Reason for Leave </p>

                <div className='h-3'></div>
              </div>
              
              <div className='h-10 flex items-center px-2 text-black font-bold border-b-2 justify-start flex-1 border-black'>
                  
              </div>
            </div>

            <div className=' w-full flex flex-row items-center border-b-2 border-black'>
              <div className='h-24 px-2 text-black font-bold w-full border-black'>
                <p className="text-lg">Task Assigned Person During Leave </p>

                <div className="flex flex-col mt-2">
                  <span>Name: {details.ASSIGNER_NAME} ({details.TASK_ASSIGNER_ID})</span>
                  <span>Designation: {details.ASSIGNER_DESIGNATION}</span>
                </div>
                <div className='h-3'></div>
              </div>
            </div>

            <div className=' w-full flex flex-row items-center'>
              <div className='h-10 flex px-2 text-black font-bold justify-start w-full border-black'>
                <p className="tracking-wider">Leave Dues: Casual Leave: {clLeaveLeft ? clLeaveLeft : 0}, Medical Leave: {slLeaveLeft ? slLeaveLeft : 0}, Earned Leave: {elLeaveLeft ? elLeaveLeft : 0}, Maternity Leave (HO): {mlLeaveLeft ? mlLeaveLeft : 0}</p>

                <div className='h-3'></div>
              </div>
            </div>


          </div>

          <div className='h-10'></div>

          <div className=' w-full border-2 border-black'>
            <div className=' w-full grid grid-cols-2 gap-6 border-b-2 border-black pb-4'>
              {
                approverList.map((e,i)=>(
                  <div className=' '>
                    <div className=' pt-2 pb-1 flex flex-col items-center space-y-0'>
                      <p className=' text-black font-bold'>
                        { e.ACTION_CODE === 1 ?
                          <>
                            { e.AUTHORIZER_TYPE === "RECOMMENDER" ? "Recommended" : "Approved" }
                          </>
                        :
                          <>
                            { e.AUTHORIZER_TYPE === "RECOMMENDER" ? "Not Recommended" : "Rejected"  }
                          </>
                        }
                          
                      </p>
                      
                      <p className=' text-black font-bold'>
                        Note: { e.NOTE ? e.NOTE:"N/A" }
                      </p>
                      
                      <p className=' text-black font-bold'>
                        { `${e.EMPLOYEE_NAME} (${e.APPROVER_ID})` }
                      </p>
                      
                      <p className=' text-black font-bold'>
                        { e.DESIGNATION }
                      </p>
                    </div>
                  </div>
                ))
              }
            </div>

            <div className='h-2'></div>
            
            <div className='w-full h-20 flex pb-3 items-end justify-center text-black font-bold'>
              <p> Central Treasury Department</p>      
            </div>   
          </div>
        </div>
        :
        null
      }
        

      {/* leave apply modal start here */}
      {isApplyModalOpen && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <div
              className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-7/12 h-auto overflow-y-auto"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <span className="absolute bg-gray-700 rounded-full top-1 right-1 w-9 h-9 text-lg text-white flex items-center justify-center cursor-pointer" onClick={closeApplyModal}>
                  &#x2715;
                </span>
                <label className="block text-gray-700 text-lg font-bold mb-2">Leave Apply</label>
                
              </div>

              <div className="h-5"></div>

              <div className="px-6 mb-10">
                <div>
                  <InputLebel text="Leave Type *" />

                  <div className="h-1"></div>

                  <select
                    className="py-2 h-12 w-full focus:outline-none text-gray-500 rounded-md border-[0.5px] hover:border-gray-400 px-3 select select-bordered bg-white"
                    onChange={handleSelectChange}
                    value={selectedStatus || ''}
                  >
                    <option value="" disabled>Select Leave Type</option>
                    {leaveType.map((leave) => (
                      <option key={leave.Leave_type} value={leave.Leave_Name}>
                        {leave.Leave_Quota
                          ? `${leave.Leave_Name} (${leave.Leave_Taken}/${leave.Leave_Quota})`
                          : leave.Leave_Name}
                      </option>
                    ))}
                  </select>

                  {/* {selectedStatus === '' && (
                    <p className="text-red-500">Please select Leave Type</p>
                  )} */}
                </div>

                <div className="h-5"></div>

                {/* leave date */}
                <div>
                  <InputLebel text="Select Dates *" />

                  <div className="h-1"></div>

                  <div>
                    <Datepicker
                      // useRange={moveTypeCode==='11'?true:false}
                      // asSingle={moveTypeCode!=='11'?true:false}
                      popoverDirection="down"
                      placeholder="Date From-To         "
                      showFooter={true}
                      primaryColor={"green"}
                      showShortcuts={true}
                      value={startEndDate}
                      onChange={handleDateChange}
                      displayFormat={"DD/MM/YYYY"}
                      toggleClassName={`absolute rounded-r-lg text-[#7F7F7F] right-0 h-full px-3 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed`}
                      // inputClassName="w-full rounded-md focus:ring-0 font-normal bg-green-100 dark:bg-green-900 dark:placeholder:text-green-100"
                      // containerClassName=" border border-[1px] border-gray-400 w-80 rounded-md"
                      // toggleClassName=" text-gray-400"
                      inputClassName={`outline-buttonColor bg-white w-full rounded-r-lg h-[48px] border-[1px] border-gray-300 rounded-md px-3`}
                      // containerClassName="  "
                    />
                  </div>
                </div>

                <div>
                  {/* Recommender Display */}
                  {selectedStatus && (
                    <div>
                      <div className="h-5"></div>
                      <InputLebel text="Recommender *" />
                      <div className="h-1"></div>

                      <div className="px-3 h-12 flex items-center justify-between font-semibold border-[1px] rounded-md bg-[#E3F2FD] text-gray-600">
                        <p>
                          {selectedRecommender ? `${selectedRecommender.EMPLOYEE_NAME}` : 'No Recommender Selected'}
                        </p>

                        <div className="w-9 h-9 rounded-full flex items-center justify-center">
                          <img src="/images/recommender.png" alt="delete" className='w-8 h-8' />
                        </div>
                      </div>
                    </div>
                  )}

                </div>

                <div className="h-5"></div>

                {/* task assigner */}
                <div>
                  <InputLebel text="Select Task Assigner *" />

                  <div className="h-1"></div>

                  <div className="modal-action mt-0 w-full">
                    <label htmlFor="my-modal-1" className="w-full border-none bg-transparent hover:bg-transparent">
                      <div onClick={handleTaskAssignerClick} className={` w-full h-12 border-[0.2px] border-gray-300 rounded-md flex text-left justify-center items-center hover:cursor-pointer`}>
                        <p className="w-full pl-4 text-gray-400">{selectedUserName === '' ? "Select Task Assigner" : selectedUserName}</p>
                      </div>
                    </label>
                  </div>
                </div>

                <div className="h-5"></div>

                {/* add people via email */}
                <div>
                  <InputLebel text="Notify Via Email *" />

                  <div className="h-1"></div>

                  <div className="modal-action mt-0 w-full">
                    <label htmlFor="my-modal-2" className="w-full border-none bg-transparent hover:bg-transparent">
                      <div onClick={handleEmailUserSelect} className={` w-full h-12 border-[0.2px] border-gray-300 rounded-md flex text-left justify-center items-center hover:cursor-pointer`}>
                        <p className="w-full pl-4 text-gray-400">{selectedEmailUserName === '' ? "Select User for Notify Via Email" : selectedEmailUserName}</p>
                      </div>
                    </label>
                  </div>
                </div>

                <div className="h-4"></div>

                <div className="overflow-x-auto">
                  <div className="flex items-center space-x-3 w-full">
                    {selectedUsers.map((user, index) => (
                      <div key={user.EMPLOYEE_ID} className="h-10 flex items-center px-3 bg-[#EDF1F8] rounded-md space-x-1" style={{ whiteSpace: 'nowrap' }}>
                        <div className="w-8 h-full flex items-center">
                          {user.PROPIC_NAME ? (
                            <img src={selectPicUrl[user.EMPLOYEE_ID] || "/images/user.png"} alt="logo" className="w-7 h-7 rounded-full" />
                          ) : (
                            <>
                              <div className="bg-gray-300 w-7 h-7 rounded-full flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
                                </svg>
                              </div>
                            </>
                          )}
                        </div>

                        <div className="space-x-4 flex items-center">
                          <p className="whitespace-nowrap">{user.EMPLOYEE_NAME}</p>

                          <button className="h-full text-red-500" onClick={() => removeSelectedUser(user.EMPLOYEE_ID)}>X</button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="h-5"></div>

                {/* for note */}
                <div className=" w-full flex flex-col items-start">
                  <InputLebel text="Note" />
                  
                  <div className="h-1"></div>
                  
                  <textarea
                      className=" w-full h-24 border-gray-300 bg-white border-[1.5px] rounded-[8px] px-2 py-1 focus:outline-none"
                      value={note}
                      onChange={handleNoteChange}
                      
                      // rows={4} // Adjust the number of rows as needed
                      // cols={50} // Adjust the number of columns as needed
                  />

                  <div className="flex items-center justify-between w-full">
                    <p>
                      {showAlert && (
                        <p style={{ color: 'red' }}>{showAlert}</p>
                      )}
                    </p>
                    <p style={{ color: note.length < maxCharLimit ? 'black' : 'red' }}>
                      {note.length} <span className='text-black'>/ {maxCharLimit}</span>
                    </p>
                  </div>
                  {/* {noteError.note && (
                      <span className={`text-red-500`}>{noteError.note}</span>
                  )} */}
                </div>

                <div className="h-5"></div>

                  {/* image upload */}
                <div>
                  <InputLebel text="Add Picture" />

                  <div className="h-1"></div>

                  <div>
                    <input 
                      type="file" 
                      onChange={handleImage} 
                      className="file-input file-input-bordered w-full bg-white" 
                      accept="image/*" 
                      multiple  // Enable multiple file selection
                    />

                    <div className="h-3"></div>

                    <div style={{ overflowX: 'auto' }}>
                      <div className="flex space-x-4">
                        {imagePreviews.map((preview, index) => (
                          <div key={index} className="flex-shrink-0">
                            <div className="flex items-center flex-col">
                              <div className="flex items-center justify-between w-full">
                                <h3 className="text-sm text-black">Image Preview {index + 1}:</h3>
                                
                                <button onClick={() => handleDeleteImage(index)} className="ml-2 text-white bg-red-500 w-6 h-6 rounded-full cursor-pointer flex justify-center">
                                  <p>x</p>
                                </button>
                              </div>
                              <div className="">
                                <img src={preview} alt={`Preview ${index + 1}`} style={{ width: '190px', height: '140px' }} className="rounded-md" />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="h-5"></div>

                {/* pdf upload */}
                <div>
                  <InputLebel text="Add PDF" />

                  <div className='h-1'></div>
           
                  <input
                    placeholder={acceptedFilesRef.current.length}
                    type="file"
                    accept=".pdf"
                    className="file-input file-input-bordered w-full bg-white"
                    multiple
                    onChange={handleFileUpload}
                  /> 
                  {/* {createNoticeError.pdfBase64Array && <span className={`text-red-500`}>{createNoticeError.pdfBase64Array}</span>} */}
                  <div style={{ overflowX: 'auto' }}>
                    <div className="flex space-x-4">
                      {pdfBase64Array.length > 0 && pdfBase64Array.map((file, index) => (
                        <div key={index} className="mt-4 flex items-center flex-col mb-4">
                          <div className="flex items-center justify-between w-full">
                            <h3 className="text-sm text-black">Pdf {index + 1}:</h3>

                            <button onClick={() => handleDeleteFile(index)} className="ml-2 text-white bg-red-500 w-6 h-6 rounded-full cursor-pointer flex justify-center">
                              x
                            </button>
                            
                          </div>
                            
                          <p style={{ width: '190px', height: '40px' }} className="overflow-hidden border-[1px] border-gray-300 rounded-md flex items-center px-3 py-2 whitespace-nowrap overflow-ellipsis">
                            {file.name.length > 25 ? `${file.name.slice(0, 20)}...pdf` : file.name}
                          </p>

                        </div>
                      ))}
                    </div>
                  </div>
                  
                  {/* {showAlert && 
                    <div className=' w-full flex flex-col items-start'>
                      <div className='h-3'></div>
                      <SuccessAlert text={`Notice Created Successfully`} setShowAlert={setShowAlert} />
                    </div>
                  } */}
                </div>

                {/* upload image */}

                {/* <div>
                  <input type="file" onChange={handleImageChange} />
                  {image && <img src={image} alt="Uploaded" style={{ maxWidth: '100%' }} />}
                </div> */}

                <div className="h-12"></div>

                <div className="w-full flex justify-center items-center space-x-6">
                  {/* <div>
                    <button onClick={resetForm} className="w-40 h-10 text-lg rounded-md border-2 border-buttonColor text-buttonColor">
                      Reset
                    </button>
                  </div> */}

                  <div className="w-full">
                    <button onClick={handleSubmit} className="w-full h-10 text-lg rounded-md bg-buttonColor text-white">
                      Submit
                    </button>
                  </div>
                </div>

              </div>

              
              {(selectedStatus !== '' || selectedStatus !== null) && (fromDate !== null) ? (
                <>
                  {/* task assigner modal */}
                  <input type="checkbox" id="my-modal-1" className="modal-toggle" />
                  <div className="modal">
                    <div className="modal-box h-3/4 bg-white">
                      <label onClick={(e) => { setUsers([]); setSearchAssigner(''); usersRef.current.value = ''; }} htmlFor="my-modal-1" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                      <h3 className="font-bold text-lg text-red-500">Select Task Assigner</h3>
                      
                      <div className='w-full flex flex-col justify-center items-center my-3'>
                        <div className="form-control">
                          <div className="input-group ">
                            <input ref={usersRef} onChange={(e) => { setSearchAssigner(e.target.value) }} onKeyPress={(e) => { if (e.key === 'Enter') getAssigners(); }} type="text" placeholder="Search…" className="input input-bordered w-80 focus:outline-none bg-white" />
                            <button onClick={getAssigners} className="btn btn-square bg-buttonColor text-white border-none" >
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" /></svg>
                            </button>
                          </div>
                        </div>

                        <div className="h-5"></div>

                        <div className="overflow-y-auto max-h-[500px]">
                        {
                          users.length === 0 ?
                            <p className=' mt-16 font-medium text-lg'>Search Name Here</p> :
                            isUsersLoading ? 
                            <p>loading...</p> : 
                            
                            users.map((e, index) =>
                              <div key={index} onClick={()=>{userSelect(e)}} className="pb-3 w-[336px]">
                                <label htmlFor="my-modal-1" className="w-full border-none bg-transparent hover:bg-transparent">
                                  
                                  <div className={`flex py-3 pl-3 pr-5 w-full shadow bg-[#EDF1F8] rounded-md  text-left justify-start space-y-0 items-center space-x-4`}>
                                    <div className={`h-[50px] w-[50px] flex justify-start items-center`}>
                                      {e.PROPIC_NAME ? (
                                        <img src={picUrl} alt="logo" className="w-10 h-10 rounded-full" />
                                      ) : (
                                        <>
                                          <div className="bg-gray-300 w-10 h-10 rounded-full flex items-center justify-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                              <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
                                            </svg>
                                          </div>
                                        </>
                                      )}
                                    </div>

                                    <div className="flex flex-col w-full">
                                      <p className={`w-full text-gray-600 text-[16px] font-semibold`}>
                                        {`${e.EMPLOYEE_NAME} (${e.EMPLOYEE_ID})`}
                                      </p>
                                      <div className="h-2"></div>
                                      <span className={`rounded-md w-auto px-3 text-sm pb-1 bg-[#207833] text-white text-[16px]`}>
                                        {`${e.POSITION_NAME} `}
                                      </span>
                                    </div>
                                  </div>
                                </label>
                              </div>
                            )
                          }
                          </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : null }

              
              {(selectedStatus !== '') && (fromDate !== null) && (selectedUserName !== '') ? (
                <>
                  {/* notify email modal */}
                  <input type="checkbox" id="my-modal-2" className="modal-toggle" />
                  <div className="modal">
                    <div className="modal-box h-3/4 bg-white">
                      <label onClick={(e) => { setEmailUsers([]); setSearchAssigner(''); usersRef.current.value = ''; }} htmlFor="my-modal-2" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                      <h3 className="font-bold text-lg text-red-500">Select User for Notify Via Email</h3>
                      
                      <div className='w-full flex flex-col justify-center items-center my-3'>
                        <div className="form-control">
                          <div className="input-group ">
                            <input ref={usersRef} onChange={(e) => { setSearchViaEmail(e.target.value) }} onKeyPress={(e) => { if (e.key === 'Enter') getUserViaEmail(); }} type="text" placeholder="Search…" className="input input-bordered w-72 focus:outline-none bg-white" />
                            <button onClick={getUserViaEmail} className="btn btn-square text-white bg-buttonColor border-none">
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" /></svg>
                            </button>
                          </div>
                        </div>

                        <div className="h-5"></div>

                        
                        <div className="overflow-y-auto max-h-[500px]">
                        {
                          emailUsers.length === 0 ?
                            <p className=' mt-16 font-medium text-lg'>Search Name Here</p> :
                          isUsersLoading ? 
                            <p>loading...</p> : 
                            
                          emailUsers.map((e, index) =>
                            <div key={index} onClick={()=>{selectUserEmail(e)}} className="pb-3 w-[336px]">
                              <label htmlFor="my-modal-2" className="w-full border-none bg-transparent hover:bg-transparent">
                                  
                                <div className={`flex py-3 pl-3 pr-5 w-full shadow bg-[#EDF1F8] rounded-md  text-left justify-start space-y-0 items-center space-x-4`}>
                                  <div className={`h-[50px] w-[50px] flex justify-start items-center`}>
                                    { e.PROPIC_NAME ? (
                                      <img src={picUrl} alt="logo" className="w-10 h-10 rounded-full" />
                                    ) : (
                                      <>
                                        <div className="bg-gray-300 w-10 h-10 rounded-full flex items-center justify-center">
                                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
                                          </svg>
                                        </div>
                                      </>
                                    )}
                                  </div>

                                  <div className="flex flex-col w-full">
                                    <p className={`w-full text-gray-600 text-[16px] font-semibold`}>
                                      {`${e.EMPLOYEE_NAME} (${e.EMPLOYEE_ID})`}
                                    </p>
                                    
                                    <p className={`w-full text-gray-600 text-sm mt-0`}>
                                        {`${e.EMAIL} `}
                                    </p>
                                      
                                    <div className="h-2"></div>
                                      
                                    <span className={`rounded-md w-auto px-3 text-sm pb-1 bg-[#207833] text-white text-[16px]`}>
                                      {`${e.POSITION_NAME} `}
                                    </span>
                                  </div>
                                </div>
                              </label>
                            </div>
                          )
                        }
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : null }


              <div className="h-4"></div>

              {/* <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  onClick={closeApplyModal}
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                >
                  Cancel
                </button>
              </div> */}
            </div>
          </div>
        </div>
      )}

      <div className="h-6"></div>

      
    </div>
  )
}