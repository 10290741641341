import React, { useState, useEffect } from 'react';
import RoleAccessService from '../service/RoleAccessService';
import Loading from '../../components/loader/Loading';
import produce from 'immer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RoleInsertDeleteService from '../service/RoleInsertDeleteService';
import DeleteRoleService from '../service/DeleteRoleService';

export default function RoleManagementPage() {
  const [menuList, setMenuList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openClose,setOpenClose]=useState(false);
  const [ userId, setUserId] = useState("");
  const [deletedMenuId, setDeletedMenuId] = useState('');

  useEffect(() => {
    getRoles();
  }, []);

  useEffect(() => {
    const id = localStorage.getItem("userId");
    setUserId(id);
    // getAdminInfo(id);
  }, []);

  const getRoles = async () => {
    try {
      const re = await RoleAccessService(); // Assuming this function returns the data correctly
      const sorted = [...re.roles].sort((a, b) => a.ROLE_NAME.localeCompare(b.ROLE_NAME));
      setMenuList(sorted);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching roles:', error);
      setIsLoading(false);
    }
  };

  const showToastError = (message) => {
    toast.error(message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT }); // autoClose is in milliseconds
  };
  
  const showToastSuccess = (message) => {
    toast.success(message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT }); // autoClose is in milliseconds
  };
    

  const handleButtonClick = (menuIndex, buttonIndex, menuType) => {
    setMenuList(prevMenuList => {
      const updatedMenuList = [...prevMenuList];
      const updatedMenu = { ...updatedMenuList[menuIndex] };
      const updatedButton = { ...updatedMenu[menuType][buttonIndex] };
    
      updatedButton.IS_ASSOCIATED = updatedButton.IS_ASSOCIATED === 1 ? 0 : 1;
      updatedMenu[menuType][buttonIndex] = updatedButton;
      updatedMenuList[menuIndex] = updatedMenu;
    
      return updatedMenuList;
    });
  };

  const roleInsertDelete=async(roleId,menuId)=>{
    // setIsLoading(true);
    const re=await RoleInsertDeleteService(userId,roleId,menuId);
    if(re.Status===200){
      showToastSuccess(re.Message);
      // getRoles();
    }
    else{
      showToastSuccess('Failed');
      // getRoles(); 
    }  
  }

  const deleteRole=async(roleId)=>{
    try {
      setIsLoading(true);
      await DeleteRoleService(roleId); // Wait for the deletion to complete
      showToastSuccess('Deleted Successfully');
      getRoles();
    } catch (error) {
      // Handle any errors that may occur during the deletion
      console.error('Error deleting role:', error);
    } finally {
      setIsLoading(false);
      setDeletedMenuId('');
    }
  }

  const yes = (id) => {
    deleteRole(id)
    setDeletedMenuId('');
  }

  const deleteCancel = () => {
    setOpenClose(false); 
    setDeletedMenuId(''); 
  }
    

  return (
    <div>
      <div className='my-20 px-8'>
        <ToastContainer/>
        { 
          isLoading ?
            <div className='w-full h-screen flex justify-center items-center'>
              <Loading />
            </div>
            
            :
              <div className='flex flex-col items-start'>
                <div className='h-6'></div>
                  <div className='w-full h-14 flex flex-row space-x-4 items-center rounded-t-md border-[0.5px] border-gray-200 '>
                    <div className='w-40 h-full text-sm  text-black font-semibold flex justify-center items-center'>Role</div>
                    
                    <div className="w-40 h-full text-sm  text-black font-semibold flex justify-center items-center">Lebel</div>
                        
                    <div className="flex-1 h-full text-sm  text-black font-semibold flex justify-center items-center">Permission Menu</div>
                        
                    <div className='w-14 h-full text-sm  text-black font-semibold flex justify-center items-center'>Action</div>
                  </div>

                  {menuList.map((menu, k) => (
                    <div key={k} className={`w-full py-4 flex flex-row space-x-4 ${k === menuList.length - 1 ? "rounded-b-md" : ""} items-start border-r-[0.5px] border-b-[0.5px] border-l-[0.5px] border-gray-200`}>
                      <div className='w-40 h-full flex justify-center items-center text-sm font-bold text-blackColor'>{menu.ROLE_NAME}</div>

                      <div className='flex-1 flex-col items-start space-y-10'>
                        <div className='flex-1 h-full flex flex-row space-x-4 items-start'>
                          <div className="w-40 h-full flex justify-center items-center text-sm font-mon font-medium text-blackColor">
                            Menu Mobile
                          </div>
                          
                          <div className="flex-1 h-full flex justify-center items-center text-sm font-mon font-medium text-blackColor">
                            <div className='grid grid-cols-4 gap-6'>
                              {menu.Menu_Mobile.map((e, i) => (
                                <button 
                                  onClick={() =>{ handleButtonClick(k, i,"Menu_Mobile");roleInsertDelete(menu.ROLE_ID,e.MENU_ID)}}
                                  key={i} className='flex text-start flex-row space-x-2 items-center w-40'>
                                  
                                  <div className={`rounded-[4px] w-4 h-4 ${e.IS_ASSOCIATED === 1 ? "bg-green-600 border-none" : "bg-white border-[0.1px] border-gray-300"}  flex justify-center items-center`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-3 h-3 text-white">
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                    </svg>
                                  </div>
                                  
                                  <p className='text-xs  font-medium text-black'>{e.MENU_NAME}</p>
                                </button>
                              ))}
                            </div>
                          </div>
                        </div>
                        
                        
                        <div className='flex-1 h-full flex flex-row space-x-4 items-start'>
                          <div className="w-40 h-full flex justify-center items-center text-sm font-mon font-medium text-blackColor">
                            Menu Web
                          </div>
                          
                          <div className="flex-1 h-full flex justify-center items-center text-sm font-mon font-medium text-blackColor">
                            <div className='grid grid-cols-4 gap-6'>
                              {menu.Menu_Web.map((f, j) => (
                                <button
                                  onClick={() => {handleButtonClick(k, j,"Menu_Web"); roleInsertDelete(menu.ROLE_ID,f.MENU_ID)}}
                                  key={j} className='flex text-start flex-row space-x-2 items-center w-40'>
                                  
                                  <div className={`rounded-[4px] w-4 h-4 ${f.IS_ASSOCIATED === 1 ? "bg-green-600 border-none" : "bg-white border-[0.1px] border-gray-300"}  flex justify-center items-center`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-3 h-3 text-white">
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                    </svg>
                                  </div>
                                  
                                  <p className='text-xs  font-medium text-black'>{f.MENU_NAME}</p>
                                </button>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>

                      {
                        menu.CAN_DELETE===1 ?

                          <button onClick={()=>{ 
                            setOpenClose(true)
                            // yes(menu.ROLE_ID)
                            setDeletedMenuId(menu.ROLE_ID)
                            // deleteRole(menu.ROLE_ID); 
                          }} className=' pr-4' >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                            </svg>

                          </button>
                        :
                          <div className="w-10"></div>    
                      }
                    </div>
                  ))}
                </div>
            }
        </div>

        <input
                type="checkbox"
                id="my-modal-11"
                className="modal-toggle"
                checked={openClose}
            />
            <div className="modal">
                <div className="modal-box w-96">
                    <div className=" w-full h-56 flex items-center flex-col justify-center bg-white">
                        <div className=' flex justify-center mb-5'>
                            <img src="/images/delete.png" alt="delete" className='w-12 h-12' />
                        </div>

                        <p className="text-black font-bold text-[18px] ">
                            Warning !!!
                        </p>

                        <p className="text-black text-[18px] text-center">
                            Are you sure you want to delete this role?
                        </p>
                        <div className="h-6"></div>
                        <div className=" w-full flex flex-row justify-between items-center gap-4">
                            <button 
                                onClick={() => { 
                                    deleteCancel();
                                }} 
                                className=" px-4 w-36 py-2 mr-2 text-gray-600 bg-white border-[0.5px] border-gray-700 rounded-lg">
                                
                                NO
                            </button>

                            <button
                                onClick={() => {
                                    // deleteRole(menu.ROLE_ID); 
                                    yes(deletedMenuId);
                                    setOpenClose(false);
                                }}
                                className=" px-4 w-36 py-2 bg-red-500 text-white rounded-lg"
                            >
                                YES
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        
    );
}
