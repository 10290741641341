import { baseUrl } from "../../utils/path";




const SendNoticeNotificationService = async (id) => {
    const url=`${baseUrl}send_notice_notifications`;
    console.log(id);
     const response = await fetch(url,
         {
             method: "POST",
             headers: {
 
                 "Content-Type": "application/json",
                 'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
             },
             body: JSON.stringify(
                {"NOTICE_ID": id }
             ),
         }
     );
    
     const data = await response.json();
    
     return data;
 
 
 }
 export default SendNoticeNotificationService
 