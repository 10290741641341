import React,{useState} from 'react'
import { useRequisitionContext } from '../context/RequisitionContext';
import PendingRequisitionPage from './PendingRequisitionPage';
import ApprovedRequisitionPage from './ApprovedRequisitionPage';
import RejectedRequisitionPage from './RejectedRequisitionPage';

export default function MyRequisitionPage() {
   
    const [menu,setMenu]=useState(1);
    return (
    <div className=' my-20 '>
        <div className=' w-full h-14 shadow-sm bg-gray-100 px-8 flex flex-row justify-between items-center'>
            <button onClick={()=>{setMenu(1)}} className={`w-52 h-10 flex justify-center items-center ${menu===1?"text-white bg-green-400":"text-gray-400 bg-gray-100"} rounded-full`}>PENDING</button>
            <button onClick={()=>{setMenu(2)}} className={`w-52 h-10 flex justify-center items-center ${menu===2?"text-white bg-green-400":"text-gray-400 bg-gray-100"} rounded-full`}>APPROVED</button>
            <button onClick={()=>{setMenu(3)}} className={`w-52 h-10 flex justify-center items-center ${menu===3?"text-white bg-green-400":"text-gray-400 bg-gray-100"} rounded-full`}>REJECTED</button>
        </div>

    <div className='h-6'></div>

<div className='px-8'>
     {(() => {
            switch (menu) {
              case 1:
                return <PendingRequisitionPage />
              case 2:
                return <ApprovedRequisitionPage />
              case 3:
                return <RejectedRequisitionPage />
             

              default:
                return null
            }
          })()}
    </div>

    </div>
  )
}
