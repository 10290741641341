import { baseUrl } from "../../utils/path";

const DeleteApplicationService = async(empId, module, applicationID)=>{
   
  const url=`${baseUrl}delete_applications`;
  
  console.log(`empId:${empId} module: ${module} applicationID: ${applicationID}`);
   
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
      },
        
      body: JSON.stringify({
        "EMPLOYEE_ID": empId,
        "MODULE_NAME": module,
        "APPLICATION_ID": applicationID
                  
      }),
    });
      
    const data = await response.json();
    console.log(`222 empId:${empId} module: ${module} applicationID: ${applicationID}`);
    
    return data;

 }
 export default DeleteApplicationService
 