import { baseUrl } from "../../utils/path";



const InsertNoticeImageService = async (id,imageString) => {
    const url=`${baseUrl}insert_notice_doc`;
     // console.log(`em:${empId} se: ${sequence} st: ${status} md: ${moduleId} au: ${authorizationId} ap: ${approvalId}`);
     console.log(`im base: ${imageString} id:${id}`);
     const response = await fetch(url,
         {
             method: "POST",
             headers: {
 
                 "Content-Type": "application/json",
                 'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
             },
             body: JSON.stringify(
                {"NOTICE_ID": id, "NOTICE_DOCS": imageString}
             ),
         }
     );
    
     const data = await response.json();
     console.log(`im: ${data}`);
     return data;
 
 
 }
 export default InsertNoticeImageService
 