import { baseUrl } from "../../utils/path";


const url = `${baseUrl}hierarchy_report`;

const HierarchyAssignReportService = async ( EMPLOYEE_ID, COMPANY_CODE, PLANT_CODE, P_OFFSET, P_LIMIT ) => {
    
    // console.log(`uid: ${userId} lat ${lat}  long ${long} ra${radius} cc${companyCode}  pc${PlantCode}`);
    console.log(`empId:${EMPLOYEE_ID} compCode: ${COMPANY_CODE} pCode: ${PLANT_CODE}  offset: ${P_OFFSET} limit: ${P_LIMIT}`);

    const response = await fetch(url, {
      method: "POST",
      headers: {
          "Content-Type": "application/json",
          Authorization:
              "Basic " +
              btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
      },
      body: JSON.stringify({
        "EMPLOYEE_ID":EMPLOYEE_ID ,
        "COMPANY_CODE":COMPANY_CODE ,
        "PLANT_CODE":PLANT_CODE ,
        "P_OFFSET": P_OFFSET,
        "P_LIMIT":P_LIMIT 
      }),
    });
    // if (!response.ok) {
    //     throw new Error("fetching is not successful");
    //   }

    const data = await response.json();
    console.log("service data", data['ATTENDANCE_DETAILS']);
    return data;

};
export default HierarchyAssignReportService;
