import { baseUrl } from "../../utils/path";


  

  const GetApproverService= async(applicantId,moduleType,objectId)=>{

    console.log(applicantId);
    console.log(moduleType);
    console.log(objectId);
    const url=`${baseUrl}approver_details_by_module`;

    const response = await   fetch(url,
        {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
            },
            body: JSON.stringify(
                {
                    "ORIGINATOR": applicantId,
                    "MODULE_TYPE": moduleType,
                    "OBJECT_ID": objectId
                  }
            ),
          }
        );
        if (!response.ok) {
            throw new Error("fetching is not successful");
          }
        
          const data = await response.json();
         
          return data;

     
}
export default GetApproverService
