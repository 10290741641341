import { baseUrl } from "../../utils/path";



const LeaveApplySubmitService = async(empId, applyDate, leaveCode, dateFrom, dateTo, recommenderId, note, leaveDays, companyCode, plantCode, psg, taskAssId)=>{

  const url = `${baseUrl}leave_request_submit`;

  console.log( `id:${empId} today: ${applyDate} leaveCode: ${leaveCode} sDate: ${dateFrom} eDate: ${dateTo} recId: ${recommenderId} note: ${note} totalDay: ${leaveDays} compCode:${companyCode} plant: ${plantCode} taskId: ${taskAssId}`);
    
  const response = await fetch(url,
      {
          method: "POST",
          headers: {
            
            "Content-Type": "application/json",
            'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
          },
          body: JSON.stringify({
            "EMPLOYEE_ID": empId,
            "APPLICATION_DATE": applyDate,
            "LEAVE_TYPE": leaveCode,
            "DATE_FROM": dateFrom,
            "DATE_TO": dateTo,
            "APPROVER_ID": recommenderId,
            "NOTE": note,
            "AUTHORIZATION_STATUS": "IN PROCESS",
            "LEAVE_DAYS": leaveDays,
            "COMPANY_CODE": companyCode,
            "PLANT_CODE": plantCode,
            "MODULE_TYPE": "LEAVE",
            "PSG": psg,
            "TASK_ASSIGNER_ID": taskAssId,
            "IS_TASK_ASSIGNER_ACCEPTED": 3
          }),
        }
      );
      // if (!response.ok) {
      //     throw new Error("fetching is not successful");
      //   }
        const data = await response.json();
        console.log(`users: ${data}`);
        return data;

   
}
export default LeaveApplySubmitService;
