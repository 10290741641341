import { baseUrl } from "../../utils/path";


  

  const MyMoveOrderService= async(
    empId,
    page,
    limit
    )=>{

    const url=`${baseUrl}my_move_orders`;

    const response = await   fetch(url,
        {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
            },
            body: JSON.stringify(
                {
                
                    "EMP_ID": empId,
                    "P_OFFSET": page,
                    "P_LIMIT": limit
            }
            ),
          }
        );
        if (!response.ok) {
            throw new Error("fetching is not successful");
          }
        
          const data = await response.json();
         
          return data;

     
}
export default MyMoveOrderService
