import { baseUrl } from "../../utils/path";

const IouItemDetailsService= async(iouId) => {

  const url=`${baseUrl}pending_iou_items`;

  const response = await fetch(url,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
      },
      
      body: JSON.stringify({
        "IOU_ID" : iouId
      }),
    }
  );

  if (!response.ok) {
    throw new Error("fetching is not successful");
  }
          
  const data = await response.json();
  return data; 
}

export default IouItemDetailsService
