import { useEffect, useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from "moment";
import ApproverHierarchyListService from "../../move_order/service/ApproverHierarchyListService";
import IouItemDetailsService from "../../iou/service/IouItemDetailsService";
import AdminInfoService from "../../services/AdminInfoService";
import { useRequisitionContext } from "../context/RequisitionContext";
import RequisitionItemService from "../service/RequisitionItemService";
import { baseUrl } from "../../utils/path";
import numeral from "numeral";
import Loading from "../../components/loader/Loading";

const infoUrl= `${baseUrl}my_info`;


export default function RequisitionDetailsPopup() {
  const [isOpen, setIsOpen] = useState(false);
  const [approverList, setApproverList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [employeeId, setEmployeeId] = useState('');
  const [designation, setDesignation] = useState('');
//   const iouContext = useIouContext();
  const [empId, setEmpId] = useState('');
  const [joiningDate, setJoiningDate] = useState('');
  const [requisitionItemDetails, setRequisitionItemDetails] = useState([]);
  const {isDelete,setIsDelete,details,setDetails,isPopUp,setIsPopUp, deleteRowId, setDeleteRowId } = useRequisitionContext();

  const [total,setTotal]=useState();
  const [inwords,setInWords]=useState('');

  const statusBackgroundColor = getStatusBackgroundColor(details.STATUS);

  const handleIsDelete=(id)=>{
    setIsDelete(true);
    setDeleteRowId(id)
  }
  
  useEffect(() => {
    // When the component mounts, set isOpen to true to trigger the opening transition
    setIsOpen(true);
  }, []);
  
  const handleClose = () => {
    // When closing, set isOpen to false to trigger the closing transition
    setIsOpen(false);
    setDetails({});
    setIsDelete(false);

    // Wait for the closing transition to complete before actually closing
    setTimeout(() => {
      setIsPopUp(false);
    }, 200); // Adjust the timeout to match your transition duration
  };
  
  
    useEffect(() => {
      setIsLoading(true);
      const eid = localStorage.getItem('empId');
      setEmployeeId(eid);
      const id = localStorage.getItem('userId');
      getAdminInfo(id);
      getItem();
      getHierarchy(details.REQUSTED_BY_ID, details.REQUISITION_TYPE === "General" ? "REQUISITION GENERAL" : details.REQUISITION_TYPE === "IT" ? "REQUISITION IT" : details.REQUISITION_TYPE === "Supply Chain" ? "REQUISITION SUPPLY CHAIN" : "REQUISITION HEAVY EQUIPMENT" , details.REQ_ID);
      setIsLoading(false);
    }, []);

    

    const getItem=async()=>{
      setIsLoading(true);
      const re=await RequisitionItemService(details.REQ_ID);
      console.log("service requisition", re);
      setRequisitionItemDetails(re.item_details);
      let sum=0;
      for(let i=0;i<re.item_details.length;i++) {
        if(re.item_details[i].UNIT_PRICE ){
          sum=sum+(re.item_details[i].ITEM_QUANTITY*re.item_details[i].UNIT_PRICE)  
        }    
      }
      setTotal(sum);
      setInWords(test(sum));
      setIsLoading(false);
    }
  
    // generate pdf for downloading
    // const generatePDF = () => {
    //   console.log("download button clicked")
  
    //   const element = document.getElementById('pdf');
    //   html2canvas(element).then((canvas) => {
    //     const imgData = canvas.toDataURL('image/png');
    //     const pdf = new jsPDF('portrait', 'mm', 'a4');
  
    //     pdf.addImage(imgData, 'PNG', 10, 10);
    //     pdf.save(`MY_REQUISITION_${moment(details.CREATION_DATE).format('DD-MM-yyyy')}.pdf`);
    //   });
    // };

    const generatePDF = () => {
      console.log("download button clicked")
  
      const element = document.getElementById('pdf');
      setIsLoading(true);
      html2canvas(element).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');

        const componentWidth = pdf.internal.pageSize.getWidth();
        const componentHeight = pdf.internal.pageSize.getHeight();
  
        pdf.addImage(imgData, 'PNG', 5, 0, componentWidth, componentHeight);

        setIsLoading(false);

        pdf.save(`MY_REQUISITION_${moment(details.CREATION_DATE).format('DD-MM-yyyy')}.pdf`);
      });
    };
    
    const [actionCode, setActionCode] = useState('');

    const getHierarchy = async (id, module, object) => {
      setIsLoading(true);
      const re = await ApproverHierarchyListService(id, module, object);
      const sortedData = re.APPROVER_LIST.sort((a, b) => a.STAGE_SEQ - b.STAGE_SEQ);
      setApproverList(sortedData);
      setActionCode(sortedData[0].ACTION_CODE)
      // console.log("hierarchy requisition: ", approverList);
      setIsLoading(false);
    }
  
    const getAdminInfo = async (uid) => {
      const admin = await AdminInfoService(infoUrl, uid);
      // setAdminDetails(admin.EMP_DETAILS);
      const eId = admin.EMP_DETAILS[0].EMPLOYEE_ID;
  
      const designation = admin.EMP_DETAILS[0].DESIGNATION;
      const joiningDate = admin.EMP_DETAILS[0].JOINING_DATE;
      setEmpId(eId);
      setDesignation(designation);
      setJoiningDate(joiningDate);
    }
  
    const convertDate = (dateString) => {
      const dateObject = new Date(dateString);
      const day = String(dateObject.getUTCDate()).padStart(2, '0');
      const month = String(dateObject.getUTCMonth() + 1).padStart(2, '0');
      const year = dateObject.getUTCFullYear();
  
      return `${day}/${month}/${year}`;
    };

    function test(n) {
      if (n < 0)
        return false;
      
        const  single_digit = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine']
        const   double_digit = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen']
        const   below_hundred = ['Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety']
          
        if (n === 0) return 'Zero'
          
        function translate(n) {
          let  word = ""
          if (n < 10) {
            word = single_digit[n] + ' '
          }
          else if (n < 20) {
            word = double_digit[n - 10] + ' '
          }
          else if (n < 100) {
            const  rem = translate(n % 10)
            word = below_hundred[(n - n % 10) / 10 - 2] + ' ' + rem
          }
          else if (n < 1000) {
            word = single_digit[Math.trunc(n / 100)] + ' Hundred ' + translate(n % 100)
          }
          else if (n < 1000000) {
            word = translate(parseInt(n / 1000)).trim() + ' Thousand ' + translate(n % 1000)
          }
          else if (n < 1000000000) {
            word = translate(parseInt(n / 1000000)).trim() + ' Million ' + translate(n % 1000000)
          }
          else {
            word = translate(parseInt(n / 1000000000)).trim() + ' Billion ' + translate(n % 1000000000)
          }
          return word
        }
          
        const   result = translate(n) 
        return result.trim()+' taka only.'
      }
  
    // Define background color based on status
    function getStatusBackgroundColor(status) {
      switch (status) {
        case "IN PROCESS":
          return 'bg-[#FDCA4D] text-black border-black';
        case "APPROVED":
          return 'bg-[#2D833F] text-white';
        case "REJECTED":
          return 'bg-[#E20000] text-white';
        default:
          return 'bg-transparent'; // Default bg color
      }
    }

    // calculate total amount
  const totalAmount = requisitionItemDetails.reduce((acc, e) => {
    const itemAmount = isNaN(e.ITEM_QUANTITY * e.UNIT_PRICE) ? 0 : e.ITEM_QUANTITY * e.UNIT_PRICE;
    return acc + itemAmount;
  }, 0);

  // using for see more and see less
  const [seeMore, setSeeMore] = useState(-1);

  const handleSeeMoreDetails = (index) => {
    setSeeMore(index === seeMore ? -1 : index);
  };


  return (
    <div className={`my-14 fixed top-0 right-0 w-[400px] h-full bg-white border-l border-gray-300 pb-4 pt-2 transition-transform ${
      isOpen ? 'translate-x-0' : 'translate-x-full'
    }`}>
      <div className="max-h-full no-scrollbar overflow-y-auto transition-transform ease-in-out duration-700">

        {/* Add a button or an element to close the popup */}
        { isLoading ? <Loading /> : (
        <div className={`px-3 py-4 flex flex-row items-center justify-between space-x-3 ${statusBackgroundColor}`}>
          <div className='flex items-center justify-between space-x-2 w-full'>
            <div className='flex items-center space-x-2 w-full'>
              <button className={`py-2 rounded-md border-[0.5px] hover:border-gray-400 px-3`} onClick={handleClose}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
                </svg>
              </button>

              <p className={`font-semibold `}>Status: {details.STATUS}</p>
            </div>
            

            <div className='flex items-center space-x-2'>
                {/* <div className=''>
                  { 
                    details.STATUS==="APPROVED" ?
                      <button onClick={generatePDF} className='py-2 px-2 rounded-md border-[0.5px] font-medium text-white'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                        </svg>
                      </button>
                    :
                      <div></div>
                  }
                </div> */}
  
              <div className="">
                { 
                  details.STATUS==="IN PROCESS" && actionCode === 3 ?
                    <button onClick={() => handleIsDelete(details.REQ_ID)} className='py-2 px-2 rounded-md text-[16px] font-medium text-[#EC5555]'>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                      </svg>
                    </button>
                    :
                    <div></div>
                }
              </div>
            </div>
          </div>

          {/* <div>
              <p className={`border-[3px] px-4 py-4 rounded-full ${getStatusBorderColor(details.AUTHORIZATION_STATUS)}`}></p>
          </div> */}
        </div>
        )}

        {/* <div className="h-4"></div> */}


        {/* Render details data here */}
        { isLoading ? <Loading /> : (
        <div className='space-y-3'>
          {/* <p className="my-4 font-semibold px-3">Status: {details.AUTHORIZATION_STATUS}</p> */}
          
          <div>
            <p className='bg-[#E6E1DD] px-3 py-2 font-semibold'>Employee Details</p>
            <p className='px-3 border-b-[0.5px] pb-2'>Applicant Name: {details.EMPLOYEE_NAME == null ? "N/A" : details.EMPLOYEE_NAME}</p>
            <p className='px-3 border-b-[0.5px] pb-2'>Applicant ID: {details.REQUSTED_BY_ID == null ? "N/A" : details.REQUSTED_BY_ID}</p>
            <p className='px-3 border-b-[0.5px] pb-2'>Designation: {designation}</p>
            <p className='px-3 border-b-[0.5px] pb-2'>Joining Date: {convertDate(joiningDate)}</p>
          </div>

          <div>
            <p className='bg-[#E6E1DD] px-3 py-2 font-semibold'>Requisition Details</p>

            <p className='px-3 border-b-[0.5px] pb-2'>Application Date: {convertDate(details.CREATION_DATE)}</p>
            <p className='px-3 border-b-[0.5px] pb-2'>Company Name: {details.COMPANY_NAME == null ? "N/A" : details.COMPANY_NAME}</p>
            <p className='px-3 border-b-[0.5px] pb-2'>Plant Name: {details.PLANT_NAME == null ? "N/A" : details.PLANT_NAME}</p>
            <p className='px-3 border-b-[0.5px] pb-2'>Requisition Type: {details.REQUISITION_TYPE == null ? "N/A" : details.REQUISITION_TYPE}</p>

            <p className='px-3 border-b-[0.5px] pb-2'>Total Amount: {details.TOTAL_AMOUNT ? `${numeral(total).format('0,0')} ৳` : "N/A"} </p>

            <p className='px-3 border-b-[0.5px] pb-2'>Payment Method: {details.PAYMENY_METHOD == null ? "N/A" : details.PAYMENY_METHOD}</p>
          </div>
          
          <div>
            <table className="w-full bg-white border-[0.5px] border-gray-200 mt-1">
              {/* head */}
              <thead className='bg-bgTableHeader text-tableHeaderText w-full h-12'>
                <tr className='bg-[#E6E1DD]'>
                  <th className='py-3 px-3 text-left'>Item Details</th>
                  <th className='py-3 px-3 text-left'>Quantity</th>
                  <th className='py-3 px-3 text-left'>Rate</th>
                  <th className='py-3 px-3 text-left'>UOM</th>
                  <th className='py-3 px-3 text-left'>Amount</th>
                </tr>
              </thead>

              <tbody>
                {
                  requisitionItemDetails.map((e, index) =>
                    <tr key={index} className='px-3 border-b-[0.5px]'>
                      {/* width with ellipsis */}
                      {/* <td style={{ maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                        {e.ITEM_DESC == null ? "N/A" : e.ITEM_DESC}
                      </td> */}

                      <td style={{ whiteSpace: 'normal', wordBreak: 'break-word' }} className='px-3 py-2 text-left whitespace-nowrap'>
                        {e.ITEM_DESC == null ? "N/A" : (
                          <>
                            {seeMore === index ? (
                              <div>
                                {e.ITEM_DESC}
                                <button className='text-gray-500' onClick={() => handleSeeMoreDetails(index)}>See Less</button>
                              </div>
                            ) : (
                              <div>
                                {e.ITEM_DESC.length > 14 ? (
                                  <>
                                    {e.ITEM_DESC.substring(0, 14)}...
                                    <button className='text-gray-500' onClick={() => handleSeeMoreDetails(index)}>See More</button>
                                  </>
                                ) : (
                                  <div>{e.ITEM_DESC}</div>
                                )}
                              </div>
                            )}
                          </>
                        )}
                      </td>
                      
                      <td className='px-3 py-2 text-left whitespace-nowrap'>{e.ITEM_QUANTITY == null ? "N/A" : e.ITEM_QUANTITY}</td>
                      <td className='px-3 py-2 text-left whitespace-nowrap'>{e.UNIT_PRICE == null ? "N/A" : e.UNIT_PRICE}</td>
                      <td className='px-3 py-2 text-left whitespace-nowrap'>{e.UOM == null ? "N/A" : e.UOM}</td>
                      <td className='px-3 py-2 text-left whitespace-nowrap'>{isNaN(e.ITEM_QUANTITY*e.UNIT_PRICE) ? "N/A" : `${numeral(e.ITEM_QUANTITY * e.UNIT_PRICE).format('0,0')}`}</td>
                    </tr>
                   )
                }
              </tbody>

              <tbody>
                <tr className="bg-white">
                  <td colSpan="4" className='px-3 py-2 text-left whitespace-nowrap'>
                    Total Amount:
                  </td>
                  
                  <td className='px-3 py-2 text-left whitespace-nowrap'>
                    {numeral(totalAmount).format('0,0')}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className=''>
            <p className='bg-[#E6E1DD] px-3 py-2 font-semibold'>Approve/Reject history</p>
            
            <div className="h-3"></div>

            <div className='px-3 w-5/6'>
              {
                approverList.map((e, i) => (
                  <div className='mt-3 flex flex-col items-start space-y-4' key={i}>
                    
                    {/* <div className="h-[2px]"></div> */}

                    <div className='flex flex-row items-center space-x-6 w-96 '>
                      <div className={`w-6 h-6 p-[2px] rounded-full border-[1px] border-orange-500 flex justify-center items-center`}>
                        {
                          e.ACTION_CODE === 1
                          ?
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 text-green-500">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                          :
                          e.ACTION_CODE === 0 ?
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 text-red-500">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>

                          :
                          null
                        }
                      </div>

                      <div className=' flex flex-col items-start space-y-1'>
                        <p className='  font-medium text-black w-5/6'>{e.EMPLOYEE_NAME} <span className="text-[#7F7F7F]">({e.AUTHORIZER_TYPE})</span></p>
                        <p>{
                          e.ACTION_CODE === 1 ?
                            <div className=' flex flex-col items-start space-y-1'>
                              <p className=' font-medium text-green-500'>
                                {
                                  e.AUTHORIZER_TYPE === "RECOMMENDER" ? "RECOMMENDED" : "APPROVED"
                                }
                              </p>
                              
                              <p className=' font-medium text-green-500'>
                                {
                                  e.NOTE === null ? "N/A" : e.NOTE
                                }
                              </p>
                            </div>

                          : e.ACTION_CODE === 3 ?
                            <p className=' text-orange-500 font-medium'>
                              PENDING
                            </p>
                              :
                            <div className=' flex flex-col items-start space-y-1'>
                              <p className=' text-red-500 font-medium' >
                                {
                                  e.AUTHORIZER_TYPE === "RECOMMENDER" ? "NOT RECOMMENDED" : "NOT APPROVED" 
                                }
                              </p>
                              
                              <p className=' text-red-500 font-medium' >
                                {
                                  e.NOTE === null ? "N/A" : e.NOTE
                                }
                              </p>
                            </div>
                          }
                        </p>
                        
                      <div className="h-4"></div>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
            
          <div className="h-32"></div>
        </div>
        )}
      </div>
    </div>
  );
}