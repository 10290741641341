import React, { useEffect, useState } from 'react'
import ApproverHierarchyListService from "../service/ApproverHierarchyListService";
import { useMoveOrderContext } from '../context/MoveContext';

import moment from 'moment';
import numeral from 'numeral';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import Loading from '../../components/loader/Loading';

export default function MoveOrderDetailsPopup() {
    const [isOpen, setIsOpen] = useState(false);
    const [approverList, setApproverList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [employeeId, setEmployeeId] = useState('');
    const {isDelete,setIsDelete,details,setDetails,isPopUp,setIsPopUp, deleteRowId, setDeleteRowId}=useMoveOrderContext();
  
  const handleIsDelete=(id)=>{
    setIsDelete(true);
    setDeleteRowId(id)
  }
    const statusBackgroundColor = getStatusBackgroundColor(details.AUTHORIZATION_STATUS);
  
    useEffect(() => {
      // When the component mounts, set isOpen to true to trigger the opening transition
      setIsOpen(true);
    }, []);
  
    const handleClose = () => {
      // When closing, set isOpen to false to trigger the closing transition
      setIsOpen(false);
      setDetails({});
      setIsDelete(false);
      // Wait for the closing transition to complete before actually closing
      setTimeout(() => {
        
        setIsPopUp(false);
      }, 200); // Adjust the timeout to match your transition duration
    };
  
  
    useEffect(() => {
      
      const eid = localStorage.getItem('empId');
      setEmployeeId(eid);
      getHierarchy(eid, details.IS_VEHICLE_NEED === 1 ? "TRANSPORT MOVE ORDER" : "MOVE ORDER", details.MO_ID);
      const handleBackButton = (e) => {
        e.preventDefault();
        e.stopPropagation();
      };
      window.addEventListener('popstate', handleBackButton);
  
      return () => {
        window.removeEventListener('popstate', handleBackButton);
      };

      
    }, []);
  
    // generate pdf for downloading
    const generatePDF = () => {
      const element = document.getElementById('pdf');
      html2canvas(element).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('portrait', 'mm', 'a4');
  
        pdf.addImage(imgData, 'PNG', 10, 10);
        pdf.save(`MY_MOVE_ORDER_${moment(details.CREATION_DATE).format('DD/MM/yyyy')}.pdf`);
      });
    };

    const [actionCode, setActionCode] = useState('');
  
    const getHierarchy = async (id, module, object) => {
      setIsLoading(true);
      const re = await ApproverHierarchyListService(id, module, object);
      const sortedData = re.APPROVER_LIST.sort((a, b) => a.STAGE_SEQ - b.STAGE_SEQ);
      setApproverList(sortedData);
      setActionCode(sortedData[0].ACTION_CODE)
      // console.log("hierarchy move order: ", approverList[0].ACTION_CODE);
      setIsLoading(false);
    }
  
    const convertDate = (inputDate) => {
      const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ];
  
      const date = new Date(inputDate);
      const day = date.getUTCDate();  // Use getUTCDate to get the day in UTC
      const monthName = months[date.getUTCMonth()];  // Use getUTCMonth to get the month in UTC
      const year = date.getUTCFullYear();  // Use getUTCFullYear to get the year in UTC
  
      return `${day} ${monthName} ${year}`;
    };
    
    const convertTo12HourFormat = (dateString) => {
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true,
        timeZone: 'UTC'
      };
  
      const date = new Date(dateString);
      date.setHours(date.getHours() - 6); // Subtract 6 hours
      return date.toLocaleString('en-US', options);
    };
  
    // Define background color based on status
    function getStatusBackgroundColor(status) {
      switch (status) {
        case "IN PROCESS":
          return 'bg-[#FDCA4D] text-black border-black';
        case "APPROVED":
          return 'bg-[#2D833F] text-white';
        case "REJECTED":
          return 'bg-[#E20000] text-white';
        default:
          return 'bg-transparent'; // Default bg color
      }
    }
  
    // Customize this component based on the structure of your details data
    return (
      <div className={`my-14 fixed top-0 right-0 w-3/12 h-full bg-white border-l border-gray-300 pb-4 pt-2 transition-transform ${
        isOpen ? 'translate-x-0' : 'translate-x-full'
      }`}>
        
        <div className="max-h-full no-scrollbar overflow-y-auto transition-transform ease-in-out duration-700">
  
          {/* Add a button or an element to close the popup */}
          <div className={`px-3 py-4 flex flex-row items-center justify-between space-x-3 ${statusBackgroundColor}`}>
            <div className="flex items-center justify-between space-x-2 w-full">
              <div className='flex items-center space-x-2'>
                <button className={`py-2 rounded-md border-[0.5px] hover:border-gray-400 px-3`} onClick={handleClose}>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
                  </svg>
                </button>
  
                <p className={`font-semibold `}>Status: {details.AUTHORIZATION_STATUS}</p>
              </div>

              <div className=''>
                {/* {
                  details.AUTHORIZATION_STATUS==="APPROVED" ?
                    <button onClick={generatePDF} className='py-2 px-2 rounded-md border-[0.5px] text-[16px] font-medium text-white'>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                      </svg>
                    </button>
                  :
                  <div></div>
                } */}

                { 
                  details.AUTHORIZATION_STATUS==="IN PROCESS" && actionCode === 3 ?
                    <button onClick={() => handleIsDelete(details.MO_ID)} className='py-2 px-2 rounded-md text-[16px] font-medium text-[#EC5555]'>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                      </svg>
                    </button>
                    :
                    <div></div>
                }
              </div>
            </div>
  
            {/* <div>
                <p className={`border-[3px] px-4 py-4 rounded-full ${getStatusBorderColor(details.AUTHORIZATION_STATUS)}`}></p>
            </div> */}
          </div>
  
          {/* <div className="h-4"></div> */}
  
  
          {/* Render details data here */}
          <div className='space-y-3'>
            {/* <p className="my-4 font-semibold px-3">Status: {details.AUTHORIZATION_STATUS}</p> */}
            
            <div>
              <p className='bg-[#E6E1DD] px-3 py-2 font-semibold'>Employee Details</p>
              <p className='px-3 border-b-[0.5px] pb-2'>Applicant Name: {details.EMPLOYEE_NAME}</p>
              <p className='px-3 border-b-[0.5px] pb-2'>Applicant Name: {details.EMPLOYEE_ID}</p>
            </div>
  
            <div>
              <p className='bg-[#E6E1DD] px-3 py-2 font-semibold'>Move Order Details</p>
  
              <p className='px-3 border-b-[0.5px] pb-2'>Task Type: {details.TASK_TYPE}</p>
              <p className='px-3 border-b-[0.5px] pb-2'>Start From-To: {details.PLACE_FROM} To {details.PLACE_TO}</p>
              <p className='px-3 border-b-[0.5px] pb-2'>Request Date: {convertDate(details.CREATION_DATE)}</p>
              <p className='px-3 border-b-[0.5px] pb-2'>Start Date: {convertDate(details.START_DATE)}</p>
              <p className='px-3 border-b-[0.5px] pb-2'>End Date: {convertDate(details.END_DATE)}</p>
              <p className='px-3 border-b-[0.5px] pb-2'>Time Out: {convertTo12HourFormat(details.END_TIME)}</p>
              <p className='px-3 border-b-[0.5px] pb-2'>Time In: {convertTo12HourFormat(details.START_TIME)}</p>
              <p className='px-3 border-b-[0.5px] pb-2'>Amount: {details.APPLY_AMOUNT}</p>
              {
                details.VEHICLE_NAME
                  ?
                  <p className='px-3 border-b-[0.5px] pb-2'>Vehicle Type: {details.VEHICLE_NAME}</p>
                  :
                  <p className='px-3 border-b-[0.5px] pb-2'>Vehicle Type: N/A</p>
  
              }
              <p className='px-3 border-b-[0.5px] pb-2'>Status: {details.AUTHORIZATION_STATUS}</p>
              
              <p className='px-3 border-b-[0.5px] pb-2'>Note: {details.PURPOSE}</p>
            </div>
  
            <div className=''>
              <p className='bg-[#E6E1DD] px-3 py-2 font-semibold'>Approve/Reject history</p>
              
              <div className="h-3"></div>

              <div className='px-3 w-5/6'>
                {
                  approverList.map((e, i) => (
                    <div className='flex flex-col items-start space-y-4' key={i}>
                      <div className='flex flex-row items-center space-x-6 h-20 w-96 '>
                        <div className={`w-6 h-6 p-[2px] rounded-full border-[1px] border-orange-500 flex justify-center items-center`}>
                          {
                            e.ACTION_CODE === 1
                            ?
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 text-green-500">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                              </svg>
                            :
                            e.ACTION_CODE === 0 ?
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 text-red-500">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                              </svg>
  
                            :
                            null
                          }
                        </div>
  
                        <div className=' flex flex-col items-start space-y-1'>
                          <p className='  font-medium text-black w-5/6'>{e.EMPLOYEE_NAME} <span className="text-[#7F7F7F]">({e.AUTHORIZER_TYPE})</span></p>
                          <p>{
                            e.ACTION_CODE === 1 ?
                              <div className=' flex flex-col items-start space-y-1'>
                                <p className=' font-medium text-green-500'>
                                  {
                                    e.AUTHORIZER_TYPE === "RECOMMENDER" ? "RECOMMENDED" : "APPROVED"
                                  }
                                </p>
                                
                                <p className=' font-medium text-green-500'>
                                  {
                                    e.NOTE === null ? "N/A" : e.NOTE
                                  }
                                </p>
                              </div>
  
                            : e.ACTION_CODE === 3 ?
                              <p className=' text-orange-500 font-medium'>
                                PENDING
                              </p>
                                :
                              <div className=' flex flex-col items-start space-y-1'>
                                <p className=' text-red-500 font-medium' >
                                  {
                                    e.AUTHORIZER_TYPE === "RECOMMENDER" ? "NOT RECOMMENDED" : "REJECTED" 
                                  }
                                </p>
                                
                                <p className=' text-red-500 font-medium' >
                                  {
                                    e.NOTE === null ? "N/A" : e.NOTE
                                  }
                                </p>
                              </div>
                            }
                          </p>
                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
              
            <div className="h-32"></div>
          </div>
        </div>
      </div>
    );
  };