import { baseUrl } from "../../utils/path";


  

  const GetVehicleTypeService= async()=>{

    const url=`${baseUrl}move_order_vehicle_type`;

    const response = await   fetch(url,
        {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
            },
           
          }
        );
        if (!response.ok) {
            throw new Error("fetching is not successful");
          }
        
          const data = await response.json();
         
          return data;

     
}
export default GetVehicleTypeService
