


  const addTerm= async(url,empId,term,ratio,value,status,approvalType)=>{
    console.log(`term name: ${term}`);
    // console.log(`em:${empId} se: ${sequence} st: ${status} md: ${moduleId} au: ${authorizationId} ap: ${approvalId}`);
    const response = await   fetch(url,
        {
            method: "POST",
            headers: {
              
              "Content-Type": "application/json",
              'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
            },
            body: JSON.stringify({
                "AM_ID": empId, 
                "TERM": term,
                 "RATIO": ratio,
                "VALUE": value,
                "STATUS": status,
                "APPROVAL_TYPE": approvalType
            }),
          }
        );
        // if (!response.ok) {
        //     throw new Error("fetching is not successful");
        //   }
          const data = await response.json();
          console.log(`stage: ${data}`);
          return data;

     
}
export default addTerm
