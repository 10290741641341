import React, { createContext, useState, useContext } from 'react';

const MoveOrder = createContext();

export const MoveOrderProvider = ({ children }) => {
  const [movePageno, setMovePageNo] = useState(1);
  const [details,setDetails]=useState({});
  const [isDelete,setIsDelete]=useState(false);
  const [isPopUp,setIsPopUp]=useState(false);
  const [deleteRowId, setDeleteRowId] = useState(null);

  return (
    <MoveOrder.Provider value={{ movePageno, setMovePageNo,details,setDetails,isDelete,setIsDelete,isPopUp,setIsPopUp,deleteRowId, setDeleteRowId }}>
      {children}
    </MoveOrder.Provider>
  );
};

export const useMoveOrderContext = () => {
  return useContext(MoveOrder);
};
