import React, { useEffect, useState } from 'react'
import { useHomePageContext } from '../../components/home_component/HomePageContext';
import MyMoveOrderService from '../service/MyMoveOrderService';
import { useMoveOrderContext } from '../context/MoveContext';
import Loading from '../../components/loader/Loading';
import { gray500, gray600 } from '../../utils/colors';
import ApproverHierarchyListService from '../service/ApproverHierarchyListService';

import moment from 'moment';
import numeral from 'numeral';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import MoveOrderDetailsPopup from './MoveOrderDetailsPopup';
import DeleteApplicationService from '../../iou/service/DeleteApplicationService';
import { usePDF } from 'react-to-pdf';

// import { numberToWord } from 'number-to-words-npm';


export default function MoveOrderPage() {
  const {menu, setMenu}=useHomePageContext();
  const [myMoveOrderList, setMyMoveOrderList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [approverList, setApproverList] = useState([]);
  const {deleteRowId, setDeleteRowId } = useMoveOrderContext();
  let limit=10;
    
  let [pageCount,setPage]=useState(1);
  let [page,setPg]=useState(0);
  const [dataLen,setDataLen]=useState(0);

  
  const { setMovePageNo, isDelete, setIsDelete, isPopUp, setIsPopUp, setDetails, details } = useMoveOrderContext();

  const [employeeId,setEmployeeId]=useState('');
  const [isEmpty,setIsEmpty]=useState(false);

  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState('');
  
  const { toPDF, targetRef } = usePDF({filename: `MY_MOVE_ORDER_${moment(details.CREATION_DATE).format('DD/MM/yyyy')}.pdf`});


  useEffect(() => {
    const eid=localStorage.getItem('empId');
    setEmployeeId(eid);
    myMoveOrder(eid,page,limit);
    // getHierarchy(eid, details.IS_VEHICLE_NEED === 1 ? "TRANSPORT MOVE ORDER" : "MOVE ORDER", details.MO_ID);
  }, []);

  const myMoveOrder = async (empId, page, limit) => {
    setIsLoading(true);

    const re = await MyMoveOrderService(empId, page, limit);

    if(re.move_order_details.length===0){
      setDataLen(0);
      setIsEmpty(true);
    }
    else{
      setDataLen(re.move_order_details.length);
      setIsEmpty(false);
    }
    console.log(re)
    setMyMoveOrderList(re.move_order_details);
    setIsLoading(false);
  }

  const formatDate = (inputDate) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    const date = new Date(inputDate);
    return date.toLocaleDateString('en-US', options);
  }

  const convertDate = (inputDate) => {
    // const months = [
    //   "January", "February", "March", "April", "May", "June",
    //   "July", "August", "September", "October", "November", "December"
    // ];
  
    const date = new Date(inputDate);
    const day = date.getUTCDate();  // Use getUTCDate to get the day in UTC
    // const monthName = months[date.getUTCMonth()];  // Use getUTCMonth to get the month in UTC
    const month = date.getUTCMonth() + 1; // Months are zero-based, so we add 1
    const year = date.getUTCFullYear();  // Use getUTCFullYear to get the year in UTC
  
    return `${day}/${month}/${year}`;
  };
  
  const convertTo12HourFormat = (dateString) => {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
      timeZone: 'UTC'
    };
  
    const date = new Date(dateString);
      date.setHours(date.getHours() - 6); // Subtract 6 hours
      return date.toLocaleString('en-US', options);
  };
  
  // Example usage
  const inputDate = "2023-09-07T17:00:00Z";
  const formattedDate = convertTo12HourFormat(inputDate);
  console.log(formattedDate);
  

  const setData = (val) => {
    // setDetails(val);
    // setMovePageNo(2);
   
  }

  // for next page
  const next = async() => {
    const newPage = page + 10;
    setPg(newPage);
    setPage((pre)=>pre+1);
    myMoveOrder(employeeId, newPage, limit);
  }

  // for previous page
  const previous = async() => {
    const newPage = page - 10;
    setPg(newPage);      
    setPage((pre) => pre - 1);
    myMoveOrder(employeeId, newPage, limit);
  }

  const[pdfData, setPdfData] = useState('');


  // row click function then see details
  // const handleRowClick = (item) => {
  //   setIsLoading(true);
  //   setSelectedItem(item); //aita k bad dibo
  //   setDetails(item);
  //   setPdfData(item);
  //   setIsPopUp(true);
  //   getHierarchy(employeeId, details.IS_VEHICLE_NEED === 1 ? "TRANSPORT MOVE ORDER" : "MOVE ORDER", details.MO_ID);
    
  //   setIsLoading(false);
  // };

  const handleRowClick = async (item) => {
    setIsLoading(true);
  
    await setSelectedItem((prevSelectedItem) => {
      if (prevSelectedItem) {
        // Do cleanup for the previously selected item
        setDetails();
        setPdfData('');
        setIsPopUp(false);
        // getHierarchy();
      }
  
      // Set the new selected item
      setDetails(item);
      setPdfData(item);
      setIsPopUp(true);
      getHierarchy(employeeId, item.IS_VEHICLE_NEED === 1 ? "TRANSPORT MOVE ORDER" : "MOVE ORDER", item.MO_ID);
  
      return item; // Return the new selected item
    });
  
    setIsLoading(false);
  };
  

  const handleRowDelete = async () => {
    setIsLoading(true);
    console.log("Deleted row: ");
    console.log("Deleted Item: ", deleteRowId);
    const result = await DeleteApplicationService(employeeId, "MOVE_ORDER", deleteRowId);
    myMoveOrder(employeeId,page,limit);
    setIsDelete(false);
    setIsPopUp(false);
    setIsLoading(false);
  }

  const getHierarchy = async (id, module, object) => {
    setIsLoading(true);
    const re = await ApproverHierarchyListService(id, module, object);
    const sortedData = re.APPROVER_LIST.sort((a, b) => a.STAGE_SEQ - b.STAGE_SEQ);
    console.log("hierarchy: ", sortedData);
    setApproverList(re.APPROVER_LIST.sort((a, b) => a.STAGE_SEQ - b.STAGE_SEQ));
    setIsLoading(false);
  }

  // Function to close details popup
  const closeDetailsPopup = () => {
    setSelectedItem(null);
  };

  // goto create move order
  const goTo=()=>{
    setMovePageNo(2);
  }

  return (
    <div className="my-20 mx-6">
      <div className='w-full'>
        <p className=" text-black text-lg font-semibold">
          Move Order
        </p>

        <div className="h-5"></div>

        <div className=' w-96 flex flex-row space-x-10 items-center'>
            <button
              onClick={()=>{goTo()}}
              className=' h-12 px-4 bg-buttonColor text-white rounded-md flex items-center space-x-1'>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
              </svg>


              <p>Add Move Order</p>
            </button>
            {/* <button
              onClick={()=>{setMenu('mmv')}}
            className=' py-2 px-4 bg-green-500 text-white rounded-md'>
                My Move Order
            </button> */}
        </div>
      </div>



      <div className="h-6"></div>

      <div className="flex items-center space-x-2">
        <select
          placeholder='Sort By Status' className="py-2 w-48 focus:outline-none text-gray-500 rounded-md border-[0.5px] hover:border-gray-400 px-3 bg-white"
          onChange={(e) => setSelectedStatus(e.target.value)}
          value={selectedStatus}
        >
          <option value="">ALL</option>
          <option value="IN PROCESS">Pending</option>
          <option value="APPROVED">Approved</option>
          <option value="REJECTED">Rejected</option>
        </select>

        {/* for downloading pdf */}
        {
          details.AUTHORIZATION_STATUS==="APPROVED" ?
            <button onClick={() => toPDF()} className='py-2 px-4 rounded-md border-[0.5px] font-medium text-white bg-buttonColor flex items-center space-x-1'>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
              </svg>
                  
              <p>Download PDF</p>
            </button>
          :
          
          <div></div>
        }
      </div>

      { isLoading ? (
        <Loading />
        ) :  (
        <div>
          <div className="h-6"></div>
            
            <p className=" text-black text-lg font-semibold">
              My Move Order
            </p>
          
          <div className="h-4"></div>
          {/* { !selectedItem && ( */}

          {
            myMoveOrderList.length === 0 ? (
              <div className='flex flex-row justify-center items-center text-center w-full h-screen'>
                <h1 className={`text-red-500 text-center w-full font-bold text-2xl`}>No Data Found</h1>
              </div>
            ) : (
              myMoveOrderList.filter((e) => !selectedStatus || e.AUTHORIZATION_STATUS === selectedStatus).length === 0 ? (
                <div className='h-[400px] w-full text-center flex items-center justify-center'>
                  <h1 className={`text-red-500 text-center w-full font-bold text-2xl`}>No Data Found</h1>
                </div>
            ) : (

              <table className={`w-full ${isPopUp ? 'lg:w-4/6' : ''} border-[0.5px] border-gray-200`}>
                <thead className="bg-bgTableHeader text-tableHeaderText shadow-sm h-14">
                  <tr>
                    <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                      DATE FROM
                    </th>

                    <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                      DATE TO
                    </th>
                          
                    <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                      MOVE ORDER TYPE
                    </th>
                          
                    <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                      DESTINATION
                    </th>
                          
                    <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                      STATUS
                    </th>
                  </tr>
                </thead>
                  
                {/* {
                  myMoveOrderList.length === 0 ? (
                    <div className='flex flex-row justify-center items-center text-center w-full h-screen'>
                      <h1 className={`text-red-500 text-center w-full font-bold text-2xl`}>No Data Found</h1>
                    </div>
                  ) : (
                    myMoveOrderList.filter((e) => !selectedStatus || e.AUTHORIZATION_STATUS === selectedStatus).length === 0 ? (
                      <div className='h-[400px] w-full text-center flex items-center justify-center'>
                        <h1 className={`text-red-500 text-center w-full font-bold text-2xl`}>No Data Found</h1>
                      </div>
                  ) : ( */}
                  <tbody className="w-full divide-y divide-borderColor">
                    {myMoveOrderList.filter((e) => !selectedStatus || e.AUTHORIZATION_STATUS === selectedStatus).map((e, index) => (
                      <tr key={index} className="bg-white cursor-pointer" onClick={() => handleRowClick(e)}>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {convertDate(e.START_DATE) == null ? "N/A" : convertDate(e.START_DATE)}
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap">
                          {convertDate(e.END_DATE) == null ? "N/A" : convertDate(e.END_DATE)}
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap">
                          {e.IS_VEHICLE_NEED === 1 ? "Transport Move Order" : "Move Order"}
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap">
                          {e.PLACE_TO == null ? "N/A" : e.PLACE_TO}
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap">
                          {e.AUTHORIZATION_STATUS == null ? "N/A" : e.AUTHORIZATION_STATUS}
                        </td>
                        {/* Uncomment the section below if needed */}
                        {/* 
                        <td className="px-6 py-4 whitespace-nowrap">
                          {e.AUTHORIZATION_STATUS === 'IN PROCESS' ? (
                            <span className="text-[#FDCA4D]">Pending</span>
                          ) : e.AUTHORIZATION_STATUS === 'APPROVED' ? (
                            <span className="text-[#2D833F]">Approved</span>
                          ) : e.AUTHORIZATION_STATUS === 'REJECTED' ? (
                            <span className="text-[#E20000]">Rejected</span>
                          ) : (
                            <span className="text-gray-500">N/A</span>
                          )}
                        </td>
                        */}
                      </tr>
                    ))}
                  </tbody>
              </table>
            )
          )}
          

          {/* Display details popup if a row is clicked */}
          
          { isPopUp &&(
            <MoveOrderDetailsPopup  />
           
          )}
          
          {/* {

            isDelete &&
            (
              <div
              onClick={()=>{setIsDelete(false)}}
              className=' w-96 h-96 bg-red-500'>

              </div>
            )
          } */}

          {isDelete && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white px-6 py-8 rounded-xl w-96">
                <div className="text-center w-full">
                  <div className=' flex justify-center mb-5'>
                    <img src="/images/delete.png" alt="delete" className='w-12 h-12' />
                  </div>

                  <h3 className='font-semibold text-lg'>Warning !!!</h3>
                  <p className="text-lg mb-4">Are you sure you want to delete?</p>
                </div>
                <div className="flex justify-between items-center">
                  <button
                    className="px-4 w-36 py-2 mr-2 text-gray-600 bg-white border-[0.5px] border-gray-700 rounded-lg"
                    onClick={() => setIsDelete(false)}
                  >
                    No
                  </button>

                  <button 
                    className="px-4 w-36 py-2 bg-red-500 text-white rounded-lg"
                    onClick={handleRowDelete}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        )
      }

      <div className="h-10"></div>


      {/* for pdf */}
      {
         pdfData.AUTHORIZATION_STATUS==='APPROVED' && isPopUp ?
        <div ref={targetRef} id='pdf' className='w-3/4 bg-white py-8 pl-16 pr-16'>
        <div className=' w-full border-2 border-black'>
          <div className=' flex flex-row w-full'>
            <div className=' flex-1 h-20 border-b-2 border-r-2 border-black'>
              <p className=' w-full flex  justify-center items-center text-2xl font-pristina italic text-black'>{pdfData.COMPANY_NAME} ({pdfData.COMPANY_CODE})</p>
              <div className=' text-black font-bold text-xl w-full justify-center items-center flex'>Move Order</div>
            </div>

            <div className=' w-56 h-20 border-b-2 flex items-center justify-center border-black'>
              <p className='text-black font-bold justify-start'>
                Date: {
                  moment(pdfData.CREATION_DATE).format('DD/MM/yyyy')
                }
              </p>
            </div>
          </div>

          <div className=' w-full flex flex-row items-center'>
            <div className='h-10 flex px-2 text-black font-bold justify-start w-52 border-b-2 border-r-2 border-black'>
              <p>Applicant Name </p>
              <div className='h-3'></div>
            </div>

            <div className='h-10 flex px-2 text-black font-bold justify-start flex-1 border-b-2 border-black'>
              <p>{pdfData.EMPLOYEE_NAME} ({employeeId})</p>
              <div className='h-3'></div>
            </div>
          </div>

          <div className=' w-full flex flex-row items-center'>
            <div className='h-10 flex px-2 text-black font-bold justify-start w-52 border-b-2 border-r-2 border-black'>
              <p>Company Name </p>
              <div className='h-3'></div>

            </div>
            <div className='h-10 flex px-2 text-black font-bold justify-start flex-1 border-b-2 border-black'>
              <p>{pdfData.COMPANY_NAME} ({pdfData.COMPANY_CODE})</p>
              <div className='h-3'></div>
            </div>
          </div>

          <div className=' w-full flex flex-row items-center'>
            <div className='h-10 flex px-2 text-black font-bold justify-start w-52 border-b-2 border-r-2 border-black'>
              <p>Move Order Type </p>
              <div className='h-3'></div>
            </div>

            <div className='h-10 flex px-2 text-black font-bold justify-start flex-1 border-b-2 border-black'>
              <p>{pdfData.TASK_TYPE} </p>
              <div className='h-3'></div>
            </div>
          </div>

          <div className=' w-full flex flex-row items-center'>
            <div className='h-10 flex px-2 text-black font-bold justify-start w-52 border-b-2 border-r-2 border-black'>
              <p>Purpose </p>
              <div className='h-3'></div>
            </div>

            <div className='h-10 flex px-2 text-black font-bold justify-start flex-1 border-b-2 border-black'>
              <p>{pdfData.PURPOSE} </p>
              <div className='h-3'></div>
            </div>
          </div>

          <div className=' w-full flex flex-row items-center'>
            <div className='h-20 flex px-2 text-black font-bold justify-start w-52 border-b-2 border-r-2 border-black'>
              <p>Destination </p>
              <div className='h-3'></div>
            </div>

            <div className='h-20 flex flex-col items-start text-black font-bold justify-start flex-1 border-b-2 border-black'>
              <div className=' h-10 w-full flex flex-row items-center'>
                <div className='px-2 h-10 flex-1 border-r-2 border-black border-b-2'>
                  <p className='h-full w-full flex justify-center '>From</p>
                  <div className='h-3'></div>
                </div>
            
                <div className='px-2 h-10 flex-1 border-black border-b-2'>
                  <p className='h-full w-full flex justify-center'>To</p>
                  <div className='h-3'></div>
                </div>
              </div>
              
              <div className=' h-10 w-full flex flex-row items-center'>
                <div className='px-2 h-10 flex-1 border-r-2 border-black border-b-2'>
                  <p className='h-full w-full flex justify-center'>{pdfData.PLACE_FROM}</p>
                  <div className='h-3'></div>
                </div>
                
                <div className='px-2 h-10 flex-1 border-black border-b-2'>
                  <p className='h-full w-full flex justify-center'>{pdfData.PLACE_TO}</p>
                <div className='h-3'></div>
              </div>
            </div>
          </div>
        </div>
        
        <div className=' w-full flex flex-row items-center'>
          <div className='h-10 flex px-2 text-black font-bold justify-start w-52 border-b-2 border-r-2 border-black'>
            <p>Other Employees </p>

            <div className='h-3'></div>
          </div>
          
          <div className='h-10 flex items-center px-2 text-black font-bold justify-start flex-1 border-b-2 border-black'>
              
          </div>
        </div>

        <div className=' w-full flex flex-row items-center'>
          <div className='h-24 flex px-2 text-black font-bold justify-start w-52 border-b-2 border-r-2 border-black'>
            <p>Duration </p>
            <div className='h-3'></div>
          </div>
          
          <div className='flex flex-col items-start text-black font-bold justify-start flex-1 border-b-2 border-black'>
            <div className=' h-12 w-full flex flex-row items-center'>
              <div className='px-2 h-12 flex-1 border-r-2 border-black border-b-2'>
                <p className='h-full w-full flex'>Date: {moment(pdfData.START_DATE).format('DD-MM-yyyy')}</p>
                <div className='h-3'></div>
              </div>
          
              <div className='px-2 h-12 flex-1 border-black border-b-2'>
                <p className='h-full w-full flex'>Date: {moment(pdfData.END_DATE).format('DD-MM-yyyy')}</p>
                <div className='h-3'></div>
              </div>
            </div>
            
            <div className='w-full flex flex-row items-center'>
              <div className='px-2 h-12 flex-1 border-r-2 border-black'>
                <p className='h-full flex w-full '>Time Out: {convertTo12HourFormat(pdfData.END_TIME)}</p>
                <div className='h-3'></div>
              </div>
              
              <div className='px-2 h-12 flex-1 border-black'>
                <p className='h-full flex w-full '>Time In: {convertTo12HourFormat(pdfData.START_TIME)}</p>
                <div className='h-3'></div>
              </div>
            </div>
          </div>
        </div>
        
        <div className=' w-full flex flex-row items-center'>
            <div className='h-10 flex px-2 text-black font-bold justify-start w-52 border-b-2 border-r-2 border-black'>
              <p>Transport By</p>
              <div className='h-3'></div>
            </div>

            <div className='h-10 flex px-2 text-black font-bold justify-start flex-1 border-b-2 border-black'>
              <p>{ pdfData.VEHICLE_NAME ? pdfData.VEHICLE_NAME : "N/A" }</p>

            </div>
          </div>
          
          <div className=' w-full flex flex-row items-center'>
            <div className='h-10 flex px-2 text-black font-bold justify-start w-52 border-b-2 border-r-2 border-black'>
              <p>Driver's Name & Mobile No</p>
              <div className='h-3'></div>
            </div>
            
            <div className='h-10 flex px-2 text-black font-bold justify-start flex-1 border-b-2 border-black'>

            </div>
          </div>
          
          <div className=' w-full flex flex-row items-center'>
            <div className='h-10 flex px-2 text-black font-bold justify-start w-52  border-r-2 border-black'>
              <p>Advance amount received</p>
              <div className='h-3'></div>
            </div>

            <div className='h-10 flex px-2 text-black font-bold justify-start flex-1  border-black'>
            { `${pdfData.APPLY_AMOUNT === 0 ? "N/A" : ` ${numeral(pdfData.APPLY_AMOUNT).format('0,0')} ৳`}`}
            </div>
          </div>
        </div>

        <div className='h-10'></div>

        <div className=' w-full border-2 border-black'>
          <div className=' w-full grid grid-cols-2 gap-6 border-b-2 border-black pb-4'>
            {
              approverList.map((e,i)=>(
                <div className=' '>
                  <div className=' pt-2 pb-1 flex flex-col items-center space-y-0'>
                    <p className=' text-black font-bold'>
                    { e.ACTION_CODE === 1 ?
                          <>
                            { e.AUTHORIZER_TYPE === "RECOMMENDER" ? "Recommended" : "Approved" }
                          </>
                        :
                          <>
                            { e.AUTHORIZER_TYPE === "RECOMMENDER" ? "Not Recommended" : "Rejected"  }
                          </>
                    }
                    </p>
                     
                    <p className=' text-black font-bold'>
                      Note: { e.NOTE ? e.NOTE:"N/A" }
                    </p>
                    
                    <p className=' text-black font-bold'>
                      { `${e.EMPLOYEE_NAME} (${e.APPROVER_ID})` }
                    </p>
                    
                    <p className=' text-black font-bold'>
                      { e.DESIGNATION }
                    </p>
                  </div>
                </div>
              ))
            }
          </div>

          <div className='h-2'></div>
          
          <div className='w-full h-20 flex pb-3 items-end justify-center text-black font-bold'>
            <p> Central Treasury Department</p>      
          </div>   
        </div>
      </div>
      :
      null
    }


    <div className='h-10'> </div>

      { myMoveOrderList.length > 0 &&
        <div className=' flex flex-row space-x-4 justify-end items-end my-6 w-full pr-6'>
          <button  disabled={ page===0?true:false} onClick={previous}>
            <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-5 h-5  ${page ===0?'text-white':`text-${gray600}`} font-bold`}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
            </svg>
          </button>
              
          <p className={`text-${gray500} text-sm font-bold`}>
            {pageCount}
          </p> 


          <button disabled={(page===0 && isEmpty) || dataLen<10 ? true:false} onClick={next}>
            <svg   xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-5 h-5  ${isEmpty || dataLen<10 ?'text-white':`text-${gray600}`} font-bold`}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
            </svg>
          </button>
        </div>
      }  
    </div>
  )
}