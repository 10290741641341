import { baseUrl } from "../utils/path";

  const GetPlantMasterService= async()=>{
    const url=`${baseUrl}plants`;
    
    const response = await   fetch(url,
        {
            method: "GET",
            headers: {
              
              "Content-Type": "application/json",
              'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
            },
          
          }
        );
    
          const data = await response.json();
         
          return data;

     
}
export default GetPlantMasterService
