import { baseUrl } from "../../utils/path";


const FrontDeskReportPermissionService= async(empId,companyCode,Plantcode)=>{

    const url=`${baseUrl}attendance_report_company_plant_permission`;

    const response = await   fetch(url,
        {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
            },
            body: JSON.stringify({
                "EMPLOYEE_ID":empId,
                "COMPANY_CODE":companyCode,
                "PLANT_CODE":Plantcode
            }),
          }
        );
        if (!response.ok) {
            throw new Error("fetching is not successful");
          }
        
          const data = await response.json();
         
          return data;

     
}
export default FrontDeskReportPermissionService