

  

  const createApprovalTemplate= async(url,approvalName,companyCode,plantCode,moduleCode,psgId)=>{

    // console.log(`ap: ${approvalName},cd: ${companyCode},pl: ${plantCode},md: ${moduleCode},psg: ${psgId}`);
    
    const response = await   fetch(url,
        {
            method: "POST",
            headers: {
              
              "Content-Type": "application/json",
              'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
            },
            body: JSON.stringify({
              "APPROVAL_STAGE_NAME":approvalName,
              "COMPANY_CODE":companyCode,
              "PLANT_CODE":plantCode,
              "MODULE_TYPE":moduleCode,
              "PSG":psgId
            }),
          }
        );
        // if (!response.ok) {
        //     throw new Error("fetching is not successful");
        //   }
          const data = await response.json();
          console.log(`tem: ${data.body}`);
          return data;

     
}
export default createApprovalTemplate
