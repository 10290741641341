
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import "rsuite/dist/rsuite.min.css";
import { DatePicker } from "rsuite";
import moment from 'moment/moment';
import React, { useEffect, useState } from 'react'
import EmployeeInfoService from '../../services/EmployeeInfoService';
import Loading from '../loader/Loading';
import { red500 } from '../../utils/colors';
import addEmpFromSapService from '../../services/AddEmpFromSapService';
import DateWiseEmployeeInfoService from '../../services/DateWiseEmpInfoService';
import { baseUrl, sapBaseUrl } from '../../utils/path';


const insertUrl=`${baseUrl}user_insertion_from_sap`;


const columns = [
  { id: 'employee_id', label: 'EMPLOYEE ID', minWidth: 170 },
  { id: 'employee_name', label: 'EMPLOYEE NAME', minWidth: 170 },
  {
    id: 'joining_date',
    label: 'JOINING DATE',
    minWidth: 170,
    align: 'right',
   
  },
  {
    id: 'mobile_number',
    label: 'MOBILE NUMBER',
    minWidth: 170,
    align: 'right',
   
  },
  {
    id: 'email',
    label: 'EMAIL',
    minWidth: 170,
    align: 'right',
   
  },
  {
    id: 'date_of_birth',
    label: 'DATE OF BIRTH',
    minWidth: 170,
    align: 'right',
   
  },
  {
    id: 'employment_status',
    label: 'EMPLOYMENT STATUS',
    minWidth: 200,
    align: 'right',
    
  },
  {
    id: 'gender',
    label: 'GENDER',
    minWidth: 170,
    align: 'right',
   
  },
  {
    id: 'position_id',
    label: 'POSITION ID',
    minWidth: 170,
    align: 'right',
  
  },
  {
    id: 'department_id',
    label: 'DEPARTMENT ID',
    minWidth: 170,
    align: 'right',
  
  },
 
  {
    id: 'permanent_date',
    label: 'PERMANENT DATE',
    minWidth: 170,
    align: 'right',
   
  },
  {
    id: 'manager_id',
    label: 'MANAGER ID',
    minWidth: 170,
    align: 'right',
    
  },
  {
    id: 'blood_group',
    label: 'BLOOD GROUP',
    minWidth: 170,
    align: 'right',
    
  },
  {
    id: 'plant_id',
    label: 'PLANT ID',
    minWidth: 170,
    align: 'right',
  
  },
  {
    id: 'ws_rules',
    label: 'WS RULES',
    minWidth: 170,
    align: 'right',
  
  },
  {
    id: 'creation_date',
    label: 'CREATION DATE',
    minWidth: 170,
    align: 'right',
  
  },
  {
    id: 'maritial_status',
    label: 'MARITIAL STATUS',
    minWidth: 170,
    align: 'right',
    
  },
  {
    id: 'emp_status',
    label: 'EMPLOYEE STATUS',
    minWidth: 170,
    align: 'right',
    
  }
];

// function createData(name, code, population, size) {
//   const density = population / size;
//   return { name, code, population, size, density };
// }



export default function StickyHeadTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openClose,setOpenClose]=useState(false);
  const [empId, setEmpId] = useState('');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  var formatted;
  const [creationDate, setCreationDate] = useState(null);
  
  const url=`${sapBaseUrl}/ZHCM_INFO_MOBILE_APP_SRV/ZHR_MOBILESet?$format=json`;
  // const url=`https://wsaps4h01.weplbd.com:5201/sap/opu/odata/SAP/ZHCM_INFO_MOBILE_APP_SRV/ZHR_MOBILESet?$format=json&$top=2&$skip=2`;
  const [infos,setInfos]=useState([]);
  const [isInfoLoading,setIsInfoLoading]=useState(true);
  const [uId,setUId]=useState('');
  const [loop,setLoop]=useState(1);
  const [value, setValue] = React.useState(null);
  const [dateWiseInfos,setDateWiseInfos]=useState([]);
  var i=0;
  
  // const [isSend,setisSend]=useState(0);
  // setIsInfoLoading(false);

  useEffect(()=>{
    const id=localStorage.getItem('userId');
    setUId(id);
    getInfo();
     
      
  },[])

  const handleCreationDateChange = (date) => {
    formatted=moment(date).format("DD.MM.YYYY");
    setCreationDate(formatted);
    setEmpId('');
    setValue(date);
    getDateWiseInfo();
    console.log("format Date:", formatted);
  };

  const handleCleanCreationDate=()=>{
    setEmpId('');
    setValue(null);
    setCreationDate(null);
    setDateWiseInfos([]);
    console.log(`birth: ${value}`);
    getInfo();
  }

  const getDateWiseInfo=async()=>{
    // console.log(dateWiseUrl);
    const dateWiseUrl=`${sapBaseUrl}/ZHCM_INFO_MOBILE_APP_SRV/ZHR_MOBILESet?$filter=(creation_date eq '${formatted}')&$format=json`;
    setInfos([]);
    setIsInfoLoading(true);
    const info=await DateWiseEmployeeInfoService(dateWiseUrl);
    // setDateWiseInfos(info.d.results);
    console.log("date wise data: ", info.d.results.length);
    setInfos(info.d.results);
    setIsInfoLoading(false);
    // console.log(`date wise info: ${info.d.results[0].employee_name}`);
    // console.log(`len: ${dateWiseInfos.length}`);
  }

  // search by employee Id
  const searchEmpId = async () => {
    const idWiseUrl=`${sapBaseUrl}/ZHCM_INFO_MOBILE_APP_SRV/ZHR_MOBILESet?$filter=(employee_id eq '${empId}')&$format=json`;
    setInfos([]);
    setIsInfoLoading(true);
    const info=await DateWiseEmployeeInfoService(idWiseUrl);
    // setDateWiseInfos(info.d.results);
    console.log("id wise data: ", info.d.results);
    setInfos(info.d.results);
    setIsInfoLoading(false);
  };

  const getInfo=async()=>{
    const inf = await EmployeeInfoService(url);
    console.log(inf);
    setInfos(inf.d.results);
    // console.log(`infos: ${inf.d.results}`);
    setIsInfoLoading(false);
    console.log(infos.length);
  }

  const sendInfo=async()=>{
    setOpenClose(true);
    for(i;i<infos.length;i++){
      const send=await addEmpFromSapService(insertUrl,uId,infos[i]);
      console.log(i);
      console.log(send);
      setLoop(i+1);
    }
    
    setOpenClose(false);
    setLoop(0);
  }

  return (
    <div className=' mt-20 px-8'>
      <div className=' flex flex-row justify-between my-3'>
        <h4 className=' text-black font-semibold lg:text-2xl md:text-lg'>Employee Info</h4>
      </div>

      <div className="h-4"></div>

      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-3">
          <DatePicker
            className=' md:w-56 lg:w-80'
            appearance="default"
            size="lg"
            format="dd-MM-yyyy"
            placeholder="Select Creation Date and Sync"
            onChange={handleCreationDateChange}
            value={value}
            onClean={handleCleanCreationDate}
            ranges={[
              {
                label: "Now",
                value: new Date(),
              },
            ]}
              // style={{ width: 335, height: 50 }}
          />

          <div className='flex items-center space-x-1'>
            <input onChange={(e)=>{setEmpId(e.target.value)}} value={empId} type="text" className=' px-2 h-10 rounded-md w-52 border-[0.2px] border-gray-200 placeholder:text-sm placeholder:text-gray-400 focus:outline-none bg-white' placeholder='Search by Employee ID' />

            <button onClick={() => {searchEmpId()}} className={`h-10 w-12 rounded-md shadow-sm flex justify-center items-center bg-buttonColor px-2`}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-8 text-white font-bold">
                <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
              </svg>
            </button>
          </div>
        </div>

          {/* disabled={dateWiseInfos.length ===0?true:false} */}
          {/* {creationDate!==null && dateWiseInfos.length===0?"LOADING...." :"SYNC WITH APP"} */}
        <button onClick={()=>{sendInfo()}}  className='h-10 w-auto px-4 rounded-md shadow-md bg-buttonColor flex items-center space-x-1 text-white text-sm md:text-xs font-bold'>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
          </svg>

          <p>SYNC WITH APP</p>
        </button>
      </div>

      <div className="h-8"></div>
        
      {
        isInfoLoading ?
          <div className=' flex justify-center items-center  w-full h-screen'>
            <Loading/>
          </div>
        :
          infos.length===0 ?
            <div className=' flex justify-center items-center w-full h-screen'>
              <h1 className={`text-${red500} font-bold text-2xl`}>No Data Found</h1>
            </div>
   
        :
          <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 460 }}>
              <Table stickyHeader aria-label="sticky table ">
                <TableHead >
                  <TableRow >
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, backgroundColor:  '#DFE5E0', color:'#32353C', fontSize:"14px"}}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                  
                <TableBody>
                  { infos
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                          {columns.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {column.format && typeof value === 'number'
                                  ? column.format(value)
                                  : value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })
                  }
                </TableBody>
              </Table>
            </TableContainer>
        
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={infos.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
      }

      <input type="checkbox" id="my-modal-11" className="modal-toggle" checked={openClose} />
            
      <div className="modal">
        <div className="modal-box">
          {/* <label htmlFor="my-modal-11" onClick={(e)=>{setOpenCLose(false); setPicUrl('')}} className="btn btn-sm btn-circle absolute right-2 top-2">✕</label> */}
   
          <div className=' w-full h-72 flex items-center justify-center bg-white'>
            <h1 className=' text-green-500 font-bold text-xl'>{`Inserting ${loop} of ${infos.length}`}</h1>
          </div>
        </div>
      </div>
    </div>
  );
}
