import { baseUrl } from "../../utils/path";

const LeaveApplyPdfService = async(applyId, pdf) => {

  const url = `${baseUrl}insert_pdf`;

  console.log("applyId", applyId);
  console.log("pdf: ", pdf);
    
  const response = await fetch(url,
      {
          method: "POST",
          headers: {
            
            "Content-Type": "application/json",
            'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
          },
          body: JSON.stringify({
              "MODULE_TYPE": "LEAVE_PDF",
              "OBJECT_ID": applyId,
              "PDF": pdf
          }),
        }
      );  
        const data = await response.json();
        console.log(`users: ${data}`);
        return data;

   
}
export default LeaveApplyPdfService;
