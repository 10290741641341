import { baseUrl } from "../../utils/path";


const LeaveAssignerService = async(id, userName)=>{

  const url = `${baseUrl}employee_search_by_name_id`;
    
  const response = await fetch(url,
      {
          method: "POST",
          headers: {
            
            "Content-Type": "application/json",
            'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
          },
          body: JSON.stringify({
              "Z_EMPLOYEE_ID": id !== null ? id : null,
              "Z_NAME": userName !== null ? userName : null,
          }),
        }
      );
      // if (!response.ok) {
      //     throw new Error("fetching is not successful");
      //   }
        const data = await response.json();
        console.log(`users: ${data}`);
        return data;

   
}
export default LeaveAssignerService;
