


const AddLatLongService = async (url, userId,lat,long,radius,companyCode,PlantCode) => {
    console.log(`uid: ${userId} lat ${lat}  long ${long} ra${radius} cc${companyCode}  pc${PlantCode}`);
    // console.log(`em:${empId} se: ${sequence} st: ${status} md: ${moduleId} au: ${authorizationId} ap: ${approvalId}`);
    const response = await fetch(url,
        {
            method: "POST",
            headers: {

                "Content-Type": "application/json",
                'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
            },
            body: JSON.stringify({
                "Z_DATA": userId,
                "COMPANY_CODE": companyCode,
                "PLANT_CODE":PlantCode,
                "LATITUDE":lat,
                "LONGITUDE":long,
                "RADIUS":radius
            }),
        }
    );
    // if (!response.ok) {
    //     throw new Error("fetching is not successful");
    //   }
    const data = await response.json();
    console.log(`info: ${data.status}`);
    return data;


}
export default AddLatLongService
