import { baseUrl } from "../../utils/path";

const url = `${baseUrl}requisition_report`;

const RequisitionReportService = async (
    EMPLOYEE_ID,
    COMPANY_CODE,
    PLANT_CODE,
    FROM_DATE,
    TO_DATE,
    P_OFFSET,
    P_LIMIT,
) => {
    // console.log(`uid: ${userId} lat ${lat}  long ${long} ra${radius} cc${companyCode}  pc${PlantCode}`);
    console.log(
        `empId:${EMPLOYEE_ID} compCode: ${COMPANY_CODE} pCode: ${PLANT_CODE} fDate: ${FROM_DATE} toDate: ${TO_DATE} offset: ${P_OFFSET} limit: ${P_LIMIT}`
    );

    // console.log(`IN_TIME value: ${IN_TIME}`);

    const response = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization:
                "Basic " +
                btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
        },
        body: JSON.stringify({
            EMPLOYEE_ID: EMPLOYEE_ID,
            COMPANY_CODE: COMPANY_CODE,
            PLANT_CODE: PLANT_CODE,
            FROM_DATE: FROM_DATE,
            TO_DATE: TO_DATE,
            P_OFFSET: P_OFFSET,
            P_LIMIT: P_LIMIT,
        }),
    });
    // if (!response.ok) {
    //     throw new Error("fetching is not successful");
    //   }

    const data = await response.json();
    console.log("service data", data['REQUISTION_LIST']);
    return data;

};
export default RequisitionReportService;
