
import { baseUrl } from "../../../utils/path";

const OtpVerificationService = async (email, otp, empNum) => {

  const url = `${baseUrl}otp_verification`;

  const otpString = otp.join('');

  console.log("email", email);
  console.log("otp", otp);
  console.log("otpString", otpString);
  console.log("mob no.: ", empNum);
    
  const response = await fetch(url,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
      },
      body: JSON.stringify({
        "EMAIL": email, 
        "OTP": otpString,
        "MOBILE_NUMBER": empNum
      }),
    }
  );
  const data = await response.json();
  console.log(`result: ${data}`);
  return data;
   
}
export default OtpVerificationService;