import { baseUrl } from "../../utils/path";

  

  const CheckLeaveService= async(
    empId,
    fromDate,
    toDate
    )=>{
        console.log(empId);

        console.log(fromDate);
        console.log(toDate);

    const url=`${baseUrl}check_appl_by_dates`;

    const response = await   fetch(url,
        {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
            },
            body: JSON.stringify(
                {
                
                    "EMPLOYEE_ID": empId,
      "FROM_DATE": fromDate,
      "TO_DATE": toDate
            }
            ),
          }
        );
        if (!response.ok) {
            throw new Error("fetching is not successful");
          }
        
          const data = await response.json();
         
          return data;

     
}
export default CheckLeaveService
