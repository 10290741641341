import React from 'react'


import { useRequisitionContext } from '../context/RequisitionContext';
// import MyRequisitionPage from './MyRequisitionPage';
// import MyRequisitionDetailsPage from './MyRequisitionDetailsPage';
import RequisitionPage from './RequisitionPage';
import CreateRequisitionPage from './CreateRequisitionPage';


export default function MyRequisitionHome() {

    const {requisitionPageNo, setRequisitionPageNo,details,setDetails}=useRequisitionContext();
  return (
    <div>

{(() => {
            switch (requisitionPageNo) {
              case 1:
                return <RequisitionPage />
              case 2:
                return <CreateRequisitionPage />
             

              default:
                return null
            }
          })()}
    </div>

    
  )
}
