import React,{useState,useEffect,useRef} from 'react';
import LoginService from '../../services/LoginService';
import { useNavigate } from 'react-router-dom';
import { red500 } from '../../utils/colors';
import PrivateRoutes from '../../utils/PrivateRoutes';
import { baseUrl } from '../../utils/path';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ForgetPasswordService from './service/ForgetPasswordService';
import ResendOtpService from './service/ResendOtpService';
import OtpVerificationService from './service/OtpVerificationService';
import UpdatePasswordService from './service/UpdatePasswordService';


const loginUrl = `${baseUrl}user_login`;

export default function LoginPage({ setToken }) {

  const [remmember,setRemember] = useState(false);
  const [username,setUsername] = useState( '');
  const [password,setPassword] = useState( '');
  const[isLoading,setIsloading] = useState(null);
  const [showAlert, setShowAlert] = useState(null);
  const [loginError,setLoginError] = useState({});
  const usernameRef = useRef(null);
  const passwordRef = useRef(null);
  const navigate = useNavigate();
  const [isForgetModalOpen, setIsForgetModalOpen] = useState(false);
  const [isVerifyOtpModalOpen, setIsVerifyOtpModalOpen] = useState(false);
  const [empId, setEmpId] = useState('');
  const [empNumber, setEmpNumber] = useState('');
  const [empEmail, setEmpEmail] = useState('');
  const [otp, setOtp] = useState(["", "", "", ""]);
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const [resendTimer, setResendTimer] = useState(600);
  const [showResendButton, setShowResendButton] = useState(false);
  const [showSubmitButton, setShowSubmitButton] = useState(true);
  const [isNewPasswordModalOpen, setIsNewPasswordModalOpen] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [containsNumber, setContainsNumber] = useState(false);
  const [containsSpecialChar, setContainsSpecialChar] = useState(false);
  const [containsUppercase, setContainsUppercase] = useState(false);
  const [isLength, setIsLength] = useState(false);


  useEffect( () => {
    const savedUserName = localStorage.getItem('username');
    const savedPassword = localStorage.getItem('password');
    const savedRemember = localStorage.getItem('remember');

    if((savedUserName && savedPassword) === null ) {
      console.log("save nai");
    }
    else {
      setUsername(savedUserName);
      setPassword(savedPassword);
      setRemember(savedRemember);
      usernameRef.current.value=savedUserName;
      passwordRef.current.value=savedPassword;
      // console.log(`username: ${savedUserName} password: ${savedPassword} remember: ${savedRemember}`);
    }
  },[]);

  useEffect(() => {
    let timer;

    if (isVerifyOtpModalOpen && resendTimer > 0) {
      timer = setTimeout(() => setResendTimer(resendTimer - 1), 1000);
    } else if (resendTimer === 0) {
      setShowSubmitButton(false); // Hide the "Submit" button
      setShowResendButton(true); // Show the "Resend Code" button when the timer reaches zero
    }

    return () => clearTimeout(timer);
  }, [isVerifyOtpModalOpen, resendTimer]);

  useEffect(() => {
    // Reset the timer and button visibility when the modal opens
    if (isVerifyOtpModalOpen) {
      setResendTimer(600); // Reset timer to 10 minutes in seconds
      setShowSubmitButton(true); // Show the "Submit" button
      setShowResendButton(false); // Hide the "Resend Code" button
    }
  }, [isVerifyOtpModalOpen]);

  const openForgetModal = () => {
    setIsForgetModalOpen(true);
  };

  const closeForgetModal = () => {
    setIsForgetModalOpen(false);
  };

  const addZerosToLeft = (str) => {
    const loginIdLength = 8 - str.length;
    return loginIdLength > 0 ? '0'.repeat(loginIdLength) + str : str;
  };

  const validateLogin = () => {
    const loginError={};
    if(!username.trim()){
      loginError.username="Please Enter Username";
    }
    if(!password.trim()){
      loginError.password="Please Enter Password";
    }
    setLoginError(loginError);

    return Object.keys(loginError).length === 0;
  }

 const [message, setMessage] = useState('');
  const login = async () => {
    if(validateLogin()){
      setIsloading(true);
      const addUserId = addZerosToLeft(username);
      const log = await LoginService(loginUrl, addUserId, password);
      console.log(log.message);
      console.log(log.status);
      console.log("user id: ", addUserId);
      console.log("id length: ", addUserId.length);

      if(log.status === true) {
        localStorage.setItem('userId', log.FOO);
        
        setIsloading(false);
        setShowAlert(true);
        setToken(true);
        // In your login method after successful login
        localStorage.setItem('token', log.token);
        
        // Navigate to the home page after a delay
        setTimeout(() => {
          navigate('/home');
        }, 500); // Delay of 1 seconds
      }
      else {
        setIsloading(false);
        setShowAlert(false);
        setMessage(log.message);
        console.log("not successful");
      }
    }
    else {
      console.log('validation failed');
    }
  }

  const saveInfo = () => {
    // Save email and password in local storage
    localStorage.setItem('username', username);
    localStorage.setItem('password', password);

    console.log('save korlo');
  }

  const deleteInfo = () => {
    localStorage.removeItem('username');
    localStorage.removeItem('password');
    console.log('delete korlo');
  }
  const [isPass,setIsPass] = useState(true);

  const handleShow = () => {
    setIsPass(!isPass);
  }

  const handleEmpId = (e) => {
    setEmpId(e.target.value);

    console.log("empId", e.target.value);
  }

  const handleEmpNumber = (e) => {
    setEmpNumber(e.target.value);

    console.log("empNumber", e.target.value);
  }

  const handleEmpEmail = (e) => {
    setEmpEmail(e.target.value);

    console.log("empEmail", e.target.value);
  }

  const handleSend = async () => {
    setOtp([]);
    if(empId === "") {
      toast.error("Please enter employee id.", {
        autoClose: 3000,
        position: toast.POSITION.TOP_CENTER,
      });
     
    }

    else if(empNumber === "") {
      toast.error("Please enter employee phone number.", {
        autoClose: 3000,
        position: toast.POSITION.TOP_CENTER,
      });
      
    }

    else {
      const result = await ForgetPasswordService(empEmail, empNumber, empId);
      console.log("res: ", result);

      if(result.status === true) {
        setIsForgetModalOpen(false);
        setIsVerifyOtpModalOpen(true);

        console.log("result: ", result);
        console.log("result.status: ", result.status);


        toast.success(`${result.message}`, {
          autoClose: 3000,
          position: toast.POSITION.TOP_CENTER,
        });
      }

      else {
        toast.error(`${result.message}`, {
          autoClose: 3000,
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  }

  // const openVerifyOtp = () => {
  // }

  const closeVerifyOtp = () => {
    setIsVerifyOtpModalOpen(false);
  }

  const handleInputChange = (index, event) => {
    const newOtp = [...otp];
    newOtp[index] = event.target.value;

    if (event.target.value !== "" && index < 3 && inputRefs[index + 1]?.current) {
      // Move focus to the next input field if it exists
      inputRefs[index + 1].current.focus();
    }

    setOtp(newOtp);
  };

  const handleSubmitOtp = async () => {
    console.log("otp: ", otp);

    const result = await OtpVerificationService(empEmail, otp, empNumber);
    console.log("verify: ", result);

    if(result.status === true) {
      setIsForgetModalOpen(false);
      setIsVerifyOtpModalOpen(false);
      setIsNewPasswordModalOpen(true);

      toast.success(`${result.message}`, {
        autoClose: 3000,
        position: toast.POSITION.TOP_CENTER,
      });
    }

    else {
      toast.error(`${result.message}`, {
        autoClose: 3000,
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }

  const handleResend = async () => {
    setResendTimer(600); // Reset timer to 10 minutes in seconds
    setShowSubmitButton(true); // Show the "Submit" button
    setShowResendButton(false); // Hide the "Resend Code" button

    const result = await ResendOtpService(empEmail, empNumber);
    console.log("resend: ", result);

    toast.success(`${result.message}`, {
      autoClose: 3000,
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const handleNewPassword = (e) => {
    const newPassword = e.target.value;

    setNewPassword(e.target.value);
    
    console.log("newPass: ", newPassword);

    setContainsNumber(/\d/.test(newPassword));
    setContainsSpecialChar(/[!@#$%^&*(),.?":{}|<>]/.test(newPassword));
    setContainsUppercase(/[A-Z]/.test(newPassword));
    setIsLength(newPassword.length >= 6);
  };

  const handleConfirmNewPassword = (e) => {
    setConfirmNewPassword(e.target.value);

    console.log("conPass", e.target.value);
  }

  const closeNewPasswordModal = () => {
    setEmpEmail('');
    setEmpId('');
    setEmpNumber('');
    setNewPassword('');
    setConfirmNewPassword('');
    setContainsNumber(false);
    setContainsSpecialChar(false);
    setContainsUppercase(false);
    setIsLength(false);
    
    setIsNewPasswordModalOpen(false);
  }

  const handleChangePassword = async () => {
    if(newPassword === "") {
      toast.error("Please enter new password.", {
        autoClose: 3000,
        position: toast.POSITION.TOP_CENTER,
      });
    }

    else if(confirmNewPassword === "") {
      toast.error("Please enter confirm password.", {
        autoClose: 3000,
        position: toast.POSITION.TOP_CENTER,
      });
    }

    else if (newPassword !== confirmNewPassword) {
      toast.error("New password and confirm password do not match.", {
        autoClose: 3000,
        position: toast.POSITION.TOP_CENTER,
      });
    }

    else if (!/\d/.test(newPassword) || !/[!@#$%^&*(),.?":{}|<>]/.test(newPassword) || !/[A-Z]/.test(newPassword)) {
      toast.error("Password must contain at least 1 Number, 1 uppercase letter and 1 special character.", {
        autoClose: 3000,
        position: toast.POSITION.TOP_CENTER,
      });
    }

    else if (newPassword.length < 6) {
      toast.error("Password must be at least 6 characters long.", {
        autoClose: 3000,
        position: toast.POSITION.TOP_CENTER,
      });
    }

    else {
      const res = await UpdatePasswordService(empEmail, newPassword, empId, empNumber);

      if(res.status === true) {
        toast.success(`${res.message}`, {
          autoClose: 3000,
          position: toast.POSITION.TOP_CENTER,
        });

        setEmpEmail('');
        setEmpNumber('');
        setEmpId('');

        setIsForgetModalOpen(false);
        setIsVerifyOtpModalOpen(false);
        setIsNewPasswordModalOpen(false);
      }
    }
  }



  return (
    <div className=' w-full flex justify-center h-screen items-center'>
      <div className=' md:w-1/2 w-[420px]  bg-gray-100 shadow-md rounded-lg p-8 flex flex-col items-center'>
        <img src="./images/logo.png" alt="logo"  className= ' h-20 w-20 rounded-full'/>
        <h1 className=' text-black font-bold text-2xl'>WEPL</h1>
        
        <div className=' w-96 flex flex-row space-x-1 p-2 border-[0.5px] border-gray-400 rounded-md mx-24 mt-6'>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-gray-500 font-normal pt-2">
            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
          </svg>

          <input ref={usernameRef} type="text" onChange ={(e)=>{
            setUsername(e.target.value)
          }} required className=' border-none outline-none h-8  bg-transparent w-full'/>

        </div>
        
        { loginError.username && 
          <span className={`text-${red500}`}>
            {loginError.username}
          </span>
        }

        <ToastContainer />
          
        <div className=' w-96 flex flex-row space-x-1 p-2 border-[0.5px] border-gray-400 rounded-md mx-24 mt-6'>      
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-gray-500 font-normal pt-2">
            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 5.25a3 3 0 013 3m3 0a6 6 0 01-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1121.75 8.25z" />
          </svg>


          <input required ref={passwordRef} type={isPass?"password":"text"}  onChange={(e)=>{setPassword(e.target.value)}}  className=' border-none outline-none h-8  bg-transparent w-full'/>

          { isPass ?
            <button onClick={handleShow} >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
              </svg>
            </button>
          :
            <button onClick={handleShow}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
              </svg>
            </button>
          }
        </div>
          
        { loginError.password && 
          <span className={`text-${red500}`}>
            {loginError.password}
          </span>
        }

        <div className="h-4"></div>

        <div className='w-96 flex items-center justify-between'>
          <button onClick={() => {
            let newRemember;
            if(remmember) {
              newRemember=false;
            }
            else {
              newRemember=true;
            }
            setRemember(newRemember);
            console.log(newRemember);

            if(newRemember === true) {
              saveInfo();
              localStorage.setItem('remember',newRemember);
            }
            else {
              deleteInfo();
              localStorage.removeItem('remember',newRemember);
            }
          }}>
            <div className='flex flex-row space-x-2'>
              <div className={` h-[17px] w-[17px] ${remmember ? ' border-white': 'border-gray-400'} border-[0.5px] rounded-sm ${remmember ? 'bg-blue-700':'bg-gray-100'} justify-center items-center`}>
                { remmember ?
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-[14px] h-[14px] text-white font-bold`}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                  </svg>
                :
                  <p></p>
                }
              </div>
              
              <p className=' text-gray-600 text-xs font-medium '>Remember username</p>
            </div>
          </button>

          <button onClick={openForgetModal}>
            <p className="text-red-500 text-xs font-medium underline">
              Forget Password?
            </p>
          </button>
        </div>

        

        { isLoading ?
          <div class="inline-block h-12 w-12  mt-12 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-info motion-reduce:animate-[spin_1.5s_linear_infinite]"
          role="status">

          </div>
        : 
          <button onClick={()=>{login()}} className=' h-10 w-96  mx-24 mt-6 rounded-lg bg-blue-700 flex justify-center items-center'>
            <h4 className=' text-white font-semibold text-sm'> Sign In</h4>
          </button>
        }
          
        { showAlert === true ?
          <div className="alert alert-success shadow-lg mt-4">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6 text-white" fill="none" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
                  
              <span className=' text-white font-semibold text-sm'>Login Successful</span>
            </div>
          </div>

        : showAlert === false ?
          <button  onClick={()=>{setShowAlert(null)}} className="alert alert-error shadow-lg mt-4">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-white">
                <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              
              <span className=' text-white font-semibold text-sm'>{message}</span>
            </div>
          </button>
        :
          <p></p>
        }
      
        <div>
        </div>

        { isForgetModalOpen && (
          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
              </div>

              <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                &#8203;
              </span>

              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-2/6 h-auto overflow-y-auto" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <span className="absolute bg-gray-700 rounded-full top-1 right-1 w-9 h-9 text-lg text-white flex items-center justify-center cursor-pointer" onClick={closeForgetModal}>
                    &#x2715;
                  </span>
                  <label className="block text-gray-700 text-lg font-bold mb-2">Redeem Password</label>
                </div>

                <div className="h-5"></div>

                <div className="px-6 pb-10">
                  <div>
                    <p className='text-red-500 font-semibold'>Employee Id *</p>

                    <div className="h-2"></div>

                    <input type="text" value={(empId)} onChange={handleEmpId} className="w-full bg-white px-3 h-[48px] rounded-lg border-[0.2px] border-gray-300 placeholder:text-sm placeholder:text-gray-400 focus:outline-none" placeholder='Enter employee id' />
                  </div>

                  <div className="h-5"></div>

                  <div>
                    <p className='text-red-500 font-semibold'>Employee Phone Number *</p>

                    <div className="h-2"></div>

                    <input type="text" value={(empNumber)} onChange={handleEmpNumber} className="w-full bg-white px-3 h-[48px] rounded-lg border-[0.2px] border-gray-300 placeholder:text-sm placeholder:text-gray-400 focus:outline-none" placeholder='Enter employee phone number' />
                  </div>

                  <div className="h-5"></div>

                  <div>
                    <p className='text-red-500 font-semibold'>Employee Email (Optional)</p>

                    <div className="h-2"></div>

                    <input type="email" value={(empEmail)} onChange={handleEmpEmail} className="w-full bg-white px-3 h-[48px] rounded-lg border-[0.2px] border-gray-300 placeholder:text-sm placeholder:text-gray-400 focus:outline-none" placeholder='Enter employee email' />
                  </div>

                  <div className="h-5"></div>

                  <div className='flex items-center space-x-3 bg-[#DA924E] opacity-70 text-white py-2 px-3 rounded-lg'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-16">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
                    </svg>

                    <p className='text-justify leading-4 text-xs py-0'>
                      <span className="font-semibold">Please Note:</span> If you have an official email, you can use both email and phone for password recovery. However, if you don't have an official email, please use your registered phone number for the recovery process.
                    </p>
                  </div>

                  <div className="h-16"></div>

                  <div onClick={handleSend} className='w-full cursor-pointer bg-buttonColor text-white text-center py-3 rounded-full font-semibold'>
                    <button className='tracking-wider'>
                      Send
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        { isVerifyOtpModalOpen && (
          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
              </div>

              <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                &#8203;
              </span>

              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-2/6 h-auto overflow-y-auto" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <span className="absolute bg-gray-700 rounded-full top-1 right-1 w-9 h-9 text-lg text-white flex items-center justify-center cursor-pointer" onClick={closeVerifyOtp}>
                    &#x2715;
                  </span>
                  <label className="block text-gray-700 text-lg font-bold mb-2">Verify OTP</label>
                </div>

                <div className="h-5"></div>

                <div className="px-6 pb-10">
                  <div className="h-5"></div>

                  <div className='text-center'>
                    <h2 className='text-gray-400'>Verification Code</h2>

                    <div className="h-3"></div>

                    <div className='flex flex-col'>
                      <span>We send you a verification code</span>

                      <span>Please enter it below</span>
                    </div>
                  </div>

                  {/* <div className="h-10"></div> */}

                  {/* <input type="text" className="w-full text-lg px-3 h-[48px] border-b-[1px] border-gray-500 placeholder:text-sm placeholder:text-gray-400 focus:outline-none" /> */}

                  <div className="h-8"></div>

                  <div className="flex items-center justify-center space-x-4">
                    {inputRefs.map((ref, index) => (
                      <input
                        key={index}
                        type="text"
                        className="w-16 text-center text-3xl px-3 h-[60px] rounded-xl border-[1px] bg-white border-gray-500 placeholder:text-sm placeholder:text-gray-400 focus:outline-none"
                        maxLength="1"
                        value={otp[index] || ""}
                        onChange={(e) => handleInputChange(index, e)}
                        ref={ref}
                      />
                    ))}
                  </div>

                  <div className="h-5"></div>

                  {/* { showResendButton ? (
                    <div onClick={handleResend} className='cursor-pointer text-center text-blue-500 text-[17px]'>
                      <button className='tracking-wider underline'>
                        Resend Code
                      </button>
                    </div>
                  ) : (
                    <>
                      <p className='text-center font-semibold'>Resend OTP after:</p>
                      <p className='text-center font-semibold'>{Math.floor(resendTimer / 60).toString().padStart(2, '0')}:{(resendTimer % 60).toString().padStart(2, '0')}</p>
                    </>
                  )} */}

                  <p className='text-center font-semibold'>
                    Resend OTP
                  </p>

                  <p className='text-center font-semibold'>
                    {showSubmitButton
                      ? `${Math.floor(resendTimer / 60).toString().padStart(2, '0')}:${(resendTimer % 60).toString().padStart(2, '0')}`
                      : "Time has expired. Resend OTP to try again."
                    }
                  </p>

                  <div className="h-16"></div>

                  {/* <div onClick={handleSubmitOtp} className='w-full text-[17px] cursor-pointer bg-buttonColor text-white text-center py-3 rounded-full'>
                    <button className='tracking-wider'>
                      Submit
                    </button>
                  </div> */}

                  {showSubmitButton && (
                    <div onClick={handleSubmitOtp} className='w-full cursor-pointer bg-buttonColor text-white text-center py-3 rounded-full font-semibold'>
                      <button className='tracking-wider'>
                        Submit
                      </button>
                    </div>
                  )}

                  {showResendButton && (
                    <div onClick={handleResend} className='w-full cursor-pointer bg-buttonColor text-white text-center py-3 rounded-full font-semibold'>
                      <button className='tracking-wider'>
                        Resend OTP
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        { isNewPasswordModalOpen && (
          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
              </div>

              <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                &#8203;
              </span>

              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-2/6 h-auto overflow-y-auto" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <span className="absolute bg-gray-700 rounded-full top-1 right-1 w-9 h-9 text-lg text-white flex items-center justify-center cursor-pointer" onClick={closeNewPasswordModal}>
                    &#x2715;
                  </span>
                  <label className="block text-gray-700 text-lg font-bold mb-2">Change Password</label>
                </div>

                <div className="h-5"></div>

                <div className="px-6 pb-10">
                  <div>
                    <p className='text-buttonColor font-semibold'>Enter New Password *</p>

                    <div className="h-2"></div>

                    <input type="text" value={(newPassword)} onChange={handleNewPassword} className="w-full px-3 h-[48px] rounded-lg border-[0.2px] bg-white border-gray-300 placeholder:text-sm placeholder:text-gray-400 focus:outline-none" placeholder='Enter new password' />
                  </div>

                  <div className="h-5"></div>

                  <div>
                    <p className='text-buttonColor font-semibold'>Confirm New Password *</p>

                    <div className="h-2"></div>

                    <input type="text" value={(confirmNewPassword)} onChange={handleConfirmNewPassword} className="w-full px-3 h-[48px] rounded-lg border-[0.2px] bg-white border-gray-300 placeholder:text-sm placeholder:text-gray-400 focus:outline-none" placeholder='Confirm new password' />
                  </div>

                  <div className="h-8"></div>

                  <div className='space-y-3'>
                    <div className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        checked={containsNumber}
                        className="form-checkbox h-4 w-4 accent-buttonColor"
                        readOnly
                      />
                      <p className="text-gray-700">Number</p>
                    </div>

                    <div className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        checked={containsSpecialChar}
                        className="form-checkbox h-4 w-4 accent-buttonColor"
                        readOnly
                      />
                      <p className="text-gray-700">Special Character</p>
                    </div>

                    <div className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        checked={containsUppercase}
                        className="form-checkbox h-4 w-4 accent-buttonColor"
                        readOnly
                      />
                      <p className="text-gray-700">Uppercase Letter</p>
                    </div>

                    <div className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        checked={isLength}
                        className="form-checkbox h-4 w-4 accent-buttonColor"
                        readOnly
                      />
                      <p className="text-gray-700">At least 6 characters</p>
                    </div>
                  </div>

                  <div className="h-16"></div>

                  <div onClick={handleChangePassword} className='w-full cursor-pointer bg-buttonColor text-white text-center py-3 rounded-full font-semibold'>
                    <button className='tracking-wider'>
                      Change Password
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
//     <div className=' flex justify-center items-center w-full  '>

//         <div className=' bg-gray-100 shadow-xl rounded-lg w-1/2 h-3/4 p-8'>
//             <div className='flex justify-center items-center w-full flex-col space-y-4'>
//                 <img src="./images/logo.png" alt="logo"  className= ' h-20 w-20 rounded-full'/>
//                 <h1 className=' text-black font-bold text-2xl'>WEPL HRM</h1>
                
//                 {/* <div className=' w-96 h-10 rounded-md border-gray-400 border-[1px] justify-center items-start px-4 space-x-4 focus:border-blue-500'>
               


//         </div> */}
//             </div>

//             <div className=' w-full flex flex-row space-x-1 p-2 border-[0.5px] border-gray-400 rounded-md mx-24 mt-6'>
//                 <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-gray-500 font-normal pt-2">
//   <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
// </svg>

// <input ref={usernameRef} type="text" onChange={(e)=>{
//   setUsername(e.target.value)
// }} required className=' border-none outline-none h-8  bg-transparent w-full'/>

//                 </div>
//                 {loginError.username && <span className={`text-${red500}`}>{loginError.username}</span>}
//             <div className=' w-full flex flex-row space-x-1 p-2 border-[0.5px] border-gray-400 rounded-md mx-24 mt-6'>
                

// <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-gray-500 font-normal pt-2">
//   <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 5.25a3 3 0 013 3m3 0a6 6 0 01-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1121.75 8.25z" />
// </svg>


// <input required ref={passwordRef} type="password" onChange={(e)=>{setPassword(e.target.value)}}  className=' border-none outline-none h-8  bg-transparent w-full'/>

//                 </div>
//                 {loginError.username && <span className={`text-${red500}`}>{loginError.username}</span>}

//                 <button onClick={()=>{
//                   let newRemember;
//                   if(remmember){
//                     newRemember=false;
//                   }
//                   else{
//                     newRemember=true;
//                   }

//                   setRemember(newRemember);
                  
//                   console.log(newRemember);

//                   if(newRemember ===true){

//                     saveInfo();
//                     localStorage.setItem('remember',newRemember);
//                   }
//                   else{
//                     deleteInfo();
//                     localStorage.removeItem('remember',newRemember);
//                   }

//                 }}>
//                 <div className=' flex flex-row space-x-2 mx-24 mt-6'>
//                       <div className={` h-[17px] w-[17px] ${remmember ? ' border-white': 'border-gray-400'} border-[0.5px] rounded-sm ${remmember ? 'bg-blue-700':'bg-gray-100'} justify-center items-center`}>

//                      {
//                       remmember ?
//                       <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-[14px] h-[14px] text-white font-bold`}>
//                       <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
//                     </svg>:
//                     <p></p>
//                      }


//                       </div>
//                       <p className=' text-gray-600 text-xs font-medium '>Remember username</p>
//                     </div>
//                 </button>

               
//             { isLoading?
//             <div
//             class="inline-block h-12 w-12  mt-12 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-info motion-reduce:animate-[spin_1.5s_linear_infinite]"
//             role="status">
           
//           </div>
//                  : <button onClick={()=>{login()}} className=' h-10 w-80  mx-24 mt-6 rounded-lg bg-blue-700 flex justify-center items-center'>

//                   <h4 className=' text-white font-semibold text-sm'> Sign In</h4>
                  
//                   </button>
//             }
//             {
//               showAlert ===true?
//               <div className="alert alert-success shadow-lg mt-4">
//   <div>
//     <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6 text-white" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
//     <span className=' text-white font-semibold text-sm'>Login Successful</span>
//   </div>
// </div>
// :
// showAlert ===false?
// <button  onClick={()=>{setShowAlert(null)}} className="alert alert-error shadow-lg mt-4">
//   <div>
//   <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-white">
//   <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
// </svg>

//     <span className=' text-white font-semibold text-sm'>Login Failed. Try Again</span>
//   </div>
// </button>:
// <p></p>

//             }
               
//         </div>

        

//     </div>
  
}
