import React, { useEffect, useState } from "react";
import GetCompanyPlantFromSapService from "../../services/GetCompanyPlantFromSapService";
import SyncCompanyPlantService from "../../services/SyncCompanyPlantService";
import { Loader } from "rsuite";
import Loading from "../loader/Loading";
import { baseUrl, sapBaseUrl } from "../../utils/path";

const url =
    `${sapBaseUrl}/ZHCM_INFO_MOBILE_APP_SRV/ZHR_PLANTSet?$expand=Plants&$format=json`;
const syncUrl = `${baseUrl}create_new_company_plant`;
export default function CompanyWisePlant() {
    const [isLoading, setIsLoading] = useState(false);
    const [company, setCompany] = useState([]);
    const [loop, setLoop] = useState(1);
    const [openClose, setOpenClose] = useState(false);
    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        setIsLoading(true);
        const re = await GetCompanyPlantFromSapService(url);
        console.log(re.d.results);
        setCompany(re.d.results);
        setIsLoading(false);
    };
    const sync = async () => {
        setOpenClose(true);
        for (var i = 0; i < company.length; i++) {
            for (var j = 0; j < company[i].Plants.results.length; j++) {
                const re = await SyncCompanyPlantService(
                    syncUrl,
                    company[i].Company_code,
                    company[i].Company_name,
                    company[i].Plants.results[j].Plant,
                    company[i].Plants.results[j].Plant_Name
                );
                console.log(re);
            }
            setLoop(i + 1);
        }

        setOpenClose(false);
        setLoop(0);
    };
    return (
        <div>
            {isLoading ? (
                <div className=" w-full h-screen flex justify-center items-center">
                    <Loading />
                </div>
            ) : (
                <div className=" my-24 mx-4">
                    <div className="w-full h-14 flex flex-row  space-x-4  items-center  bg-offWhiteColor rounded-t-md border-[0.5px] border-borderColor ">
                        <div className="w-52 h-full text-sm font-mon text-blackishColor font-medium  flex justify-center items-center">
                            Company Name
                        </div>
                        <div className="w-36 h-full text-sm font-mon text-blackishColor font-medium  flex justify-center items-center">
                            Company Code
                        </div>
                        <div className="flex-1 h-full  flex-row text-sm font-mon text-blackishColor font-medium  flex justify-center items-center">
                            <p>Plants</p>
                            <div className="w-6"></div>
                            <button
                                onClick={sync}
                                className=" py-1 px-4 rounded-md bg-buttonColor text-sm text-white font-semibold flex items-center space-x-2"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                </svg>

                                <p>Sync</p>
                            </button>
                        </div>
                    </div>
                    {/* loop er last er jonno rounded-b-md dibo */}
                    {company.map((j, k) => (
                        <div className={`w-full py-4 flex flex-row space-x-4 ${
                                k === company.length - 1 ? " rounded-b-md" : ""
                            } items-start border-r-[0.5px] border-b-[0.5px] border-l-[0.5px] border-borderColor`}
                        >
                            <div className="w-52 h-full px-4 flex justify-start items-start text-sm font-mon font-medium text-blackColor">
                                {j.Company_name}
                            </div>
                            <div className="w-36 h-full  flex justify-center items-start text-sm font-mon font-medium text-blackColor ">
                                {j.Company_code}
                            </div>

                            <div className="flex-1 h-full items-start text-sm font-mon font-medium text-blackColor">
                                <div className="grid grid-cols-4 gap-4 pr-8">
                                    {j.Plants.results.map((e, i) => (
                                        <div
                                            key={i}
                                            className=" flex flex-row space-x-2 pb-4 justify-start  items-start"
                                        >
                                            <p
                                                className="text-sm text-black font-medium"
                                                style={{ padding: 0 }}
                                            >
                                                {i + 1}.
                                                {`${e.Plant_Name}(${e.Plant})`}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            <input
                type="checkbox"
                id="my-modal-11"
                className="modal-toggle"
                checked={openClose}
            />
            <div className="modal">
                <div className="modal-box">
                    {/* <label htmlFor="my-modal-11" onClick={(e)=>{setOpenCLose(false); setPicUrl('')}} className="btn btn-sm btn-circle absolute right-2 top-2">✕</label> */}

                    <div className=" w-full h-72 flex items-center justify-center bg-white">
                        <h1 className=" text-green-500 font-bold text-xl">{`Inserting ${loop} of ${company.length}`}</h1>
                    </div>
                </div>
            </div>
        </div>
    );
}
