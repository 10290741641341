import React,{useState,useRef,useEffect} from 'react'
import Select from "react-tailwindcss-select";
import { PDFDocument, rgb } from 'pdf-lib';
import getCompanyList from '../../services/CompanyListService';
import getPlantList from '../../services/PlantListService';
import CreateNoticeService from '../service/CreateNoticeService';
import NoticeCompanyPlantService from '../service/NoticeCompanyPlantService';
import InsertNoticeImageService from '../service/InsertNoticeImageService';
import InsertNoticePdfService from '../service/InsertNoticePdfService';
import Loading from '../../components/loader/Loading';
import SuccessAlert from '../../components/alert_component/SuccessAlert';
import { baseUrl } from '../../utils/path';
import { useHomePageContext } from '../../components/home_component/HomePageContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SendNoticeNotificationService from '../service/SendNoticeNotificationService';
const companyUrl=`${baseUrl}getCompanyList`;
const plantUrl=`${baseUrl}get_plant_details`;

export default function CreateNoticePage() {
    const titleRef=useRef(null);
    const [title,setTitle]=useState('');
    const [description,setDescription]=useState('');
    const [noticeImages, setNoticeImages] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [userId, setUserId] = useState('');
    const [companies,setCompanies]=useState([]);
    const [plants,setPlants]=useState([]);
    let companyList = [];
    let plantList=[];
    const [company,setCompany]=useState([]);
    const [plant,setPlant]=useState([]);
    const [plist,setPList]=useState([]);
    const [isLoading,setIsLoading]=useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [employeeId,setEmployeeId]=useState('');

    const {menu,setMenu}=useHomePageContext();

    useEffect(()=>{
      const id = localStorage.getItem('userId');
      const eid=localStorage.getItem('empId');
      
    setUserId(id);
    setEmployeeId(eid);
        getCompany();
    },[]);

    let providedObject = {
        "cmid": 0,
        "company_name": "ALL Company",
        "company_code": "ALL"
      };
    let providedObject2 = {
        "PMID": 0, "PLANT_NAME": "All Plant", "PLANT_CODE": "plantCode"
      };

      companyList.splice(0, 0, providedObject);
      plantList.splice(0, 0, providedObject2);
      companyList = companyList.concat(companies);
      plantList=plantList.concat(plants);

    const getCompany=async()=>{
        const result= await getCompanyList(companyUrl);
        setCompanies(result.items);
        
    }

    const [tempPlant,setTempPlant]=useState([]);
    let pppList=[];
    let temp=[];
    const  getPlant=async(code)=>{

        const plant=await getPlantList(plantUrl,code);
        console.log(plant.PLANT_DETAILS);
    
        setPlants(plant.PLANT_DETAILS);
        const options2 = plant.PLANT_DETAILS.map(plant => ({
          value: plant.PLANT_CODE,
          label: `${plant.PLANT_NAME}(${plant.PLANT_CODE})`
      }));
      temp=temp.concat(plant.PLANT_DETAILS);
      pppList=pppList.concat(options2);
      console.log(` tem len: ${temp.length}`);

      setPList(pppList)
      setTempPlant(temp);
      
    
        }

    // Assuming companyList is an array of objects with 'cmid', 'company_name', and 'company_code' properties
const options = companyList.map(company => ({
    value: company.company_code,
    label: `${company.company_name}(${company.company_code})`
}));
    // Assuming companyList is an array of objects with 'cmid', 'company_name', and 'company_code' properties


// console.log(options2.length);


const [isPlantShow,setIsplantShow]=useState(true);
    const handleChange = value => {
        // console.log("company:", value);
        setCompany(value);
       
        if(value !==null){
        
          if(value[0].value==="ALL"){
            setIsplantShow(false);
          }
          else{
            setIsplantShow(true);
            if(plist.length!==0){
              setPList([]);
              setTempPlant([])
            }
            for(var i=0;i<value.length;i++){
              getPlant(value[i].value);
          }

          }
          
        }
        else{
          setIsplantShow(true);
          setPList([]);
          setTempPlant([])
        }
       
    };
    const handleChangePlant = value => {
        console.log("plant:", value);
        setPlant(value);
        
    };

    const [pdfBase64Array, setPdfBase64Array] = useState([]);
    const acceptedFilesRef = useRef([]);

 
    
    

    // const handleFileUpload = async (e) => {
    //     const files = e.target.files;
    //     const newPdfBase64Array = [];
    
    //     for (let i = 0; i < files.length; i++) {
    //       const file = files[i];
    
    //       if (file && file.type === 'application/pdf') {
    //         if (file.size > 5 * 1024 * 1024) {
    //           alert(`The PDF "${file.name}" exceeds the 5MB limit and will be ignored.`);
    //         } else {
    //           const fileData = await file.arrayBuffer();
    //           const base64String = btoa(new Uint8Array(fileData).reduce((data, byte) => data + String.fromCharCode(byte), ''));
    //           newPdfBase64Array.push(base64String);
    //           acceptedFilesRef.current.push(file);
    //         }
    //       }
    //     }
    
    //     setPdfBase64Array(prevArray => [...prevArray, ...newPdfBase64Array]);
    
    //     // Reset input field if no accepted files
    //     if (acceptedFilesRef.current.length === 0) {
    //       e.target.value = '';
    //     }
    //   };

    const handleFileUpload = async (e) => {
      const files = e.target.files;
      const newPdfBase64Array = [];
      const newAcceptedFiles = [];
  
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
  
        if (file && file.type === 'application/pdf') {
          if (file.size > 5 * 1024 * 1024) {
            alert(`The PDF "${file.name}" exceeds the 5MB limit and will be ignored.`);
          } else {
            const fileData = await file.arrayBuffer();
            const base64String = btoa(new Uint8Array(fileData).reduce((data, byte) => data + String.fromCharCode(byte), ''));
            newPdfBase64Array.push(base64String);
            newAcceptedFiles.push(file);
          }
        }
      }
  
      setPdfBase64Array([...newPdfBase64Array]);
  
      acceptedFilesRef.current = newAcceptedFiles;
  
      // Reset input field if no accepted files
      if (newAcceptedFiles.length === 0) {
        e.target.value = '';
        setPdfBase64Array([]);
      }
    };


    const resizeImage = (image, maxWidth, maxHeight) => {
        return new Promise((resolve) => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
    
          const width = image.width;
          const height = image.height;
    
          let newWidth = width;
          let newHeight = height;
    
          if (width > height) {
            if (width > maxWidth) {
              newHeight = (maxWidth / width) * height;
              newWidth = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              newWidth = (maxHeight / height) * width;
              newHeight = maxHeight;
            }
          }
    
          canvas.width = newWidth;
          canvas.height = newHeight;
    
          ctx.drawImage(image, 0, 0, newWidth, newHeight);
    
          canvas.toBlob((blob) => {
            resolve(blob);
          }, 'image/jpeg');
        });
      };
    
      // const handlePhotograph = async (event) => {
      //   const files = event.target.files;
    
      //   const newImages = [];
      //   const newPreviews = [];
    
      //   const resizeAndConvert = async (file) => {
      //     const reader = new FileReader();
      //     reader.onload = async () => {
      //       const image = new Image();
      //       image.src = reader.result;
    
      //       image.onload = async () => {
      //         const resizedBlob = await resizeImage(image, 800, 600); // Resize to 800x600
    
      //         const readerResized = new FileReader();
      //         readerResized.onload = () => {
      //           const base64String = readerResized.result.split(',')[1]; // Extract base64 string
      //           newImages.push(base64String);
      //           newPreviews.push(reader.result);
    
      //           if (newImages.length === files.length) {
      //             setNoticeImages([...noticeImages, ...newImages]);
      //             setImagePreviews([...imagePreviews, ...newPreviews]);
      //           }
      //         };
      //         readerResized.readAsDataURL(resizedBlob);
      //       };
      //     };
      //     reader.readAsDataURL(file);
      //   };
    
      //   for (let i = 0; i < files.length; i++) {
      //     await resizeAndConvert(files[i]);
      //   }
      // };

      const handlePhotograph = async (event) => {
        const files = event.target.files;
      
        const newImages = [];
        const newPreviews = [];
      
        const resizeAndConvert = async (file) => {
          const reader = new FileReader();
          reader.onload = async () => {
            const image = new Image();
            image.src = reader.result;
      
            image.onload = async () => {
              const resizedBlob = await resizeImage(image, 800, 600); // Resize to 800x600
      
              const readerResized = new FileReader();
              readerResized.onload = () => {
                const base64String = readerResized.result.split(',')[1]; // Extract base64 string
                newImages.push(base64String);
                newPreviews.push(reader.result);
      
                if (newImages.length === files.length) {
                  setNoticeImages([...newImages]);
                  setImagePreviews([...newPreviews]);
                }
              };
              readerResized.readAsDataURL(resizedBlob);
            };
          };
          reader.readAsDataURL(file);
        };
      
        for (let i = 0; i < files.length; i++) {
          await resizeAndConvert(files[i]);
        }
      };
      


    const handleDescriptionChange = (e) => {
        const inputText = e.target.value;
        if (inputText.length <= 500) {
            setDescription(inputText);
        } else {
            setDescription(inputText.slice(0, 500));
        }
    }

    const createNotice=async()=>{

      if(validateCreateNoticeError()){
        setIsLoading(true);
      
      const re=await CreateNoticeService(title,description,employeeId);
      const noticeId=re.NOTICE_ID;
      createNoticeCompanyPlant(noticeId);
      createNoticeImage(noticeId);
      createNoticePdf(noticeId);
      notify(noticeId);
     
      }
    }

    const notify=async(noticeId)=>{
      const re =await SendNoticeNotificationService(noticeId);
      console.log(re);
    }

    const createNoticeCompanyPlant=async(id)=>{
      let newArray;
      const newTempPlant = tempPlant.filter(plantItem => 
        plant.some(plantValue => plantValue.value === plantItem.PLANT_CODE)
      );
      console.log(`new: ${newTempPlant}`);
       
      if(plant.length===0){
         newArray=[{
          "notice_id": id.toString(),
          "plant_code":"ALL",
        "company_code": "ALL"
       
        }];
      }
      else{
        newArray = newTempPlant.map(p => ({
          "notice_id": id.toString(),
          "company_code": p.COMPANY_CODE,
          "plant_code":p.PLANT_CODE
        }));
      }
      // console.log(`new array: ${newArray[0]}`);
        const cp=await NoticeCompanyPlantService(newArray);
    }

    const createNoticeImage=async(id)=>{
      for(let i=0;i<noticeImages.length;i++){
        // console.log(`base image: ${noticeImages[i]}`);
        const ni=InsertNoticeImageService(id.toString(),noticeImages[i])
      }
    }

    const createNoticePdf=async(id)=>{

      for(let i=0;i<pdfBase64Array.length;i++){
        const np=await InsertNoticePdfService(id.toString(),pdfBase64Array[i]);
      }
      setIsLoading(false);
      showToastSuccess('Notice Created Successfully');
      setShowAlert(true);
      setTimeout(() => {
        setMenu(3000);
      }, 3200); // Delay of 1 seconds

    }

    //validator
    const [createNoticeError,setCreateNoticeError]=useState({});

    const validateCreateNoticeError=()=>{
      const createNoticeError={};
      
      if(!title.trim()){
        createNoticeError.title="Please Enter Title";
      }
      if(!description.trim()){
        createNoticeError.description="Please Enter Description";
      }
      // if(pdfBase64Array.length===0){
      //   createNoticeError.pdfBase64Array="Please Select Pdf";
      // }
      // if(noticeImages.length===0){
      //   createNoticeError.noticeImages="Please Select Pdf";
      // }
      // Validate Company
  if (company.length === 0) {
    
    createNoticeError.company = "Company is required";
  }

  // Validate Plant (if applicable)
  if (isPlantShow && tempPlant.length === 0) {
  
    createNoticeError.tempPlant = "Plant is required";
  }
      setCreateNoticeError(createNoticeError);

      return Object.keys(createNoticeError).length===0;
    }

    const showToastSuccess = (message) => {
      toast.success(message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT }); // autoClose is in milliseconds
  };

  const back=()=>{
    setMenu(3000);
  }


  return (
    <div className='my-16 mx-8'>
      <ToastContainer />
        {
          isLoading?
          <div className=' w-full h-screen flex justify-center items-center'>
            <Loading/>
          </div>
          :
          <div className=' flex w-full  justify-center items-center '>
            <div className='w-full flex flex-col items-start'>
                <div className='h-6'></div>
                
                <div className=' w-full flex flex-row  justify-between items-center'>
                <h1 className=' text-2xl text-black font-semibold'>Create Notice</h1>
                <button onClick={back} className=' py-1 px-10 rounded-full bg-buttonColor text-[16px] font-medium text-white'>Back</button>
                </div>
           
            
            <div className='h-6'></div>
            <p className=' text-lg text-orange-500'>Notice Title*</p>
           
            <div className='h-1'></div>
            <input ref={titleRef} onChange={(e)=>{setTitle(e.target.value)}} placeholder='Enter Notice Title' className='px-4 h-14 w-full rounded-md border-[1px] border-gray-200 focus:outline-none bg-white' type="text" />
            {createNoticeError.title && <span className={`text-red-500`}>{createNoticeError.title}</span>}
            <p className="text-sm text-gray-500">{title.length} / 150</p>
            <div className='h-3'></div>
            <p className=' text-lg text-orange-500'>Notice Description*</p>
            <div className='h-1'></div>
         
            <textarea
  placeholder='Enter Notice Description Here'
  className="p-2 w-full h-28 focus:outline-none border-[1px] border-gray-200 rounded-md bg-white"
  value={description}
  onChange={handleDescriptionChange}
/>
{createNoticeError.description && <span className={`text-red-500`}>{createNoticeError.description}</span>}
            <p className="text-sm text-gray-500">{description.length} / 500</p>
            <div className='h-3'></div>
            <p className=' text-lg text-orange-500'>Image</p>
            <div className='h-1'></div>
            <input 
        type="file" 
        
        onChange={handlePhotograph} 
        className="file-input file-input-bordered w-full bg-white" 
        accept="image/*" 
        multiple  // Enable multiple file selection
      />
      {createNoticeError.noticeImages && <span className={`text-red-500`}>{createNoticeError.noticeImages}</span>}
      <div className='h-2'></div>
      {imagePreviews.map((preview, index) => (
        <div key={index}>
          <h3 className=' text-sm text-black'>Image Preview {index + 1}:</h3>
          <img src={preview} alt={`Preview ${index + 1}`} style={{ maxWidth: '100%', maxHeight: '200px' }} />
          <div className='h-1'></div>
        </div>
      ))}
            
            <div className='h-3'></div>
            <p className=' text-lg text-orange-500'>PDF</p>
            <div className='h-1'></div>
           
        <input
        placeholder={acceptedFilesRef.current.length}
          type="file"
          accept=".pdf"
          className="file-input file-input-bordered w-full bg-white"
          multiple
          onChange={handleFileUpload}
        />
         {/* {createNoticeError.pdfBase64Array && <span className={`text-red-500`}>{createNoticeError.pdfBase64Array}</span>} */}
   
   {pdfBase64Array.length > 0 && pdfBase64Array.map((base64, index) => (
        <div key={index} className="mt-4">
          <embed
            src={`data:application/pdf;base64,${base64}`}
            type="application/pdf"
            width="100%"
            height="500px"
          />
        </div>
      ))}
      {
              showAlert && 
              <div className=' w-full flex flex-col items-start'>
                 <div className='h-3'></div>
                <SuccessAlert text={`Notice Created Successfully`} setShowAlert={setShowAlert} />
              </div>
            }
      <div className='h-3'></div>
            <p className=' text-lg text-orange-500'>Select Receiver</p>
            <div className='h-1'></div>
            <p className=' text-lg text-orange-500'>Company*</p>
            <Select
            placeholder='Select Company'
            isSearchable={true}
            isClearable={true}
            isMultiple={true}
            value={company}
            onChange={handleChange}
            options={options}
            classNames={'h-12'}
            
        />
        {createNoticeError.company && <span className={`text-red-500`}>{createNoticeError.company}</span>}
        <div className='h-3'></div>
        
       
        {
           isPlantShow?
                <div className=' w-full'>
                   <p className=' text-lg text-orange-500'>Plant*</p>
                  <Select
            placeholder='Select Plant'
            isSearchable={true}
            isClearable={true}
            isMultiple={true}
            value={plant}
            onChange={handleChangePlant}
            options={plist}
            classNames={'h-12'}
            
        />
                </div>
        
        :
        null
          
        }
        {createNoticeError.tempPlant && <span className={`text-red-500`}>{createNoticeError.tempPlant}</span>}
        

        <div className='h-10'></div>
        <button onClick={createNotice} className=' w-full h-10 bg-buttonColor text-white font-medium flex justify-center items-center rounded-md'>Create notice</button>
        <div className='h-10'></div>  
       
                        <div className=' h-48'></div>
        
        </div>
            </div>}

        
    </div>
  )
}