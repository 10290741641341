


const DateWiseEmployeeInfoService = async ( url ) => {
    // const dateWiseUrl=`http://wsaps4h01.weplbd.com:8001/sap/opu/odata/SAP/ZHCM_INFO_MOBILE_APP_SRV/ZHR_MOBILESet?$filter=(creation_date eq '${cdate}')&$format=json`;
    // const uname="";
    // const pass="";
      // console.log(`em:${empId} se: ${sequence} st: ${status} md: ${moduleId} au: ${authorizationId} ap: ${approvalId}`);
      console.log(`date wise url: ${url}`);
      const response = await fetch(url,
          {
              method: "GET",
              headers: {
                  "Content-Type": "application/json",
                  'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_SU}:${process.env.REACT_APP_SP}`),
                  
              },
              
          }
      );
      // if (!response.ok) {
      //     throw new Error("fetching is not successful");
      //   }
      const data = await response.json();
      console.log(`odata: ${data}`);
      return data;
  
  
  }
  export default DateWiseEmployeeInfoService
  