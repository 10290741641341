


const LeaveApplyService = async ( url ) => {

  console.log(`sap url: ${url}`);
  
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_SU}:${process.env.REACT_APP_SP}`),      
    },   
  });
  
  // if (!response.ok) {
  //     throw new Error("fetching is not successful");
  //   }
  const data = await response.json();
  console.log(`odata: ${data}`);
  return data;
}
export default LeaveApplyService
