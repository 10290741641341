import { DatePicker } from "rsuite";
import Datepicker from "react-tailwindcss-datepicker";
import React, { useEffect } from "react";
import { useState } from "react";
import Loading from "../../components/loader/Loading";
import moment from "moment";
import { baseUrl } from "../../utils/path";
import getCompanyList from "../../services/CompanyListService";
import getPlantList from "../../services/PlantListService";
// import MoveOrderReportService from "../service/MoveOrderReportService";

import { CSVLink } from "react-csv";
import HierarchyAssignReportService from "../service/HierarchyAssignReportService";

const companyUrl = `${baseUrl}getCompanyList`;
const moduleUrl = `${baseUrl}approval_module_types`;
const plantUrl = `${baseUrl}get_plant_details`;


export default function HierarchyAssignReportPage() {

  const [sateStatusCnt, setStageStatusCnt] = useState('');
  const [userStatusCnt, setUserStatusCnt] = useState('A');
  const [uId, setUid] = useState('');
  const [page, setPage] = useState(0);
  const [selectedValue, setSelectedValue] = useState(10);
  let limit = 10;
  let totalLimit = 100000;
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [totalPage, setTotalPage] = useState(null);
  const [pageCount, setPageCount] = useState(1)
  const [empId, setEmpId] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isSearch, setIsSearch] = useState(false);
  let [pageCountSearch, setPageCountSearch] = useState(1);
  let [pageSearch, setPageSearch] = useState(0);
  const [startEndDate, setStartEndDate] = useState({ startDate: null, endDate: null, });
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [companyCode, setCompanyCode] = useState('');
  const [plantCode, setPlantCode] = useState('');
  const [selectedCompanyname, setSelectedCompanyName] = useState('');
  const [selectedPlantname, setSelectedPlantName] = useState('');
  const [isCompanyLoading, setIsCompanyLoading] = useState(true);
  const [company, setCompany] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [isPlantVisible, setIsPlantVisible] = useState(false);
  const [plant, setPlant] = useState([]);
  const [isPlantLoading, setIsPlantLoading] = useState(true);
  const [searchById, setSearchById] = useState('');
  const [processedData, setProcessedData] = useState([]);
  const [allProcessedData, setAllProcessedData] = useState([]);
  const todayDate = moment(Date()).format('YYYY-MM-DD');
  const [today, setToday] = useState('');
  const [dateValue, setDateValue] = React.useState(null);
  const [startDate, setStartDate] = useState('');
  const [moveOrderStatus, setMoveOrderStatus] = useState('');

  useEffect(() => {
    setToday(todayDate);
    setStartDate(today)
    const id = localStorage.getItem('userId');
    setUid(id);
    getAll();
    allDownloadData("", "", "", "");
    // getPlanMaster();
    // stageStatusRef.current.value = "A";
    // userStatusRef.current.value = "A";
    setStageStatusCnt("A");
    setUserStatusCnt("A");

  }, [page]);

  useEffect(() => {
    const getCompanyName = async () => {
      const result = await getCompanyList(companyUrl);
      setCompanyList(result.items);
      setIsCompanyLoading(false);
    };
    getCompanyName();
  }, []);

  // initially get all data
  const getAll = async () => {
    const result = await HierarchyAssignReportService("", "", "", page,selectedValue);

    dividePage(result.Total_Count);
    // console.log("Move Order", result.ATTENDANCE_DETAILS);
    console.log(result.ATTENDANCE_DETAILS)

    setData(result.ATTENDANCE_DETAILS);
    // setData2(result.ATTENDANCE_DETAILS);
    processData(result.ATTENDANCE_DETAILS);
    setIsLoading(false);
  };
  console.log(data)

  const allDownloadData = async (stDate, eDate, compCode, plantCode, searchId, orderStatus) => {
    setIsLoading(true);

    // console.log("stDate: ", stDate);
    // console.log("eDate: ", eDate);
    console.log("compCode: ", compCode);
    console.log("plantCode: ", plantCode);
    console.log("searchId: ", searchId);

    const result = await HierarchyAssignReportService(searchId ? searchId : "", compCode ? compCode : "", plantCode ? plantCode : "", page, totalLimit,selectedValue);

    allProcessData(result.ATTENDANCE_DETAILS);
    setIsLoading(false);
  };

  // divided page number to allPage (for pagination)
  const dividePage = (number) => {
    console.log(number);
    if (typeof number !== 'number') {
      throw new Error('Input must be a number');
    }

    const re = Math.ceil(number / selectedValue);
    setTotalPage(re);
  };

  // go to next page
  const next = async () => {
    // setPage((page)=>page+10);
    const newPage = parseInt(page) + parseInt(selectedValue);
    setPage(newPage);
    console.log(`page here ${newPage}`);
    setPageCount((pre) => pre + 1);

    setIsLoading(true);
    setData([]);
    setData2([])

    const result = await HierarchyAssignReportService(searchById, companyCode, plantCode, newPage,selectedValue)

    dividePage(result.Total_Count);
    setData(result.ATTENDANCE_DETAILS);
    // setData2(result.ATTENDANCE_DETAILS);
    processData(result.ATTENDANCE_DETAILS);
    setIsLoading(false);

    console.log('clicked');
  }
  // console.log(page)

  // after Searching next page
  const searchNext = async () => {
    // setPage((page)=>page+10);
    const newPage = pageSearch + selectedValue;
    setPageSearch(newPage);
    console.log(`page here ${newPage}`);
    setPageCountSearch((pre) => pre + 1);

    setIsLoading(true);
    setData([]);
    setData2([]);

    const result = await HierarchyAssignReportService(searchById, companyCode, plantCode, newPage,selectedValue);

    dividePage(result.Total_Count);

    setData(result.ATTENDANCE_DETAILS);
    // setData2(result.ATTENDANCE_DETAILS);
    processData(result.ATTENDANCE_DETAILS);
    setIsLoading(false);
  }

  // back to previous page
  const previous = async () => {

    // setPage((pre)=>pre-10);
    const newPage = page - selectedValue;
    setPage(newPage);
    console.log(`page here ${newPage}`);
    setPageCount((pre) => pre - 1);

    console.log('previous button clicked');
    setIsLoading(true);
    setData([]);
    setData2([]);

    const result = await HierarchyAssignReportService(searchById, companyCode, plantCode, newPage,selectedValue);

    dividePage(result.Total_Count);

    setData(result.ATTENDANCE_DETAILS);
    // setData2(result.ATTENDANCE_DETAILS);
    processData(result.ATTENDANCE_DETAILS);
    setIsLoading(false);
  }

  // after Searching back previous
  const searchPrevious = async () => {
    const newPage = pageSearch - selectedValue;
    setPageSearch(newPage);
    console.log(`page here ${newPage}`);
    setPageCountSearch((pre) => pre - 1);

    console.log('clicked');
    setIsLoading(true);
    setData([]);
    setData2([]);

    const result = await HierarchyAssignReportService(searchById, companyCode, plantCode, newPage,selectedValue);

    dividePage(result.Total_Count);
    setData(result.ATTENDANCE_DETAILS);
    // setData2(result.ATTENDANCE_DETAILS);
    processData(result.ATTENDANCE_DETAILS);
    setIsLoading(false);
  }

  // convert date by day/month/year
  const convertDate = (dateString) => {
    const dateObject = new Date(dateString);
    const day = String(dateObject.getUTCDate()).padStart(2, '0');
    const month = String(dateObject.getUTCMonth() + 1).padStart(2, '0');
    const year = dateObject.getUTCFullYear();

    return `${day}/${month}/${year}`;
  };

  // using for see more and see less for description
  const [seeMore, setSeeMore] = useState(-1);

  const handleSeeMoreDetails = (index) => {
    setSeeMore(index === seeMore ? -1 : index);
  };

  // date select
  // const handleDateChange = async (newDate) => {
  //   console.log("start:", moment(newDate.startDate).format("YYYY-MM-DD"));
  //   console.log("end:", moment(newDate.endDate).format("YYYY-MM-DD"));
  //   setStartEndDate(newDate);

  //   setFromDate(newDate.startDate);
  //   setToDate(newDate.endDate);

  //   console.log("select date new: ", fromDate);
  //   setIsLoading(true);
  //   setData([]);

  //   const result = await MoveOrderReportService("", "", "", newDate.startDate, newDate.endDate, page, limit);

  //   dividePage(result.Total_Count);
  //   setData(result.ATTENDANCE_DETAILS);
  //   processData(result.ATTENDANCE_DETAILS);
  //   allDownloadData(newDate.startDate, newDate.endDate, "", "", "");
  //   setIsLoading(false);
  // }

  // company data
  const handleCompany = async (e) => {
    const selectedCode = e.target.value;
    const selectedName = companyList.find(c => c.company_code === selectedCode)?.company_name || '';

    console.log("Selected company code: ", selectedCode)

    setCompanyCode(selectedCode);
    setSelectedCompanyName(selectedName);
    getPlant(selectedCode);

    setIsLoading(true);
    setData([]);
    setData2([]);

    const result = await HierarchyAssignReportService("", selectedCode, "", page,selectedValue);

    dividePage(result.Total_Count);
    setData(result.ATTENDANCE_DETAILS);
    // setData2(result.ATTENDANCE_DETAILS);
    processData(result.ATTENDANCE_DETAILS);
    allDownloadData(selectedCode);
    setIsPlantVisible(true);
    setIsLoading(false);
  };

  // plant data 
  const handlePlant = async (e) => {
    // handleDropDown(e);
    // setPlantName(e.PLANT_NAME);

    const selectedPlantCode = e.target.value;
    setPlantCode(selectedPlantCode);

    const selectedName = plant.find(c => c.PLANT_CODE === selectedPlantCode)?.PLANT_NAME || '';
    setSelectedPlantName(selectedName);

    setIsLoading(true);
    setData([]);
    setData2([]);

    const result = await HierarchyAssignReportService("", companyCode, selectedPlantCode, page,selectedValue);

    dividePage(result.Total_Count);
    setData(result.ATTENDANCE_DETAILS);
    // setData2(result.ATTENDANCE_DETAILS);
    processData(result.ATTENDANCE_DETAILS);
    allDownloadData(companyCode, selectedPlantCode, "",);
    setIsLoading(false);
  };

  // plant list here
  const getPlant = async (code) => {
    const plant = await getPlantList(plantUrl, code);
    console.log("plant:=", plant);
    setPlant(plant.PLANT_DETAILS);

    setIsPlantLoading(false);
  }

  // for searching by employee id
  const searchEmpId = async () => {
    setIsLoading(true);
    console.log("searchingId: ", searchById);
    setData([]);
    setData2([]);

    const result = await HierarchyAssignReportService(searchById, companyCode, plantCode, page,selectedValue);

    dividePage(result.Total_Count);
    setData(result.ATTENDANCE_DETAILS);
    // setData2(result.ATTENDANCE_DETAILS);
    processData(result.ATTENDANCE_DETAILS);
    allDownloadData(companyCode, plantCode, searchById);
    setIsLoading(false);

    // setSearchById('');
  }

  const processData = (data) => {
    if (data && data.length > 0) {
      const newData = data.map((obj) => {
        const newDate = new Date(obj.REQUISITION_LOADING_DATE);
        const formattedDate = moment(newDate).format("DD/MM/YYYY");
        const convertTime = formatDate(obj.MOVE_ORDER_DATE);

        return { ...obj, REQUISITION_LOADING_DATE: formattedDate, MOVE_ORDER_DATE: convertTime };
      });
      setProcessedData(newData);
      console.log("processed Data: ", processedData)
    }

    else {
      console.log("Data is empty or undefined");
    }
  };

  const allProcessData = (data) => {
    if (data && data.length > 0) {
      const newData = data.map((obj) => {
        const newDate = new Date(obj.REQUISITION_LOADING_DATE);
        const formattedDate = moment(newDate).format("DD/MM/YYYY");
        const convertTime = formatDate(obj.MOVE_ORDER_DATE);

        return { ...obj, REQUISITION_LOADING_DATE: formattedDate, MOVE_ORDER_DATE: convertTime };
      });
      setAllProcessedData(newData);
    }

    else {
      console.log("Data is empty or undefined");
    }
  };

  const headers = [
    { label: "EMPLOYEE ID", key: "EMPLOYEE_ID" },
    { label: "EMPLOYEE NAME", key: "EMPLOYEE_NAME" },
    { label: "POSITION", key: "POSITION_NAME" },
    // { label: "SUBAREA", key: "PLANT_NAME" },
    { label: "DEPARTMENT", key: "DEPARTMENT_NAME" },
    { label: "IOU", key: "IOU" },
    { label: "LEAVE", key: "LEAVE" },
    { label: "LOAN", key: "LOAN" },
    { label: "MOVE ORDER", key: "MOVE_ORDER" },
    { label: "LEAVE REIMBURSMENT", key: "LEAVE_REIMBURSEMENT" },
    { label: "COMPLAIN", key: "COMPLAIN" },
    { label: "EMPLOYEE REQUISITION", key: "EMPLOYEE_REQUISITION" },
    { label: "EMPLOYEE REQUISITION IT", key: "EMPLOYEE_REQUISITION_IT" },
    { label: "TRANSPORT MOVE ORDER", key: "TRANSPORT_MOVE_ORDER" },
    { label: "EMPLOYEE REQUISITION HEAVY EQUIPMENT", key: "REQUISITION_HEAVY_EQUIPMENT" },
    { label: "EMPLOYEE REQUISITION SUPPLY CHAIN", key: "REQUISITION_SUPPLY_CHAIN" },
  ];

  // let fileName = moment(Date()).format("DD/MM/YYYY");

  const [fileName, setFileName] = useState(moment(Date()).format("DD/MM/YYYY"));

  // single date select
  const handleDateChange2 = async (newDate) => {
    console.log("new Date", newDate)
    const startDateFormatted = moment(newDate.startDate).format("YYYY-MM-DD");
    const endDateFormatted = moment(newDate.endDate).format("YYYY-MM-DD");

    console.log("start:", startDateFormatted);
    console.log("end:", endDateFormatted);

    setStartEndDate(newDate);

    // setStartEndDate(newDate);
    setDateValue(newDate);
    setFromDate(newDate.startDate);
    setToDate(newDate.endDate);

    console.log("select date new: ", fromDate);
    setIsLoading(true);
    setStartDate(startDateFormatted);
    setData([]);
    setData2([]);
    setCompanyCode([]);
    setPlantCode([]);
    setSearchById('');
    setPageCountSearch(1);
    setPageCount(1);
    // setPage(0);
    const p = 0;
    setPage(p);

    const allDataDate = {
      startDate: moment(newDate.startDate), // Replace with your actual start date
      endDate: moment(newDate.endDate),   // Replace with your actual end date
    };

    // Check if start date and end date are the same
    const useStartDate = allDataDate.startDate.isSame(allDataDate.endDate, 'day');

    // Update fileName to include the selected date or fallback to today's date
    const newFileName = useStartDate ? allDataDate.startDate.format("DD/MM/YYYY") : moment(Date()).format("DD/MM/YYYY");
    setFileName(newFileName);

    // const result = await MoveOrderReportService("", "", "",  moveOrderStatus, newDate.startDate ? newDate.startDate : todayDate, newDate.endDate ? newDate.endDate : todayDate, p, limit);

    // console.log("total: ", result.Total_Count)
    // dividePage(result.Total_Count);
    // setData(result.ATTENDANCE_DETAILS);
    // setData2(result.ATTENDANCE_DETAILS);
    // processData(result.ATTENDANCE_DETAILS);
    // // getTotalReport(startDateFormatted)
    // allDownloadData(startDateFormatted, endDateFormatted, "", "", "", moveOrderStatus);
    setIsLoading(false);
  }

  // cancel date
  // const handleCleanDate = () => {
  //   console.log("clean date clicked")

  //   setIsLoading(true);
  //   setPageCountSearch(1);
  //   setPageCount(1);
  //   setData([]);
  //   setData2([]);
  //   console.log("before: ", page);
  //   const p = 0;
  //   setPage(p);
  //   // setPage(0);

  //   // Update fileName to today's date
  //   setFileName(moment(Date()).format("DD/MM/YYYY")); 

  //   console.log("after: ", page);
  //   setStartDate(today);
  //   getAll(today, today, p);
  //   // getTotalReport(today);
  //   allDownloadData(today, today, "", "", "");
  //   setIsLoading(false);
  //   setDateValue(null);
  // }

  // format time
  const format12HourTime = (hour, minute) => {
    if (!hour || !minute) return "N/A";

    const formattedHour = (parseInt(hour, 10) % 12 || 12).toString().padStart(2, '0');
    const formattedMinute = parseInt(minute, 10).toString().padStart(2, '0');

    const period = parseInt(hour, 10) < 12 ? 'AM' : 'PM';
    return `${formattedHour}:${formattedMinute} ${period}`;
  };

  const formatDate = (dateTimeString) => {
    if (!dateTimeString) return "N/A";

    const formattedDate = moment.utc(dateTimeString).format("YYYY-MM-DD hh:mm A");
    return formattedDate;
  };

  // const handleMoveOrderStatus = async (status) => {
  //   setIsLoading(true);
  //   console.log(status);

  //   const result = await MoveOrderReportService(searchById ? searchById : "", companyCode ? companyCode : "", plantCode ? plantCode : "", status, fromDate ? fromDate : todayDate, toDate ? toDate : todayDate, page, limit);

  //   // const result = await MoveOrderReportService(searchById ? searchById : "", companyCode ? companyCode : "", plantCode ? plantCode : "", status , startDate ? startDate : todayDate, startDate ? startDate : todayDate, page, limit )

  //   dividePage(result.Total_Count);
  //   setData(result.ATTENDANCE_DETAILS);
  //   // setData2(result.ATTENDANCE_DETAILS);
  //   processData(result.ATTENDANCE_DETAILS);
  //   allDownloadData(fromDate ? fromDate : todayDate, toDate ? toDate : todayDate, companyCode, plantCode, searchById, status);

  //   // const re = await MyIouService(employeeId, status);
  //   // console.log("Details:", re.iou_details)
  //   setIsLoading(false);
  // }

// Handler function to update state when the dropdown value changes
const handleSelectChange = (event) => {
  const newValue = event.target.value;
  setSelectedValue(newValue);
  console.log("Selected value:", newValue);
};

useEffect(()=>{
  setIsLoading(true)
 setPage(0)
 setPageCount(1)
 getAll()
 setIsLoading(false)


},[selectedValue])

  return (
    <div className="mx-8 my-20 bg-white">
      <div className='w-full bg-white'>
        <h4 className=' text-black font-semibold lg:text-lg md:text-sm text-xs'>Hierarchy Assign Report</h4>

        <div className="h-5"></div>

        {
          isLoading ? (
            <Loading />
          ) : (
            <>
              <div className="flex items-center space-x-3">
                {/* date picker */}
                {/* <div className="w-1/4"> */}
                {/* <Datepicker
                    // useRange={moveTypeCode==='11'?true:false}
                    // asSingle={moveTypeCode!=='11'?true:false}
                    // popoverDirection="down"
                    // placeholder="Date From-To         "
                    // showFooter={true}
                    // primaryColor={"green"}
                    // showShortcuts={true}
                    // value={startEndDate}
                    // onChange={handleDateChange2}
                    // displayFormat={"DD/MM/YYYY"}
                    // toggleClassName={`absolute rounded-r-lg text-[#7F7F7F] right-0 h-full px-3 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed`}
                    // inputClassName="w-full rounded-md focus:ring-0 font-normal bg-green-100 dark:bg-green-900 dark:placeholder:text-green-100"
                    // containerClassName=" border border-[1px] border-gray-400 w-80 rounded-md"
                    // toggleClassName=" text-gray-400"
                    // inputClassName={`outline-buttonColor bg-white w-full rounded-r-lg h-[48px] border-[1px] border-gray-300 rounded-md px-3`}
                    // containerClassName="  "
                  /> */}

                {/* <DatePicker
                    className='w-full'
                    appearance="default"
                    size="lg"
                    format="dd-MM-yyyy"
                    placeholder="Select Date"
                    onChange={handleDateChange2}
                    value={dateValue}
                    onClean={handleCleanDate}
                    ranges={[
                      {
                        label: "Now",
                        value: new Date(),
                      },
                    ]}
                    // style={{ width: 335, height: 50 }}
                  /> */}
                {/* </div> */}

                <div className="w-1/3">
                  {
                    <select
                      placeholder='Select Company'
                      value={companyCode}
                      onChange={handleCompany}
                      className="w-full border bg-white h-[48px] rounded-lg px-2 focus:outline-none"
                    >
                      <option value='' disabled selected>Select Company</option>
                      {
                        companyList.map((e, index) =>
                          <option key={index} value={e.company_code}>{e.company_name}({e.company_code})</option>
                        )
                      }
                    </select>
                  }
                </div>

                <div className="w-1/3">
                  {isPlantVisible && (
                    <select
                      value={plantCode}
                      onChange={handlePlant}
                      className="w-full border bg-white h-[48px] rounded-lg px-2 focus:outline-none"
                    >
                      <option value="" disabled selected>Select Plant</option>
                      {
                        isPlantLoading ?
                          <p>loading.....</p>
                          : plant.map((e, index) =>
                            <option value={e.PLANT_CODE} key={index}>{e.PLANT_NAME}({e.PLANT_CODE})</option>
                          )
                      }
                    </select>
                  )}
                </div>

                <div className="flex flex-row space-x-2 items-center w-1/3">
                  <input onChange={(e) => { setSearchById(e.target.value) }} onKeyPress={(e) => { if (e.key === 'Enter') searchEmpId(); }} value={searchById} type="text" className="w-full px-3 h-[48px] rounded-lg border-[0.2px] border-gray-300 placeholder:text-sm placeholder:text-gray-400 focus:outline-none bg-white" placeholder='Search by Employee Id' />

                  <button onClick={() => { searchEmpId() }} className="bg-[#013E0E] h-[48px] w-[87px] px-3 flex justify-center items-center text-white rounded-lg">
                    {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                    </svg> */}

                    Search
                  </button>
                </div>
              </div>

              <div className="h-7"></div>

              {/* <button onClick={downloadExcel} className="bg-[#013E0E] h-[48px] w-[87px] px-3 flex justify-center items-center text-white rounded-lg">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" dataSlot="icon" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
                </svg>

                <p>Download</p>
              </button> */}

              <div className="flex items-center justify-end space-x-2">


                {data.length === 0 ? null : (
                  <CSVLink data={processedData} headers={headers} filename={`HIERARCHY_REPORT_${fileName}.csv`}>
                    <button className={` w-[180px] h-[48px] flex justify-center items-center bg-[#E6E1DD] px-2 rounded-lg shadow-sm text-[#013E0E] space-x-2 no-underline`}>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" dataSlot="icon" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
                      </svg>

                      <h2 className=" lg:text-sm md:text-xs text-xs">
                        Download
                      </h2>
                    </button>
                  </CSVLink>
                )}

                {data.length === 0 ? null : (
                  <CSVLink data={allProcessedData} headers={headers} filename={`ALL_HIERARCHY_REPORT_${fileName}.csv`}>
                    <button  className={` w-[180px] h-[48px] flex justify-center items-center bg-[#E6E1DD] px-2 rounded-lg shadow-sm text-[#013E0E] space-x-2`}>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" dataSlot="icon" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
                      </svg>

                      <h2 className=" lg:text-sm md:text-xs text-xs">
                        Download All
                      </h2>
                    </button>
                  </CSVLink>
                )}
              </div>

              <div className="h-7"></div>

              <div className="w-full">
                {/* {
                  data2.length === 0 ? (
                    <div className='flex flex-row justify-center items-center text-center w-full h-screen'>
                      <h1 className={`text-red-500 text-center w-full font-bold text-2xl`}>No Data Found</h1>
                    </div>
                  ) : (
                    <> */}
                <div className="overflow-x-auto">
                  {
                    data.length == 0 ? <><div><h1 className="text-red-600 text-center pt-[15%]">No Data Found</h1></div></> : <>
                      {
                        <table className="w-full border-[0.5px] border-gray-200 rounded-lg">
                          <thead className="bg-bgTableHeader text-tableHeaderText shadow-sm h-14 rounded-lg">
                            <tr>
                              <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                SL
                              </th>

                              <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                EMPLOYEE ID
                              </th>

                              <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                EMPLOYEE NAME
                              </th>

                              <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                POSITION
                              </th>

                              {/* <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                            SUBAREA
                          </th> */}

                              <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                DEPARTMENT
                              </th>

                              <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                IOU
                              </th>

                              <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                LEAVE

                              </th>

                              <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                LOAN
                              </th>

                              <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                MOVE ORDER
                              </th>

                              <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                LEAVE REIMBURSMENT
                              </th>

                              <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                COMPLAIN
                              </th>

                              <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                EMPLOYEE REQUISITION
                              </th>

                              <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                EMPLOYEE REQUISITION IT
                              </th>

                              <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                TRANSPORT MOVE ORDER
                              </th>

                              <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                EMPLOYEE REQUISITION HEAVY EQUIPMENT
                              </th>

                              <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                EMPLOYEE REQUISITION SUPPLY CHAIN
                              </th>
                            </tr>
                          </thead>

                          <tbody className="w-full divide-y divide-borderColor">

                            {
                              data.map((item, index) => (
                                <tr key={index} className='bg-white'>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {parseInt(page) + parseInt(index) + 1}
                                   
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {item.EMPLOYEE_ID ? item.EMPLOYEE_ID : '---'}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {item.EMPLOYEE_NAME ? item.EMPLOYEE_NAME : '---'}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {item.POSITION_NAME ? item.POSITION_NAME : '---'}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {item.DEPARTMENT_NAME ? item.DEPARTMENT_NAME : '---'}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {item.IOU ? item.IOU : '---'}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {item.LEAVE ? item.LEAVE : '---'}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {item.LOAN ? item.LOAN : '---'}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {item.MOVE_ORDER ? item.MOVE_ORDER : '---'}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {item.LEAVE_REIMBURSEMENT
                                      ? item.LEAVE_REIMBURSEMENT
                                      : '---'}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {item.COMPLAIN
                                      ? item.COMPLAIN
                                      : '---'}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {item.EMPLOYEE_REQUISITION
                                      ? item.EMPLOYEE_REQUISITION
                                      : '---'}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {item.EMPLOYEE_REQUISITION_IT
                                      ? item.EMPLOYEE_REQUISITION_IT
                                      : '---'}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {item.TRANSPORT_MOVE_ORDER
                                      ? item.TRANSPORT_MOVE_ORDER
                                      : '---'}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {item.REQUISITION_HEAVY_EQUIPMENT
                                      ? item.REQUISITION_HEAVY_EQUIPMENT
                                      : '---'}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {item.REQUISITION_SUPPLY_CHAIN
                                      ? item.REQUISITION_SUPPLY_CHAIN
                                      : '---'}
                                  </td>
                                </tr>
                              ))
                            }
                          </tbody>
                        </table>

                      }</>
                  }




                </div>
                {/* </>
                  )
                } */}
              </div>
            </>
          )
        }

        {/* pagination start here */}

        <div className="h-5"></div>
        <div className="text-base font-bold">
          <label htmlFor="numbers">Show Data Per Page:</label>
          <select name="numbers" id="numbers" value={selectedValue} onChange={handleSelectChange}>
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>

        {data.length > 0 && (
          <div className='flex flex-row space-x-4 justify-center items-end mt-4 mb-3 lg:w-full md:w-3/4 sm:w-96 pr-6'>
            <button disabled={page === 0 && pageSearch === 0 ? true : false} onClick={() => { isSearch ? searchPrevious() : previous() }}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-6 h-6 font-bold ${page === 0 && pageSearch === 0 ? 'text-white' : `text-gray-600`} font-bold`}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
              </svg>
            </button>

            <p className={`text- text-[18px] font-bold`}>
              Page {isSearch ? pageCountSearch : pageCount} Of {totalPage}
            </p>

            <button disabled={(isSearch ? pageCountSearch : pageCount) === totalPage || data.length === 0} onClick={() => { isSearch ? searchNext() : next() }}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-6 h-6 font-bold ${((isSearch ? pageCountSearch : pageCount) === totalPage || data.length === 0) ? 'text-white' : 'text-gray-500'} font-bold`}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
              </svg>
            </button>
          </div>
        )}
      </div>
    </div>
  )
}