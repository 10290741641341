//UserRolePermissionService

import { baseUrl } from "../../utils/path";

 
const url=`${baseUrl}user_password_reset`;


  const UserResetPasswordService = async(userId, empId)=>{
    console.log(`user:${userId} Empid: ${empId}`);
   
  
    const response = await fetch(url,
        {
            method: "POST",
            headers: {
              
              "Content-Type": "application/json",
              'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
            },
            body: JSON.stringify({
              "Z_DATA": userId,
              "EMPLOYEE_ID":empId
            }),
          }
        );
     console.log(response);
          const data = await response.json();
        console.log(data);
          return data;
     
}
export default UserResetPasswordService