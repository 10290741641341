import { baseUrl } from "../../utils/path";


  

  const RequisitionItemService= async(reqId)=>{
   
   
    const url=`${baseUrl}requision_item_details`;
  
    const response = await   fetch(url,
        {
            method: "POST",
            headers: {
              
              "Content-Type": "application/json",
              'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
            },
            body: JSON.stringify({
               
                "REQ_ID":reqId
                 
            }),
          }
        );
     
          const data = await response.json();
        
          return data;

     
}
export default RequisitionItemService
