import React from 'react'
import { useIouContext } from '../context/IouContext';
// import MyIouPage from './MyIouPage';
// import IouDetailsPage from './IouDetailsPage';
import IouPage from './IouPage';
import CreateIouPage from './CreateIouPage';

export default function MyIouHome() {

    const {iouPageNo, setIouPageNo}=useIouContext();
  return (
    <div>

{(() => {
            switch (iouPageNo) {
              case 1:
                return <IouPage />  
              case 2:
                return <CreateIouPage />
             

              default:
                return null
            }
          })()}
    </div>

    
  )
}
