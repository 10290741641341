import React,{useState,useRef,useEffect} from 'react'
import CreateRoleService from '../service/CreateRoleService';
import GetRoleService from '../service/GetRoleService';
import Loading from '../../components/loader/Loading';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RoleWiseMenuCreationService from '../service/RoleWiseMenuCreationService';
import { useHomePageContext } from '../../components/home_component/HomePageContext';
export default function CreateRolePage() {
    const roleNameRef = useRef(null);

  const [roleName, setRoleName] = useState('');
  const [roleId, setRoleId] = useState('');
  const [isSelectAll, setIsSelectAll] = useState(null);
  const [userId, setUserId] = useState('');
  const [mobileMenuList, setMobileMenuList] = useState([]);
  const [webMenuList, setWebMenuList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const { menu, setMenu } = useHomePageContext();

  useEffect(() => {
    const id = localStorage.getItem('userId');
    setUserId(id);
  }, []);

  const handleSelectAll = () => {
    if (isSelectAll === null || isSelectAll === false) {
      setIsSelectAll(true);
      const newSelectedItems = mobileMenuList.map(menu => ({
        Z_DATA: userId,
        ROLE_ID: roleId,
        MENU_ID: menu.XXMA_MENU_ID,
      }));
      setSelectedItems(newSelectedItems);

      const newSelectedItems2 = webMenuList.map(menu => ({
        Z_DATA: userId,
        ROLE_ID: roleId,
        MENU_ID: menu.MENU_ID,
      }));
      setSelectedItems(prevItems => [...prevItems, ...newSelectedItems2]);
    } else {
      if (isSelectAll === true) {
        setIsSelectAll(null);
        setSelectedItems([]);
      }
    }
  };

  const handleUnselectAll = () => {
    if (isSelectAll === null || isSelectAll === true) {
      setIsSelectAll(false);
      setSelectedItems([]);
    } else {
      if (isSelectAll === false) {
        setIsSelectAll(null);
        setSelectedItems([]);
      }
    }
  };

  const handleToggleSelect = menuId => {
    const isSelected = selectedItems.some(item => item.MENU_ID === menuId);

    if (isSelected) {
      setSelectedItems(prevItems =>
        prevItems.filter(item => item.MENU_ID !== menuId)
      );
    } else {
      setSelectedItems(prevItems => [
        ...prevItems,
        {
          Z_DATA: userId,
          ROLE_ID: roleId,
          MENU_ID: menuId,
        },
      ]);
    }
  };
  const [roleError,setRoleError]=useState({});

 const validateRoleName=()=>{
        const roleError={};
        
        if(!roleName.trim()){
          roleError.roleName='Please Enter Item Price';
        }
        setRoleError(roleError);
        return Object.keys(roleError).length ===0;

    }

  const createRole = async () => {
   if(validateRoleName()){
    setIsLoading(true);
    const re = await CreateRoleService(userId, roleName);
    if(re.MESSAGE==='The Name Already Exists!'){
      showToastError(re.MESSAGE);
      setIsLoading(false);
    }
    else{
      setRoleId(re.ROLE_ID);
      getMenus();
      setIsLoading(false);
    }
   
     
  
   
    // const re2 = await RoleWiseMenuCreationService(
    //   userId,
    //   re.ROLE_ID,
    //   3000
    // );
   
   }
  };

  const getMenus = async () => {
    const re = await GetRoleService(userId);
    setMobileMenuList(re.data.Menus_Mobile);
    setWebMenuList(re.data.Menus_Web);
  };

  const showToastError = message => {
    toast.error(message, {
      autoClose: 3000,
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const showToastSuccess = message => {
    toast.success(message, {
      autoClose: 3000,
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const insertMenuRole = async () => {
    if (roleName === '') {
      showToastError('Please Create a Role First');
    } else {
      setIsLoading(true);
      for (let i = 0; i < selectedItems.length; i++) {
        const re = await RoleWiseMenuCreationService(
          selectedItems[i].Z_DATA,
          selectedItems[i].ROLE_ID,
          selectedItems[i].MENU_ID
        );
      }
      setIsLoading(false);
      showToastSuccess('Created Successfully');
      setTimeout(() => {
        setMenu(3000);
      }, 3300);
    }
  };
   

  return (
    <div className=' my-20 px-8'>
        <ToastContainer/>
        <p className=' text-lg font-bold text-black'>Create Role</p>
        <div className='h-4'></div>
        <div className=' w-3/4 flex flex-row items-center space-x-3'>
            <input ref={roleNameRef} onChange={(e)=>{setRoleName(e.target.value)}} type="text" placeholder='Role Name' className=' focus:outline-none px-2 h-12 rounded-md w-64 border-[1px] bg-white border-gray-300' />
            <button 
            onClick={createRole}
            className=' h-12 px-4 shadow-sm bg-buttonColor rounded-md text-white font-semibold'>
                Create Role
            </button>
        </div>

        {roleError.roleName && <span className={`text-red-500`}>{roleError.roleName}</span>}
        <div className='h-4'></div>
        <div className=' w-full flex flex-row space-x-6 items-center'>
            {roleId && (
              <>
                <button   
                  onClick={handleSelectAll}   
                  className=' flex flex-row space-x-2 items-center'>
                    <div className={`${isSelectAll===true?"bg-green-600 ":"bg-white border-[1px] border-gray-400"} h-4 w-4 rounded-md flex justify-center items-center`}>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-3 h-3 text-white">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                      </svg>
                    </div>
                    
                    <p className=' text-sm font-medium  text-gray-400'>Selelct All</p>
                </button>
              </>
            )}
            {/* <button  
             onClick={handleUnselectAll}       
            className=' flex flex-row space-x-2 items-center'>
            <div className={`${isSelectAll===false?"bg-green-600 ":"bg-white border-[1px] border-gray-400"} h-4 w-4 rounded-md flex justify-center items-center`}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-3 h-3 text-white">
  <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
</svg>
            </div>
            <p className=' text-sm font-medium  text-gray-400'>Unselelct All</p>
            </button> */}
        </div>
        <div className='h-4'></div>
       
            {
                isLoading?

                <div className=' w-full h-60 flex justify-center items-center'>
                    <Loading/>
                </div>

                :
                <div className=' flex flex-col items-start w-full'>
               { 
                mobileMenuList.length===0?
                null:
               
               <p className=' text-[18px] font-semibold text-black'>Mobile Menu</p>}
                <div className='h-4'></div>
                <div className=' w-full grid grid-cols-4 gap-6'>
                {
                    mobileMenuList.map((e,i)=>(
                        <button
                        key={i}
              onClick={() => handleToggleSelect(e.XXMA_MENU_ID)}
    
                        className={`flex flex-row space-x-2 items-center`}
                        >
                            <div className={`${selectedItems.some(item => item.MENU_ID === e.XXMA_MENU_ID) ? 'bg-green-600' : ' border-[1px] border-gray-300 bg-white'} h-4 w-4 rounded-md  flex justify-center items-center`}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-3 h-3 text-white">
      <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
    </svg>
    
    
                            </div>
                            <p className='text-start'>{e.XXMA_MENU_NAME}</p>
    
                        </button>
                    ))
                }
                  </div>
                <div className='h-4'></div>
                {
                    webMenuList.length===0?
                    null:
                    <p className=' text-[18px] font-semibold text-black'>Web Menu</p>}
                <div className='h-4'></div>
                <div className=' w-full grid grid-cols-4 gap-6'>
                {
                    webMenuList.map((e,i)=>(
                        <button
                        key={i}
              onClick={() => handleToggleSelect(e.MENU_ID)}
    
                        className={`flex flex-row space-x-2 items-center`}
                        >
                            <div className={`${selectedItems.some(item => item.MENU_ID === e.MENU_ID) ? 'bg-green-600' : ' border-[1px] border-gray-300 bg-white'} h-4 w-4 rounded-md  flex justify-center items-center`}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-3 h-3 text-white">
      <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
    </svg>
    
    
                            </div>
                            <p className='text-start'>{e.MENU_NAME}</p>
    
                        </button>
                    ))
                }
                 </div>
                </div>
               
            }
      
        <div className='h-4'></div>
        { roleId && (
          <>
        <button 
        onClick={insertMenuRole}
        className=' py-1 px-4 bg-buttonColor rounded-md shadow-sm text-white font-semibold'>
            SAVE
        </button>
        </>
      )}
    </div>
  )
}

