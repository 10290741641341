import { useEffect, useState } from "react";
import { Loader } from "rsuite";
import Loading from "../../components/loader/Loading";
import GetJobFromSapService from "../service/GetJobFromSapService";
import { baseUrl, sapBaseUrl } from "../../utils/path";
import SyncJobService from "../service/SyncJobService";

const url =
  `${sapBaseUrl}/ZHCM_INFO_MOBILE_APP_SRV/ZHR_JOBSet?$format=json`;

    // const syncUrl = `${baseUrl}job_sync`;

export default function JobSyncPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [job, setJob] = useState([]);
  const [loop, setLoop] = useState(1);
  const [openClose, setOpenClose] = useState(false);
  const [userId, setUserId] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [searchTerm, setSearchTerm] = useState(""); // S

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const id = localStorage.getItem("userId");
      setUserId(id);
      // getAdminInfo(id);
    }, []);

    const getData = async () => {
        setIsLoading(true);
        const re = await GetJobFromSapService(url);
        console.log(re.d.results);
        setJob(re.d.results);
        setIsLoading(false);
    };


    const toggleCheckbox = (positionId) => {
        if (selectedItems.includes(positionId)) {
          setSelectedItems(selectedItems.filter((id) => id !== positionId));
        } else {
          setSelectedItems([...selectedItems, positionId]);
        }
      };
    
      const toggleSelectAll = () => {
        setSelectAll(!selectAll);
        setSelectedItems(selectAll ? [] : job.map((item) => item.job_id));
      };

      const sync = async () => {
        setOpenClose(true);
    
        const itemsToSync = selectAll
          ? job
          : job.filter((item) => selectedItems.includes(item.job_id));
    
        for (const e of itemsToSync) {
          const re = await SyncJobService(userId, e.Job_name, e.job_id);
          console.log(re);
        }
    
        setOpenClose(false);
        setSelectedItems([]);
        setSelectAll(false);
      };
    
      // Step 2: Add a search input field
      const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
      };
    
      // Step 3: Filter the position array based on the search term
      const filteredPosition = job.filter(
        (item) =>
          item.Job_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.job_id.toLowerCase().includes(searchTerm.toLowerCase())
      );

    return (
        <div>
          {isLoading ? (
            <div className=" w-full h-screen flex justify-center items-center">
              <Loading />
            </div>

          ) : (
            <div className=" my-24 mx-4">
              <div className="w-full h-16 flex flex-row justify-between space-x-4 items-center bg-offWhiteColor rounded-t-md border-[0.5px] border-borderColor px-3">
                {/* <div className="h-full text-sm font-mon text-blackishColor font-medium  flex justify-center items-center">
                  Job Name
                </div> */}

                <div className="">
                  {/* Step 2: Add a search input field  */}
                  <input
                    type="text"
                    placeholder="Search by Job Name or Id..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                    className="border rounded-md w-60 bg-white px-2 h-12 focus:outline-none"
                  />
                </div>

                {/* <button onClick={sync} className=" h-7 w-16 rounded-md bg-green-500 text-sm text-white font-semibold">
                  Sync
                </button> */}

                <div className="flex items-center space-x-3">
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      checked={selectAll}
                      onChange={toggleSelectAll}
                      className="mr-2"
                    />
                   
                    <span>Select All</span>
                  </div>

                  <button
                    onClick={sync}
                    className="h-12 w-40 flex items-center justify-center font-semibold space-x-1 rounded-md bg-buttonColor text-white"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                    </svg>

                    <p>Sync</p>
                  </button>
                </div>
              </div>

              {/* <div className="h-4"></div> */}

              <div className="h-4"></div>


              {/* loop er last er jonno rounded-b-md dibo */}
              {/* <div className="grid grid-cols-4 gap-4 border">
                  {job.map((j, k) => (
                    <div className={`w-full py-4 flex flex-row space-x-4 ${ k === job.length - 1 ? " rounded-b-md" : "" } items-start border-borderColor`} >
                      <p className="text-sm text-black font-medium px-2">
                        {k + 1}. {`${j.Job_name}(${j.job_id})`}
                      </p>
                    </div>
                  ))}
                </div> */}

                <table className="w-full border-collapse">
                  <thead className="bg-bgTableHeader text-tableHeaderText">
                    <tr>
                      <th className="border px-2 py-2 text-center font-semibold">Select</th>
                      <th className="border px-2 py-2 text-center font-semibold">SL</th>
                      <th className="border px-2 py-2 font-semibold">Job Name</th>
                      <th className="border px-2 py-2 font-semibold">Job ID</th>
                      {/* <th className="border text-sm text-black px-2 py-2 text-left font-semibold">SL</th>
                      <th className="border text-sm text-black px-2 py-2 font-semibold">Job Name</th>
                      <th className="border text-sm text-black px-2 py-2 font-semibold">Job ID</th> */}
                    </tr>
                  </thead>
                  
                  <tbody>
                    {filteredPosition.map((j, k) => (
                      <tr key={k} className={`w-full py-4 ${ k === filteredPosition.length - 1 ? "rounded-b-md" : "" }`} >
                        <td className="text-center border text-sm text-black font-medium px-2">
                          <input type="checkbox" checked={selectedItems.includes(j.job_id)} onChange={() => toggleCheckbox(j.job_id)} />
                        </td>
                        
                        <td className="border text-sm text-black font-medium text-center px-2">
                          {k + 1}.
                        </td>
                        
                        <td className="border text-sm text-black font-medium text-center px-2">
                          {j.Job_name}
                        </td>
                        
                        <td className="border text-sm text-black font-medium text-center px-2">
                          {j.job_id}
                        </td>
                        
                        {/* {job[k * 2 + 1] && (
                          <>
                            <td className="border text-sm text-black font-medium px-2">
                              {(k + 1) * 2}.
                            </td>
                            
                            <td className="border text-sm text-black font-medium px-2">
                              {job[k * 2 + 1].Job_name}
                            </td>
                            
                            <td className="border text-sm text-black font-medium px-2">
                              {job[k * 2 + 1].job_id}
                            </td>
                          </>
                        )} */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}  
            
            <input
              type="checkbox"
              id="my-modal-11"
              className="modal-toggle"
              checked={openClose}
            />
            
            <div className="modal">
              <div className="modal-box">
                {/* <label htmlFor="my-modal-11" onClick={(e)=>{setOpenCLose(false); setPicUrl('')}} className="btn btn-sm btn-circle absolute right-2 top-2">✕</label> */}

                <div className=" w-full h-72 flex items-center justify-center bg-white">
                  <h1 className=" text-green-500 font-bold text-xl">{`Inserting ${loop} of ${job.length}`}</h1>
                </div>
              </div>
            </div>
        </div>
    );
}
