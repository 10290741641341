import { baseUrl } from "../../../utils/path";





  const OfficeTimeSearchByPlant= async(plantCode)=>{

    const url=`${baseUrl}attendance_setup_list`;

    const response = await   fetch(url,
        {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
            },
            body: JSON.stringify({
                "COMPANY_CODE": null,
                "PLANT_CODE": plantCode,
                "COMPANY_NAME": null,
                "PLANT_NAME": null
            }),
          }
        );
        if (!response.ok) {
            throw new Error("fetching is not successful");
          }
        
          const data = await response.json();
         
          return data;

     
}
export default OfficeTimeSearchByPlant
