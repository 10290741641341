import { baseUrl } from "../../utils/path";



  const AddRemarksService= async(rowId,module,remarks)=>{
    console.log(rowId);
    console.log(module);
    console.log(remarks);

    const url=`${baseUrl}attendance_report_remarks_add`;

    const response = await   fetch(url,
        {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
            },
            body: JSON.stringify({
                "OBJECT_ID":rowId,
                "MODULE_TYPE":module,
                "REMARKS": remarks
            }),
          }
        );
        if (!response.ok) {
            throw new Error("fetching is not successful");
          }
        
          const data = await response.json();
         
          return data;

     
}
export default AddRemarksService
