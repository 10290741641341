import React, { useEffect, useState, useRef } from "react";
import {
    gray500,
    gray100,
    red500,
    gray300,
    gray400,
    green100,
    green600,
    gray700,
    gray600,
} from "../../utils/colors";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers";
import Datepicker from "react-tailwindcss-datepicker";
import { DataGrid } from "@mui/x-data-grid";
import FrontDeskCompanyListService from "../service/FrontDeskCompanyListService";
import FrontDeskPlantListService from "../service/FrontDeskPlantListService";
import InputLebel from "../../common/InputLebel";
import moment from "moment";
import AttendanceReportService from "../service/AttendanceReportService";
import Loading from "../../components/loader/Loading";
import AddRemarksService from "../service/AddRemarksService";
import { CSVLink } from "react-csv";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function FrontDeskReportPage() {
    const fromIdRef = useRef(null);
    const toIdRef = useRef(null);
    let selectedCompanyCode,
        positionId,
        jobId,
        selectedPalntCode,
        selectedModuleCode,
        selectedUserId,
        selectedAuthorizerId,
        selectPsgid,
        selectDocumentIdGolbal;
    const [company, setCompany] = useState([]);
    const [plant, setPlant] = useState([]);
    const [isPlantLoading, setIsPlantLoading] = useState(true);
    const [companyCode, setCompanyCode] = useState("");
    const [plantCode, setPlantCode] = useState("");
    const [adminId, setAdminId] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [value, setValue] = React.useState(null);
    const [value1, setValue1] = React.useState(null);
    const [fromId, setFromId] = useState("");
    const [toId, setToId] = useState("");
    const [attReportList, setAttReportList] = useState([]);
    const [attReportList2, setAttReportList2] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    let [pageCount, setPage] = useState(1);
    let [page, setPg] = useState(0);
    const limit = 10;
    const allLimit = 50000;
    const [isCompanyNameShow, setIsCompanyNameShow] = useState(true);
    const [isPlantNameShow, setIsPlantNameShow] = useState(true);
    const [isDateShow, setIsDateShow] = useState(true);
    const [isEmpIdShow, setIsEmpIdShow] = useState(true);
    const [isEmpNameShow, setIsEmpNameShow] = useState(true);
    const [isDesignationShow, setIsDesignationShow] = useState(true);
    const [isInTimehow, setIsInTimeShow] = useState(true);
    const [isOutTimehow, setIsOutTimeShow] = useState(true);
    const [isLateTimehow, setIsLateTimeShow] = useState(true);
    const [isEmpStatushow, setIsEmpStatusShow] = useState(true);
    const [isApplicationStatushow, setIsApplicationStatusShow] = useState(true);
    const [isRemarksMoveOrderShow, setIsRemarksMoveOrderShow] = useState(true);
    const [isFrontDeskRemarksShow, setIsFrontDeskRemarksShow] = useState(true);
    const [hidden, setHidden] = useState(false);
    const [hiddenList, setHiddenList] = useState([]);

    const addToHiddenList = (name) => {
        // Create a new array by spreading the existing items and adding the new name
        const updatedList = [...hiddenList, { name }];
        // Set the state with the new array
        setHiddenList(updatedList);

        // Update the visibility state based on the added name
        if (name === "COMPANY NAME") {
            setIsCompanyNameShow(false);
        } else if (name === "PLANT NAME") {
            setIsPlantNameShow(false);
        } else if (name === "DATE") {
            setIsDateShow(false);
        } else if (name === "EMPLYOEE ID") {
            setIsEmpIdShow(false);
        } else if (name === "EMPLOYEE NAME") {
            setIsEmpNameShow(false);
        } else if (name === "DESIGNATION") {
            setIsDesignationShow(false);
        } else if (name === "IN TIME") {
            setIsInTimeShow(false);
        } else if (name === "OUT TIME") {
            setIsOutTimeShow(false);
        } else if (name === "LATE TIME") {
            setIsLateTimeShow(false);
        } else if (name === "REMARKS MOVE ORDER") {
            setIsRemarksMoveOrderShow(false);
        } else if (name === "FRONT DESK REMARKS") {
            setIsFrontDeskRemarksShow(false);
        } else if (name === "EMPLOYEE STATUS") {
            setIsEmpStatusShow(false);
        } else if (name === "APPLICATION STATUS") {
            setIsApplicationStatusShow(false);
        }
        // Add more conditions for other columns if needed
    };

    const deleteFromHiddenList = (name) => {
        // Update the visibility state based on the deleted name
        if (name === "COMPANY NAME") {
            setIsCompanyNameShow(true);
        } else if (name === "PLANT NAME") {
            setIsPlantNameShow(true);
        } else if (name === "DATE") {
            setIsDateShow(true);
        } else if (name === "EMPLYOEE ID") {
            setIsEmpIdShow(true);
        } else if (name === "EMPLOYEE NAME") {
            setIsEmpNameShow(true);
        } else if (name === "DESIGNATION") {
            setIsDesignationShow(true);
        } else if (name === "IN TIME") {
            setIsInTimeShow(true);
        } else if (name === "OUT TIME") {
            setIsOutTimeShow(true);
        } else if (name === "LATE TIME") {
            setIsLateTimeShow(true);
        } else if (name === "REMARKS MOVE ORDER") {
            setIsRemarksMoveOrderShow(true);
        } else if (name === "FRONT DESK REMARKS") {
            setIsFrontDeskRemarksShow(true);
        } else if (name === "EMPLOYEE STATUS") {
            setIsEmpStatusShow(true);
        } else if (name === "APPLICATION STATUS") {
            setIsApplicationStatusShow(true);
        }
        // Add more conditions for other columns if needed

        // Remove the item from hiddenList
        const updatedItems = hiddenList.filter((item) => item.name !== name);
        setHiddenList(updatedItems);
        if (updatedItems.length === 0) {
            setHidden(false);
        }
    };

    const toggleColumnVisibility = (columnName) => {
        // Update the state based on the column name
        if (columnName === "COMPANY NAME") {
            setIsCompanyNameShow(!isCompanyNameShow);
            addToHiddenList(columnName);
        } else if (columnName === "PLANT NAME") {
            setIsPlantNameShow(!isPlantNameShow);
            addToHiddenList(columnName);
        } else if (columnName === "DATE") {
            setIsDateShow(!isDateShow);
            addToHiddenList(columnName);
        } else if (columnName === "EMPLYOEE ID") {
            setIsEmpIdShow(!isEmpIdShow);
            addToHiddenList(columnName);
        } else if (columnName === "EMPLOYEE NAME") {
            setIsEmpNameShow(!isEmpNameShow);
            addToHiddenList(columnName);
        } else if (columnName === "DESIGNATION") {
            setIsDesignationShow(!isDesignationShow);
            addToHiddenList(columnName);
        } else if (columnName === "IN TIME") {
            setIsInTimeShow(!isInTimehow);
            addToHiddenList(columnName);
        } else if (columnName === "OUT TIME") {
            setIsOutTimeShow(!isOutTimehow);
            addToHiddenList(columnName);
        } else if (columnName === "LATE TIME") {
            setIsLateTimeShow(!isLateTimehow);
            addToHiddenList(columnName);
        } else if (columnName === "REMARKS MOVE ORDER") {
            setIsRemarksMoveOrderShow(!isRemarksMoveOrderShow);
            addToHiddenList(columnName);
        } else if (columnName === "FRONT DESK REMARKS") {
            setIsFrontDeskRemarksShow(!isFrontDeskRemarksShow);
            addToHiddenList(columnName);
        } else if (columnName === "EMPLOYEE STATUS") {
            setIsEmpStatusShow(false);
            addToHiddenList(columnName);
        } else if (columnName === "APPLICATION STATUS") {
            setIsApplicationStatusShow(!isApplicationStatushow);
            addToHiddenList(columnName);
        }
        // Add more conditions for other columns if needed
    };

    //pagination

    const next = async () => {
        // setPage((page)=>page+10);
        const newPage = page + limit;
        setPg(newPage);
        console.log(`page here ${newPage}`);
        setPage((pre) => pre + 1);

        setIsLoading(true);
        setAttReportList([]);
        setAttReportList2([]);

        submit(newPage);
        setIsLoading(false);

        console.log("clicked");
    };

    const previous = async () => {
        // setPage((pre)=>pre-10);
        const newPage = page - limit;
        setPg(newPage);
        console.log(`page here ${newPage}`);
        setPage((pre) => pre - 1);

        console.log("clicked");
        setIsLoading(true);
        setAttReportList([]);
        setAttReportList2([]);

        submit(newPage);
        setIsLoading(false);
        // console.log(result.ATTENDANCE_DETAILS);
    };

    const [totalPage, setTotalPage] = useState(null);

    const divideAndCeil = (number) => {
        if (typeof number !== "number") {
            throw new Error("Input must be a number");
        }

        const re = Math.ceil(number / limit);
        setTotalPage(re);
    };

    //pagination

    const [startEndDate, setStartEndDate] = useState({
        startDate: null,
        endDate: null,
    });
    const [openClose, setOpenClose] = useState(true);
    const [openClose2, setOpenClose2] = useState(false);

    useEffect(() => {
        // setOpenClose(true);
        const id = localStorage.getItem("userId");
        console.log(id);
        setAdminId(id);
        getCompany(id);
    }, []);

    const [remarks, setRemarks] = useState("");

    const handleRemarksChange = (event) => {
        const inputValue = event.target.value;
        if (inputValue.length <= 100) {
            setRemarks(inputValue);
        }
    };

    const [precompanyCode, setPreCompanyCode] = useState("");
    const [preplantCode, setPrePlantCode] = useState("");

    const [prestartDate, setPreStartDate] = useState("");
    const [preendDate, setPreEndDate] = useState("");

    const submit = async (page) => {
        if (validateFilter()) {
            setIsLoading(true);
            //fromId===''?null:fromId,(fromId !=='' && toId==='')?fromId:toId===''?null:toId
            console.log(`${startDate}===${prestartDate}`);

            if (
                companyCode !== precompanyCode ||
                plantCode !== preplantCode ||
                startDate !== prestartDate ||
                endDate !== preendDate
            ) {
                const newPage = 0;
                setPg(newPage);
                setPage(1);
                page = newPage;
                console.log(`my con page: ${page}`);
            } else {
                console.log("date are same");
            }

            const re = await AttendanceReportService(
                fromId === "" ? null : fromId,
                fromId !== "" && toId === ""
                    ? fromId
                    : toId === ""
                    ? null
                    : toId,
                companyCode,
                plantCode,
                startDate,
                endDate,
                page,
                limit
            );
            console.log(re.ATTENDANCE_DETAILS);
            setOpenClose(false);
            divideAndCeil(re.Total_Count);
            const updatedAttendanceDetails = re.ATTENDANCE_DETAILS.map(
                (attendance) => {
                    // const logInTime = parseTimeString(attendance.LOG_IN);
                    // const officeBufferTimeParsed = parseTimeString(
                    //     attendance.OFFICE_BUFFER_TIME
                    // );

                    // console.log("logInTime:", logInTime);
                    // console.log(
                    //     "officeBufferTimeParsed:",
                    //     officeBufferTimeParsed
                    // );

                    // // Calculate the time difference in milliseconds
                    // let timeDifference = logInTime - officeBufferTimeParsed;

                    // // Ensure a positive time difference
                    // if (timeDifference < 0) {
                    //     timeDifference = 0;
                    // }

                    // console.log("timeDifference:", timeDifference);

                    // // Calculate hours and minutes
                    // const hours = Math.floor(timeDifference / (60 * 60 * 1000));
                    // const minutes = Math.floor(
                    //     (timeDifference % (60 * 60 * 1000)) / (60 * 1000)
                    // );

                    // // Check if the difference is greater than 0
                    // if (timeDifference > 0) {
                    //     return {
                    //         ...attendance,
                    //         LATE_TIME: `${hours
                    //             .toString()
                    //             .padStart(2, "0")}:${minutes
                    //             .toString()
                    //             .padStart(2, "0")} hr`,
                    //     };
                    // } else {
                    //     return {
                    //         ...attendance,
                    //         LATE_TIME: null,
                    //     };
                    // }
                    const logInTime = parseTimeString(attendance.LOG_IN);
                    const officeBufferTimeParsed = parseTimeString(
                        attendance.OFFICE_BUFFER_TIME
                    );

                    console.log("logInTime:", logInTime);
                    console.log(
                        "officeBufferTimeParsed:",
                        officeBufferTimeParsed
                    );

                    // Calculate the time difference in milliseconds
                    let timeDifference = logInTime - officeBufferTimeParsed;

                    // Ensure a positive time difference
                    if (timeDifference < 0) {
                        timeDifference = 0;
                    }

                    console.log("timeDifference:", timeDifference);

                    // Calculate hours and minutes
                    const totalMinutes = Math.floor(
                        timeDifference / (60 * 1000)
                    );
                    const hours = Math.floor(totalMinutes / 60);
                    const minutes = totalMinutes % 60;

                    console.log("hours:", hours);
                    console.log("minutes:", minutes);

                    // Check if the difference is greater than 0
                    if (totalMinutes > 0) {
                        return {
                            ...attendance,
                            LATE_TIME: `${hours
                                .toString()
                                .padStart(2, "0")}:${minutes
                                .toString()
                                .padStart(2, "0")}`,
                        };
                    } else {
                        return {
                            ...attendance,
                            LATE_TIME: null,
                        };
                    }
                }
            );

            setAttReportList(updatedAttendanceDetails);
            setAttReportList2(updatedAttendanceDetails);
            // setAttReportList(re.ATTENDANCE_DETAILS);
            // setAttReportList2(re.ATTENDANCE_DETAILS);
            processData(re.ATTENDANCE_DETAILS);
            
            setPreCompanyCode(companyCode);
            setPrePlantCode(plantCode);
            setPreStartDate(startDate);
            setPreEndDate(endDate);
            setIsLoading(false);
        }
    };


    const allSubmit = async () => {
        if (validateFilter()) {
            setIsLoading(true);
            //fromId===''?null:fromId,(fromId !=='' && toId==='')?fromId:toId===''?null:toId
            console.log(`${startDate}===${prestartDate}`);

            if (
                companyCode !== precompanyCode ||
                plantCode !== preplantCode ||
                startDate !== prestartDate ||
                endDate !== preendDate
            ) {
                const newPage = 0;
                setPg(newPage);
                setPage(1);
                page = newPage;
                console.log(`my con page: ${page}`);
            } else {
                console.log("date are same");
            }

            const re = await AttendanceReportService(
                "",
                "",
                companyCode,
                plantCode,
                startDate,
                endDate,
                page,
                allLimit 
            );
            console.log("front desk details all: ", re.ATTENDANCE_DETAILS);
            setOpenClose(false);
            divideAndCeil(re.Total_Count);
            const updatedAttendanceDetails = re.ATTENDANCE_DETAILS.map(
                (attendance) => {
                    // const logInTime = parseTimeString(attendance.LOG_IN);
                    // const officeBufferTimeParsed = parseTimeString(
                    //     attendance.OFFICE_BUFFER_TIME
                    // );

                    // console.log("logInTime:", logInTime);
                    // console.log(
                    //     "officeBufferTimeParsed:",
                    //     officeBufferTimeParsed
                    // );

                    // // Calculate the time difference in milliseconds
                    // let timeDifference = logInTime - officeBufferTimeParsed;

                    // // Ensure a positive time difference
                    // if (timeDifference < 0) {
                    //     timeDifference = 0;
                    // }

                    // console.log("timeDifference:", timeDifference);

                    // // Calculate hours and minutes
                    // const hours = Math.floor(timeDifference / (60 * 60 * 1000));
                    // const minutes = Math.floor(
                    //     (timeDifference % (60 * 60 * 1000)) / (60 * 1000)
                    // );

                    // // Check if the difference is greater than 0
                    // if (timeDifference > 0) {
                    //     return {
                    //         ...attendance,
                    //         LATE_TIME: `${hours
                    //             .toString()
                    //             .padStart(2, "0")}:${minutes
                    //             .toString()
                    //             .padStart(2, "0")} hr`,
                    //     };
                    // } else {
                    //     return {
                    //         ...attendance,
                    //         LATE_TIME: null,
                    //     };
                    // }
                    const logInTime = parseTimeString(attendance.LOG_IN);
                    const officeBufferTimeParsed = parseTimeString(
                        attendance.OFFICE_BUFFER_TIME
                    );

                    console.log("logInTime:", logInTime);
                    console.log(
                        "officeBufferTimeParsed:",
                        officeBufferTimeParsed
                    );

                    // Calculate the time difference in milliseconds
                    let timeDifference = logInTime - officeBufferTimeParsed;

                    // Ensure a positive time difference
                    if (timeDifference < 0) {
                        timeDifference = 0;
                    }

                    console.log("timeDifference:", timeDifference);

                    // Calculate hours and minutes
                    const totalMinutes = Math.floor(
                        timeDifference / (60 * 1000)
                    );
                    const hours = Math.floor(totalMinutes / 60);
                    const minutes = totalMinutes % 60;

                    console.log("hours:", hours);
                    console.log("minutes:", minutes);

                    // Check if the difference is greater than 0
                    if (totalMinutes > 0) {
                        return {
                            ...attendance,
                            LATE_TIME: `${hours
                                .toString()
                                .padStart(2, "0")}:${minutes
                                .toString()
                                .padStart(2, "0")}`,
                        };
                    } else {
                        return {
                            ...attendance,
                            LATE_TIME: null,
                        };
                    }
                }
            );

            // setAttReportList(updatedAttendanceDetails);
            // setAttReportList2(updatedAttendanceDetails);
            // setAttReportList(re.ATTENDANCE_DETAILS);
            // setAttReportList2(re.ATTENDANCE_DETAILS);
            // processData(re.ATTENDANCE_DETAILS);
            allProcessData(re.ATTENDANCE_DETAILS);
            setPreCompanyCode(companyCode);
            setPrePlantCode(plantCode);
            setPreStartDate(startDate);
            setPreEndDate(endDate);
            setIsLoading(false);
        }
    };

    // function parseTimeString(timeString) {
    //   const [hours, minutes, period] = timeString.match(/(\d+):(\d+)\s*(\w+)/).slice(1, 4);

    //   let hoursParsed = parseInt(hours, 10);
    //   if (period.toLowerCase() === 'pm' && hoursParsed !== 12) {
    //     hoursParsed += 12;
    //   } else if (period.toLowerCase() === 'am' && hoursParsed === 12) {
    //     hoursParsed = 0;
    //   }

    //   return new Date(2000, 0, 1, hoursParsed, parseInt(minutes, 10));
    // }

    function parseTimeString(timeString) {
        if (timeString && typeof timeString === "string") {
            const matchResult = timeString.match(/(\d+):(\d+)\s*(\w+)/);

            if (
                matchResult &&
                Array.isArray(matchResult) &&
                matchResult.length >= 4
            ) {
                const [hours, minutes, period] = matchResult.slice(1, 4);

                let hoursParsed = parseInt(hours, 10);
                if (period.toLowerCase() === "pm" && hoursParsed !== 12) {
                    hoursParsed += 12;
                } else if (
                    period.toLowerCase() === "am" &&
                    hoursParsed === 12
                ) {
                    hoursParsed = 0;
                }

                return new Date(2000, 0, 1, hoursParsed, parseInt(minutes, 10));
            } else {
                // Handle the case where the timeString doesn't match the expected format
                console.error("Invalid time format:", timeString);
                return null; // or any other suitable value or action
            }
        } else {
            // Handle the case where timeString is not a valid string
            console.error("Invalid time string:", timeString);
            return null; // or any other suitable value or action
        }
    }

    const [filterError, setFilterError] = useState({});
    const validateFilter = () => {
        const filterError = {};
        if (companyCode === "") {
            filterError.companyCode = "Please Select Company";
        }
        if (plantCode === "") {
            filterError.plantCode = "Please Select Plant";
        }
        // if( String(fromId).trim() !== '0' && String(toId).trim() === ''){
        //   filterError.toId = 'Please Enter To Id';

        // }

        // if(String(toId).trim() !== '0' && String(fromId).trim() === ''){
        //   filterError.fromId = 'Please Enter From Id';

        // }
        if (fromId !== "" && toId !== "" && fromId > toId) {
            filterError.fromId = "From Id has to be Less than To Id";
        }

        if (startEndDate.startDate > startEndDate.endDate) {
            filterError.startEndDate = "From Date must be Less than to Date";
        }
        if (startEndDate.startDate === null && startEndDate.endDate === null) {
            filterError.startEndDate = "Please Select Date";
        }
        if (companyCode === "") {
            filterError.companyCode = "Please Select Company";
        }
        if (plantCode === "") {
            filterError.plantCode = "Please Select Plant";
        }
        // if(iouItemList.length===0){
        //   filterError.iouItemList="Please Add Iou Item";
        // }

        setFilterError(filterError);
        return Object.keys(filterError).length === 0;
    };

    const getCompany = async (id) => {
        const re = await FrontDeskCompanyListService(id);
        console.log(re.Company_List);
        setCompany(re.Company_List);
    };

    const getPlant = async (cid) => {
        console.log(cid);
        setIsPlantLoading(true);
        const re = await FrontDeskPlantListService(adminId, cid);
        setPlant(re.Plant_List);
        setIsPlantLoading(false);
    };

    const handleValueChange = (newValue) => {
        const startDate = new Date(newValue.startDate);
        const endDate = new Date(newValue.endDate);

        if (!isNaN(startDate) && !isNaN(endDate)) {
            const st = moment(startDate).format("YYYY-MM-DD");
            const et = moment(endDate).format("YYYY-MM-DD");
            setStartDate(st);
            setEndDate(et);

            setStartEndDate(newValue);
        } else {
            console.error("Invalid date format for newValue.startDate");
        }
    };

    const [sortDirection, setSortDirection] = useState("asc");

    const convertToTime = (timeString) => {
        if (timeString) {
            const parts = timeString.split(" ");
            if (parts.length === 2) {
                const [time, meridiem] = parts[0].split(":");
                const hours = parseInt(time, 10);
                const minutes =
                    parseInt(meridiem, 10) === "PM" ? hours + 12 : hours;
                return hours * 60 + minutes;
            }
        }
        return null;
    };

    // const handleSort = (columnName) => {
    //   const newData = [...attReportList];
    //   newData.sort((a, b) => {
    //     if (columnName === 'LOG_IN') {
    //       const timeA = convertToTime(a[columnName]);
    //       const timeB = convertToTime(b[columnName]);
    //       return timeA - timeB;
    //     } else {
    //       return a[columnName] - b[columnName];
    //     }
    //   });
    //   console.log(newData[0].LOG_IN);
    //   setAttReportList(newData);
    // };

    const handleSort = (columnName) => {
        const newData = [...attReportList];
        const direction = sortDirection === "asc" ? 1 : -1;

        newData.sort((a, b) => {
            if (columnName === "LOG_IN") {
                const timeA = convertToTime(a[columnName]);
                const timeB = convertToTime(b[columnName]);
                return (timeA - timeB) * direction;
            } else if (columnName === "LATE_TIME") {
                const timeA = convertToTime(a[columnName]);
                const timeB = convertToTime(b[columnName]);
                return (timeA - timeB) * direction;
            } else {
                return (a[columnName] - b[columnName]) * direction;
            }
        });

        setAttReportList(newData);
        setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    };

    const [selectedStatus, setSelectedStatus] = useState("all");

    const handleStatusChange = (e) => {
        const selectedValue = e.target.value.toLowerCase(); // Convert to lowercase
        setSelectedStatus(selectedValue);

        if (selectedValue === "all") {
            setAttReportList(attReportList2);
            processData(attReportList2);
            return;
        } else if (selectedValue === "present") {
            const filteredData = attReportList2.filter(
                (item) =>
                    item.LOG_IN ||
                    (item.OBJECT_SUB_TYPE_CODE &&
                        (item.OBJECT_SUB_TYPE_CODE.toLowerCase() ===
                            "official" ||
                            item.OBJECT_SUB_TYPE_CODE.toLowerCase() ===
                                "personal"))
            );

            setAttReportList(filteredData);
            processData(filteredData);
        } else if (selectedValue === "late") {
            const filteredData = attReportList2.filter(
                (item) => item.LATE_TIME
            );

            setAttReportList(filteredData);
            processData(filteredData);
        } else {
            const filteredData = attReportList2.filter(
                (item) =>
                    item.OBJECT_SUB_TYPE_CODE &&
                    item.OBJECT_SUB_TYPE_CODE.toLowerCase().includes(
                        selectedValue
                    )
            );

            setAttReportList(filteredData);
            processData(filteredData);
        }
    };

    const [applicationStatus, setApplicationStatus] = useState("");

    const handleAplicationStatusChange = (e) => {
        const selectedValue = e.target.value.toLowerCase(); // Convert to lowercase
        setApplicationStatus(selectedValue);

        if (selectedValue === "all") {
            setAttReportList(attReportList2);
            processData(attReportList2);
            return;
        } else if (selectedValue === "approved") {
            const filteredData = attReportList2.filter(
                (item) =>
                    item.AUTHORIZATION_STATUS &&
                    item.AUTHORIZATION_STATUS.toLowerCase() === "approved"
            );

            setAttReportList(filteredData);
            processData(filteredData);
        } else if (selectedValue === "rejected") {
            const filteredData = attReportList2.filter(
                (item) =>
                    item.AUTHORIZATION_STATUS &&
                    item.AUTHORIZATION_STATUS.toLowerCase() === "rejected"
            );

            setAttReportList(filteredData);
            processData(filteredData);
        } else {
            const filteredData = attReportList2.filter(
                (item) =>
                    item.AUTHORIZATION_STATUS &&
                    item.AUTHORIZATION_STATUS.toLowerCase() === "in process"
            );

            setAttReportList(filteredData);
            processData(filteredData);
        }
    };

    const showToastError = (message) => {
        toast.error(message, {
            autoClose: 3000,
            position: toast.POSITION.TOP_RIGHT,
        }); // autoClose is in milliseconds
    };
    const showToastSuccess = (message) => {
        toast.success(message, {
            autoClose: 3000,
            position: toast.POSITION.TOP_RIGHT,
        }); // autoClose is in milliseconds
    };

    const [module, setModule] = useState("");
    const [rowId, setRowId] = useState("");

    const clearVal = () => {
        setModule("");
        setRowId("");
        setRemarks("");
        // if (remarks !== '') {

        // }
    };

    const AddRemarks = async () => {
        console.log(rowId);
        console.log(module);
        console.log(remarks);

        const re = await AddRemarksService(rowId, module, remarks);
        setOpenClose2(false);
        showToastSuccess("Remarks Added Successfully");
        clearVal();
        setAttReportList([]);
        setAttReportList2([]);
        submit(page);
    };

    const [processedData, setProcessedData] = useState([]);
    const [allProcessedData, setAllProcessedData] = useState([]);

    const processData = (data) => {
        if (data && data.length > 0) {
            const newData = data.map((obj) => {
                const newDate = new Date(obj.CREATION_DATE);
                const formattedDate = moment(newDate).format("DD/MM/YYYY");

                return { ...obj, CREATION_DATE: formattedDate };
            });
            setProcessedData(newData);
        } else {
            console.log("Data is empty or undefined");
        }
    };

    const allProcessData = (data) => {
        if (data && data.length > 0) {
            const newData = data.map((obj) => {
                const newDate = new Date(obj.CREATION_DATE);
                const formattedDate = moment(newDate).format("DD/MM/YYYY");

                return { ...obj, CREATION_DATE: formattedDate };
            });
            setAllProcessedData(newData);
        } else {
            console.log("Data is empty or undefined");
        }
    };

    const headers = [
        { label: "Date", key: "CREATION_DATE" },
        { label: "COMPANY NAME", key: "COMPANY_NAME" },
        { label: "PLANT NAME", key: "PLANT_NAME" },
        { label: "EMPLYOEE ID", key: "EMPLOYEE_ID" },
        { label: "EMPLOYEE NAME", key: "EMPLOYEE_NAME" },
        { label: "DESIGNATION", key: "POSITION_NAME" },
        { label: "IN TIME", key: "LOG_IN" },
        { label: "OUT TIME", key: "LOG_OUT" },
        { label: "LATE TIME", key: "LATE_TIME" },
        { label: "EMPLOYEE STATUS", key: "OBJECT_SUB_TYPE_CODE" },
        { label: "REMARKS MOVE ORDER", key: "PURPOSE" },
        { label: "APPLICATION STATUS", key: "AUTHORIZATION_STATUS" },
        { label: "FRONT DESK REMARKS", key: "FRONT_DESK_REMARKS" },
    ];

    let fileName = moment(Date()).format("DD/MM/YYYY");

    const addText = (txt) => {
        if (txt === "Official" || txt === "Personal") {
            return `${txt} Move Order`;
        } else {
            return txt;
        }
    };

    return (
        <div className=" my-20 px-8 bg-white">
            <ToastContainer />
            <div className="bg-white flex flex-row justify-between items-center">
                <div className=" bg-white flex flex-row space-x-4  items-center ">
                    <button
                        onClick={() => {
                            setOpenClose(true);
                        }}
                        className=" py-2 px-4 bg-buttonColor shadow-md rounded-md"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6 text-white"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z"
                            />
                        </svg>
                    </button>

                    {processedData && (
                        <CSVLink
                            data={processedData}
                            headers={headers}
                            filename={`attendance_${fileName}.csv`}
                        >
                            <button
                                className={` w-auto h-10 flex justify-center items-center bg-buttonColor px-2 rounded-md shadow-sm`}
                            >
                                <h2 className=" lg:text-sm md:text-xs text-xs text-white  font-medium">
                                    Download
                                </h2>
                            </button>
                        </CSVLink>
                    )}

                    {allProcessedData && (
                        <CSVLink
                            data={allProcessedData}
                            headers={headers}
                            filename={`all_attendance_${fileName}.csv`}
                        >
                            <button
                                className={` w-auto h-10 flex justify-center items-center bg-buttonColor px-2 rounded-md shadow-sm`}
                            >
                                <h2 className=" lg:text-sm md:text-xs text-xs text-white  font-medium">
                                    Download All
                                </h2>
                            </button>
                        </CSVLink>
                    )}
                </div>

                <p className="flex-1"></p>
            </div>

            <div className=" h-6"></div>
            {isLoading ? (
                <div className=" w-full h-screen flex justify-center items-center">
                    <Loading />
                </div>
            ) : attReportList2.length === 0 ? (
                <div className=" flex justify-center items-center w-full h-[300px]">
                    <h1 className=" text-red-600 font-bold text-2xl">
                        No Data Found
                    </h1>
                </div>
            ) : (
                <div className=" overflow-x-auto">
                    <table className="border-[0.5px] border-gray-200">
                        <thead className=" bg-bgTableHeader text-tableHeaderText shadow-sm h-14">
                            <tr>
                                <td className="px-6 py-3 text-left text-sm  font-bold  text-gray-500 uppercase tracking-wider">
                                    <div className=" flex flex-col items-center space-y-0 ">
                                        <div className="menu menu-horizontal ">
                                            <li tabIndex={0}>
                                                {/* <img src="/images/logo.png" alt="logo" className=' h-8 w-8 rounded-full' /> */}

                                                <button
                                                    disabled={
                                                        hiddenList.length === 0
                                                            ? true
                                                            : false
                                                    }
                                                    onClick={() =>
                                                        setHidden(!hidden)
                                                    }
                                                    className=" hover:bg-transparent  text-green-600"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        strokeWidth={1.5}
                                                        stroke="currentColor"
                                                        className="w-6 h-6"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"
                                                        />
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                                        />
                                                    </svg>
                                                </button>

                                                {hidden && (
                                                    <ul
                                                        className={`rounded-md shadow-lg flex justify-start`}
                                                    >
                                                        <li className="space-y-1">
                                                            <div className="flex flex-col space-y-0 bg-white">
                                                                {hiddenList.map(
                                                                    (
                                                                        item,
                                                                        index
                                                                    ) => (
                                                                        <button
                                                                            key={
                                                                                index
                                                                            }
                                                                            onClick={() => {
                                                                                deleteFromHiddenList(
                                                                                    item.name
                                                                                );
                                                                            }}
                                                                            className=" flex w-full justify-start items-start"
                                                                        >
                                                                            {/* Render your button content here */}

                                                                            <p className=" text-sm ">{`${item.name} - X`}</p>
                                                                        </button>
                                                                    )
                                                                )}
                                                                <div className="border-gray-200 border-[1px]"></div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                )}
                                            </li>
                                        </div>

                                        <p>SL</p>
                                    </div>
                                </td>
                                {isDateShow ? (
                                    <th className="px-6 py-3 text-left text-sm text-gray-500 uppercase tracking-wider">
                                        <div className="w-full flex flex-row items-center space-x-1">
                                            <p>DATE</p>
                                            <button
                                                onClick={() =>
                                                    toggleColumnVisibility(
                                                        "DATE"
                                                    )
                                                }
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth={1.5}
                                                    stroke="currentColor"
                                                    className="w-4 h-4"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                    </th>
                                ) : null}

                                {isCompanyNameShow ? (
                                    <th className="px-6 py-3 text-left text-sm text-gray-500 uppercase tracking-wider">
                                        <div className="w-full flex flex-row items-center space-x-1">
                                            <p>COMPANY NAME</p>
                                            <button
                                                onClick={() =>
                                                    toggleColumnVisibility(
                                                        "COMPANY NAME"
                                                    )
                                                }
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth={1.5}
                                                    stroke="currentColor"
                                                    className="w-4 h-4"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                    </th>
                                ) : null}
                                {isPlantNameShow ? (
                                    <th className="px-6 py-3 text-left text-sm text-gray-500 uppercase tracking-wider">
                                        <div className="w-full flex flex-row items-center space-x-1">
                                            <p>PLANT NAME</p>
                                            <button
                                                onClick={() =>
                                                    toggleColumnVisibility(
                                                        "PLANT NAME"
                                                    )
                                                }
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth={1.5}
                                                    stroke="currentColor"
                                                    className="w-4 h-4"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                    </th>
                                ) : null}

                                {isEmpIdShow ? (
                                    <th className="px-6 py-3 text-left text-sm text-gray-500 uppercase tracking-wider">
                                        <div className="w-full flex flex-row items-center space-x-1">
                                            <p>EMPLYOEE ID</p>
                                            <button
                                                onClick={() =>
                                                    toggleColumnVisibility(
                                                        "EMPLYOEE ID"
                                                    )
                                                }
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth={1.5}
                                                    stroke="currentColor"
                                                    className="w-4 h-4"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                    </th>
                                ) : null}
                                {isEmpNameShow ? (
                                    <th className="px-6 py-3 text-left text-sm text-gray-500 uppercase tracking-wider">
                                        <div className="w-full flex flex-row items-center space-x-1">
                                            <p>EMPLOYEE NAME</p>
                                            <button
                                                onClick={() =>
                                                    toggleColumnVisibility(
                                                        "EMPLOYEE NAME"
                                                    )
                                                }
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth={1.5}
                                                    stroke="currentColor"
                                                    className="w-4 h-4"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                    </th>
                                ) : null}
                                {isDesignationShow ? (
                                    <th className="px-6 py-3 text-left text-sm text-gray-500 uppercase tracking-wider">
                                        <div className="w-full flex flex-row items-center space-x-1">
                                            <p>DESIGNATION</p>
                                            <button
                                                onClick={() =>
                                                    toggleColumnVisibility(
                                                        "DESIGNATION"
                                                    )
                                                }
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth={1.5}
                                                    stroke="currentColor"
                                                    className="w-4 h-4"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                    </th>
                                ) : null}

                                {isInTimehow ? (
                                    <th
                                        onClick={() => handleSort("LOG_IN")}
                                        className=" cursor-pointer px-6 py-3 text-left text-sm   text-gray-500 uppercase tracking-wider"
                                    >
                                        <div className=" flex flex-row space-x-2 items-center">
                                            IN TIME{" "}
                                            {sortDirection === "asc" ? (
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth={1.5}
                                                    stroke="currentColor"
                                                    className="w-6 h-6"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M19.5 13.5L12 21m0 0l-7.5-7.5M12 21V3"
                                                    />
                                                </svg>
                                            ) : (
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth={1.5}
                                                    stroke="currentColor"
                                                    className="w-6 h-6"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M4.5 10.5L12 3m0 0l7.5 7.5M12 3v18"
                                                    />
                                                </svg>
                                            )}
                                            <button
                                                onClick={() =>
                                                    toggleColumnVisibility(
                                                        "IN TIME"
                                                    )
                                                }
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth={1.5}
                                                    stroke="currentColor"
                                                    className="w-4 h-4"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                    </th>
                                ) : null}

                                {isOutTimehow ? (
                                    <th className="px-6 py-3 text-left text-sm text-gray-500 uppercase tracking-wider">
                                        <div className="w-full flex flex-row items-center space-x-1">
                                            <p>OUT TIME</p>
                                            <button
                                                onClick={() =>
                                                    toggleColumnVisibility(
                                                        "OUT TIME"
                                                    )
                                                }
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth={1.5}
                                                    stroke="currentColor"
                                                    className="w-4 h-4"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                    </th>
                                ) : null}

                                {isLateTimehow ? (
                                    <th
                                        onClick={() => handleSort("LATE_TIME")}
                                        className="cursor-pointer px-6 py-3 text-left text-sm   text-gray-500 uppercase tracking-wider"
                                    >
                                        <div className=" flex flex-row space-x-2 items-center">
                                            LATE TIME{" "}
                                            {sortDirection === "asc" ? (
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth={1.5}
                                                    stroke="currentColor"
                                                    className="w-6 h-6"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M19.5 13.5L12 21m0 0l-7.5-7.5M12 21V3"
                                                    />
                                                </svg>
                                            ) : (
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth={1.5}
                                                    stroke="currentColor"
                                                    className="w-6 h-6"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M4.5 10.5L12 3m0 0l7.5 7.5M12 3v18"
                                                    />
                                                </svg>
                                            )}
                                            <button
                                                onClick={() =>
                                                    toggleColumnVisibility(
                                                        "LATE TIME"
                                                    )
                                                }
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth={1.5}
                                                    stroke="currentColor"
                                                    className="w-4 h-4"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                    </th>
                                ) : null}
                                {isEmpStatushow ? (
                                    <th className="  px-6 py-3 text-left text-sm   text-gray-500 uppercase tracking-wider">
                                        <div className="w-full flex flex-col items-start  space-y-1">
                                            <p>EMPLOYEE STATUS</p>
                                            <select
                                                placeholder="Status"
                                                className="h-8 w-full bg-white rounded-md focus:outline-none "
                                                value={selectedStatus}
                                                onChange={handleStatusChange}
                                            >
                                                <option value="all">All</option>
                                                <option value="leave">
                                                    Leave
                                                </option>
                                                <option value="official">
                                                    Official-MO
                                                </option>
                                                <option value="personal">
                                                    Personal-MO
                                                </option>
                                                <option value="present">
                                                    Present
                                                </option>
                                                <option value="late">
                                                    Late
                                                </option>
                                            </select>
                                            <button
                                                onClick={() =>
                                                    toggleColumnVisibility(
                                                        "EMPLOYEE STATUS"
                                                    )
                                                }
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth={1.5}
                                                    stroke="currentColor"
                                                    className="w-4 h-4"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                    </th>
                                ) : null}
                                {isRemarksMoveOrderShow ? (
                                    <th className="px-6 py-3 text-left text-sm text-gray-500 uppercase tracking-wider">
                                        <div className="w-full flex flex-row items-center space-x-1">
                                            <p>REMARKS MOVE ORDER</p>
                                            <button
                                                onClick={() =>
                                                    toggleColumnVisibility(
                                                        "REMARKS MOVE ORDER"
                                                    )
                                                }
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth={1.5}
                                                    stroke="currentColor"
                                                    className="w-4 h-4"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                    </th>
                                ) : null}

                                {isApplicationStatushow ? (
                                    <th className="px-6 py-3 text-left text-sm   text-gray-500 uppercase tracking-wider">
                                        <div className="w-full flex flex-col  items-start  space-y-1">
                                            <p>APPLICATION STATUS</p>
                                            <select
                                                placeholder="Status"
                                                className="h-8 w-36 bg-white rounded-md focus:outline-none "
                                                value={applicationStatus}
                                                onChange={
                                                    handleAplicationStatusChange
                                                }
                                            >
                                                <option value="all">All</option>
                                                <option value="in process">
                                                    IN PROCESS
                                                </option>
                                                <option value="approved">
                                                    APPROVED
                                                </option>
                                                <option value="rejected">
                                                    REJECTED
                                                </option>
                                            </select>
                                            <button
                                                onClick={() =>
                                                    toggleColumnVisibility(
                                                        "APPLICATION STATUS"
                                                    )
                                                }
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth={1.5}
                                                    stroke="currentColor"
                                                    className="w-4 h-4"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                    </th>
                                ) : null}

                                {isFrontDeskRemarksShow ? (
                                    <th className="px-6 py-3 text-left text-sm text-gray-500 uppercase tracking-wider">
                                        <div className="w-full flex flex-row items-center space-x-1">
                                            <p>FRONT DESK REMARKS</p>
                                            <button
                                                onClick={() =>
                                                    toggleColumnVisibility(
                                                        "FRONT DESK REMARKS"
                                                    )
                                                }
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth={1.5}
                                                    stroke="currentColor"
                                                    className="w-4 h-4"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                    </th>
                                ) : null}

                                <td className="px-6 py-3 text-left text-sm  font-bold  text-gray-500 uppercase tracking-wider">
                                    Edit
                                </td>
                            </tr>
                        </thead>
                        <tbody className="  divide-y divide-borderColor">
                            {attReportList.map((e, index) => {
                                return (
                                    <tr
                                        key={index}
                                        className={`${
                                            e.LATE_TIME
                                                ? "bg-[#EC5555] text-white"
                                                : "bg-white"
                                        }`}
                                    >
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            {
                                                <div className=" flex flex-col items-center w-full">
                                                    <p>{page + index + 1}</p>
                                                </div>
                                            }
                                        </td>
                                        {isDateShow ? (
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                {e.CREATION_DATE
                                                    ? moment(
                                                          e.CREATION_DATE
                                                      ).format("YYYY-MM-DD")
                                                    : "N/A"}
                                            </td>
                                        ) : null}
                                        {isCompanyNameShow ? (
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                {e.COMPANY_NAME
                                                    ? e.COMPANY_NAME
                                                    : "N/A"}
                                            </td>
                                        ) : null}
                                        {/* Render the rest of the columns */}
                                        {isPlantNameShow ? (
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                {e.PLANT_NAME
                                                    ? e.PLANT_NAME
                                                    : "N/A"}
                                            </td>
                                        ) : null}
                                        {isEmpIdShow ? (
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                {e.EMPLOYEE_ID
                                                    ? e.EMPLOYEE_ID
                                                    : "N/A"}
                                            </td>
                                        ) : null}
                                        {isEmpNameShow ? (
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                {e.EMPLOYEE_NAME
                                                    ? e.EMPLOYEE_NAME
                                                    : "N/A"}
                                            </td>
                                        ) : null}
                                        {isDesignationShow ? (
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                {e.POSITION_NAME
                                                    ? e.POSITION_NAME
                                                    : "N/A"}
                                            </td>
                                        ) : null}
                                        {isInTimehow ? (
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                {e.LOG_IN ? e.LOG_IN : "N/A"}
                                            </td>
                                        ) : null}
                                        {isOutTimehow ? (
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                {e.LOG_OUT ? e.LOG_OUT : "N/A"}
                                            </td>
                                        ) : null}
                                        {isLateTimehow ? (
                                            <td
                                                className={`px-6 py-4 whitespace-nowrap `}
                                            >
                                                {e.LATE_TIME
                                                    ? e.LATE_TIME
                                                    : "N/A"}
                                            </td>
                                        ) : null}
                                        {isEmpStatushow ? (
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                {e.OBJECT_SUB_TYPE_CODE
                                                    ? addText(
                                                          e.OBJECT_SUB_TYPE_CODE
                                                      )
                                                    : "N/A"}
                                            </td>
                                        ) : null}
                                        {isRemarksMoveOrderShow ? (
                                            <td className="px-6 py-4 ">
                                                {
                                                    <div className=" w-48">
                                                        {e.PURPOSE
                                                            ? e.PURPOSE
                                                            : "N/A"}
                                                    </div>
                                                }
                                            </td>
                                        ) : null}
                                        {isApplicationStatushow ? (
                                            <td className="px-6 py-4 ">
                                                {
                                                    <div className=" w-48">
                                                        {e.AUTHORIZATION_STATUS
                                                            ? e.AUTHORIZATION_STATUS
                                                            : "N/A"}
                                                    </div>
                                                }
                                            </td>
                                        ) : null}
                                        {isFrontDeskRemarksShow ? (
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                {e.FRONT_DESK_REMARKS
                                                    ? e.FRONT_DESK_REMARKS
                                                    : "N/A"}
                                            </td>
                                        ) : null}
                                        <td className="px-6 py-3 text-left text-sm   text-gray-500 uppercase tracking-wider">
                                            <button
                                                onClick={() => {
                                                    setOpenClose2(true);
                                                    setModule(e.MODULE);
                                                    setRowId(e.ATDN_ROW_ID);
                                                    if (e.FRONT_DESK_REMARKS) {
                                                        setRemarks(
                                                            e.FRONT_DESK_REMARKS
                                                        );
                                                    }
                                                }}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth={1.5}
                                                    stroke="currentColor"
                                                    className={`w-6 h-6 ${
                                                        e.LATE_TIME
                                                            ? "text-white"
                                                            : "text-gray-500"
                                                    }`}
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                                                    />
                                                </svg>
                                            </button>
                                        </td>

                                        {/* Render the rest of the columns */}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            )}

            <div className=" h-10"></div>
            {/* pagination */}
            {startDate === "" ? null : (
                <div className=" flex flex-row space-x-4 justify-center  items-end mt-4 mb-3 lg:w-full md:w-3/4 sm:w-96  pr-6">
                    <button
                        disabled={page === 0 ? true : false}
                        onClick={() => {
                            previous();
                        }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className={`w-6 h-6  font-bold  ${
                                page === 0 ? "text-white" : `text-${gray600}`
                            } font-bold`}
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15.75 19.5L8.25 12l7.5-7.5"
                            />
                        </svg>
                    </button>
                    <p className={`text-${gray500} text-[18px] font-bold`}>
                        {pageCount} Of {totalPage}
                    </p>

                    <button
                        disabled={
                            attReportList.length === 0 ||
                            pageCount === totalPage
                                ? true
                                : false
                        }
                        onClick={() => {
                            next();
                        }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className={`w-6 h-6  font-bold  ${
                                attReportList.length === 0 ||
                                pageCount === totalPage
                                    ? "text-white"
                                    : `text-${gray600}`
                            } font-bold`}
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M8.25 4.5l7.5 7.5-7.5 7.5"
                            />
                        </svg>
                    </button>
                </div>
            )}
            {/* pagination */}

            {/* modal filter */}
            <input
                type="checkbox"
                id="my-modal-11"
                className="modal-toggle"
                checked={openClose}
            />
            <div className="modal">
                <div className="modal-box h-screen max-w-4xl bg-white">
                    <div className="w-full flex flex-row justify-end items-end">
                        <button
                            onClick={() => {
                                setOpenClose(false);
                            }}
                            className=" w-8 h-8 rounded-full bg-red-500 flex justify-center items-center"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6 text-white"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                            </svg>
                        </button>
                    </div>
                    <div className="h-4"></div>
                    <div className="w-full">
                        <Datepicker
                            // useRange={moveTypeCode==='11'?true:false}
                            // asSingle={moveTypeCode!=='11'?true:false}
                            popoverDirection="down"
                            placeholder="Start Date - End Date         "
                            showFooter={true}
                            primaryColor={"green"}
                            showShortcuts={true}
                            value={startEndDate}
                            onChange={handleValueChange}
                            displayFormat={"DD/MM/YYYY"}
                            toggleClassName={`absolute bg-buttonColor rounded-r-lg text-white right-0 h-full px-3 text-${gray400} focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed`}
                            // inputClassName="w-full rounded-md focus:ring-0 font-normal bg-green-100 dark:bg-green-900 dark:placeholder:text-green-100"
                            // containerClassName=" border border-[1px] border-gray-400 w-80 rounded-md"
                            // toggleClassName=" text-gray-400"
                            inputClassName={`outline-buttonColor w-full rounded-r-lg h-12 border-[1px] border-gray-300 rounded-md px-3 bg-white`}
                            // containerClassName="  "
                        />
                    </div>
                    {filterError.startEndDate && (
                        <span className={`text-red-500`}>
                            {filterError.startEndDate}
                        </span>
                    )}
                    <div className="h-4"></div>

                    <select
                        placeholder="Select Company"
                        value={companyCode}
                        onChange={(e) => {
                            selectedCompanyCode = e.target.value;
                            setCompanyCode(selectedCompanyCode);
                            getPlant(selectedCompanyCode);
                        }}
                        className={`select select-bordered bg-white w-full  focus:outline-none text-${gray500}  `}
                    >
                        <option value="" disabled selected>
                            Select Company
                        </option>
                        {company.map((e, index) => (
                            <option key={index} value={e.COMPANY_CODE}>
                                {e.COMPANY_NAME}({e.COMPANY_CODE})
                            </option>
                        ))}
                    </select>
                    {filterError.companyCode && (
                        <span className={`text-red-500`}>
                            {filterError.companyCode}
                        </span>
                    )}

                    <div className="h-4"></div>

                    {isPlantLoading ? (
                        <p></p>
                    ) : (
                        <select
                            value={plantCode}
                            onChange={(e) => {
                                selectedPalntCode = e.target.value;
                                setPlantCode(selectedPalntCode);
                            }}
                            className={`select select-bordered w-full bg-white  focus:outline-none text-${gray500}  `}
                        >
                            <option value="" disabled selected>
                                Select Plant
                            </option>
                            {isPlantLoading ? (
                                <p>loading.....</p>
                            ) : (
                                plant.map((e, index) => (
                                    <option
                                        className=" text-black"
                                        value={e.PLANT_CODE}
                                        key={index}
                                    >
                                        {e.PLANT_NAME}({e.PLANT_CODE})
                                    </option>
                                ))
                            )}
                        </select>
                    )}
                    {filterError.plantCode && (
                        <span className={`text-red-500`}>
                            {filterError.plantCode}
                        </span>
                    )}

                    <div className="h-4"></div>

                    <div className=" w-full flex flex-row space-x-4 items-start">
                        <div className=" flex flex-col items-start w-full">
                            <input
                                min={1}
                                ref={fromIdRef}
                                type="number"
                                onChange={(e) => {
                                    setFromId(e.target.value);
                                }}
                                placeholder="From Id"
                                className=" w-full h-11 focus:outline-none bg-white px-2 rounded-md border-[0.2px] border-gray-300"
                            />
                            {filterError.fromId && (
                                <span className={`text-red-500`}>
                                    {filterError.fromId}
                                </span>
                            )}
                        </div>
                        <div className=" flex flex-col items-start w-full">
                            <input
                                placeholder="To Id"
                                min={1}
                                ref={toIdRef}
                                type="number"
                                onChange={(e) => {
                                    setToId(e.target.value);
                                }}
                                className=" w-full h-11 bg-white focus:outline-none px-2 rounded-md border-[0.2px] border-gray-300"
                            />
                            {/* {filterError.toId && <span className={`text-red-500`}>{filterError.toId}</span>} */}
                        </div>
                    </div>

                    {/* <div className='h-5'></div>
                        <InputLebel text="Taka *" />
                <div className='h-1'></div> */}

                    {/* {itemError.itemPrice && <span className={`text-red-500`}>{itemError.itemPrice}</span>} */}
                    <div className="h-32 "></div>
                    <button
                        onClick={() => {
                            submit(page);
                            allSubmit();
                        }}
                        className=" w-full h-10 rounded-full bg-buttonColor text-white flex justify-center items-center"
                    >
                        SUBMIT
                    </button>
                </div>
            </div>

            <input
                type="checkbox"
                id="my-modal-11"
                className="modal-toggle"
                checked={openClose2}
            />
            <div className="modal">
                <div className="modal-box bg-white">
                    <label
                        htmlFor="my-modal-11"
                        onClick={(e) => {
                            setOpenClose2(false);
                        }}
                        className="btn btn-sm btn-circle absolute right-2 top-2"
                    >
                        ✕
                    </label>

                    <div className=" w-full h-52 flex  bg-white flex-col items-start space-y-2">
                        <div className="h-4"></div>
                        <div className=" w-full flex flex-col items-start">
                            <p className="text-sm font-medium text-black">
                                Add Remakrs
                            </p>
                            <div className="h-2"></div>
                            <textarea
                                value={remarks}
                                onChange={handleRemarksChange}
                                className=" px-2 py-1 w-full bg-white h-32 border-[1px] border-gray-400 rounded-md focus:outline-none"
                            ></textarea>
                            <p>{remarks.length}/100</p>
                        </div>

                        <div className=" w-full flex flex-row items-center justify-between">
                            <button
                                className=" py-1 px-4 bg-red-500 rounded-lg text-white font-semibold"
                                onClick={() => {
                                    setOpenClose2(false);
                                    clearVal();
                                }}
                            >
                                CANCEL
                            </button>
                            <button
                                className=" py-1 px-4 bg-green-600 rounded-lg text-white font-semibold"
                                onClick={() => {
                                    AddRemarks();
                                }}
                            >
                                SUBMIT
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
