import { baseUrl } from "../../utils/path";


  

  const MyRequisitionService= async(empId, status)=>{
   
   
    const url=`${baseUrl}my_requisition`;
  
    const response = await   fetch(url,
        {
            method: "POST",
            headers: {
              
              "Content-Type": "application/json",
              'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
            },
            body: JSON.stringify({
               
                   "STATUS": status,
                   "EMPLOYEE_ID":empId
                 
            }),
          }
        );
     
          const data = await response.json();
        
          return data;

     
}
export default MyRequisitionService
