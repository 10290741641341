

  

  const getTermList= async(url,amId,id)=>{

    console.log(`am: ${amId}, user id: ${id}`);
    
    const response = await   fetch(url,
        {
            method: "POST",
            headers: {
              
              "Content-Type": "application/json",
              'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
            },
            body: JSON.stringify({
                "AM_ID": amId,
                "Z_DATA":id
            }),
          }
        );
        // if (!response.ok) {
        //     throw new Error("fetching is not successful");
        //   }
          const data = await response.json();
          console.log(`term: ${data}`);
          return data;

     
}
export default getTermList
