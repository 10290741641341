


  export  const gray500='gray-500';
  export  const gray600='gray-500';
  export  const gray100='gray-100';
  export  const gray200='gray-200';
  export  const gray300='gray-300';
  export  const gray400='gray-400';
  export  const gray700='gray-700';
  export  const gray800='gray-800';
  export  const red500='red-500';
  export  const green100='green-100';
  export  const green600='green-600';


