
import './App.css';
import LoginPage from './components/auth/LoginPage';
import HomePage from './components/home_component/HomePage';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import PrivateRoutes from './utils/PrivateRoutes'
import { useState,useEffect } from 'react';
import AddLatLong from './components/add_lat_long/AddLatLong';
import AddOfficeTimePage from './components/add_office_time/AddOfficeTimePage';
import {  useHomePageContext } from './components/home_component/HomePageContext';
import MyRequisitionDetailsPage from './requisition/component/MyRequisitionDetailsPage';
import MyRequisitionPage from './requisition/component/MyRequisitionPage';


// ... (your imports)

function App() {
  const [auth, setAuth] = useState({ token: false });
  const { menu,setMenu } = useHomePageContext();
  const [loading, setLoading] = useState(true);
  const [forceRender, setForceRender] = useState(false);
  // Callback function to update auth.token
  const setToken = (value) => {
    setAuth({ token: value });
  };
  useEffect(() => {
    setLoading(false); // Set loading to false once the component mounts
  }, []);

  // useEffect(() => {
  //   const storedMenuCode = localStorage.getItem("menuCode");
  //   const intValue = parseInt(storedMenuCode, 10);
  //   console.log(`menuCode in app: ${storedMenuCode}`);
  //   if (intValue) {
  //     setMenu(intValue, () => {
  //       console.log(`menu after setting: ${menu}`);
  //     });
  //   } else {
  //     setMenu(3000, () => {
  //       console.log(`menu after setting: ${menu}`);
  //     });
  //   }
  // }, []);
  
  
  

  if (loading) {
    // You can render a loading indicator here
    return (
      <div className='w-full h-screen flex justify-center items-center'>
       <p></p>
      </div>
    );
  }

  return (
  
      <Router>
        <Routes>
          <Route exact path="/" element={<LoginPage setToken={setToken} />} />
          <Route element={<PrivateRoutes auth={auth} />}>
            <Route path="/home/:mId?" element={<HomePage />} />
            <Route path="/add-fence" element={<AddLatLong />} />
            <Route path="/add-time" element={<AddOfficeTimePage />} />
            <Route path="/my-requisition" element={<MyRequisitionPage />} />
          </Route>
        </Routes>
      </Router>
  
  );
}

export default App;




// function App() {

//   const [auth, setAuth] = useState({ token: false });
//   const { setMenu } = useHomePageContext();

//   // Callback function to update auth.token
//   const setToken = (value) => {
//     setAuth({ token: value });
//   };
//   useEffect(()=>{
//     const menu = localStorage.getItem("menuCode");
//     if(menu){
//       setMenu(menu);
//     }
//   },[]);

//   return (
   
//     <HomePageProvider>
//     <Router>
//       <Routes>
//         <Route exact path="/" element={<LoginPage setToken={setToken}/>}/>
       
//         <Route element={<PrivateRoutes auth={auth} />}>
      
//        <Route  path="/home" element={<HomePage/>}/>
      
//         <Route  path="/add-fence" element={<AddLatLong/>}/>
//         <Route  path="/add-time" element={<AddOfficeTimePage/>}/>
//         <Route  path="/my-requisition" element={<MyRequisitionPage/>}/>

//             </Route>
      
//       </Routes>
//     </Router>
//     </HomePageProvider>
    
   
//   );
// }

// export default App;
