import { baseUrl } from "../../utils/path";

const syncUrl = `${baseUrl}position_sync`;

const SyncPositionService = async (userId, positionName, positionId) => {

  console.log(`user:${userId} name:${positionName} id: ${positionId}`);

  const response = await fetch(syncUrl,
      {
          method: "POST",
          headers: {

              "Content-Type": "application/json",
              Authorization: 'Basic ' + btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
          },
          body: JSON.stringify({
              Z_DATA: userId,
              POSITION_NAME: positionName,
              POSITION_ID: positionId,
              
          }),
      }
  );
  // if (!response.ok) {
  //     throw new Error("fetching is not successful");
  //   }
  const data = await response.json();
  console.log("info:", data);
  return data;


}
export default SyncPositionService
