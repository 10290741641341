import { baseUrl } from "../../utils/path";




  const UserActiveDeactiveService= async(empId,status)=>{
    console.log(`emp id: ${empId}`);
    console.log(`emp status: ${status}`);
    const url=`${baseUrl}user_activation`;
  
    const response = await   fetch(url,
        {
            method: "POST",
            headers: {
              
              "Content-Type": "application/json",
              'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
            },
            body: JSON.stringify({
               
                "EMPLOYEE_ID":empId,
                "STATUS":status
            }),
          }
        );
     console.log(response);
          const data = await response.json();
        console.log(data);
          return data;
     
}
export default UserActiveDeactiveService