import React, { createContext, useState, useContext } from 'react';

const Iou = createContext();

export const IouProvider = ({ children }) => {
  const [iouPageNo, setIouPageNo] = useState(1);
  const [details,setDetails]=useState({});
  const [isDelete,setIsDelete]=useState(false);
  const [isPopUp,setIsPopUp]=useState(false);
  const [deleteRowId, setDeleteRowId] = useState(null);

  return (
    <Iou.Provider value={{ iouPageNo, setIouPageNo,details,setDetails,isDelete,setIsDelete,isPopUp,setIsPopUp, deleteRowId, setDeleteRowId }}>
      {children}  
    </Iou.Provider>
  );
};

export const useIouContext = () => {
  return useContext(Iou);
};
