import { useEffect, useState } from "react";
import { useLeaveContext } from "../context/LeaveContext";
import { baseUrl } from "../../utils/path";
import AdminInfoService from "../../services/AdminInfoService";
import ApproverHierarchyListService from "../../move_order/service/ApproverHierarchyListService";

const infoUrl = `${baseUrl}my_info`;


export default function LeaveDetailsPopup() {

  const [isOpen, setIsOpen] = useState(false);
  const [employeeId, setEmployeeId] = useState('');
  const [designation, setDesignation] = useState('');
  const [empId, setEmpId] = useState('');
  const [leaveDetails, setLeaveDetails] = useState([]);
  const [joiningDate, setJoiningDate] = useState('');
  const [actionCode, setActionCode] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [approverList, setApproverList] = useState([]);

  const {isDelete, setIsDelete, details, setDetails, isPopUp, setIsPopUp, deleteRowId, setDeleteRowId } = useLeaveContext();


  const handleIsDelete=(id)=>{
    setIsDelete(true);
    setDeleteRowId(id)
  }
  const statusBackgroundColor = getStatusBackgroundColor(details.AUTHORIZATION_STATUS);
  
  useEffect(() => {
    // When the component mounts, set isOpen to true to trigger the opening transition
    setIsOpen(true);
  }, []);

  useEffect(() => {
    console.log(details.MO_ID);
    const eid = localStorage.getItem('empId');
    setEmployeeId(eid);
    console.log("employee: ", eid);
    const id = localStorage.getItem('userId');
    getAdminInfo(id);
    // getItem();
    getHierarchy(details.EMPLOYEE_ID, "LEAVE", details.LEAVE_APPL_ID);
  }, []);

  const getAdminInfo = async (uid) => {
    const admin = await AdminInfoService(infoUrl, uid);
    // setAdminDetails(admin.EMP_DETAILS);
    const eId = admin.EMP_DETAILS[0].EMPLOYEE_ID;

    const designation = admin.EMP_DETAILS[0].DESIGNATION;
    const joiningDate = admin.EMP_DETAILS[0].JOINING_DATE;
    setEmpId(eId);
    setDesignation(designation);
    setJoiningDate(joiningDate);
  }

  const getHierarchy = async (id, module, object) => {
    setIsLoading(true);
    const re = await ApproverHierarchyListService(id, module, object);
    const sortedData = re.APPROVER_LIST.sort((a, b) => a.STAGE_SEQ - b.STAGE_SEQ);
    setApproverList(sortedData);
    setActionCode(sortedData[0].ACTION_CODE)
    // console.log("hierarchy move order: ", approverList[0].ACTION_CODE);
    setIsLoading(false);
  }

  // const getItem = async () => {
  //   const re = await IouItemDetailsService(details.IOU_ID);
  //   console.log("table details: ", re);
  //   setIouItemDetails(re.iou_items);
  // }

  const handleClose = () => {
    // When closing, set isOpen to false to trigger the closing transition
    setIsOpen(false);
    setDetails({});
    setIsDelete(false);

    // Wait for the closing transition to complete before actually closing
    setTimeout(() => {
      setIsPopUp(false);
    }, 200); // Adjust the timeout to match your transition duration
  };

  // Define background color based on status
  function getStatusBackgroundColor(status) {
    switch (status) {
      case "IN PROCESS":
        return 'bg-[#FDCA4D] text-black border-black';
      case "APPROVED":
        return 'bg-[#2D833F] text-white';
      case "REJECTED":
        return 'bg-[#E20000] text-white';
      default:
        return 'bg-transparent'; // Default bg color
    }
  }

  const formatDate = (dateString) => {
    const [day, month, year] = dateString.split('-');
    const formattedDate = new Date(`${year}-${month}-${day}`);
    const options = { month: 'short', day: 'numeric', year: 'numeric' };
    return formattedDate.toLocaleDateString('en-US', options);
  };


  return (
    <div className={`my-14 fixed top-0 right-0 w-3/12 h-full bg-white border-l border-gray-300 pb-4 pt-2 transition-transform ${
      isOpen ? 'translate-x-0' : 'translate-x-full'
    }`}>
      <div className="max-h-full no-scrollbar overflow-y-auto transition-transform ease-in-out duration-700">

        {/* Add a button or an element to close the popup */}
        <div className={`px-3 py-4 flex flex-row items-center justify-between space-x-3 ${statusBackgroundColor}`}>
          <div className='flex items-center justify-between space-x-2 w-full'>
            <div className='flex items-center space-x-2'>
              <button className={`py-2 rounded-md border-[0.5px] hover:border-gray-400 px-3`} onClick={handleClose}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
                </svg>
              </button>
  
              <p className={`font-semibold `}>Status: {details.AUTHORIZATION_STATUS}</p>
            </div>
  
            <div className='flex items-center space-x-2'>
                
  
              <div className="">
                { 
                  details.AUTHORIZATION_STATUS === "IN PROCESS" && actionCode === 3 ?
                  <button onClick={() => handleIsDelete(details.LEAVE_APPL_ID)} className='py-2 px-2 rounded-md text-[16px] font-medium text-[#EC5555]'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                    </svg>
                  </button>
                  :
                  <div></div>
                }
              </div>
            </div>
          </div>
        </div>

        {/* Render details data here */}
        <div className='space-y-3'>
          <div>
            <p className='bg-[#E6E1DD] px-3 py-2 font-semibold'>Employee Details</p>

            <p className='px-3 border-b-[0.5px] pb-2 flex justify-start'>
              <span className="w-[150px]">Applicant Name:</span>
              <span>{details.EMPLOYEE_NAME == null ? "N/A" : details.EMPLOYEE_NAME}</span>
            </p>
            <p className='px-3 border-b-[0.5px] pb-2 flex justify-start'>
              <span className="w-[150px]">Designation:</span>
              <span>{designation}</span>
            </p>
          </div>

          <div>
            <p className='bg-[#E6E1DD] px-3 py-2 font-semibold'>LEAVE APPLY DETAILS</p>
  
            <p className='px-3 border-b-[0.5px] pb-2 flex justify-start'>
              <span className="w-[150px]">Leave Type:</span>
              <span>{details.LEAVE_TYPE == null ? "N/A" : details.LEAVE_TYPE}</span>
            </p>
            <p className='px-3 border-b-[0.5px] pb-2 flex justify-start'>
              <span className="w-[150px]">Application Date:</span>
              <span>{details.APPLICATION_DATE == null ? "N/A" : details.APPLICATION_DATE}</span>
            </p>
            <p className='px-3 border-b-[0.5px] pb-2 flex justify-start'>
              <span className="w-[150px]">Task Assigner Name:</span>
              <span>{details.ASSIGNER_NAME == null ? "N/A" : details.ASSIGNER_NAME}</span>
            </p>
            <p className='px-3 border-b-[0.5px] pb-2 flex justify-start'>
              <span className="w-[150px]">Task Assigner Id:</span>
              <span>{details.TASK_ASSIGNER_ID == null ? "N/A" : details.TASK_ASSIGNER_ID}</span>
            </p>
            <p className='px-3 border-b-[0.5px] pb-2 flex justify-start'>
              <span className="w-[150px]">Task Assigner Designation:</span>
              <span>{details.ASSIGNER_DESIGNATION == null ? "N/A" : details.ASSIGNER_DESIGNATION}</span>
            </p>
            <p className='px-3 border-b-[0.5px] pb-2 flex justify-start'>
              <span className="w-[150px]">Task Assigner Status:</span>
              <span>{details.IS_TASK_ASSIGNER_ACCEPTED === 1 ? "Accepted" : details.IS_TASK_ASSIGNER_ACCEPTED === 3 ? "Not Accepted Yet" : "Rejected"}</span>
            </p>
            <p className='px-3 border-b-[0.5px] pb-2 flex justify-start'>
              <span className="w-[150px]">Accept/Reject Notes:</span>
              <span>{details.TASK_ASSIGNER_NOTES == null ? "N/A" : details.TASK_ASSIGNER_NOTES}</span>
            </p>
            <p className='px-3 border-b-[0.5px] pb-2 flex justify-start'>
              <span className="w-[150px]">From Date:</span> 
              <span>{details.DATE_FROM == null ? "N/A" : formatDate(details.DATE_FROM)}</span>
            </p>
            <p className='px-3 border-b-[0.5px] pb-2 flex justify-start'>
              <span className="w-[150px]">To Date:</span>
              <span>{details.DATE_TO == null ? "N/A" : formatDate(details.DATE_TO)}</span>
            </p>
            <p className='px-3 border-b-[0.5px] pb-2 flex justify-start'>
              <span className="w-[150px]">Requested Days:</span>
              <span>{details.LEAVE_DAYS == null ? "N/A" : details.LEAVE_DAYS}</span>
            </p>
            <p className='px-3 border-b-[0.5px] pb-2 flex justify-start'>
              <span className="w-[150px]">Status:</span>
              <span>{details.AUTHORIZATION_STATUS === "IN PROCESS" ? "Waiting for approval" : details.AUTHORIZATION_STATUS === "APPROVED" ? "APPROVED" : "REJECTED"}</span>
            </p>
            <p className='px-3 border-b-[0.5px] pb-2 flex justify-start'>
              <span className="w-[150px]">Note:</span>
              <span>{details.NOTE == null ? "N/A" : details.NOTE}</span>
            </p>
          </div>


          <div className=''>
            <p className='bg-[#E6E1DD] px-3 py-2 font-semibold'>Approve/Reject history</p>
              
            {/* <div className="h-3"></div> */}

            <div className='px-3 w-5/6'>
              {
                approverList.map((e, i) => (
                  <div className='flex flex-col items-start space-y-4' key={i}>
                      
                    <div className="h-[2px]"></div>
                      
                    <div className='flex flex-row items-center space-x-6 w-96 '>
                      <div className={`flex justify-center items-center`}>
                        {
                          e.ACTION_CODE === 1
                          ?
                            <div className="w-6 h-6 p-[2px] rounded-full border-[2px] border-green-500">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 text-green-500">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                              </svg>
                            </div>
                          :
                          e.ACTION_CODE === 0 ?
                            <div className="w-6 h-6 p-[2px] rounded-full border-[2px] border-red-500">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 text-red-500">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                              </svg>
                            </div>
                            
  
                          :
                          <div className="w-6 h-6 p-[2px] rounded-full border-[2px] border-red-500">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 text-red-500">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M8.625 12a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                            </svg>
                          </div>
                          

                        }
                      </div>
  
                      <div className=' flex flex-col items-start space-y-1'>
                        <p className='  font-medium text-black w-5/6'>{e.EMPLOYEE_NAME} <span className="text-[#7F7F7F]">({e.AUTHORIZER_TYPE})</span></p>
                          <p> {
                            e.ACTION_CODE === 1 ?
                            <div className=' flex flex-col items-start space-y-1'>
                              <p className=' font-medium text-green-500'>
                                {
                                  e.AUTHORIZER_TYPE === "RECOMMENDER" ? "RECOMMENDED" : "APPROVED"
                                }
                              </p>
                                
                              <p className=' font-medium text-gray-500'>
                                {
                                  e.NOTE === null ? "N/A" : e.NOTE
                                }
                              </p>
                            </div>
  
                            : e.ACTION_CODE === 3 ?
                            <p className=' text-orange-500 font-medium'>
                              PENDING
                            </p>
                            :
                            <div className=' flex flex-col items-start space-y-1'>
                              <p className=' text-red-500 font-medium' >
                                {
                                  e.AUTHORIZER_TYPE === "RECOMMENDER" ? "NOT RECOMMENDED" : "NOT APPROVED" 
                                }
                              </p>
                                
                              <p className=' text-gray-500 font-medium' >
                                {
                                  e.NOTE === null ? "N/A" : e.NOTE
                                }
                              </p>
                            </div>
                          }
                          </p>
                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
            <div className="h-32"></div>
        </div>
      </div>
    </div>
  )
}