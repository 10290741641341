import React from 'react'
import Papa from 'papaparse'
import { useState } from 'react'
import UploadHolidayService from '../service/HolidayUploadService';


export default function HolidayUploadPage() {

    const [data,setData]=useState([]);
    const [columnArray,setColumnArray]=useState([]);
    const [values,setValues]=useState([]);
    const [loop,setLoop]=useState(1);
    const [openClose,setOpenClose]=useState(false);
    var i=0;
    const [selectedFile, setSelectedFile] = useState(null);

    const upload=async()=>{
        setOpenClose(true);
       
        const list=[];
       
        for (const e of values) {
            for (const v of e) {
               // Pass 'v' to the function
              // Use the 'result' here if needed
              list.push(v)
             
            }
            const result = await UploadHolidayService(list[0],list[1],list[2],list[3],list[4],list[5],list[6],list[7],);
            console.log("result: ", result)
            list.splice(0, list.length);
            setLoop(e+1);
          }
        
        setOpenClose(false);
        setLoop(0);
    }

    const handleFile=(event)=>{
        const file = event.target.files[0];
        Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: function(result){
                const columnArray=[];
                const valuesArray=[];
                result.data.map((d)=>{
                    columnArray.push(Object.keys(d));
                    valuesArray.push(Object.values(d));
                });
                setData(result.data);
                setColumnArray(columnArray[0]);
                setValues(valuesArray);

                // Set selectedFile to the uploaded file
                setSelectedFile(file);

            }
        })
    }
    return (
        <div className='mx-8 my-16'>
            <div className=' h-6'></div>
            <h1 className=' text-lg font-medium text-black'>Upload Holiday Excel FIle</h1>
            <div className=' h-6'></div>
            
            {/* <input 
                type="file" 
                accept='.csv' 
                onChange={handleFile} 
                className="file-input file-input-bordered w-full max-w-xs" 
            /> */}

            <label className={`flex-row shadow justify-between cursor-pointer items-center flex space-x-2 h-12 border-[1px] border-gray-300 rounded-l-lg pr-2`} style={{ display: 'inline-block' }}>
                <div className='flex flex-row space-x-2 items-center'>
                    <div className={`bg-buttonColor px-4 h-12 items-center flex rounded-l-lg w-32 text-white text-lg font-medium`}>
                        Choose File
                    </div>
                            
                    <input
                        type="file"
                        accept=".csv"
                        className="hidden"
                        onChange={handleFile}
                    />
                    
                    <span className={`text-[#333439] text-lg items-center flex bg-white px-4`}>{selectedFile ? selectedFile.name : 'No File Selected'}</span>
                </div>
            </label>

            <div className=' h-6'></div>
            {
                 values.length === 0 ?


                 <div className=' flex justify-center items-center w-full h-[300px]'>

                     <h1 className=' text-black font-bold text-2xl'>Please Choose File</h1>

                 </div>

                 :
                <table className=" border-[0.5px] border-gray-200 ">
                    <thead className="bg-bgTableHeader text-tableHeaderText shadow-sm h-14">
                        <tr>
                            {
                                columnArray.map((col,i)=>(
                                    <th key={i} className="px-6 py-3 text-left text-sm uppercase tracking-wider">{col}</th>
                                ))
                            }
                       
                            
                           
                     
                           
                            
                            
                        </tr>
                    </thead>
                    <tbody className=" divide-y divide-borderColor">
                   { 
                 
                  
                  
                  values.map((e,index)=>{
                  
                    return(
                        <tr key={index}  className='bg-white'>

                            {
                               e.map((v,i)=>(
                                <td key={i} className="px-6 py-4 whitespace-nowrap">{v}</td>
                               ))
                            }
                   
                                    
                                    
                                  
                                   
                                </tr>
                    )
                  })
                                
                                }
                           

                    </tbody>
                  
                </table>
                }
                <div className=' h-6'></div>
                {
                    values.length===0?null:
                    <button onClick={upload} className=' w-36 h-9 flex justify-center items-center rounded-md bg-buttonColor text-sm text-white font-semibold'>UPLOAD</button>
                }
                <div className=' h-20'></div>
                <input type="checkbox" id="my-modal-11" className="modal-toggle" checked={openClose} />
<div className="modal"> 
  <div className="modal-box">
  {/* <label htmlFor="my-modal-11" onClick={(e)=>{setOpenCLose(false); setPicUrl('')}} className="btn btn-sm btn-circle absolute right-2 top-2">✕</label> */}
   
    
       <div className=' w-full h-72 flex items-center justify-center bg-white'>
        <h1 className=' text-green-500 font-bold text-xl'>{`Inserting ${loop} of ${values.length}`}</h1>
       </div>

    
  
    
  </div>
</div>
        </div>
    )
}
