import React, { createContext, useState, useContext } from 'react';

const Leave = createContext();

export const LeaveProvider = ({ children }) => {
  const [leavePageNo, setLeavePageNo] = useState(1);
  const [details, setDetails] = useState({});
  const [isDelete, setIsDelete] = useState(false);
  const [isPopUp, setIsPopUp] = useState(false);
  const [deleteRowId, setDeleteRowId] = useState(null);

  return (
    <Leave.Provider value={{ leavePageNo, setLeavePageNo, details, setDetails, isDelete, setIsDelete, isPopUp, setIsPopUp, deleteRowId, setDeleteRowId }}>
      {children}  
    </Leave.Provider>
  );
};

export const useLeaveContext = () => {
  return useContext(Leave);
};