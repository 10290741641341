import React,{useState,useEffect} from 'react'
import MyRequisitionService from '../service/MyRequisitionService';
import { useRequisitionContext } from '../context/RequisitionContext';
import Loading from '../../components/loader/Loading';

export default function RejectedRequisitionPage() {
    const {requisitionPageNo, setRequisitionPageNo,details,setDetails}=useRequisitionContext();
  
    const [employeeId, setEmployeeId] = useState('');
    const [reqList,setReqList]=useState([]);
    const [isLoading,setIsLoading]=useState(false);
    
    
    
    const setData = (val) => {
        setDetails(val);
        setRequisitionPageNo(2);
       
    }
    useEffect(()=>{
        const eid = localStorage.getItem('empId');
        setEmployeeId(eid);
        getMyRequisition(eid);
    },[]);
    
    const getMyRequisition=async(id)=>{
        setIsLoading(true);
        const re=await MyRequisitionService(id,"REJECTED");
        setReqList(re.req_details);
        setIsLoading(false);
        
    }
    const convertDate = (dateString) => {
        const dateObject = new Date(dateString);
        const day = String(dateObject.getUTCDate()).padStart(2, '0');
        const month = String(dateObject.getUTCMonth() + 1).padStart(2, '0');
        const year = dateObject.getUTCFullYear();
        
        return `${day}-${month}-${year}`;
      };
  return (
    <div>
    {
        isLoading
        ?
        <Loading/>
        :
        <div >
        {
            reqList.length===0?
            <div className=' w-full h-screen flex justify-center items-center'>
                <p className=' text-red-500 font-semibold'>No Requisition Found</p>
            </div>
            :
            
           <div className=' grid grid-cols-4 gap-6'>
            {
                 reqList.map((e,i)=>(
                    <button key={i}
                    onClick={() => setData(e)}
                    className=' h-52 w-48 rounded-md border-[1px] border-gray-300 p-2'>
                      <p className='text-black font-semibold'>{e.EMPLOYEE_NAME}</p>
                      <p className='text-black font-medium'>Requisition Type: {e.REQUISITION_TYPE}</p>
                      <div className='h-4'></div>
                      <div className='w-full flex space-x-2 items-center  justify-center'>
                          <div className='px-2 py-1 bg-blue-200 rounded-md'>{convertDate(e.CREATION_DATE)}</div>
                          {
                            e.PAYMENY_METHOD
                            &&
                            <div className='px-2 py-1 bg-blue-200 rounded-md'>{e.PAYMENY_METHOD}</div>
                          }
                      </div>
                    </button>  
                  ))
            }
           </div>


        }
    </div>
    }
</div>
  )
}
