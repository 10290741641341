

  const deleteTemplate= async(url,templateId)=>{
    
    // console.log(`em:${empId} se: ${sequence} st: ${status} md: ${moduleId} au: ${authorizationId} ap: ${approvalId}`);
    const response = await   fetch(url,
        {
            method: "POST",
            headers: {
              
              "Content-Type": "application/json",
              'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
            },
            body: JSON.stringify({
                "AM_ID":templateId
            }),
          }
        );
        // if (!response.ok) {
        //     throw new Error("fetching is not successful");
        //   }
          const data = await response.json();
          console.log(`delete template: ${data}`);
          return data;

     
}
export default deleteTemplate
