import { baseUrl } from "../../utils/path";



const CreateIouItemService = async (  iouList) => { 
    const url=`${baseUrl}iou_item_insertion`;
    console.log(JSON.stringify(iouList));
    const payload = { data: iouList };
    const jsonString = JSON.stringify(payload);
    console.log(jsonString);
    const response = await fetch(url,
      {
        method: "POST",
        headers: {
  
          "Content-Type": "application/json",
          'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
        },
        body: JSON.stringify(
            payload
        ),
      }
    );
    const data = await response.json();
    return data;
  
  }
  export default CreateIouItemService
  