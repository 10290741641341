import { DatePicker } from "rsuite";
import Datepicker from "react-tailwindcss-datepicker";
import React, { useEffect } from "react";
import { useState } from "react";
import moment from "moment";
import { CSVLink } from "react-csv";
import Loading from "../../loader/Loading";
import getCompanyList from "../../../services/CompanyListService";
import DailyAttendanceService from "../../daily_attendance/service/DailyAttendanceService";
import GetDailyAttendanceTotal from "../../daily_attendance/service/GetDailyAttendanceTotal";
import { baseUrl } from "../../../utils/path";
import getPlantList from "../../../services/PlantListService";
import AttendanceImageService from "../../../services/AttendanceImageService";
import FrontDeskCompanyListService from "../../../front_desk_report/service/FrontDeskCompanyListService";
import FrontDeskPlantListService from "../../../front_desk_report/service/FrontDeskPlantListService";


const companyUrl = `${baseUrl}getCompanyList`;
const moduleUrl = `${baseUrl}approval_module_types`;
const plantUrl = `${baseUrl}get_plant_details`;
const imgURL=`${baseUrl}attendance_picture`;


export default function DailyAttendanceReportPage() {

  const [sateStatusCnt, setStageStatusCnt] = useState('');
  const [userStatusCnt, setUserStatusCnt] = useState('A');
  const [uId, setUid] = useState('');
  const [page, setPage] = useState(0);
  let limit = 10;
  let totalLimit = 100000;
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [totalPage, setTotalPage] = useState(null);
  const [pageCount, setPageCount] = useState(1)
  const [empId, setEmpId] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isSearch, setIsSearch]=useState(false);
  let [pageCountSearch, setPageCountSearch]=useState(1);
  let [pageSearch, setPageSearch]=useState(0);
  // const [startEndDate, setStartEndDate] = useState({ startDate: null, endDate: null, });
  // const [startEndDate, setStartEndDate] = useState(new Date());
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [companyCode, setCompanyCode] = useState('');
  const [plantCode, setPlantCode] = useState('');
  const [selectedCompanyname, setSelectedCompanyName] = useState('');
  const [selectedPlantname, setSelectedPlantName] = useState('');
  const [isCompanyLoading, setIsCompanyLoading] = useState(true);
  const [company, setCompany] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [isPlantVisible, setIsPlantVisible] = useState(false);
  const [plant, setPlant] = useState([]);
  const [isPlantLoading, setIsPlantLoading] = useState(true);
  const [searchById, setSearchById]=useState('');
  const [processedData, setProcessedData] = useState([]);
  const [allProcessedData, setAllProcessedData] = useState([]);
  const [totalReport, setTotalReport] = useState([]);
  const todayDate = moment(Date()).format('YYYY-MM-DD');
  const [today, setToday]=useState('');
  const [dateValue, setDateValue] = React.useState(null);
  const [startDate, setStartDate]=useState('');
  const [imgOpenClose, setImgOpenCLose]=useState(false);
  const [isImageLoading, setIsImageLoading]=useState(true);
  const [picUrl, setPicUrl]=useState(null);
  const [attendanceStatus, setAttendanceStatus] = useState("all");
  const [attendanceData, setAttendanceData] = useState([]);
  const [hidden, setHidden] = useState(false);
  const [hiddenList, setHiddenList] = useState([]);
  const [isEmpIdShow, setIsEmpIdShow] = useState(true);
  const [isEmpNameShow, setIsEmpNameShow] = useState(true);
  const [isPositionShow, setIsPositionShow] = useState(true);
  const [isPlantNameShow, setIsPlantNameShow] = useState(true);
  const [isDepartmentShow, setIsDepartmentShow] = useState(true);
  const [isClockInShow, setIsClockInShow] = useState(true);
  const [isClockOutShow, setIsClockOutShow] = useState(true);
  const [isLateTimeShow, setIsLateTimeShow] = useState(true);
  const [isLeaveTypeShow, setIsLeaveTypeShow] = useState(true);
  const [isMoveOrderShow, setIsMoveOrderShow] = useState(true);
  const [isMoveOrderClockInShow, setIsMoveOrderClockInShow] = useState(true);
  const [isMoveOrderClockOutShow, setIsMoveOrderClockOutShow] = useState(true);
  const [isAttendanceStatusShow, setIsAttendanceStatusShow] = useState(true);
  const [isTotalWorkingTimeShow, setIsTotalWorkingTimeShow] = useState(true);
  const [isPictureShow, setIsPictureShow] = useState(true);
  const [isRemarksShow, setIsRemarksShow] = useState(true);
  const [isOverTimeShow, setIsOverTimeShow] = useState(true);

  useEffect(() => {
    setToday(todayDate);
    setStartDate(today)
    const id = localStorage.getItem('userId');
    setUid(id);
    getCompanyName(id);
    getAll(todayDate, todayDate, page);
    allDownloadData(todayDate, todayDate, "", "", "");
    getTotalReport(todayDate);
    // getPlanMaster();
    // stageStatusRef.current.value = "A";
    // userStatusRef.current.value = "A";
    setStageStatusCnt("A");
    setUserStatusCnt("A");

  }, [todayDate]);

  useEffect(() => {
    // const getCompanyName = async () => {
    //   const result = await getCompanyList(companyUrl);
    //   setCompanyList(result.items);
    //   setIsCompanyLoading(false);
    // };
    // getCompanyName();
  },[] );

  const getCompanyName = async (id) => {
    const re = await FrontDeskCompanyListService(id);
    console.log(re.Company_List);
    setIsCompanyLoading(false);
    setCompanyList(re.Company_List);
  };

  // for totalAttendance, totalAbsent, totalLeave .....
  const getTotalReport = async (tDate) => {
    const re = await GetDailyAttendanceTotal(tDate);
    console.log("total report: ", re.TOTAL_DETAILS);
    setTotalReport(re.TOTAL_DETAILS);
  }

  // initially get all data
  const getAll = async (fDate, tDate, pg) => {
    console.log("date: ", fDate, tDate)
    setIsLoading(true);
    // setData([]);
    const result = await DailyAttendanceService("", "", "", todayDate, todayDate, pg, limit);
    
    console.log("totalAl: ", result.Total_Count)
    dividePage(result.Total_Count);
    console.log("daily atn. rep.", result.ATTENDANCE_DETAILS);
      
    setData(result.ATTENDANCE_DETAILS);
    setData2(result.ATTENDANCE_DETAILS);
    processData(result.ATTENDANCE_DETAILS);
    setIsLoading(false);
  };

  const allDownloadData = async (stDate, eDate, compCode, plantCode, searchId) => {
    setIsLoading(true);

    console.log("stDate: ", stDate);
    console.log("eDate: ", eDate);
    console.log("compCode: ", compCode);
    console.log("plantCode: ", plantCode);
    console.log("searchId: ", searchId);

    const result = await DailyAttendanceService(searchId ? searchId : "", compCode ? compCode : "", plantCode ? plantCode : "", stDate ? stDate : "", eDate ? eDate : "", page, totalLimit);
    
    allProcessData(result.ATTENDANCE_DETAILS);
    setIsLoading(false);
  };

  // divided page number to allPage (for pagination)
  const dividePage = (number) => {
    console.log(number);
    if (typeof number !== 'number') {
      throw new Error('Input must be a number');
    }
      
    const re= Math.ceil(number / 10);
    setTotalPage(re);
  };

  // go to next page
  const next = async () => {
    // setPage((page)=>page+10);
    const newPage = page + 10;
    setPage(newPage);
    console.log(`page here ${newPage}`);
    setPageCount((pre) => pre + 1);
  
    setIsLoading(true);
    setData([]);
    setData2([]);
  
    const result = await DailyAttendanceService(searchById, companyCode, plantCode, startDate ? startDate : todayDate, startDate ? startDate : todayDate, newPage, limit)
      
    dividePage(result.Total_Count);
    setData(result.ATTENDANCE_DETAILS);
    setData2(result.ATTENDANCE_DETAILS);
    processData(result.ATTENDANCE_DETAILS);
    setIsLoading(false);
    
    console.log('clicked');
  }

  // after Searching next page
  const searchNext = async() => {
    // setPage((page)=>page+10);
    const newPage = pageSearch + 10;
    setPageSearch(newPage);
    console.log(`page here ${newPage}`);
    setPageCountSearch((pre) => pre + 1);

    setIsLoading(true);
    setData([]);
    setData2([]);

    const result = await DailyAttendanceService(searchById, companyCode, plantCode, startDate ? startDate : today, startDate ? startDate : today, newPage, limit);

    dividePage(result.Total_Count);
      
    setData(result.ATTENDANCE_DETAILS);
    setData2(result.ATTENDANCE_DETAILS);
    processData(result.ATTENDANCE_DETAILS);
    setIsLoading(false);
  }

  // back to previous page
  const previous = async () => {
      
    // setPage((pre)=>pre-10);
    const newPage = page - 10;
    setPage(newPage);
    console.log(`page here ${newPage}`);
    setPageCount((pre) => pre - 1);
  
    console.log('previous button clicked');
    setIsLoading(true);
    setData([]);
    setData2([]);
    
    const result = await DailyAttendanceService(searchById, companyCode, plantCode, startDate ? startDate : today, startDate ? startDate : today, newPage, limit);
    
    dividePage(result.Total_Count);

    setData(result.ATTENDANCE_DETAILS);
    setData2(result.ATTENDANCE_DETAILS);
    processData(result.ATTENDANCE_DETAILS);
    setIsLoading(false);
  }

  // after Searching back previous
  const searchPrevious = async () => {
    const newPage = pageSearch - 10;
    setPageSearch(newPage);
    console.log(`page here ${newPage}`);
    setPageCountSearch((pre) => pre - 1);
  
    console.log('clicked');
    setIsLoading(true);
    setData([]);
    setData2([]);
    
    const result = await DailyAttendanceService(searchById, companyCode, plantCode, startDate ? startDate : today, startDate ? startDate : today, newPage, limit);

    dividePage(result.Total_Count);
    setData(result.ATTENDANCE_DETAILS);
    setData2(result.ATTENDANCE_DETAILS);
    processData(result.ATTENDANCE_DETAILS);
    setIsLoading(false);
  }

  // convert date by day/month/year
  const convertDate = (dateString) => {
    const dateObject = new Date(dateString);
    const day = String(dateObject.getUTCDate()).padStart(2, '0');
    const month = String(dateObject.getUTCMonth() + 1).padStart(2, '0');
    const year = dateObject.getUTCFullYear();

    return `${day}/${month}/${year}`;
  };

  // using for see more and see less for description
  const [seeMore, setSeeMore] = useState(-1);

  const handleSeeMoreDetails = (index) => {
    setSeeMore(index === seeMore ? -1 : index);
  };

  // date select
  // const handleDateChange = async (newDate) => {
  //   console.log("start:", moment(newDate.startDate).format("YYYY-MM-DD"));
  //   console.log("end:", moment(newDate.endDate).format("YYYY-MM-DD"));
  //   setStartEndDate(newDate);
    
  //   setFromDate(newDate.startDate);
  //   setToDate(newDate.endDate);

  //   console.log("select date new: ", fromDate);
  //   setIsLoading(true);
  //   setData([]);
  //   setData2([]);
    
  //   const result = await DailyAttendanceService("", "", "", newDate.startDate, newDate.endDate, page, limit);
    
  //   dividePage(result.Total_Count);
  //   setData(result.ATTENDANCE_DETAILS);
  //   setData2(result.ATTENDANCE_DETAILS);
  //   processData(result.ATTENDANCE_DETAILS);
  //   allDownloadData(newDate.startDate, newDate.endDate, "", "", "");
  //   setIsLoading(false);
  // }

  const [fileName, setFileName] = useState(moment(Date()).format("DD/MM/YYYY"));
  
  // single date select
  const handleDateChange = async (newDate) => {
    console.log("new Date", newDate)
    const startDateFormatted = moment(newDate).format("YYYY-MM-DD");
    const endDateFormatted = moment(newDate).format("YYYY-MM-DD");

    console.log("start:", startDateFormatted);
    console.log("end:", endDateFormatted);

    // setStartEndDate(newDate);
    setDateValue(newDate);
    setFromDate(startDateFormatted);
    setToDate(endDateFormatted);

    console.log("select date new: ", fromDate);
    setIsLoading(true);
    setStartDate(startDateFormatted);
    setData([]);
    setData2([]);
    setCompanyCode([]);
    setPlantCode([]);
    setPageCountSearch(1);
    setPageCount(1);
    // setPage(0);
    const p = 0;
    setPage(p);

    // Update fileName to include the selected date or fallback to today's date
    setFileName(newDate ? moment(newDate).format("DD/MM/YYYY") : todayDate);

    const result = await DailyAttendanceService("", "", "", startDateFormatted ? startDateFormatted : todayDate, endDateFormatted ? endDateFormatted : todayDate, p, limit);
    
    console.log("total: ", result.Total_Count)
    dividePage(result.Total_Count);
    setData(result.ATTENDANCE_DETAILS);
    setData2(result.ATTENDANCE_DETAILS);
    processData(result.ATTENDANCE_DETAILS);
    getTotalReport(startDateFormatted)
    allDownloadData(startDateFormatted, endDateFormatted, "", "", "");
    setIsLoading(false);
  }

  // cancel date
  const handleCleanDate = () => {
    console.log("clean date clicked")
    
    setIsLoading(true);
    setPageCountSearch(1);
    setPageCount(1);
    setData([]);
    setData2([]);
    console.log("before: ", page);
    const p = 0;
    setPage(p);
    // setPage(0);

    // Update fileName to today's date
    setFileName(moment(Date()).format("DD/MM/YYYY")); 

    console.log("after: ", page);
    setStartDate(today);
    getAll(today, today, p);
    getTotalReport(today);
    allDownloadData(today, today, "", "", "");
    setIsLoading(false);
    setDateValue(null);
  }

  // company data
  const handleCompany = async (e) => {
    const selectedCode = e.target.value;
    const selectedName = companyList.find(c => c.company_code === selectedCode)?.company_name || '';
    
    console.log("Selected company code: ", selectedCode)

    setCompanyCode(selectedCode);
    setSelectedCompanyName(selectedName);
    getPlant(selectedCode);

    setIsLoading(true);
    setData([]);
    setData2([]);
    
    const result = await DailyAttendanceService("", selectedCode, "", startDate ? startDate : todayDate, startDate ? startDate : todayDate, page, limit);

    dividePage(result.Total_Count);
    setData(result.ATTENDANCE_DETAILS);
    setData2(result.ATTENDANCE_DETAILS);
    processData(result.ATTENDANCE_DETAILS);
    allDownloadData(fromDate ? fromDate : todayDate, toDate ? toDate : todayDate, selectedCode, "", "");
    setIsPlantVisible(true);
    setIsLoading(false);
  };

  // plant data 
  const handlePlant = async (e) => {
    // handleDropDown(e);
    // setPlantName(e.PLANT_NAME);

    const selectedPlantCode = e.target.value;
    setPlantCode(selectedPlantCode);

    const selectedName = plant.find(c => c.PLANT_CODE === selectedPlantCode)?.PLANT_NAME || '';
    setSelectedPlantName(selectedName);

    setIsLoading(true);
    setData([]);
    setData2([]);

    const result = await DailyAttendanceService("", companyCode, selectedPlantCode, startDate ? startDate : todayDate, startDate ? startDate : todayDate, page, limit);

    dividePage(result.Total_Count);
    setData(result.ATTENDANCE_DETAILS);
    setData2(result.ATTENDANCE_DETAILS);
    processData(result.ATTENDANCE_DETAILS);
    allDownloadData(fromDate ? fromDate : todayDate, toDate ? toDate : todayDate, companyCode, selectedPlantCode, "");
    setIsLoading(false);
  };

  // plant list here
  // const getPlant = async (code) => {
  //   const plant = await getPlantList(plantUrl, code);
  //   console.log("plant:=", plant);
  //   setPlant(plant.PLANT_DETAILS);

  //   setIsPlantLoading(false);
  // }

  const getPlant = async (cid) => {
    console.log(cid);
    setIsPlantLoading(true);
    const re = await FrontDeskPlantListService(uId, cid);
    setPlant(re.Plant_List);
    setIsPlantLoading(false);
};

  // for searching by employee id
  const searchEmpId = async () => {
    setIsLoading(true);
    setData([]);
    setData2([]);

    const result = await DailyAttendanceService(searchById, companyCode, plantCode, startDate ? startDate : todayDate, startDate ? startDate : todayDate, page, limit);

    dividePage(result.Total_Count);
    setData(result.ATTENDANCE_DETAILS);
    setData2(result.ATTENDANCE_DETAILS);
    processData(result.ATTENDANCE_DETAILS);
    allDownloadData(fromDate ? fromDate : todayDate, toDate ? toDate : todayDate, companyCode, plantCode, searchById);
    setIsLoading(false);

    // setSearchById('');
  }

  // downloading only page data
  const processData = (data) => {
    if (data && data.length > 0) {
      const newData = data.map((obj) => {
        const newDate = new Date(obj.REQUISITION_LOADING_DATE);
        const formattedDate = moment(newDate).format("DD/MM/YYYY");
        const lateTime = calculateWorkingTime(obj.LOG_IN, obj.OFFICE_BUFFER_TIME);
        const totalWorkingTime = calculateWorkingTime(obj.LOG_OUT, obj.LOG_IN);
  
        return { ...obj, REQUISITION_LOADING_DATE: formattedDate, LATE_TIME: lateTime, TOTAL_WORKING_TIME: totalWorkingTime };
      });
  
      setProcessedData(newData);
      console.log("processed Data: ", newData);
    } else {
      console.log("Data is empty or undefined");
    }
  };
  
  // for downloading all data
  const allProcessData = (data) => {
    if (data && data.length > 0) {
      const newData = data.map((obj) => {
        const newDate = new Date(obj.REQUISITION_LOADING_DATE);
        const formattedDate = moment(newDate).format("DD/MM/YYYY");
        const lateTime = calculateWorkingTime(obj.LOG_IN, obj.OFFICE_BUFFER_TIME);
        const totalWorkingTime = calculateWorkingTime(obj.LOG_OUT, obj.LOG_IN);
  
        return { ...obj, REQUISITION_LOADING_DATE: formattedDate, LATE_TIME: lateTime, TOTAL_WORKING_TIME: totalWorkingTime };
      });
  
      setAllProcessedData(newData);
    } else {
      console.log("Data is empty or undefined");
    }
  };


  // for excel downloading header
  const headers = [
    { label: "EMPLOYEE ID", key: "EMPLOYEE_ID" },
    { label: "EMPLOYEE NAME", key: "EMPLOYEE_NAME" },
    { label: "POSITION", key: "POSITION_NAME" },
    { label: "PLANT NAME", key: "PLANT_NAME" },
    { label: "DEPARTMENT", key: "DEPARTMENT_NAME" },
    { label: "CLOCK IN", key: "LOG_IN" },
    { label: "CLOCK OUT", key: "LOG_IN" },
    { label: "LATE TIME", key: "LATE_TIME" },
    { label: "LEAVE TYPE", key: "LEAVE_TYPE" },
    { label: "MOVE ORDER", key: "MOVE_ORDER_TYPE" },
    { label: "MOVE ORDER CLOCK IN", key: "MOVE_ORDER_START_TIME" },
    { label: "MOVE ORDER CLOCK OUT", key: "MOVE_ORDER_END_TIME" },
    { label: "ATTENDANCE STATUS", key: "ATTENDANCE_STATUS" },
    { label: "TOTAL WORKING TIME", key: "TOTAL_WORKING_TIME" },
    { label: "PICTURE", key: "PICTURE" },
    { label: "REMARKS", key: "REMARKS" },
    { label: "OVER TIME", key: "OVER_TIME" },
  ];

  // today date 
  // let fileName = moment(Date()).format("DD/MM/YYYY");

  // calculating total working time and late time
  const calculateWorkingTime = (loginTime, officeStartTime) => {
    // Create Date objects for login time and office start time
    const loginDate = new Date(`1970-01-01 ${loginTime}`);
    const officeStartDate = new Date(`1970-01-01 ${officeStartTime}`);
  
    // Calculate the time difference in milliseconds
    const timeDiff = loginDate.getTime() - officeStartDate.getTime();
  
    // Check if login time is greater than office start time
    if (timeDiff > 0) {
      // Convert the time difference to minutes or hours
      let result = '';
      const minutesDiff = Math.floor(timeDiff / 1000 / 60);
      if (minutesDiff >= 60) {
        const hoursDiff = Math.floor(minutesDiff / 60);
        const remainingMinutesDiff = minutesDiff % 60;
        result = `${hoursDiff} h : ${remainingMinutesDiff} m`;
        return result;
      } 
      else {
        result = `${minutesDiff}m`;
        return result;
      }
      // return result;
    } 
    else {
      return "N/A";
    }
  };

  // format time
  // const formatTime = (dateTimeString) => {
  //   if (!dateTimeString) return "N/A";
  
  //   const dateComponents = dateTimeString.split(/[\s-:]/);
  
  //   // Check if the split was successful and there are enough components
  //   if (dateComponents.length >= 5) {
  //     const [day, month, year, hour, minute] = dateComponents;
  //     const formattedTime = `${day}-${month}-${year} ${format12HourTime(hour, minute)}`;
  //     return formattedTime;
  //   } else {
  //     return "N/A";
  //   }
  // };
  
  // const format12HourTime = (hour, minute) => {
  //   if (!hour || !minute) return "N/A";
  
  //   // format two-digit for hour and minute
  //   const formattedHour = (parseInt(hour, 10) % 12 || 12).toString().padStart(2, '0');
  //   const formattedMinute = parseInt(minute, 10).toString().padStart(2, '0');
  
  //   const period = parseInt(hour, 10) < 12 ? 'AM' : 'PM';
  //   return `${formattedHour}:${formattedMinute} ${period}`;
  // };

  
  // show image
  const showImage = async(attId) => {
    const im = await AttendanceImageService(imgURL, uId, attId);
    base64ToImageUrl(im.PICTURE);
  }

  const base64ToImageUrl = (base64String) => {
    const blob = base64ToBlob(base64String);
    const url = URL.createObjectURL(blob);
    setPicUrl(url);
    setIsImageLoading(false);
  };
              
  const base64ToBlob = (base64String) => {
    const byteCharacters = atob(base64String);
    const byteArrays = [];
              
    for (let i = 0; i < byteCharacters.length; i++) {
      byteArrays.push(byteCharacters.charCodeAt(i));
    }
              
    const blob = new Blob([new Uint8Array(byteArrays)], { type: 'image/png' });
    return blob;
  };

  const[filterTotal, setFilterTotal] = useState(null);

  const handleAttendanceStatus = async (e) => {
    
    const selectedValue = e.target.value.toLowerCase(); 
    console.log("att value: ", selectedValue);
    setAttendanceStatus(selectedValue);
    
    // const result = await DailyAttendanceService(searchById, companyCode, plantCode, startDate ? startDate : todayDate, startDate ? startDate : todayDate, page, limit);
    // setAttendanceData(result.ATTENDANCE_DETAILS)
    // setFilterTotal(result.Total_Count)

    if(selectedValue === 'all') {
      setData(data2);
      console.log(data2.length);
      dividePage(data2.length)
      processData(data2);
      return;
    }

    else if(selectedValue === 'present') {
      console.log(data2.length);
      const presentData = data.filter((item) => 
         item.ATTENDANCE_STATUS.toLowerCase() === "present"
      )
      console.log(presentData.length);
      setData([]);
      setData(presentData);
      setPageCount(1);
      setPageCountSearch(1);
      dividePage(presentData.length);
      processData(presentData);
    }

    else if(selectedValue === 'absent') {
      const absentData = data2.filter((item) => 
         item.ATTENDANCE_STATUS.toLowerCase() === "absent"
      )
      setData(absentData);
      setPageCount(1);
      setPageCountSearch(1);
      dividePage(absentData.length)
      processData(absentData);
    }
    
  }

  const addToHiddenList = (name) => {
    // Create a new array by spreading the existing items and adding the new name
    const updatedList = [...hiddenList, { name }];
    // Set the state with the new array
    setHiddenList(updatedList);

    // Update the visibility state based on the added name
    if (name === "EMPLOYEE ID") {
      setIsEmpIdShow(false);
    } else if (name === "EMPLOYEE NAME") {
      setIsEmpNameShow(false);
    } else if (name === "POSITION") {
        setIsPositionShow(false);
    } else if (name === "PLANT NAME") {
        setIsPlantNameShow(false);
    } else if (name === "DEPARTMENT") {
        setIsDepartmentShow(false);
    } else if (name === "CLOCK IN") {
        setIsClockInShow(false);
    } else if (name === "CLOCK OUT") {
        setIsClockOutShow(false);
    } else if (name === "LATE TIME") {
        setIsLateTimeShow(false);
    } else if (name === "LEAVE TYPE") {
        setIsLeaveTypeShow(false);
    } else if (name === "MOVE ORDER") {
        setIsMoveOrderShow(false);
    } else if (name === "MOVE ORDER CLOCK IN") {
        setIsMoveOrderClockInShow(false);
    } else if (name === "MOVE ORDER CLOCK OUT") {
        setIsMoveOrderClockOutShow(false);
    } else if (name === "ATTENDANCE STATUS") {
        setIsAttendanceStatusShow(false);
    } else if (name === "TOTAL WORKING TIME") {
        setIsTotalWorkingTimeShow(false);
    } else if (name === "PICTURE") {
        setIsPictureShow(false);
    } else if (name === "REMARKS") {
      setIsRemarksShow(false);
    } else if (name === "OVER TIME") {
      setIsOverTimeShow(false);
    }
    // Add more conditions for other columns if needed
};

const deleteFromHiddenList = (name) => {
    // Update the visibility state based on the deleted name
    if (name === "EMPLOYEE ID") {
      setIsEmpIdShow(true);
    } else if (name === "EMPLOYEE NAME") {
      setIsEmpNameShow(true);
    } else if (name === "POSITION") {
        setIsPositionShow(true);
    } else if (name === "PLANT NAME") {
        setIsPlantNameShow(true);
    } else if (name === "DEPARTMENT") {
        setIsDepartmentShow(true);
    } else if (name === "CLOCK IN") {
        setIsClockInShow(true);
    } else if (name === "CLOCK OUT") {
        setIsClockOutShow(true);
    } else if (name === "LATE TIME") {
        setIsLateTimeShow(true);
    } else if (name === "LEAVE TYPE") {
        setIsLeaveTypeShow(true);
    } else if (name === "MOVE ORDER") {
        setIsMoveOrderShow(true);
    } else if (name === "MOVE ORDER CLOCK IN") {
        setIsMoveOrderClockInShow(true);
    } else if (name === "MOVE ORDER CLOCK OUT") {
        setIsMoveOrderClockOutShow(true);
    } else if (name === "ATTENDANCE STATUS") {
        setIsAttendanceStatusShow(true);
    } else if (name === "TOTAL WORKING TIME") {
        setIsTotalWorkingTimeShow(true);
    } else if (name === "PICTURE") {
        setIsPictureShow(true);
    } else if (name === "REMARKS") {
      setIsRemarksShow(true);
    } else if (name === "OVER TIME") {
      setIsOverTimeShow(true);
    }
    // Add more conditions for other columns if needed

    // Remove the item from hiddenList
    const updatedItems = hiddenList.filter((item) => item.name !== name);
    setHiddenList(updatedItems);
    if (updatedItems.length === 0) {
        setHidden(false);
    }
};

const toggleColumnVisibility = (columnName) => {
    // Update the state based on the column name
    if (columnName === "EMPLOYEE ID") {
      setIsEmpIdShow(!isEmpIdShow);
      addToHiddenList(columnName);
    } else if (columnName === "EMPLOYEE NAME") {
      setIsEmpNameShow(!isEmpNameShow);
      addToHiddenList(columnName);
    } else if (columnName === "POSITION") {
      setIsPositionShow(!isPositionShow);
      addToHiddenList(columnName);
    } else if (columnName === "PLANT NAME") {
      setIsPlantNameShow(!isPlantNameShow);
      addToHiddenList(columnName);
    } else if (columnName === "DEPARTMENT") {
      setIsDepartmentShow(!isDepartmentShow);
      addToHiddenList(columnName);
    } else if (columnName === "CLOCK IN") {
      setIsClockInShow(!isClockInShow);
      addToHiddenList(columnName);
    } else if (columnName === "CLOCK OUT") {
      setIsClockOutShow(!isClockOutShow);
      addToHiddenList(columnName);
    } else if (columnName === "LATE TIME") {
      setIsLateTimeShow(!isLateTimeShow);
      addToHiddenList(columnName);
    } else if (columnName === "LEAVE TYPE") {
      setIsLeaveTypeShow(!isLeaveTypeShow);
      addToHiddenList(columnName);
    } else if (columnName === "MOVE ORDER") {
      setIsMoveOrderShow(!isMoveOrderShow);
      addToHiddenList(columnName);
    } else if (columnName === "MOVE ORDER CLOCK IN") {
      setIsMoveOrderClockInShow(!isMoveOrderClockInShow);
      addToHiddenList(columnName);
    } else if (columnName === "MOVE ORDER CLOCK OUT") {
      setIsMoveOrderClockOutShow(!isMoveOrderClockOutShow);
      addToHiddenList(columnName);
    } else if (columnName === "ATTENDANCE STATUS") {
      setIsAttendanceStatusShow(!isAttendanceStatusShow);
      addToHiddenList(columnName);
    } else if (columnName === "TOTAL WORKING TIME") {
      setIsTotalWorkingTimeShow(!isTotalWorkingTimeShow);
      addToHiddenList(columnName);
    } else if (columnName === "PICTURE") {
      setIsPictureShow(!isPictureShow);
      addToHiddenList(columnName);
    } else if (columnName === "REMARKS") {
      setIsRemarksShow(!isRemarksShow);
      addToHiddenList(columnName);
    } else if (columnName === "OVER TIME") {
      setIsOverTimeShow(!isOverTimeShow);
      addToHiddenList(columnName);
    }
    // Add more conditions for other columns if needed
};
  


  return (
    <div className="mx-8 my-20 bg-white">
      <div className='w-full bg-white'>
        <h4 className=' text-black font-semibold lg:text-lg md:text-sm text-xs'>Daily Attendance Report</h4>

        <div className="h-5"></div>

        {
          isLoading ? (
            <Loading />
          ) : (
            <>
              <div className="flex items-center space-x-3">
                <div className="w-1/4">
                  {/* <Datepicker
                    popoverDirection="down"
                    placeholder="Date From-To         "
                    showFooter={true}
                    primaryColor={"green"}
                    showShortcuts={true}
                    value={startEndDate}
                    onChange={handleDateChange}
                    displayFormat={"DD/MM/YYYY"}
                    onClean={handleCleanDate}
                    toggleClassName={`absolute rounded-r-lg text-[#7F7F7F] right-0 h-full px-3 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed`}
                    // inputClassName="w-full rounded-md focus:ring-0 font-normal bg-green-100 dark:bg-green-900 dark:placeholder:text-green-100"
                    // containerClassName=" border border-[1px] border-gray-400 w-80 rounded-md"
                    // toggleClassName=" text-gray-400"
                    inputClassName={`outline-buttonColor w-full rounded-r-lg h-[48px] border-[1px] border-gray-300 rounded-md px-3`}
                    // containerClassName="  "
                  /> */}

                  <DatePicker
                    className='w-full'
                    appearance="default"
                    size="lg"
                    format="dd-MM-yyyy"
                    placeholder="Select Date"
                    onChange={handleDateChange}
                    value={dateValue}
                    onClean={handleCleanDate}
                    ranges={[
                      {
                        label: "Now",
                        value: new Date(),
                      },
                    ]}
                    // style={{ width: 335, height: 50 }}
                  />
                </div>

                <div className="w-1/4">
                  {
                    <select
                      placeholder='Select Company'
                      value={companyCode}
                      onChange={handleCompany}
                      className="w-full border bg-white h-[42px] rounded-lg px-2 focus:outline-none"
                    >
                      <option value='' disabled selected>Select Company</option>
                      {
                        companyList.map((e, index) =>
                          <option key={index} value={e.COMPANY_CODE}>
                            {e.COMPANY_NAME}({e.COMPANY_CODE})
                          </option>
                        )
                      }
                    </select>
                  }
                </div>

                <div className="w-1/4">
                  {  isPlantVisible && (
                    <select
                      value={plantCode}
                      onChange={handlePlant}
                      className="w-full border bg-white h-[42px] rounded-lg px-2 focus:outline-none"
                    >
                      <option value="" disabled selected>Select Plant</option>
                      {
                        isPlantLoading ?
                          <p>loading.....</p>
                          : plant.map((e, index) =>
                            <option value={e.PLANT_CODE} key={index}>{e.PLANT_NAME}({e.PLANT_CODE})</option>
                          )
                      }
                    </select>
                  )}
                </div>

                <div className="flex flex-row space-x-2 items-center w-1/4">
                  <input onChange={(e)=>{setSearchById(e.target.value)}} onKeyPress={(e) => { if (e.key === 'Enter') searchEmpId(); }} value={searchById} type="text" className="w-full px-3 h-[42px] rounded-lg border-[0.2px] bg-white border-gray-300 placeholder:text-sm placeholder:text-gray-400 focus:outline-none" placeholder='Search by Id or Name' />
                  
                  <button onClick={() => { searchEmpId() }} className="bg-[#013E0E] h-[42px] w-[87px] px-3 flex justify-center items-center text-white rounded-lg">
                    {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                    </svg> */}

                    Search
                  </button>
                </div>
              </div>

              <div className="h-7"></div>
              {totalReport.map((report, index) => 
                <div key={index}>
              
                  <div className='flex items-center justify-between space-x-4 w-full'>
                    
                    <div className='flex items-center justify-between w-1/5 h-24 rounded-l-md shadow-sm pl-2 pr-4 border-l-4 border-buttonColor'>
                      <div className="flex items-center space-x-2">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" dataSlot="icon" className="w-6 h-6 text-buttonColor">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M18 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM3 19.235v-.11a6.375 6.375 0 0 1 12.75 0v.109A12.318 12.318 0 0 1 9.374 21c-2.331 0-4.512-.645-6.374-1.766Z" />
                        </svg>

                        <p className="text-[#333439]">Total Attendance</p>
                      </div>

                      <p className="text-3xl font-semibold text-[#012308]">{report.TOTAL_ATTENDANCE}</p>

                    </div>

                    <div className='flex items-center justify-between w-1/5 h-24 rounded-l-md shadow-sm pl-2 pr-4 border-l-4 border-[#E20000]'>
                      <div className="flex items-center space-x-2">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" dataSlot="icon" className="w-6 h-6 text-[#E20000]">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
                        </svg>


                        <p className="text-[#333439]">Total Absent</p>
                      </div>

                      <p className="text-3xl font-semibold text-[#4B0000]">{report.TOTAL_ABSENT}</p>

                    </div>

                    <div className='flex items-center justify-between w-1/5 h-24 rounded-l-md shadow-sm pl-2 pr-4 border-l-4 border-[#FBCC16]'>
                      <div className="flex items-center space-x-2">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" dataSlot="icon" className="w-6 h-6 text-[#FBCC16]">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M22 10.5h-6m-2.25-4.125a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM4 19.235v-.11a6.375 6.375 0 0 1 12.75 0v.109A12.318 12.318 0 0 1 10.374 21c-2.331 0-4.512-.645-6.374-1.766Z" />
                        </svg>


                        <p className="text-[#333439]">Total Leave</p>
                      </div>

                      <p className="text-3xl font-semibold text-[#54431A]">{report.TOTAL_LEAVE}</p>

                    </div>

                    <div className='flex items-center justify-between w-1/5 h-24 rounded-l-md shadow-sm pl-2 pr-4 border-l-4 border-[#7a39b2]'>
                      <div className="flex items-center space-x-2">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" dataSlot="icon" className="w-6 h-6 text-[#7a39b2]">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
                        </svg>

                        <p className="text-[#333439]">Total Move Order</p>
                      </div>

                      <p className="text-3xl font-semibold text-[#33194e]">{report.TOTAL_MOVEORDER}</p>

                    </div>

                    <div className='flex items-center justify-between w-1/5 h-24 rounded-l-md shadow-sm pl-2 pr-4 border-l-4 border-[#194BFA]'>
                      <div className="flex items-center space-x-2">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" dataSlot="icon" className="w-6 h-6 text-[#194BFA]">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z" />
                        </svg>


                        <p className="text-[#333439]">Total Employee</p>
                      </div>

                      <p className="text-3xl font-semibold text-[#081953]">{report.TOTAL_EMPLOYEE}</p>

                    </div>
                  </div>
                </div>
              )}

              <div className="h-10"></div>

              {/* <button onClick={downloadExcel} className="bg-[#013E0E] h-[48px] w-[87px] px-3 flex justify-center items-center text-white rounded-lg">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" dataSlot="icon" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
                </svg>

                <p>Download</p>
              </button> */}

              <div className="flex items-center justify-end space-x-2">
                { data.length === 0 ? null : (
                  <CSVLink data={processedData} headers={headers} filename={`DAILY_ATTENDANCE_REPORT_${fileName}.csv`}>
                    <button className={` w-[180px] h-[48px] flex justify-center items-center bg-[#E6E1DD] px-2 rounded-lg shadow-sm text-[#013E0E] space-x-2 no-underline`}>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" dataSlot="icon" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
                      </svg>

                      <h2 className=" lg:text-sm md:text-xs text-xs">
                        Download
                      </h2>
                    </button>
                  </CSVLink>
                )}

                { data.length === 0 ? null : (
                  <CSVLink data={allProcessedData} headers={headers} filename={`ALL_DAILY_ATTENDANCE_REPORT_${fileName}.csv`}>
                    <button className={` w-[180px] h-[48px] flex justify-center items-center bg-[#E6E1DD] px-2 rounded-lg shadow-sm text-[#013E0E] space-x-2`}>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" dataSlot="icon" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
                      </svg>

                      <h2 className=" lg:text-sm md:text-xs text-xs">
                        Download All
                      </h2>
                    </button>
                  </CSVLink>
                )}
              </div>

              <div className="h-5"></div>

              <div className="w-full">
                {
                  data2.length === 0 ? (
                    <div className=" flex justify-center items-center w-full h-[300px]">
                      <h1 className=" text-red-600 font-bold text-2xl">
                        No Data Found
                      </h1>
                    </div>
                  ) : (
                    <>
                      <div className="overflow-x-auto">
                        <table className="w-full border-[0.5px] border-gray-200 rounded-lg">
                          <thead className="bg-bgTableHeader text-tableHeaderText shadow-sm h-14 rounded-lg">
                            <tr>
                              <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                <div className="flex flex-col items-center space-y-0">
                                  <div className="menu menu-horizontal ">
                                    <li tabIndex={0}>
                                      <button disabled = { hiddenList.length === 0 ? true : false } onClick={() => setHidden(!hidden) } className=" hover:bg-transparent text-green-600">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                          <path strokeLinecap="round" strokeLinejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"
                                          />
                                          <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"/>
                                        </svg>
                                      </button>

                                      {hidden && (
                                        <ul className={`rounded-md shadow-lg flex justify-start`}>
                                          <li className="space-y-1">
                                            <div className="flex flex-col space-y-0 bg-white">
                                              {hiddenList.map((item, index) => (
                                                <button key={index} onClick={() => { deleteFromHiddenList(item.name);}} className=" flex w-full justify-start items-start">
                                                  {/* Render your button content here */}
                                                  <p className=" text-sm ">{`${item.name} - X`}</p>
                                                </button>
                                              ))}
                                              
                                              <div className="border-gray-200 border-[1px]"></div>
                                            </div>
                                          </li>
                                        </ul>
                                      )}
                                    </li>
                                  </div>

                                  <p>SL</p>
                                </div>
                              </th>

                              { isEmpIdShow ? (
                                <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                  <div className="w-full flex flex-row items-center space-x-1">
                                    <p>EMPLOYEE ID</p>
                                    
                                    <button onClick={() => toggleColumnVisibility("EMPLOYEE ID")}>
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"/>
                                      </svg>
                                    </button>
                                  </div>
                                </th>
                              ) : null }
                                  
                              { isEmpNameShow ? (
                                <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                  <div className="w-full flex flex-row items-center space-x-1">
                                    <p>EMPLOYEE NAME</p>
                                    
                                    <button onClick={() => toggleColumnVisibility("EMPLOYEE NAME")}>
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"/>
                                      </svg>
                                    </button>
                                  </div>
                                </th>
                              ) : null }

                              {/* <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                EMPLOYEE NAME
                              </th> */}
                                  
                              { isPositionShow ? (
                                <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                  <div className="w-full flex flex-row items-center space-x-1">
                                    <p>POSITION</p>
                                    
                                    <button onClick={() => toggleColumnVisibility("POSITION")}>
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"/>
                                      </svg>
                                    </button>
                                  </div>
                                </th>
                              ) : null }
                              
                              {/* <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                POSITION
                              </th> */}

                              { isPlantNameShow ? (
                                <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                  <div className="w-full flex flex-row items-center space-x-1">
                                    <p>PLANT NAME</p>
                                    
                                    <button onClick={() => toggleColumnVisibility("PLANT NAME")}>
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"/>
                                      </svg>
                                    </button>
                                  </div>
                                </th>
                              ) : null }
                                  
                              {/* <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                PLANT NAME
                              </th> */}

                              { isDepartmentShow ? (
                                <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                  <div className="w-full flex flex-row items-center space-x-1">
                                    <p>DEPARTMENT</p>
                                    
                                    <button onClick={() => toggleColumnVisibility("DEPARTMENT")}>
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"/>
                                      </svg>
                                    </button>
                                  </div>
                                </th>
                              ) : null }
                                
                              {/* <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                DEPARTMENT
                              </th> */}

                              { isClockInShow ? (
                                <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                  <div className="w-full flex flex-row items-center space-x-1">
                                    <p>CLOCK IN</p>
                                    
                                    <button onClick={() => toggleColumnVisibility("CLOCK IN")}>
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"/>
                                      </svg>
                                    </button>
                                  </div>
                                </th>
                              ) : null }

                              {/* <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                CLOCK IN
                              </th> */}

                              { isClockOutShow ? (
                                <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                  <div className="w-full flex flex-row items-center space-x-1">
                                    <p>CLOCK OUT</p>
                                    
                                    <button onClick={() => toggleColumnVisibility("CLOCK OUT")}>
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"/>
                                      </svg>
                                    </button>
                                  </div>
                                </th>
                              ) : null }

                              {/* <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                CLOCK OUT
                              </th> */}

                              { isLateTimeShow ? (
                                <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                  <div className="w-full flex flex-row items-center space-x-1">
                                    <p>LATE TIME</p>
                                    
                                    <button onClick={() => toggleColumnVisibility("LATE TIME")}>
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"/>
                                      </svg>
                                    </button>
                                  </div>
                                </th>
                              ) : null }
                                  
                              {/* <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                LATE TIME
                              </th> */}

                              { isLeaveTypeShow ? (
                                <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                  <div className="w-full flex flex-row items-center space-x-1">
                                    <p>LEAVE TYPE</p>
                                    
                                    <button onClick={() => toggleColumnVisibility("LEAVE TYPE")}>
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"/>
                                      </svg>
                                    </button>
                                  </div>
                                </th>
                              ) : null }
                                  
                              {/* <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                LEAVE TYPE
                              </th> */}

                              { isMoveOrderShow ? (
                                <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                  <div className="w-full flex flex-row items-center space-x-1">
                                    <p>MOVE ORDER</p>
                                    
                                    <button onClick={() => toggleColumnVisibility("MOVE ORDER")}>
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"/>
                                      </svg>
                                    </button>
                                  </div>
                                </th>
                              ) : null }
                                  
                              {/* <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                MOVE ORDER
                              </th> */}

                              { isMoveOrderClockInShow ? (
                                <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                  <div className="w-full flex flex-row items-center space-x-1">
                                    <p>MOVE ORDER CLOCK IN</p>
                                    
                                    <button onClick={() => toggleColumnVisibility("MOVE ORDER CLOCK IN")}>
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"/>
                                      </svg>
                                    </button>
                                  </div>
                                </th>
                              ) : null }
                                
                              {/* <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                MOVE ORDER CLOCK IN
                              </th> */}

                              { isMoveOrderClockOutShow ? (
                                <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                  <div className="w-full flex flex-row items-center space-x-1">
                                    <p>MOVE ORDER CLOCK OUT</p>
                                    
                                    <button onClick={() => toggleColumnVisibility("MOVE ORDER CLOCK OUT")}>
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"/>
                                      </svg>
                                    </button>
                                  </div>
                                </th>
                              ) : null }

                              {/* <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                MOVE ORDER CLOCK OUT
                              </th> */}

                              { isAttendanceStatusShow ? (
                                <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                  <div className="w-full flex flex-row items-center space-x-1">
                                    <p>ATTENDANCE STATUS</p>

                                    <select
                                      placeholder="Status"
                                      className="h-8 w-full rounded-md focus:outline-none "
                                      value={attendanceStatus}
                                      onChange={handleAttendanceStatus}
                                    >
                                      <option value="all">
                                        All
                                      </option>
                                      <option value="present">
                                        Present
                                      </option>
                                      <option value="absent">
                                        Absent
                                      </option>
                                    </select>
                                    
                                    <button onClick={() => toggleColumnVisibility("ATTENDANCE STATUS")}>
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"/>
                                      </svg>
                                    </button>
                                  </div>
                                </th>
                              ) : null }

                              {/* <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                ATTENDANCE STATUS

                                <select
                                  placeholder="Status"
                                  className="h-8 w-full rounded-md focus:outline-none "
                                  value={attendanceStatus}
                                  onChange={handleAttendanceStatus}
                                >
                                  <option value="all">
                                    All
                                  </option>
                                  <option value="present">
                                    Present
                                  </option>
                                  <option value="absent">
                                    Absent
                                  </option>
                                </select>
                              </th> */}
                                  
                              { isTotalWorkingTimeShow ? (
                                <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                  <div className="w-full flex flex-row items-center space-x-1">
                                    <p>TOTAL WORKING TIME</p>
                                    
                                    <button onClick={() => toggleColumnVisibility("TOTAL WORKING TIME")}>
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"/>
                                      </svg>
                                    </button>
                                  </div>
                                </th>
                              ) : null }
                              
                              {/* <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                TOTAL WORKING TIME
                              </th> */}

                              { isPictureShow ? (
                                <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                  <div className="w-full flex flex-row items-center space-x-1">
                                    <p>PICTURE</p>
                                    
                                    <button onClick={() => toggleColumnVisibility("PICTURE")}>
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"/>
                                      </svg>
                                    </button>
                                  </div>
                                </th>
                              ) : null }
                                  
                              {/* <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                PICTURE
                              </th> */}

                              { isRemarksShow ? (
                                <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                  <div className="w-full flex flex-row items-center space-x-1">
                                    <p>REMARKS</p>
                                    
                                    <button onClick={() => toggleColumnVisibility("REMARKS")}>
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"/>
                                      </svg>
                                    </button>
                                  </div>
                                </th>
                              ) : null }

                              {/* <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                REMARKS
                              </th> */}

                              { isOverTimeShow ? (
                                <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                  <div className="w-full flex flex-row items-center space-x-1">
                                    <p>OVER TIME</p>
                                    
                                    <button onClick={() => toggleColumnVisibility("OVER TIME")}>
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"/>
                                      </svg>
                                    </button>
                                  </div>
                                </th>
                              ) : null }

                              {/* <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                OVER TIME
                              </th> */}
                            </tr>
                          </thead>
                              
                          <tbody className="w-full divide-y divide-borderColor">
                            {data.map((e, index) => (
                              <tr key={index} className={`${calculateWorkingTime(e.LOG_IN, e.OFFICE_BUFFER_TIME ) !== "N/A" ? 'bg-red-400 text-white' : 'bg-white'}`}>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <div className="flex flex-col items-center w-full">
                                    <p>{page + index + 1}</p>
                                  </div>
                                </td>

                                { isEmpIdShow ? (
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {e.EMPLOYEE_ID == null ? "N/A" : e.EMPLOYEE_ID}
                                  </td>
                                ) : null }

                                {/* <td className="px-6 py-4 whitespace-nowrap">
                                  {e.EMPLOYEE_ID == null ? "N/A" : e.EMPLOYEE_ID}
                                </td> */}

                                { isEmpNameShow ? (
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {e.EMPLOYEE_NAME == null ? "N/A" : e.EMPLOYEE_NAME}
                                  </td>
                                ) : null }
                                    
                                {/* <td className="px-6 py-4 whitespace-nowrap">
                                  {e.EMPLOYEE_NAME == null ? "N/A" : e.EMPLOYEE_NAME}
                                </td> */}

                                { isPositionShow ? (
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {e.POSITION_NAME == null ? "N/A" : e.POSITION_NAME}
                                  </td>
                                ) : null }
                                    
                                {/* <td className="px-6 py-4 whitespace-nowrap">
                                  {e.POSITION_NAME == null ? "N/A" : e.POSITION_NAME}
                                </td> */}

                                { isPlantNameShow ? (
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {e.PLANT_NAME == null ? "N/A" : `${e.PLANT_NAME}-(${e.PLANT_CODE})`}
                                  </td>
                                ) : null }
                                    
                                {/* <td className="px-6 py-4 whitespace-nowrap">
                                  {e.PLANT_NAME == null ? "N/A" : `${e.PLANT_NAME}-(${e.PLANT_CODE})`}
                                </td> */}
                                
                                { isDepartmentShow ? (
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {e.DEPARTMENT_NAME == null ? "N/A" : e.DEPARTMENT_NAME}
                                  </td>
                                ) : null }

                                {/* <td className="px-6 py-4 whitespace-nowrap">
                                  {e.DEPARTMENT_NAME == null ? "N/A" : e.DEPARTMENT_NAME}
                                </td> */}

                                { isClockInShow ? (
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {e.LOG_IN == null ? "N/A" : e.LOG_IN}
                                  </td>
                                ) : null }

                                {/* <td className="px-6 py-4 whitespace-nowrap">
                                  {e.LOG_IN == null ? "N/A" : e.LOG_IN}
                                </td> */}

                                { isClockOutShow ? (
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {e.LOG_OUT == null ? "N/A" : e.LOG_OUT}
                                  </td>
                                ) : null }
                                    
                                {/* <td className="px-6 py-4 whitespace-nowrap">
                                  {e.LOG_OUT == null ? "N/A" : e.LOG_OUT}
                                </td> */}

                                { isLateTimeShow ? (
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {calculateWorkingTime(e.LOG_IN, e.OFFICE_BUFFER_TIME )}
                                  </td>
                                ) : null }

                                {/* <td className="px-6 py-4 whitespace-nowrap">
                                  {calculateWorkingTime(e.LOG_IN, e.OFFICE_BUFFER_TIME )}
                                </td> */}

                                { isLeaveTypeShow ? (
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {e.LEAVE_TYPE == null ? "N/A" : e.LEAVE_TYPE}
                                  </td>
                                ) : null }

                                {/* <td className="px-6 py-4 whitespace-nowrap">
                                  {e.LEAVE_TYPE == null ? "N/A" : e.LEAVE_TYPE}
                                </td> */}
                                    
                                { isMoveOrderShow ? (
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {e.MOVE_ORDER_TYPE == null ? "N/A" : e.MOVE_ORDER_TYPE}
                                  </td>
                                ) : null }
                                {/* <td className="px-6 py-4 whitespace-nowrap">
                                  {e.MOVE_ORDER_TYPE == null ? "N/A" : e.MOVE_ORDER_TYPE}
                                </td> */}

                                { isMoveOrderClockInShow ? (
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {e.MOVE_ORDER_START_TIME == null ? "N/A" : e.MOVE_ORDER_START_TIME}
                                  </td>
                                ) : null }
                                
                                {/* <td className="px-6 py-4 whitespace-nowrap">
                                  {e.MOVE_ORDER_START_TIME == null ? "N/A" : e.MOVE_ORDER_START_TIME}
                                </td> */}

                                { isMoveOrderClockOutShow ? (
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {e.MOVE_ORDER_END_TIME == null ? "N/A" : e.MOVE_ORDER_END_TIME}
                                  </td>
                                ) : null }

                                {/* <td className="px-6 py-4 whitespace-nowrap">
                                  {e.MOVE_ORDER_END_TIME == null ? "N/A" : e.MOVE_ORDER_END_TIME}
                                </td> */}

                                { isAttendanceStatusShow ? (
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {e.ATTENDANCE_STATUS == null ? "N/A" : e.ATTENDANCE_STATUS}
                                  </td>
                                ) : null }

                                {/* <td className="px-6 py-4 whitespace-nowrap">
                                  {e.ATTENDANCE_STATUS == null ? "N/A" : e.ATTENDANCE_STATUS}
                                </td>  */}

                                { isTotalWorkingTimeShow ? (
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {calculateWorkingTime(e.LOG_OUT, e.LOG_IN )}
                                  </td>
                                ) : null }
                                    
                                {/* <td className="px-6 py-4 whitespace-nowrap">
                                  {calculateWorkingTime(e.LOG_OUT, e.LOG_IN )}
                                </td> */}

                                { isPictureShow ? (
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {e.ATDN_ROW_ID == null || e.ATDN_ROW_ID === "N/A" ? (
                                      "N/A"
                                    ) : (
                                      <button onClick={()=>{setImgOpenCLose(true); showImage(e.ATDN_ROW_ID)}} className=' w-auto p-1 bg-green-500 shadow-md rounded-md'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-white">
                                          <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                                        </svg>
                                      </button>
                                    )}
                                  </td>
                                ) : null }
                                    
                                {/* <td className="px-6 py-4 whitespace-nowrap">
                                  {e.ATDN_ROW_ID == null || e.ATDN_ROW_ID === "N/A" ? (
                                    "N/A"
                                  ) : (
                                    <button onClick={()=>{setImgOpenCLose(true); showImage(e.ATDN_ROW_ID)}} className=' w-auto p-1 bg-green-500 shadow-md rounded-md'>
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-white">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                                      </svg>
                                    </button>
                                  )}
                                </td> */}

                                { isRemarksShow ? (
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {e.REMARKS == null ? "N/A" : e.REMARKS}
                                  </td>
                                ) : null }

                                {/* <td className="px-6 py-4 whitespace-nowrap">
                                  {e.REMARKS == null ? "N/A" : e.REMARKS}
                                </td> */}

                                { isOverTimeShow ? (
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {e.OVER_TIME == null ? "N/A" : e.OVER_TIME}
                                  </td>
                                ) : null }

                                {/* <td className="px-6 py-4 whitespace-nowrap">
                                  {e.OVER_TIME == null ? "N/A" : e.OVER_TIME}
                                </td> */}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )
                }
              </div>
            </>
          )
        }

        {/* pagination start here */}

        <div className="h-5"></div>

        {data.length > 0 && (
          <div className='flex flex-row space-x-4 justify-center items-end mt-4 mb-3 lg:w-full md:w-3/4 sm:w-96 pr-6'>
            <button disabled={page === 0 && pageSearch === 0 ? true : false} onClick={() => { isSearch ? searchPrevious() : previous() }}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-6 h-6 font-bold ${page === 0 && pageSearch === 0 ? 'text-white' : `text-gray-600`} font-bold`}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
              </svg>
            </button>

            <p className={`text- text-[18px] font-bold`}>
              Page {isSearch ? pageCountSearch : pageCount} Of {totalPage}
            </p>

            <button disabled={(isSearch ? pageCountSearch : pageCount) === totalPage || data.length === 0} onClick={() => { isSearch ? searchNext() : next() }}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-6 h-6 font-bold ${((isSearch ? pageCountSearch : pageCount) === totalPage || data.length === 0) ? 'text-white' : 'text-gray-500'} font-bold`}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
              </svg>
            </button>
          </div>
        )}
      </div>

      <input type="checkbox" id="my-modal-11" className="modal-toggle" checked={imgOpenClose} />
        <div className="modal ">
          <div className="modal-box bg-white">
            <label htmlFor="my-modal-11" onClick={(e)=>{setImgOpenCLose(false); setPicUrl('')}} className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
   
            {
              isImageLoading ?
              <p>Loading........</p>
              :
              <img src={picUrl} alt="attpic" className=' h-80 w-full p-4 shadow-md rounded-md'  />
            }
  
    
          </div>
        </div>
    </div>
  )
}