import React, { useState, useEffect, useRef } from 'react'
import getCompanyList from '../../services/CompanyListService';
import getPlantList from '../../services/PlantListService';
import SuccessAlert from '../alert_component/SuccessAlert';
import { gray500, gray100, red500, gray300, gray400, green100, green600, gray700, gray600 } from '../../utils/colors';
import { useLocation } from 'react-router-dom';


import AddLatLongService from '../../services/AddLatLongService';
import ErrorAlert from '../alert_component/ErrorAlert';
import { baseUrl } from '../../utils/path';
import { useNavigate } from 'react-router-dom';
const companyUrl = `${baseUrl}getCompanyList`;
const plantUrl = `${baseUrl}get_plant_details`;
const addLatLongUrl=`${baseUrl}insert_geo_fencing_web`;


export default function AddLatLong(props) {
  let selectedCompanyCode, positionId, jobId, selectedPalntCode, selectedModuleCode, selectedUserId, selectedAuthorizerId, selectPsgid, selectDocumentIdGolbal;

  const lat = useRef(null);
  const long = useRef(null);
  const radi = useRef(null);

  const [company, setCompany] = useState([]);
  const [plant, setPlant] = useState([]);
  const [isPlantLoading, setIsPlantLoading] = useState(true);
  const [companyCode, setCompanyCode] = useState('');
  const [plantCode, setPlantCode] = useState('');
  const [selectedCompanyname, setSelectedCompanyName] = useState('');
  const [selectedPlantname, setSelectedPlantName] = useState('');
  const [adminId, setAdminId] = useState('');
  const [latlongError, setLatLongError] = useState({});
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [radius, setRaidus] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [latLongRadi,setLatLongRadi]=useState({})
  const [isLoading,setIsLoading]=useState(false);
  const location = useLocation();
  const [fenceId,setFenceId]=useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const id = localStorage.getItem('userId');
    setAdminId(id);
    getCompany();
    if (location.state && location.state.fence) {
      const { GF_ID, COMPANY_CODE, COMPANY_NAME, PLANT_CODE, PLANT_NAME, LATITUDE, LONGITUDE, RADIUS } = location.state.fence;
  
      if (GF_ID !== null) {
        setFenceId(GF_ID);
        setCompanyCode(COMPANY_CODE);
        setSelectedCompanyName(COMPANY_NAME);
        setPlantCode(PLANT_CODE);
        setSelectedPlantName(PLANT_NAME);
        setLatitude(LATITUDE.toString());
        setLongitude(LONGITUDE.toString());
        setRaidus(RADIUS.toString());
        if (COMPANY_CODE !== null) {
          // setIsPlantLoading(false);
          getPlant(COMPANY_CODE);
        }
        lat.current.value = LATITUDE.toString();
        long.current.value = LONGITUDE.toString();
        radi.current.value = RADIUS.toString();
      }
    }
    
  
  }, []);
  

  const validateAddLatLong =  () => {
    const latlongError = {};
    if (!latitude.trim()) {
      latlongError.latitude = "Enter Latitude";
    }
    if (!longitude.trim()) {
      latlongError.longitude = "Enter Longitude";
    }
    if (!radius.trim()) {
      latlongError.radius = "Enter Radius";
    }
    if (selectedCompanyname === '') {
      latlongError.selectedCompanyname = "Select Company"
    }
    if (selectedPlantname === '') {
      latlongError.selectedPlantname = "Select Plant"
    }

   

    setLatLongError(latlongError);

    return Object.keys(latlongError).length === 0;

  }

  const addValue= async()=>{
    if(validateAddLatLong()){
      setIsLoading(true);
        const add =await AddLatLongService(addLatLongUrl, adminId,latitude,longitude,radius,companyCode,plantCode);
       console.log(add);
       if(add.Message==="Success"){
        setSelectedCompanyName('');
        setCompanyCode('');
        setPlantCode('');
        setSelectedPlantName('');
        lat.current.value="";
        long.current.value="";
        radi.current.value="";
        setIsLoading(false);
        setShowAlert(true);
        setTimeout(() => {
          navigate('/home');
        }, 3200); // Delay of 1 seconds
       }
       else{
        setIsLoading(false);
        setShowErrorAlert(true);
      }
    }
    
  }

  const getCompany = async () => {
    const result = await getCompanyList(companyUrl);
    setCompany(result.items);

  }

  const getPlant = async (code) => {

    const plant = await getPlantList(plantUrl, code);

    setPlant(plant.PLANT_DETAILS);
    setIsPlantLoading(false);

  }

  const back=()=>{
    navigate('/home')
  }

  return (
    <div className=' w-full flex justify-center items-center'>
      <div className='  mt-24 shadow-xl rounded-lg w-1/2  p-8 flex flex-col items-start '>
         {
              showAlert && <SuccessAlert text={`Fence ${fenceId==null?"Added":"Updated"} Successfully`} setShowAlert={setShowAlert} />
            }
            {
              showErrorAlert && <ErrorAlert  text={`Fence ${fenceId==null?"Adding":"Updating"} Failed`} setShowErrorAlert={setShowErrorAlert}/>
            }

        {/* <h3 className=' text-black text-lg font-semibold my-3'>{fenceId==null? "Add Geo Fence":"Update Geo Fence"}</h3> */}
        <div className=' w-full flex flex-row items-center  justify-between'>
        <h3 className=' text-black text-lg font-semibold my-3'>{fenceId==null? "Add Geo Fence":"Update Geo Fence"}</h3>
            <button onClick={back} className=' py-1 px-10 rounded-full bg-green-500 text-[16px] font-medium text-white'>Back</button>
            </div>
        {


          <select placeholder='Select Company' value={companyCode} onChange={(e) => { selectedCompanyCode = e.target.value; setCompanyCode(selectedCompanyCode); setSelectedCompanyName(e.company_name); getPlant(selectedCompanyCode) }} className={`select select-bordered bg-white w-full mb-3 focus:outline-none text-${gray500}  `}>
            <option value='' disabled selected>Select Company</option>
            {
              company.map((e, index) =>
                <option key={index} value={e.company_code}>{e.company_name}({e.company_code})</option>
              )
            }
          </select>


        }

        {latlongError.selectedCompanyname && <span className={`text-${red500}`}>{latlongError.selectedCompanyname}</span>}

        {
          isPlantLoading ? <p></p> : <select value={plantCode} onChange={(e) => { selectedPalntCode = e.target.value; setPlantCode(selectedPalntCode); setSelectedPlantName(e.PLANT_NAME) }} className={`select select-bordered w-full mb-3  focus:outline-none bg-white text-${gray500}  `}>
            <option value="" disabled selected>Select Plant</option>
            {
              isPlantLoading ?
                <p>loading.....</p>
                : plant.map((e, index) =>
                  <option value={e.PLANT_CODE} key={index}>{e.PLANT_NAME}({e.PLANT_CODE})</option>
                )
            }

          </select>
        }
        {latlongError.selectedPlantname && <span className={`text-${red500}`}>{latlongError.selectedPlantname}</span>}
        <input min={1} ref={lat} onChange={(e) => { setLatitude(e.target.value) }} type="number" placeholder="Latitude" className="input input-bordered w-full bg-white focus:outline-none mb-3" />
        {latlongError.latitude && <span className={`text-${red500}`}>{latlongError.latitude}</span>}
        <input min={1} ref={long} onChange={(e) => setLongitude(e.target.value)} type="number" placeholder="Longitude" className="input input-bordered w-full bg-white focus:outline-none mb-3" />
        {latlongError.longitude && <span className={`text-${red500}`}>{latlongError.longitude}</span>}
        
        <input min={1} ref={radi} onChange={(e) => setRaidus(e.target.value)} type="number" placeholder="Radius" className="input input-bordered w-full bg-white focus:outline-none mb-3" />
        {latlongError.radius && <span className={`text-${red500}`}>{latlongError.radius}</span>}
        <div className=' w-full flex flex-row justify-end'>

         { 
         isLoading?
         <span className="loading loading-spinner text-success">Loading....</span>
         :
         <button onClick={()=>{addValue()}} className=' flex w-20 h-10 rounded-3xl justify-center items-center text-white font-semibold text-sm bg-green-600 mb-5'>
            {fenceId==null? "ADD":"UPDATE"}
          </button>
          }

         
        </div>
      </div>
    </div>
  )
}
