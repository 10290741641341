import { baseUrl } from "../../utils/path";


  const ApproverHierarchyListService = async( empId, moduleType, objectId ) => {

    console.log(`aa: ${empId} ${moduleType} ${objectId}`);

    const url=`${baseUrl}approver_hierarchy_list`;

    const response = await   fetch(url,
        {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
            },
            body: JSON.stringify(
                {
                
                    "ORIGINATOR": empId,
                    "MODULE_TYPE": moduleType,
                    "OBJECT_ID": objectId
            }
            ),
          }
        );
        if (!response.ok) {
            throw new Error("fetching is not successful");
          }
        
          const data = await response.json();
         
          return data;

     
}
export default ApproverHierarchyListService
