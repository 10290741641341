import React, { useState, useRef, useEffect } from 'react'
import { gray500, gray100, red500, gray300, gray400, green100, green600, gray700, gray600, gray200 } from '../../utils/colors';
import getCompanyList from '../../services/CompanyListService';
import Loading from '../loader/Loading';
import getModuleList from '../../services/ModuleListService';
import getPlantList from '../../services/PlantListService';
import getApprovalTemplates from '../../services/ApprovalTemplateService';
import createApprovalTemplate from '../../services/CreateApprovalTemplatService';
import getStageDetails from '../../services/StageDetailsService';
import getAllLeaveType from '../../services/AllLeaveTypeService';
import getUserList from '../../services/UsersService';
import addStageDetails from '../../services/AddStageService';
import getAuthorization from '../../services/AuthorizationService';
import deleteStageDetails from '../../services/DeleteStageService';
import deleteTemplate from '../../services/DeleteTemplateService';
import getStageUsers from '../../services/stageUserService';
import addStageUser from '../../services/AddStageUserService';
import deleteStageUser from '../../services/DeleteStageUserService';
import addTerm from '../../services/AddTermService';
import getTermList from '../../services/TermListService';
import deleteTermService from '../../services/DeleteTermService';
import getIouList from '../../services/IouTypeList';
import getMoveOrderList from '../../services/MoveOrderTypeService';
import getLoanTypeList from '../../services/LoanTypeService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Papa from 'papaparse';
import GetPlantMasterService from '../../services/GetPlantMasterService';
import { baseUrl } from '../../utils/path';
import ApprovalEditTemplateName from '../../services/ApprovalEditTemplateNameService';

const companyUrl = `${baseUrl}getCompanyList`;
const moduleUrl = `${baseUrl}approval_module_types`;
const plantUrl = `${baseUrl}get_plant_details`;
const approvalTemplateUrl = `${baseUrl}approval_templates`;
const createApprovalTemplateUrl = `${baseUrl}create_approval_template`;
const stageDetailsUrl = `${baseUrl}stage_details_of_template`;
const allLeaveTypeUrl = `${baseUrl}leave_type_list_web`;
const userUrl = `${baseUrl}employee_search_by_name_id`;
const addStageUrl = `${baseUrl}insert_stage_approver_emp`;
const authorizationUrl = `${baseUrl}authorization_types_web`;
const deleteStageUrl = `${baseUrl}delete_approval_emp_web`;
const deleteTemplateUrl = `${baseUrl}delete_approval_template_web`;
const stageEmpUrl = `${baseUrl}approval_stage_emp_list`;
const addStageUserurl = `${baseUrl}insert_stage_users_web`;
const deleteStageUserUrl = `${baseUrl}delete_stage_users_web`;
const addTermUrl = `${baseUrl}create_stage_term_web`;
const termListUrl = `${baseUrl}approval_stage_terms_list_web`;
const deleteTermUrl = `${baseUrl}delete_stage_term_web`;
const iouTypeUrl = `${baseUrl}iou_types`;
const moveOrderTypeUrl = `${baseUrl}move_order_type`;
const loanTypeUrl = `${baseUrl}loan_types`;


const psgList = [{ "name": "Head Office", "id": 90 }, { "name": "Outside Head Office", "id": 95 }];
const leaveRatioList = [{ "name": "Equal", "value": "==" }, { "name": "Not Equal", "value": "!=" }, { "name": "Less Than", "value": "<" }, { "name": "Greater Than", "value": ">" }, { "name": "Less or Equal", "value": "<=" }, { "name": "Greater or Equal", "value": ">=" }];
const iouRatioList = [{ "name": "Equal", "value": "==" }, { "name": "Max", "value": ">" }, { "name": "Min", "value": "<" }, { "name": "Less or Equal", "value": "<=" }, { "name": "Greater or Equal", "value": ">=" }];
const moveOrderRatioList = [{ "name": "Equal", "value": "==" }, { "name": "Max", "value": ">" }, { "name": "Min", "value": "<" }, { "name": "Less or Equal", "value": "<=" }, { "name": "Greater or Equal", "value": ">=" }];
const requisitionRatioList = [{ "name": "Equal", "value": "==" }, { "name": "Max", "value": ">" }, { "name": "Min", "value": "<" }, { "name": "Less or Equal", "value": "<=" }, { "name": "Greater or Equal", "value": ">=" }];
const loanRatioList = [{ "name": "Equal", "value": "==" }, { "name": "Max", "value": ">" }, { "name": "Min", "value": "<" }, { "name": "Less or Equal", "value": "<=" }, { "name": "Greater or Equal", "value": ">=" }];

export default function ApprovalSetupPage() {

  let selectedCompanyCode, selectedPalntCode, selectedModuleCode, selectedUserId, selectedAuthorizerId, selectPsgid, selectDocumentIdGolbal;
  let selectedRatioValue;
  let authName;
  const approvalTemplateRef = useRef(null);
  const usersRef = useRef(null);
  const stageSequenceRef = useRef(null);
  const stageStatusRef = useRef('A');
  const userStatusRef = useRef('A');
  const termValueRef = useRef(null);
  const termStatusRef = useRef(null);
  const [createLoading, setCreateLoading] = useState(true);
  const [company, setCompany] = useState([]);
  const [module, setModule] = useState([]);
  const [plant, setPlant] = useState([]);
  const [isPlantLoading, setIsPlantLoading] = useState(true);
  const [isCompanyLoading, setIsCompanyLoading] = useState(true);
  const [templateName, setTemplateName] = useState('');
  const [companyCode, setCompanyCode] = useState('');
  const [plantCode, setPlantCode] = useState('');
  const [moduleCode, setModuleCode] = useState('');
  const [selectedCompanyname, setSelectedCompanyName] = useState('');
  const [selectedPlantname, setSelectedPlantName] = useState('');
  const [selectedModulename, setSelectedModuleName] = useState('');
  const [template, setTemplate] = useState([]); //template niya search
  const [templateFilter, setTemplateFilter] = useState([]); //template niya search
  const [isTemplateLoading, setIsTemplateLoading] = useState(true);
  const [buttonState, setButtonState] = useState('stages');
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [stageDetails, setStageDetails] = useState([]);
  const [isStageLoading, setIsStageLoading] = useState(true);
  const [leaveTypes, setLeaveType] = useState([]);
  const [isLeaveTypeLoading, setIsLeaveTypeLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [isUsersLoading, setIsUsersLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState({});
  const [searchKey, setSearchKey] = useState({});
  let id, username;
  const [userId, setUserId] = useState('');
  const [selectedUserName, setSelectedUserName] = useState('');
  const [sategSequenceCnt, setStageSequenceCnt] = useState('');
  const [sateStatusCnt, setStageStatusCnt] = useState('');
  const [authorizers, setAuthorizers] = useState([]);
  const [authorizerId, setAuthorizerId] = useState('');
  const [authorizerName, setAuthorizerName] = useState('');
  const [psgId, setPsgId] = useState('');
  const [psgName, setPsgName] = useState('');
  const [userStatusCnt, setUserStatusCnt] = useState('A');
  const [stageUsers, setStageUsers] = useState([]);
  const [isStageUserLoading, setIsStageUserLoading] = useState(true);
  const [termValue, setTermValue] = useState('');
  const [termStatus, setTermStatus] = useState('');
  const [selectedTermId, setDocumnetId] = useState('');
  const [selectedDocumnetName, setSelectedLeaveName] = useState('');
  const [selectedTermName, setLeaveTerm] = useState('');
  const [seletedRationName, setSelectedRatioName] = useState('');
  const [ratioValue, setRatioValue] = useState('');
  const [termList, setTermList] = useState([]);
  const [isTermListLoading, setIsTermListLoading] = useState(true);
  const [createTemplateError, setCreateTemplateError] = useState({});
  const [isTmplateErorr, setIsTemplateErorr] = useState(true);

  const [openCloseCreate, setOpenCloseCreate] = useState(false);
  const [stageModal, setStageModal] = useState(false);

  const [addStageError, setAddStageError] = useState({});

  const [userModal, setUserModal] = useState(false);

  const [addUserError, setAddUserError] = useState({});
  const [termModal, setTermModal] = useState(false);
  const [addTermError, setAddTermError] = useState({});
  const [iouTypes, setIouTypes] = useState([]);
  const [isIouTypeLoading, setIsIouTypeLoading] = useState(true);
  const [moveOrderTypes, setMoveOrderTypes] = useState([]);
  const [isMoveOrderTypeLoading, setIsMoveOrderTypeLoading] = useState(true);

  const [lonTypes, setLoanTypes] = useState([]);

  const [isLoanTypeLoading, setIsloanTypeLoading] = useState(true);
  const [showAlertCreateTemplate, setShowAlertCreateTemplate] = useState(false);
  const [showErrorAlertCreateTemplate, setShowErrorAlertCreateTemplate] = useState(false);
  const [uId, setUid] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [userBulkModal, setUserBulkModal] = useState(false);
  const [stageBulkModal, setStageBulkModal] = useState(false);
  const [filter, setFilter] = useState('');
  
  useEffect(() => {
    const id = localStorage.getItem('userId');
    setUid(id);
    getTemplate();
    getPlanMaster();
    stageStatusRef.current.value = "A";
    userStatusRef.current.value = "A";
    setStageStatusCnt("A");
    setUserStatusCnt("A");

  }, []);

  //excel upload

  const [userExcel, setUserExcel] = useState([]);
  const [stageExcel, setStageExcel] = useState([]);
  const [columnUserArray, setColumnUserArray] = useState([]);
  const [columnStageArray, setColumnStageArray] = useState([]);
  const [userValues, setUserValues] = useState([]);
  const [stageValues, setStageValues] = useState([]);

  const [bulkLoading, setBulkLoading] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [isPlantVisible, setIsPlantVisible] = useState(false);

  useEffect(() => {
    const getCompanyName = async () => {
      const result = await getCompanyList(companyUrl);
      setCompanyList(result.items);
      setIsCompanyLoading(false);
    };
    getCompanyName();
  },[] );


  const handleFile = (event) => {
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (result) {
        const columnArray = [];
        const valuesArray = [];
        result.data.map((d) => {
          columnArray.push(Object.keys(d));
          valuesArray.push(Object.values(d));
        });
        setUserExcel(result.data);
        setColumnUserArray(columnArray[0]);
        setUserValues(valuesArray);

      }
    })
  }

  const stageFileRef = useRef(null);
  const userFileRef = useRef(null);

  const handleFileStage = (event) => {
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (result) {
        const columnArray = [];
        const valuesArray = [];
        result.data.map((d) => {
          columnArray.push(Object.keys(d));
          valuesArray.push(Object.values(d));
        });
        setStageExcel(result.data);
        setColumnStageArray(columnArray[0]);
        setStageValues(valuesArray);

      }
    })
  }

  const uploadBulkUser = async () => {
    userFileRef.current.value = null;
    setStageExcel([]);
    setColumnUserArray([]);
    setUserValues([]);

    setBulkLoading(true);
    const list = [];

    for (const e of userValues) {
      for (const v of e) {
        // Pass 'v' to the function
        // Use the 'result' here if needed
        list.push(v)

      }
      const adu = await addStageUser(addStageUserurl, list[0], selectedTemplate.AM_ID, list[1]);

      list.splice(0, list.length);
    }

    setBulkLoading(false);
    stageUser(selectedTemplate.AM_ID);
    setUserBulkModal(false);


  }
  const uploadBulkStage = async () => {
    stageFileRef.current.value = null;
    // event.target.value = '';
    setStageExcel([]);
    setStageValues([]);
setColumnStageArray([]);
    setBulkLoading(true);
    const list = [];

    for (const e of stageValues) {
      for (const v of e) {
        // Pass 'v' to the function
        // Use the 'result' here if needed
        list.push(v)

      }
      // const addStage=await addStageDetails(addStageUrl,userId,list[0],list[2],list[3],list[1],selectedTemplate.AM_ID);
      const addStage = await addStageDetails(addStageUrl, list[1], list[0], list[3], selectedTemplate.MODULE_TYPE, list[2], selectedTemplate.AM_ID);
      list.splice(0, list.length);
    }

    setBulkLoading(false);
    callStageDetails(selectedTemplate.AM_ID);
    
    

    setStageBulkModal(false);


  }

  //excel upload

  const getLoanType = async () => {
    const loan = await getLoanTypeList(loanTypeUrl);

    setLoanTypes(loan.loan_type);
    setIsIouTypeLoading(false);
  }

  const getMoveOrderType = async (id) => {
    const mv = await getMoveOrderList(moveOrderTypeUrl, id);

    setMoveOrderTypes(mv.move_order_type);
    setIsMoveOrderTypeLoading(false);
  }
  const getIou = async (id) => {
    const iou = await getIouList(iouTypeUrl, id);
    setIouTypes(iou.iou_type);
    setIsIouTypeLoading(false);
  }

  //
  const [isStageDeleteWarning, setIsStageDeleteWarning] = useState(false);
  const [stId, setStId] = useState('');
  const stageDeleteWarning = (id) => {
    setIsStageDeleteWarning(true);
    setStId(id);
  }
  const [isUserDeleteWarning,setIsUserDeleteWarning]=useState(false);
  const [stUId,setStUId]=useState('');
  const userDeleteWarning=(id)=>{
    setIsUserDeleteWarning(true);
    setStUId(id);
  }

  const validateAddterm = () => {
    const addTermError = {};
    if (selectedDocumnetName === '') {
      addTermError.selectedLeaveName = "Select Document";
    }
    if (selectedTermName === '') {
      addTermError.leaveTerm = "Select Term";
    }
    if (seletedRationName === '') {
      addTermError.seletedRationName = "Select a ratio";
    }
    if (!termValue.trim()) {
      addTermError.termValue = "Please Enter value";
    }
    if (!termStatus.trim()) {
      addTermError.termStatus = "Please Enter Status";
    }
    setAddTermError(addTermError);

    return Object.keys(addTermError).length === 0;
  }

  const validateAddUser = () => {
    const addUserError = {};
    if (selectedUserName === '') {
      addUserError.selectedUserName = "Please Select User";
    }
    if (!userStatusCnt.trim()) {
      addUserError.userStatusCnt = "Please Enter Status";
    }
    setAddUserError(addUserError);

    return Object.keys(addUserError).length === 0;

  }

  const validateAddStage = () => {
    const addStageError = {};
    if (!sategSequenceCnt === '') {
      addStageError.sategSequenceCnt = "Please Enter Sequence";
    }
    // if(!sateStatusCnt.trim()){
    //   addStageError.sateStatusCnt="Please Enter Status";
    // }
    if (selectedUserName === '') {
      addStageError.selectedUserName = "Please Select User";
    }
    if (authorizerName === '') {
      addStageError.authorizerName = "Please Select Authorizer Type";
    }

    setAddStageError(addStageError);

    return Object.keys(addStageError).length === 0;

  }


  const validateCreateTemplate = () => {
    const createTemplateError = {};

    if (!templateName.trim()) {
      createTemplateError.templateName = "Template Name is Required";
    }
    if (selectedCompanyname === '') {
      createTemplateError.selectedCompanyname = "Company is Required";
    }
    if (selectedPlantname === '') {
      createTemplateError.selectedPlantname = "Plant is Required";
    }
    if (selectedPlantname === '') {
      createTemplateError.selectedPlantname = "Plant is Required";
    }
    if (selectedModulename === '') {
      createTemplateError.selectedModulename = "Module is Required";
    }
    if (psgName === '') {
      createTemplateError.psgName = "Rule is Required";
    }

    setCreateTemplateError(createTemplateError);

    return Object.keys(createTemplateError).length === 0;

  }

  const deleteTerm = async (atId) => {
    const del = await deleteTermService(deleteTermUrl, atId);
    setIsTermListLoading(true);
    setTermList([]);
    getTerm(selectedTemplate.AM_ID, uId);
  }

  const getTerm = async (amId, uId) => {
    const term = await getTermList(termListUrl, amId, uId);

    setTermList(term.TERMS_DETAIL_LIST);
    setIsTermListLoading(false);

  }

  const insertTerm = async () => {
    if (validateAddterm()) {
      const ins = await addTerm(addTermUrl, selectedTemplate.AM_ID, selectedTermName, ratioValue, termValue, termStatus, selectedTermId);
      setTermModal(false);
      setIsTermListLoading(true);
      setTermList([]);
      getTerm(selectedTemplate.AM_ID, uId);
    }
    else {
      setTermModal(true);
    }
  }
  const onAddTermCancel = () => {
    setTermValue('');
    termValueRef.current.value = "";
    setTermStatus('');
    termStatusRef.current.value = "";
    setSelectedLeaveName('');
    setDocumnetId('');
    setLeaveTerm('');
    setSelectedRatioName('');
    setRatioValue('');
  }

  const delStageUser = async (aoId) => {
    const delU = await deleteStageUser(deleteStageUserUrl, aoId);
    setIsStageUserLoading(true);
    setStageUsers([]);
    stageUser(selectedTemplate.AM_ID);
    setIsUserDeleteWarning(false);
  }

  //add user warning

  const [isAddUserWarningModal,setIsAddUserWarningModal]=useState(false);

  const addUserWarningModal=()=>{
    if (validateAddUser()) {
      setIsAddUserWarningModal(true);
    }
    else {
      setUserModal(true);
    }
  }
//working

const showToastError = (message) => {
  toast.error(message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT }); // autoClose is in milliseconds
};
  const insertStageUser = async () => {
   
      const adu = await addStageUser(addStageUserurl, userId, selectedTemplate.AM_ID, userStatusCnt);
    
     if(adu.message){
      showToastError(adu.message);
     }
      setUserModal(false);
      setIsStageUserLoading(true);
      setStageUsers([]);
      stageUser(selectedTemplate.AM_ID);
   
  }


  const stageUser = async (amId) => {
    const stu = await getStageUsers(stageEmpUrl, amId);
    console.log("state users:", stu);
    setStageUsers(stu.USERES_LIST);
    setIsStageUserLoading(false);
  }
// delete template warning
  const [isDeleteTemplateWarningModal,setIsDeleteTemplateWarningModal]=useState(false);
  const [templateAmId,setTemplateAmId]=useState('');
  const deleteTemplateWrning=(id)=>{
    setIsDeleteTemplateWarningModal(true);
    setTemplateAmId(id);

  }

  const deleteTem = async (templateId) => {
    const dt = await deleteTemplate(deleteTemplateUrl, templateId);
    setIsTemplateLoading(true);
    setTemplate([]);
    getTemplate();
    setIsDeleteTemplateWarningModal(false);

  }

  const deleteStage = async (stId) => {
    const deleteStage = await deleteStageDetails(deleteStageUrl, stId);
    setIsStageLoading(true);
    setStageDetails([]);
    callStageDetails(selectedTemplate.AM_ID);
    setIsStageDeleteWarning(false);

  }

  const [informerError, setInformerError] = useState(false);

  //add stage waring
  const [isAddStageWarningModal,setIsAddStageWarningModal]=useState(false);
  const addStageWarningShow=()=>{
    if(validateAddStage()){
      setIsAddStageWarningModal(true);
    }
    else{
      setStageModal(true);
    }
   
  }

  const addStage = async () => {
    
      if (authorizerId === '13') {
        if (stageDetails.length === 0) {
          const addStage = await addStageDetails(addStageUrl, userId, sategSequenceCnt, sateStatusCnt, selectedTemplate.MODULE_TYPE, authorizerId, selectedTemplate.AM_ID);
          if(addStage.Message ==="Same Combination Already Exist"){
            showToastError(addStage.Message);
           }
          setStageModal(false);
          setIsStageLoading(true);
          setStageDetails([]);
          callStageDetails(selectedTemplate.AM_ID);
          setInformerError(false);
        }
        else {
          setStageModal(true);
          setInformerError(true);
        }
      } else {
        const addStage = await addStageDetails(addStageUrl, userId, sategSequenceCnt, sateStatusCnt, selectedTemplate.MODULE_TYPE, authorizerId, selectedTemplate.AM_ID);
        if(addStage.Message==="Same Combination Already Exist"){
          showToastError(addStage.Message);
         }
        setStageModal(false);
        setIsStageLoading(true);
        setStageDetails([]);
        callStageDetails(selectedTemplate.AM_ID);
        setInformerError(false);
      }
   
  }

  const getAuthorizers = async () => {
    const authorizers = await getAuthorization(authorizationUrl);
    setAuthorizers(authorizers.AUTHORIZATION_TYPES_WEB);
  }



  const onCancelStageAddUpdate = () => {
    // setStageSequenceCnt('');
    //  stageSequenceRef.current.value='';
    // setStageStatusCnt(''); 
    // stageStatusRef.current.value=''; 
    setSelectedUser({});
    setSelectedUserName('');
    setAuthorizerId('');
    setAuthorizerName('');
  }
  const getUsers = async () => {
    if (!isNaN(searchKey)) {
      id = searchKey;
    }
    else {
      username = searchKey;
    }
    const users = await getUserList(userUrl, id, username);
    setUsers(users.EMPLOYEE_LIST);
    setIsUsersLoading(false);
    setSearchKey('');
    usersRef.current.value = '';
  }

  const getLeaveType = async () => {
    const leave = await getAllLeaveType(allLeaveTypeUrl);

    setLeaveType(leave.LEAVE_TYPES_WEB);
    setIsLeaveTypeLoading(false);
  }
  const callStageDetails = async (amId) => {
    const details = await getStageDetails(stageDetailsUrl, amId);
    const sorted = details.STAGE_DETAILS.sort((a, b) => a.STAGE_SEQ - b.STAGE_SEQ);
    setStageDetails(sorted);
    stageSequenceRef.current.value = sorted.length + 1;
    setStageSequenceCnt(sorted.length + 1);
    getAuthorizers();
    setIsStageLoading(false);
  }


// create template warning

const [isCreateTemplateModal,setIsCreateTemplateModal]=useState(false);
const showTemplateCreateWarning=()=>{
  if (validateCreateTemplate()) {

  setIsCreateTemplateModal(true);
  }
  else {
    console.log("validation false");
    console.log(isTmplateErorr);
    setOpenCloseCreate(true);
  }
}
  const createTemplate = async () => {
   

      setIsTemplateErorr(false);
      setOpenCloseCreate(false);
      console.log(`validate: ${isTmplateErorr}`);
      const res = await createApprovalTemplate(createApprovalTemplateUrl, templateName, companyCode, plantCode, moduleCode, psgId);
      if (res.AM_ID != null) {
        getTemplate();
        setShowAlertCreateTemplate(true);

      } else {
        getTemplate();
        setShowErrorAlertCreateTemplate(true);
      }

      //  isCreateTem=false;

    
   
  }

  const getTemplate = async () => {
    setTemplate([]);
    setTemplateAfterPlantSelection([]);
    setTemplateAfterFunctionSelection([]);
    setIsTemplateLoading(true);

    const tem = await getApprovalTemplates(approvalTemplateUrl);

    if (isFilter === true) {
      const filteredList = tem.TEMPLATE_DETAILS.filter((item) => {
        return item.PLANT_CODE.toLowerCase().includes(dropVal);
      });

      const selectedValue = dropVal2;
      const filteredList2 = filteredList.filter((item) => {
        const moduleName = item.MODULE_TYPE_NAME.toLowerCase();
        if (selectedValue === "leave reimbursement") {
          return moduleName === "leave reimbursement";
        } else if (selectedValue === "leave") {
          return moduleName === "leave";
        } else if (selectedValue === "move order") {
          return moduleName === "move order";
        } else if (selectedValue === "transport move order") {
          return moduleName === "transport move order";
        } else {
          return moduleName.includes(selectedValue);
        }
      });

      const inputValue = searchInput;
      const filteredList3 = filteredList2.filter((item) =>
        item.APPROVAL_STAGE_NAME.toLowerCase().includes(inputValue) ||
        item.MODULE_TYPE_NAME.toLowerCase().includes(inputValue) ||
        item.COMPANY_NAME.toLowerCase().includes(inputValue) ||
        item.COMPANY_CODE.toLowerCase().includes(inputValue) ||
        item.PLANT_NAME.toLowerCase().includes(inputValue) ||
        item.PLANT_CODE.toLowerCase().includes(inputValue)
      );

      setTemplate(filteredList3);
      setTemplateAfterPlantSelection(filteredList);
      setTemplateAfterFunctionSelection(filteredList2);
      setIsTemplateLoading(false);
      setIsTemplateErorr(true);
    } else {
      setTemplate(tem.TEMPLATE_DETAILS);
      setTemplateFilter(tem.TEMPLATE_DETAILS);
      setIsTemplateLoading(false);
      setIsTemplateErorr(true);
    }
  }


  // const getTemplate=async()=>{
  //   setTemplate([]);
  //   setTemplateAfterPlantSelection([]);
  //   setTemplateAfterFunctionSelection([]);
  //   setIsTemplateLoading(true);
  //   const tem=await getApprovalTemplates(approvalTemplateUrl);
  //  console.log(isFilter);
  //   if(isFilter===true){

  //       const filteredList = templateFilter.filter((item) => {
  //           return (
  //             item.PLANT_CODE.toLowerCase().includes(dropVal)
  //           );         
  //       });

  //       setTemplate(filteredList);
  //       setTemplateAfterPlantSelection(filteredList);
  //       const selectedValue=dropVal2;
  //       const filteredList2 = templateAfterPlantSelection.filter((item) => {
  //         const moduleName = item.MODULE_TYPE_NAME.toLowerCase();

  //         if (selectedValue === "leave reimbursement") {
  //           return moduleName === "leave reimbursement";
  //         } else if (selectedValue === "leave") {
  //           return moduleName === "leave";
  //         }
  //         else if (selectedValue === "move order") {
  //           return moduleName === "move order";
  //         } 
  //         else if (selectedValue === "transport move order") {
  //           return moduleName === "transport move order";
  //         } 

  //         else {
  //           return (

  //             moduleName.includes(selectedValue)
  //           );
  //         }
  //       });

  //       setTemplate(filteredList2);
  //       setTemplateAfterFunctionSelection(filteredList2);
  //       const inputValue=searchInput;
  //       const filteredList3 = templateAfterFunctionSelection.filter(
  //         (item) =>
  //           item.APPROVAL_STAGE_NAME.toLowerCase().includes(inputValue) ||
  //           item.MODULE_TYPE_NAME.toLowerCase().includes(inputValue) ||
  //           item.COMPANY_NAME.toLowerCase().includes(inputValue) ||
  //           item.COMPANY_CODE.toLowerCase().includes(inputValue) ||
  //           item.PLANT_NAME.toLowerCase().includes(inputValue) ||
  //           item.PLANT_CODE.toLowerCase().includes(inputValue) 


  //       );
  //       setTemplate(filteredList3);
  //       setIsTemplateLoading(false);
  //     setIsTemplateErorr(true);




  //   }
  //   else{
  //     setTemplate(tem.TEMPLATE_DETAILS);
  //     setTemplateFilter(tem.TEMPLATE_DETAILS);
  //     setIsTemplateLoading(false);
  //     setIsTemplateErorr(true);
  //   }




  // }

  //search korte hbe
  let inputValue = '';
  const handleSearch = (event) => {
    inputValue = event.target.value.toLowerCase();

    if (inputValue === '') {
      setTemplate(templateAfterFunctionSelection);
    }
    else {
      const filteredList = templateAfterFunctionSelection.filter(
        (item) =>
          item.APPROVAL_STAGE_NAME.toLowerCase().includes(inputValue) ||
          item.MODULE_TYPE_NAME.toLowerCase().includes(inputValue) ||
          item.COMPANY_NAME.toLowerCase().includes(inputValue) ||
          item.COMPANY_CODE.toLowerCase().includes(inputValue) ||
          item.PLANT_NAME.toLowerCase().includes(inputValue) ||
          item.PLANT_CODE.toLowerCase().includes(inputValue)


      );
      setTemplate(filteredList);
    }

    setSearchInput(inputValue);

  };


  const [dropVal, setDropVal] = useState('');
  const [dropVal2, setDropVal2] = useState('');
  // let dropVal='';


  const [isFilter, setIsFilter] = useState(false);
  // const filtered=()=>{
  //   if(filter==='company'){
  //     getCompany();
  //   }
  //   else if(filter ==='plant'){
  //     getPlanMaster();
  //   }

  // }

  const [plantMaster, setPlantMaster] = useState([]);
  const [isPlantMasterLoading, setIsPlantMasterLoading] = useState(false);


  const getPlanMaster = async () => {
    setIsPlantMasterLoading(true);
    const re = await GetPlantMasterService();
    setPlantMaster(re.plant_list);
    setIsPlantMasterLoading(false);
  }

  const [vv, setVv] = useState('');
  const [templateAfterPlantSelection, setTemplateAfterPlantSelection] = useState([]);
  const [plantName, setPlantName] = useState('');
  const handleDropDown = (event) => {
    const selectedValue = event.target.value.toLowerCase();
    console.log(selectedValue);

    setIsFilter(true);
    setVv(selectedValue);

    if (dropVal2 !== '') {
      setDropVal2('');
    }

    if (selectedValue === '') {
      setTemplate(templateFilter);
    } else {
      const filteredList = templateFilter.filter((item) => {
        const moduleName = item.MODULE_TYPE_NAME.toLowerCase();


        if (selectedValue === "leave reimbursement") {
          return moduleName === "leave reimbursement";
        } else if (selectedValue === "leave") {
          return moduleName === "leave";
        } else if (selectedValue === "move order") {
          return moduleName === "move order";
        } else if (selectedValue === "transport move order") {
          return moduleName === "transport move order";
        } else {
          return (
            item.PLANT_CODE.toLowerCase().includes(selectedValue)
          );
        }
      });

      setTemplate(filteredList);
      setTemplateAfterPlantSelection(filteredList);
    }

    setDropVal(selectedValue);
  };


  const [templateAfterFunctionSelection, setTemplateAfterFunctionSelection] = useState([]);

  const handleDropDown2 = (event) => {
    const selectedValue = event.target.value.toLowerCase();
    // setIsFilter(true);
    setVv(selectedValue); // Update vv with the selected value


    if (selectedValue === '') {
      setTemplate(templateAfterPlantSelection);
    } else {
      const filteredList = templateAfterPlantSelection.filter((item) => {
        const moduleName = item.MODULE_TYPE_NAME.toLowerCase();

        if (selectedValue === "leave reimbursement") {
          return moduleName === "leave reimbursement";
        } 
        else if (selectedValue === "leave") {
          return moduleName === "leave";
        }
        else if (selectedValue === "move order") {
          return moduleName === "move order";
        }
        else if (selectedValue === "transport move order") {
          return moduleName === "transport move order";
        }
        else if (selectedValue === "employee requisition") {
          return moduleName === "requisition general";
        }
        else if (selectedValue === "employee requisition it") {
          return moduleName === "requisition it";
        }
        else if (selectedValue === "employee requisition heavy eqp") {
          return moduleName === "requisition heavy equipment";
        }
        else if (selectedValue === "employee requisition supply chain") {
          return moduleName === "requisition supply chain";
        }

        else {
          return (
            item.APPROVAL_STAGE_NAME.toLowerCase().includes(selectedValue) ||
            item.COMPANY_NAME.toLowerCase().includes(selectedValue) ||
            item.COMPANY_CODE.toLowerCase().includes(selectedValue) ||
            item.PLANT_NAME.toLowerCase().includes(selectedValue) ||
            item.PLANT_CODE.toLowerCase().includes(selectedValue) ||
            moduleName.includes(selectedValue)
          );
        }
      });

      setTemplate(filteredList);
      setTemplateAfterFunctionSelection(filteredList);
    }

    setDropVal2(selectedValue);

  };

  const getCompany = async () => {
    const result = await getCompanyList(companyUrl);
    console.log("company=:", result);
    setCompany(result.items);
    setIsCompanyLoading(false);
  }

  const getModule = async () => {
    const data = await getModuleList(moduleUrl);
    setModule(data.MODULE_DETAILS);
  }

  const getPlant = async (code) => {

    const plant = await getPlantList(plantUrl, code);
    console.log("plant:=", plant);
    setPlant(plant.PLANT_DETAILS);

    setIsPlantLoading(false);

  }

  const getCreateModal = () => {
    getCompany();
    getModule();
    setCreateLoading(false);
  }

  const handleCompany = (e) => {
    const selectedCode = e.target.value;
    const selectedName = companyList.find(c => c.company_code === selectedCode)?.company_name || '';
    
    if (approvalTemplateRef.current) {
      approvalTemplateRef.current.value = selectedName;
    }

    setTemplateName(selectedName);
    setCompanyCode(selectedCode);
    setSelectedCompanyName(selectedName);
    getPlant(selectedCode);
    setIsPlantVisible(true);
  };

  const handlePlant = (e) => {
    handleDropDown(e);
    setPlantName(e.PLANT_NAME);

    const selectedPlantCode = e.target.value;
    setPlantCode(selectedPlantCode);

    const selectedName = plant.find(c => c.PLANT_CODE === selectedPlantCode)?.PLANT_NAME || '';
    setSelectedPlantName(selectedName);

    if (approvalTemplateRef.current) {
      approvalTemplateRef.current.value = approvalTemplateRef.current.value + "_" + selectedName;
      const name = approvalTemplateRef.current.value;
      setTemplateName(name);
    }
  };

  // edited stage/template name
  const [isModalOpen, setModalOpen] = useState(false);
  const [editedApprovalStageName, setEditedApprovalStageName] = useState('');
  const [isSecondModalOpen, setSecondModalOpen] = useState(false);

  const handleClick =  (approvalStageName) => {
    setModalOpen(true);

    setEditedApprovalStageName(approvalStageName);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSecondModalOpen(false);
  };

  const handleSaveChanges = async () => {

    const result = await ApprovalEditTemplateName(uId, editedApprovalStageName, selectedTemplate.AM_ID);
    
    console.log("edited result: ", result);
    
    const tem = await getApprovalTemplates(approvalTemplateUrl);
  
      if (isFilter === true) {
        const filteredList = tem.TEMPLATE_DETAILS.filter((item) => {
          return item.PLANT_CODE.toLowerCase().includes(dropVal);
        });
  
        const selectedValue = dropVal2;
        const filteredList2 = filteredList.filter((item) => {
          const moduleName = item.MODULE_TYPE_NAME.toLowerCase();
          if (selectedValue === "leave reimbursement") {
            return moduleName === "leave reimbursement";
          } else if (selectedValue === "leave") {
            return moduleName === "leave";
          } else if (selectedValue === "move order") {
            return moduleName === "move order";
          } else if (selectedValue === "transport move order") {
            return moduleName === "transport move order";
          } else {
            return moduleName.includes(selectedValue);
          }
        });
  
        const inputValue = searchInput;
        const filteredList3 = filteredList2.filter((item) =>
          item.APPROVAL_STAGE_NAME.toLowerCase().includes(inputValue) ||
          item.MODULE_TYPE_NAME.toLowerCase().includes(inputValue) ||
          item.COMPANY_NAME.toLowerCase().includes(inputValue) ||
          item.COMPANY_CODE.toLowerCase().includes(inputValue) ||
          item.PLANT_NAME.toLowerCase().includes(inputValue) ||
          item.PLANT_CODE.toLowerCase().includes(inputValue)
        );
  
        setTemplate(filteredList3);
        setTemplateAfterPlantSelection(filteredList);
        setTemplateAfterFunctionSelection(filteredList2);
        setIsTemplateLoading(false);
        setIsTemplateErorr(true);
      } else {
        setTemplate(tem.TEMPLATE_DETAILS);
        setTemplateFilter(tem.TEMPLATE_DETAILS);
        setIsTemplateLoading(false);
        setIsTemplateErorr(true);
      }

      // Close the existing modal by toggling the checkbox
      const modalCheckbox = document.getElementById("my-modal-5");
      if (modalCheckbox) {
        modalCheckbox.checked = false;
      }
      

    handleCloseModal();
  };

  // const handleClick = (template) => {
  //   console.log(template);
  //   // navigate('/add-fence', { state: { fence } }); // Pass the props as state
  // };

  return (
    <div className=' w-full   my-20 lg:pl-8 lg:pr-8 md:pl-4 md:pr-8 pl-4 pr-4  flex justify-center items-center bg-white '>
      {/* The button to open modal */}
      {/* <label onClick={getTemplate} htmlFor="my-modal-3" className="btn bg-green-600 border-none">Approval Template</label>
        {/* Put this part before </body> tag */}
      {/* <input type="checkbox" id="my-modal-3" className="modal-toggle" /> */}
      {/* <div className="modal ">
 
    </div> */}
    
    <ToastContainer />
      <div className=" bg-white  w-full  ">
        {/* <label htmlFor="my-modal-3" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label> */}
        <div className=''>
          <h3 className="text-lg font-bold text-black ">Approval Template: </h3>

          <div className="h-5"></div>

          <div className="flex items-center space-x-3">
            {/* <h3 className="text-lg font-bold text-black ">Approval Template: </h3> */}
            <div className="w-1/3">
              {
                <select
                  placeholder='Select Company'
                  value={companyCode}
                  onChange={handleCompany}
                  className="select select-bordered w-full focus:outline-none mb-2 bg-white"
                >
                  <option value='' disabled selected>Select Company</option>
                  {
                    companyList.map((e, index) =>
                      <option key={index} value={e.company_code}>{e.company_name}({e.company_code}) </option>
                    )
                  }
                </select>
              }
            </div>

            <div className="w-1/3">
              { isPlantMasterLoading ? (
                <span className="loading loading-ring loading-md"></span>
              ) : isPlantVisible && (
                <select
                  value={plantCode}
                  onChange={handlePlant}
                  className="select select-bordered w-full focus:outline-none mb-2 bg-white"
                >
                  <option value="" disabled selected>Select Plant</option>
                  {
                    isPlantLoading ?
                      <p>loading.....</p>
                      : plant.map((e, index) =>
                        <option value={e.PLANT_CODE} key={index}>{e.PLANT_NAME}({e.PLANT_CODE})</option>
                      )
                  }

                </select>
              )
              }
            </div>

            <div className="w-1/3">

              {
                isFilter ?
                  <div>
                    {
                      //   filter==='company'?
                      //     <select value={dropVal} onChange={handleDropDown}  className="select select-bordered w-60">

                      // <option value={''} disabled >Select Company</option>

                      // {company.map((e,i)=>(
                      //   <option value={e.company_code}>{e.company_name} ({e.company_code})</option>
                      // ))}
                      // </select>

                      // :

                      // <select value={dropVal2} onChange={handleDropDown2} className="select select-bordered focus:outline-none w-full mb-2">

                      //   <option value={''} disabled >Select Function</option>
                      //   <option value={'iou'}>IOU</option>
                      //   <option value={'leave'}>Leave</option>
                      //   <option value={'loan'}>Loan</option>
                      //   <option value={'move order'}>Move Order</option>
                      //   <option value={'leave reimbursement'}>Leave Reimbursement</option>
                      //   <option value={'complain'}>Complain</option>
                      //   <option value={'employee requisition'}>Employee Requisition</option>
                      //   <option value={'transport move order'}>Transport Move Order</option>
                      //   <option value={'employee requisition it'}>Employee Requisition IT</option>
                      //   <option value={'employee requisition heavy eqp'}>Employee Requisition Heavy Eqp</option>
                      //   <option value={'employee requisition supply chain'}>Employee Requisition Supply Chain</option>
                      // </select>

                      <select value={dropVal2} onChange={handleDropDown2} className="select select-bordered focus:outline-none w-full mb-2 bg-white">

                        <option value={''} disabled >Select Function</option>
                        <option value={'leave'}>Leave</option>
                        <option value={'leave reimbursement'}>Leave Reimbursement</option>
                        <option value={'move order'}>Move Order</option>
                        <option value={'transport move order'}>Transport Move Order</option>
                        <option value={'iou'}>IOU</option>
                        <option value={'loan'}>Loan</option>
                        <option value={'complain'}>Complain</option>
                        <option value={'employee requisition'}>Requisition General</option>
                        <option value={'employee requisition it'}>Requisition IT</option>
                        <option value={'employee requisition heavy eqp'}>Requisition Heavy Equipment</option>
                        <option value={'employee requisition supply chain'}>Requisition Supply Chain</option>
                      </select>
                    }
                  </div>
                :
                null
              }
            </div>
          </div>

          {/* <input
            type="text"

            onChange={handleSearch}
            placeholder='Search Here....' className=' placeholder:text-sm h-12 w-60 px-4 focus:outline-none border-[1px] border-gray-300 rounded-[8px]' /> */}

        </div>

        <div className=' w-full h-[0.3px] bg-gray-200 my-2'></div>
          <div className=' flex flex-row w-full h-13 justify-between items-center p-1'>
            <div>
              { isFilter ?
                  <input type="text" onChange={handleSearch} placeholder='Search Here....' className=' placeholder:text-sm h-12 w-60 px-4 focus:outline-none border-[1px] bg-white border-gray-300 rounded-[8px]' />
                : 
                null 
              }
            </div>

            <div>  
              <label onClick={() => { setOpenCloseCreate(true); getCreateModal() }} htmlFor="my-modal-4" className={` w-auto h-8 flex justify-center items-center text-xs font-bold bg-buttonColor rounded-md px-4 text-white hover:bg-gray-700 cursor-pointer`}>CREATE</label>
            </div>
          </div>
        <div className=' w-full h-[0.3px] bg-gray-200 my-2'></div>

        {
          isTemplateLoading ?
            <Loading />
            :
            <div className="overflow-x-auto w-full">
              <table className="w-full bg-white border-[0.5px] border-gray-200">
                {/* head */}
                <thead className='bg-bgTableHeader text-tableHeaderText w-full h-14'>
                  <tr>
                    <td className='px-6 py-3'> </td>
                    <th className='px-6 py-3 text-left'>TEMPLATE NAME</th>
                    <th className='px-6 py-3 text-left'>COMPANY NAME</th>
                    <th className='px-6 py-3 text-left'>PLANT NAME</th>
                    <th className='px-6 py-3 text-left'>FUNCTION NAME</th>
                    <th className='px-6 py-3 text-left'>WS Rule</th>
                  </tr>
                </thead>
                <tbody className='bg-white w-full divide-y divide-borderColor'>

                  {
                    template.map((e, index) =>
                      <tr key={index}>
                        <td className='px-6 py-3 text-left'>

                          <label onClick={() => { setSelectedTemplate(e); callStageDetails(e.AM_ID) }} htmlFor="my-modal-5" className="btn btn-sm  relative border-none hover:bg-transparent  right-2 top-0 bg-transparent ">
                            {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 text-amber-400 ">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                            </svg> */}
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-amber-400">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
</svg>

                          </label>
                         

                        </td>
                        <td className='px-6 py-3 text-left whitespace-nowrap'>{e.APPROVAL_STAGE_NAME}</td>
                        <td className='px-6 py-3 text-left whitespace-nowrap'>{e.COMPANY_NAME}{`-(${e.COMPANY_CODE})`}</td>
                        <td className='px-6 py-3 text-left whitespace-nowrap'>{e.PLANT_NAME}{`-(${e.PLANT_CODE})`}</td>
                        <td className='px-6 py-3 text-left whitespace-nowrap'>{e.MODULE_TYPE_NAME}</td>
                        <td className='px-6 py-3 text-left whitespace-nowrap'>{e.PSG === 90 ? "Head Office" : "Outside Head Office"}</td>
                      </tr>

                    )
                  }


                </tbody>
              </table>
            </div>
        }



      </div>

      {/* create modal */}
      {/* Put this part before </body> tag */}
      <input type="checkbox" id="my-modal-4" className="modal-toggle" checked={openCloseCreate} />
      <label htmlFor="my-modal-4" className="modal cursor-pointer">
        <label className="modal-box relative h-[460px] max-w-4xl bg-white" htmlFor="">
          <h3 className="text-lg font-bold mb-2">Add Approval Template</h3>

          {
            createLoading ? <Loading />
              :
              <div>
                <input onChange={(e) => {
                  setTemplateName(e.target.value)
                }} ref={approvalTemplateRef} type="text" placeholder="Name" className="input bg-white input-bordered w-full mb-2 focus:outline-none" />
                {createTemplateError.templateName && <span className={`text-${red500}`}>{createTemplateError.templateName}</span>}


                {
                  <select placeholder='Select Company' value={companyCode}
                    onChange={(e) => {
                      const selectedCode = e.target.value;
                      const selectedName = company.find(c => c.company_code === selectedCode)?.company_name || '';
                      approvalTemplateRef.current.value = selectedName;
                      const name = approvalTemplateRef.current.value;
                      setTemplateName(name);
                      setCompanyCode(selectedCode);
                      setSelectedCompanyName(selectedName);
                      getPlant(selectedCode);

                    }} className="select select-bordered w-full focus:outline-none mb-2 bg-white">
                      <option value='' disabled selected>Select Company</option>
                    {
                      company.map((e, index) =>
                        <option key={index} value={e.company_code}>{e.company_name}({e.company_code})</option>
                      )
                    }
                  </select>
                  }


                {createTemplateError.selectedCompanyname && <span className={`text-${red500}`}>{createTemplateError.selectedCompanyname}</span>}
                {
                  <select value={selectedPlantname}
                    onChange={(e) => {
                      selectedPalntCode = e.target.value;
                      setPlantCode(selectedPalntCode);
                      const selectedName = plant.find(c => c.PLANT_CODE === selectedPalntCode)?.PLANT_NAME || '';
                      setSelectedPlantName(e.PLANT_NAME);
                      approvalTemplateRef.current.value = approvalTemplateRef.current.value + "_" + selectedName;
                      const name = approvalTemplateRef.current.value;
                      setTemplateName(name);
                    }} className="select select-bordered w-full focus:outline-none mb-2 bg-white">
                    <option value="" disabled selected>Select Plant</option>
                    {
                      isPlantLoading ?
                        <p>loading.....</p>
                        : plant.map((e, index) =>
                          <option value={e.PLANT_CODE} key={index}>{e.PLANT_NAME}({e.PLANT_CODE})</option>
                        )
                    }

                  </select>
                }
                {createTemplateError.selectedPlantname && <span className={`text-${red500}`}>{createTemplateError.selectedPlantname}</span>}
                {
                  //   <select value={moduleCode}
                  //    onChange={(e)=>{selectedModuleCode=e.target.value;
                  //     setModuleCode(selectedModuleCode); 
                  //     setSelectedModuleName(e.MODULE_TYPE_NAME)}} className="select select-bordered w-full focus:outline-none mb-2">
                  //   <option value="" disabled selected>Select Function</option>
                  //   {
                  //     module.map((e,index)=>
                  //     <option value={e.MODULE_ID} key={index}>{e.MODULE_TYPE_NAME}</option>
                  //     )
                  //   }
                  // </select>
                  <select
                    value={moduleCode}
                    onChange={(e) => {
                      const selectedModuleCode = e.target.value;
                      const selectedModuleName = e.target.options[e.target.selectedIndex].text;
                      setModuleCode(selectedModuleCode);
                      setSelectedModuleName(selectedModuleName);
                      approvalTemplateRef.current.value = selectedModuleName + "_" + approvalTemplateRef.current.value;
                      const name = approvalTemplateRef.current.value;
                      setTemplateName(name);
                    }}
                    className="select select-bordered w-full focus:outline-none mb-2 bg-white"
                  >
                    <option value="" disabled>Select Function</option>
                    {
                      module.map((e, index) => (
                        <option value={e.MODULE_ID} key={index}>{e.MODULE_TYPE_NAME}</option>
                      ))
                    }
                  </select>

                }
                {createTemplateError.selectedModulename && <span className={`text-${red500}`}>{createTemplateError.selectedModulename}</span>}
                {
                  //   <select value={psgName} onChange={(e)=>{selectPsgid=e.target.value; setPsgId(selectPsgid); setPsgName(e.name)}} className="select select-bordered w-full focus:outline-none mb-2">
                  //   <option value="" disabled selected>Select WS Rule</option>
                  //   {
                  //     psgList.map((e,index)=>
                  //     <option value={e.id} key={index}>{e.name}</option>
                  //     )
                  //   }
                  // </select>
                  <select
                    value={psgId}
                    onChange={(e) => {
                      const selectPsgid = e.target.value;
                      const selectedOption = e.target.options[e.target.selectedIndex];
                      const selectedPsgName = selectedOption.textContent; // This retrieves the text content of the selected option
                      setPsgId(selectPsgid);
                      setPsgName(selectedPsgName);
                      // approvalTemplateRef.current.value = approvalTemplateRef.current.value + "_" + selectedPsgName;
                      // const name = approvalTemplateRef.current.value;
                      // setTemplateName(name);
                    }}
                    className="select select-bordered w-full focus:outline-none mb-2 bg-white"
                  >
                    <option value="" disabled>Select WS Rule</option>
                    {
                      psgList.map((e, index) => (
                        <option value={e.id} key={index}>{e.name}</option>
                      ))
                    }
                  </select>

                }
                {createTemplateError.psgName && <span className={`text-${red500}`}>{createTemplateError.psgName}</span>}



                <div htmlFor="my-modal-4" className=' flex flex-row justify-between '>
                  {/* <button className='modal-action'>
                <div className={`bg-${red500} w-auto px-2 py-1 text-white text-sm `}>
                   <p > CANCEL</p>
                </div>
            </button> */}

                  <div className="modal-action">
                    <label onClick={() => { setOpenCloseCreate(false); setCompanyCode(''); setSelectedCompanyName(''); setPlantCode(''); setSelectedPlantName(''); setModuleCode(''); setSelectedModuleName(''); setTemplateName(''); approvalTemplateRef.current.value = ""; setPsgId(''); setPsgName('') }} className={` bg-${red500} border-none h-10 w-auto flex justify-center items-center shadow-sm rounded-md px-4 text-white font-semibold text-sm hover:bg-${gray700}`}>CANCEL</label>
                  </div>
                  <div className="modal-action">
                    <label 
                    onClick={() => { 
                      showTemplateCreateWarning();
                         }}
                     className={` bg-${green600} border-none h-10 w-auto flex justify-center items-center shadow-sm rounded-md px-4 text-white font-semibold text-sm hover:bg-${gray700}`}>CREATE</label>
                  </div>
                </div>
              </div>

          }
        </label>
      </label>

      {/* end create modal */}


      {/* start edit approval template */}

      <input type="checkbox" id="my-modal-5" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box w-11/12 max-w-7xl h-screen bg-white">
          <label onClick={() => { setStageDetails([]); setStageUsers([]); setButtonState('stages') }} htmlFor="my-modal-5" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
          <h3 className="font-bold text-lg">Approval Template</h3>
          <div className={` w-full h-[0.3px] bg-${gray300} my-2`}>

          </div>

          <div className="overflow-x-auto ">
            <table className="w-full bg-white border-[0.5px] border-gray-200">
              {/* head */}
              <thead className='bg-bgTableHeader text-tableHeaderText w-full h-14'>
                <tr>
                  <td></td>
                  <th className='px-6 py-3 text-left'>Edit</th>
                  <th className='px-6 py-3 text-left'>TEMPLATE NAME</th>
                  <th className='px-6 py-3 text-left'>COMPANY NAME</th>
                  <th className='px-6 py-3 text-left'>PLANT NAME</th>
                  <th className='px-6 py-3 text-left'>FUNCTION NAME</th>
                  <th className='px-6 py-3 text-left'>WS Rule</th>
                </tr>
              </thead>
              <tbody>
                {/* row 1 */}
                <tr>
                  <td>
                    <label 
                    onClick={() => {
                      deleteTemplateWrning(selectedTemplate.AM_ID)
                       }} 
                       htmlFor="my-modal-5" className="btn btn-sm  border-none hover:bg-transparent bg-transparent">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-4 text-red-600">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                      </svg> 
                    </label>
                  </td>
                  
                  <td className='px-6 py-3 text-left whitespace-nowrap'>
                    <label onClick={() => {handleClick(selectedTemplate.APPROVAL_STAGE_NAME)}} htmlFor="my-modal-6" className="btn btn-sm  relative border-none hover:bg-transparent  right-2 top-0 bg-transparent ">
                      {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 text-amber-400 ">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                      </svg> */}
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-gray-600">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                      </svg>

                    </label>


                  </td>
                  <td className='px-6 py-3 text-left whitespace-nowrap'>{selectedTemplate.APPROVAL_STAGE_NAME}</td>
                  <td className='px-6 py-3 text-left whitespace-nowrap'>{selectedTemplate.COMPANY_NAME}</td>
                  <td className='px-6 py-3 text-left whitespace-nowrap'>{selectedTemplate.PLANT_NAME}</td>
                  <td className='px-6 py-3 text-left whitespace-nowrap'>{selectedTemplate.MODULE_TYPE_NAME}</td>
                  <td className='px-6 py-3 text-left whitespace-nowrap'>{selectedTemplate.PSG === 90 ? "Head Office" : "Outside Head Office"}</td>
                </tr>

              </tbody>
            </table>

            {isModalOpen && (
              <div className="fixed z-10 inset-0 overflow-y-auto">
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                  <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                  </div>
                  <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                    &#8203;
                  </span>
                  <div
                    className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-8/12"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline"
                  >
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                      <span className="absolute bg-gray-700 rounded-full top-1 right-1 w-9 h-9 text-lg text-white flex items-center justify-center cursor-pointer" onClick={handleCloseModal}>
                        &#x2715;
                      </span>
                      <label className="block text-gray-700 text-sm font-bold mb-2">Edit Template Name:</label>
                      <input
                        type="text"
                        className="w-full border rounded py-2 px-3 focus:outline-none bg-white"
                        value={editedApprovalStageName}
                        onChange={(e) => setEditedApprovalStageName(e.target.value)}
                      />
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                      <button
                        onClick={handleSaveChanges}
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
                      >
                        Save Changes
                      </button>
                      
                      <button
                        onClick={handleCloseModal}
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className=' flex flex-row space-x-4 p-1 border-[0.1px] border-gray-200 rounded-md  w-48 px-1 mb-6'>
            <button onClick={() => { setButtonState('stages') }} className={`h-10 w-20 rounded-md shadow-sm flex justify-center items-center ${buttonState === 'stages' ? 'bg-green-100' : 'bg-white'} hover:bg-green-200 ${buttonState === 'stages' ? `text-${green600}` : `text-${gray500}`} font-medium text-sm`}>
              STAGES
            </button>
            <button onClick={() => { setButtonState('users'); stageUser(selectedTemplate.AM_ID) }} className={`h-10 w-20 rounded-md shadow-sm flex justify-center items-center ${buttonState === 'users' ? 'bg-green-100' : 'bg-white'} hover:bg-green-200 ${buttonState === 'users' ? `text-${green600}` : `text-${gray500}`} font-medium text-sm`}>
              USERS
            </button>


            {/* <button 
 disabled={selectedTemplate.MODULE_TYPE_NAME==="LEAVE REIMBURSEMENT"?true:false}
 onClick={()=>{setButtonState('terms');
      if(selectedTemplate.MODULE_TYPE_NAME === "LEAVE"){
        getTerm(selectedTemplate.AM_ID,uId);
       getLeaveType();
      }
      else if(selectedTemplate.MODULE_TYPE_NAME ==="IOU"){
        getTerm(selectedTemplate.AM_ID,uId);
        getIou(uId);
      }
      else if(selectedTemplate.MODULE_TYPE_NAME ==="MOVE ORDER"){
        getTerm(selectedTemplate.AM_ID,uId);
        getMoveOrderType(uId);
      }
      else if(selectedTemplate.MODULE_TYPE_NAME ==="LOAN"){
        getTerm(selectedTemplate.AM_ID,uId);
       getLoanType();
      }
      else if(selectedTemplate.MODULE_TYPE_NAME ==="EMPLOYEE REQUISITION"){
        getTerm(selectedTemplate.AM_ID,uId);
      //  getLoanType();
      }
      else if(selectedTemplate.MODULE_TYPE_NAME ==="TRANSPORT MOVE ORDER"){
        getTerm(selectedTemplate.AM_ID,uId);
        getMoveOrderType(uId);
      }
  }} className={`h-10 w-20 rounded-md shadow-sm flex justify-center items-center ${buttonState==='terms'?'bg-green-100':'bg-white'} hover:bg-green-200 ${buttonState==='terms'?`text-${green600}`:`text-${gray500}`} font-medium text-sm`}>
      TERMS
    </button> */}



          </div>

          {(() => {
            switch (buttonState) {
              case 'stages':
                return <div className=' w-full flex flex-col rounded-md border-[0.2px] p-2 border-gray-300'>
                  <div className=' flex flex-row justify-between'>
                    <h3 className={` text-${gray700} font-semibold text-sm`}>Define stages first to final sequentially.</h3>
                    <div className='mr-24 flex flex-row space-x-12 items-center'>

                      <label onClick={() => setStageBulkModal(true)} htmlFor="my-modal" className="btn btn-sm bg-transparent  border-none hover:bg-transparent absolute">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-green-600 mt-1">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M9 8.25H7.5a2.25 2.25 0 00-2.25 2.25v9a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25H15m0-3l-3-3m0 0l-3 3m3-3V15" />
                        </svg>

                      </label>
                      <label onClick={() => setStageModal(true)} htmlFor="my-modal" className="btn btn-sm bg-transparent  border-none hover:bg-transparent absolute">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-amber-400 font-bold mt-[6px]">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>

                      </label>
                    </div>
                  </div>
                  <div className=' my-3 w-full h-[0.2px] bg-gray-300'>

                  </div>

                  {
                    isStageLoading ?
                      <div className=' w-full h-28 flex justify-center items-center'>

                        <h4 className={` text-sm text-${gray600} font-semibold`}>Loading.....</h4>
                      </div>
                      :
                      stageDetails.length === 0 ?
                        <div className=' w-full h-28 flex justify-center items-center'>

                          <h4 className={` text-sm text-${gray600} font-semibold`}>ADD STAGE</h4>
                        </div>
                        :
                        <div className="overflow-x-auto ">
                          <table className="w-full bg-white border-[0.5px] border-gray-200">
                            {/* head */}
                            <thead className='bg-bgTableHeader text-tableHeaderText w-full h-14'>
                              <tr>
                                <th></th>
                                <th className='px-6 py-3 text-left'>Sequence</th>
                                <th className='px-6 py-3 text-left'>Approver Name</th>
                                <th className='px-6 py-3 text-left'>authorization type</th>
                                <th className='px-6 py-3 text-left'>Status</th>

                              </tr>
                            </thead>
                            <tbody>
                              {/* row 1 */}
                              {
                                stageDetails.map((e, index) =>
                                  <tr key={index}>
                                    <td>
                                      <label onClick={() => { stageDeleteWarning(e.ST_ID) }} className="btn btn-sm  border-none hover:bg-transparent bg-transparent">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-4 text-red-600">
                                          <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                        </svg>

                                      </label>
                                    </td>
                                    <td className='px-6 py-3 text-left whitespace-nowrap'>{e.STAGE_SEQ}</td>
                                    <td className='px-6 py-3 text-left whitespace-nowrap'>{e.EMPLOYEE_NAME} {`(${e.EMPLOYEE_ID})`}</td>
                                    <td className='px-6 py-3 text-left whitespace-nowrap'>{e.AUTHORIZER_TYPE}</td>
                                    <td className='px-6 py-3 text-left whitespace-nowrap'>{e.STATUS}</td>

                                  </tr>
                                )
                              }

                            </tbody>
                          </table>
                        </div>
                  }
                </div>;
              case 'terms':
                return <div className={`w-full flex flex-col rounded-md ${selectedTemplate.MODULE_TYPE_NAME === "LEAVE REIMBURSEMENT" ? 'border-none' : 'border-[0.2px] p-2 border-gray-300'} `}>
                  {
                    selectedTemplate.MODULE_TYPE_NAME === "LEAVE REIMBURSEMENT" ? null :
                      <div className=' flex flex-row justify-between'>
                        <h3 className={` text-${gray700} font-semibold text-sm`}>This template will launch if the following terms are true.</h3>

                        <p className='mr-12'>
                          <label onClick={() => setTermModal(true)} htmlFor="my-modal-10" className="btn btn-sm bg-transparent  border-none hover:bg-transparent absolute">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-amber-400 font-bold">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>

                          </label></p>
                      </div>}
                  {
                    selectedTemplate.MODULE_TYPE_NAME === "LEAVE REIMBURSEMENT" ? null :
                      <div className=' my-3 w-full h-[0.2px] bg-gray-300'>

                      </div>
                  }

                  {
                    selectedTemplate.MODULE_TYPE_NAME === "LEAVE REIMBURSEMENT" ? null :

                      isTermListLoading ?
                        <div className=' w-full h-28 flex justify-center items-center'>

                          <h4 className={` text-sm text-${gray600} font-semibold`}>Loading.....</h4>
                        </div>
                        :
                        termList.length === 0 ?
                          <div className=' w-full h-28 flex justify-center items-center'>

                            <h4 className={` text-sm text-${gray600} font-semibold`}>ADD TERM</h4>
                          </div>
                          :

                          <div className="overflow-x-auto ">
                            <table className="w-full bg-white border-[0.5px] border-gray-200">
                              {/* head */}
                              <thead className='bg-bgTableHeader text-tableHeaderText w-full h-14'>
                                <tr>
                                  <th></th>
                                  <th className='px-6 py-3 text-left'>Document Type</th>
                                  <th className='px-6 py-3 text-left'>Term</th>
                                  <th className='px-6 py-3 text-left'>Ratio</th>
                                  <th className='px-6 py-3 text-left'>Value</th>
                                  <th className='px-6 py-3 text-left'>Status</th>

                                </tr>
                              </thead>
                              <tbody>
                                {/* row 1 */}
                                {

                                  termList.map((e, index) =>
                                    <tr key={index}>
                                      <th>
                                        <label onClick={() => deleteTerm(e.AT_ID)} className="btn btn-sm  border-none hover:bg-transparent absolute right-2 top-2 bg-transparent">
                                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-4 text-red-600">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                          </svg>
                                        </label>
                                      </th>
                                      <td className='px-6 py-3 text-left whitespace-nowrap'>{e.DOC_TYPE}</td>
                                      <td className='px-6 py-3 text-left whitespace-nowrap'>{e.TERM}</td>
                                      <td className='px-6 py-3 text-left whitespace-nowrap'>{e.RATIO}</td>
                                      <td className='px-6 py-3 text-left whitespace-nowrap'>{e.VALUE}</td>
                                      <td className='px-6 py-3 text-left whitespace-nowrap'>{e.STATUS}</td>

                                    </tr>

                                  )

                                }

                              </tbody>
                            </table>
                          </div>}
                </div>;
              case 'users':
                return <div className=' w-full flex flex-col rounded-md border-[0.2px] p-2 border-gray-300'>
                  <div className=' flex flex-row justify-between'>
                    <h3 className={` text-${gray700} font-semibold text-sm`}>Only following users have the access to this template.</h3>
                    <div className='mr-24 flex flex-row space-x-12 items-center'>
                      <label onClick={() => { setUserBulkModal(true) }} htmlFor="my-modal-11" className="btn btn-sm bg-transparent  border-none hover:bg-transparent absolute">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-green-600 mt-1">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M9 8.25H7.5a2.25 2.25 0 00-2.25 2.25v9a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25H15m0-3l-3-3m0 0l-3 3m3-3V15" />
                        </svg>


                      </label>
                      <label onClick={() => { setUserModal(true) }} htmlFor="my-modal-11" className="btn btn-sm bg-transparent  border-none hover:bg-transparent absolute">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-amber-400 font-bold mt-[5px]">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>

                      </label>


                    </div>
                  </div>
                  <div className=' my-3 w-full h-[0.2px] bg-gray-300'>

                  </div>

                  {
                    isStageUserLoading ?
                      <div className=' w-full h-28 flex justify-center items-center'>

                        <h4 className={` text-sm text-${gray600} font-semibold`}>Loading.....</h4>
                      </div>
                      :
                      stageUsers.length === 0 ?
                        <div className=' w-full h-28 flex justify-center items-center'>

                          <h4 className={` text-sm text-${gray600} font-semibold`}>ADD USER</h4>
                        </div>
                        :


                        <div className="overflow-x-auto ">
                          <table className="w-full bg-white border-[0.5px] border-gray-200">
                            {/* head */}
                            <thead className='bg-bgTableHeader text-tableHeaderText w-full h-14'>
                              <tr>
                                <th></th>
                                <th className='px-6 py-3 text-left'>USER NAME</th>

                                <th className='px-6 py-3 text-left'>Status</th>

                              </tr>
                            </thead>
                            <tbody>
                              {/* row 1 */}
                              {
                                stageUsers.map((e, index) =>

                                  <tr key={index}>
                                    <th>
                                      <label onClick={() => { userDeleteWarning(e.AO_ID) }} className="btn btn-sm  border-none hover:bg-transparent bg-transparent">
                                        {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-4 text-amber-400 ">
             <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
           </svg> */}
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-4 text-red-600">
                                          <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                        </svg>
                                      </label>
                                    </th>
                                    <td className='px-6 py-3 text-left whitespace-nowrap'>{e.EMPLOYEE_NAME} {`(${e.ORIGINATORS})`}</td>

                                    <td className='px-6 py-3 text-left whitespace-nowrap'>{e.STATUS}</td>

                                  </tr>
                                )
                              }

                            </tbody>
                          </table>
                        </div>

                  }
                </div>;

              default:
                return null
            }
          })()}


        </div>
      </div>
      {/* end edit approval template */}

      {/* start add stage modal */}

      <input type="checkbox" id="my-modal" className="modal-toggle" checked={stageModal} />
      <div className="modal ">
        <div className="modal-box bg-white">
          <label htmlFor="my-modal" onClick={() => { setStageModal(false) }} className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
          <h3 className="font-bold text-lg">Add Approval Stages</h3>
          <div className={` w-full h-[0.2px] bg-${gray300} my-2`}>

          </div>
          <div className=' flex flex-col items-start space-y-4 my-4'>
            <input ref={stageSequenceRef} onChange={(e) => { setStageSequenceCnt(e.target.value) }} type="number" placeholder="Enter Sequence" className="input input-bordered bg-white w-full focus:outline-none   " />
            {/* <div className={` w-full h-12 border-[0.2px] border-${gray300} rounded-md flex text-left justify-center items-center`}>
              <p className={`w-full  pl-6 text-${gray400} text-[16px]`}>{stageDetails.length + 1}</p>
            </div> */}

            {addStageError.sategSequenceCnt && <span className={`text-${red500}`}>{addStageError.sategSequenceCnt}</span>}

            <div className="modal-action w-full bg-white">
              <label htmlFor="my-modal-16" className="w-full border-none bg-transparent hover:bg-transparent">
                <div className={` w-full h-12 border-[0.2px] border-${gray300} rounded-md flex text-left justify-center items-center`}>
                  <p className={`w-full  pl-6 text-${gray400} text-[16px]`}>{selectedUserName === '' ? "Add user" : selectedUserName}</p>
                </div>
              </label>
            </div>
            {addStageError.selectedUserName && <span className={`text-${red500}`}>{addStageError.selectedUserName}</span>}

            <input ref={stageStatusRef} onChange={(e) => { setStageStatusCnt(e.target.value) }} type="text" placeholder="Enter Status" className="input input-bordered bg-white w-full focus:outline-none mb-3  " />
            {addStageError.sateStatusCnt && <span className={`text-${red500}`}>{addStageError.sateStatusCnt}</span>}
            {

              <select placeholder='Select Authorizer Type' value={authorizerName} onChange={(e) => { selectedAuthorizerId = e.target.value; setAuthorizerId(selectedAuthorizerId); authName = e.AUTHORIZER_TYPE; console.log(authName); setAuthorizerName(e.AUTHORIZER_TYPE) }} className="select select-bordered w-full focus:outline-none mb-2 bg-white">
                <option value='' disabled selected>Select Authorizer Type</option>
                {
                  authorizers.map((e, index) =>
                    <option key={index} value={e.AU_ID}>{e.AUTHORIZER_TYPE}</option>
                  )
                }


              </select>

            }
            {addStageError.authorizerName && <span className={`text-${red500}`}>{addStageError.authorizerName}</span>}

          </div>
          {
            informerError && (<p className=' text-red-600 text-sm'>Informer Sequence Has To Be 1</p>)
          }

          <div className=' flex flex-row justify-between'>
            <div className="modal-action">
              <label onClick={() => { setStageModal(false); }} htmlFor="my-modal" className="btn border-none bg-transparent hover:bg-transparent">
                <div className={`bg-${red500} h-8 w-20 text-white justify-center items-center rounded-md shadow-sm`}>
                  <p className=' pt-2'>CANCEL</p>
                </div>
              </label>
            </div>
            <div className="modal-action">
              <label
               onClick={() => { addStageWarningShow() }} 
               htmlFor="my-modal" className="btn border-none bg-transparent hover:bg-transparent">
                <div className={`bg-${green600} h-8 w-20 text-white justify-center items-center rounded-md shadow-sm`}>
                  <p className=' pt-2'>CREATE</p>
                </div>
              </label>


            </div>
          </div>
        </div>
      </div>


      {/* end add stage modal */}

      {/* start add term modal */}
      <input type="checkbox" id="my-modal-10" className="modal-toggle" checked={termModal} />
      <div className="modal ">
        <div className="modal-box bg-white">
          <label htmlFor="my-modal-10" onClick={() => setTermModal(false)} className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
          <h3 className="font-bold text-lg">Add Approval Terms</h3>
          <div className=' flex flex-col items-start my-4'>
            {
              selectedTemplate.MODULE_TYPE_NAME === "LEAVE" ?

                (<select placeholder='Select Document Type' value={selectedDocumnetName} onChange={(e) => { selectDocumentIdGolbal = e.target.value; setDocumnetId(selectDocumentIdGolbal); setSelectedLeaveName(e.OBJECT_SUB_TYPE_CODE) }} className="select select-bordered w-full focus:outline-none mb-2 bg-white">
                  <option value='' disabled selected>Select Document Type</option>
                  {
                    leaveTypes.map((e, index) =>
                      <option key={index} value={e.OBJECT_ID}>{e.OBJECT_SUB_TYPE_CODE}</option>
                    )
                  }


                </select>

                )

                :

                selectedTemplate.MODULE_TYPE_NAME === "IOU" ?

                  (<select placeholder='Select Document Type' value={selectedDocumnetName} onChange={(e) => { selectDocumentIdGolbal = e.target.value; setDocumnetId(selectDocumentIdGolbal); setSelectedLeaveName(e.OBJECT_SUB_TYPE_CODE) }} className="select select-bordered w-full focus:outline-none mb-2 bg-white">
                    <option value='' disabled selected>Select Document Type</option>
                    {
                      iouTypes.map((e, index) =>
                        <option key={index} value={e.IOU_ID}>{e.IOU_NAME}</option>
                      )
                    }


                  </select>

                  )
                  :
                  selectedTemplate.MODULE_TYPE_NAME === "MOVE ORDER" ?
                    <select placeholder='Select Document Type' value={selectedDocumnetName} onChange={(e) => { selectDocumentIdGolbal = e.target.value; setDocumnetId(selectDocumentIdGolbal); setSelectedLeaveName(e.OBJECT_SUB_TYPE_CODE) }} className="select select-bordered w-full focus:outline-none mb-2">
                      <option value='' disabled selected>Select Document Type</option>
                      {
                        moveOrderTypes.map((e, index) =>
                          <option key={index} value={e.MOT_ID}>{e.TYPE_NAME}</option>
                        )
                      }


                    </select>




                    :
                    selectedTemplate.MODULE_TYPE_NAME === "TRANSPORT MOVE ORDER" ?
                      <select placeholder='Select Document Type' value={selectedDocumnetName} onChange={(e) => { selectDocumentIdGolbal = e.target.value; setDocumnetId(selectDocumentIdGolbal); setSelectedLeaveName(e.OBJECT_SUB_TYPE_CODE) }} className="select select-bordered w-full focus:outline-none mb-2">
                        <option value='' disabled selected>Select Document Type</option>
                        {
                          moveOrderTypes.map((e, index) =>
                            <option key={index} value={e.MOT_ID}>{e.TYPE_NAME}</option>
                          )
                        }


                      </select>




                      :
                      selectedTemplate.MODULE_TYPE_NAME === "LOAN" ?
                        <select placeholder='Select Document Type' value={selectedDocumnetName} onChange={(e) => { selectDocumentIdGolbal = e.target.value; setDocumnetId(selectDocumentIdGolbal); setSelectedLeaveName(e.OBJECT_SUB_TYPE_CODE) }} className="select select-bordered w-full focus:outline-none mb-2">
                          <option value='' disabled selected>Select Document Type</option>
                          {
                            lonTypes.map((e, index) =>
                              <option key={index} value={e.LTYPE_ID}>{e.LTYPE_NAME}</option>
                            )
                          }


                        </select>


                        :
                        selectedTemplate.MODULE_TYPE_NAME === "EMPLOYEE REQUISITION" ?
                          <select placeholder='Select Document Type' value={selectedDocumnetName} onChange={(e) => { selectDocumentIdGolbal = e.target.value; setDocumnetId(selectDocumentIdGolbal); setSelectedLeaveName(e.OBJECT_SUB_TYPE_CODE) }} className="select select-bordered w-full focus:outline-none mb-2">
                            <option value='' disabled selected>Select Document Type</option>
                            {

                              <option value="00">Requisition</option>

                            }


                          </select>


                          :

                          null

            }

            {addTermError.selectedLeaveName && <span className={`text-${red500}`}>{addTermError.selectedLeaveName}</span>}


            {
              selectedTemplate.MODULE_TYPE_NAME === "LEAVE" ?
                <select placeholder='Select Term' value={selectedTermName} onChange={(e) => { setLeaveTerm(e.target.value) }} className="select select-bordered w-full focus:outline-none mb-2">
                  <option value='' disabled selected>Select Term</option>

                  <option value="Leave Days">Leave Days</option>



                </select>
                :
                selectedTemplate.MODULE_TYPE_NAME === "IOU" ?

                  <select placeholder='Select Term' value={selectedTermName} onChange={(e) => { setLeaveTerm(e.target.value) }} className="select select-bordered w-full focus:outline-none mb-2">
                    <option value='' disabled selected>Select Term</option>

                    <option value="Iou Amount">Iou Amount</option>



                  </select>


                  :

                  selectedTemplate.MODULE_TYPE_NAME === "MOVE ORDER" ?

                    <select placeholder='Select Term' value={selectedTermName} onChange={(e) => { setLeaveTerm(e.target.value) }} className="select select-bordered w-full focus:outline-none mb-2">
                      <option value='' disabled selected>Select Term</option>

                      <option value="move order Amount">Move Order Amount</option>



                    </select>


                    :
                    selectedTemplate.MODULE_TYPE_NAME === "TRANSPORT MOVE ORDER" ?

                      <select placeholder='Select Term' value={selectedTermName} onChange={(e) => { setLeaveTerm(e.target.value) }} className="select select-bordered w-full focus:outline-none mb-2">
                        <option value='' disabled selected>Select Term</option>

                        <option value="transport move order Amount">Transport Move Order Amount</option>



                      </select>


                      :


                      selectedTemplate.MODULE_TYPE_NAME === "LOAN" ?

                        <select placeholder='Select Term' value={selectedTermName} onChange={(e) => { setLeaveTerm(e.target.value) }} className="select select-bordered w-full focus:outline-none mb-2">
                          <option value='' disabled selected>Select Term</option>

                          <option value="Loan Amount">Loan Amount</option>



                        </select>
                        :
                        selectedTemplate.MODULE_TYPE_NAME === "EMPLOYEE REQUISITION" ?

                          <select placeholder='Select Term' value={selectedTermName} onChange={(e) => { setLeaveTerm(e.target.value) }} className="select select-bordered w-full focus:outline-none mb-2">
                            <option value='' disabled selected>Select Term</option>

                            <option value="Requisition Amount">Requisition Amount</option>



                          </select>
                          :


                          null

            }

            {addTermError.leaveTerm && <span className={`text-${red500}`}>{addTermError.leaveTerm}</span>}

            {
              selectedTemplate.MODULE_TYPE_NAME === "LEAVE" ?
                <select placeholder='Select Ratio' value={seletedRationName} onChange={(e) => { selectedRatioValue = e.target.value; setRatioValue(selectedRatioValue); setSelectedRatioName(e.name) }} className="select select-bordered w-full focus:outline-none mb-2">
                  <option value='' disabled selected>Select Ratio</option>

                  {
                    leaveRatioList.map((e, index) =>
                      <option key={index} value={e.value}>{e.name}</option>
                    )
                  }



                </select>
                :

                selectedTemplate.MODULE_TYPE_NAME === "IOU" ?
                  <select placeholder='Select Ratio' value={seletedRationName} onChange={(e) => { selectedRatioValue = e.target.value; setRatioValue(selectedRatioValue); setSelectedRatioName(e.name) }} className="select select-bordered w-full focus:outline-none mb-2">
                    <option value='' disabled selected>Select Ratio</option>

                    {
                      iouRatioList.map((e, index) =>
                        <option key={index} value={e.value}>{e.name}</option>
                      )
                    }



                  </select>


                  :

                  selectedTemplate.MODULE_TYPE_NAME === "MOVE ORDER" ?
                    <select placeholder='Select Ratio' value={seletedRationName} onChange={(e) => { selectedRatioValue = e.target.value; setRatioValue(selectedRatioValue); setSelectedRatioName(e.name) }} className="select select-bordered w-full focus:outline-none mb-2">
                      <option value='' disabled selected>Select Ratio</option>

                      {
                        moveOrderRatioList.map((e, index) =>
                          <option key={index} value={e.value}>{e.name}</option>
                        )
                      }



                    </select>


                    :
                    selectedTemplate.MODULE_TYPE_NAME === "TRANSPORT MOVE ORDER" ?
                      <select placeholder='Select Ratio' value={seletedRationName} onChange={(e) => { selectedRatioValue = e.target.value; setRatioValue(selectedRatioValue); setSelectedRatioName(e.name) }} className="select select-bordered w-full focus:outline-none mb-2">
                        <option value='' disabled selected>Select Ratio</option>

                        {
                          moveOrderRatioList.map((e, index) =>
                            <option key={index} value={e.value}>{e.name}</option>
                          )
                        }



                      </select>


                      :

                      selectedTemplate.MODULE_TYPE_NAME === "LOAN" ?
                        <select placeholder='Select Ratio' value={seletedRationName} onChange={(e) => { selectedRatioValue = e.target.value; setRatioValue(selectedRatioValue); setSelectedRatioName(e.name) }} className="select select-bordered w-full focus:outline-none mb-2">
                          <option value='' disabled selected>Select Ratio</option>

                          {
                            loanRatioList.map((e, index) =>
                              <option key={index} value={e.value}>{e.name}</option>
                            )
                          }



                        </select>


                        :
                        selectedTemplate.MODULE_TYPE_NAME === "EMPLOYEE REQUISITION" ?
                          <select placeholder='Select Ratio' value={seletedRationName} onChange={(e) => { selectedRatioValue = e.target.value; setRatioValue(selectedRatioValue); setSelectedRatioName(e.name) }} className="select select-bordered w-full focus:outline-none mb-2">
                            <option value='' disabled selected>Select Ratio</option>

                            {
                              requisitionRatioList.map((e, index) =>
                                <option key={index} value={e.value}>{e.name}</option>
                              )
                            }



                          </select>


                          :

                          null

            }

            {addTermError.seletedRationName && <span className={`text-${red500}`}>{addTermError.seletedRationName}</span>}
            <input ref={termValueRef} onChange={(e) => { setTermValue(e.target.value) }} type="number" placeholder="Enter value" className="input input-bordered  w-full focus:outline-none mb-3  " />
            {addTermError.termValue && <span className={`text-${red500}`}>{addTermError.termValue}</span>}
            <input ref={termStatusRef} onChange={(e) => { setTermStatus(e.target.value) }} type="text" placeholder="Enter Status" className="input input-bordered  w-full focus:outline-none mb-3  " />
            {addTermError.termStatus && <span className={`text-${red500}`}>{addTermError.termStatus}</span>}
          </div>
          <div className=' flex flex-row justify-between'>
            <div className="modal-action">
              <label onClick={() => { onAddTermCancel(); setTermModal(false) }} htmlFor="my-modal-10" className="btn border-none bg-transparent hover:bg-transparent">
                <div className={`bg-${red500} h-8 w-20 justify-center items-center rounded-md shadow-sm`}>
                  <p className=' pt-2'>CANCEL</p>
                </div>
              </label>
            </div>
            <div className="modal-action">
              <label onClick={() => { insertTerm(); onAddTermCancel() }} htmlFor="my-modal-10" className="btn border-none bg-transparent hover:bg-transparent">
                <div className={`bg-${green600} h-8 w-20 justify-center items-center rounded-md shadow-sm`}>
                  <p className=' pt-2'>CREATE</p>
                </div>
              </label>


            </div>
          </div>
        </div>
      </div>

      {/* end add term modal */}

      {/* start add  user modal */}
      <input type="checkbox" id="my-modal-11" className="modal-toggle" checked={userModal} />
      <div className="modal bg-white">
        <div className="modal-box bg-white">
          <label htmlFor="my-modal-11" onClick={(e) => { setUserModal(false) }} className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
          <h3 className="font-bold text-lg">Add User </h3>
          <div className=' flex flex-col items-start space-y-4 my-4'>
            {/* <input ref={stageSequenceRef} onChange={(e)=>{setStageSequenceCnt(e.target.value)}} type="number" placeholder="Enter Sequence" className="input input-bordered  w-full focus:outline-none   " /> */}

            <div className="modal-action w-full">
              <label onClick={() => { setSelectedUser({}); setUserId(''); setSelectedUserName('') }} htmlFor="my-modal-16" className="w-full border-none bg-transparent hover:bg-transparent">
                <div className={` w-full h-12 border-[0.2px] border-${gray300} rounded-md flex text-left justify-center items-center`}>
                  <p className={`w-full  pl-6 text-${gray400} text-[16px]`}>{selectedUserName === '' ? "Add user" : selectedUserName}</p>
                </div>
              </label>
            </div>
            {addUserError.selectedUserName && <span className={`text-${red500}`}>{addUserError.selectedUserName}</span>}
            <input ref={userStatusRef} onChange={(e) => { setUserStatusCnt(e.target.value) }} type="text" placeholder="Enter Status" className="input input-bordered bg-white w-full focus:outline-none mb-3  " />
            {addUserError.userStatusCnt && <span className={`text-${red500}`}>{addUserError.userStatusCnt}</span>}

          </div>

          <div className=' flex flex-row justify-between'>
            <div className="modal-action-11">
              <label onClick={() => { setUserModal(false); setSelectedUser({}); setUserId(''); setSelectedUserName('');   }} htmlFor="my-modal-11" className="btn border-none bg-transparent hover:bg-transparent">
                <div className={`bg-${red500} h-8 w-20 justify-center text-white items-center rounded-md shadow-sm`}>
                  <p className=' pt-2'>CANCEL</p>
                </div>
              </label>
            </div>
            <div className="modal-action-11">
              <label
               onClick={() => { 
                addUserWarningModal();
                    }} 
                    
                    htmlFor="my-modal-11" className="btn border-none bg-transparent hover:bg-transparent">
                <div className={`bg-${green600} h-8 w-20 justify-center text-white items-center rounded-md shadow-sm`}>
                  <p className=' pt-2'>CREATE</p>
                </div>
              </label>


            </div>
          </div>
        </div>
      </div>

      {/* end add  user modal */}

      {/* start edit template master */}
      <input type="checkbox" id="my-modal-12" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box bg-white">
          <label htmlFor="my-modal-12" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
          <h3 className="font-bold text-lg">Congratulations random Internet user!</h3>
          <p className="py-4">You've been selected for a chance to get one year of subscription to use Wikipedia for free!</p>
          <div className="modal-action">
            <label htmlFor="my-modal-12" className="btn">Yay!</label>
          </div>
        </div>
      </div>

      {/* end edit template master */}

      {/* start edit stage modal */}
      <input type="checkbox" id="my-modal-13" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box bg-white">

          <label htmlFor="my-modal-13" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
          <h3 className="font-bold text-lg">Edit Approval Stages</h3>
          <div className={` w-full h-[0.2px] bg-${gray300} my-2`}>

          </div>
          <div className=' flex flex-col items-start space-y-4 my-4'>
            {/* <input ref={stageSequenceRef} onChange={(e)=>{setStageSequenceCnt(e.target.value)}} type="number" placeholder="Enter Sequence" className="input input-bordered  w-full focus:outline-none   " /> */}

            <div className="modal-action w-full">
              <label htmlFor="my-modal-16" className="w-full border-none bg-transparent hover:bg-transparent">
                <div className={` w-full h-12 border-[0.2px] border-${gray300} rounded-md flex text-left justify-center items-center`}>
                  <p className={`w-full  pl-6 text-${gray400} text-[16px]`}>{selectedUserName === '' ? "Add user" : selectedUserName}</p>
                </div>
              </label>
            </div>
            {/* <input ref={stageStatusRef} onChange={(e)=>{setStageStatusCnt(e.target.value)}}  type="text" placeholder="Enter Status" className="input input-bordered  w-full focus:outline-none mb-3  " /> */}
            {

              <select placeholder='Select Authorizer Type' value={authorizerName} onChange={(e) => { selectedAuthorizerId = e.target.value; setAuthorizerId(selectedAuthorizerId); setAuthorizerName(e.AUTHORIZER_TYPE) }} className="select select-bordered w-full focus:outline-none mb-2">
                <option value='' disabled selected>Select Authorizer Type</option>
                {
                  authorizers.map((e, index) =>
                    <option key={index} value={e.AU_ID}>{e.AUTHORIZER_TYPE}</option>
                  )
                }


              </select>

            }

          </div>
          <div className=' flex flex-row justify-between'>
            <div className=' flex flex-row space-x-1'>
              <div className="modal-action">
                <label onClick={onCancelStageAddUpdate} htmlFor="my-modal-13" className="btn border-none bg-transparent hover:bg-transparent">
                  <div className={` bg-blue-500 h-8 w-20 justify-center items-center rounded-md shadow-sm`}>
                    <p className=' pt-2'>CANCEL</p>
                  </div>
                </label>
              </div>
              <div className="modal-action">
                <label htmlFor="my-modal-13" className="btn border-none bg-transparent hover:bg-transparent">
                  <div className={`bg-${red500} h-8 w-20 text-white justify-center items-center rounded-md shadow-sm`}>
                    <p className=' pt-2'>DELETE</p>
                  </div>
                </label>
              </div>
            </div>
            <div className="modal-action">
              <label htmlFor="my-modal-13" className="btn border-none bg-transparent hover:bg-transparent">
                <div className={`bg-${green600} h-8 w-20 text-white justify-center items-center rounded-md shadow-sm`}>
                  <p className=' pt-2'>CREATE</p>
                </div>
              </label>
              {/* 9 tarikh sesh korlam */}
              {/* main theke sokal a push */}

            </div>
          </div>
        </div>
      </div>
      {/* end edit stage modal */}

      {/* start edit terms modal */}
      <input type="checkbox" id="my-modal-14" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box bg-white">
          <label htmlFor="my-modal-14" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
          <h3 className="font-bold text-lg">Congratulations random Internet user!</h3>
          <p className="py-4">You've been selected for a chance to get one year of subscription to use Wikipedia for free!</p>
          <div className="modal-action">
            <label htmlFor="my-modal-14" className="btn">Yay!</label>
          </div>
        </div>
      </div>
      {/* end edit terms modal */}

      {/* start edit users modal */}

      <input type="checkbox" id="my-modal-15" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box bg-white">
          <label htmlFor="my-modal-15" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
          <h3 className="font-bold text-lg">Congratulations random Internet user!</h3>
          <p className="py-4">You've been selected for a chance to get one year of subscription to use Wikipedia for free!</p>
          <div className="modal-action">
            <label htmlFor="my-modal-15" className="btn">Yay!</label>
          </div>
        </div>
      </div>

      {/* end edit users modal */}

      {/* start select user modal */} 
      <input type="checkbox" id="my-modal-16" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box h-3/4 bg-white">
          <label onClick={(e) => {
            setUsers([]); setSearchKey('');
            usersRef.current.value = '';
          }} htmlFor="my-modal-16" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
          <h3 className="font-bold text-lg">Select User</h3>
          <div className='w-full flex flex-col justify-center items-center my-3'>
            <div className="form-control">
              <div className="input-group ">
                <input ref={usersRef} onChange={(e) => { setSearchKey(e.target.value) }} onKeyPress={(e) => { if (e.key === 'Enter') getUsers(); }} type="text" placeholder="Search…" className="input input-bordered w-72 focus:outline-none bg-white" />
                <button onClick={getUsers} className="btn btn-square">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" /></svg>
                </button>
              </div>
            </div>

            {

              users.length === 0 ?
                <p className=' mt-16 font-medium text-lg'>Search Name Here</p> :
                isUsersLoading ?
                  <p>loding...</p> :

                  users.map((e, index) =>
                    <div key={index} onClick={(v) => { setSelectedUser(e); selectedUserId = e.EMPLOYEE_ID; setUserId(selectedUserId); setSelectedUserName(e.EMPLOYEE_NAME) }} className="modal-action w-[336px]">
                      <label htmlFor="my-modal-16" className="w-full border-none bg-transparent hover:bg-transparent">
                        <div className={` w-full h-12 shadow-md  rounded-md flex text-left justify-center items-center`}>
                          <p className={`w-full  pl-6 text-${gray600} text-[16px]`}>{`${e.EMPLOYEE_NAME} (${e.POSITION_NAME})`}</p>
                        </div>
                      </label>
                    </div>
                  )

            }

          </div>

        </div>
      </div>

      {/* end select user modal */}

      {/* user bulk modal */}
      <input type="checkbox" id="my-modal-11" className="modal-toggle" checked={userBulkModal} />
      <div className="modal">
        <div className="modal-box relative w-11/12 max-w-7xl h-screen bg-white">
          <label htmlFor="my-modal-11" onClick={(e) => { setUserBulkModal(false); }} className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>

          <div className='mx-8 my-16'>
            <div className=' h-6'></div>
            <h1 className=' text-lg font-medium text-black'>Upload User Excel FIle</h1>
            <div className=' h-6'></div>
            <input ref={userFileRef} type="file" accept='.csv' onChange={handleFile} className="file-input file-input-bordered w-full max-w-xs" />
            <div className=' h-6'></div>
            {
              userValues.length === 0 ?


                <div className=' flex justify-center items-center w-full h-[300px]'>

                  <h1 className=' text-black font-bold text-2xl'>Please Choose File</h1>

                </div>

                :
                <table className=" border-[0.5px] border-gray-200 ">
                  <thead className=" bg-gray-200 shadow-sm h-14">
                    <tr>
                      {
                        columnUserArray.map((col, i) => (
                          <th key={i} className="px-6 py-3 text-left text-sm   text-gray-500 uppercase tracking-wider">{col}</th>
                        ))
                      }







                    </tr>
                  </thead>
                  <tbody className=" divide-y divide-borderColor">
                    {



                      userValues.map((e, index) => {

                        return (
                          <tr key={index} className='bg-white'>

                            {
                              e.map((v, i) => (
                                <td key={i} className="px-6 py-4 whitespace-nowrap">{v}</td>
                              ))
                            }





                          </tr>
                        )
                      })

                    }


                  </tbody>

                </table>
            }
            <div className=' h-6'></div>
            {
              userValues.length === 0 ? null :
                <button onClick={uploadBulkUser} className=' w-36 h-9 flex justify-center items-center rounded-md bg-green-600 text-sm text-white font-semibold'>UPLOAD</button>
            }
            <div className=' h-20'></div>

          </div>





        </div>
      </div>

      {/* end user bulk modal */}

      {/* stage bulk modal */}

      <input type="checkbox" id="my-modal-1199" className="modal-toggle" checked={stageBulkModal} />
      <div className="modal">
        <div className="modal-box relative w-11/12 max-w-7xl h-screen bg-white">
          <label htmlFor="my-modal-1199" onClick={(e) => { setStageBulkModal(false); }} className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>

          <div className='mx-8 my-16'>
            <div className=' h-6'></div>
            <h1 className=' text-lg font-medium text-black'>Upload Stage Excel FIle</h1>
            <div className=' h-6'></div>
            <input ref={stageFileRef} type="file" accept='.csv' onChange={handleFileStage} className="file-input file-input-bordered w-full max-w-xs" />
            <div className=' h-6'></div>
            {
              stageValues.length === 0 ?


                <div className=' flex justify-center items-center w-full h-[300px]'>

                  <h1 className=' text-black font-bold text-2xl'>Please Choose File</h1>

                </div>

                :
                <table className=" border-[0.5px] border-gray-200 ">
                  <thead className=" bg-gray-200 shadow-sm h-14">
                    <tr>
                      {
                        columnStageArray.map((col, i) => (
                          <th key={i} className="px-6 py-3 text-left text-sm   text-gray-500 uppercase tracking-wider">{col}</th>
                        ))
                      }







                    </tr>
                  </thead>
                  <tbody className=" divide-y divide-borderColor">
                    {



                      stageValues.map((e, index) => {

                        return (
                          <tr key={index} className='bg-white'>

                            {
                              e.map((v, i) => (
                                <td key={i} className="px-6 py-4 whitespace-nowrap">{v}</td>
                              ))
                            }





                          </tr>
                        )
                      })

                    }


                  </tbody>

                </table>
            }
            <div className=' h-6'></div>
            {
              stageValues.length === 0 ? null :
                <button onClick={uploadBulkStage} className=' w-36 h-9 flex justify-center items-center rounded-md bg-green-600 text-sm text-white font-semibold'>UPLOAD</button>
            }
            <div className=' h-20'></div>

          </div>





        </div>
      </div>


      {/*end stage bulk modal */}

      {/* loader modal */}
      <input type="checkbox" id="my-modal-110" className="modal-toggle" checked={bulkLoading} />
      <div className="modal">
        <div className="modal-box bg-white">
          {/* <label htmlFor="my-modal-11" onClick={(e)=>{setOpenCLose(false); setPicUrl('')}} className="btn btn-sm btn-circle absolute right-2 top-2">✕</label> */}


          <div className=' w-full h-72 flex items-center justify-center bg-white'>
            <h1 className=' text-sm text-green-500 font-medium'>Uploading</h1>
          </div>




        </div>
      </div>

      {/* stage delete warning */}
      <input type="checkbox" id="my-modal-11" className="modal-toggle" checked={isStageDeleteWarning} />
      <div className="modal">
        <div className="modal-box w-96 bg-white">
          <div className=' w-full flex items-center flex-col justify-center bg-white'>
            <div className=' flex justify-center mb-5'>
              <img src="/images/delete.png" alt="delete" className='w-12 h-12' />
            </div>

            <h3 className='font-semibold text-lg'>Warning !!!</h3>
            <p className="text-lg mb-4">Are you sure you want to delete?</p>
            
            <div className=' w-full flex flex-row justify-between items-center'>
              <button onClick={() => { setIsStageDeleteWarning(false) }} className=' x-4 w-36 py-2 mr-2 text-gray-600 bg-white border-[0.5px] border-gray-700 rounded-lg' >Cancel</button>
              <button onClick={() => { deleteStage(stId) }} className=' px-4 w-36 py-2 bg-red-500 text-white rounded-lg'>Delete</button>
            </div>
          </div>
        </div>
      </div>
      {/* user delete warning */}
      <input type="checkbox" id="my-modal-11" className="modal-toggle" checked={isUserDeleteWarning} />
      <div className="modal">
        <div className="modal-box w-96 bg-white">
          <div className=' w-full flex items-center flex-col justify-center bg-white'>

            <div className=' flex justify-center mb-5'>
              <img src="/images/delete.png" alt="delete" className='w-12 h-12' />
            </div>

            <h3 className='font-semibold text-lg'>Warning !!!</h3>
            <p className="text-lg mb-4">Are you sure you want to delete?</p>

            <div className=' w-full flex flex-row justify-between items-center'>
              <button onClick={() => { setIsUserDeleteWarning(false) }} className=' x-4 w-36 py-2 mr-2 text-gray-600 bg-white border-[0.5px] border-gray-700 rounded-lg' >Cancel</button>
              <button onClick={() => { delStageUser(stUId) }} className=' px-4 w-36 py-2 bg-red-500 text-white rounded-lg' >Delete</button>
            </div>
          </div>
        </div>
      </div>
      {/* create template warning */}
      <input type="checkbox" id="my-modal-11" className="modal-toggle" checked={isCreateTemplateModal} />
      <div className="modal">
        <div className="modal-box w-72 bg-white">
          <div className=' w-full h-24 flex items-center flex-col justify-center bg-white'>
            <p className='text-black font-semibold text-[18px] ' >Do you want to Create ?</p>
            <div className='h-10' ></div>
            <div className=' w-full flex flex-row justify-between items-center'>
              <button onClick={() => { setIsCreateTemplateModal(false) }} className=' text-white bg-red-500 py-1 px-8 rounded-md' >NO</button>
              <button 
            onClick={() => { 
              createTemplate(); 
              setCompanyCode('');
               setSelectedCompanyName('');
                setPlantCode(''); 
                setSelectedPlantName(''); 
                setModuleCode(''); 
                setSelectedModuleName('');
                 setTemplateName(''); 
                 approvalTemplateRef.current.value = "";
                  setPsgId(''); 
                  setPsgName('');
                  setIsCreateTemplateModal(false);
                 }}

              
              className=' text-white bg-green-500 py-1 px-8 rounded-md' >YES</button>
            </div>
          </div>
        </div>
      </div>
      {/* Add Approval Stages warning */}
      <input type="checkbox" id="my-modal-11" className="modal-toggle" checked={isAddStageWarningModal} />
      <div className="modal">
        <div className="modal-box w-72 bg-white">
          <div className=' w-full h-24 flex items-center flex-col justify-center bg-white'>
            <p className='text-black font-semibold text-[18px] ' >Do you want to Create ?</p>
            <div className='h-10' ></div>
            <div className=' w-full flex flex-row justify-between items-center'>
              <button onClick={() => { setIsAddStageWarningModal(false) }} className=' text-white bg-red-500 py-1 px-8 rounded-md' >NO</button>
              <button 
            onClick={() => { 
              addStage(); 
              onCancelStageAddUpdate();
              setIsAddStageWarningModal(false);

                 }}

              
              className=' text-white bg-green-500 py-1 px-8 rounded-md' >YES</button>
            </div>
          </div>
        </div>
      </div>
      {/* Add user  warning */}
      <input type="checkbox" id="my-modal-11" className="modal-toggle" checked={isAddUserWarningModal} />
      <div className="modal">
        <div className="modal-box w-72 bg-white">
          <div className=' w-full h-24 flex items-center flex-col justify-center bg-white'>
            <p className='text-black font-semibold text-[18px] ' >Do you want to Create ?</p>
            <div className='h-10' ></div>
            <div className=' w-full flex flex-row justify-between items-center'>
              <button onClick={() => { setIsAddUserWarningModal(false) }} className=' text-white bg-red-500 py-1 px-8 rounded-md' >NO</button>
              <button 
            onClick={() => { 
              insertStageUser(); 
              setSelectedUser({});
               setUserId(''); 
               setSelectedUserName('');
                // setUserStatusCnt('');
                //  userStatusRef.current.value = "";
                 setIsAddUserWarningModal(false);

                 }}

              
              className=' text-white bg-green-500 py-1 px-8 rounded-md' >YES</button>
            </div>
          </div>
        </div>
      </div>
       {/*  delete template warning */}
       <input type="checkbox" id="my-modal-11" className="modal-toggle" checked={isDeleteTemplateWarningModal} />
      <div className="modal">
        <div className="modal-box w-96 bg-white">
          <div className=' w-full flex items-center flex-col justify-center bg-white'>
            <div className=' flex justify-center mb-5'>
              <img src="/images/delete.png" alt="delete" className='w-12 h-12' />
            </div>

            <h3 className='font-semibold text-lg'>Warning !!!</h3>
            <p className="text-lg mb-4">Are you sure you want to delete?</p>
            
            <div className=' w-full flex flex-row justify-between items-center'>
              <button onClick={() => { setIsDeleteTemplateWarningModal(false) }} className=' px-4 w-36 py-2 mr-2 text-gray-600 bg-white border-[0.5px] border-gray-700 rounded-lg' >Cancel</button>
              
              <button onClick={() => { deleteTem(templateAmId) }} className=' px-4 w-36 py-2 bg-red-500 text-white rounded-lg' >Delete</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}