import React, { useEffect, useState } from 'react'
import { useRequisitionContext } from '../context/RequisitionContext';
import { useHomePageContext } from '../../components/home_component/HomePageContext';
import ApproverHierarchyListService from '../../move_order/service/ApproverHierarchyListService';
import RequisitionItemService from '../service/RequisitionItemService';
import AdminInfoService from '../../services/AdminInfoService';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import moment from 'moment';
import { baseUrl } from '../../utils/path';
import Loading from '../../components/loader/Loading';
import MyRequisitionService from '../service/MyRequisitionService';
import numeral from 'numeral';
import RequisitionDetailsPopup from './RequisitionDetailsPopup';
import DeleteApplicationService from '../../iou/service/DeleteApplicationService';
import { usePDF } from 'react-to-pdf';

const infoUrl= `${baseUrl}my_info`;

export default function RequisitionPage() {
  
  const {menu,setMenu}=useHomePageContext();
  const [designation,setDesignation]=useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);
  const [isLoading, setIsLoading]=useState(false);
  const [empId, setEmpId]=useState('');
  const [joiningDate, setJoiningDate]=useState('');
  const [requisitionItemDetails, setRequisitionItemDetails]=useState([]);
  const [approverList, setApproverList]=useState([]);
  const [requisitionDetails, setRequisitionDetails] = useState([])
  // const {requisitionPageNo, setRequisitionPageNo,details,setDetails}=useRequisitionContext();
  const { setRequisitionPageNo, isDelete,setIsDelete,details,setDetails,isPopUp,setIsPopUp, deleteRowId, setDeleteRowId}=useRequisitionContext();
  const [sStatus, setSstatus] = useState('');
  const { toPDF, targetRef } = usePDF({filename: `MY_REQUISITION_${moment(details.CREATION_DATE).format('DD-MM-yyyy')}.pdf`});

  const goTo = () => {
    setRequisitionPageNo(2);
  }

  useEffect(()=>{
    getAll();
  },[]);
  
  const getAll=async()=>{
    setIsLoading(true);
    const id = localStorage.getItem('userId');
    // const eid = localStorage.getItem('empId');
    // const des = localStorage.getItem('designation');
    // setDesignation(des);
    getAdminInfo(id);
    getItem();
    // getHierarchy(details.REQUSTED_BY_ID, details.REQUISITION_TYPE === "General" ? "REQUISITION GENERAL" : details.REQUISITION_TYPE === "IT" ? "REQUISITION IT" : details.REQUISITION_TYPE === "Supply Chain" ? "REQUISITION SUPPLY CHAIN" : "REQUISITION HEAVY EQUIPMENT" , details.REQ_ID);
    setIsLoading(false);
  }

  useEffect(()=>{
    const eid = localStorage.getItem('empId');
    setEmpId(eid);
    getMyRequisitionProcessing(eid);
    getHierarchy(details.REQUSTED_BY_ID, details.REQUISITION_TYPE === "General" ? "REQUISITION GENERAL" : details.REQUISITION_TYPE === "IT" ? "REQUISITION IT" : details.REQUISITION_TYPE === "Supply Chain" ? "REQUISITION SUPPLY CHAIN" : "REQUISITION HEAVY EQUIPMENT" , details.REQ_ID);
    
  },[]); 
    
  const getMyRequisitionProcessing = async (id) => {
    setIsLoading(true);
    const re=await MyRequisitionService(id, "");
    console.log("Details Req:", re.req_details);
    setRequisitionDetails(re.req_details);
    setIsLoading(false);
  }

  const getHierarchy=async(id, module, object)=>{
    setIsLoading(true);
    const re = await ApproverHierarchyListService(id, module, object);
    const sortedData = re.APPROVER_LIST.sort((a, b) => a.STAGE_SEQ - b.STAGE_SEQ);
    console.log("select hierarchy: ", sortedData);
    setApproverList(sortedData);
    setIsLoading(false);
  }

  const [total, setTotal]=useState();
  const [inwords, setInWords]=useState('');

  const getItem=async(item)=>{
    setIsLoading(true);
    const re = await RequisitionItemService(item.REQ_ID);
    console.log("Req Details: ", re)
    setRequisitionItemDetails(re.item_details);
    let sum=0;
    for(let i = 0; i < re.item_details.length; i++){
      if(re.item_details[i].UNIT_PRICE ){
        sum = sum + ( re.item_details[i].ITEM_QUANTITY * re.item_details[i].UNIT_PRICE)  
      }
    }
    setTotal(sum);
    setInWords(test(sum));
    setIsLoading(false);
  }

  const getAdminInfo = async (uid) => {
    const admin = await AdminInfoService(infoUrl, uid);
    // setAdminDetails(admin.EMP_DETAILS);
    const eId = admin.EMP_DETAILS[0].EMPLOYEE_ID;
    
    const designation = admin.EMP_DETAILS[0].DESIGNATION;
    const joiningDate=admin.EMP_DETAILS[0].JOINING_DATE;
    setEmpId(eId);
    setDesignation(designation);
    setJoiningDate(joiningDate);
  }

  const convertDate = (dateString) => {
    const dateObject = new Date(dateString);
    const day = String(dateObject.getUTCDate()).padStart(2, '0');
    const month = String(dateObject.getUTCMonth() + 1).padStart(2, '0');
    const year = dateObject.getUTCFullYear();
    
    return `${day}-${month}-${year}`;
  };

  const generatePDF = () => {
    const element = document.getElementById('pdf');
    html2canvas(element).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('portrait', 'mm', 'a4');

      pdf.addImage(imgData, 'PNG', 10, 10);
      pdf.save(`MY_REQUISITION_${moment(details.CREATION_DATE).format('DD-MM-yyyy')}.pdf`);
    });
  };

  function test(n) {
    if (n < 0)
      return false;

    const  single_digit = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine']
    const   double_digit = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen']
    const   below_hundred = ['Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety']
    if (n === 0) return 'Zero'
    
    function translate(n) {
      let  word = ""
      if (n < 10) {
        word = single_digit[n] + ' '
      }

      else if (n < 20) {
        word = double_digit[n - 10] + ' '
      }

      else if (n < 100) {
        const  rem = translate(n % 10)
        word = below_hundred[(n - n % 10) / 10 - 2] + ' ' + rem
      }

      else if (n < 1000) {
        word = single_digit[Math.trunc(n / 100)] + ' Hundred ' + translate(n % 100)
      }

      else if (n < 1000000) {
        word = translate(parseInt(n / 1000)).trim() + ' Thousand ' + translate(n % 1000)
      }

      else if (n < 1000000000) {
        word = translate(parseInt(n / 1000000)).trim() + ' Million ' + translate(n % 1000000)
      }

      else {
        word = translate(parseInt(n / 1000000000)).trim() + ' Billion ' + translate(n % 1000000000)
      }

      return word
    }
      
    const   result = translate(n) 
    return result.trim()+' taka only.'
  }

  const[pdfData, setPdfData] = useState('');

  // const handleRowClick = (item) => {
  //   setIsLoading(true);
  //   setSelectedItem(item); //aita k bad dibo
  //   setDetails(item);
  //   setPdfData(item);
  //   setIsPopUp(true);
  //   getItem(item);
  //   getHierarchy(item.REQUSTED_BY_ID, item.REQUISITION_TYPE === "General" ? "REQUISITION GENERAL" : item.REQUISITION_TYPE === "IT" ? "REQUISITION IT" : item.REQUISITION_TYPE === "Supply Chain" ? "REQUISITION SUPPLY CHAIN" : "REQUISITION HEAVY EQUIPMENT" , item.REQ_ID);
    
  //   setIsLoading(false);
  // };

  const handleRowClick = async (item) => {
    setIsLoading(true);
  
    await setSelectedItem((prevSelectedItem) => {
      if (prevSelectedItem) {
        // Do cleanup for the previously selected item
        setDetails();
        setPdfData('');
        setIsPopUp(false);
        getItem();
        // getHierarchy();
      }
  
      // Set the new selected item
      setDetails(item);
      setPdfData(item);
      setIsPopUp(true);
      getItem(item);
      getHierarchy(item.REQUSTED_BY_ID, item.REQUISITION_TYPE === "General" ? "REQUISITION GENERAL" : item.REQUISITION_TYPE === "IT" ? "REQUISITION IT" : item.REQUISITION_TYPE === "Supply Chain" ? "REQUISITION SUPPLY CHAIN" : "REQUISITION HEAVY EQUIPMENT" , item.REQ_ID);
  
      return item; // Return the new selected item
    });
  
    setIsLoading(false);
  };
  

  // Function to close details popup
  const closeDetailsPopup = () => {
    setSelectedItem(null);
  };

  const handleStatus = async (status) => {
    setIsLoading(true);
    const re=await MyRequisitionService(empId, status);
    console.log("Details:", re)
    setRequisitionDetails(re.req_details);
    setIsLoading(false);
  }

  const handleRowDelete = async () => {
    setIsLoading(true);
    console.log("Deleted row: ");
    console.log("Deleted Item: ", deleteRowId);
    const result = await DeleteApplicationService(empId, "EMPLOYEE_REQUISITION", deleteRowId);
    console.log("Delete Requisition Row: ", result);
    getMyRequisitionProcessing(empId);
    setIsDelete(false);
    setIsPopUp(false);
    setIsLoading(false);
  }
  
  return (
    // <div className=' w-full h-screen flex justify-center items-center'>
    //     <div className=' w-96 flex flex-row space-x-10 items-center'>
    //         <button
    //         onClick={()=>{setMenu('creq')}}
    //         className=' py-2 px-4 bg-green-500 text-white rounded-md'>
    //             Create Requisition
    //         </button>
    //         <button
    //           onClick={()=>{setMenu('mreq')}}
    //         className=' py-2 px-4 bg-green-500 text-white rounded-md'>
    //             My Requisition
    //         </button>
    //     </div>
    // </div>


    <div className="my-20 mx-6">
      <div className="w-full">
        <p className=" text-black text-lg font-semibold">
          Requisition
        </p>

        <div className="h-5"></div>

        <div className=' w-96 flex flex-row space-x-10 items-center'>
          <button onClick={()=>{goTo()}} className=' h-12 px-4 bg-buttonColor text-white rounded-md flex items-center space-x-1'>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
            </svg>

              <p>Add Requisition</p>
          </button>

          {/* <div className=''>
              { 
                details.STATUS === "APPROVED" ?
                  <button onClick={generatePDF} className='text-[16px] font-medium text-black'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                    </svg>
                  </button>
                :
                <div></div>
              }
            </div> */}
        </div>
      </div>

      <div className="h-6"></div>

      <div className="flex items-center space-x-2">
        <select
          placeholder='Sort By Status' className="py-2 w-48 focus:outline-none text-gray-500 rounded-md border-[0.5px] hover:border-gray-400 px-3 bg-white"
          onChange={(e) => {handleStatus(e.target.value); setSelectedStatus(e.target.value) }}
          value={selectedStatus}
        >
          <option value="">ALL</option>
          <option value="IN PROCESS">Pending</option>
          <option value="APPROVED">Approved</option>
          <option value="REJECTED">Rejected</option>
        </select>


        {/* downloading pdf here */}

        <div className=''>
          {
            details.STATUS==="APPROVED" ?
              <button onClick={() => toPDF()} className='py-2 px-4 rounded-md border-[0.5px] font-medium text-white bg-buttonColor flex items-center space-x-1'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                </svg>
                
                <p>Download PDF</p>
              </button>
            :
            <div></div>
          }
        </div>
      </div>

      { isLoading ? (
          <Loading />
        ) : (
          <div>
            <div className="h-6"></div>
            
            <p className=" text-black text-lg font-semibold">
              My Requisition
            </p>
          
            <div className="h-4"></div>

            {
              requisitionDetails.length === 0 ? (
                <div className='flex flex-row justify-center items-center text-center w-full h-screen'>
                  <h1 className={`text-red-500 text-center w-full font-bold text-2xl`}>No Data Found</h1>
                </div>
              ) : (
              requisitionDetails.filter((e) => !selectedStatus || e.STATUS === selectedStatus).length === 0 ? (
                <div className='h-[400px] w-full text-center flex items-center justify-center'>
                  <h1 className={`text-red-500 text-center w-full font-bold text-2xl`}>No Data Found</h1>
                </div>
              ) : (

                <table className={`w-full ${isPopUp ? 'lg:w-7/12' : ''} border-[0.5px] border-gray-200`}>
                  <thead className="bg-bgTableHeader text-tableHeaderText h-14">
                    <tr>
                      <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                        NAME
                      </th>

                      <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                        REQUISITION TYPE
                      </th>

                      <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                        DATE
                      </th>
                            
                      <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                        PAYMENT METHOD
                      </th>
                            
                      <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                        STATUS
                      </th>
                    </tr>
                  </thead>
                  
                  {/* {
                    myMoveOrderList.length === 0 ? (
                      <div className='flex flex-row justify-center items-center text-center w-full h-screen'>
                        <h1 className={`text-red-500 text-center w-full font-bold text-2xl`}>No Data Found</h1>
                      </div>
                    ) : (
                      myMoveOrderList.filter((e) => !selectedStatus || e.AUTHORIZATION_STATUS === selectedStatus).length === 0 ? (
                        <div className='h-[400px] w-full text-center flex items-center justify-center'>
                          <h1 className={`text-red-500 text-center w-full font-bold text-2xl`}>No Data Found</h1>
                        </div>
                    ) : ( */}
                    <tbody className="w-full divide-y divide-borderColor">
                      { requisitionDetails.filter((e) => !selectedStatus || e.STATUS === selectedStatus).map((e, index) => (
                        <tr key={index} className="bg-white cursor-pointer" onClick={() => handleRowClick(e)}>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {e.EMPLOYEE_NAME == null ? "N/A" : e.EMPLOYEE_NAME}
                          </td>
                          
                          <td className="px-6 py-4 whitespace-nowrap">
                            {e.REQUISITION_TYPE == null ? "N/A" : e.REQUISITION_TYPE}
                          </td>

                          <td className="px-6 py-4 whitespace-nowrap">
                            {convertDate(e.CREATION_DATE) == null ? "N/A" : convertDate(e.CREATION_DATE)}
                          </td>

                          <td className="px-6 py-4 whitespace-nowrap">
                            {e.PAYMENY_METHOD == null ? "N/A" : e.PAYMENY_METHOD}
                          </td>

                          <td className="px-6 py-4 whitespace-nowrap">
                            {e.STATUS == null ? "N/A" : e.STATUS}
                          </td>

                          {/* Uncomment the section below if needed */}
                          {/* 
                          <td className="px-6 py-4 whitespace-nowrap">
                            {e.AUTHORIZATION_STATUS === 'IN PROCESS' ? (
                              <span className="text-[#FDCA4D]">Pending</span>
                            ) : e.AUTHORIZATION_STATUS === 'APPROVED' ? (
                              <span className="text-[#2D833F]">Approved</span>
                            ) : e.AUTHORIZATION_STATUS === 'REJECTED' ? (
                              <span className="text-[#E20000]">Rejected</span>
                            ) : (
                              <span className="text-gray-500">N/A</span>
                            )}
                          </td>
                          */}
                        </tr>
                      ))}
                    </tbody>
                </table>
              )
          )}

          {/* Display details popup if a row is clicked */}
          { isPopUp && (
            <RequisitionDetailsPopup />
          )}

          {isDelete && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white px-6 py-8 rounded-xl w-96">
                <div className="text-center w-full">
                    <div className=' flex justify-center mb-5'>
                      <img src="/images/delete.png" alt="delete" className='w-12 h-12' />
                    </div>

                  <h3 className='font-semibold text-lg'>Warning !!!</h3>
                  <p className="text-lg mb-4">Are you sure you want to delete?</p>
                </div>
                <div className="flex justify-between items-center">
                  <button
                    className="px-4 w-36 py-2 mr-2 bg-white border-[0.5px] border-gray-700 text-gray-600 rounded-lg"
                    onClick={() => setIsDelete(false)}
                  >
                    No
                  </button>

                  <button 
                    className="px-4 w-36 py-2 bg-red-500 text-white rounded-lg"
                    onClick={handleRowDelete}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          )}
          
          <div className="h-9"></div>

          {/* for PDF */}
          
          {
            pdfData.STATUS === "APPROVED" && isPopUp ?
              <div ref={targetRef} id='pdf' className='w-3/4 bg-white py-8 pl-16 pr-16'>
                <p className=' w-full flex justify-center items-center text-2xl font-pristina italic text-black'>{pdfData.COMPANY_NAME} ({pdfData.COMPANY_CODE})</p>
                
                <div className=' text-black font-bold text-xl w-full justify-center items-center flex'>{pdfData.REQUISITION_TYPE === "IT" ? "Requisition Form (IT)" : "Requisition Form"}</div>
                
                <div className=' h-4'></div>
                
                <div className=' w-full border-2 border-black'>
                  <div className=' w-full flex flex-row items-center'>
                    <div className='h-10 flex px-2 text-black font-bold justify-start flex-1 border-b-2 border-r-2 border-black'>
                      <p>Requisition No: {pdfData.REQ_ID}</p>
                      
                      <div className='h-3'></div>

                    </div>
                      
                    <div className='h-10 flex px-2 text-black font-bold justify-start flex-1 border-b-2 border-black'>
                      <p>Application Date: {moment(pdfData.CREATION_DATE).format('DD-MM-yyyy')}</p>
                    
                      <div className='h-3'></div>

                    </div>
                  </div> 
                  
                  <div className=' w-full flex flex-row items-center'>
                    <div className='h-10 flex px-2 text-black font-bold justify-start flex-1 border-b-2 border-r-2 border-black'>
                      <p>Name: {pdfData.EMPLOYEE_NAME} ({pdfData.REQUSTED_BY_ID})</p>
                      
                      <div className='h-3'></div>

                    </div>
                    <div className='h-10 flex px-2 text-black font-bold justify-start flex-1 border-b-2 border-black'>
                      <p>Company: {pdfData.COMPANY_NAME} ({pdfData.COMPANY_CODE})</p>
                      
                      <div className='h-3'></div>

                    </div>
                  </div> 
                  
                  <div className=' w-full flex flex-row items-center'>
                    <div className='h-10 flex px-2 text-black font-bold justify-start flex-1 border-b-2 border-r-2 border-black'>
                      <p>Designation: {designation}</p>
                      
                      <div className='h-3'></div>

                    </div>
                    
                    <div className='h-10 flex px-2 text-black font-bold justify-start flex-1 border-b-2 border-black'>
                      <p>Plant: {pdfData.PLANT_NAME} ({pdfData.PLANT_CODE})</p>
                      <div className='h-3'></div>

                    </div>
                  </div> 
                  
                  <div className=' w-full flex flex-row items-center'>
                    <div className='h-10 flex px-2 text-black font-bold justify-start flex-1   border-black'>
                      <p>Purpose: {pdfData.REQUISITION_TYPE}</p>
                      <div className='h-3'></div>
                    </div>
                  </div> 
                </div>
                
                <div className='h-10'></div>
                
                <div className='w-full border-2 border-black'>
                  <div className='w-full flex flex-row items-stretch'>
                    <div className='py-2 flex px-2 text-black font-bold justify-center w-12 border-r-2 border-black'>
                      SL
                    </div>
                    <div className='py-2 flex px-2 text-black font-bold justify-center w-3/6 border-r-2 border-black'>
                      Requisition Details
                    </div>
                    <div className='py-2 flex px-2 text-black font-bold justify-center w-24 border-r-2 border-black'>
                      Quantity
                    </div>
                    <div className='py-2 flex px-2 text-black font-bold justify-center w-20 border-r-2 border-black'>
                      Unit
                    </div>
                    <div className='py-2 flex px-2 text-black font-bold justify-center w-1/4 border-r-2 border-black'>
                      Apprx. Amount
                    </div>  
                    <div className='py-2 flex px-2 text-black font-bold justify-center w-28 border-black'>
                      Total
                    </div>
                  </div>
                </div>
                
                
                {
                  requisitionItemDetails.map((e, i) => (
                    <div key={i} className='w-full border-b-2 border-l-2 border-r-2 border-black'>
                      <div className='w-full flex flex-row items-stretch'>
                        <div className='px-2 text-black font-semibold flex justify-center w-12 border-r-2 border-black'>
                          {i + 1}
                          
                        </div>
                        <div className='px-2 text-black font-semibold w-3/6 border-r-2 border-black'>
                          {e.ITEM_DESC}
                          <div className="h-3"></div>
                        </div>
                        <div className='px-2 text-black font-semibold flex justify-center w-24 border-r-2 border-black'>
                          {e.ITEM_QUANTITY}
                          <div className="h-3"></div>
                        </div>
                        <div className='px-2 text-black font-semibold flex justify-center w-20 border-r-2 border-black'>
                          {e.UOM}
                          <div className="h-3"></div>
                        </div>
                        <div className='px-2 text-black font-semibold flex justify-center w-1/4 border-r-2 border-black'>
                          {e.UNIT_PRICE ? `${numeral(e.UNIT_PRICE).format('0,0')} tk` : "N/A"}
                          <div className="h-3"></div>
                        </div>
                        <div className='px-2 text-black font-semibold flex justify-center w-28 border-black'>
                          {isNaN(e.ITEM_QUANTITY * e.UNIT_PRICE) ? "N/A" : `${numeral(e.ITEM_QUANTITY * e.UNIT_PRICE).format('0,0')} tk`}
                          <div className="h-3"></div>
                        </div>
                      </div>
                    </div>
                  ))
                }

                
                <div className='w-full flex flex-row items-center border-l-2 border-r-2 border-black'>
                  <div className='h-10 flex px-2 text-black font-bold justify-start flex-1 border-b-2 border-r-2 border-black'>
                    <p>Amount in Words: {inwords}</p>
                    
                    <div className='h-3'></div>
                  </div>
                  
                  <div className='h-10 flex px-2 pb-4 text-black font-bold justify-center w-24 border-b-2 border-black'>
                    <p>{numeral(total).format('0,0')} tk</p>
                    
                    <div className='h-3'></div>

                  </div>
                </div> 
                
                <div className=' h-10'></div>

                <div className='w-full border-2 border-black'>
                  <div className='w-full grid grid-cols-2 gap-6 pt-2 pb-5'>
                    {
                      approverList.map((e,i)=>(
                        <div className=' '>
                          <div className=' flex flex-col items-center space-y-0'>
                            <p className=' text-black font-bold'>
                              { e.ACTION_CODE === 1 ?
                                <>
                                  { e.AUTHORIZER_TYPE === "RECOMMENDER" ? "Recommended" : "Approved" }
                                </>
                              :
                                <>
                                  { e.AUTHORIZER_TYPE === "RECOMMENDER" ? "Not Recommended" : "Rejected"  }
                                </>
                              }
                            </p>
                            
                            <p className=' text-black font-bold'>
                              Note: { e.NOTE ? e.NOTE : "N/A" }
                            </p>

                            <p className=' text-black font-bold'>
                              { `${e.EMPLOYEE_NAME} (${e.APPROVER_ID})` }
                            </p>

                            <p className=' text-black font-bold'>
                              { e.DESIGNATION }
                            </p>
                          </div>
                        </div>
                      ))
                    }
                  </div>           
                </div>
              </div>
            :
            null
          }
          </div>
        )
      }
    </div>
  )
}
