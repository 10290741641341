import React, { createContext, useState, useContext } from 'react';

const HomePage = createContext();

export const HomePageProvider = ({ children }) => {
  const [menu, setMenu] = useState(null);
 
  return (
    <HomePage.Provider value={{ menu, setMenu }}>
      {children}
    </HomePage.Provider>
  );
};

export const useHomePageContext = () => {
  return useContext(HomePage);
};