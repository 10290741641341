import { baseUrl } from "../../utils/path";



const CreateNoticeService = async (title,description,userId) => {
    console.log( `${title} ${title} ${userId}` );
   const url=`${baseUrl}create_notice`;
    // console.log(`em:${empId} se: ${sequence} st: ${status} md: ${moduleId} au: ${authorizationId} ap: ${approvalId}`);
    const response = await fetch(url,
        {
            method: "POST",
            headers: {

                "Content-Type": "application/json",
                'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
            },
            body: JSON.stringify({
                "NOTICE_TITLE": title,
                "NOTICE_DESCRIPTION": description,
                "POSTED_BY_ID": userId
            }),
        }
    );
   
    const data = await response.json();
    console.log(`create: ${data}`);
    return data;


}
export default CreateNoticeService
