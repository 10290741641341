// CreateRoleService

//RoleInsertDeleteService

import { baseUrl } from "../../utils/path";

  const CreateRoleService= async(userId,roleName)=>{
    
    const url=`${baseUrl}role_creation`;
  
    const response = await   fetch(url,
        {
            method: "POST",
            headers: {
              
              "Content-Type": "application/json",
              'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
            },
            body: JSON.stringify({
               
                "Z_DATA":userId,
                "ROLE_NAME":roleName,
                "CAN_DELETE":1
            }),
          }
        );
     console.log(response);
          const data = await response.json();
        console.log(data);
          return data;

     
}
export default CreateRoleService
