import { baseUrl } from "../../utils/path";

const url = `${baseUrl}attendance_upload`;

const SiteAttendanceUploadService = async (
    EMPLOYEE_ID,
    LOGIN_TIME,
    ADATE,
    COMPANY_CODE,
    PLANT_CODE,
    LATITUDE,
    LONGITUDE,
    REMARKS,
    IN_OUT_STATUS,
    CREATED_BY,
) => {
    // console.log(`uid: ${userId} lat ${lat}  long ${long} ra${radius} cc${companyCode}  pc${PlantCode}`);
    console.log(
        `id:${EMPLOYEE_ID} date: ${LOGIN_TIME} aDATE: ${ADATE} code: ${COMPANY_CODE} pCode: ${PLANT_CODE} lat: ${LATITUDE} lg: ${LONGITUDE} rem: ${REMARKS} create:${CREATED_BY} status: ${IN_OUT_STATUS}`
    );

    // console.log(`IN_TIME value: ${IN_TIME}`);

    const response = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization:
                "Basic " +
                btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
        },
        body: JSON.stringify({
            "EMPLOYEE_ID": EMPLOYEE_ID,
            "LOGIN_TIME": LOGIN_TIME,
            "ADATE": ADATE,
            "COMPANY_CODE": COMPANY_CODE,
            "PLANT_CODE": PLANT_CODE,
            "LATITUDE": LATITUDE,
            "LONGITUDE": LONGITUDE,
            "REMARKS": REMARKS,
            "IN_OUT_STATUS": IN_OUT_STATUS,
            "CREATED_BY": CREATED_BY

            // "EMPLOYEE_ID": 1104901 ,
            // "LOGIN_TIME": "2023-10-24 01:33:00",
            // "ADATE": "2023-10-24",
            // "COMPANY_CODE": "1000",
            // "PLANT_CODE": "DK01",
            // "LATITUDE": 23.81351 ,
            // "LONGITUDE": 90.37982 ,
            // "REMARKS": "dddd",
            // "IN_OUT_STATUS": 1,
            // "CREATED_BY": 1104901
        }),
    });
    // if (!response.ok) {
    //     throw new Error("fetching is not successful");
    //   }

    const data = await response.json();
    console.log(
        `2 id:${EMPLOYEE_ID} date: ${LOGIN_TIME} code: ${COMPANY_CODE} pCode: ${PLANT_CODE} lat: ${LATITUDE} lg: ${LONGITUDE} rem: ${REMARKS} create:${CREATED_BY} status: ${IN_OUT_STATUS}`
    );
    return data;
};
export default SiteAttendanceUploadService;
