import React,{useState,useEffect} from 'react'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import moment from 'moment';
import numeral from 'numeral';
import { useRequisitionContext } from '../context/RequisitionContext';
import ApproverHierarchyListService from '../../move_order/service/ApproverHierarchyListService';
import AdminInfoService from '../../services/AdminInfoService';
import Loading from '../../components/loader/Loading';
import RequisitionItemService from '../service/RequisitionItemService';
import { baseUrl } from '../../utils/path';
const infoUrl= `${baseUrl}my_info`;


export default function MyRequisitionDetailsPage() {
    const [designation,setDesignation]=useState('');
   
    const [isLoading,setIsLoading]=useState(false);
    const [empId,setEmpId]=useState('');
    const [joiningDate,setJoiningDate]=useState('');
    const [requisitionItemDetails,setRequisitionItemDetails]=useState([]);
    const [approverList,setApproverList]=useState([]);
    const {requisitionPageNo, setRequisitionPageNo,details,setDetails}=useRequisitionContext();
    // const [designation,setDesignation]=useState('');
    const back=()=>{
        setRequisitionPageNo(1);
    }

    useEffect(()=>{
        getAll();
    
    },[]);
    const getAll=async()=>{
        setIsLoading(true);
        const id = localStorage.getItem('userId');
        const des = localStorage.getItem('designation');
        setDesignation(des);
        getAdminInfo(id);
        getItem();
        getHierarchy(details.REQUSTED_BY_ID,"EMPLOYEE REQUISITION",details.REQ_ID);
        setIsLoading(false);
    }

    const getHierarchy=async(id,module,object)=>{
  
        const re=await ApproverHierarchyListService(id,module,object);
        console.log(re);
       
        const sortedData = re.APPROVER_LIST.sort((a, b) => a.STAGE_SEQ - b.STAGE_SEQ);
        setApproverList(sortedData);
        
    }
    const [total,setTotal]=useState();
    const [inwords,setInWords]=useState('');

    const getItem=async()=>{
        const re=await RequisitionItemService(details.REQ_ID);
        setRequisitionItemDetails(re.item_details);
        let sum=0;
        for(let i=0;i<re.item_details.length;i++){
            if(re.item_details[i].UNIT_PRICE ){
                sum=sum+(re.item_details[i].ITEM_QUANTITY*re.item_details[i].UNIT_PRICE)  
            }
          
        }
        setTotal(sum);
        setInWords(test(sum));
    }
    const getAdminInfo = async (uid) => {
        const admin = await AdminInfoService(infoUrl, uid);
        // setAdminDetails(admin.EMP_DETAILS);
        const eId = admin.EMP_DETAILS[0].EMPLOYEE_ID;
       
        const designation = admin.EMP_DETAILS[0].DESIGNATION;
        const joiningDate=admin.EMP_DETAILS[0].JOINING_DATE;
        setEmpId(eId);
        setDesignation(designation);
        setJoiningDate(joiningDate);

       
      
    
      }

      const convertDate = (dateString) => {
        const dateObject = new Date(dateString);
        const day = String(dateObject.getUTCDate()).padStart(2, '0');
        const month = String(dateObject.getUTCMonth() + 1).padStart(2, '0');
        const year = dateObject.getUTCFullYear();
        
        return `${day}-${month}-${year}`;
      };

      const generatePDF = () => {


        const element = document.getElementById('pdf');
        html2canvas(element).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('portrait', 'mm', 'a4');

            pdf.addImage(imgData, 'PNG', 10, 10);
            pdf.save(`MY_REQUISITION_${moment(details.CREATION_DATE).format('DD-MM-yyyy')}.pdf`);
        });
    };

    function test(n) {
        if (n < 0)
          return false;
       const  single_digit = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine']
      const   double_digit = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen']
      const   below_hundred = ['Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety']
        if (n === 0) return 'Zero'
        function translate(n) {
          let  word = ""
            if (n < 10) {
                word = single_digit[n] + ' '
            }
            else if (n < 20) {
                word = double_digit[n - 10] + ' '
            }
            else if (n < 100) {
              const  rem = translate(n % 10)
                word = below_hundred[(n - n % 10) / 10 - 2] + ' ' + rem
            }
            else if (n < 1000) {
                word = single_digit[Math.trunc(n / 100)] + ' Hundred ' + translate(n % 100)
            }
            else if (n < 1000000) {
                word = translate(parseInt(n / 1000)).trim() + ' Thousand ' + translate(n % 1000)
            }
            else if (n < 1000000000) {
                word = translate(parseInt(n / 1000000)).trim() + ' Million ' + translate(n % 1000000)
            }
            else {
                word = translate(parseInt(n / 1000000000)).trim() + ' Billion ' + translate(n % 1000000000)
            }
            return word
        }
      const   result = translate(n) 
        return result.trim()+' taka only.'
    }


  return (
    <div className=' my-20 px-8'>

        

           {
            isLoading?
            <Loading/>
            :

            <div>
                <div className=' w-3/4 shadow-sm'>
            <div className='h-3'></div>
                <div className='px-4 w-full flex flex-row   justify-between items-center '>
               { 
               details.STATUS==="APPROVED"?
               <button onClick={generatePDF} className=' py-1 px-10 rounded-full bg-green-500 text-[16px] font-medium text-white'>Download</button>
            :
            <div></div>
            }
                    <button onClick={back} className=' py-1 px-8 rounded-full bg-green-500 text-sm text-white'>Back</button>
                </div>
                <div className='h-3'></div>
                <div className=' w-full h-[1px] bg-gray-200'></div>
                <div className='h-3'></div>
                <div className='px-4 w-full flex flex-row space-x-10 items-center'>
                    <p className=' text-gray-700 font-medium text-lg w-80'>Requisition Type</p>
                    <p className=' text-gray-700 font-medium text-lg flex-1'>: {details.REQUISITION_TYPE}</p>

                </div>
                <div className='h-3'></div>
                <div className=' w-full h-[1px] bg-gray-200'></div>
                
                <div className='h-3'></div>
                <div className='px-4 w-full flex flex-row space-x-10 items-center'>
                    <p className=' text-gray-700 font-medium text-lg w-80'>Applicant Name</p>
                    <p className=' text-gray-700 font-medium text-lg flex-1'>: {details.EMPLOYEE_NAME}</p>

                </div>
                <div className='h-3'></div>
                <div className=' w-full h-[1px] bg-gray-200'></div>
                <div className='h-3'></div>
                <div className='px-4 w-full flex flex-row space-x-10 items-center'>
                    <p className=' text-gray-700 font-medium text-lg w-80'>Company Name</p>
                    <p className=' text-gray-700 font-medium text-lg flex-1'>: {details.COMPANY_NAME}</p>

                </div>
                <div className='h-3'></div>
                <div className=' w-full h-[1px] bg-gray-200'></div>
          <div className='h-3'></div>
                <div className='px-4 w-full flex flex-row space-x-10 items-center'>
                    <p className=' text-gray-700 font-medium text-lg w-80'>Plant Name</p>
                    <p className=' text-gray-700 font-medium text-lg flex-1'>: {details.PLANT_NAME}</p>

                </div>
                <div className='h-3'></div>
                <div className=' w-full h-[1px] bg-gray-200'></div>
          <div className='h-3'></div>
                
                <div className='px-4 w-full flex flex-row space-x-10 items-center'>
                    <p className=' text-gray-700 font-medium text-lg w-80'>Applicant Id</p>
                    <p className=' text-gray-700 font-medium text-lg flex-1'>: {details.REQUSTED_BY_ID}</p>

                </div>
                <div className='h-3'></div>
                <div className=' w-full h-[1px] bg-gray-200'></div>
          {/* <div className='h-3'></div>
                <div className='px-4 w-full flex flex-row space-x-10 items-center'>
                    <p className=' text-gray-700 font-medium text-lg w-80'>Designation</p>
                    <p className=' text-gray-700 font-medium text-lg flex-1'>: {designation}</p>

                </div>
                <div className='h-3'></div>
                <div className=' w-full h-[1px] bg-gray-200'></div> */}
          {/* <div className='h-3'></div>
                <div className='px-4 w-full flex flex-row space-x-10 items-center'>
                    <p className=' text-gray-700 font-medium text-lg w-80'>Joining Date</p>
                    <p className=' text-gray-700 font-medium text-lg flex-1'>: {convertDate(joiningDate)}</p>

                </div>
                <div className='h-3'></div>
                <div className=' w-full h-[1px] bg-gray-200'></div> */}
          <div className='h-3'></div>
                <div className='px-4 w-full flex flex-row space-x-10 items-center'>
                    <p className=' text-gray-700 font-medium text-lg w-80'>Apply Date</p>
                    <p className=' text-gray-700 font-medium text-lg flex-1'>: {convertDate(details.CREATION_DATE)}</p>

                </div>
                <div className='h-3'></div>
                <div className=' w-full h-[1px] bg-gray-200'></div>
          
          
                    <div>
                        <div className='px-4 w-full flex flex-row space-x-10 items-center'>
                    <p className=' text-gray-700 font-medium text-lg w-80'>Total Amount</p>
                    <p className=' text-gray-700 font-medium text-lg flex-1'>: {numeral(total).format('0,0')} ৳</p>

                </div>
                <div className='h-3'></div>
                <div className=' w-full h-[1px] bg-gray-200'></div>
          <div className='h-3'></div>
                    </div>
                    
               { 
               details.PAYMENY_METHOD
               ?
               
               <div className='px-4 w-full flex flex-row space-x-10 items-center'>
                    <p className=' text-gray-700 font-medium text-lg w-80'>Payment Method</p>
                    <p className=' text-gray-700 font-medium text-lg flex-1'>: {details.PAYMENY_METHOD}</p>

                </div>
                :
                <div className='px-4 w-full flex flex-row space-x-10 items-center'>
                <p className=' text-gray-700 font-medium text-lg w-80'>Payment Method</p>
                <p className=' text-gray-700 font-medium text-lg flex-1'>: N/A</p>

            </div>
                
            }
                <div className='h-3'></div>
                <div className=' w-full h-[1px] bg-gray-200'></div>
          <div className='h-3'></div>
        
                
                    <div>
                        <div className='px-4 w-full flex flex-row space-x-10 items-center'>
                    <p className=' text-gray-700 font-medium text-lg w-80'>Status</p>
                    <p className=' text-gray-700 font-medium text-lg flex-1'>: {details.STATUS}</p>

                </div>
                <div className='h-3'></div>
                <div className=' w-full h-[1px] bg-gray-200'></div>
          <div className='h-3'></div>
                    </div>
                    
         
                
                    {/* <div>
                        <div className='px-4 w-full flex flex-row space-x-10 items-center'>
                    <p className=' text-gray-700 font-medium text-lg w-80'>Less than 30</p>
                    <p className=' text-gray-700 font-medium text-lg flex-1'>: {details.LESS_DAYS_30}</p>

                </div>
                <div className='h-3'></div>
                <div className=' w-full h-[1px] bg-gray-200'></div>
          <div className='h-3'></div>
                    </div> */}
                   

            </div>
            {/* <div className='h-4'></div>
            <p className=' text-gray-700 font-medium text-lg w-80'>Purpose</p>
            <div className='h-1'></div>
            <div className=' w-3/4 p-2 border-[1px] border-gray-300 rounded-md'>
                {
                    details.IOU_REASON
                }
            </div> */}
            <div className='h-4'></div>
            <table className="table w-full mt-1 mb-10">
                            {/* head */}
                            <thead>
                                <tr>
                                    
                                    <td>Details</td>
                                    <th>Quantity*Price</th>
                                    <th>UOM</th>
                                    <th>Total</th>

                                

                                </tr>
                            </thead>


                            <tbody>

                                {

                                    requisitionItemDetails.map((e, index) =>

                                        <tr key={index}>
                                           

                                            <td>{e.ITEM_DESC}</td>
                                            <td>{e.ITEM_QUANTITY}* {e.UNIT_PRICE?e.UNIT_PRICE:"N/A"}</td>

                                            {/* <td>{
                                                e.UNIT_PRICE?
                                                <p>{e.ITEM_QUANTITY* e.UNIT_PRICE}</p>
                                                :
                                                e.ITEM_QUANTITY  numeral(number).format('0,0.00') 
                                                }</td> */}
                                            <td>{e.UOM}</td>
                                            <td>{isNaN(e.ITEM_QUANTITY*e.UNIT_PRICE)?"N/A":`${numeral(e.ITEM_QUANTITY*e.UNIT_PRICE).format('0,0')}`}</td>
                                            

                                        </tr>
                                    )
                                }

                            </tbody>
                        </table>

                        <div className='h-4'></div>
                        <p className=' text-black font-semibold text-lg'>APPROVE/REJECT HISTORY</p>
                        <div className='h-3'></div>
                        {
                approverList.map((e,i)=>(
                    <div className=' flex flex-col items-start space-y-4' key={i}>
                     <div className=' flex flex-row items-center space-x-6  h-20 w-96 '>
                       <div className={`w-6 h-6 p-[2px] rounded-full border-[1px] border-orange-500 flex justify-center items-center`}>
                        {
                            e.ACTION_CODE ===1
                            ?
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 text-green-500">
  <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
</svg>
:
e.ACTION_CODE===0?
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 text-red-500">
  <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
</svg>

:
null

                        }
                       </div>
                       <div className=' flex flex-col items-start space-y-1'>
                       <p className='  font-medium text-black'>{e.EMPLOYEE_NAME} ({e.AUTHORIZER_TYPE})</p>
                       <p>{
                        e.ACTION_CODE ===1?
                        <div className=' flex flex-col items-start space-y-1'>
                          <p className=' font-medium text-green-500'>
                          {
                          e.AUTHORIZER_TYPE ==="RECOMMENDER"?
                          "RECOMMENDED"
                          :
                          "APPROVED"
                          }
                          </p>
                          <p className=' font-medium text-green-500'>
                          {
                          e.NOTE ===null?"N/A":e.NOTE 
                          }
                          </p>
                        </div>
                    
                        : e.ACTION_CODE ===3?
                        <p className=' text-orange-500 font-medium'>
                          PENDING
                        </p>
                        :
                        <div className=' flex flex-col items-start space-y-1'>
                          <p className=' text-red-500 font-medium' >
                          {
                          e.AUTHORIZER_TYPE ==="RECOMMENDER"?
                          "NOT RECOMMENDED"
                          :
                          "NOT APPROVED"
                          }
                        </p>
                          <p className=' text-red-500 font-medium' >
                          {
                         e.NOTE ===null?"N/A":e.NOTE 
                          }
                        </p>
                        </div>
                        }</p>
                       </div>
                     </div>
                     
                    </div>
                ))
            }


            </div>
           }

           <div className='h-10'>

           </div>

           {
            details.STATUS==="APPROVED"?
            <div id='pdf' className=' w-3/4  bg-white py-8 pl-3 pr-16'>
        <p className=' w-full flex  justify-center items-center text-2xl font-pristina italic    text-black'>{details.COMPANY_NAME} ({details.COMPANY_CODE})</p>
 <div className=' text-black font-bold text-xl w-full justify-center items-center flex'>{details.REQUISITION_TYPE==="IT"?"Requisition Form (IT)":"Requisition Form"}</div>
        <div className=' h-4'></div>
        <div className=' w-full border-2 border-black'>
        <div className=' w-full flex flex-row items-center'>
                        <div className='h-10 flex  px-2 text-black font-bold justify-start flex-1 border-b-2 border-r-2 border-black'>
                            <p>Requisition No: {details.REQ_ID}</p>
                            <div className='h-3'></div>

                        </div>
                        <div className='h-10 flex  px-2 text-black font-bold justify-start flex-1 border-b-2 border-black'>
                            <p>Application Date: {moment(details.CREATION_DATE).format('DD-MM-yyyy')}</p>
                            <div className='h-3'></div>

                        </div>
                    </div> 
        <div className=' w-full flex flex-row items-center'>
                        <div className='h-10 flex  px-2 text-black font-bold justify-start flex-1 border-b-2 border-r-2 border-black'>
                            <p>Name: {details.EMPLOYEE_NAME} ({details.REQUSTED_BY_ID})</p>
                            <div className='h-3'></div>

                        </div>
                        <div className='h-10 flex  px-2 text-black font-bold justify-start flex-1 border-b-2 border-black'>
                            <p>Company: {details.COMPANY_NAME} ({details.COMPANY_CODE})</p>
                            <div className='h-3'></div>

                        </div>
                    </div> 
        <div className=' w-full flex flex-row items-center'>
                        <div className='h-10 flex  px-2 text-black font-bold justify-start flex-1 border-b-2 border-r-2 border-black'>
                            <p>Desination: {designation}</p>
                            <div className='h-3'></div>

                        </div>
                        <div className='h-10 flex  px-2 text-black font-bold justify-start flex-1 border-b-2 border-black'>
                            <p>Plant: {details.PLANT_NAME} ({details.PLANT_CODE})</p>
                            <div className='h-3'></div>

                        </div>
                    </div> 
        <div className=' w-full flex flex-row items-center'>
                        <div className='h-10 flex  px-2 text-black font-bold justify-start flex-1   border-black'>
                            <p>Purpose: {details.REQUISITION_TYPE}</p>
                            <div className='h-3'></div>

                        </div>
                 
                    </div> 

        </div>
        <div className='h-10'></div>
        <div className=' w-full  border-2 border-black'>
           
            <div className=' w-full flex flex-row items-center'>
                        <div className='py-2 flex  px-2 text-black font-bold justify-center w-12  border-r-2 border-black'>
                       SL

                        </div>
                        <div className='py-2 flex  px-2 text-black font-bold justify-center flex-1  border-r-2 border-black'>
                        Requisition Details

                        </div>
                        <div className='py-2 flex  px-2 text-black font-bold justify-center w-16  border-r-2 border-black'>
                        Quantity

                        </div>
                        <div className='py-2 flex  px-2 text-black font-bold justify-center w-16  border-r-2 border-black'>
                        Unit

                        </div>
                        <div className='py-2 flex  px-2 text-black font-bold justify-center flex-1  border-r-2 border-black'>
                        Approximate Amount

                        </div>
                        <div className='py-2 flex  px-2 text-black font-bold justify-center w-40  border-black'>
                        Total 

                        </div>
                    </div> 

                    
        </div>
        {
                        requisitionItemDetails.map((e,i)=>(
                            <div key={i} className=' w-full border-b-2 border-l-2 border-r-2 border-black'>
                                 <div className=' w-full flex flex-row items-center'>
                        <div className='pb-3 flex  px-2 text-black font-semibold justify-center w-12  border-r-2 border-black'>
                       {i+1}

                        </div>
                        <div className='pb-3 flex  px-2 text-black font-semibold justify-center flex-1  border-r-2 border-black'>
                        {
                            e.ITEM_DESC
                        }
                        <div className='h-3'></div>

                        </div>
                        <div className='pb-3 flex  px-2 text-black font-semibold justify-center w-16  border-r-2 border-black'>
                        {
                            e.ITEM_QUANTITY
                        }
                        <div className='h-3'></div>

                        </div>
                        <div className='pb-3 flex  px-2 text-black font-semibold justify-center w-16  border-r-2 border-black'>
                       {
                        e.UOM
                       }
                       <div className='h-3'></div>

                        </div>
                        <div className='pb-3 flex  px-2 text-black font-semibold justify-center flex-1  border-r-2 border-black'>
                        {
                            e.UNIT_PRICE ?
                           `${numeral(e.UNIT_PRICE).format('0,0')} tk`  
                            :
                            "N/A"
                        }
                        <div className='h-3'></div>

                        </div>
                        <div className='pb-3 flex  px-2 text-black font-semibold justify-center w-40  border-black'>
                        {isNaN(e.ITEM_QUANTITY*e.UNIT_PRICE)?"N/A":`${numeral(e.ITEM_QUANTITY*e.UNIT_PRICE).format('0,0')} tk`}   
                        <div className='h-3'></div>

                        </div>
                    </div> 
                            </div> 
                        ))
                    }
                     <div className=' w-full flex flex-row items-center border-l-2 border-r-2 border-black'>
                        <div className='h-10 flex  px-2 text-black font-bold justify-start flex-1 border-b-2 border-r-2 border-black'>
                            <p>Amount in Words: {inwords}</p>
                            <div className='h-3'></div>

                        </div>
                        <div className='h-10 flex  px-2 pb-4 text-black font-bold justify-center items-center w-40 border-b-2 border-black'>
                            <p>{numeral(total).format('0,0')} tk</p>
                            <div className='h-3'></div>

                        </div>
                    </div> 
                    <div className=' h-10'>

                    

                    </div>

                    <div className=' w-full border-2 border-black'>
        <div className=' w-full grid grid-cols-2 gap-6 pt-2   pb-5'>
                    {
                            approverList.map((e,i)=>(
                                <div className=' '>
                                        <div className=' flex flex-col items-center space-y-0'>
                                            <p className=' text-black font-bold'>
                                                {
                                                   e.AUTHORIZER_TYPE==="RECOMMENDER"?
                                                    "Recommended":"Approved"
                                                }
                                            </p>
                                            <p className=' text-black font-bold'>
                                            Note: {
                                                    e.NOTE?
                                                    e.NOTE:"N/A"
                                                }
                                            </p>
                                            <p className=' text-black font-bold'>
                                                {
                                                   `${e.EMPLOYEE_NAME} (${e.APPROVER_ID})`
                                                }
                                            </p>
                                            <p className=' text-black font-bold'>
                                                {
                                                   e.DESIGNATION
                                                }
                                            </p>

                                        </div>
                                </div>
                            ))
                        }
                    </div>

                      
        </div>


        </div>
        :
        null
        }

        </div>
  )
}
