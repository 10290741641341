import React, { useEffect, useState } from "react";
import { Loader } from "rsuite";
import Loading from "../../components/loader/Loading";
import PositionGetFromSapService from "../service/PositionGetFromSapService";
import SyncPositionService from "../service/SyncPositionService";
import { sapBaseUrl } from "../../utils/path";

const url =
  `${sapBaseUrl}/ZHCM_INFO_MOBILE_APP_SRV/ZHR_PositionSet?$format=json`;

export default function SyncPositionPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [position, setPosition] = useState([]);
  const [loop, setLoop] = useState(1);
  const [openClose, setOpenClose] = useState(false);
  const [userId, setUserId] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [empId, setEmpId] = useState("");
  const [searchTerm, setSearchTerm] = useState(""); // Step 1

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const id = localStorage.getItem("userId");
    setUserId(id);
    // getAdminInfo(id);
  }, []);

  const getData = async () => {
    setIsLoading(true);
    const re = await PositionGetFromSapService(url);
    console.log(re.d.results);
    setPosition(re.d.results);
    setIsLoading(false);
  };

  const toggleCheckbox = (positionId) => {
    if (selectedItems.includes(positionId)) {
      setSelectedItems(selectedItems.filter((id) => id !== positionId));
    } else {
      setSelectedItems([...selectedItems, positionId]);
    }
  };

  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
    setSelectedItems(selectAll ? [] : position.map((item) => item.Postion_id));
  };

  const sync = async () => {
    setOpenClose(true);

    const itemsToSync = selectAll
      ? position
      : position.filter((item) => selectedItems.includes(item.Postion_id));

    for (const e of itemsToSync) {
      const re = await SyncPositionService(userId, e.Position_name, e.Postion_id);
      console.log(re);
    }

    setOpenClose(false);
    setSelectedItems([]);
    setSelectAll(false);
  };

  // Step 2: Add a search input field
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Step 3: Filter the position array based on the search term
  const filteredPosition = position.filter(
    (item) =>
      item.Position_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.Postion_id.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      {isLoading ? (
        <div className="w-full h-screen flex justify-center items-center">
          <Loading />
        </div>
      ) : (
        <div className="my-24 mx-4">
          <div className="w-full h-16 flex flex-row justify-between space-x-4 items-center bg-offWhiteColor rounded-t-md border-[0.5px] border-borderColor px-3">
            {/* Step 2: Add a search input field */}
            {/* <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearchChange}
              className="border rounded-md p-2"
            /> */}

            {/* <div className="h-full text-sm font-mon text-blackishColor font-medium flex justify-center items-center">
              Position Name
            </div> */}

            
            <div className="">
            {/* Step 2: Add a search input field  */}
              <input
              type="text"
              placeholder="Search by Position Name or Id..."
              value={searchTerm}
              onChange={handleSearchChange}
              className="border rounded-md w-60 px-2 h-12 bg-white focus:outline-none"
                />
            </div>

            <div className="flex items-center space-x-3">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={toggleSelectAll}
                  className="mr-2"
                />
                <span>Select All</span>
              </div>

              <button
                onClick={sync}
                className="h-12 w-40 flex items-center justify-center font-semibold space-x-1 rounded-md bg-buttonColor text-white"
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                </svg>

                <p>Sync</p>
              </button>
            </div>
          </div>

            {/* <div className="h-4"></div> */}


          <div className="h-4"></div>

          <table className="w-full border-collapse">
            <thead className="bg-bgTableHeader text-tableHeaderText">
              <tr>
                <th className="border text-sm font-semibold px-2 py-2 center">
                  Select
                </th>
                <th className="border text-sm font-semibold px-2 py-2 text-center">
                  SL
                </th>
                <th className="border text-sm font-semibold px-2 py-2 text-center">
                  Position Name
                </th>
                <th className="border text-sm font-semibold px-2 py-2 text-center">
                  Position ID
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredPosition.map((j, k) => (
                <tr
                  key={k}
                  className={`w-full py-4 ${
                    k === filteredPosition.length - 1 ? "rounded-b-md" : ""
                  }`}
                >
                  <td className="text-center border text-sm text-black font-medium px-2">
                    <input
                      type="checkbox"
                      checked={selectedItems.includes(j.Postion_id)}
                      onChange={() => toggleCheckbox(j.Postion_id)}
                    />
                  </td>
                  <td className="text-center border text-sm text-black font-medium px-2">
                    {k + 1}.
                  </td>
                  <td className="text-center border text-sm text-black font-medium px-2">
                    {j.Position_name}
                  </td>
                  <td className="text-center border text-sm text-black font-medium px-2">
                    {j.Postion_id}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <input
        type="checkbox"
        id="my-modal-11"
        className="modal-toggle"
        checked={openClose}
      />
      <div className="modal">
        <div className="modal-box">
          <div className="w-full h-72 flex items-center justify-center bg-white">
            <h1 className="text-green-500 font-bold text-xl">{`Inserting ${loop} of ${position.length}`}</h1>
          </div>
        </div>
      </div>
    </div>
  );
}
