import React,{useState,useRef,useEffect} from 'react'
import { gray500, gray100, red500, gray300, gray400, green100, green600, gray700, gray600, gray200 } from '../../utils/colors';
import { baseUrl } from '../../utils/path';
import getUserList from '../../services/UsersService';
import UserWiseRoleService from '../service/UserWiseRoleService';
import Loading from '../../components/loader/Loading';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UserRolePermissionService from '../service/UserRolePermissionService';
import { useHomePageContext } from '../../components/home_component/HomePageContext';
import UserActiveDeactiveService from '../../user_active_deactive/service/UserActiveDeactiveService';
import UserResetPasswordService from '../service/UserResetPasswordService';
const userUrl = `${baseUrl}employee_search_by_name_id`;

export default function UserRoleMenuAssignmentPage() {
    const [selectedUserName, setSelectedUserName] = useState('');
 const [users, setUsers] = useState([]);
  const [isUsersLoading, setIsUsersLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState({});
  const [searchKey, setSearchKey] = useState({});
  const [userId,setUserId]=useState();
  const [adminId, setAdminId] = useState('');
  const [roleList,setRoleList]=useState([]);
  const [isLoading,setIsLoading]=useState(false);
  const [isSelectAll, setIsSelectAll] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [empId,setEmpId]=useState('');
  let id, username,selectedUserId;
  const usersRef = useRef(null);
  const [resetModal, setResetModal] = useState(false);

useEffect(()=>{
  const id = localStorage.getItem('userId');
  setAdminId(id);
},[])
const getUsers = async () => {
 
    if (!isNaN(searchKey)) {
      id = searchKey;
    }
    else {
      username = searchKey;
    }
    const users = await getUserList(userUrl, id, username);
    setUsers(users.EMPLOYEE_LIST);
    setEmpId(users.EMPLOYEE_LIST[0].EMPLOYEE_ID);
    
    setIsUsersLoading(false);
    setSearchKey('');
    usersRef.current.value = '';
   
  }

  const userSelect=async(e)=>{
    setIsLoading(true);
    setRoleList([]);
    setSelectedUser(e); 
    selectedUserId = e.EMPLOYEE_ID;
    console.log(selectedUserId);
    getRole(e.EMPLOYEE_ID);
     setUserId(selectedUserId); 
    setSelectedUserName(e.EMPLOYEE_NAME) ;
    setIsLoading(false);
  }

  const handleSelectAll = () => {
    if (isSelectAll === null || isSelectAll === false) {
      setIsSelectAll(true);
      const newSelectedItems = roleList.map(menu => ({
       
        ROLE_ID: menu.ROLE_ID,
        EMPLOYEE_ID: empId,
      }));
      setSelectedItems(newSelectedItems);

      
    } else {
      if (isSelectAll === true) {
        setIsSelectAll(null);
        setSelectedItems([]);
      }
    }
  };

  const handleUnselectAll = () => {
    if (isSelectAll === null || isSelectAll === true) {
      setIsSelectAll(false);
      setSelectedItems([]);
    } else {
      if (isSelectAll === false) {
        setIsSelectAll(null);
        setSelectedItems([]);
      }
    }
  };

  const handleToggleSelect = menuId => {
    const isSelected = selectedItems.some(item => item.ROLE_ID === menuId);

    if (isSelected) {
      setSelectedItems(prevItems =>
        prevItems.filter(item => item.ROLE_ID !== menuId)
      );
    } else {
      setSelectedItems(prevItems => [
        ...prevItems,
        {
          
          ROLE_ID:menuId,
        EMPLOYEE_ID: empId,
        },
      ]);
    }
  };

  const [activeStatus,setActiveStatus]=useState(null);

  const getRole=async(empId)=>{
    const re=await UserWiseRoleService(adminId,empId);
    setRoleList(re.User_Roles);
    setActiveStatus(re.Active_Status)
  }

  const { menu, setMenu } = useHomePageContext();

  const showToastError = message => {
    toast.error(message, {
      autoClose: 3000,
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const showToastSuccess = message => {
    toast.success(message, {
      autoClose: 3000,
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const handleButtonClick = (roleIndex) => {
    setRoleList(prevMenuList => {
        const updatedMenuList = prevMenuList.map((role, index) => {
            if (index === roleIndex) {
                return {
                    ...role,
                    IS_ASSOCIATED: role.IS_ASSOCIATED === 1 ? 0 : 1,
                };
            }
            return role;
        });

        return updatedMenuList;
    });
};




  const insertUserRole = async (roleId) => {
    
     
    setIsLoading(true);
        const re = await UserRolePermissionService( 
          adminId,
          roleId,
         empId
        );
        console.log(re);
      
     
      showToastSuccess(`Permission Updated Successfully`);
     
       
        getRole(empId);
        setIsLoading(false);
     
    
  };

  const doActiveInactive = async () => {
    const newStatus = activeStatus === 1 ? 0 : 1;
    const re = await UserActiveDeactiveService(empId, newStatus);
    if (newStatus === 1) {
      showToastSuccess("Activated");
    } else {
      showToastSuccess("Deactivated");
    }
  }
  
  const handleActiveDeactive = () => {
    const newStatus = activeStatus === 1 ? 0 : 1;
    setActiveStatus(newStatus);
    doActiveInactive();
  }

  const handleYesClick = async () => {
    setIsLoading(true);

    const result = await UserResetPasswordService(adminId, empId);
    
    // Show success toast
    showToastSuccess(result.message);

    setIsLoading(false);
    setResetModal(false);
  };
  

  return (
    <div className=' my-20 px-8'>
       <ToastContainer/>
        <p className=' text-lg text-black font-semibold'>Assign Role & Menu to User</p>
        <div className='h-4'></div>
        <div className="modal-action w-full bg-white">
              <label htmlFor="my-modal-16" className="w-full border-none bg-transparent hover:bg-transparent">
                <div className={` w-full h-12 border-[0.2px] border-${gray300} rounded-md flex text-left justify-center items-center`}>
                  <p className={`w-full  pl-6 text-${gray400} text-[16px]`}>{selectedUserName === '' ? "Select user" : selectedUserName}</p>
                </div>
              </label>
            </div>
            <div className='h-4'></div>
            {/* {
               roleList.length===0
               ?
               null:
              
              <p className=' text-[18px] text-black font-semibold'>User Active/Deactive</p>}
            <div className='h-4'></div>
            {
              roleList.length===0
              ?
              null:
               <button
                 
               onClick={() => {handleActiveDeactive()}}
               className='flex text-start flex-row space-x-2 items-center'>
               <div className={`rounded-[4px] w-4 h-4 ${activeStatus===1 ? "bg-green-600 border-none" : "bg-white border-[0.1px] border-gray-300"}  flex justify-center items-center`}>
                   <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-3 h-3 text-white">
                       <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                   </svg>
               </div>
               <p className='text-xs  font-medium text-black'>
                  {activeStatus=== 1 ? "Deactive" : "Active"}
              </p>
           </button>
            } */}

<div className='h-4'></div>
{
  roleList.length === 0
    ? null
    :
    <>
      <p className='text-[18px] text-black font-semibold'>User Active/Deactive</p>
      <div className='h-4'></div>
      <div className='flex justify-between items-center'>
        <div className="flex space-x-4">
          <label className="flex items-center space-x-2 cursor-pointer">
            <input
              type="radio"
              name="activeDeactivate"
              checked={activeStatus === 1}
              onChange={handleActiveDeactive}
              className="accent-green-600" // Added radio button color green-600
            />
            <p className='text-xs font-medium text-black'>
              Active
            </p>
          </label>
          
          <label className="flex items-center space-x-2 cursor-pointer">
            <input
              type="radio"
              name="activeDeactivate"
              checked={activeStatus !== 1}
              className="accent-green-600" // Added radio button color green-600
              onChange={handleActiveDeactive}
            />
            <p className='text-xs font-medium text-black'>
              Deactive
            </p>
          </label>
        </div>

            <button onClick={() => setResetModal(true)} className='h-12 px-6 rounded-md text-[16px] font-medium text-white bg-buttonColor'>
              Reset Password
            </button>
      </div>
    </>
}



            <div className='h-4'></div>
           { 
            roleList.length===0
            ?
            null:
           <p className=' text-[18px] text-black font-semibold'>Roles</p>}
            <div className='h-4'></div>
           
             
            <div className='h-4'></div>
            {
              isLoading?
              <div className=' h-64 w-full flex justify-center items-center'>
                <Loading/>
              </div>
              :
              <div className='grid grid-cols-4 gap-6'>
              {roleList.map((f, j) => (
                  <button
                 
                      onClick={() => {handleButtonClick( j);insertUserRole(f.ROLE_ID,empId) }}
                      key={j} className='flex text-start flex-row space-x-2 items-center'>
                      <div className={`rounded-[4px] w-4 h-4 ${f.IS_ASSOCIATED === 1 ? "bg-green-600 border-none" : "bg-white border-[0.1px] border-gray-300"}  flex justify-center items-center`}>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-3 h-3 text-white">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                          </svg>
                      </div>
                      <p className='text-xs  font-medium text-black'>{f.ROLE_NAME}</p>
                  </button>
              ))}
          </div>
            }
             <div className='h-4'></div>
             {/* <button 
        onClick={insertUserRole}
        className=' py-1 px-4 bg-green-600 rounded-md shadow-sm text-white font-semibold'>
            SAVE
        </button> */}
             <div className='h-4'></div>


          {/* reset password modal */}
          {resetModal && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white px-6 py-8 rounded-xl w-96">
                <div className="text-center w-full">
                    <div className=' flex justify-center mb-5'>
                      <img src="/images/warning.png" alt="delete" className='w-16 h-16' />
                    </div>

                  <h3 className='font-semibold text-lg'>Warning !!!</h3>
                  <p className="text-lg mb-4">Are you sure you want to Reset Password?</p>
                </div>
                <div className="flex justify-between items-center">
                  <button
                    className="px-4 w-36 py-2 mr-2 bg-white border-[0.5px] border-gray-700 text-gray-600 rounded-lg"
                    onClick={() => setResetModal(false)}
                  >
                    No
                  </button>

                  <button 
                    className="px-4 w-36 py-2 bg-buttonColor text-white rounded-lg"
                    onClick={handleYesClick}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          )}


            
      {/* start select user modal */}
      <input type="checkbox" id="my-modal-16" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box h-3/4 bg-white">
          <label onClick={(e) => {
            setUsers([]); setSearchKey('');
            usersRef.current.value = '';
          }} htmlFor="my-modal-16" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
          <h3 className="font-bold text-lg">Select User</h3>
          <div className='w-full flex flex-col justify-center items-center my-3'>
            <div className="form-control">
              <div className="input-group ">
                <input ref={usersRef} onChange={(e) => { setSearchKey(e.target.value) }} onKeyPress={(e) => { if (e.key === 'Enter') getUsers(); }} type="text" placeholder="Search…" className="input input-bordered w-72 focus:outline-none bg-white" />
                <button onClick={getUsers} className="btn btn-square">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" /></svg>
                </button>
              </div>
            </div>

            {

              users.length === 0 ?
                <p className=' mt-16 font-medium text-lg'>Search Name Here</p> :
                isUsersLoading ?
                  <p>loding...</p> :

                  users.map((e, index) =>
                    <div key={index} onClick={()=>{userSelect(e)}} className="modal-action w-[336px]">
                      <label htmlFor="my-modal-16" className="w-full border-none bg-transparent hover:bg-transparent">
                       
                       <div className={`flex flex-col py-1  w-full  shadow-md  rounded-md  text-left justify-center items-start space-y-0`}>
                          <p className={`w-full  px-6 text-${gray600} text-[16px]`}>{`${e.EMPLOYEE_NAME} (${e.POSITION_NAME})`}</p>
                          <p className={`w-full  px-6 text-${gray600} text-[16px]`}>{`ID: ${e.EMPLOYEE_ID} `}</p>
                        </div>
                        
                       
                      </label>
                    </div>
                  )

            }

          </div>

        </div>
      </div>

      {/* end select user modal */}


    </div>
  )
}
