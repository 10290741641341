import { baseUrl } from "../../utils/path";


  

  const CreateMoveOrderService= async(empId,taskType,pfrom,pto,sdate,edate,st,et,purpose,
    amount,isVehicle,approverId,vehicle
    )=>{

    const url=`${baseUrl}create_move_order`;

    const response = await   fetch(url,
        {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
            },
            body: JSON.stringify(
                {
                
                "EMPLOYEE_ID":empId,
                "TASK_TYPE":taskType,
                "PLACE_FROM":pfrom,
                "PLACE_TO":pto,
                "START_DATE":sdate,    //2023-09-20T04:00:00Z
                "END_DATE":edate,
                "START_TIME":st,       //2023-09-20T04:00:00Z
                "END_TIME":et,
                "PURPOSE":purpose,
                "APPLY_AMOUNT":amount,
                "APPROVE_AMOUNT":amount,
                "IS_VEHICLE_NEED":isVehicle,
                "OBJECT_TYPE_CODE":"MOVE ORDER",
                "APPROVER_ID":approverId,
                "AUTHORIZATION_STATUS":"IN PROCESS",
                "VEHICLE":vehicle
            }
            ),
          }
        );
        if (!response.ok) {
            throw new Error("fetching is not successful");
          }
        
          const data = await response.json();
         
          return data;

     
}
export default CreateMoveOrderService
