import React, { useRef, useEffect } from "react";
import Papa from "papaparse";
import { useState } from "react";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SiteAttendanceUploadService from "../service/SiteAttendanceUploadService";
import AttendanceUploadToSapService from "../service/SIteAttendanceLoginLogoutService";
import FrontDeskCompanyListService from "../../front_desk_report/service/FrontDeskCompanyListService";
import FrontDeskPlantListService from "../../front_desk_report/service/FrontDeskPlantListService";
import {
    gray500,
    gray100,
    red500,
    gray300,
    gray400,
    green100,
    green600,
    gray700,
    gray600,
} from "../../utils/colors";
// import GeoFenceListService from "../../services/GeoFenceListService";
import GeoFencingService from "../service/GeoFencingService";
import { sapBaseUrl } from "../../utils/path";
// import AdminInfoService from "../../services/AdminInfoService";
export default function SiteAttendanceUploadPage() {
    const [data, setData] = useState([]);
    const [columnArray, setColumnArray] = useState([]);
    const [values, setValues] = useState([]);
    const [loop, setLoop] = useState(1);
    const [openClose, setOpenClose] = useState(false);
    const [fileInputKey, setFileInputKey] = useState(0);
    const [selectVisible, setSelectVisible] = useState(false);
    const [selectedOption, setSelectedOption] = useState("");
    const [uploadButtonVisible, setUploadButtonVisible] = useState(false);
    const [uniqueData, setUniqueData] = useState([]);
    const [showExcelUploadButton, setShowExcelUploadButton] = useState(false);
    var i = 0;

    //drop
    let selectedCompanyCode;
    let sPlant;
    const [company, setCompany] = useState([]);
    const [plant, setPlant] = useState([]);
    const [isPlantLoading, setIsPlantLoading] = useState(true);
    const [companyCode, setCompanyCode] = useState("");
    const [plantCode, setPlantCode] = useState("");
    const [adminId, setAdminId] = useState("");
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [duplicatesArray, setDuplicatesArray] = useState([""]);
    const [unsentData, setUnsentData] = useState([]);
    

    const [eId, setEId] = useState("");

    useEffect(() => {
        // setOpenClose(true);
        const id = localStorage.getItem("userId");
        const empId = localStorage.getItem("empId");
        console.log(id);
        console.log(empId);
        setAdminId(id);
        // getAdminInfo(id);
        getCompany(id);
        setEId(empId);
    }, []);

    const getFence = async (companyCode,plantCode) => {
        const re = await GeoFencingService(companyCode, plantCode);
        console.log(re);
        if (re.status === true) {
            setLatitude(re.latitude);
            setLongitude(re.longitude);
            // Set state to show the excel upload button
            setShowExcelUploadButton(true);
        } else {
            console.log("error");
            toast.error(`${re.message}`, {
                autoClose: 3000,
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };

    const getCompany = async (id) => {
        const re = await FrontDeskCompanyListService(id);
        console.log(re.Company_List);
        setCompany(re.Company_List);
    };

    const getPlant = async (cid) => {
        console.log(cid);
        setIsPlantLoading(true);
        const re = await FrontDeskPlantListService(adminId, cid);
        // console.log(re.Plant_List);
        setPlant(re.Plant_List);
        // console.log();

        setIsPlantLoading(false);
    };

    //drop

    const convertDateFormat = (inputDate) => {
        // Split the input date into month, day, and year
        const [month, day, year] = inputDate.split("/");

        // Create a new date object with the input values
        const originalDate = new Date(`${month}/${day}/${year}`);

        // Get the day, month, and year from the original date
        const convertedDay = originalDate.getDate();
        const convertedMonth = originalDate.getMonth() + 1; // Months are zero-based
        const convertedYear = originalDate.getFullYear();

        // Format the date in dd.mm.yyyy
        const outputDate = `${convertedDay}.${convertedMonth}.${convertedYear}`;

        return outputDate;
    };

    const [isError,setIsError]=useState(false);
    
    const upload = async () => {
        setOpenClose(true);
        let newValues = [];
        let unsentRow = [];

        console.log(`val len: ${values.length}`);
        for (const e of values) {
            const url = `${sapBaseUrl}/ZHCM_ATTENDANCE_ENTRY_SRV/ZATTENDANCE_STRUCSet?$filter=(EmpId%20eq%20%27${
                e[0]
            }%27%20and%20Date%20eq%20%27${convertDateFormat(
                e[1]
            )}%27%20and%20Time%20eq%27${formatTime(e[2])}%27)&$format=json`;
            const re = await AttendanceUploadToSapService(url);
            console.log(re.d.results[0].Status);
            console.log(re.d.results[0].Msg);
            if (re.d.results[0].Status === "S") {
                newValues.push(e);
            }
            else {
                unsentRow.push(e);
            }
        }

        // Set the state to show the unsent data
        // setUnsentData(unsentRow);

        for (const e of newValues) {
            const result = await SiteAttendanceUploadService(
                e[0],
                `${moment(e[1]).format("YYYY-MM-DD")} ${formatTime(e[2])}`,
                `${moment(e[1]).format("YYYY-MM-DD")}`,
                companyCode,
                plantCode,
                latitude,
                longitude,
                e[3],
                selectedOption,
                eId,
            );
            //   e.splice(0, e.length);
            // setUniqueData([]);
            setLoop((e) => e + 1);
            setSelectedOption("");

            console.log(result);
        }

        setData([]);
        setColumnArray([]);
        setValues([]);
        setUniqueData([]);
        setOpenClose(false);
        setLoop(0);
        setCompanyCode("");
        setPlantCode("");
        setSelectedFile(null)

        if(unsentRow.length>0){
            //setValues(unsentRow);
            setIsError(true);
            setUnsentData(unsentRow)

            // Show toast with total unsent data
            toast.error(`Total unsent data: ${unsentRow.length}`, {
                autoClose: 3000,
                position: toast.POSITION.TOP_RIGHT,
            });
        }

        
        // console.log("Unsent Row: ", unsentRow.length)

        setFileInputKey((prevKey) => prevKey + 1);

        setSelectVisible(false);
        setUploadButtonVisible(false);
    };

    // useEffect(() => {
    //     console.log("Unsent Data after state update:", unsentData);
    // }, [unsentData]);


    const [selectedFile, setSelectedFile] = useState(null);

    const handleFile = (event) => {
        setIsError(false);

        const files = event.target.files;
        if (!files || files.length === 0) {
            // Handle the case where no files are selected or the selection is canceled
            setData([]);
            setColumnArray([]);
            setValues([]);
            setUniqueData([]);
            setSelectVisible(false);
            setUploadButtonVisible(false);

            setSelectedFile(null); // Set selectedFile to null when no file is selected
            return;
        }

        Papa.parse(files[0], {
            header: true,
            skipEmptyLines: true,
            complete: function (result) {
                const uniqueValues = removeDuplicates(result.data, [
                    "EMPLOYEE_ID",
                    "LOGIN_TIME",
                ]);
                const duplicates = result.data.filter((item) => !uniqueValues.includes(item));

                // Store duplicates in a separate array
                setDuplicatesArray(duplicates);

                const columnArray = Object.keys(uniqueValues[0]);
                const valuesArray = uniqueValues.map((d) => Object.values(d));

                setData(result.data);
                setColumnArray(columnArray);
                setValues(valuesArray);
                setUniqueData(uniqueValues);

                setSelectVisible(true);
                setUploadButtonVisible(false);
                setSelectedFile(files[0]); // Set selectedFile to the uploaded file

                // Display toast with the length of duplicate data
                toast.success(`${duplicates.length} duplicate entries found!`, {
                    autoClose: 3000,
                    position: toast.POSITION.TOP_RIGHT,
                });
            },
        });
    };

    const formatTime = (time) => {
        // Check if time is defined before splitting
        if (time) {
            const [hours, minutes] = time.split(":");
            const paddedHours = hours.padStart(2, "0");
            const paddedMinutes = minutes.padStart(2, "0");
            // return `${paddedHours}:${paddedMinutes}`;
            
            // Always set seconds to "00"
            const paddedSeconds = "00";
            return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
        }
        return "";
    };

    // useEffect(() => {
    //     // Reset unsentData when values change (e.g., after a file upload)
    //     setUnsentData([]);
    // }, [values]);

    return (
        <div className="mx-8 my-16">
            <div className=" h-6"></div>

            <div className="flex items-end justify-between">
                <div>
                    <h1 className=" text-lg font-medium text-black">
                        Upload Attendance Excel File
                    </h1>

                    <div className=" h-6"></div>

                    {/* <input
                        type="file"
                        accept=".csv"
                        onChange={handleFile}
                        className="file-input file-input-bordered w-full max-w-xs"
                        key={fileInputKey}
                    /> */}
                    
                    <label className={`flex-row shadow justify-between cursor-pointer items-center flex space-x-2 h-[48px] border-[1px] border-gray-300 rounded-l-lg pr-2`}>
                        <div className='flex flex-row space-x-2 items-center'>
                            <div className={`font-mon bg-[#013E0E] px-4 h-[48px] items-center flex rounded-l-lg w-32 text-white text-lg font-medium`}>
                                Choose File
                            </div>
                            
                            <input
                                type="file"
                                accept=".csv"
                                className="hidden"
                                onChange={handleFile}
                                key={fileInputKey}
                            />
                            <span className={`text-[#333439] text-lg items-center flex bg-white px-4`} >{selectedFile ? selectedFile.name : 'No File Selected'}</span>
                        </div>
                    </label>

                    
                </div>

                <div className="flex justify-start">
                {   selectVisible && 
                    selectedOption !== "" &&
                    companyCode !== "" &&
                    plantCode !== "" &&
                    showExcelUploadButton && (
                        <button
                            onClick={upload}
                            className="w-40 h-[48px] flex justify-center items-center rounded-md bg-buttonColor text-sm text-white font-semibold space-x-2"
                        >   
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5" />
                        </svg>


                           <p>Excel Upload </p>
                        </button>
                    )}
                </div>
            </div>

            <div className=" h-6"></div>

            <ToastContainer />

            <div className="flex space-x-3 items-center text-lg">
                <div className="w-1/3">
                    { selectVisible && (
                        <select
                            placeholder="Select Login or Logout"
                            name=""
                            id=""
                            className={`select select-bordered focus:outline-none bg-white w-full text-gray-500`}
                            value={selectedOption}
                            onChange={(e) => {
                                setSelectedOption(e.target.value);
                            }}
                        >
                            <option value="" disabled selected>
                                Select Login or Logout
                            </option>
                            <option value="1">Login</option>
                            <option value="0">Logout</option>
                        </select>
                    )}
                </div>

                <div className="h-6"></div>

                <div className="w-1/3">
                    { selectVisible && selectedOption !== "" && (
                        <select
                            placeholder="Select Company"
                            value={companyCode}
                            onChange={(e) => {
                                selectedCompanyCode = e.target.value;
                                setCompanyCode(selectedCompanyCode);
                                getPlant(selectedCompanyCode);
                            }}
                            className={`select select-bordered w-full bg-white focus:outline-none text-${gray500} `}
                        >
                            <option value="" disabled selected>
                                Select Company
                            </option>
                            {company.map((e, index) => (
                                <option key={index} value={e.COMPANY_CODE}>
                                    {e.COMPANY_NAME}({e.COMPANY_CODE})
                                </option>
                            ))}
                        </select>
                    )}
                </div>

                <div className="h-6"></div>

                {isPlantLoading ? (
                    <p></p>
                ) : (
                    <div className="w-1/3">
                        { selectVisible && selectedOption !== "" && companyCode !== "" && <select
                            value={plantCode}
                            onChange={(e) => {
                                sPlant = e.target.value;
                                console.log(sPlant);
                                getFence(companyCode, sPlant);
                                setPlantCode(sPlant);
                                
                            }}
                            className={`select select-bordered w-full bg-white focus:outline-none text-${gray500}  `}
                        >
                            <option value="" disabled selected>
                                Select Plant
                            </option>
                            {isPlantLoading ? (
                                <p>loading.....</p>
                            ) : (
                                plant.map((e, index) => (
                                    <option
                                        className=" text-black"
                                        value={e.PLANT_CODE}
                                        key={index}
                                    >
                                        {e.PLANT_NAME}({e.PLANT_CODE})
                                    </option>
                                ))
                            )}
                        </select>}
                    </div>
                )}
            </div>

            {/* <div className="flex justify-start">
                {   selectVisible && 
                    selectedOption !== "" &&
                    companyCode !== "" &&
                    plantCode !== "" &&
                    showExcelUploadButton && (
                        <button
                            onClick={upload}
                            className="w-36 h-9 flex justify-center items-center rounded-md bg-[#036A19] text-sm text-white font-semibold"
                        >
                            EXCEL UPLOAD
                        </button>
                    )}
            </div> */}

            <div className="h 5"></div>

            { isError ?
            <>
                <div>
                    <table className="border-[0.5px] border-gray-200 ">
                        <thead className=" text-red-500 shadow-sm h-14">
                            <tr className="border-1 ">
                                <th className="bg-bgTableHeader px-6 py-3 text-left text-sm uppercase tracking-wider">
                                    EMPLOYEE_ID
                                </th>
                                <th className="bg-bgTableHeader px-6 py-3 text-left text-sm uppercase tracking-wider">
                                    Login_Date
                                </th>
                                <th className="bg-bgTableHeader px-6 py-3 text-left text-sm uppercase tracking-wider">
                                    In_Time
                                </th>
                                
                                <th className="bg-bgTableHeader px-6 py-3 text-left text-sm uppercase tracking-wider">
                                    Remarks
                                </th>
                            </tr>
                        </thead>
                        <tbody className=" divide-y divide-borderColor">
                            {unsentData.map((e, index) => {
                                return (
                                    <tr key={index} className={`${ index % 2 === 0 ? "bg-white" : "bg-[#EEF2FF]" }`}>
                                        {e.map((v, i) => (
                                            <td key={i} className="px-6 py-4 whitespace-nowrap">
                                                {v}
                                            </td>
                                        ))}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </>
            : 
            <>{values.length === 0 ? (
                <div className=" flex justify-center items-center w-full h-[300px]">
                    <h1 className=" text-black font-bold text-2xl">
                        Please Choose File
                    </h1>
                </div>
            ) : (
                <table className=" border-[0.5px] border-gray-200 mt-5">
                    <thead className=" bg-[#DFE5E0] text-[#32353C] shadow-sm h-14">
                        <tr>
                            {columnArray.map((col, i) => (
                                <th
                                    key={i}
                                    className="px-6 py-3 text-left bg-bgTableHeader text-tableHeaderText text-sm uppercase tracking-wider"
                                >
                                    {col}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className=" divide-y divide-borderColor">
                        {values.map((e, index) => {
                            return (
                                <tr
                                    key={index}
                                    className={`${
                                        index % 2 === 0
                                            ? "bg-white"
                                            : "bg-[#EEF2FF]"
                                    }`}
                                >
                                    {e.map((v, i) => (
                                        <td
                                            key={i}
                                            className="px-6 py-4 whitespace-nowrap"
                                        >
                                            {v}
                                        </td>
                                    ))}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            )}</>
            }

            <div className=" h-6"></div>
            <div className=" h-20"></div>
            <input
                type="checkbox"
                id="my-modal-11"
                className="modal-toggle"
                checked={openClose}
            />
            <div className="modal">
                <div className="modal-box bg-white">
                    <div className=" w-full h-72 flex items-center justify-center bg-white">
                        <h1 className=" text-green-500 font-bold text-xl">{`Inserting ${loop} of ${values.length}`}</h1>
                    </div>
                </div>
            </div>
        </div>
    );
}

// Function to remove duplicates based on specified columns
function removeDuplicates(arr, columns) {
    const seen = new Set();
    return arr.filter((item) => {
        const key = columns.map((column) => item[column]).join("|");
        if (seen.has(key)) {
            return false;
        }
        seen.add(key);
        return true;
    });
}
