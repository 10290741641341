import { baseUrl } from "../../utils/path";

const syncUrl = `${baseUrl}job_sync`;

const SyncJobService = async (userId, jobName, jobId) => {

  console.log(`user:${userId} name:${jobName} id: ${jobId}`);

  const response = await fetch(syncUrl,
      {
          method: "POST",
          headers: {

              "Content-Type": "application/json",
              Authorization: 'Basic ' + btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
          },
          body: JSON.stringify({
            Z_DATA: userId,
              JOB_NAME: jobName,
              JOB_ID: jobId,
              
          }),
      }
  );
  // if (!response.ok) {
  //     throw new Error("fetching is not successful");
  //   }
  const data = await response.json();
  // console.log(`info: ${data.status}`);
  return data;


}
export default SyncJobService
