import { baseUrl } from "../../utils/path";



const NoticeCompanyPlantService = async (list) => {
    const url = `${baseUrl}notice_company_plant`;
    // console.log(`em:${empId} se: ${sequence} st: ${status} md: ${moduleId} au: ${authorizationId} ap: ${approvalId}`);

    const payload = { data: list };
     const jsonString = JSON.stringify(payload);
    console.log(jsonString);

    const response = await fetch(url,
        {
            method: "POST",
            headers: {

                "Content-Type": "application/json",
                'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
            },
            body: jsonString
      
        }
    );

    const data = await response.json();
    console.log(`c p: ${data}`);
    return data;


}
export default NoticeCompanyPlantService
