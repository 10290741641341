
import { baseUrl } from "../../../utils/path";

const ForgetPasswordService = async (email, empNum, id) => {

  const url = `${baseUrl}pass_recovery_otp_send`;

  console.log("email", email)
  console.log("mob no.: ", empNum)
  console.log("id: ", id)
    
  const response = await fetch(url,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
      },
      body: JSON.stringify({
        "EMAIL": email, 
        "MOBILE_NUMBER": empNum,
        "EMPLOYEE_ID": id
      }),
    }
  );
  const data = await response.json();
  console.log(`result: ${data}`);
  return data;
   
}
export default ForgetPasswordService;