
import React, { useState, useEffect, useRef } from 'react'
import InputLebel from '../../common/InputLebel'
import Datepicker from "react-tailwindcss-datepicker";
import moment from 'moment';
import { gray500, gray100, red500, gray300, gray400, green100, green600, gray700, gray800, gray200, gray600 } from '../../utils/colors';
import dayjs, { Dayjs } from 'dayjs';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import GetApproverService from '../service/GetApproverService';
import GetMoveOrderTypeService from '../service/GetMoveOrderTypeService';
import GetVehicleTypeService from '../service/GetVehicleTypeService';
import CreateMoveOrderService from '../service/CreateMoveOrderService';
import { useHomePageContext } from '../../components/home_component/HomePageContext';
import MoveOrderCheckService from '../service/MoveOrderCheckService';
import CheckLeaveService from '../service/CheckLeaveService';
import { useMoveOrderContext } from '../context/MoveContext';

export default function CreateMoveOrderPage() {

  const placeFromRef = useRef(null);
  const placeToRef = useRef(null);
  const purposeRef = useRef(null);
  const amountRef = useRef(null);
  const vehicleNameRef = useRef(null);


  const [value, setValue] = React.useState(null);
  const [value1, setValue1] = React.useState(null);
  const [value2, setValue2] = React.useState(null);
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [startDate2, setStartDate2] = useState('');
  const [endDate2, setEndDate2] = useState('');

  const [placeFrom, setPlaceFrom] = useState('');
  const [placeTo, setPlaceTo] = useState('');
  const [purpose, setPurpose] = useState('');
  const [amount, setAmount] = useState('');
  const [vehicleName, setVehicleName] = useState('');
  const [isVehicle, setIsVehicle] = useState(false);
  const [isVehicleValue, setIsVehicleValue] = useState(0);
  const [userId, setUserId] = useState('');
  const [employeeId, setEmployeeId] = useState('');
  const [fromTime,setFromTime]=useState('');
  const [toTime,setToTime]=useState('');
  const { setMovePageNo } = useMoveOrderContext();

  const back = () => {
    console.log('dfghedgh');
    setMovePageNo(1); 
  } 

  const handleVehicleChange = () => {
    setIsVehicle(prevIsVehicle => !prevIsVehicle); // Toggle isVehicle

    // Now, let's use the updated value of isVehicle
    if (!isVehicle) {
      setIsVehicleValue(1);

    } else {
      setIsVehicleValue(0);

    }
  }


  //mv type
  const [moveTypeList, setMoveTypeList] = useState([]);
  let selectedMoveTypeCode;
  const [moveTypeCode, setMoveTypeCode] = useState('');
  const [moveTypeName, setMoveTypeName] = useState('');
  const [showAlert, setShowAlert] = useState(null);
  const { menu, setMenu } = useHomePageContext();
  //mv type

  //vehicle type

  const [vehicleTypeList, setVehicleTypeList] = useState([]);
  let selectedVehicleTypeName;
  const [vehicleTypeName, setVehicleTypeName] = useState('');

  //vehicle type

  const [startEndDate, setStartEndDate] = useState({
    startDate: null,
    endDate: null

  });
  const [startEndDate2, setStartEndDate2] = useState({
    startDate2: null,
    endDate2: null

  });

  useEffect(() => {
    const id = localStorage.getItem('userId');
    const eid = localStorage.getItem('empId');
    
    setUserId(id);
    setEmployeeId(eid);
    getMoveOrderType(id);
    getVehicle();
  }, []);

  const showToastError = (message) => {
    toast.error(message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT }); // autoClose is in milliseconds
  };
  const showToastSuccess = (message) => {
    toast.success(message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT }); // autoClose is in milliseconds
  };

  const areDatesEqual = (startDate, endDate) => {
    return startDate === endDate;
  };
  // const areDatesEqual = (startDate, endDate) => {
  //   return startDate.$d.toDateString() === endDate.$d.toDateString();
  // };

  const areTimesEqual = (startDate, endDate) => {
    return startDate.$d.getHours() === endDate.$d.getHours() &&
      startDate.$d.getMinutes() === endDate.$d.getMinutes();
  };





  // const { menu, setMenu } = useHomePageContext();

  const create = async () => {
      if(validateCreateMoveOrderError()){
        console.log(moveTypeCode);
      console.log(typeof(moveTypeCode));
      const datesEqual = areDatesEqual(startEndDate.startDate, startEndDate2.startDate2); //added startDate2
      const timesEqual = areTimesEqual(value, value1);
      const moveCheck=await checkMove();
      console.log(moveCheck);
      const leaveCheck=await checkLeave();
      console.log(leaveCheck);
      if (moveTypeCode === '12') {
        console.log('Inside personal move order');
    
      
        const result = await GetApproverService(employeeId, "MOVE ORDER", moveTypeCode);
        if(result.EMPLOYEE_LIST.length===0){
  showToastError('No Approver Exist For This User');
}
else{
  if (!checkValidDates(startEndDate.startDate, startEndDate.endDate)) {
    showToastError("You cannot take on Friday or Saturday");
  } else if (!isTimeDifferenceLessThanOrEqual4Hours(value, value1)) {
    showToastError("Personal Move Order must be less than 4 hours");
  } else if (timesEqual) {
    showToastError('Start and End Time are the same');
  }
  else if(moveCheck) {
    showToastError("Already you have a move order")
  }
  else if(leaveCheck){
    showToastError("You can not take move order, while you are on leave")
  }
  
  else {
    // Logic for Personal move order
    const re = await CreateMoveOrderService(employeeId, moveTypeName, placeFrom, placeTo, startDate, endDate,
      startTime, endTime, purpose, amount === '' ? 0 : amount, isVehicleValue, result.EMPLOYEE_LIST[0].APPROVER_ID,
      vehicleTypeName === 'Others' ? vehicleName : vehicleTypeName
    )

    console.log(re);

    // clearValueAfterCreating();
    setShowAlert(true);
    showToastSuccess('Move Order Created Successfully');

    setTimeout(() => {
      setMovePageNo(1);
    }, 3200); // Delay of 1 seconds
  
}
}

 

       
        // ...
      } else if (moveTypeCode === '11') {
        console.log('Inside official move order');
        // ...
    
          const result = await GetApproverService(employeeId, "MOVE ORDER", moveTypeCode);
        if(result.EMPLOYEE_LIST.length===0){
          showToastError('No Approver Exist For This User');
        }
        else if(moveCheck) {
          showToastError("Already you have a move order")
        }
        else if(leaveCheck){
          showToastError("You can not take move order, while you are on leave")
        }
        else{
          if (datesEqual && timesEqual) {
            showToastError('Dates and Times are same, please change');
          } else {
            // Logic for Official move order
            const re = await CreateMoveOrderService(employeeId, moveTypeName, placeFrom, placeTo, startDate, startDate2,
              startTime, endTime, purpose, amount === '' ? 0 : amount, isVehicleValue, result.EMPLOYEE_LIST[0].APPROVER_ID,
              vehicleTypeName === 'Others' ? vehicleName : vehicleTypeName
            )
  
  
  
            // console.log(vehicleTypeName);
            // console.log(vehicleName);
  
            clearValueAfterCreating();
            setShowAlert(true);
            showToastSuccess('Move Order Created Successfully');
  
            setTimeout(() => {
              // setMenu('3000');
              setMovePageNo(1);
            }, 3200); // Delay of 1 seconds
          }
        }
        
      }
      }
      else{
        console.log('validation failed');
      }
    
      

  };
  

  // const create = async () => {

    

  //   if (validateCreateMoveOrderError()) {
  //     console.log('clicked');
     
  //     const datesEqual = areDatesEqual(startEndDate.startDate, startEndDate2.startDate);
  //    console.log(datesEqual);
  //     const timesEqual = areTimesEqual(value, value1);
  //     console.log(timesEqual);
  //     const result = await GetApproverService(employeeId, "MOVE ORDER", moveTypeCode);
  //     console.log( result.EMPLOYEE_LIST.length);
  //     // if(result.EMPLOYEE_LIST.length===0){
  //     //   showToastError('No Approver Exist For This User');
  //     // }
    
  //       if (moveTypeCode === '11') {
  //         console.log(moveTypeCode);
  //         console.log('official');
  //         if (datesEqual && timesEqual) {
  //           showToastError('Dates and Times are same, plz change');
  //         }
  //         else {
          
  //           const re = await CreateMoveOrderService(employeeId, moveTypeName, placeFrom, placeTo, startDate, endDate,
  //             startTime, endTime, purpose, amount === '' ? 0 : amount, isVehicleValue, result.EMPLOYEE_LIST[0].APPROVER_ID,
  //             vehicleTypeName === 'Others' ? vehicleName : vehicleTypeName
  //           )
  
  
  
  //           // console.log(vehicleTypeName);
  //           // console.log(vehicleName);
  
  //           clearValueAfterCreating();
  //           setShowAlert(true);
  //           showToastSuccess('Move Order Created Successfully');
  
  //           setTimeout(() => {
  //             setMenu('3000');
  //           }, 3200); // Delay of 1 seconds
  //         }
  
  //       }
  //     else   {
  //         console.log('personal');
  //         console.log(moveTypeCode);
  //         if (!checkValidDates(startDate, endDate)) {
  //           showToastError("You can not take on Friday or Saturday");
  //           console.log(startTime);
  //         }
  //         else if (!isTimeDifferenceLessThanOrEqual4Hours(value, value1)) {
  //           showToastError("Personal Move Order is less than 4 hour");
  //         }
  //         else if (timesEqual) {
  //           showToastError('Start and End Time is same');
  //         }
  //         else {
  //           // const result = await GetApproverService(employeeId, "MOVE ORDER", moveTypeCode);
  //           const re = await CreateMoveOrderService(employeeId, moveTypeName, placeFrom, placeTo, startDate, endDate,
  //             startTime, endTime, purpose, amount === '' ? 0 : amount, isVehicleValue, result.EMPLOYEE_LIST[0].APPROVER_ID,
  //             vehicleTypeName === 'Others' ? vehicleName : vehicleTypeName
  //           )
  
  //           console.log(re);
  
  //           // clearValueAfterCreating();
  //           setShowAlert(true);
  //           showToastSuccess('Move Order Created Successfully');
  
  //           setTimeout(() => {
  //             setMenu('3000');
  //           }, 3200); // Delay of 1 seconds
  
  
  //         }
  //       }
      
     
        
      


      


  //   }
  // }

  //clear refs and state value

  const clearValueAfterCreating = () => {
    placeFromRef.current.value = '';
    // placeToRef.current.value='';
    purposeRef.current.value = '';
    // if(amountRef.current.value !==null && vehicleNameRef.current.value !==null){
    //   amountRef.current.value='';
    // vehicleNameRef.current.value='';
    // }
    setValue(null);
    setValue1(null);
    // setValue2(null);
    setStartTime('');
    setEndTime('');
    setStartDate('');
    setEndDate('');
    setMoveTypeCode('');
    setMoveTypeName('');
    setPlaceFrom('');
    setPlaceTo('');
    setPurpose('');
    setAmount('');
    setVehicleName('');
    setIsVehicle(false);
    setIsVehicleValue(0);
    setStartEndDate(
      {
        startDate: null,
        endDate: null
      }
    );
    setStartEndDate2(
      {
        startDate: null,
        endDate: null
      }
    );
  }

  //get approver



  //personal move order validation

  // const checkValidDates = (date1, date2) => {
  //   const isWeekendDay = (date) => {
  //     const dayIndex = new Date(date).getDay();
  //     return dayIndex === 5 || dayIndex === 6; // Friday or Saturday
  //   };

  //   const dayName1 = new Date(date1).toLocaleString('en-US', { weekday: 'long' });
  //   const dayName2 = new Date(date2).toLocaleString('en-US', { weekday: 'long' });
  //   console.log(dayName1);
  //   console.log(dayName2);
  //   return !isWeekendDay(date1) && !isWeekendDay(date2);
  // };
  const checkValidDates = (date1, date2) => {
    const isWeekendDay = (date) => {
      const dayIndex = new Date(date).getDay();
      return dayIndex === 5 || dayIndex === 6; // Friday or Saturday
    };
  
    const dayName1 = new Date(date1).toLocaleString('en-US', { weekday: 'long' });
    const dayName2 = new Date(date2).toLocaleString('en-US', { weekday: 'long' });
    console.log(dayName1);
    console.log(dayName2);
    
    const isValid = !isWeekendDay(date1) && !isWeekendDay(date2);
    console.log(`Valid Dates: ${isValid}`);
    return isValid;
  };
  

  // const isTimeDifferenceLessThanOrEqual4Hours = (startTime, endTime) => {
  //   console.log(startTime);
  //   console.log(endTime);
  //   const start = new Date(startTime);
  //   const end = new Date(endTime);
  //   console.log(start);
  //   console.log(end);

  //   const differenceInMilliseconds = end - start;
  //   const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60);
  //   console.log(`dif: ${differenceInHours}`);
  //   return differenceInHours >= 0 && differenceInHours <= 4;
  // };

  const isTimeDifferenceLessThanOrEqual4Hours = (startTime, endTime) => {

    const differenceInMilliseconds = endTime - startTime;
    const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60);

    console.log(`dif: ${differenceInHours}`);

    return differenceInHours >= 0 && differenceInHours <= 4;
  };


  const convertTime = (dt) => {
    const date = new Date(dt);
  
    const options = {
      timeZone: 'Asia/Dhaka',
      hour: '2-digit',
      minute: '2-digit'
    };
  
    const bdTime = date.toLocaleString('en', options);
  
    return bdTime.replace(/\s?[APap][Mm]$/, ''); // Remove AM/PM indicator
  }

  const checkMove=async()=>{
    const re=await MoveOrderCheckService(employeeId,`${moment(startDate).format('MM-DD-yyyy')} ${fromTime}`,moveTypeCode==='11'?`${moment(startDate2).format('MM-DD-yyyy')} ${toTime}`:`${moment(endDate).format('MM-DD-yyyy')} ${toTime}`);
    console.log(re.Application_DETAILS.length);
    if(re.Application_DETAILS.length===0){
      return false;
    }
    else{
      return true;
    }
  }

  const checkLeave=async()=>{
    const re=await CheckLeaveService(employeeId,`${moment(startDate).format('DD-MM-yyyy')}`,moveTypeCode==='11'?`${moment(startDate2).format('DD-MM-yyyy')}`:`${moment(endDate).format('DD-MM-yyyy')}`);
    if(re.Application_DETAILS.length===0){
      return false;
    }
    else{
      return true;
    }
  }
    

  //personal mpve order validation

  const getVehicle = async () => {
    const re = await GetVehicleTypeService();
    console.log(re.vehicle_type);
    setVehicleTypeList(re.vehicle_type);
  }

  const getMoveOrderType = async (id) => {
    const mv = await GetMoveOrderTypeService(id);
    console.log(mv.move_order_type);
    setMoveTypeList(mv.move_order_type);
  }
  const handleChangeStartTime = (time) => {
    console.log(time);
    setValue(time);
    // const formattedDate = convertToUTC12Hour(time);
    const formattedDate = addHoursToUTC(time, 6);
    console.log(formattedDate);
    setStartTime(formattedDate);
    const tm=convertTime(time);
    setFromTime(tm);
    console.log(tm);

  }
  const handleChangeEndTime = (time) => {
    console.log(time);
    setValue1(time);
    // const formattedDate = convertToUTC12Hour(time);
    const formattedDate = addHoursToUTC(time, 6);

    console.log(formattedDate);
    setEndTime(formattedDate);
    const tm=convertTime(time);
    setToTime(tm);
    console.log(tm);
  }
  const addHoursToUTC = (inputObject, hoursToAdd) => {
    const utcDateString = inputObject.$d.toISOString();
    const utcDate = new Date(utcDateString);
    utcDate.setUTCHours(utcDate.getUTCHours() + hoursToAdd);
    return utcDate.toISOString();
  };
  function convertToUTC12Hour(dateString) {
    const originalDate = new Date(dateString);
    const year = originalDate.getFullYear();
    const month = String(originalDate.getMonth() + 1).padStart(2, '0');
    const day = String(originalDate.getDate()).padStart(2, '0');
    let hours = originalDate.getHours();
    const minutes = String(originalDate.getMinutes()).padStart(2, '0');

    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;

    const formattedDate = `${year}-${month}-${day}T${String(hours).padStart(2, '0')}:${minutes}:00Z`;
    return formattedDate;
  }


  const handleValueChange = (newValue) => {
    const startDate = new Date(newValue.startDate);
    const endDate = new Date(newValue.endDate);

    if (!isNaN(startDate) && !isNaN(endDate)) {
      const isoDateString = startDate.toISOString();
      const isoDateString2 = endDate.toISOString();
      setStartDate(isoDateString);
      setEndDate(isoDateString2);

      setStartEndDate(newValue);

    } else {
      console.error('Invalid date format for newValue.startDate');
    }
  }
  const handleValueChange2 = (newValue) => {
    const startDate = new Date(newValue.startDate);
    const endDate = new Date(newValue.endDate);

    if (!isNaN(startDate) && !isNaN(endDate)) {
      const isoDateString = startDate.toISOString();
      const isoDateString2 = endDate.toISOString();
      setStartDate2(isoDateString);
      setEndDate2(isoDateString2);
console.log(newValue);
      setStartEndDate2(newValue);

    } else {
      console.error('Invalid date format for newValue.startDate');
    }
  }
  

  //validator
  const [createMoveOrderError, setCreateMoveOrderError] = useState({});

  const validateCreateMoveOrderError = () => {
    const createMoveOrderError = {};

    if (!placeFrom.trim()) {
      createMoveOrderError.placeFrom = "Please Enter Place From";
    }
    if (!placeTo.trim()) {
      createMoveOrderError.placeTo = "Please Enter Place To";
    }
    if (moveTypeCode === '') {
      createMoveOrderError.moveTypeCode = "Please Select Task Type";
    }
    if (startDate === '') {
      createMoveOrderError.startDate = "Please Select Start Date";
    }
    if (moveTypeCode==='11' && startDate2 === '') {
      createMoveOrderError.startDate2 = "Please Select End Date";
    }
    // if (endDate === '') {
    //   createMoveOrderError.endDate = "Please Select End Date";
    // }
    if (startTime === '') {
      createMoveOrderError.startTime = "Please Select Start Time";
    }
    if (endTime === '') {
      createMoveOrderError.endTime = "Please Select End Time";
    }
    if (!purpose.trim()) {
      createMoveOrderError.purpose = "Please Enter Purpose";
    }
    // if(moveTypeCode==="11" && !amount.trim()){
    //   createMoveOrderError.amount="Please Enter Amounts";
    // }
    if (moveTypeName === "Official" && isVehicle && vehicleTypeName === '') {
      createMoveOrderError.vehicleTypeName = "Select Vehicle Name";
    }
    if (moveTypeName === "Official" && isVehicle && vehicleTypeName === 'Others' && !vehicleName.trim()) {
      createMoveOrderError.vehicleName = "Enter Vehicle Name";
    }

    setCreateMoveOrderError(createMoveOrderError);

    return Object.keys(createMoveOrderError).length === 0;
  }

  return (
    <div className='my-16 mx-8'>
      <ToastContainer />
      <div className='h-4'></div>

      <div className="flex justify-between items-center">
        <p className=' text-xl text-black font-semibold'>Create Move Order</p>

        <button onClick={back} className=' py-1 px-10 rounded-full bg-buttonColor text-[16px] font-medium text-white'>Back</button>
      </div>

      <div className='h-4'></div>
      <InputLebel text="Task Type *" />
      <div className='h-1'></div>


      <select
        value={moveTypeCode}
        onChange={(e) => {
          const selectedMoveTypeCode = e.target.value; // Use const instead of changing a variable outside this scope
          console.log(selectedMoveTypeCode);
          console.log(typeof(selectedMoveTypeCode));

          setMoveTypeCode(selectedMoveTypeCode);

          // Assuming e contains TYPE_NAME property
          const jnf = e.target.options[e.target.selectedIndex].getAttribute('data-type-name');
          setMoveTypeName(jnf);
          console.log(`ccc : ${jnf}`);
        }}
        className="select select-bordered w-full bg-white"
      >
        <option value='' disabled>Select</option>
        {
          moveTypeList.map((e, i) => (
            <option
              key={i}
              value={e.MOT_ID}
              data-type-name={e.TYPE_NAME} // Add a data attribute to hold TYPE_NAME
            >
              {e.TYPE_NAME}
            </option>
          ))
        }
      </select>


      {createMoveOrderError.moveTypeCode && <span className={`text-red-500`}>{createMoveOrderError.moveTypeCode}</span>}
      <div className='h-2'></div>
      <div className=' flex flex-row space-x-8 items-center'>
        <div className=' w-full flex flex-col items-start'>
          <InputLebel text="From Where *" />
          <div className='h-1'></div>
          <input onChange={(e) => { setPlaceFrom(e.target.value) }} ref={placeFromRef} type="text" className=' h-12 w-full rounded-md px-4 border-[1px] border-gray-300 focus:outline-gray-400 bg-white' placeholder=' Place from go' />
          {createMoveOrderError.placeFrom && <span className={`text-red-500`}>{createMoveOrderError.placeFrom}</span>}
        </div>


        <div className='w-full flex flex-col items-start'>
          <InputLebel text="Destination *" />
          <div className='h-1'></div>
          <input onChange={(e) => { setPlaceTo(e.target.value) }} ref={placeToRef} type="text" className=' h-12 w-full rounded-md px-4 border-[1px] border-gray-300 focus:outline-gray-400 bg-white' placeholder=' Place to go' />
          {createMoveOrderError.placeTo && <span className={`text-red-500`}>{createMoveOrderError.placeTo}</span>}
        </div>

      </div>


      <div className='h-2'></div>
      {
        moveTypeCode !== '11'
          ?
          <InputLebel text="Start & End Date *" />
          :
          null

      }
      <div className='h-1'></div>
      {
        moveTypeCode !== '11'
          ?

          <Datepicker
            useRange={moveTypeCode === '11' ? true : false}
            asSingle={moveTypeCode !== '11' ? true : false}
            popoverDirection='down'

            placeholder='Select Date'
            showFooter={true}
            primaryColor={"green"}
            showShortcuts={false}
            value={startEndDate}
            onChange={handleValueChange}
            displayFormat={"DD/MM/YYYY"}
            toggleClassName={`absolute bg-buttonColor rounded-r-lg text-white right-0 h-full px-3 text-${gray400} focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed`}
            // inputClassName="w-full rounded-md focus:ring-0 font-normal bg-green-100 dark:bg-green-900 dark:placeholder:text-green-100" 
            // containerClassName=" border border-[1px] border-gray-400 w-80 rounded-md" 
            // toggleClassName=" text-gray-400"
            inputClassName={`outline-buttonColor  lg:w-full md:w-64 sm:w-60 rounded-r-lg h-12 border-[1px] border-gray-300 rounded-md px-3 bg-white`}
          // containerClassName="  " 


          />

          :
          <div className=' w-full flex flex-row space-x-4 items-center'>
            <div className=' w-full flex flex-col items-start space-y-1'>
              <InputLebel text="Start Date *" />
              <Datepicker
                useRange={false}
                asSingle={true}
                popoverDirection='down'

                placeholder='Start Date'
                showFooter={true}
                primaryColor={"green"}
                showShortcuts={false}
                value={startEndDate}
                onChange={handleValueChange}
                displayFormat={"DD/MM/YYYY"}
                toggleClassName={`absolute bg-buttonColor rounded-r-lg text-white right-0 h-full px-3 text-${gray400} focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed`}
                // inputClassName="w-full rounded-md focus:ring-0 font-normal bg-green-100 dark:bg-green-900 dark:placeholder:text-green-100" 
                // containerClassName=" border border-[1px] border-gray-400 w-80 rounded-md" 
                // toggleClassName=" text-gray-400"
                inputClassName={`outline-buttonColor lg:w-full md:w-64 sm:w-60 rounded-r-lg h-12 border-[1px] border-gray-300 rounded-md px-3 bg-white`}
              // containerClassName="  " 


              />
              {createMoveOrderError.startDate && <span className={`text-red-500`}>{createMoveOrderError.startDate}</span>}
            </div>
            <div className=' w-full flex flex-col items-start space-y-1'>
              <InputLebel text="End Date *" />
              <Datepicker
                useRange={false}
                asSingle={true}
                popoverDirection='down'

                placeholder='End Date'
                showFooter={true}
                primaryColor={"green"}
                showShortcuts={false}
                value={startEndDate2}
                onChange={handleValueChange2}
                displayFormat={"DD/MM/YYYY"}
                toggleClassName={`absolute bg-buttonColor rounded-r-lg text-white right-0 h-full px-3 text-${gray400} focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed`}
                // inputClassName="w-full rounded-md focus:ring-0 font-normal bg-green-100 dark:bg-green-900 dark:placeholder:text-green-100" 
                // containerClassName=" border border-[1px] border-gray-400 w-80 rounded-md" 
                // toggleClassName=" text-gray-400"
                inputClassName={`outline-buttonColor  lg:w-full md:w-64 sm:w-60 rounded-r-lg h-12 border-[1px] border-gray-300 rounded-md px-3 bg-white`}
              // containerClassName="  " 


              />
              {createMoveOrderError.startDate2 && <span className={`text-red-500`}>{createMoveOrderError.startDate2}</span>}
            </div>

          </div>

      }
      <div className=' flex flex-row space-x-4'>
        {
          moveTypeCode !== '11' ?
            <div>
              {createMoveOrderError.startDate && <span className={`text-red-500`}>{createMoveOrderError.startDate}</span>}
              {/* {createMoveOrderError.endDate && <span className={`text-red-500`}>{createMoveOrderError.endDate}</span>} */}
            </div>
            :
            null
        }
      </div>
      <div className='h-2'></div>

      <div className=' flex flex-row space-x-8 items-center'>
        <div className=' w-full flex flex-col items-start'>
          <InputLebel text="Time Out*" />
          <div className='h-1'></div>
          <LocalizationProvider

            dateAdapter={AdapterDayjs}>

            <TimePicker
              className=' w-full   focus:outline-none'
              placeholder="select start Time"
              label="Start Time"
              value={value}

              onChange={(newValue) => { handleChangeStartTime(newValue); }}
            />

          </LocalizationProvider>
          {createMoveOrderError.startTime && <span className={`text-red-500`}>{createMoveOrderError.startTime}</span>}
        </div>


        <div className=' w-full flex flex-col items-start'>
          <InputLebel text="Time In*" />
          <div className='h-1'></div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>

            <TimePicker
              className=' w-full '

              label="End Time"
              value={value1}

              onChange={(newValue) => { handleChangeEndTime(newValue); }}
            />

          </LocalizationProvider>
          {createMoveOrderError.endTime && <span className={`text-red-500`}>{createMoveOrderError.endTime}</span>}
        </div>


      </div>


      <div className='h-2'></div>

      <div className=' w-full flex flex-col items-start'>
        <InputLebel text="Purpose *" />
        <div className='h-1'></div>
        <input onChange={(e) => { setPurpose(e.target.value) }} ref={purposeRef} type="text" className=' h-12 w-full rounded-md px-4 border-[1px] border-gray-300 focus:outline-gray-400 bg-white' placeholder=' Enter purpose' />
      </div>
      {createMoveOrderError.purpose && <span className={`text-red-500`}>{createMoveOrderError.purpose}</span>}



      <div className='h-3'></div>
      {
        moveTypeCode === '11' ?

          <div>
            <div className=' flex flex-row space-x-8 items-start'>
              <div className='w-full flex flex-col items-start'>
                <InputLebel text="Cash Amount" />
                <div className='h-1'></div>
                <input onChange={(e) => { setAmount(e.target.value) }} ref={amountRef} type="text" className=' h-12 w-full rounded-md px-4 border-[1px] border-gray-300 focus:outline-gray-400' placeholder=' Enter cash amount' />
                {/* {createMoveOrderError.amount && <span className={`text-red-500`}>{createMoveOrderError.amount}</span>} */}
              </div>

              <button
                onClick={handleVehicleChange}
                className=' border-[1px] border-gray-300 mt-6 py-[10px] bg-slate-50 rounded-md  px-2 w-full flex flex-row justify-between items-center pr-4'>
                <p className=' text-lg text-black'>Do you need vehicle</p>
                <div className={`h-5 w-5 rounded-full ${isVehicle ? "border-none bg-green-500 " : "border-[0.2px] border-gray-300 bg-white "}  p-[2px] flex justify-center items-center`}>
                  {
                    isVehicle ?
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 text-white">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                      </svg>

                      :
                      null

                  }

                </div>
              </button>


            </div>
            <div className='h-2'></div>

            {
              isVehicle ?
                <div>
                  <InputLebel text="Select Vehicle Type *" />
                  <div className='h-1'></div>
                  <select value={vehicleTypeName} onChange={(e) => {
                    selectedVehicleTypeName = e.target.value;
                    setVehicleTypeName(selectedVehicleTypeName);

                  }} className="select select-bordered w-full ">
                    <option value='' disabled selected>Select</option>
                    {
                      vehicleTypeList.map((e, i) => (
                        <option key={i} value={e.VEHICLE_NAME}>{e.VEHICLE_NAME}</option>
                      ))
                    }

                  </select>
                  {createMoveOrderError.vehicleTypeName && <span className={`text-red-500`}>{createMoveOrderError.vehicleTypeName}</span>}
                  <div className='h-2'></div>
                  {
                    vehicleTypeName === "Others"
                      ?
                      <div>
                        <InputLebel text="Others Vehicle Name *" />
                        <div className='h-1'></div>
                        <input onChange={(e) => { setVehicleName(e.target.value) }} ref={vehicleNameRef} type="text" className=' h-12 w-full rounded-md px-4 border-[1px] border-gray-300 focus:outline-gray-400' placeholder='Enter vehicle name here' />
                        {createMoveOrderError.vehicleName && <span className={`text-red-500`}>{createMoveOrderError.vehicleName}</span>}
                      </div>
                      :
                      null
                  }
                </div>
                :
                null
            }
          </div>
          :
          null
      }

      <div className='h-12'></div>
      <button
        onClick={create}
        className=' w-full rounded-md h-12 flex justify-center items-center text-white text-sm bg-buttonColor'
      >Submit</button>

      {
        showAlert === true ?
          <div className=' flex flex-col items-start'>
            <div className='h-12'></div>
            <div className="alert alert-success shadow-lg mt-4">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6 text-white" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                <span className=' text-white font-semibold text-sm'>Created Successful</span>
              </div>
            </div>
            <div className='h-12'></div>
          </div>
          :
          null
      }




    </div>
  )
}
