import { baseUrl } from "../../utils/path";



const LeaveApproverService = async(id, leaveId)=>{

  const url = `${baseUrl}approver_details_by_module`;

  console.log("id", id)
  console.log("leave id: ", leaveId)
    
  const response = await fetch(url,
      {
          method: "POST",
          headers: {
            
            "Content-Type": "application/json",
            'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
          },
          body: JSON.stringify({
              "ORIGINATOR": id,
              "MODULE_TYPE": "LEAVE",
              "OBJECT_ID": leaveId
          }),
        }
      );
      // if (!response.ok) {
      //     throw new Error("fetching is not successful");
      //   }
        const data = await response.json();
        console.log(`users: ${data}`);
        return data;

   
}
export default LeaveApproverService;
