


const AttendanceUploadToSapService = async (
    url
) => {
    // console.log(`uid: ${userId} lat ${lat}  long ${long} ra${radius} cc${companyCode}  pc${PlantCode}`);
   console.log(url);

   const response = await fetch(url,
    {
        // mode: 'no-cors',
        // mode: "cors",
        method: "GET",
        headers: {
            "Content-Type": "application/json",
           
            'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_SU}:${process.env.REACT_APP_SP}`),
        },
        
    }
);
    // if (!response.ok) {
    //     throw new Error("fetching is not successful");
    //   }
    const data = await response.json();

    return data;
};
export default AttendanceUploadToSapService;
