import React, { useState, useEffect, useRef } from 'react'
import InputLebel from '../../common/InputLebel';
import AdminInfoService from '../../services/AdminInfoService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GetApproverService from '../../move_order/service/GetApproverService';
import CreateRequisitionService from '../service/CreateRequistionService';
import RequisitionItemInsertionService from '../service/RequisitionItemInsertionService';
import { useNavigate } from 'react-router-dom';
import { baseUrl } from '../../utils/path';
import { useHomePageContext } from '../../components/home_component/HomePageContext';
import { useRequisitionContext } from '../context/RequisitionContext';
const infoUrl = `${baseUrl}my_info`;
const requisitionTypeList = [
    'General',
    'IT',
    'Supply Chain',
    'Heavy Equipment'
];
export default function CreateRequisitionPage() {
    const itemDescriptionRef = useRef(null);
    const itemPriceRef = useRef(null);
    const itemQuantityRef = useRef(null);
    const itemUomRef = useRef(null);


    const [reqType, setReqType] = useState('');

    const [cheque, setCheque] = useState(false);
    const [cash, setCash] = useState(false);
    const [paymentType, setPaymentType] = useState('');
    const [companyCode, setCompanyCode] = useState('');
    const [plantCode, setPlantCode] = useState('');
    const [approverId, setApproverId] = useState('');
    const [userId, setUserId] = useState('');
    const [employeeId, setEmployeeId] = useState('');
    const [requisitionItemList, setRequisitionItemList] = useState([]);
    const [openClose, setOpenClose] = useState(false);
    const [requisitionItemDescription, setRequisitionItemDescription] = useState('');
    const [itemPrice, setItemPrice] = useState('');
    const [itemQuanity, setItemQuantity] = useState('');
    const [itemUom, setitemUom] = useState('');
    const [itemError, setItemError] = useState({});
    const [inWord, setInWord] = useState('');
    const [showAlert, setShowAlert] = useState(null);

    const navigate = useNavigate();
    const {menu,setMenu}=useHomePageContext();
    const {requisitionPageNo, setRequisitionPageNo,details,setDetails}=useRequisitionContext();
    // const [designation,setDesignation]=useState('');
    const back=()=>{
        setRequisitionPageNo(1);
    }

    useEffect(() => {
        const id = localStorage.getItem('userId');
        const eid = localStorage.getItem('empId');
        setUserId(id);
        setEmployeeId(eid);
        getAdminInfo(id);

    }, []);
    useEffect(() => {
        const totalAmount = calculateTotalPrice();
        console.log("total", totalAmount);
        const totalAmountInWords = test(totalAmount);
        console.log("inWord", totalAmountInWords);
        setInWord(totalAmountInWords);
    }, [requisitionItemList]);


    const getAdminInfo = async (uid) => {
        const admin = await AdminInfoService(infoUrl, uid);

        setCompanyCode(admin.EMP_DETAILS[0].COMPANY_CODE);
        setPlantCode(admin.EMP_DETAILS[0].PLANT_CODE);
    }

    const addItem = (item) => {
        setRequisitionItemList([...requisitionItemList,
        {
            "item_desc": item.item_desc,
            "item_amount": parseFloat(item.item_amount),
            "item_quantity": item.item_quantity,
            "item_uom": item.item_uom
        }
        ]);
    };

    // Function to update an item at a specific index
    const updateItemAtIndex = (index, newItem) => {
        requisitionItemList[index] = {
            ...requisitionItemList[index], ...{
                "item_desc": newItem.item_desc,
                "item_amount": parseFloat(newItem.item_amount),
                "item_quantity": newItem.item_quantity,
                "item_uom": newItem.item_uom
            }
        };
    };

    // Example usage
    // addItem({ "item_desc": "new item", "item_amount": 100 });
    // updateItemAtIndex(3, { "item_amount": 999 });
    const [index, setIndex] = useState();
    const [isUpdate, setIsUpdate] = useState(false);
    const update = (index, des, prc) => {
        setIsUpdate(true);
        setIndex(index);
        setRequisitionItemDescription(des);
        itemPriceRef.current.value = prc;
        setItemPrice(prc);
        setOpenClose(true);
    }

    const updateFinal = () => {
        updateItemAtIndex(index, { "item_desc": requisitionItemDescription, "item_amount": itemPrice, "item_quantity": itemQuanity, "item_uom": itemUom });
        setIsUpdate(false);
        setOpenClose(false);
        setRequisitionItemDescription('');
        itemPriceRef.current.value = '';
        setItemPrice('');
    }

    const calculateTotalPrice = () => {
        const totalAmount = requisitionItemList.reduce((total, item) => {
            const amount = item.item_amount || 0;
            return total + (amount * item.item_quantity);
        }, 0);

        return totalAmount;
    };
   
    
    const numbersToWords = {
        0: "zero",
        1: "one",
        2: "two",
        3: "three",
        4: "four",
        5: "five",
        6: "six",
        7: "seven",
        8: "eight",
        9: "nine",
        10: "ten",
        11: "eleven",
        12: "twelve",
        13: "thirteen",
        14: "fourteen",
        15: "fifteen",
        16: "sixteen",
        17: "seventeen",
        18: "eighteen",
        19: "nineteen",
        20: "twenty",
        30: "thirty",
        40: "forty",
        50: "fifty",
        60: "sixty",
        70: "seventy",
        80: "eighty",
        90: "ninety",
      };
    
      function test(n) {
        if (n < 0)
          return false;
       const  single_digit = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine']
      const   double_digit = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen']
      const   below_hundred = ['Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety']
        if (n === 0) return 'Zero Taka Only'
        function translate(n) {
          let  word = ""
            if (n < 10) {
                word = single_digit[n] + ' '
            }
            else if (n < 20) {
                word = double_digit[n - 10] + ' '
            }
            else if (n < 100) {
              const  rem = translate(n % 10)
                word = below_hundred[(n - n % 10) / 10 - 2] + ' ' + rem
            }
            else if (n < 1000) {
                word = single_digit[Math.trunc(n / 100)] + ' Hundred ' + translate(n % 100)
            }
            else if (n < 1000000) {
                word = translate(parseInt(n / 1000)).trim() + ' Thousand ' + translate(n % 1000)
            }
            else if (n < 1000000000) {
                word = translate(parseInt(n / 1000000)).trim() + ' Million ' + translate(n % 1000000)
            }
            else {
                word = translate(parseInt(n / 1000000000)).trim() + ' Billion ' + translate(n % 1000000000)
            }
            return word
        }
      const   result = translate(n) 
        return result.trim()+' taka only.'
    }

    const deleteItemAtIndex = (index) => {
        if (index >= 0 && index < requisitionItemList.length) {
            const updatedList = [...requisitionItemList];
            updatedList.splice(index, 1);
            setRequisitionItemList(updatedList);
        }
        calculateTotalPrice();
        setRequisitionItemDescription('');
        itemPriceRef.current.value = '';
        setItemPrice('');
    };

    const validateItem = () => {
        const itemError = {};
        if (requisitionItemDescription === '') {
            itemError.requisitionItemDescription = 'Please Enter Description';
        }
        if (!itemQuanity.trim()) {
            itemError.itemQuanity = 'Enter Quantity';
        }
        if (!itemUom.trim()) {
            itemError.itemUom = 'Enter UOM';
        }
        // if(!itemPrice.trim()){
        //     itemError.itemPrice='Please Enter Item Price';
        // }
        setItemError(itemError);
        return Object.keys(itemError).length === 0;

    }

    const [reqError,setReqError]=useState({});

    const validateReq=()=>{
        const reqError={};
        if(reqType===''){
            reqError.reqType="Please Select Requisition Type";
        }
        if(requisitionItemList.length===0){
            reqError.requisitionItemList="Please Add Requisition Item";
        }
        // if(paymentType===''){
        //     reqError.paymentType="Select Payment Type";
        // }
        setReqError(reqError);
        return Object.keys(reqError).length===0;
    }
    const showToastError = (message) => {
        toast.error(message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT }); // autoClose is in milliseconds
    };
      const showToastSuccess = (message) => {
        toast.success(message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT }); // autoClose is in milliseconds
    };

    const reqreationCreateCycle=async()=>{
        if(validateReq()){
            let rt;
            if(reqType==="IT"){
                rt="REQUISITION IT";
            }
            else if(reqType==="General"){
                rt="REQUISITION GENERAL"
            }
            else if(reqType==="Supply Chain"){
                rt="REQUISITION SUPPLY CHAIN"
            }
            else if(reqType==="Heavy Equipment"){
                rt="REQUISITION HEAVY EQUIPMENT"
            }
            const re=await GetApproverService(employeeId,rt,0);
          
          
            if(re.EMPLOYEE_LIST.length===0){
                showToastError('No Approver Exist For This User');
              }
              else{
                setApproverId(re.EMPLOYEE_LIST[0].APPROVER_ID);

                const creq=await CreateRequisitionService(employeeId,inWord,re.EMPLOYEE_LIST[0].APPROVER_ID,paymentType,plantCode,companyCode,reqType);
                const reqId=creq.REQ_ID;
                const newArray=requisitionItemList.map(item=>({
                    "REQ_ID": reqId,
                "ITEM_DESC": item.item_desc,
                "ITEM_QUANTITY": item.item_quantity,
                "UOM":item.item_uom,
                "UNIT_PRICE": item.item_amount
                }));
                const item=RequisitionItemInsertionService(paymentType,newArray);
                showToastSuccess('Requisition Created Successfully');
                clearValue();
                setShowAlert(true);
                setTimeout(() => {
                    setRequisitionPageNo(1)
                    // navigate('/my-requisition');
                  }, 3200);
              }
            // Delay of 1 seconds
        }
    }

    const clearValue=()=>{
        setReqType('');
        setPaymentType('');
        setCash(false);
        setCheque(false);
        setRequisitionItemList([]);
    }

    // const handleItemDescriptionChange = (e) => {
    //     const inputText = e.target.value;

    //     setRequisitionItemDescription(inputText);

    // }

    const maxCharLimit = 100;

    const handleItemDescriptionChange = (e) => {
        const inputText = e.target.value;
      
        // Check if the input exceeds the character limit
        if (inputText.length <= maxCharLimit) {
          setRequisitionItemDescription(inputText);
          setShowAlert(null); // Clear any previous character limit exceeded message
        } 
        else {
          setShowAlert('Character limit exceeded!');
        }
      
        console.log('Length:', requisitionItemDescription.length);
        console.log('Limit:', maxCharLimit);
    };
      
      

    const submit = () => {
        if (validateItem()) {
            // if((iouItemDescription!=='' || itemPrice!=='')){
            //     updateItemAtIndex(index,{ "item_desc": iouItemDescription, "item_amount": itemPrice })
            // }
            // else{
            //     addItem({ "item_desc": iouItemDescription, "item_amount": itemPrice });
            // }
            addItem({ "item_desc": requisitionItemDescription, "item_amount": itemPrice, "item_quantity": itemQuanity, "item_uom": itemUom });
            setRequisitionItemDescription('');
            itemPriceRef.current.value = '';
            itemQuantityRef.current.value = '';
            itemUomRef.current.value = '';
            setItemPrice('');
            setItemQuantity('');
            setitemUom('');
            setOpenClose(false);
        }
    }

    const handleCheque = () => {
        if (cash) {
            setCash(false);
            setCheque(true);
            setPaymentType('cheque');
        }
        else {
            setCheque(true);
            setPaymentType('cheque');
        }
    }
    const handleCash = () => {
        if (cheque) {
            setCheque(false);
            setCash(true);

            setPaymentType('cash');
        }
        else {
            setCash(true);
            setPaymentType('cash');
        }
    }

    return (
        <div className=' my-20 px-8'>
          <ToastContainer />
          <div className="flex justify-between items-center">
            <p className=" text-lg font-bold text-black">Create Requisition</p>

            <button onClick={back} className=' py-1 px-10 rounded-full bg-buttonColor text-[16px] font-medium text-white'>Back</button>
          </div>
          
            <div className='h-3'></div>
            <InputLebel text="Select Requisition Type *" />
            <div className='h-1'></div>
            <select
                value={reqType}
                onChange={(e) => {
                    const selectedMoveTypeCode = e.target.value; // Use const instead of changing a variable outside this scope
                    setReqType(selectedMoveTypeCode);


                }}
                className="select select-bordered w-full bg-white"
            >
                <option value='' disabled>Select</option>
                {
                    requisitionTypeList.map((e, i) => (
                        <option
                            key={i}
                            value={e}
                        // Add a data attribute to hold TYPE_NAME
                        >
                            {e}
                        </option>
                    ))
                }
            </select>
            {reqError.reqType && <span className={`text-red-500`}>{reqError.reqType}</span>}
            <div className='h-4'></div>
            <div className="overflow-x-auto ">
                {
                    requisitionItemList.length === 0 ?
                        <div className='  flex-col  w-full h-32 flex justify-center items-center'>
                            <p className=' text-lg font-semibold text-gray-500'>ADD ITEM</p>
                            {/* {iouError.iouItemList && <span className={`text-red-500`}>{iouError.iouItemList}</span>} */}
                            {reqError.requisitionItemList && <span className={`text-red-500`}>{reqError.requisitionItemList}</span>}
                        </div>
                        :

                        <table className="table w-full mt-1 mb-10">
                            {/* head */}
                            <thead>
                                <tr>
                                    <td></td>
                                    <td>Details</td>
                                    <th>Quantity*Price</th>
                                    <th>UOM</th>
                                    <th>Total</th>
                                    <th>Action</th>

                                </tr>
                            </thead>


                            <tbody>

                                {

                                    requisitionItemList.map((e, index) =>

                                        <tr key={index}>
                                            <td></td>

                                            <td>{e.item_desc}</td>

                                            <td>{e.item_quantity}*{e.item_amount}</td>
                                            <td>{e.item_uom}</td>
                                            <td>{e.item_quantity * e.item_amount}</td>
                                            <td>
                                                <div className=' flex flex-row space-x-4 items-center'>
                                                    <button
                                                        onClick={() => update(index, e.item_desc, e.item_amount, e.item_quantity, e.item_uom)}
                                                    >
                                                        <svg

                                                            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                                        </svg>

                                                    </button>
                                                    <button
                                                        onClick={() => { deleteItemAtIndex(index) }}
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                                        </svg>

                                                    </button>
                                                </div>

                                            </td>

                                        </tr>
                                    )
                                }

                            </tbody>
                        </table>}
            </div>
            <div className='h-3'></div>
            <div className='w-full flex flex-row items-center  justify-between'>
                <p className=' text-black font-semibold text-lg'>Total:</p>
                <p className=' text-black font-semibold text-lg'>{calculateTotalPrice()} ৳</p>
            </div>

            <div className='h-2'></div>
            
            {/* {inWord !== '' && (
                <div className=' h-14 w-full border-[1px] border-gray-300 rounded-md flex px-4 justify-start items-center'>
                {
                    inWord
                }
                </div>
            )} */}

            {typeof inWord === 'string' && inWord.trim() !== '' && inWord !== 'Zero Taka Only' && (
                <div className='h-14 w-full border-[1px] border-gray-300 rounded-md flex px-4 justify-start items-center'>
                    {inWord}
                </div>
            )}

            <div className="h-3"></div>

            <div className=' flex flex-row space-x-6 items-center'>
                <button
                    onClick={handleCash}
                    className=' flex flex-row space-x-4 items-center'>
                    <p className=' text-lg text-black font-semibold'>Cash</p>
                    <div className={`h-6 w-6 rounded-full border-[0.3px]  p-[1px] flex justify-center items-center ${cash ? " bg-green-500" : "bg-white border-green-500"}`}>
                        {
                            cash ?
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 text-white">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                </svg>

                                :
                                null
                        }
                    </div>
                </button>
                <button
                    onClick={handleCheque}
                    className=' flex flex-row space-x-4 items-center'>
                    <p className=' text-lg text-black font-semibold'>Cheque</p>
                    <div className={`h-6 w-6 rounded-full border-[0.3px] flex justify-center items-center ${cheque ? " bg-green-500" : "bg-white border-green-500"}`}>
                        {
                            cheque ?
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 text-white">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                </svg>

                                :
                                null
                        }
                    </div>
                </button>

            </div>
            {/* {reqError.paymentType && <span className={`text-red-500`}>{reqError.paymentType}</span>} */}

            <div className='h-3'></div>
            {/* <div className=' h-14 w-full border-[1px] border-gray-300 rounded-md flex px-4 justify-start items-center'>
                {
                    inWord
                }
            </div> */}
            
            <div className='h-16'></div>
            <div className='px-40 flex flex-row justify-between items-center'>
                <button
                    onClick={reqreationCreateCycle}
                    className=' w-60 h-12 rounded-full flex justify-center items-center bg-buttonColor text-white'>SUBMIT</button>
                <button onClick={() => { setOpenClose(true) }} className=' w-60 h-12 rounded-full flex justify-center items-center bg-blue-500 text-white'>ADD ITEM</button>
            </div>
            <div className='h-10'></div>
            {
                showAlert ===true?
                <div className="alert alert-success shadow-lg mt-4">
                <div>
                <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6 text-white" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                <span className=' text-white font-semibold text-sm'>Requisition Created Successfully</span>
                </div>
                </div>
                :
                null
            }
            <div className='h-10'></div>
            <input type="checkbox" id="my-modal-11" className="modal-toggle" checked={openClose} />
            <div className="modal">
                <div className="modal-box bg-white">



                    <div className=' w-full h-96 flex  flex-col items-start bg-white'>
                        <div className='w-full flex flex-row justify-end items-end'>
                            <button onClick={() => { setOpenClose(false); if (isUpdate) { setIsUpdate(false) } }}
                                className=' w-8 h-8 rounded-full bg-red-500 flex justify-center items-center'
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-white">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>

                            </button>
                        </div>
                        <div className='h-3'></div>
                        <InputLebel text="Description *" /> 
                        <div className='h-1'></div>
                        <textarea
                            value={requisitionItemDescription}
                            onChange={handleItemDescriptionChange}
                            className='px-2 py-1 w-full h-24 border-[1px] bg-white border-gray-300 rounded-md focus:outline-blue-300'>

                        </textarea>
                        <div className="flex items-center justify-between w-full">
                            <p>
                                {showAlert && (
                                    <p style={{ color: 'red' }}>{showAlert}</p>
                                )}
                            </p>
                            
                            <p style={{ color: requisitionItemDescription.length < maxCharLimit ? 'black' : 'red' }}>
                                {requisitionItemDescription.length} <span className='text-black'>/ {maxCharLimit}</span>
                            </p>
                        </div>
                        
                        {itemError.requisitionItemDescription && <span className={`text-red-500`}>{itemError.requisitionItemDescription}</span>}
                        <div className='h-5'></div>
                        <div className=' w-full flex flex-row space-x-6 items-center'>
                            <div className=' flex flex-col items-start w-full'>
                                <InputLebel text="Quantity *" />
                                <div className='h-1'></div>
                                <input
                                 min={1}
                                    type='number'
                                    ref={itemQuantityRef}
                                    onChange={(e) => { setItemQuantity(e.target.value) }}
                                    className='border-[1px] px-2 border-gray-300 bg-white rounded-md w-full h-12 focus:outline-blue-300'
                                />
                                {itemError.itemQuanity && <span className={`text-red-500`}>{itemError.itemQuanity}</span>}
                            </div>
                            <div className=' flex flex-col items-start w-full'>
                                <InputLebel text="UOM *" />
                                <div className='h-1'></div>
                                <input
                                    type='text'
                                    ref={itemUomRef}
                                    onChange={(e) => { setitemUom(e.target.value) }}
                                    className='border-[1px] px-2 border-gray-300 bg-white rounded-md w-full h-12 focus:outline-blue-300'
                                />
                                {itemError.itemUom && <span className={`text-red-500`}>{itemError.itemUom}</span>}
                            </div>
                        </div>
                        <div className='h-5'></div>
                        <InputLebel text="Taka " />
                        <div className='h-1'></div>
       

                        <input
                        min={1}
                            type='number'
                            ref={itemPriceRef}
                            onChange={(e) => { setItemPrice(e.target.value) }}
                            className='border-[1px] px-2 border-gray-300 bg-white rounded-md w-full h-12 focus:outline-blue-300'
                        />
                        {/* {itemError.itemPrice && <span className={`text-red-500`}>{itemError.itemPrice}</span>} */}
                        <div className='h-5'></div>
                        <button
                            onClick={isUpdate ? updateFinal : submit}
                            className=' w-full h-10 rounded-full bg-buttonColor text-white flex justify-center items-center'>{
                                isUpdate //true false korte hbe
                                    ?
                                    "UPDATE" :
                                    "SUBMIT"
                            }</button>



                    </div>




                </div>
            </div>
        </div>
    )
}
