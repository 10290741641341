import React,{useEffect, useState,useRef} from 'react'

import Loading from '../loader/Loading';
import { useNavigate } from 'react-router-dom';
import OfficeTimeListService from '../../services/OfficeTimeListService';
import { baseUrl } from '../../utils/path';
import OfficeTimeSearchService from './service/OfficeTimeSearchService';
import OfficeTimeSearchByPlant from './service/OfficeTimeSearchByPlant';
const url=`${baseUrl}attendance_setup_list`;

export default function OfficeTimePage() {
    const navigate = useNavigate();
    const [timeList,setTimeList]=useState([]);
    const [isLoading,setIsLoading]=useState(true);
  
    useEffect(() => {
       
        getTimeList();

    },[] );

    const getTimeList=async()=>{
        const list=await OfficeTimeListService(url);
        setTimeList(list.details_list);
        setIsLoading(false);
    }

    const companyRef=useRef(null);
    const plantRef=useRef(null);
  
    const [companyKey,setCompanyKey]=useState('');
    const [plantKey,setPlantKey]=useState('');

    const searchCompanyCode=async()=>{
        setIsLoading(true);
        try {
            const re=await OfficeTimeSearchService(companyKey);
            console.log(re.details_list.length);
            if (re && re.details_list) {
                setTimeList(re.details_list);
            } else {
              setTimeList([]); // Set an empty array if data is not available
            }
          } catch (error) {
            console.error('Error fetching data:', error);
            // Handle the error (e.g., show an error message)
          } finally {
            companyRef.current.value='';
    setCompanyKey('');
    setIsLoading(false);
          }
    
   
   
      }
    
    
    
    const searchPlantCode = async () => {
        setIsLoading(true);
        try {
          const re = await OfficeTimeSearchByPlant(plantKey);
          if (re && re.details_list) {
            setTimeList(re.details_list);
          } else {
            setTimeList([]); // Set an empty array if data is not available
          }
        } catch (error) {
          console.error('Error fetching data:', error);
          // Handle the error (e.g., show an error message)
        } finally {
          plantRef.current.value = '';
          setPlantKey('');
          setIsLoading(false);
        }
      };
 

  const handleClick = (office) => {
   
    
    navigate('/add-time', { state: { office } }); // Pass the props as state
  };
  return (
    <div className=' my-16 flex flex-col items-start space-y-20 '>
    

{/* <div className='w-full flex flex-row items-center justify-between'>
<div className='    h-16 justify-center items-start    '>
        <button onClick={()=>{navigate('/add-time')}} className=' w-auto py-2  md:mx-8 px-4  mt-2 rounded-md  text-white font-semibold bg-green-500'>
            ADD OFFICE TIME
        </button>
    </div>
        <div className='md:mx-8 px-4 mt-2  flex flex-row space-x-4 items-center'>
            <input onChange={(e)=>{setSearchKey(e.target.value)}} ref={searchRef} type="text" className=' px-2 h-10 rounded-md w-44 border-[0.2px] border-gray-400 placeholder:text-sm placeholder:text-gray-400' placeholder=' Company/Plant Name or Code' />
            <button className=' bg-green-500 h-10 w-16 flex justify-center items-center text-white rounded-md'>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
</svg>

            </button>
        </div>
        </div> */}

<div className='w-full flex flex-row items-center justify-between'>
        <div className='  justify-center items-start    '>
        <button onClick={()=>{navigate('/add-time')}} className=' w-auto py-3  md:mx-8 px-5  mt-2 rounded-md flex items-center space-x-2 text-white font-semibold bg-buttonColor'>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
              <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5" />
            </svg>

            <p>ADD OFFICE TIME</p>
        </button>
        </div>
        <div className='md:mx-8 px-4 mt-2  flex flex-row space-x-4 items-center'>
            <input onChange={(e)=>{setCompanyKey(e.target.value)}} onKeyPress={(e) => { if (e.key === 'Enter') searchCompanyCode(); }} ref={companyRef} type="text" className=' px-2 h-10 rounded-md w-44 border-[0.2px] border-gray-400 placeholder:text-sm placeholder:text-gray-400 bg-white focus:outline-none' placeholder=' Company Code' />
            <button
            onClick={()=>{searchCompanyCode()}}
            className=' bg-buttonColor h-10 w-16 flex justify-center items-center text-white rounded-md'>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
</svg>

            </button>
        </div>
        <div className='md:mx-8 px-4 mt-2  flex flex-row space-x-4 items-center'>
            <input onChange={(e)=>{setPlantKey(e.target.value)}}  onKeyPress={(e) => { if (e.key === 'Enter') searchPlantCode(); }} ref={plantRef} type="text" className=' px-2 h-10 rounded-md w-44 border-[0.2px] border-gray-400 placeholder:text-sm placeholder:text-gray-400 bg-white focus:outline-none' placeholder=' Plant Code' />
            <button
             onClick={()=>{searchPlantCode()}}
            className=' bg-buttonColor h-10 w-16 flex justify-center items-center text-white rounded-md'>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
</svg>

            </button>
        </div>
        </div>

{
isLoading?<Loading/>:

timeList.length===0?
    <div className=' w-full h-screen flex justify-center items-center'>
        <p className=' text-xl text-red-500 font-bold'>No Data Found</p>
    </div>
    :

<div className="overflow-x-auto   mt-36 md:w-full w-96 ">
        <div className="inline-block min-w-full md:px-8 mx-0">
            <div className=" shadow overflow-hidden">
                <table className="">
                    <thead className="bg-bgTableHeader text-tableHeaderText shadow-sm h-14">
                        <tr>
                        <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">EDIT</th>
                            
                            <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">COMPANY CODE</th>
                            <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">COMPANY NAME</th>
                            {/* Add the rest of the columns */}
                            <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">PLANT CODE</th>
                            <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">PLANT NAME</th>
                            <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">OFFICE START TIME</th>
                            <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">OFFICE END TIME</th>
                            {/* Add the rest of the columns */}
                            <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">OFFICE BUFFER TIME</th>
                            <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">WS RULE</th>
                        </tr>
                    </thead>
                    <tbody className=" divide-y divide-borderColor">
                   { 
                  
                  timeList.map((office,index)=>{
                  
                    return(
                        <tr key={index}  className='bg-white'>
                    <td className="px-6 py-4 whitespace-nowrap">
                    <button onClick={()=>{handleClick(office)}} >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
<path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
</svg>
                    </button>

                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">{office.COMPANY_CODE}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{office.COMPANY_NAME}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{office.PLANT_CODE}</td>
                                    {/* Render the rest of the columns */}
                                    <td className="px-6 py-4 whitespace-nowrap">{office.PLANT_NAME}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{office.OFFICE_START_TIME}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{office.OFFICE_END_TIME}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{office.OFFICE_BUFFER_TIME}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{office.PSG===90?"Head Office":"Outside Head Office"}</td>
                                    {/* Render the rest of the columns */}
                                  
                                   
                                </tr>
                    )
                  })
                                
                                }
                           

                    </tbody>
                  
                </table>
            </div>
        </div>
    </div>
    
    }

</div>
  )
}