import { baseUrl } from "../../../utils/path";

  

  const GetDailyAttendanceTotal= async(date)=>{
    console.log("total date: ", date)

    const url=`${baseUrl}attendance__header_total`;

    const response = await   fetch(url,
        {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
            },
            body: JSON.stringify({
              "ADATE": date 

            }),
           
          }
        );
        // if (!response.ok) {
        //     throw new Error("fetching is not successful");
        //   }
        
          const data = await response.json();
         
          return data;

     
}
export default GetDailyAttendanceTotal
