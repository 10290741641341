import { baseUrl } from "../../utils/path";

const url=`${baseUrl}holiday_insertion`;

const UploadHolidayService = async (HOLIDAY_DATE, DAY,COMMENTS,ID,FILE_NAME,COMPANY_CODE,PLANT_CODE,PSG) => {
    // console.log(`uid: ${userId} lat ${lat}  long ${long} ra${radius} cc${companyCode}  pc${PlantCode}`);
    console.log(`em:${HOLIDAY_DATE} se: ${DAY} st: ${COMMENTS} md: ${ID} au: ${FILE_NAME} ap: ${COMPANY_CODE} pt: ${PLANT_CODE}`);
    const response = await fetch(url,
        {
            method: "POST",
            headers: {

                "Content-Type": "application/json",
                'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
            },
            body: JSON.stringify({
                "HOLIDAY_DATE": HOLIDAY_DATE,
                "DAY": DAY,
                "COMMENTS":COMMENTS,
                "ID":ID,
                "FILE_NAME":FILE_NAME,
                "COMPANY_CODE":COMPANY_CODE,
                "PLANT_CODE":PLANT_CODE,
                "PSG":PSG
            }),
        }
    );
    // if (!response.ok) {
    //     throw new Error("fetching is not successful");
    //   }
    const data = await response.json();
    console.log("status: ", data);
    
    return data;


}
export default UploadHolidayService
