import { baseUrl } from "../utils/path";

const url = `${baseUrl}update_approval_template_name`;

const ApprovalEditTemplateName = async(z_data, stage_name, am_id)=>{

  console.log(`id:${z_data} stage: ${stage_name} am_id: ${am_id}`);
    
  const response = await fetch(url,
      {
          method: "POST",
          headers: {
            
            "Content-Type": "application/json",
            'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
          },
          body: JSON.stringify({
            "Z_DATA": z_data,
            "STAGE_NAME": stage_name,
            "AM_ID": am_id
          }),
        }
      );
      // if (!response.ok) {
      //     throw new Error("fetching is not successful");
      //   }
        const data = await response.json();
        console.log(`template changes data: ${data['SUCCESS']}`);
        return data['SUCCESS'];

   
}
export default ApprovalEditTemplateName
