import { baseUrl } from "../../utils/path";



const CreateRequisitionService = async (empId,inword,approverId,payment,plantCode,companyCode,reqType) => {

   const url=`${baseUrl}emp_requisition_insert`;
    // console.log(`em:${empId} se: ${sequence} st: ${status} md: ${moduleId} au: ${authorizationId} ap: ${approvalId}`);
    const response = await fetch(url,
        {
            method: "POST",
            headers: {

                "Content-Type": "application/json",
                'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
            },
            body: JSON.stringify({
                "OBJECT_TYPE_CODE": "EMPLOYEE_REQUISITION",
                "REQUSTED_BY_ID": empId,
                "IN_WORDS": inword,
                "APPROVER_ID": approverId,
                "PAYMENY_METHOD": payment,
                "STATUS": "IN PROCESS",
                "PLANT_CODE": plantCode,
                "COMPANY_CODE": companyCode,
                "REQUISITION_TYPE": reqType
            }),
        }
    );
   
    const data = await response.json();
    console.log(`create: ${data}`);
    return data;


}
export default CreateRequisitionService
