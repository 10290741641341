import React from 'react'

// import MyMoveOrderPage from './MyMoveOrderPage';
import { useMoveOrderContext } from '../context/MoveContext';
// import MoveOrderDetailsPage from './MoveOrderDetailsPage';
import CreateMoveOrderPage from './CreateMoveOrderPage';
import MoveOrderPage from './MoveOrderPage';


export default function MoveOrderHomePage() {
    // const {movePageno,setMovePageNo}=useMoveOrderContext();
    const {movePageno, setMovePageNo} =useMoveOrderContext();
  return (
    <div>
     {(() => {
            switch (movePageno) {
              case 1:
                return <MoveOrderPage />
              case 2:
                return <CreateMoveOrderPage />

              default:
                return null
            }
          })()}
    </div>
  )
}
