import { baseUrl } from "../../utils/path";


  const MonthlyAttendanceReportService = async( empId, year, month, compCode, plantCode, pOffset, pLimit ) => {

    const url = `${baseUrl}monthly_report`;
    
    console.log(`montRep. : ${empId} ${month} ${year} ${compCode} ${plantCode} ${pOffset} ${pLimit}`);

    const response = await  fetch( url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
      },
      body: JSON.stringify({
        "MONTH": month,
        "YEAR": year,
        "EMPLOYEE_ID": empId,
        "COMPANY_CODE": compCode,
        "PLANT_CODE": plantCode,
        "P_OFFSET": pOffset,
        "P_LIMIT": pLimit 
      }),
    });
      
    if (!response.ok) {
      throw new Error("fetching is not successful");
    }
        
    const data = await response.json();
         
    return data;
  }
export default MonthlyAttendanceReportService;
