

  


const SyncCompanyPlantService = async (url, companyCode,companyName,plantCode,plantName) => {
  
    // console.log(`em:${empId} se: ${sequence} st: ${status} md: ${moduleId} au: ${authorizationId} ap: ${approvalId}`);
    const response = await fetch(url,
        {
            method: "POST",
            headers: {

                "Content-Type": "application/json",
                'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
            },
            body: JSON.stringify({
                
                "COMPANY_CODE": companyCode,
                "COMPANY_NAME": companyName,
                "PLANT_CODE":plantCode,
                "PLANT_NAME":plantName,
                
            }),
        }
    );
    // if (!response.ok) {
    //     throw new Error("fetching is not successful");
    //   }
    const data = await response.json();
    // console.log(`info: ${data.status}`);
    return data;


}
export default SyncCompanyPlantService
