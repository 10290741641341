import { baseUrl } from "../../utils/path";

const url = `${baseUrl}get_lat_long_by_company_plant`;

const GeoFencingService = async (COMPANY_CODE, PLANT_CODE) => {
    // console.log(`uid: ${userId} lat ${lat}  long ${long} ra${radius} cc${companyCode}  pc${PlantCode}`);
    console.log(`em:${COMPANY_CODE} se: ${PLANT_CODE}`);

    const response = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization:
                "Basic " +
                btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
        },
        body: JSON.stringify({
            COMPANY_CODE: COMPANY_CODE,
            PLANT_CODE: PLANT_CODE,
        }),
    });
    // if (!response.ok) {
    //     throw new Error("fetching is not successful");
    //   }
    const data = await response.json();

    return data;
};
export default GeoFencingService;
