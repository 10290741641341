import { DatePicker } from "rsuite";
import Datepicker from "react-tailwindcss-datepicker";
import React, { useEffect } from "react";
import { useState } from "react";
import Loading from "../../components/loader/Loading";
import moment from "moment";
import { baseUrl } from "../../utils/path";
import getCompanyList from "../../services/CompanyListService";
import getPlantList from "../../services/PlantListService";

import { CSVLink } from "react-csv";
import MonthlyAttendanceReportService from "../service/MonthlyAttendanceReportService";

const companyUrl = `${baseUrl}getCompanyList`;
const moduleUrl = `${baseUrl}approval_module_types`;
const plantUrl = `${baseUrl}get_plant_details`;


export default function MonthlyAttendanceReport() {

  const [uId, setUid] = useState('');
  const [page, setPage] = useState(0);
  let limit = 10;
  let totalLimit = 100000;
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [totalPage, setTotalPage] = useState(null);
  const [pageCount, setPageCount] = useState(1)
  const [empId, setEmpId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSearch, setIsSearch]=useState(false);
  let [pageCountSearch, setPageCountSearch]=useState(1);
  let [pageSearch, setPageSearch]=useState(0);
  const [startEndDate, setStartEndDate] = useState({ startDate: null, endDate: null, });
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [companyCode, setCompanyCode] = useState('');
  const [plantCode, setPlantCode] = useState('');
  const [selectedCompanyname, setSelectedCompanyName] = useState('');
  const [selectedPlantname, setSelectedPlantName] = useState('');
  const [isCompanyLoading, setIsCompanyLoading] = useState(true);
  const [company, setCompany] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [isPlantVisible, setIsPlantVisible] = useState(false);
  const [plant, setPlant] = useState([]);
  const [isPlantLoading, setIsPlantLoading] = useState(true);
  const [searchById, setSearchById]=useState('');
  const [processedData, setProcessedData] = useState([]);
  const [allProcessedData, setAllProcessedData] = useState([]);
  const todayDate = moment(Date()).format('YYYY-MM-DD');
  const [today, setToday]=useState('');
  const [dateValue, setDateValue] = React.useState(null);
  const [startDate, setStartDate]=useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const years = Array.from({ length: 38 }, (_, i) => 2023 + i); // Years from 2023 to 2060
  const months = [
    { value: '01', name: 'January' },
    { value: '02', name: 'February' },
    { value: '03', name: 'March' },
    { value: '04', name: 'April' },
    { value: '05', name: 'May' },
    { value: '06', name: 'June' },
    { value: '07', name: 'July' },
    { value: '08', name: 'August' },
    { value: '09', name: 'September' },
    { value: '10', name: 'October' },
    { value: '11', name: 'November' },
    { value: '12', name: 'December' },
  ];


  useEffect(() => {
    setToday(todayDate);
    setStartDate(today)
    const id = localStorage.getItem('userId');
    const eId = localStorage.getItem('empId');
    console.log(eId)
    setUid(id);
    setEmpId(eId);
    getAllData("", "", "", "", "", page, itemsPerPage);
    allDownloadData("", "", "", "", "");
    // getPlanMaster();
    // stageStatusRef.current.value = "A";
    // userStatusRef.current.value = "A";

  }, [itemsPerPage, data.length, page]);

  useEffect(() => {
    const getCompanyName = async () => {
      const result = await getCompanyList(companyUrl);
      setCompanyList(result.items);
      setIsCompanyLoading(false);
    };
    getCompanyName();
  },[] );

  // useEffect(() => {
  //   getAllData("", "", "", "", "", page, itemsPerPage);
  //   dividePage(data.length); // Recalculate total pages based on current data length
  //   setIsLoading(false);
  // }, [data.length, page]);

  const handleItemsPerPageChange = (value) => {
    setIsLoading(true);
    console.log("perPage: ", value);
    setItemsPerPage(value);
    // setPage(0);
    // getAll(startDate ? startDate : todayDate, startDate ? startDate : todayDate, page, value);
    setPage(0); // Reset to the first page when changing items per page
    setPageCountSearch(1);
    setPageCount(1);
    // dividePage(data.length); // Recalculate total pages based on current data length
    setIsLoading(false);
  };

  // initially get all data
  const getAllData = async (eId, year, month, companyCode, plantCode, offset, itemsPerPage) => {
    setIsLoading(true);
    // setSearchById('');
    // setSelectedYear('');
    // setSelectedMonth('');
    // setCompanyCode('');
    // setSelectedCompanyName('');
    // setSelectedPlantName('');
    // setIsPlantVisible(false);
    

    const result = await MonthlyAttendanceReportService(eId, year, month, companyCode, plantCode, offset, itemsPerPage);
    
    if (result && result.monthly_attendance_details) {
      const modifiedData = result.monthly_attendance_details.map(item => {
        // const moveOrder = item.OFFICIAL_MOVE_ORDER !== undefined ? item.OFFICIAL_MOVE_ORDER : item.PERSONAL_MOVE_ORDER;
        const remainingDays = item.TOTAL_WORKING_DAYS - (item.PRESENT_DAYS + item.LEAVE_DAYS + item.OFFICIAL_MOVE_ORDER);
        return { ...item, remainingDays };
      });
      console.log("modified: ", modifiedData);
      dividePage(modifiedData[0].TOTAL_OUTPUT);
      setData(modifiedData);
      setData2(modifiedData);
      processData(modifiedData);
    }

    // dividePage(result.monthly_attendance_details[0].TOTAL_OUTPUT);
    // console.log("attnReport", result.monthly_attendance_details);
      
    // setData(result.monthly_attendance_details);
    // setData2(result.monthly_attendance_details);
    // processData(result.monthly_attendance_details);
    setIsLoading(false);
  };

  const allDownloadData = async (searchId, selectYear, selectMonth, cCode, pCode) => {
    setIsLoading(true);

    console.log("id: ", searchId);
    console.log("year: ", selectYear);
    console.log("month: ", selectMonth);
    console.log("compCode: ", cCode);
    console.log("plantCode: ", pCode);

    // const result = await MoveOrderReportService(searchId ? searchId : "", compCode ? compCode : "", plantCode ? plantCode : "", orderStatus, stDate ? stDate : todayDate, eDate ? eDate : todayDate, page, totalLimit);
    
    // allProcessData(result.ATTENDANCE_DETAILS);

    const nullPage = 0;

    const result = await MonthlyAttendanceReportService(searchId, selectYear, selectMonth, cCode, pCode, nullPage, totalLimit);

    // allProcessData(result.monthly_attendance_details);

    if (result && result.monthly_attendance_details) {
      const modifiedData = result.monthly_attendance_details.map(item => {
        // const moveOrder = item.OFFICIAL_MOVE_ORDER !== undefined ? item.OFFICIAL_MOVE_ORDER : item.PERSONAL_MOVE_ORDER;
        const remainingDays = item.TOTAL_WORKING_DAYS - (item.PRESENT_DAYS + item.LEAVE_DAYS + item.OFFICIAL_MOVE_ORDER);
        return { ...item, remainingDays };
      });

      allProcessData(modifiedData);

      // console.log("modified: ", modifiedData);
      // dividePage(modifiedData[0].TOTAL_OUTPUT);
      // setData(modifiedData);
      // setData2(modifiedData);
      // processData(modifiedData);
    }

    setIsLoading(false);
  };

  // divided page number to allPage (for pagination)
  const dividePage = (number) => {
    console.log("dividePage: ", number);
    if (typeof number !== 'number') {
      throw new Error('Input must be a number');
    }
      
    const re= Math.ceil(number / itemsPerPage);
    setTotalPage(re);
  };

  // // go to next page
  const next = async () => {
    // setPage((page)=>page+10);
    const newPage = page + itemsPerPage;
    setPage(newPage);
    console.log(`page here ${newPage}`);
    setPageCount((pre)=>pre+1);
  
    setIsLoading(true);
    setData([]);
    setData2([])
  
    const result = await MonthlyAttendanceReportService(searchById, selectedYear, selectedMonth, companyCode, plantCode, newPage, itemsPerPage);
    
    if (result && result.monthly_attendance_details) {
      const modifiedData = result.monthly_attendance_details.map(item => {
        // const moveOrder = item.OFFICIAL_MOVE_ORDER !== undefined ? item.OFFICIAL_MOVE_ORDER : item.PERSONAL_MOVE_ORDER;
        const remainingDays = item.TOTAL_WORKING_DAYS - (item.PRESENT_DAYS + item.LEAVE_DAYS + item.OFFICIAL_MOVE_ORDER);
        return { ...item, remainingDays };
      });
      console.log("modified: ", modifiedData);
      dividePage(modifiedData[0].TOTAL_OUTPUT);
      setData(modifiedData);
      setData2(modifiedData);
      processData(modifiedData);
    }

    // dividePage(result.monthly_attendance_details[0].TOTAL_OUTPUT);
      
    // setData(result.monthly_attendance_details);
    // setData2(result.monthly_attendance_details);
    // processData(result.monthly_attendance_details);
    setIsLoading(false);
    
    console.log('clicked');
  }

  // // after Searching next page
  const searchNext = async() => {
    // setPage((page)=>page+10);
    const newPage = pageSearch + itemsPerPage;
    setPageSearch(newPage);
    console.log(`page here ${newPage}`);
    setPageCountSearch((pre)=>pre+1);

    setIsLoading(true);
    setData([]);
    setData2([]);

    const result = await MonthlyAttendanceReportService(searchById, selectedYear, selectedMonth, companyCode, plantCode, newPage, itemsPerPage);

    if (result && result.monthly_attendance_details) {
      const modifiedData = result.monthly_attendance_details.map(item => {
        // const moveOrder = item.OFFICIAL_MOVE_ORDER !== undefined ? item.OFFICIAL_MOVE_ORDER : item.PERSONAL_MOVE_ORDER;
        const remainingDays = item.TOTAL_WORKING_DAYS - (item.PRESENT_DAYS + item.LEAVE_DAYS + item.OFFICIAL_MOVE_ORDER);
        return { ...item, remainingDays };
      });
      console.log("modified: ", modifiedData);
      dividePage(modifiedData[0].TOTAL_OUTPUT);
      setData(modifiedData);
      setData2(modifiedData);
      processData(modifiedData);
    }
      
    // dividePage(result.monthly_attendance_details[0].TOTAL_OUTPUT);
      
    // setData(result.monthly_attendance_details);
    // setData2(result.monthly_attendance_details);
    // processData(result.monthly_attendance_details);
    setIsLoading(false);
  }

  // // back to previous page
  const previous = async () => {
      
    // setPage((pre)=>pre-10);
    const newPage = page - itemsPerPage;
    setPage(newPage);
    console.log(`page here ${newPage}`);
    setPageCount((pre)=>pre-1);
  
    console.log('previous button clicked');
    setIsLoading(true);
    setData([]);
    setData2([]);
    
    const result = await MonthlyAttendanceReportService(searchById, selectedYear, selectedMonth, companyCode, plantCode, newPage, itemsPerPage);

    if (result && result.monthly_attendance_details) {
      const modifiedData = result.monthly_attendance_details.map(item => {
        // const moveOrder = item.OFFICIAL_MOVE_ORDER !== undefined ? item.OFFICIAL_MOVE_ORDER : item.PERSONAL_MOVE_ORDER;
        const remainingDays = item.TOTAL_WORKING_DAYS - (item.PRESENT_DAYS + item.LEAVE_DAYS + item.OFFICIAL_MOVE_ORDER);
        return { ...item, remainingDays };
      });
      console.log("modified: ", modifiedData);
      dividePage(modifiedData[0].TOTAL_OUTPUT);
      setData(modifiedData);
      setData2(modifiedData);
      processData(modifiedData);
    }
      
    // dividePage(result.monthly_attendance_details[0].TOTAL_OUTPUT);
      
    // setData(result.monthly_attendance_details);
    // setData2(result.monthly_attendance_details);
    // processData(result.monthly_attendance_details);
    setIsLoading(false);
  }

  // // after Searching back previous
  const searchPrevious = async () => {
    const newPage = pageSearch - itemsPerPage;
    setPageSearch(newPage);
    console.log(`page here ${newPage}`);
    setPageCountSearch((pre)=>pre-1);
  
    console.log('clicked');
    setIsLoading(true);
    setData([]);
    setData2([]);
    
    const result = await MonthlyAttendanceReportService(searchById, selectedYear, selectedMonth, companyCode, plantCode, newPage, itemsPerPage);

    if (result && result.monthly_attendance_details) {
      const modifiedData = result.monthly_attendance_details.map(item => {
        // const moveOrder = item.OFFICIAL_MOVE_ORDER !== undefined ? item.OFFICIAL_MOVE_ORDER : item.PERSONAL_MOVE_ORDER;
        const remainingDays = item.TOTAL_WORKING_DAYS - (item.PRESENT_DAYS + item.LEAVE_DAYS + item.OFFICIAL_MOVE_ORDER);
        return { ...item, remainingDays };
      });
      console.log("modified: ", modifiedData);
      dividePage(modifiedData[0].TOTAL_OUTPUT);
      setData(modifiedData);
      setData2(modifiedData);
      processData(modifiedData);
    }
      
    // dividePage(result.monthly_attendance_details[0].TOTAL_OUTPUT);
      
    // setData(result.monthly_attendance_details);
    // setData2(result.monthly_attendance_details);
    // processData(result.monthly_attendance_details);
    setIsLoading(false);
  }

  // convert date by day/month/year
  const convertDate = (dateString) => {
    const dateObject = new Date(dateString);
    const day = String(dateObject.getUTCDate()).padStart(2, '0');
    const month = String(dateObject.getUTCMonth() + 1).padStart(2, '0');
    const year = dateObject.getUTCFullYear();

    return `${day}/${month}/${year}`;
  };

  // using for see more and see less for description
  const [seeMore, setSeeMore] = useState(-1);

  const handleSeeMoreDetails = (index) => {
    setSeeMore(index === seeMore ? -1 : index);
  };

  // company data
  const handleCompany = async (e) => {
    const selectedCode = e.target.value;
    const selectedName = companyList.find(c => c.company_code === selectedCode)?.company_name || '';
    
    console.log("Selected company code: ", selectedCode)

    setCompanyCode(selectedCode);
    setSelectedCompanyName(selectedName);
    getPlant(selectedCode);

    setIsLoading(true);
    setData([]);
    setData2([]);
    
    // const result = await MoveOrderReportService("", selectedCode, "", moveOrderStatus, fromDate ? fromDate : todayDate, toDate ? toDate : todayDate, page, limit);

    const result = await MonthlyAttendanceReportService(searchById ? searchById : "", selectedYear ? selectedYear : "", selectedMonth ? selectedMonth : "", selectedCode, "", page, itemsPerPage)

    if (result && result.monthly_attendance_details) {
      const modifiedData = result.monthly_attendance_details.map(item => {
        // const moveOrder = item.OFFICIAL_MOVE_ORDER !== undefined ? item.OFFICIAL_MOVE_ORDER : item.PERSONAL_MOVE_ORDER;
        const remainingDays = item.TOTAL_WORKING_DAYS - (item.PRESENT_DAYS + item.LEAVE_DAYS + item.OFFICIAL_MOVE_ORDER);
        return { ...item, remainingDays };
      });
      console.log("modified: ", modifiedData);
      dividePage(modifiedData[0].TOTAL_OUTPUT);
      setData(modifiedData);
      setData2(modifiedData);
      processData(modifiedData);
      allDownloadData(searchById ? searchById : "", selectedYear ? selectedYear : "", selectedMonth ? selectedMonth : "", selectedCode, "");
    }

    // dividePage(result.monthly_attendance_details[0].TOTAL_OUTPUT);
    // setData(result.monthly_attendance_details);
    // setData2(result.monthly_attendance_details);
    // processData(result.ATTENDANCE_DETAILS);
    setIsPlantVisible(true);
    setIsLoading(false);
  };

  // plant data 
  const handlePlant = async (e) => {
    // handleDropDown(e);
    // setPlantName(e.PLANT_NAME);

    const selectedPlantCode = e.target.value;
    setPlantCode(selectedPlantCode);

    const selectedName = plant.find(c => c.PLANT_CODE === selectedPlantCode)?.PLANT_NAME || '';
    setSelectedPlantName(selectedName);

    setIsLoading(true);
    setData([]);
    setData2([]);

    // const result = await MoveOrderReportService("", companyCode, selectedPlantCode, moveOrderStatus, fromDate ? fromDate : todayDate, toDate ? toDate : todayDate, page, limit);

    const result = await MonthlyAttendanceReportService(searchById ? searchById : "", selectedYear ? selectedYear : "", selectedMonth ? selectedMonth : "", companyCode, selectedPlantCode, page, itemsPerPage);

    if (result && result.monthly_attendance_details) {
      const modifiedData = result.monthly_attendance_details.map(item => {
        // const moveOrder = item.OFFICIAL_MOVE_ORDER !== undefined ? item.OFFICIAL_MOVE_ORDER : item.PERSONAL_MOVE_ORDER;
        const remainingDays = item.TOTAL_WORKING_DAYS - (item.PRESENT_DAYS + item.LEAVE_DAYS + item.OFFICIAL_MOVE_ORDER);
        return { ...item, remainingDays };
      });
      console.log("modified: ", modifiedData);
      dividePage(modifiedData[0].TOTAL_OUTPUT);
      setData(modifiedData);
      setData2(modifiedData);
      processData(modifiedData);
      allDownloadData(searchById ? searchById : "", selectedYear ? selectedYear : "", selectedMonth ? selectedMonth : "", companyCode ? companyCode : "", selectedPlantCode);
    }

    // dividePage(result.monthly_attendance_details[0].TOTAL_OUTPUT);
    // setData(result.monthly_attendance_details);
    // setData2(result.monthly_attendance_details);
    // processData(result.monthly_attendance_details);
    setIsLoading(false);
  };

  // plant list here
  const getPlant = async (code) => {
    const plant = await getPlantList(plantUrl, code);
    console.log("plant:=", plant);
    setPlant(plant.PLANT_DETAILS);

    setIsPlantLoading(false);
  }

  const processData = (data) => {
    if (data && data.length > 0) {
      const newData = data.map((obj) => {
        const newDate = new Date(obj.REQUISITION_LOADING_DATE);
        const formattedDate = moment(newDate).format("DD/MM/YYYY");
        const lateTime = convertTime(obj.LATE_TIME_IN_MINUTES);
        const overTime = convertTime(obj.OVER_TIME_IN_MINUTES);
        const absentDays = obj.TOTAL_WORKING_DAYS - (obj.PRESENT_DAYS + obj.LEAVE_DAYS + obj.OFFICIAL_MOVE_ORDER);

        return { ...obj, REQUISITION_LOADING_DATE: formattedDate, LATE_TIME_IN_MINUTES: lateTime, OVER_TIME_IN_MINUTES: overTime, ABSENT_DAYS: absentDays };
      });
      setProcessedData(newData);
      console.log("processed Data: ", processedData)
    } 
    
    else {
      console.log("Data is empty or undefined");
    }
  };

  const allProcessData = (data) => {
    if (data && data.length > 0) {
      const newData = data.map((obj) => {
        const newDate = new Date(obj.REQUISITION_LOADING_DATE);
        const formattedDate = moment(newDate).format("DD/MM/YYYY");
        const lateTime = convertTime(obj.LATE_TIME_IN_MINUTES);
        const overTime = convertTime(obj.OVER_TIME_IN_MINUTES);
        const absentDays = obj.TOTAL_WORKING_DAYS - (obj.PRESENT_DAYS + obj.LEAVE_DAYS + obj.OFFICIAL_MOVE_ORDER);

        return { ...obj, REQUISITION_LOADING_DATE: formattedDate, LATE_TIME_IN_MINUTES: lateTime, OVER_TIME_IN_MINUTES: overTime, ABSENT_DAYS: absentDays };
      });
      setAllProcessedData(newData);
    } 
    
    else {
      console.log("Data is empty or undefined");
    }
  };

  const headers = [
    { label: "COMPANY CODE", key: "COMPANY_CODE" },
    { label: "EMPLOYEE ID", key: "EMPLOYEE_ID" },
    { label: "EMPLOYEE NAME", key: "EMPLOYEE_NAME" },
    { label: "POSITION", key: "POSITION" },
    // { label: "SUBAREA", key: "PLANT_NAME" },
    { label: "DEPARTMENT", key: "DEPARTMENT" },
    { label: "TOTAL LATE TIME", key: "LATE_TIME_IN_MINUTES" },
    { label: "TOTAL OVER TIME", key: "OVER_TIME_IN_MINUTES" },
    { label: "SICK LEAVE TAKEN", key: "SICK_LEAVE" },
    { label: "EARN LEAVE TAKEN", key: "EARN_LEAVE" },
    { label: "CASUAL LEAVE TAKEN", key: "CASUAL_LEAVE" },
    { label: "TOTAL OFFICE MOVE ORDER", key: "OFFICIAL_MOVE_ORDER" },
    { label: "TOTAL PERSONAL MOVE ORDER TAKEN", key: "PERSONAL_MOVE_ORDER" },
    { label: "TOTAL PERSONAL MOVE ORDER TIME", key: "PERSONAL_MOVE_ORDER_TIME" },
    { label: "PRESENT DAYS", key: "PRESENT_DAYS" },
    { label: "ABSENT DAYS", key: "ABSENT_DAYS" },
    { label: "LEAVE DAYS", key: "LEAVE_DAYS" },
  ];

  // let fileName = moment(Date()).format("DD/MM/YYYY");

  const [fileName, setFileName] = useState(moment(Date()).format("DD/MM/YYYY"));
  

  // format time
   const format12HourTime = (hour, minute) => {
    if (!hour || !minute) return "N/A";

    const formattedHour = (parseInt(hour, 10) % 12 || 12).toString().padStart(2, '0');
    const formattedMinute = parseInt(minute, 10).toString().padStart(2, '0');

    const period = parseInt(hour, 10) < 12 ? 'AM' : 'PM';
    return `${formattedHour}:${formattedMinute} ${period}`;
  };

  const formatDate = (dateTimeString) => {
    if (!dateTimeString) return "N/A";

    const formattedDate = moment.utc(dateTimeString).format("YYYY-MM-DD hh:mm A");
    return formattedDate;
  };

  // const handleMoveOrderStatus = async (status) => {
  //   setIsLoading(true);
  //   console.log(status);

  //   const result = await MoveOrderReportService(searchById ? searchById : "", companyCode ? companyCode : "", plantCode ? plantCode : "", status, fromDate ? fromDate : todayDate, toDate ? toDate : todayDate, page, limit);

  //   // const result = await MoveOrderReportService(searchById ? searchById : "", companyCode ? companyCode : "", plantCode ? plantCode : "", status , startDate ? startDate : todayDate, startDate ? startDate : todayDate, page, limit )

  //   dividePage(result.Total_Count);
  //   setData(result.ATTENDANCE_DETAILS);
  //   // setData2(result.ATTENDANCE_DETAILS);
  //   processData(result.ATTENDANCE_DETAILS);
  //   allDownloadData(fromDate ? fromDate : todayDate, toDate ? toDate : todayDate, companyCode, plantCode, searchById, status);

  //   // const re = await MyIouService(employeeId, status);
  //   // console.log("Details:", re.iou_details)
  //   setIsLoading(false);
  // }

  const handleYearChange = async (e) => {
    setIsLoading(true);
    console.log("year: ", e.target.value)
    const year = e.target.value;
    setSelectedYear(year);
    setSelectedMonth('');
    setCompanyCode('');
    setPlantCode('');
    setSearchById('');

    const res = await MonthlyAttendanceReportService(searchById ? searchById : "", year, selectedMonth ? selectedMonth : "", companyCode ? companyCode : "", plantCode ? plantCode : "", page, itemsPerPage);
    // console.log("selYear: ", res);
    // setData(res.monthly_attendance_details);
    // setData2(res.monthly_attendance_details);

    if (res && res.monthly_attendance_details) {
      const modifiedData = res.monthly_attendance_details.map(item => {
        // const moveOrder = item.OFFICIAL_MOVE_ORDER !== undefined ? item.OFFICIAL_MOVE_ORDER : item.PERSONAL_MOVE_ORDER;
        const remainingDays = item.TOTAL_WORKING_DAYS - (item.PRESENT_DAYS + item.LEAVE_DAYS + item.OFFICIAL_MOVE_ORDER);
        return { ...item, remainingDays };
      });
      console.log("modified: ", modifiedData);
      dividePage(modifiedData[0].TOTAL_OUTPUT);
      setData(modifiedData);
      setData2(modifiedData);
      processData(modifiedData);
      allDownloadData(searchById ? searchById : "", year, selectedMonth ? selectedMonth : "", companyCode ? companyCode : "", plantCode ? plantCode : "");
    }

    setIsLoading(false);
  };

  const handleMonthChange = async (e) => {
    setIsLoading(true);
    console.log("month: ", e.target.value)
    const month = e.target.value;
    setSelectedMonth(month);

    const res = await MonthlyAttendanceReportService(searchById ? searchById : "", selectedYear ? selectedYear : "", month, companyCode ? companyCode : "", plantCode ? plantCode : "", page, itemsPerPage);
    // console.log("selMonth", res);
    // setData(res.monthly_attendance_details);
    // setData2(res.monthly_attendance_details);

    if (res && res.monthly_attendance_details) {
      const modifiedData = res.monthly_attendance_details.map(item => {
        // const moveOrder = item.OFFICIAL_MOVE_ORDER !== undefined ? item.OFFICIAL_MOVE_ORDER : item.PERSONAL_MOVE_ORDER;
        const remainingDays = item.TOTAL_WORKING_DAYS - (item.PRESENT_DAYS + item.LEAVE_DAYS + item.OFFICIAL_MOVE_ORDER);
        return { ...item, remainingDays };
      });
      console.log("modified: ", modifiedData);
      dividePage(modifiedData[0].TOTAL_OUTPUT);
      setData(modifiedData);
      setData2(modifiedData);
      processData(modifiedData);
      allDownloadData(searchById ? searchById : "", selectedYear ? selectedYear : "", month, companyCode ? companyCode : "", plantCode ? plantCode : "");
    }

    setIsLoading(false);
  };

  const searchEmpId = async () => {
    setIsLoading(true);
    // setData([]);
    // setData2([]);

    const res = await MonthlyAttendanceReportService(searchById ? searchById : "", selectedYear ? selectedYear : "", selectedMonth ? selectedMonth : "", companyCode ? companyCode : "", plantCode ? plantCode : "", page, itemsPerPage);

    if (res && res.monthly_attendance_details) {
      const modifiedData = res.monthly_attendance_details.map(item => {
        // const moveOrder = item.OFFICIAL_MOVE_ORDER !== undefined ? item.OFFICIAL_MOVE_ORDER : item.PERSONAL_MOVE_ORDER;
        const remainingDays = item.TOTAL_WORKING_DAYS - (item.PRESENT_DAYS + item.LEAVE_DAYS + item.OFFICIAL_MOVE_ORDER);
        return { ...item, remainingDays };
      });
      console.log("modified: ", modifiedData);
      dividePage(modifiedData[0].TOTAL_OUTPUT);
      setData(modifiedData);
      setData2(modifiedData);
      processData(modifiedData);
      allDownloadData(searchById ? searchById : "", selectedYear ? selectedYear : "", selectedMonth ? selectedMonth : "", companyCode ? companyCode : "", plantCode ? plantCode : "");
    }
    setIsLoading(false);

    // setSearchById('');
  }

  const convertTime = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours.toString().padStart(2, '0')}h : ${minutes.toString().padStart(2, '0')}m`;
  };



  return (
    <div className="mx-8 my-20 bg-white">
      <div className='w-full bg-white'>
        <h4 className=' text-black font-semibold lg:text-lg md:text-sm text-xs'>Monthly Attendance Report</h4>

        <div className="h-5"></div>

        {
          isLoading ? (
            <Loading />
          ) : (
            <>
              <div className="flex items-center space-x-3">
                <div className="w-1/5">
                  <select
                    className="select select-bordered bg-white w-full focus:outline-none h-[48px]"
                    value={selectedYear}
                    onChange={handleYearChange}
                  >
                    <option value="" disabled selected>Select Year</option>
                    {years.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="w-1/5">
                <select
                  className="select select-bordered bg-white w-full focus:outline-none h-[48px]"
                  value={selectedMonth}
                  onChange={handleMonthChange}
                >
                  <option value="" disabled selected>Select Month</option>
                  {months.map((month) => (
                    <option key={month.value} value={month.value}>
                      {month.name}
                    </option>
                  ))}
                </select>

                </div>

                <div className="w-1/5">
                  {
                    <select
                      placeholder='Select Company'
                      value={companyCode}
                      onChange={handleCompany}
                      className="select select-bordered bg-white w-full focus:outline-none h-[48px]"
                    >
                      <option value='' disabled selected>Select Company</option>
                      {
                        companyList.map((e, index) =>
                          <option key={index} value={e.company_code}>{e.company_name}({e.company_code})</option>
                        )
                      }
                    </select>
                  }
                </div>

                <div className="w-1/5">
                  {  isPlantVisible && (
                    <select
                      value={plantCode}
                      onChange={handlePlant}
                      className="w-full border bg-white h-[48px] rounded-lg px-2 focus:outline-none"
                    >
                      <option value="" disabled selected>Select Plant</option>
                      {
                        isPlantLoading ?
                          <p>loading.....</p>
                          : plant.map((e, index) =>
                            <option value={e.PLANT_CODE} key={index}>{e.PLANT_NAME}({e.PLANT_CODE})</option>
                          )
                      }
                    </select>
                  )}
                </div>

                <div className="flex flex-row space-x-2 items-center w-1/4">
                  <input onChange={(e)=>{setSearchById(e.target.value)}} onKeyPress={(e) => { if (e.key === 'Enter') searchEmpId(); }} value={searchById} type="text" className="w-full px-3 h-[48px] rounded-lg border-[0.2px] border-gray-300 placeholder:text-sm placeholder:text-gray-400 focus:outline-none bg-white" placeholder='Search by Employee Id' />
                  
                  <button onClick={() => { searchEmpId() }} className="bg-[#013E0E] h-[48px] w-[87px] px-3 flex justify-center items-center text-white rounded-lg">

                    Search
                  </button>
                </div>
              </div>

              <div className="h-7"></div>

              {/* <button onClick={downloadExcel} className="bg-[#013E0E] h-[48px] w-[87px] px-3 flex justify-center items-center text-white rounded-lg">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" dataSlot="icon" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
                </svg>

                <p>Download</p>
              </button> */}

              <div className="flex items-center justify-end space-x-2">


                { data.length === 0 ? null : (
                  <CSVLink data={processedData} headers={headers} filename={`MONTHLY_ATTENDANCE_REPORT_${fileName}.csv`}>
                    <button className={` w-[180px] h-[48px] flex justify-center items-center bg-[#E6E1DD] px-2 rounded-lg shadow-sm text-[#013E0E] space-x-2 no-underline`}>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" dataSlot="icon" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
                      </svg>

                      <h2 className=" lg:text-sm md:text-xs text-xs">
                        Download
                      </h2>
                    </button>
                  </CSVLink>
                )}

                { data.length === 0 ? null : (
                  <CSVLink data={allProcessedData} headers={headers} filename={`ALL_MONTHLY_ATTENDANCE_REPORT_${fileName}.csv`}>
                    <button className={` w-[180px] h-[48px] flex justify-center items-center bg-[#E6E1DD] px-2 rounded-lg shadow-sm text-[#013E0E] space-x-2`}>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" dataSlot="icon" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
                      </svg>

                      <h2 className=" lg:text-sm md:text-xs text-xs">
                        Download All
                      </h2>
                    </button>
                  </CSVLink>
                )}
              </div>

              <div className="h-7"></div>

              <div className="w-full">
              {
                  data2.length === 0 ? (
                    <div className='flex flex-row justify-center items-center text-center w-full h-screen'>
                      <h1 className={`text-red-500 text-center w-full font-bold text-2xl`}>No Data Found</h1>
                    </div>
                  ) : (
                    <>
                
                      <div className="overflow-x-auto">
                        <table className="w-full border-[0.5px] border-gray-200 rounded-lg">
                          <thead className="bg-bgTableHeader text-tableHeaderText shadow-sm h-14 rounded-lg">
                            <tr>
                              <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                SL
                              </th>

                              <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                COMPANY CODE
                              </th>

                              <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                EMPLOYEE ID
                              </th>
                                  
                              <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                EMPLOYEE NAME
                              </th>
                                  
                              <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                POSITION
                              </th>
                                  
                              {/* <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                SUBAREA
                              </th> */}

                              <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                DEPARTMENT
                              </th>

                              <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                TOTAL LATE TIME
                              </th>
                                  
                              <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                TOTAL OVER TIME
                              </th>
                                  
                              <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                SICK LEAVE TAKEN
                              </th>
                                  
                              <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                EARN LEAVE TAKEN
                              </th>
                                
                              <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                CASUAL LEAVE TAKEN
                              </th>

                              <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                TOTAL OFFICE MOVE ORDER
                              </th>

                              <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                TOTAL PERSONAL MOVE ORDER TAKEN
                              </th>
                                  
                              <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                TOTAL PERSONAL MOVE ORDER TIME
                              </th>
                                  
                              <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                PRESENT DAYS
                              </th>

                              <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                ABSENT DAYS
                              </th>

                              <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                                LEAVE DAYS
                              </th>
                            </tr>
                          </thead>
                              
                          <tbody className="w-full divide-y divide-borderColor">
                            {data.map((e, index) => (
                              <tr key={index}  className="bg-white">
                                <td className="px-6 py-4 whitespace-nowrap">
                                  {page + index + 1}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  {e.COMPANY_CODE}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  {e.EMPLOYEE_ID}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  {e.EMPLOYEE_NAME}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  {e.POSITION}
                                </td>
                                {/* <td className="px-6 py-4 whitespace-nowrap">
                                  {e.PLAN_NAME ? e.PLANT_NAME : "N/A"}
                                </td> */}
                                <td className="px-6 py-4 whitespace-nowrap">
                                  {e.DEPARTMENT}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  {convertTime(e.LATE_TIME_IN_MINUTES)}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  {convertTime(e.OVER_TIME_IN_MINUTES)}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  {e.SICK_LEAVE}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  {e.EARN_LEAVE}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  {e.CASUAL_LEAVE}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  {e.OFFICIAL_MOVE_ORDER}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  {e.PERSONAL_MOVE_ORDER_TAKEN ? e.PERSONAL_MOVE_ORDER_TAKEN : "N/A"}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  {e.PERSONAL_MOVE_ORDER_TIME ? e.PERSONAL_MOVE_ORDER_TIME : "N/A"}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  {e.PRESENT_DAYS}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  {e.remainingDays}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  {e.LEAVE_DAYS}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )
                }
              </div>
            </>
          )
        }

        {/* pagination start here */}

        <div className="h-5"></div>

        <div className="flex justify-start items-center space-x-2 mb-4">
            <p>Rows Per Page: </p>
            <select
              value={itemsPerPage}
              onChange={(e) => handleItemsPerPageChange(Number(e.target.value))}
              className="border rounded-md bg-white w-[68px] focus:outline-none h-[36px]"
            >
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>

        {data.length > 0 && (
          <div className='flex flex-row space-x-4 justify-center items-end mt-4 mb-3 lg:w-full md:w-3/4 sm:w-96 pr-6'>
            <button disabled={page === 0 && pageSearch === 0 ? true : false} onClick={() => { isSearch ? searchPrevious() : previous() }}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-6 h-6 font-bold ${page === 0 && pageSearch === 0 ? 'text-white' : `text-gray-600`} font-bold`}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
              </svg>
            </button>

            <p className={`text- text-[18px] font-bold`}>
              Page {isSearch ? pageCountSearch : pageCount} Of {totalPage}
            </p>

            <button disabled={(isSearch ? pageCountSearch : pageCount) === totalPage || data.length === 0} onClick={() => { isSearch ? searchNext() : next() }}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-6 h-6 font-bold ${((isSearch ? pageCountSearch : pageCount) === totalPage || data.length === 0) ? 'text-white' : 'text-gray-500'} font-bold`}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
              </svg>
            </button>
          </div>
        )}
      </div>
    </div>
  )
}