import { baseUrl } from "../../utils/path";

const LeaveHistoryService = async(id, status)=>{

  const url = `${baseUrl}emp_leave_history`;

  console.log("id", id);
  console.log("status: ", status);
    
  const response = await fetch(url,
      {
          method: "POST",
          headers: {
            
            "Content-Type": "application/json",
            'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
          },
          body: JSON.stringify({
              "EMPLOYEE_ID": id,
              "STATUS": status ? status : "IN PROCESS"
          }),
        }
      );
      // if (!response.ok) {
      //     throw new Error("fetching is not successful");
      //   }
        const data = await response.json();
        console.log(`users: ${data}`);
        return data;

   
}
export default LeaveHistoryService;
