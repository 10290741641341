import React, { useState, useRef, useEffect } from 'react'
import moment from 'moment/moment';
import "rsuite/dist/rsuite.min.css";
import { DatePicker } from "rsuite";
import { gray500, gray100, red500, gray300, gray400, green100, green600, gray700, gray600 } from '../../utils/colors';
import getUserList from '../../services/UsersService';
import getCompanyList from '../../services/CompanyListService';
import getPlantList from '../../services/PlantListService';
import getRoleList from '../../services/UserRoleService';
import getPositionList from '../../services/PositionTypeService';
import getJobList from '../../services/JobTypeService';
import addEmpService from '../../services/AddEmpService';
import addEmpRoleService from '../../services/AddEmpRoleService';
import SuccessAlert from '../alert_component/SuccessAlert';
import ErrorAlert from '../alert_component/ErrorAlert';
import { baseUrl } from '../../utils/path';



const userUrl = `${baseUrl}employee_search_by_name_id`;
const companyUrl = `${baseUrl}getCompanyList`;
const plantUrl = `${baseUrl}get_plant_details`;
const roleUrl=`${baseUrl}role_types`;
const positionUrl=`${baseUrl}emp_position_types`;
const jobUrl=`${baseUrl}emp_job_types`;
const addEmpUrl=`${baseUrl}user_creation_web`;
const addRoleUrl=`${baseUrl}inert_role_web`;

const psgList=[{"name":"Head Office","id":95},{"name":"Outside Head Office","id":90}];

export default function AddEmployeePage() {
  const usersRef = useRef(null);
  const empIdRef = useRef(null);
  const empNameRef = useRef(null);
  const empEmailRef = useRef(null);
  const empPhoneRef = useRef(null);
  const empNidRef=useRef(null);


  let selectedCompanyCode,positionId,jobId, selectedPalntCode, selectedModuleCode, selectedUserId, selectedAuthorizerId, selectPsgid, selectDocumentIdGolbal;
  const [selectedUser, setSelectedUser] = useState({});
  const [userNameCnt, setuserNameCnt] = useState({});
  const [users, setUsers] = useState([]);
  const [isUsersLoading, setIsUsersLoading] = useState(true);
  const [userId, setUserId] = useState('');
  const [selectedUserName, setSelectedUserName] = useState('');
  const [joiningDate, setJoiningDate] = useState(null);
  const [birthDate, setBirthDate] = useState(null);
  const [company, setCompany] = useState([]);
  const [plant, setPlant] = useState([]);
  const [isPlantLoading, setIsPlantLoading] = useState(true);
  const [companyCode, setCompanyCode] = useState('');
  const [plantCode, setPlantCode] = useState('');
  const [selectedCompanyname, setSelectedCompanyName] = useState('');
  const [selectedPlantname, setSelectedPlantName] = useState('');
  const [empId, setEmpId] = useState('');
  const [empName, setEmpName] = useState('');
  const [empEmail, setEmpEmail] = useState('');
  const [empPhone, setEmpPhone] = useState('');
  const [selectedMaritialStatus,setSelectedMaritialStatus]=useState('');
  const [selectedBloodGroup,setSelectedBloodGroup]=useState('');
  const [selectedEmpStatus,setSelectedEmpStatus]=useState('');
  const [selectedEmpGender,setSelectedEmpGender]=useState('');
  const [addEmpError,setAddEmpError]=useState({});
  const [roles,setRoles]=useState([]);
  const [isRolesLoading,setIsRoleLoading]=useState(true);
  const [uId,setUId]=useState('');
  const [roleIdList,setRoleIdList]=useState([]);
  const [selectedPositionName,setSelectedPositionName]=useState('');
  const [selectedPositionId,setSelectedPositionId]=useState('');
  const [positions,setPositions]=useState([]);
  const [isPositionsLoading,setIspositionLoading]=useState(true);
  const [jobs,setJobs]=useState([]);
  const [selectedJobName,setSelectedJobName]=useState('');
  const [selectedJobId,setSelectedJobId]=useState('');
  const [isJobLoading,setIsJobLoading]=useState(true);
  const [psgId,setPsgId]=useState('');
    const [psgName,setPsgName]=useState('');
    const [nid,setNid]=useState('');

    const [finalRoleList,setFinalRoleList]=useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [value, setValue] = React.useState(null);
    const [value1, setValue1] = React.useState(null);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
 

  useEffect(() => {
    const id=localStorage.getItem('userId');
    setUId(id);
    getCompany();
    getRole(id);
    getPosition(id);
    getJob(id);
     
  }, [])

  const getJob=async(id)=>{
    const job= await getJobList(jobUrl,id);
    setJobs(job.job_type);
    setIsJobLoading(false);
  }

  const getPosition=async(id)=>{
    const po=await getPositionList(positionUrl,id);
    setPositions(po.position_type);
    setIspositionLoading(false);
  }

  const handleClick = (e) => {
    const roleId = e.ROLE_ID;
    setRoleIdList((prevList) => [...prevList, roleId]);
    console.log(roleIdList); // Log the updated roleIdList
  };
  const handleRemoveClick = (roleIdToRemove) => {
    const updatedList = roleIdList.filter((roleId) => roleId !== roleIdToRemove);
    setRoleIdList(updatedList);
    console.log(roleIdList);
  };

  const getRole=async(id)=>{
    const role=await getRoleList(roleUrl,id);
    setRoles(role.roles_type);
    setIsRoleLoading(false);

  }

 const onSuccess=()=>{

  empIdRef.current.value='';
  setEmpId('');
  empNameRef.current.value='';
  setEmpName('');
  setSelectedCompanyName('');
  setCompanyCode('');
  setSelectedPlantName('');
  setPlantCode('');
  setSelectedUser({});
  setSelectedUserName('');
  setUserId('');
  setSelectedJobName('');
  setSelectedJobId('');
  empEmailRef.current.value='';
  setEmpEmail('');
  empPhoneRef.current.value='';
  setEmpPhone('');
  setSelectedPositionName('');
  setSelectedPositionId('');
  setSelectedEmpGender('');
  setSelectedMaritialStatus('');
  setSelectedBloodGroup('');
  setJoiningDate('');
  setBirthDate('');
  setPsgName('');
  setPsgId('');
  setSelectedEmpStatus('');
  empNidRef.current.value='';
  setNid('');
  setRoleIdList([]);
  // setValue(null);
  // setValue1(null);
  handleCleanBirthDate();
  handleCleanJoiningdate();
 

  }

  const handleCleanBirthDate=()=>{
    setValue(null);
    console.log(`birth: ${value}`);
  }
  const handleCleanJoiningdate=()=>{
    setValue1(null);
  }

  const adduser=async()=>{
    if(validateAddEmp()){
      const newArray = roleIdList.map(roleId => ({
        "employee_id": parseInt(empId),
        "role_id": roleId
      }));
      // console.log(newArray);
      setFinalRoleList(newArray);
     const re=await addEmpService(addEmpUrl,uId,empId,empName,companyCode,plantCode,empEmail,empPhone,userId,selectedEmpStatus,selectedPositionId,selectedJobId,selectedEmpGender,joiningDate,birthDate,selectedMaritialStatus,selectedBloodGroup,nid,psgId);
      console.log(re);
      if(re.message==="User created successfully"){
          const ro=await addEmpRoleService(addRoleUrl,newArray);
          if(ro.status==="success"){
            setShowAlert(true);
            onSuccess();
          }
          else{
            setShowErrorAlert(true);
        }
          
      console.log(ro);
      }
      else{
          setShowErrorAlert(true);
      }
   
    }
    else{
      console.log('validation false');
    }
  }

  const validateAddEmp=()=>{
    const addEmpError={};
    if(!empId.trim()){
      addEmpError.empId="Enter Id";
    }
    if(!empName.trim()){
      addEmpError.empName="Enter Name";
    }
    if(!empEmail.trim()){
      addEmpError.empEmail="Enter Email";
    }
    if(!empPhone.trim()){
      addEmpError.empPhone="Enter Phone";
    }
    if(selectedCompanyname===""){
      addEmpError.selectedCompanyname="select company";
    }
    if(selectedPlantname===""){
      addEmpError.selectedPlantname="select plant";
    }
    if(selectedEmpStatus===""){
      addEmpError.selectedEmpStatus="select status";
    }
    if(selectedEmpGender===""){
      addEmpError.selectedEmpGender="select gender";
    }
    if(selectedMaritialStatus===""){
      addEmpError.selectedMaritialStatus="select Maritial Status";
    }
    if(selectedBloodGroup===""){
      addEmpError.selectedBloodGroup="select Blood Group";
    }
    if(joiningDate==null){
      addEmpError.joiningDate="select joining date";
    }
    if(birthDate==null){
      addEmpError.birthDate="select birth date";
    }
    if(selectedUserName===''){

      addEmpError.selectedUserName="Select Manager";
    }

    if(selectedJobName===''){
      addEmpError.selectedJobName="Select Job Type";
    }
    if(psgName===''){
      addEmpError.psgName="Select WS Rule";
    }
    if(roleIdList.length===0){
      addEmpError.roleIdList="Select Role";
    }
    if(!nid.trim()){
      addEmpError.nid="Enter NId";
    }

    setAddEmpError(addEmpError);

    return Object.keys(addEmpError).length ===0;
    
  }


  const getCompany = async () => {
    const result = await getCompanyList(companyUrl);
    setCompany(result.items);

  }


  const getPlant = async (code) => {

    const plant = await getPlantList(plantUrl, code);

    setPlant(plant.PLANT_DETAILS);
    setIsPlantLoading(false);

  }
  

  const getUsers = async () => {
    const users = await getUserList(userUrl, userNameCnt);
    setUsers(users.EMPLOYEE_LIST);
    setIsUsersLoading(false);
    setuserNameCnt('');
    usersRef.current.value = '';
  }
  const handleJoiningDateChange = (date) => {
    // const d=date;
    const formatted=moment(date).format("YYYY-MM-DD");
    setJoiningDate(formatted);
   setValue1(date);
    console.log(date);
  };
  const handleBirthDateChange = (date) => {
    
    const formatted=moment(date).format("YYYY-MM-DD");
    setBirthDate(formatted);
   setValue(date);
    console.log(birthDate);
  };
  
  return (
    <div className=' w-full  flex justify-center items-center'>


      <div className={`  shadow-xl rounded-lg w-3/4  p-8 flex flex-col items-start mt-16`}>
      {
              showAlert && <SuccessAlert text={'Employee Added Successfully'} setShowAlert={setShowAlert} />
              
            }
             {
              showErrorAlert && <ErrorAlert  text={'Could Not Add Employee'} setShowErrorAlert={setShowErrorAlert}/>
            }
        <h1 className={` text-sm font-bold text-${gray700} mt-3 mb-3`}>ADD EMPLOYEE</h1>
        <div className=' flex flex-row space-x-6 mb-3'>
          <div className=' flex flex-col items-start'>
          <input ref={empIdRef} onChange={(e) => { setEmpId(e.target.value) }} type="number" placeholder="Enter Employee Id" className="input input-bordered  w-[335px]  focus:outline-none  " />
          {addEmpError.empId && <span className={`text-${red500}`}>{addEmpError.empId}</span>}
          </div>
          <div className=' flex flex-col items-start'>
          <input ref={empNameRef} onChange={(e) => { setEmpName(e.target.value) }} type="text" placeholder="Enter Employee Name" className="input input-bordered w-[335px]  focus:outline-none  " />
          {addEmpError.empName && <span className={`text-${red500}`}>{addEmpError.empName}</span>}
          </div>
        </div>
        <div className=' flex flex-row space-x-6 mb-3'>
          {

           <div className=' flex flex-col items-start'>
             <select placeholder='Select Company' value={selectedCompanyname} onChange={(e) => { selectedCompanyCode = e.target.value; console.log(selectedCompanyCode); setCompanyCode(selectedCompanyCode); setSelectedCompanyName(e.company_name); getPlant(selectedCompanyCode) }} className={`select select-bordered w-[335px]   focus:outline-none text-${gray500}  `}>
              <option value='' disabled selected>Select Company</option>
              {
                company.map((e, index) =>
                  <option key={index} value={e.company_code}>{e.company_name}</option>
                )
              }
            </select>
            {addEmpError.selectedCompanyname && <span className={`text-${red500}`}>{addEmpError.selectedCompanyname}</span>}
           </div>

          }


          {

           <div>
             <select value={selectedPlantname} onChange={(e) => { selectedPalntCode = e.target.value; setPlantCode(selectedPalntCode); setSelectedPlantName(e.PLANT_NAME) }} className={`select select-bordered w-[335px]  focus:outline-none text-${gray500}  `}>
              <option value="" disabled selected>Select Plant</option>
              {
                isPlantLoading ?
                  <p>loading.....</p>
                  : plant.map((e, index) =>
                    <option value={e.PLANT_CODE} key={index}>{e.PLANT_NAME}</option>
                  )
              }

            </select>
            {addEmpError.selectedPlantname && <span className={`text-${red500}`}>{addEmpError.selectedPlantname}</span>}
           </div>

          }

        </div>
        <div className='flex flex-row space-x-6 mb-3'>
          {/* <label htmlFor="my-modal-3" className="btn mb-3 w-[335px]">ADD MANAGER</label> */}
          {/* <button className="btn btn-outline w-[335px] border-gray-300 text-gray-500 text-left">ADD MANAGER</button> */}

         <div className=' flex flex-col items-start'>
         <label onClick={() => { setSelectedUser({}); setUserId(''); setSelectedUserName('') }} htmlFor="my-modal-16" className="border-none bg-transparent hover:bg-transparent  w-[335px]">
            <div className={` w-full h-12 border-[0.2px] border-${gray300} rounded-md flex text-left justify-center items-center`}>
              <p className={`w-full  pl-6 text-${gray400} text-[16px]`}>{selectedUserName === '' ? "Add Manager" : selectedUserName}</p>
            </div>
          </label>
          {addEmpError.selectedUserName && <span className={`text-${red500}`}>{addEmpError.selectedUserName}</span>}
         </div>

          <div className='flex flex-col items-start'>
          <select value={selectedJobName} onChange={(e) => { jobId = e.target.value; setSelectedJobId(jobId); setSelectedJobName(e.JOB_NAME) }} className={`select select-bordered w-[335px]  focus:outline-none text-${gray500}  `}>
              <option value="" disabled selected>Select Job Type</option>
              {
                isJobLoading ?
                  <option>loading.....</option>
                  : jobs.map((e, index) =>
                    <option value={e.JOB_ID} key={index}>{e.JOB_NAME}</option>
                  )
              }

            </select>
            {addEmpError.selectedJobName && <span className={`text-${red500}`}>{addEmpError.selectedJobName}</span>}
          </div>

          
        </div>

        <div className=' flex flex-row space-x-6 mb-3'>
          <div className=' flex flex-col items-start'>
          <input ref={empEmailRef} onChange={(e) => { setEmpEmail(e.target.value) }} type="text" placeholder="Enter Email" className="input input-bordered  w-[335px]  focus:outline-none " />
          {addEmpError.empEmail && <span className={`text-${red500}`}>{addEmpError.empEmail}</span>}
          </div>
         <div  className=' flex flex-col items-start'>
         <input  ref={empPhoneRef} onChange={(e) => { setEmpPhone(e.target.value) }} type="number" placeholder="Enter Phone" className="input input-bordered w-[335px]  focus:outline-none " />
         {addEmpError.empPhone && <span className={`text-${red500}`}>{addEmpError.empPhone}</span>}
         </div>
        </div>
        <div className=' flex flex-row space-x-6 mb-3'>

          <div className=' flex flex-col items-start'>
       
          <select value={selectedPositionName} onChange={(e) => { positionId = e.target.value; setSelectedPositionId(positionId); setSelectedPositionName(e.POSITION_NAME) }} className={`select select-bordered w-[335px]  focus:outline-none text-${gray500}  `}>
              <option value="" disabled selected>Select Position</option>
              {
                isPositionsLoading ?
                  <option>loading.....</option>
                  : positions.map((e, index) =>
                    <option value={e.POSITION_ID} key={index}>{e.POSITION_NAME}</option>
                  )
              }

            </select>

          {addEmpError.selectedEmpStatus && <span className={`text-${red500}`}>{addEmpError.selectedEmpStatus}</span>}
          </div>
          <div className=' flex flex-col items-start'>
          <select value={selectedEmpGender} onChange={(e)=>{setSelectedEmpGender(e.target.value)}} className={`select select-bordered w-[335px]  focus:outline-none text-${gray500} `}>
            <option value='' disabled selected>Employee Gender</option>
            <option value="M">Male</option>
            <option value="F">Female</option>
            <option value="O">Others</option>
          </select>
          {addEmpError.selectedEmpGender && <span className={`text-${red500}`}>{addEmpError.selectedEmpGender}</span>}
          </div>
        </div>
        <div className=' flex flex-row space-x-6 mb-3'>

         <div className=' flex flex-col items-start'>
         <select value={selectedMaritialStatus} onChange={(e)=>{setSelectedMaritialStatus(e.target.value)}} className={`select select-bordered w-[335px]  focus:outline-none text-${gray500} `}>
            <option value="" disabled selected>Maritial Status</option>
            <option value="Married">Married</option>
            <option value="Unmarried">Unmarried</option>
            <option value="Divorced">Divorced</option>
          </select>
          {addEmpError.selectedMaritialStatus && <span className={`text-${red500}`}>{addEmpError.selectedMaritialStatus}</span>}
         </div>
          <div className=' flex flex-col items-start'>
          <select value={selectedBloodGroup} onChange={(e)=>{setSelectedBloodGroup(e.target.value)}} className={`select select-bordered w-[335px]  focus:outline-none text-${gray500} `}>
            <option value="" disabled selected>Blood Group</option>
            <option value="A+">A+</option>
            <option value="A-">A-</option>
            <option value="B+">B+</option>
            <option value="B-">B-</option>
            <option value="O+">O+</option>
            <option value="O-">O-</option>
            <option value="AB+">AB+</option>
            <option value="AB-">AB-</option>
          </select>
          {addEmpError.selectedBloodGroup && <span className={`text-${red500}`}>{addEmpError.selectedBloodGroup}</span>}
          </div>
        </div>
        <div className=' flex flex-row space-x-6 mb-3'>

          <div className=' flex flex-col items-start'>
          <DatePicker
            appearance="default"
            size="lg"
            format="dd-MM-yyyy"
            placeholder="Joining Date"
            onChange={handleJoiningDateChange}
            value={value1}
            onClean={handleCleanJoiningdate}
            ranges={[
              {
                label: "Now",
                value: new Date(),
              },
            ]}
            style={{ width: 335, height: 50 }}
          />
          {addEmpError.joiningDate && <span className={`text-${red500}`}>{addEmpError.joiningDate}</span>}
          </div>
          <div className=' flex flex-col items-start'>
          <DatePicker
            appearance="default"
            size="lg"
            format="dd-MM-yyyy"
            placeholder="Birth Date"
            onChange={handleBirthDateChange}
            value={value}
            onClean={handleCleanBirthDate}
            ranges={[
              {
                label: "Now",
                value: new Date(),
              },
            ]}
            style={{ width: 335, height: 50 }}
          />
          {addEmpError.birthDate && <span className={`text-${red500}`}>{addEmpError.birthDate}</span>} 
          </div>

        </div>
        <div className=' flex flex-row space-x-6 mb-3'>
        <div className='w-[335px] '>
       <div className=' flex flex-col items-start'>
       <select value={psgName} onChange={(e)=>{selectPsgid=e.target.value; setPsgId(selectPsgid); setPsgName(e.name)}} className="select select-bordered w-full focus:outline-none mb-2">
      <option value="" disabled selected>Select WS Rule</option>
      {
        psgList.map((e,index)=>
        <option value={e.id} key={index}>{e.name}</option>
        )
      }
    </select>
    {addEmpError.psgName && <span className={`text-${red500}`}>{addEmpError.psgName}</span>} 
       </div>
        </div>
        <div className=' flex flex-col items-start'>
          <input ref={empNidRef} onChange={(e) => { setNid(e.target.value) }} type="number" placeholder="Enter Employee NId" className="input input-bordered  w-[335px]  focus:outline-none  " />
          {addEmpError.nid && <span className={`text-${red500}`}>{addEmpError.nid}</span>}
          </div>

        </div>
        <select value={selectedEmpStatus} onChange={(e)=>{setSelectedEmpStatus(e.target.value)}} className={`select select-bordered w-[335px]  focus:outline-none text-${gray500} mb-3`}>
            <option value="" disabled selected>Employee Status</option>
            <option value="Provision">Provision</option>
            <option value="Permanent">Permanent</option>
          </select>
          {addEmpError.selectedEmpStatus && <span className={`text-${red500}`}>{addEmpError.selectedEmpStatus}</span>}

       

       <h2 className={`text-${gray700} text-sm  ${addEmpError.roleIdList && `text-${red500}`}`}>Select Employee Role</h2>
         <div className=' w-full mb-3'>
         <div className=' rounded-md   grid grid-rows-2 grid-flow-col p-2   gap-3   '>
              {
                roles.map((e,index)=>
                <button onClick={()=>{  if(roleIdList.includes(e.ROLE_ID)){handleRemoveClick(e.ROLE_ID)}else{handleClick({"ROLE_ID": e.ROLE_ID});}}} key={index} className={` badge  badge-lg badge-outline ${roleIdList.includes(e.ROLE_ID)?"bg-green-600 text-white":" bg-transparent"}`}>{e.ROLE_NAME}</button>
                // <div className={`border-[0.2px] border-gray-300 w-auto  px-2 rounded-3xl flex justify-center items-center`}>{e.ROLE_NAME}</div>
                )
              }
          </div>
         </div>
          {addEmpError.roleIdList && <span className={`text-${red500}`}>{addEmpError.roleIdList}</span>} 
       
                 

        <button onClick={()=>{adduser()}} className={`mt-3 btn btn-block bg-${green600} border-none`}>ADD</button>
      </div>

      {/* start select user modal */}
      <input type="checkbox" id="my-modal-16" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box h-3/4">
          <label onClick={(e) => {
            setUsers([]); setuserNameCnt('');
            usersRef.current.value = '';
          }} htmlFor="my-modal-16" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
          <h3 className="font-bold text-lg">Select User</h3>
          <div className='w-full flex flex-col justify-center items-center my-3'>
            <div className="form-control">
              <div className="input-group ">
                <input ref={usersRef} onChange={(e) => { setuserNameCnt(e.target.value) }} type="text" placeholder="Search…" className="input input-bordered w-72 focus:outline-none" />
                <button onClick={getUsers} className="btn btn-square">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" /></svg>
                </button>
              </div>
            </div>

            {

              users.length === 0 ?
                <p className=' mt-16 font-medium text-lg'>Search Name Here</p> :
                isUsersLoading ?
                  <p>loding...</p> :

                  users.map((e, index) =>
                    <div key={index} onClick={(v) => { setSelectedUser(e); selectedUserId = e.EMPLOYEE_ID; setUserId(selectedUserId); setSelectedUserName(e.EMPLOYEE_NAME) }} className="modal-action w-[336px]">
                      <label htmlFor="my-modal-16" className="w-full border-none bg-transparent hover:bg-transparent">
                        <div className={` w-full h-12 shadow-md  rounded-md flex text-left justify-center items-center`}>
                          <p className={`w-full  pl-6 text-${gray600} text-[16px]`}>{`${e.EMPLOYEE_NAME} (${e.POSITION_NAME})`}</p>
                        </div>
                      </label>
                    </div>
                  )

            }

          </div>

        </div>
      </div>

      {/* end select user modal */}

    </div>
  )
}
