import { baseUrl } from "../../utils/path";


  

  const MyIouService= async(empId, status)=>{
   
   
     const url=`${baseUrl}my_iou`;

     console.log(`empId:${empId} status: ${status}`);
   
     const response = await   fetch(url,
         {
             method: "POST",
             headers: {
               
               "Content-Type": "application/json",
               'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
             },
             body: JSON.stringify({
                
                    "STATUS": status,
                    "REQUSTOR_ID":empId
                  
             }),
           }
         );
      
           const data = await response.json();
         
           return data;
 
      
 }
 export default MyIouService
 