import { baseUrl } from "../../utils/path";

const LeaveApplyImageService = async(applyId, picName) => {

  const url = `${baseUrl}insert_leave_doc`;

  console.log("applyId", applyId);
  console.log("fromDate: ", picName);
    
  const response = await fetch(url,
      {
          method: "POST",
          headers: {
            
            "Content-Type": "application/json",
            'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
          },
          body: JSON.stringify({
              "APPL_ID": applyId,
              "DOC": picName
          }),
        }
      );  
        const data = await response.json();
        console.log(`users: ${data}`);
        return data;

   
}
export default LeaveApplyImageService;
