import React, { useEffect, useState } from 'react';
import UserRoleService from '../service/UserRoleService';
// import Datepicker from "react-tailwindcss-datepicker";
import moment from 'moment';
import {
  gray500,
  gray100,
  red500,
  gray300,
  gray400,
  green100,
  green600,
  gray700,
  gray600,
} from "../../utils/colors";
import Loading from '../../components/loader/Loading';

export default function UserRolePage() {

  let [pageCount,setPage]=useState(1);
  let [page,setPg]=useState(0);
  let [pageCountSearch,setPageSearch]=useState(1);
  let [pageSearch,setPgSearch]=useState(0);
  const limit = 10;
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [value, setValue] = useState({startDate: null, endDate: null});
  const [isSearch, setIsSearch]=useState(false);
  const [totalPage, setTotalPage]=useState(null);
  const [empId, setEmpId] = useState('');
  
  const [isAscending, setIsAscending] = useState(true);


  // date format start here
  const currentDate = new Date();
  // Get the year and month of the current date
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1; // Add 1 to get the month number in the correct range (1-12)

  // Create a new Date object for the first day of the month
  const firstDayOfMonth = new Date(year, month - 1, 1);
  const lastDayOfMonth = new Date(year, month, 0);

  const formatStartDay = moment(firstDayOfMonth).format("YYYY-MM-DD");
  const formatEndDay = moment(lastDayOfMonth).format("YYYY-MM-DD");
    
    
  useEffect(() => {
    const getData = async () => {
      const result = await UserRoleService("", "", "", "", "", page, limit);
      dividePage(result.Total);
         
      setData(result.Employee_Role_Record);
      setIsLoading(false);
    };

    getData();
  },[ page, limit] );

  // divided page number to allPage
  const dividePage = (number) => {
    console.log(number);
    if (typeof number !== 'number') {
      throw new Error('Input must be a number');
    }
      
    const re= Math.ceil(number / 10);
    setTotalPage(re);
  };

  // const handleSortByEmpName = () => {
  //   const sorted = [...data].sort((a, b) =>
  //     isAscending
  //       ? a.EMPLOYEE_NAME.localeCompare(b.EMPLOYEE_NAME)
  //       : b.EMPLOYEE_NAME.localeCompare(a.EMPLOYEE_NAME)
  //   );
  //   setData(sorted);
  //   setIsAscending(!isAscending);
  // };

  // sorting by EMPLOYEE_ID
  const handleSortByEmpId = () => {
    const sorted = [...data].sort((a, b) =>
      isAscending
        ? a.EMPLOYEE_ID - b.EMPLOYEE_ID
        : b.EMPLOYEE_ID - a.EMPLOYEE_ID
    );
    setData(sorted);
    setIsAscending(!isAscending);
  };
  
  // sorting by CREATION DATE
  const handleSortByDate = () => {
    const sorted = [...data].sort((a, b) =>
      isAscending
        ? a.CREATION_DATE - b.CREATION_DATE
        : b.CREATION_DATE - a.CREATION_DATE
    );
    setData(sorted);
    setIsAscending(!isAscending);
  };

  // go to next page
  const next=async()=>{
    // setPage((page)=>page+10);
    const newPage = page + 10;
    setPg(newPage);
    console.log(`page here ${newPage}`);
    setPage((pre)=>pre+1);
  
  
    setIsLoading(true);
    setData([]);
  
    const result = await UserRoleService("", "", "", "", "", newPage, limit)
      
    dividePage(result.Total);
    setData(result.Employee_Role_Record);
    setIsLoading(false);
    
    console.log('clicked');
  }

  // afterSearching next page
  const searchNext = async() => {      
    // setPage((page)=>page+10);
    const newPage = pageSearch + 10;
    setPgSearch(newPage);
    console.log(`page here ${newPage}`);
    setPageSearch((pre)=>pre+1);

    setIsLoading(true);
    setData([]);

    const result = await UserRoleService("", "", "", "", "", newPage, limit);

    dividePage(result.Total);
      
    setData(result.Employee_Role_Record);
    setIsLoading(false);
  }

  // back to previous page
  const previous=async()=>{
      
    // setPage((pre)=>pre-10);
    const newPage = page - 10;
    setPg(newPage);
    console.log(`page here ${newPage}`);
    setPage((pre)=>pre-1);
  
    console.log('previous button clicked');
    setIsLoading(true);
    setData([]);
    
    const result = await UserRoleService("", "", "", "", "", newPage, limit);
    
    dividePage(result.Total);

    setData(result.Employee_Role_Record);
    setIsLoading(false);
  }

  // afterSearching back previous
  const searchPrevious=async()=>{
    const newPage = pageSearch - 10;
    setPgSearch(newPage);
    console.log(`page here ${newPage}`);
    setPageSearch((pre)=>pre-1);
  
    console.log('clicked');
    setIsLoading(true);
    setData([]);
    
    const result = await UserRoleService("", "", "", "", "", newPage, limit);

    dividePage(result.Total);
    setData(result.Employee_Role_Record);
    setIsLoading(false);
    // console.log(result.ATTENDANCE_DETAILS);
  }

  // const handleValueChange = (newValue) => {
  //   console.log("start:", moment(newValue.startDate).format("YYYY-MM-DD"));
  //   console.log("end:", moment(newValue.endDate).format("YYYY-MM-DD"));
  //   setValue(newValue);
  // }

  // search by EMPLOYEE_ID
  const searchEmpId = async () => {
    setIsLoading(true);
    

    const result = await UserRoleService(empId, "", "", "", "", page, limit);

    dividePage(result.Total);
    console.log(result.Total);
    setData(result.Employee_Role_Record);
    setIsLoading(false);
  };
      

  return (
    <div className="mx-8 my-10">
      <div className="h-8"></div>

      <div className='flex flex-col md:flex-row justify-between items-center'>
        <div>
          <h1 className="text-lg font-medium text-black">
            Employee Role Record
          </h1>
        </div>
          
        {/* calender */}
        {/* <div className='lg:w-80'>
          <Datepicker
            popoverDirection='down'

            placeholder='Start Date - End Date         '
            showFooter={true}
            primaryColor={"green"}
            showShortcuts={true}
            value={value}
            onChange={handleValueChange}
            displayFormat={"DD/MM/YYYY"}
            toggleClassName={`absolute bg-green-600 rounded-r-lg text-white right-0 h-full px-3 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed`}
              inputClassName={`outline-green-600 w-full rounded-r-lg h-12 border-[1px] border-gray-300 rounded-md px-3`}
          />
        </div> */}
      </div>

      {/* table data start here */}

      <div className="h-5"></div>

      {
        isLoading ? (
          <Loading />
          ) : (
            
            <div>
              <div className='flex items-center space-x-1'>
                <input onChange={(e)=>{setEmpId(e.target.value)}} onKeyPress={(e) => { if (e.key === 'Enter') searchEmpId(); }} value={empId} type="text" className=' px-2 h-10 rounded-md w-48 border-[0.2px] border-gray-400 placeholder:text-sm placeholder:text-gray-400 focus:outline-none bg-white' placeholder='Search by Employee ID' />

                <button onClick={() => {searchEmpId()}} className={`h-10 w-12 rounded-md shadow-sm flex justify-center items-center bg-buttonColor px-2`}>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-8 text-white font-bold">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                  </svg>
                </button>
              </div>
            
            <div className='h-5'></div>

              <div className="w-full">
              {
                data.length === 0 ? (
                  <div className='flex flex-row justify-center items-center text-center w-full h-screen'>
                    <h1 className={`text-red-500 text-center w-full font-bold text-2xl`}>No Data Found</h1>
                  </div>
                ) : (
                  <table className="w-full border-[0.5px] border-gray-200">
                    <thead className="bg-bgTableHeader text-tableHeaderText shadow-sm h-14">
                      <tr>
                        <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                          SL
                        </th>

                        <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                          <span>EMPLOYEE ID</span> 
                          {/* {' '} <span className='cursor-pointer text-lg'>{isAscending ? <span>&uarr;</span> : <span>&darr;</span>}</span> */}
                        </th>
                        
                        <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                          EMPLOYEE NAME
                        </th>
                        
                        <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                          ROLE NAME
                        </th>
                        
                        <th className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                          ASSIGNED BY
                        </th>
                      
                        <th  onClick={handleSortByDate} className="px-6 py-3 text-left text-sm uppercase tracking-wider">
                          ASSIGNED DATE {' '} <span className='cursor-pointer text-lg'>{isAscending ? <span>&uarr;</span> : <span>&darr;</span>}</span>
                        </th>
                      </tr>
                    </thead>
                    
                    <tbody className="w-full divide-y divide-borderColor">
                      {data.map((e, index) => (
                        <tr key={index} className="bg-white">
                          <td className="px-6 py-4 whitespace-nowrap">
                            {page + index + 1}
                          </td>

                          <td className="px-6 py-4 whitespace-nowrap">
                            {e.EMPLOYEE_ID == null ? "N/A" : e.EMPLOYEE_ID}
                          </td>
                          
                          <td className="px-6 py-4 whitespace-nowrap">
                            {e.EMPLOYEE_NAME == null ? "N/A" : e.EMPLOYEE_NAME}
                          </td>
                          
                          <td className="px-6 py-4 whitespace-nowrap">
                            {e.ROLE_NAME == null ? "N/A" : e.ROLE_NAME}
                          </td>
                          
                          <td className="px-6 py-4 whitespace-nowrap">
                            {e.ASSIGNER_NAME == null ? "N/A" : e.ASSIGNER_NAME}
                          </td>
                          
                          <td className="px-6 py-4 whitespace-nowrap">
                            {e.CREATION_DATE == null ? "N/A" : e.CREATION_DATE}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )
              }
            </div>
          </div>
        )
      }

      <div className="h-5"></div>

        {data.length > 0 && (
          <div className='flex flex-row space-x-4 justify-center items-end mt-4 mb-3 lg:w-full md:w-3/4 sm:w-96 pr-6'>
            <button disabled={page === 0 && pageSearch === 0 ? true : false} onClick={() => { isSearch ? searchPrevious() : previous() }}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-6 h-6 font-bold ${page === 0 && pageSearch === 0 ? 'text-white' : `text-${gray600}`} font-bold`}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
              </svg>
            </button>

            <p className={`text-${gray500} text-[18px] font-bold`}>
              Page {isSearch ? pageCountSearch : pageCount} Of {totalPage}
            </p>

            <button disabled={(isSearch ? pageCountSearch : pageCount) === totalPage || data.length === 0} onClick={() => { isSearch ? searchNext() : next() }}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-6 h-6 font-bold ${((isSearch ? pageCountSearch : pageCount) === totalPage || data.length === 0) ? 'text-white' : `text-${gray600}`} font-bold`}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
              </svg>
            </button>
          </div>
        )}

    </div>
  )
}