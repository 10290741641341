import React, { useRef, useState, useEffect } from "react";
import InputLebel from "../../common/InputLebel";
import GetIouTypeService from "../service/GetIouTypeService";
import AdminInfoService from "../../services/AdminInfoService";
import GetApproverService from "../../move_order/service/GetApproverService";
import CreateIouService from "../service/CreateIouService";
import CreateIouItemService from "../service/CreateIouItemService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { baseUrl } from "../../utils/path";
import { useHomePageContext } from "../../components/home_component/HomePageContext";
import { useIouContext } from "../context/IouContext";

const infoUrl = `${baseUrl}my_info`;

export default function CreateIouPage() {
    const itemDescriptionRef = useRef(null);
    const itemPriceRef = useRef(null);
    const [userId, setUserId] = useState("");
    const [employeeId, setEmployeeId] = useState("");
    const [purpose, setPurpose] = useState("");
    const [iouItemList, setIouItemList] = useState([]);
    const [openClose, setOpenClose] = useState(false);
    const [iouItemDescription, setIouItemDescription] = useState("");
    const [itemPrice, setItemPrice] = useState("");
    const [itemError, setItemError] = useState({});
    const [iouDate, setIouDate] = useState("");
    const [companyCode, setCompanyCode] = useState("");
    const [plantCode, setPlantCode] = useState("");
    const [approverId, setApproverId] = useState("");
    const [showAlert, setShowAlert] = useState(null);
    const { setIouPageNo} = useIouContext();

    const back = () => {
        console.log('send action');
        setIouPageNo(1);
    } 

    const formatDate = (date) => {
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const currentDate = new Date();

    useEffect(() => {
        const id = localStorage.getItem("userId");
        const eid = localStorage.getItem("empId");
        console.log(eid);
        setUserId(id);
        setEmployeeId(eid);
        setIouDate(formatDate(currentDate));
        getIouType(id);
        getAdminInfo(id);
    }, []);

    const getAdminInfo = async (uid) => {
        const admin = await AdminInfoService(infoUrl, uid);

        setCompanyCode(admin.EMP_DETAILS[0].COMPANY_CODE);
        setPlantCode(admin.EMP_DETAILS[0].PLANT_CODE);
    };

    const getApprover = async () => {};

    //mv type
    const [iouTypeList, setIouTypeList] = useState([]);
    let selectedIouTypeCode;
    const [iouTypeCode, setIouTypeCode] = useState("");
    const [iouTypeName, setIouTypeName] = useState("");
    //mv type

    const getIouType = async (id) => {
        const re = await GetIouTypeService(id);
        setIouTypeList(re.iou_type);
    };

    const handlePurposeChange = (e) => {
        const inputText = e.target.value;
        if (inputText.length <= 150) {
            setPurpose(inputText);
        }
    };

    // const handleItemDescriptionChange = (e) => {
    //     const inputText = e.target.value;

    //     setIouItemDescription(inputText);
    // };

    const maxCharLimit = 100;

    const handleItemDescriptionChange = (e) => {
        const inputText = e.target.value;

        if(inputText.length <= maxCharLimit) {
            setIouItemDescription(inputText);
            setShowAlert(null); // Clear any previous character limit exceeded message
        } 
        else {
            setShowAlert('Character limit exceeded!');
        }
    };

    const addItem = (item) => {
        setIouItemList([
            ...iouItemList,
            {
                item_desc: item.item_desc,
                item_amount: parseFloat(item.item_amount),
            },
        ]);
    };

    // Function to update an item at a specific index
    const updateItemAtIndex = (index, newItem) => {
        iouItemList[index] = {
            ...iouItemList[index],
            ...{
                item_desc: newItem.item_desc,
                item_amount: parseFloat(newItem.item_amount),
            },
        };
    };

    // Example usage
    // addItem({ "item_desc": "new item", "item_amount": 100 });
    // updateItemAtIndex(3, { "item_amount": 999 });
    const [index, setIndex] = useState();
    const [isUpdate, setIsUpdate] = useState(false);
    const update = (index, des, prc) => {
        setIsUpdate(true);
        setIndex(index);
        setIouItemDescription(des);
        itemPriceRef.current.value = prc;
        setItemPrice(prc);
        setOpenClose(true);
    };

    const updateFinal = () => {
        updateItemAtIndex(index, {
            item_desc: iouItemDescription,
            item_amount: itemPrice,
        });
        setIsUpdate(false);
        setOpenClose(false);
        setIouItemDescription("");
        itemPriceRef.current.value = "";
        setItemPrice("");
    };

    const validateItem = () => {
        const itemError = {};
        if (iouItemDescription === "") {
            itemError.iouItemDescription = "Please Enter Description";
        }
        if (!itemPrice.trim()) {
            itemError.itemPrice = "Please Enter Item Price";
        }
        setItemError(itemError);
        return Object.keys(itemError).length === 0;
    };

    const [iouError, setIouError] = useState({});
    const validateIou = () => {
        const iouError = {};
        if (iouTypeCode === "") {
            iouError.iouTypeCode = "Please Select Iou Type";
        }
        if (iouDate === "") {
            iouError.iouDate = "Iou Date Invalid";
        }
        if (purpose === "") {
            iouError.purpose = "Please Enter Iou Purpose";
        }
        if (iouItemList.length === 0) {
            iouError.iouItemList = "Please Add Iou Item";
        }

        setIouError(iouError);
        return Object.keys(iouError).length === 0;
    };

    const showToastError = (message) => {
        toast.error(message, {
            autoClose: 3000,
            position: toast.POSITION.TOP_RIGHT,
        }); // autoClose is in milliseconds
    };
    const showToastSuccess = (message) => {
        toast.success(message, {
            autoClose: 3000,
            position: toast.POSITION.TOP_RIGHT,
        }); // autoClose is in milliseconds
    };
    const { menu, setMenu } = useHomePageContext();

    const iouCreationCycle = async () => {
        if (validateIou()) {
            const re = await GetApproverService(employeeId, "IOU", iouTypeCode);

            // console.log(re.EMPLOYEE_LIST[0].APPROVER_ID);

            if (re.EMPLOYEE_LIST.length === 0) {
                showToastError("No Approver Exist For This User");
            } else {
                setApproverId(re.EMPLOYEE_LIST[0].APPROVER_ID);
                const ciou = await CreateIouService(
                    employeeId,
                    iouTypeName,
                    purpose,
                    re.EMPLOYEE_LIST[0].APPROVER_ID,
                    iouDate,
                    companyCode,
                    plantCode
                );
                const iouId = ciou.IOU_ID;
                const newArray = iouItemList.map((iou) => ({
                    iou_id: iouId,
                    item_desc: iou.item_desc,
                    item_amount: iou.item_amount,
                }));
                const item = CreateIouItemService(newArray);
                showToastSuccess("Iou Created Successfully");
                clearValue();
                setShowAlert(true);
                setTimeout(() => {
                    setIouPageNo(1);
                }, 3200); // Delay of 1 seconds
            }
        }
    };

    const clearValue = () => {
        setIouTypeCode("");
        setIouTypeName("");
        setPurpose("");
        setIouItemList([]);
    };

    const submit = () => {
        if (validateItem()) {
            // if((iouItemDescription!=='' || itemPrice!=='')){
            //     updateItemAtIndex(index,{ "item_desc": iouItemDescription, "item_amount": itemPrice })
            // }
            // else{
            //     addItem({ "item_desc": iouItemDescription, "item_amount": itemPrice });
            // }
            addItem({ item_desc: iouItemDescription, item_amount: itemPrice });
            setIouItemDescription("");
            itemPriceRef.current.value = "";
            setItemPrice("");
            setOpenClose(false);
        }
    };
    //     const [totalprice,setTotalprice]=useState(0);
    // let ss;
    //     const calculateTotalPrice=()=>{
    //    let sum=0;
    //    let i=0;
    //    for(i=0;i<iouItemList.length;i++){
    //     sum=sum+iouItemList[i].item_amount;
    //    }
    //    ss=sum;
    //    console.log(ss);
    //     setTotalprice(sum);
    //     }

    const calculateTotalPrice = () => {
        const totalAmount = iouItemList.reduce(
            (total, item) => total + item.item_amount,
            0
        );
        return totalAmount;
    };

    const deleteItemAtIndex = (index) => {
        if (index >= 0 && index < iouItemList.length) {
            const updatedList = [...iouItemList];
            updatedList.splice(index, 1);
            setIouItemList(updatedList);
        }
        calculateTotalPrice();
        setIouItemDescription("");
        itemPriceRef.current.value = "";
        setItemPrice("");
    };

    // const { setIouPageNo } = useIouContext();

    // const back = () => {
    //     setIouPageNo(1);
    // }

    return (
        <div className=" my-20 px-8">
          <div className="flex justify-between items-center">
            <p className=" text-lg font-bold text-black">Create IOU</p>

            <button onClick={back} className=' py-1 px-10 rounded-full bg-buttonColor text-[16px] font-medium text-white'>Back</button>
          </div>

            <div className="h-6"></div>
            <ToastContainer />

            <div className=" flex flex-row items-center space-x-10">
                <div className=" w-full flex flex-col items-start">
                    <InputLebel text="Select Iou Type *" />
                    <div className="h-1"></div>

                    <select
                        value={iouTypeCode}
                        onChange={(e) => {
                            const selectedMoveTypeCode = e.target.value; // Use const instead of changing a variable outside this scope
                            setIouTypeCode(selectedMoveTypeCode);

                            // Assuming e contains TYPE_NAME property
                            const jnf =
                                e.target.options[
                                    e.target.selectedIndex
                                ].getAttribute("data-type-name");
                            setIouTypeName(jnf);
                            console.log(`ccc : ${jnf}`);
                        }}
                        className="select select-bordered w-full bg-white"
                    >
                        <option value="" disabled>
                            Select
                        </option>
                        {iouTypeList.map((e, i) => (
                            <option
                                key={i}
                                value={e.IOU_ID}
                                data-type-name={e.IOU_NAME} // Add a data attribute to hold TYPE_NAME
                            >
                                {e.IOU_NAME}
                            </option>
                        ))}
                    </select>
                    {iouError.iouTypeCode && (
                        <span className={`text-red-500`}>
                            {iouError.iouTypeCode}
                        </span>
                    )}
                </div>
                <div className=" w-full flex flex-col items-start">
                    <InputLebel text="Iou Date *" />
                    <div className="h-1"></div>
                    <div className=" w-full h-12 border-gray-300 border-[1.5px] flex items-center justify-start rounded-[8px] px-4 ">
                        {iouDate && <p>{iouDate}</p>}
                    </div>
                </div>
                {iouError.iouDate && (
                    <span className={`text-red-500`}>{iouError.iouDate}</span>
                )}
            </div>
            <div className=" h-4"></div>
            <div className=" w-full flex flex-col items-start">
                <InputLebel text="Purpose *" />
                <div className="h-1"></div>
                <textarea
                    className=" w-full h-24 border-gray-300 border-[1.5px] rounded-[8px] px-2 py-1 focus:outline-none bg-white"
                    value={purpose}
                    onChange={handlePurposeChange}
                    maxLength={150}
                    // rows={4} // Adjust the number of rows as needed
                    // cols={50} // Adjust the number of columns as needed
                />
                <div className=" w-full flex flex-row justify-end items-end">
                    {purpose.length}/150
                </div>
                {iouError.purpose && (
                    <span className={`text-red-500`}>{iouError.purpose}</span>
                )}
            </div>
            <div className="h-4"></div>
            <div className="overflow-x-auto ">
                {iouItemList.length === 0 ? (
                    <div className="  flex-col  w-full h-32 flex justify-center items-center">
                        <p className=" text-lg font-semibold text-gray-500">
                            ADD ITEM
                        </p>
                        {iouError.iouItemList && (
                            <span className={`text-red-500`}>
                                {iouError.iouItemList}
                            </span>
                        )}
                    </div>
                ) : (
                    <table className="table w-full mt-1 mb-10">
                        {/* head */}
                        <thead>
                            <tr>
                                <td></td>
                                <th>Details</th>
                                <th>Taka</th>

                                <th>Action</th>
                            </tr>
                        </thead>

                        <tbody>
                            {iouItemList.map((e, index) => (
                                <tr key={index}>
                                    <td></td>

                                    <td>{e.item_desc}</td>

                                    <td>{e.item_amount}</td>
                                    <td>
                                        <div className=" flex flex-row space-x-4 items-center">
                                            <button
                                                onClick={() =>
                                                    update(
                                                        index,
                                                        e.item_desc,
                                                        e.item_amount
                                                    )
                                                }
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth={1.5}
                                                    stroke="currentColor"
                                                    className="w-6 h-6"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                                                    />
                                                </svg>
                                            </button>
                                            <button
                                                onClick={() => {
                                                    deleteItemAtIndex(index);
                                                }}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth={1.5}
                                                    stroke="currentColor"
                                                    className="w-6 h-6"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
            <div className="h-10"></div>
            <div className=" flex flex-row items-end justify-end space-x-6">
                <p className=" text-black text-lg font-semibold">Total:</p>
                <p className=" text-black text-lg font-semibold">
                    {calculateTotalPrice()}৳
                </p>
            </div>
            <div className="h-16"></div>
            <div className="px-40 flex flex-row justify-between items-center">
                <button
                    onClick={iouCreationCycle}
                    className=" w-60 h-12 rounded-full flex justify-center items-center bg-buttonColor text-white"
                >
                    SUBMIT
                </button>
                <button
                    onClick={() => {
                        setOpenClose(true);
                    }}
                    className=" w-60 h-12 rounded-full flex justify-center items-center bg-blue-500 text-white"
                >
                    ADD ITEM
                </button>
            </div>
            <div className="h-10"></div>
            {showAlert === true ? (
                <div className="alert alert-success shadow-lg mt-4">
                    <div>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="stroke-current flex-shrink-0 h-6 w-6 text-white"
                            fill="none"
                            viewBox="0 0 24 24"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                        </svg>
                        <span className=" text-white font-semibold text-sm">
                            IOU Created Successfully
                        </span>
                    </div>
                </div>
            ) : null}
            <div className="h-10"></div>
            <input
                type="checkbox"
                id="my-modal-11"
                className="modal-toggle"
                checked={openClose}
            />
            <div className="modal">
                <div className="modal-box bg-white">
                    <div className=" w-full h-80 flex  flex-col items-start bg-white">
                        <div className="w-full flex flex-row justify-end items-end">
                            <button
                                onClick={() => {
                                    setOpenClose(false);
                                    if (isUpdate) {
                                        setIsUpdate(false);
                                    }
                                }}
                                className=" w-8 h-8 rounded-full bg-red-500 flex justify-center items-center"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-6 h-6 text-white"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                    />
                                </svg>
                            </button>
                        </div>
                        <div className="h-5"></div>
                        <InputLebel text="Description *" />
                        <div className="h-1"></div>
                        <textarea
                            value={iouItemDescription}
                            onChange={handleItemDescriptionChange}
                            className="px-2 py-1 w-full h-24 border-[1px] border-gray-300 bg-white rounded-md focus:outline-blue-300"
                        ></textarea>

                        <div className="flex items-center justify-between w-full">
                            <p>
                                {showAlert && (
                                    <p style={{ color: 'red' }}>{showAlert}</p>
                                )}
                            </p>
                            <p style={{ color: iouItemDescription.length < maxCharLimit ? 'black' : 'red' }}>
                                {iouItemDescription.length} <span className='text-black'>/ {maxCharLimit}</span>
                            </p>
                        </div>

                        {itemError.iouItemDescription && (
                            <span className={`text-red-500`}>
                                {itemError.iouItemDescription}
                            </span>
                        )}
                        <div className="h-5"></div>
                        <InputLebel text="Taka *" />
                        <div className="h-1"></div>
                        <input
                            min={1}
                            type="number"
                            ref={itemPriceRef}
                            onChange={(e) => {
                                setItemPrice(e.target.value);
                            }}
                            className="border-[1px] px-2 border-gray-300 bg-white rounded-md w-full h-12 focus:outline-blue-300"
                        />
                        {itemError.itemPrice && (
                            <span className={`text-red-500`}>
                                {itemError.itemPrice}
                            </span>
                        )}
                        <div className="h-5"></div>
                        <button
                            onClick={isUpdate ? updateFinal : submit}
                            className=" w-full h-10 rounded-full bg-buttonColor text-white flex justify-center items-center"
                        >
                            {isUpdate //true false korte hbe
                                ? "UPDATE"
                                : "SUBMIT"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
