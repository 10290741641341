import { useEffect, useState } from "react";
import { useIouContext } from "../context/IouContext";
import ApproverHierarchyListService from "../../move_order/service/ApproverHierarchyListService";
import moment from "moment";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import AdminInfoService from "../../services/AdminInfoService";
import IouItemDetailsService from "../service/IouItemDetailsService";
import { baseUrl } from "../../utils/path";
import numeral from "numeral";

const infoUrl = `${baseUrl}my_info`;

export default function IouDetailsPopup() {
  const [isOpen, setIsOpen] = useState(false);
  const [approverList, setApproverList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [employeeId, setEmployeeId] = useState('');
  const [designation, setDesignation] = useState('');
//   const iouContext = useIouContext();
  const [empId, setEmpId] = useState('');
  const [joiningDate, setJoiningDate] = useState('');
  const [iouItemDetails, setIouItemDetails] = useState([]);
  const {isDelete,setIsDelete,details,setDetails,isPopUp,setIsPopUp, deleteRowId, setDeleteRowId } = useIouContext();

  // calculate total amount
  // State to hold the total amount
  const [totalAmount, setTotalAmount] = useState(0);

  const handleIsDelete=(id)=>{
    setIsDelete(true);
    setDeleteRowId(id)
  }
  const statusBackgroundColor = getStatusBackgroundColor(details.STATUS);
  
  useEffect(() => {
    // When the component mounts, set isOpen to true to trigger the opening transition
    setIsOpen(true);
  }, []);
  
  const handleClose = () => {
    // When closing, set isOpen to false to trigger the closing transition
    setIsOpen(false);
    setDetails({});
    setIsDelete(false);

    // Wait for the closing transition to complete before actually closing
    setTimeout(() => {
      setIsPopUp(false);
    }, 200); // Adjust the timeout to match your transition duration
  };
  
  
    useEffect(() => {
      console.log(details.MO_ID);
      const eid = localStorage.getItem('empId');
      setEmployeeId(eid);
      const id = localStorage.getItem('userId');
      getAdminInfo(id);
      getItem();
      getHierarchy(details.REQUSTED_BY_ID, details.OBJECT_TYPE_CODE, details.IOU_ID);
    }, []);

    // Calculate total amount when the component mounts or iouItemDetails changes
    useEffect(() => {
      const calculateTotalAmount = () => {
        let total = 0;
        iouItemDetails.forEach((e) => {
          total += e.ITEM_AMOUNT || 0;
        });
        setTotalAmount(total);
      };

      calculateTotalAmount();
    }, [iouItemDetails]);
  
    // generate pdf for downloading
    // const generatePDF = () => {
    //   const element = document.getElementById('pdf');
    //   html2canvas(element).then((canvas) => {
    //     const imgData = canvas.toDataURL('image/png');
    //     const pdf = new jsPDF('portrait', 'mm', 'a4');
  
    //     pdf.addImage(imgData, 'PNG', 10, 10);
    //     pdf.save(`MY_MOVE_ORDER_${moment(details.CREATION_DATE).format('DD/MM/yyyy')}.pdf`);
    //   });
    // };

    const generatePDF = () => {
      const element = document.getElementById('pdf');
      html2canvas(element).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('portrait', 'mm', 'a4');

        const componentWidth = pdf.internal.pageSize.getWidth();
        const componentHeight = pdf.internal.pageSize.getHeight();
  
        pdf.addImage(imgData, 'PNG', 0, 0, componentWidth, componentHeight);
        pdf.save(`MY_IOU_${moment(details.CREATION_DATE).format('DD/MM/yyyy')}.pdf`);
      });
    };
  
    const [actionCode, setActionCode] = useState('');

    const getHierarchy = async (id, module, object) => {
      setIsLoading(true);
      const re = await ApproverHierarchyListService(id, module, object);
      const sortedData = re.APPROVER_LIST.sort((a, b) => a.STAGE_SEQ - b.STAGE_SEQ);
      setApproverList(sortedData);
      setActionCode(sortedData[0].ACTION_CODE)
      // console.log("hierarchy move order: ", approverList[0].ACTION_CODE);
      setIsLoading(false);
    }
  
    const getItem = async () => {
      const re = await IouItemDetailsService(details.IOU_ID);
      console.log("table details: ", re);
      setIouItemDetails(re.iou_items);
    }
  
    const getAdminInfo = async (uid) => {
      const admin = await AdminInfoService(infoUrl, uid);
      // setAdminDetails(admin.EMP_DETAILS);
      const eId = admin.EMP_DETAILS[0].EMPLOYEE_ID;
  
      const designation = admin.EMP_DETAILS[0].DESIGNATION;
      const joiningDate = admin.EMP_DETAILS[0].JOINING_DATE;
      setEmpId(eId);
      setDesignation(designation);
      setJoiningDate(joiningDate);
    }
  
    const convertDate = (dateString) => {
      const dateObject = new Date(dateString);
      const day = String(dateObject.getUTCDate()).padStart(2, '0');
      const month = String(dateObject.getUTCMonth() + 1).padStart(2, '0');
      const year = dateObject.getUTCFullYear();
  
      return `${day}/${month}/${year}`;
    };
  
    // Define background color based on status
    function getStatusBackgroundColor(status) {
      switch (status) {
        case "IN PROCESS":
          return 'bg-[#FDCA4D] text-black border-black';
        case "APPROVED":
          return 'bg-[#2D833F] text-white';
        case "REJECTED":
          return 'bg-[#E20000] text-white';
        default:
          return 'bg-transparent'; // Default bg color
      }
    }

    // using for see more and see less
  const [seeMore, setSeeMore] = useState(-1);

  const handleSeeMoreDetails = (index) => {
    setSeeMore(index === seeMore ? -1 : index);
  };

    return (
      <div className={`my-14 fixed top-0 right-0 w-3/12 h-full bg-white border-l border-gray-300 pb-4 pt-2 transition-transform ${
        isOpen ? 'translate-x-0' : 'translate-x-full'
      }`}>
        <div className="max-h-full no-scrollbar overflow-y-auto transition-transform ease-in-out duration-700">
  
          {/* Add a button or an element to close the popup */}
          <div className={`px-3 py-4 flex flex-row items-center justify-between space-x-3 ${statusBackgroundColor}`}>
            <div className='flex items-center justify-between space-x-2 w-full'>
              <div className='flex items-center space-x-2'>
                <button className={`py-2 rounded-md border-[0.5px] hover:border-gray-400 px-3`} onClick={handleClose}>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
                  </svg>
                </button>
  
                <p className={`font-semibold `}>Status: {details.STATUS}</p>
              </div>
  
              <div className='flex items-center space-x-2'>
                {/* <div className=''>
                  { 
                    details.STATUS==="APPROVED" ?
                      <button onClick={generatePDF} className='py-2 px-2 rounded-md border-[0.5px] font-medium text-white'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                        </svg>
                      </button>
                    :
                      <div></div>
                  }
                </div> */}
  
                <div className="">
                { 
                  details.STATUS==="IN PROCESS" && actionCode === 3 ?
                    <button onClick={() => handleIsDelete(details.IOU_ID)} className='py-2 px-2 rounded-md text-[16px] font-medium text-[#EC5555]'>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                      </svg>
                    </button>
                    :
                    <div></div>
                }
                </div>
              </div>
            </div>
  
            {/* <div>
                <p className={`border-[3px] px-4 py-4 rounded-full ${getStatusBorderColor(details.AUTHORIZATION_STATUS)}`}></p>
            </div> */}
          </div>
  
          {/* <div className="h-4"></div> */}
  
  
          {/* Render details data here */}
          <div className='space-y-3'>
            {/* <p className="my-4 font-semibold px-3">Status: {details.AUTHORIZATION_STATUS}</p> */}
            
            <div>
              <p className='bg-[#E6E1DD] px-3 py-2 font-semibold'>Employee Details</p>
              <p className='px-3 border-b-[0.5px] pb-2'>Applicant Name: {details.EMPLOYEE_NAME == null ? "N/A" : details.EMPLOYEE_NAME}</p>
              <p className='px-3 border-b-[0.5px] pb-2'>Applicant ID: {details.REQUSTED_BY_ID == null ? "N/A" : details.REQUSTED_BY_ID}</p>
              <p className='px-3 border-b-[0.5px] pb-2'>Designation: {designation}</p>
              <p className='px-3 border-b-[0.5px] pb-2'>Joining Date: {convertDate(joiningDate)}</p>
            </div>
  
            <div>
              <p className='bg-[#E6E1DD] px-3 py-2 font-semibold'>IOU DETAILS</p>
  
              <p className='px-3 border-b-[0.5px] pb-2'>Application Date: {details.IOU_DATE == null ? "N/A" : details.IOU_DATE}</p>
              <p className='px-3 border-b-[0.5px] pb-2'>Company Name: {details.COMPANY_NAME == null ? "N/A" : details.COMPANY_NAME}</p>
              <p className='px-3 border-b-[0.5px] pb-2'>Plant Name: {details.PLANT_NAME == null ? "N/A" : details.PLANT_NAME}</p>
              <p className='px-3 border-b-[0.5px] pb-2'>Due Amount: {details && details.DUE_AMOUNT ? `${numeral(details.DUE_AMOUNT).format('0,0')} ৳` : "N/A"} </p>
  
              {
                details && details.MORE_DAYS_30 ?
                  <div>
                    <p className='px-3 border-b-[0.5px] pb-2 pt-3'>More than 30: {details.MORE_DAYS_30 && details.MORE_DAYS_30.includes('null') ? "N/A" : details.LESS_DAYS_30}</p>
                  </div>
                :
                  <div>
                    <p className='px-3 border-b-[0.5px] pb-2 pt-3'>More than 30: N/A</p>
                  </div>
              }
  
              {
                details && details.LESS_DAYS_30 ?
                  <div>
                    <p className='px-3 border-b-[0.5px] pb-2 pt-3'>Less than 30: {details.MORE_DAYS_30 && details.MORE_DAYS_30.includes('null') ? "N/A" : details.LESS_DAYS_30}</p>
                  </div>
                :
                  <div>
                    <p className='px-3 border-b-[0.5px] pb-2 pt-3'>Less than 30: N/A</p>
                  </div>
              }
  
  
              {/* <p className='px-3 border-b-[0.5px] pb-2'>More than 30: {details.MORE_DAYS_30.includes('null') ? "N/A" : details.MORE_DAYS_30}</p>
              <p className='px-3 border-b-[0.5px] pb-2'>More than 30: {details.MORE_DAYS_30.includes('null') ? "N/A" : details.MORE_DAYS_30}</p> */}
            </div>
  
            <div>
              <p className='bg-[#E6E1DD] px-3 py-2 font-semibold'>Purpose</p>
              <p className='px-3 border-b-[0.5px] pb-2'>{details.IOU_REASON == null ? "N/A" : details.IOU_REASON}</p>
            </div>
            
            <div>
              <table className="w-full bg-white border-[0.5px] border-gray-200">
                {/* head */}
                <thead className='bg-bgTableHeader text-tableHeaderText w-full h-12'>
                  <tr className='bg-bgTableHeader text-tableHeaderText px-3'>
                    <th className='py-3 px-3 text-left'>Item Details</th>
                    <th className='py-3 px-3 text-left'>Quantity</th>
                    <th className='py-3 px-3 text-left'>Rate</th>
                    <th className='py-3 px-3 text-left'>Taka</th>
                  </tr>
                </thead>
  
                <tbody>
                  {
                    iouItemDetails.map((e, index) =>
                      <tr key={index} className='px-3 border-b-[0.5px] pb-2'>
  
                        {/* width with ellipsis */}
                        {/* <td style={{ maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                          {e.ITEM_DESC == null ? "N/A" : e.ITEM_DESC}
                        </td> */}
  
                        {/* height with ellipsis */}
                        {/* <td className="ellipsis" style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'normal' }}>
                          {e.ITEM_DESC == null ? "N/A" : e.ITEM_DESC}
                        </td> */}
  
                        {/* <td style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>
                          {e.ITEM_DESC == null ? "N/A" : e.ITEM_DESC}
                        </td> */}
  
                        <td style={{ whiteSpace: 'normal', wordBreak: 'break-word' }} className='px-3 py-2 text-left whitespace-nowrap'>
                          {e.ITEM_DESC == null ? "N/A" : (
                            <>
                              {seeMore === index ? (
                                <div>
                                  {e.ITEM_DESC}
                                  <button className='text-gray-500' onClick={() => handleSeeMoreDetails(index)}>See Less</button>
                                </div>
                              ) : (
                                <div>
                                  {e.ITEM_DESC.length > 14 ? (
                                    <>
                                      {e.ITEM_DESC.substring(0, 14)}...
                                      <button className='text-gray-500' onClick={() => handleSeeMoreDetails(index)}>See More</button>
                                    </>
                                  ) : (
                                    <div>{e.ITEM_DESC}</div>
                                  )}
                                </div>
                              )}
                            </>
                          )}
                        </td>
  
                        <td className='px-3 py-2 text-left whitespace-nowrap'>{e.QUANTITY == null ? "N/A" : e.QUANTITY}</td>
                        <td className='px-3 py-2 text-left whitespace-nowrap'>{e.RATE == null ? "N/A" : e.RATE}</td>
                        <td className='px-3 py-2 text-left whitespace-nowrap'>{numeral(e.ITEM_AMOUNT).format('0,0')}</td>
                      </tr>
                     )
                  }
                </tbody>
  
                <tbody>
                  <tr className="bg-white">
                    <td colSpan="3"  className='px-3 py-2 text-left whitespace-nowrap'>
                      Total Amount:
                    </td>
                    
                    <td className='px-3 py-2 text-left whitespace-nowrap'>
                      {numeral(totalAmount).format('0,0')}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
  
            <div className=''>
              <p className='bg-[#E6E1DD] px-3 py-2 font-semibold'>Approve/Reject history</p>
              
              {/* <div className="h-3"></div> */}

              <div className='px-3 w-5/6'>
                {
                  approverList.map((e, i) => (
                    <div className='flex flex-col items-start space-y-4' key={i}>
                      
                      <div className="h-[2px]"></div>
                      
                      <div className='flex flex-row items-center space-x-6 w-96 '>
                        <div className={`w-6 h-6 p-[2px] rounded-full border-[1px] border-orange-500 flex justify-center items-center`}>
                          {
                            e.ACTION_CODE === 1
                            ?
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 text-green-500">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                              </svg>
                            :
                            e.ACTION_CODE === 0 ?
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 text-red-500">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                              </svg>
  
                            :
                            null
                          }
                        </div>
  
                        <div className=' flex flex-col items-start space-y-1'>
                          <p className='  font-medium text-black w-5/6'>{e.EMPLOYEE_NAME} <span className="text-[#7F7F7F]">({e.AUTHORIZER_TYPE})</span></p>
                          <p>{
                            e.ACTION_CODE === 1 ?
                              <div className=' flex flex-col items-start space-y-1'>
                                <p className=' font-medium text-green-500'>
                                  {/* {
                                    e.AUTHORIZER_TYPE === "RECOMMENDER" ? "RECOMMENDED" : "APPROVED"
                                  } */}
  
                                  {
                                    e.AUTHORIZER_TYPE === "RECOMMENDER" || e.AUTHORIZER_TYPE === "ACCOUNTS INFORMER" ? "RECOMMENDED" : "APPROVED"
                                  }
                                </p>
                                
                                <p className=' font-medium text-green-500'>
                                  {
                                    e.NOTE === null ? "N/A" : e.NOTE
                                  }
                                </p>
                              </div>
  
                            : e.ACTION_CODE === 3 ?
                              <p className=' text-orange-500 font-medium'>
                                PENDING
                              </p>
                                :
                              <div className=' flex flex-col items-start space-y-1'>
                                <p className=' text-red-500 font-medium' >
                                  {
                                    e.AUTHORIZER_TYPE === "RECOMMENDER" ? "NOT RECOMMENDED" : "NOT APPROVED" 
                                  }
                                </p>
                                
                                <p className=' text-red-500 font-medium' >
                                  {
                                    e.NOTE === null ? "N/A" : e.NOTE
                                  }
                                </p>
                              </div>
                            }
                          </p>
                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
              
            <div className="h-32"></div>
          </div>
        </div>
      </div>
    );
}