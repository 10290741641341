
// const username = 'fusion.appsdev';
// const password = 'Fusion1@#';




  const getMonlyRoutine= async(url,page,limit,first,last)=>{
// const urlSend=`${url}${first}/${last}?page=${page}`;
console.log(`f: ${first}--- l: ${last}`);

// console.log(urlSend);
    const response = await   fetch(url,
        {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
            },
            body: JSON.stringify({
                "P_OFFSET":page,
                "P_LIMIT":limit,
                "FROM_DATE": first,
                "TO_DATE": last
            }),
          }
        );
        if (!response.ok) {
            throw new Error("fetching is not successful");
          }
        
          const data = await response.json();
        //   console.log(data);
          return data;

     
}
export default getMonlyRoutine
