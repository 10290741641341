import { baseUrl } from "../../utils/path";



  const AttendanceReportService= async(fromEmpId,toEmpId,companyCode,plantCode,fromDate,toDate,page,limit)=>{
      console.log("cCode: ", companyCode);
      console.log("pCode: ", plantCode);
      console.log("fDate: ", fromDate);
      console.log("tDate: ", toDate);
      console.log("fEmp: ", fromEmpId);
      console.log("tEmp: ", toEmpId);
      console.log("page: ", page);
      console.log("limit: ", limit);

    const url=`${baseUrl}front_desk_report`;

    const response = await   fetch(url,
        {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
            },
            body: JSON.stringify({
                "FROM_EMPLOYEE_ID":fromEmpId,
                "TO_EMPLOYEE_ID":toEmpId,
                "COMPNAY_CODE":companyCode,
                "PLANT_CODE":plantCode,
                "FROM_DATE":fromDate,
                "TO_DATE":toDate,
                "P_OFFSET": page,
                "P_LIMIT": limit

            }),
          }
        );
        if (!response.ok) {
            throw new Error("fetching is not successful");
          }
        
          const data = await response.json();
         
          return data;

     
}
export default AttendanceReportService
