//RoleInsertDeleteService

import { baseUrl } from "../../utils/path";

  const RoleInsertDeleteService= async(userId,roleId,menuId)=>{
    
    const url=`${baseUrl}role_wise_menu_insert_delete`;
    console.log(`user:${userId} roleId:${roleId} menuId: ${menuId}`);
  
    const response = await   fetch(url,
        {
            method: "POST",
            headers: {
              
              "Content-Type": "application/json",
              'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_U}:${process.env.REACT_APP_P}`),
            },
            body: JSON.stringify({
                "Z_DATA": userId,
                "ROLE_ID":roleId,
                "MENU_ID":menuId
                 
            }),
          }
        );
     console.log(response);
          const data = await response.json();
        // console.log(data);
          return data;

     
}
export default RoleInsertDeleteService
